import React from "react"
import { Card, CardBody, CardHeader } from "reactstrap"
import { ClientListContainer } from "components/clients/redux/ClientContainers"
import Icon from "components/common/Icon"

const ClientCardBasic = () => {
    return (
        <Card>
            <CardHeader>
                <h6>
                    <Icon client />
                    Clients
                </h6>
            </CardHeader>
            <CardBody>
                <ClientListContainer />
            </CardBody>
        </Card >
    )
}

export default ClientCardBasic