import { connect } from "react-redux"
import OpportunityCardExtended from "components/opportunities/layout/OpportunityCardExtended"
import OpportunityList from "components/opportunities/layout/OpportunityList"
import { FormEvents, FormActions, ModalConstants,DispatchActions } from "@gman/gman-redux"
import { OpportunityMineScope } from "components/opportunities/redux/OpportunityMineScope"
import { OpportunityAnyScope } from "components/opportunities/redux/OpportunityAnyScope"
import { ProposalAnyScope } from "components/proposals/redux/ProposalAnyScope"
import { ClientScope } from "components/clients/redux/ClientScope"
import { OfficeScope } from "components/offices/redux/OfficeScope"

const mapDispatchToPropsMine = (dispatch) => { return mapDispatchToProps(dispatch, OpportunityMineScope) }

const mapDispatchToPropsAny = (dispatch) => { return mapDispatchToProps(dispatch, OpportunityAnyScope) }

const mapDispatchToProps = (dispatch, scope) => {
  var proposalScope = ProposalAnyScope
  return {
    ...FormEvents.getTableHeaderRowEvents(dispatch, scope),
    ...FormEvents.getFeedbackEvents(dispatch, scope),
    ...FormEvents.getPaginatorEvents(dispatch, scope),
    ...FormEvents.getModalEventsForModalsOnNewOnly(dispatch, scope),
    ...FormEvents.getRecordStateEvents(dispatch, scope),
    ...FormEvents.getRedirectEvents(dispatch, scope),
    onConfirmConvert: record => {
      var redirect = { urlFunc: (o) => "/proposalDetails/" + o.proposalId }
      dispatch(FormActions.doPost(scope, record, "/api/Opportunity/ConvertAny", "The record was converted successfully.", true, redirect))
      dispatch(FormActions.hideModal(scope, "CONVERT"))
      dispatch(FormActions.fetchRecords(proposalScope))
    },
    onPageSizeChange: (pageSize) => {
      dispatch({...DispatchActions.scopedDispatch(scope.key, "CHANGE_PAGE_SIZE"), pageSize: pageSize });
      dispatch(FormActions.fetchRecords(scope));
    },
    onLoadDependentData: () => {
      dispatch(FormActions.fetchAll(ClientScope))
      dispatch(FormActions.fetchAll(OfficeScope))
    },
    onloadClients:()=>{
      dispatch(FormActions.fetchAll(ClientScope));
    },
    confirmEvents: {
      ...FormEvents.getConfirmEventsForModalsOnNewOnly(dispatch, scope).confirmEvents,
      onSubmitUpdate: (record, isNew = false) => {
        var redirect = isNew ? { urlFunc: (o) => "/opportunityDetails/" + o.opportunityId } : {}
        dispatch(FormActions.doPost(scope, record, scope.updateEndpoint, "The record was updated successfully.", true, redirect))
        dispatch(FormActions.hideModal(scope, ModalConstants.UPDATE))
      },
      onConfirmPublish: record => {
        var redirect = { urlFunc: () => "/opportunity/mine/" }
        dispatch(FormActions.doPost(scope, record, scope.publishEndpoint, "The record was published successfully.", true, redirect))
        dispatch(FormActions.hideModal(scope, ModalConstants.PUBLISH))
      },
    }
  }
}

const OpportunityListMineContainer = connect(
  (state, ownProps) => {
    return {
      sourceData: OpportunityMineScope.selector(state),
      clientId: ownProps.clientId
    }
  },
  mapDispatchToPropsMine
)(OpportunityList)

const OpportunityListAnyContainer = connect(
  (state, ownProps) => {
    return {
      sourceData: OpportunityAnyScope.selector(state),
      clientId: ownProps.clientId
    }
  },
  mapDispatchToPropsAny
)(OpportunityList)

const DetailsContainer = (readOnly) =>
  connect(
    (state) => {
      return {
        sourceData: OpportunityAnyScope.selector(state),
        readOnly: readOnly
      }
    },
    mapDispatchToPropsAny
  )(OpportunityCardExtended)

const OpportunityEditContainer = DetailsContainer(false)

const OpportunityReadContainer = DetailsContainer(true)


export { OpportunityEditContainer, OpportunityReadContainer, OpportunityListMineContainer, OpportunityListAnyContainer }