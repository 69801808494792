import ScopeConstants from "constants/ScopeConstants"
import { FormActionReducer } from "@gman/gman-redux"

export const LeaveReducer = (
  state = {
    ...FormActionReducer.defaultPageState(),
    ...FormActionReducer.defaultSearchOptions("DisplayOrder"),
    ...FormActionReducer.defaultRecordState()
  },
  action) => {

  if (action.scope === ScopeConstants.LEAVE) {
    return FormActionReducer.reduce(action, state)
  }

  return state
}