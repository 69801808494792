import React from "react"
import { Badge } from "reactstrap"
import ProposalStatusConstants from "constants/ProposalStatusConstants"

const ProposalStatusBadge = props => {
    const { value } = props
    return (
        <Badge color={MapProposalStatusColour(value)}>
            {MapProposalStatusLabel(value)}
        </Badge>
    )
}

const MapProposalStatusLabel = value => {
    switch (value) {
        case ProposalStatusConstants.DRAFT:
            return "DRAFT"
        case ProposalStatusConstants.INPROGRESS:
            return "IN PROGRESS"
        case ProposalStatusConstants.NOGO:
            return "NO GO"
        case ProposalStatusConstants.WON:
            return "WON"
        case ProposalStatusConstants.LOST:
            return "LOST"
        case ProposalStatusConstants.UNKNOWN:
            return "UNKNOWN"
        default:
            return "UNKNOWN"
    }
}

const MapProposalStatusColour = value => {
    switch (value) {
        case ProposalStatusConstants.DRAFT:
            return "warning"
        case ProposalStatusConstants.INPROGRESS:
            return "primary"
        case ProposalStatusConstants.NOGO:
            return "dark"
        case ProposalStatusConstants.WON:
            return "success"
        case ProposalStatusConstants.LOST:
            return "danger"
        case ProposalStatusConstants.UNKNOWN:
            return "secondary"
        default:
            return "default"
    }
}

export default ProposalStatusBadge