import React, { Component } from "react"
import Moment from "moment"
import { SpinnerOverlay } from "@gman/gman-react-bootstrap"
import {
    FlexibleWidthXYPlot,
    XAxis,
    YAxis,
    ChartLabel,
    LineSeries
} from "react-vis"

export class TimesheetHoursChart extends Component {

    componentDidMount() {
        if (this.props.onLoadData) {
            this.props.onLoadData()
        }
    }

    render() {
        var sourceData = this.props.sourceData

        var loggedData = (sourceData && sourceData.records && sourceData.records.loggedHours) ? sourceData.records.loggedHours.map(function (value) {
            return { x: Moment(value.x).toDate(), y: value.y }
        }) : []

        var minimumData = (sourceData && sourceData.records && sourceData.records.minimumHours) ? sourceData.records.minimumHours.map(function (value) {
            return { x: Moment(value.x).toDate(), y: value.y }
        }) : []

        return (
            <div className="responsive-bar-chart">
                <SpinnerOverlay isLoading={sourceData && sourceData.progress}>
                    <FlexibleWidthXYPlot
                        xType="time"
                        height={200}
                        yDomain={[0, 80]}
                    >
                        <XAxis
                            tickLabelAngle={-45}
                            tickTotal={6}
                        />
                        <YAxis />
                        <ChartLabel
                            className="alt-x-label"
                            includeMargin={false}
                            xPercent={0.025}
                            yPercent={1.01}
                        />
                        <ChartLabel
                            className="alt-y-label"
                            includeMargin={false}
                            xPercent={0.06}
                            yPercent={0.06}
                            style={{
                                transform: 'rotate(-90)',
                                textAnchor: 'end'
                            }}
                        />
                        <LineSeries
                            className="series-secondary"
                            curve={'curveMonotoneX'}
                            data={loggedData}
                        />
                        <LineSeries
                            className="series-tertiary"
                            curve={'curveMonotoneX'}
                            strokeDasharray={'7, 3'}
                            data={minimumData}
                        />
                    </FlexibleWidthXYPlot>
                </SpinnerOverlay>
            </div>
        );
    }
}