import React from "react"
import PropTypes from "prop-types"
import { ClassificationShape } from "shapes/ClassificationShape"
import { AuthorizedSummaryToolbar } from "components/authentication/controls/AuthorizedControls"

const Toolbar = AuthorizedSummaryToolbar(p => p.canWriteClassifications)

const ClassificationRow = (
    {
        name,
        description,
        onShowEdit,
        onShowDelete,
        disabled
    }) => (
        <tr>
            <td>{name}</td>
            <td>{description}</td>
            <td>
                <Toolbar disabled={disabled} onShowEdit={onShowEdit} onShowDelete={onShowDelete} />
            </td>
        </tr>
    )

ClassificationRow.propTypes = PropTypes.shape(ClassificationShape).isRequired

export default ClassificationRow 