import keyMirror from "keymirror"

const ScopeConstants = keyMirror({
    AUTHENTICATION: null,
    CHARGE_RATE: null,
    CHARGE_RATE_LIST: null,
    COST_RATE: null,
    CLASSIFICATION: null,
    CLIENT: null,
    CLIENT_INVOICE: null,
    SUB_CONTRACTOR_INVOICE: null,
    SUB_CONTRACTOR_INVOICE_EDIT_LIST: null,
    COST_CODE: null,
    COST_CODE_GROUP: null,
    DASHBOARD: null,
    EXPENSE_MINE: null,
    EXPENSE_MINE_BY_DUE_DATE: null,
    EXPENSE_TEAM: null,
    EXPENSE_TEAM_BY_DUE_DATE: null,
    EXPENSE_ANY: null,
    EXPENSE_ANY_BY_DUE_DATE: null,
    EXPENSE_EDIT_LIST: null,
    EXPENSE_TYPE: null,
    EXPENSE_DUE_DATE: null,
    EXPENSE_BY_DUE_DATE: null,
    LEAVE: null,
    PROJECT_MINE: null,
    PROJECT_ANY: null,
    EDIT_DATA: null,
    PROPOSAL_MINE: null,
    PROPOSAL_ANY: null,
    USER: null,
    MANAGER: null,
    OUTSTANDING_TIMESHEET_MINE: null,
    OUTSTANDING_TIMESHEET_TEAM: null,
    OUTSTANDING_TIMESHEET_ALL: null,
    OFFICE: null,
    OPPORTUNTIY_MINE: null,
    OPPORTUNTIY_ANY: null,
    REPORT_TIMESHEET_HOURS: null,
    REPORT_INVOICES_UNPAID: null,
    REPORT_SUB_CONTRACTOR_INVOICES_UNPAID: null,
    REPORT_INVOICES_HISTORICAL: null,
    REPORT_INDIVIDUAL_INVOICE_NUMBERS: null,
    REPORT_CHARGE_RATES: null,
    REPORT_PROJECT_FINANCIALS:null,
    RESET_PASSWORD: null,
    STATIC_PROJECTS: null,
    TIMESHEET_MINE: null,
    TIMESHEET_TEAM: null,
    TIMESHEET_ANY: null,
    TIMESHEET_ENTRY: null,
    TIMESHEET_ENTRY_EDIT_LIST: null,
    TIMESHEET_ENTRY_HISTORY: null,
    USER_AUTHENTICATION: null,
    USER_CLASSIFICATION: null,
    VARIATION: null,
    WORKBENCH_EXPORT: null,
    PROJECT_FINANCIAL: null,
    PROJECT_SUMMARY_MANAGER: null,
    PROJECT_SUMMARY_DIRECTOR: null
})

export default ScopeConstants