import { connect } from "react-redux"
import { FormActions, FormEvents, DispatchActions } from "@gman/gman-redux"
import { ProjectSummaryManagerScope } from "components/projectSummary/redux/ProjectSummaryManagerScope"
import { ProjectSummaryDirectorScope } from "components/projectSummary/redux/ProjectSummaryDirectorScope"
import { ProjectSummaryList } from "components/projectSummary/layout/ProjectSummaryList"


const mapDispatchToPropsManager = (dispatch) => { return mapDispatchToProps(dispatch, ProjectSummaryManagerScope) }

const mapDispatchToPropsDirector = (dispatch) => { return mapDispatchToProps(dispatch, ProjectSummaryDirectorScope) }

const mapDispatchToProps = (dispatch, scope) => {
    return {
        ...FormEvents.getPaginatorEvents(dispatch, scope),
        ...FormEvents.getRecordStateEvents(dispatch, scope),
        onPageSizeChange: (pageSize) => {
            dispatch({ ...DispatchActions.scopedDispatch(scope.key, "CHANGE_PAGE_SIZE"), pageSize: pageSize });
            dispatch(FormActions.fetchRecords(scope));
        }
    }
}

const ProjectSummaryListManagerContainer = connect(
    state => { return { sourceData: ProjectSummaryManagerScope.selector(state) } },
    mapDispatchToPropsManager
)(ProjectSummaryList)

const ProjectSummaryListDirectorContainer = connect(
    state => { return { sourceData: ProjectSummaryDirectorScope.selector(state) } },
    mapDispatchToPropsDirector
)(ProjectSummaryList)

export {
    ProjectSummaryListManagerContainer,
    ProjectSummaryListDirectorContainer
}