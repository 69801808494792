import ScopeConstants from "constants/ScopeConstants"
import { ScopeShape } from "@gman/gman-redux"
import PropTypes from "prop-types"

export const TimesheetEntryHistoryScope = {
    key: ScopeConstants.TIMESHEET_ENTRY_HISTORY,
    selector: (state) => state.timesheetEntriesHistory,
    tokenSelector: (state) => state.authentication.token,
    fetchEndpoint: "/api/TimesheetEntryHistory/Recent/"
}

TimesheetEntryHistoryScope.propTypes = PropTypes.shape(ScopeShape).isRequired