import { shape, string, number } from "prop-types"

export const TimesheetEntryShape = shape({
    mondayHours: number.isRequired,
    tuesdayHours: number.isRequired,
    wednesdayHours: number.isRequired,
    thursdayHours: number.isRequired,
    fridayHours: number.isRequired,
    saturdayHours: number.isRequired,
    sundayHours: number.isRequired,
    timesheetEntryId: string.isRequired,
    timesheetId: string.isRequired,
    projectId: string,
    comment: string
})