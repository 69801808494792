export const InvoiceWeekDayReducer = (
    state = {
        records: [
            { weekDayId: 0, description: "Sunday" },
            { weekDayId: 1, description: "Monday" },
            { weekDayId: 2, description: "Tuesday" },
            { weekDayId: 3, description: "Wednesday" },
            { weekDayId: 4, description: "Thursday" },
            { weekDayId: 5, description: "Friday" },
            { weekDayId: 6, description: "Saturday" },
        ]
    }) => { return state }