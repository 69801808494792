import React, { Component } from "react"
import { UserContainerExtended } from "components/users/redux/UserContainers"
import { UserCardBasic } from "components/users/layout/UserCardBasic"

export class UserPageBasic extends Component {
    render() { return (<UserCardBasic />) }
}

export class UserPageExtended extends Component {
    render() { return (<UserContainerExtended userId={this.props.match.params.userId} />) }
}
