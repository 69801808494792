import React, { useState } from "react"
import { ButtonDropdown, DropdownMenu, DropdownToggle, DropdownItem } from "reactstrap"

const classes = {
    btnRoot:{
        paddingLeft:10
    },
    btn:{
        color: '#317f20',
        borderColor:'#317f20',
        background:'#fff',
        backgroundColor:'#fff',
    }
}

const PageSizeSelect = ({ defaultPageSize, onChange, disabled }) => {
    const [dropdown, setDropdown] = useState(false);
    const [pageSize, setPageSize] = useState(defaultPageSize || 50);
    const hadnleChange = (value) => {
        setPageSize(value);
        onChange(value);
    }
    return (
        <ButtonDropdown size="sm" style={classes.btnRoot} color="primary" disabled={disabled} outline isOpen={dropdown} toggle={() => { setDropdown(!dropdown) }}>
            <DropdownToggle caret style={classes.btn}>
                {pageSize} Records Per Page
            </DropdownToggle>
            <DropdownMenu right>
                <DropdownItem onClick={() => { hadnleChange(10) }}>10</DropdownItem>
                <DropdownItem onClick={() => { hadnleChange(20) }}>20</DropdownItem>
                <DropdownItem onClick={() => { hadnleChange(50) }}>50</DropdownItem>
                <DropdownItem onClick={() => { hadnleChange(100) }}>100</DropdownItem>
                <DropdownItem onClick={() => { hadnleChange(200) }}>200</DropdownItem>
                <DropdownItem onClick={() => { hadnleChange(500) }}>500</DropdownItem>
            </DropdownMenu>
        </ButtonDropdown>
    )
}

export default PageSizeSelect;