import React, { useState } from "react"
import { Button, ButtonGroup, Input } from "reactstrap"
import { FormModal } from "@gman/gman-react-bootstrap"
import { ModalActions } from "@gman/gman-redux"
import TimeCell from "components/common/TimeCell"
import Moment from "moment"
import WorkSelectForm from "components/common/WorkSelectForm"
import WorkItemCell from "components/common/WorkItemCell"
import Icon from "components/common/Icon"
import TimesheetEntryWorkSelectForm from "components/timesheetEntries/form/TimesheetEntryWorkSelectForm"
import { withFormik } from "formik"
import ClientInvoiceStatusBadge from "components/common/ClientInvoiceStatusBadge"
import InvoiceStatusConstants from "constants/InvoiceStatusConstants"
const BaseForm = props => {
    const {
        index,
        values,
        modals,
        onShowModal,
        onHideModal,
        dirty,
        errors,
        setFieldValue,
        handleChange,
        handleBlur,
        handleHoursChange,
        handleCommentChange,
        handleWorkItemChange,
        handleCostItemsStausChange
    } = props

    console.log(values.costItemStatus , 'values.costItemStatus')
    return (
        <tr>
            <td style={{ whiteSpace: 'pre' }} className="not-editable"> {values.timesheet.user != null ? values.timesheet.user.firstName + ' ' + values.timesheet.user.lastName : ''} </td>
            <td style={{ whiteSpace: 'pre' }} className="not-editable"> {Moment(values.timesheet.weekEnding).format("DD/MM/YYYY")}</td>
            <td>
                <div style={{ width: '600px' }} >
                    {
                        values.workItem ?
                            <WorkItemCell
                                workItem={values.workItem}
                                projectColumnWidth={8}
                                projectCodeColumnWidth={4}
                                projectVariationColumnWidth={8}
                                projectCostCodeColumnWidth={4}
                            />
                            : ""
                    }
                    <Button 
                       disabled={values.clientInvoice && values.clientInvoice.status != InvoiceStatusConstants.DRAFT}
                       block color="light" size="sm"   onClick={() => onShowModal("SELECT_WORK_ITEM")} className="editable ">
                        <Icon book />
                        Select Project
                    </Button>
                </div>
            </td>
            <td style={{ whiteSpace: 'pre' }} className="weekend ">{hoursField('saturdayHours', values.saturdayHours,values, handleChange, handleBlur, handleHoursChange)}</td>
            <td style={{ whiteSpace: 'pre' }} className="weekend">{hoursField('sundayHours', values.sundayHours,values, handleChange, handleBlur, handleHoursChange)}</td>
            <td style={{ whiteSpace: 'pre' }}>{hoursField('mondayHours', values.mondayHours, values,handleChange, handleBlur, handleHoursChange)}</td>
            <td style={{ whiteSpace: 'pre' }}>{hoursField('tuesdayHours', values.tuesdayHours, values,handleChange, handleBlur, handleHoursChange)}</td>
            <td style={{ whiteSpace: 'pre' }}>{hoursField('wednesdayHours', values.wednesdayHours,values, handleChange, handleBlur, handleHoursChange)}</td>
            <td style={{ whiteSpace: 'pre' }}>{hoursField('thursdayHours', values.thursdayHours,values, handleChange, handleBlur, handleHoursChange)}</td>
            <td style={{ whiteSpace: 'pre' }}>{hoursField('fridayHours', values.fridayHours, values,handleChange, handleBlur, handleHoursChange)}</td>
            <td className="weekend not-editable">
                {
                    (
                        getNumber(values.saturdayHours) +
                        getNumber(values.sundayHours) +
                        getNumber(values.mondayHours) +
                        getNumber(values.tuesdayHours) +
                        getNumber(values.wednesdayHours) +
                        getNumber(values.thursdayHours) +
                        getNumber(values.fridayHours)
                    ).toFixed(2)
                }
            </td>
            <td>
                <div style={{ width: '250px' }}>
                    <Input
                        onClick={(event) => event.stopPropagation()}
                        onChange={
                            (e) => {
                                 console.log("textareaComment",e.target.value.substring(0, 1));
                                 if(e.target.value.substring(0, 1) == "+" || e.target.value.substring(0, 1) == "-" || e.target.value.substring(0, 1) == "="){
                                    alert("“Do not use “-” “+” or “=” as your first character”");
                                 }
                                 else{
                                     handleChange(e);
                                     handleCommentChange(e);
                                 }
                            }
                        }
                        value={values.comment || ""}
                        disabled={values.clientInvoice && values.clientInvoice.status != InvoiceStatusConstants.DRAFT}
                        type="textarea"
                        name="comment"
                        id="comment"
                        className="editable"
                    />
                </div>
            </td>
            {/* <td className="not-editable">
                {values.costRate == null ? "" : (getNumber(values.costRate)).toFixed(2)}
            </td> */}
            <td className="not-editable">
                 {values.chargeRate == null ? "" : (getNumber(values.chargeRate)).toFixed(2)}
            </td>
            <td className="not-editable">
                {values.chargeRate == null ?
                    "" :
                    ((
                        getNumber(values.saturdayHours) +
                        getNumber(values.sundayHours) +
                        getNumber(values.mondayHours) +
                        getNumber(values.tuesdayHours) +
                        getNumber(values.wednesdayHours) +
                        getNumber(values.thursdayHours) +
                        getNumber(values.fridayHours)
                    ) * getNumber(values.chargeRate)).toFixed(2)
                }
            </td>
            <td className="text-center">
                <Input
                    type="radio"
                    value={0}
                    style={{ position: 'unset', margin: 0 }}
                    name="costItemStatus"
                    disabled={values.clientInvoice && values.clientInvoice.status != InvoiceStatusConstants.DRAFT}
                    onChange={
                        (e) => {
                            handleCostItemsStausChange(e);
                            handleChange(e);
                        }
                    }
                    checked={values.costItemStatus == 0 ? true : false}
                    className="editable"
                />
            </td>
            <td className="text-center">
                <Input
                    type="radio"
                    value={1}
                    style={{ position: 'unset', margin: 0 }}
                    name="costItemStatus"
                    disabled={values.clientInvoice && values.clientInvoice.status != InvoiceStatusConstants.DRAFT}
                    onChange={
                        (e) => {
                            handleCostItemsStausChange(e);
                            handleChange(e);
                        }
                    }
                    checked={values.costItemStatus == 1 ? true : false}
                    className="editable"
                />
            </td>
            <td className="text-center">
                <Input
                    type="radio"
                    value={2}
                    style={{ position: 'unset', margin: 0 }}
                    name="costItemStatus"
                    disabled={values.clientInvoice && values.clientInvoice.status != InvoiceStatusConstants.DRAFT}
                    onChange={
                        (e) => {
                            handleCostItemsStausChange(e);
                            handleChange(e);
                        }
                    }
                    checked={values.costItemStatus == 2 ? true : false}
                    className="editable"
                />
            </td>
            <td className="not-editable">{values.clientInvoice ? values.clientInvoice.number : ""}</td>
            <td className="not-editable">
                {
                    values.clientInvoice ? <ClientInvoiceStatusBadge value={values.clientInvoice.status} /> : ""
                }
            </td>
            <FormModal
                size="lg"
                isVisible={ModalActions.IsVisible(modals, "SELECT_WORK_ITEM")}
                onHide={() => onHideModal("SELECT_WORK_ITEM")}
                title={"Select Project"}>
                <TimesheetEntryWorkSelectForm
                    {...ConstructWorkItem(values.workItem)}
                    onSelectEntry={
                        (selection) => {
                            var workItem = selection.workItem;
                            setFieldValue("workItem", workItem);
                            handleWorkItemChange(workItem);
                            onHideModal("SELECT_WORK_ITEM");
                        }
                    } />
            </FormModal>
        </tr>
    )
}

const ConstructWorkItem = (workItem) => {
    return {
        workItem: {
            workItemId: workItem && workItem.workItemId,
            costCodeGroupId: workItem && workItem.costCodeGroupId,
            type: workItem && workItem.type,
            name: workItem && workItem.name,
            variation: workItem && (workItem.variation),
            costCode: {
                costCodeId: workItem && workItem.costCode && (workItem.costCode.costCodeId),
                costCodeGroupId: workItem && workItem.costCodeGroupId
            }
        }
    }
}

const getNumber = (value) => {
    var number = parseFloat(value)
    if (isNaN(number))
        return 0

    return number
}

const rateField = (id, value, values, handleChange, handleBlur, handleHoursChange) => {
    const style = {
        zIndex: 10,
        width: '75px'
    }
    return <>
        {
            <Input
                className="hours editable"
                bsSize="sm"
                onChange={(e) => {
                    handleHoursChange(e);
                    handleChange(e);
                }}
                onBlur={handleBlur}
                value={value}
                style={style}
                onClick={(event) => event.stopPropagation()}
                type="number"
                disabled={values.clientInvoice && values.clientInvoice.status != InvoiceStatusConstants.DRAFT}
                name={id}
                id={id}
            />
        }

    </>
}

const hoursField = (id, value,values, handleChange, handleBlur, handleHoursChange) => {
    const style = {
        zIndex: 10,
        width: '50px'
    }
    return <>
        {
            <Input
                className="hours editable"
                bsSize="sm"
                onChange={(e) => {
                    handleHoursChange(e);
                    handleChange(e);
                }}
                onBlur={handleBlur}
                value={value}
                style={style}
                onClick={(event) => event.stopPropagation()}
                type="number"
                disabled={values.clientInvoice && values.clientInvoice.status != InvoiceStatusConstants.DRAFT}
                step={0.25}
                name={id}
                id={id}
            />
        }

    </>
}


const TimesheetEntryEditRowForm = withFormik({
    displayName: "TimesheetEntryEditRowForm",
    enableReinitialize: true,
    handleSubmit: (values, { props, setSubmitting }) => {
        setSubmitting(false)
    }
})(BaseForm)

export default TimesheetEntryEditRowForm