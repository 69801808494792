import React, { Component } from "react"
import { withFormik } from "formik"
import * as Yup from "yup"

import {
    Button,
    ButtonGroup,
    Col,
    Container,
    FormGroup,
    Input,
    Label,
    Row
} from "reactstrap"

class BaseForm extends Component {

    render() {

        var values = this.props.values
        var dirty = this.props.dirty
        var errors = this.props.errors
        var handleChange = this.props.handleChange
        var handleBlur = this.props.handleBlur
        var isSubmitting = this.props.isSubmitting
        var onSelectEntry = this.props.onSelectEntry
        var validateForm = this.props.validateForm

        return (
            <div>
                <Container>
                    <Row>
                        <Col xs={12}>
                            <FormGroup>
                                <Label for="comment">Comment</Label>
                                <Input
                                    ref={(input) => { this.comment = input; }} 
                                    type="textarea"
                                    value={values.comment}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    id="comment"
                                    rows={5}
                                    invalid={errors.comment && errors.comment.length > 0}
                                />
                                {errors.comment && <small className="text-danger">{errors.comment}</small>}
                            </FormGroup>
                        </Col>
                    </Row>
                </Container>
                <Container>
                    <ButtonGroup className="float-right">
                        <Button
                            outline
                            disabled={isSubmitting || !dirty}
                            color="warning"
                            onClick={() => validateForm().then((errors) => {
                                if (!(Object.keys(errors).length > 0)) {
                                    onSelectEntry(values)
                                }
                            })}
                        >
                            Update
                    </Button>
                    </ButtonGroup>
                </Container>
            </div>
        )
    }
}

const validationSchema = Yup.object({
    comment: Yup.string()
        .nullable()
})

const TimesheetEntryCommentForm = withFormik({
    displayName: "TimesheetEntryCommentForm",
    enableReinitialize: true,
    validationSchema: validationSchema,
    initialValues: ({ value }) => ({
        number: value.comment
    })
})(BaseForm)

export default TimesheetEntryCommentForm