import ScopeConstants from "constants/ScopeConstants"
import { ScopeShape } from "@gman/gman-redux"
import PropTypes from "prop-types"

export const UserClassificationScope = {
    key: ScopeConstants.USER_CLASSIFICATION,
    selector: (state) => state.userClassifications,
    tokenSelector: (state) => state.authentication.token,
    fetchEndpoint: "/api/UserClassification/Search/",
    singleEndpoint: "/api/UserClassification/Get/",
    updateEndpoint: "/api/UserClassification/Update/",
    deleteEndpoint: "/api/UserClassification/Delete/",
    defaultRecord: {}
}

UserClassificationScope.propTypes = PropTypes.shape(ScopeShape).isRequired