import React, { Component } from "react"
import { Link } from "react-router-dom"
import { Row, Col, Container } from "reactstrap"
import { SpinnerOverlay } from "@gman/gman-react-bootstrap"
export class ExpenseQuickNumbers extends Component {
    constructor(props) {
        super(props)
        this.state = {
            modalState: true
        }
    }
    componentDidMount() {
        if (this.props.onLoadData) {
            this.props.onLoadData()
        }
        this.handleShow = this.handleShow.bind(this);
        this.handleClose = this.handleClose.bind(this);
    }
    handleClose(){
        this.setState({ modalState: !this.state.modalState });
    }
    handleShow() {
        var sourceData = this.props.sourceData;
        sourceData.records.nomineeForProjects = [];
        this.setState({ modalState: !this.state.modalState });
        console.log(sourceData, "LOGINsourceData");
        localStorage.setItem("isNominated", true);
    }
    render() {
        var sourceData = this.props.sourceData
        var quickLinks = this.props.quickLinks || {}
        var columns = 0
        if (sourceData && sourceData.records) {
            console.log(sourceData.records.nomineeForProjects, 'sourceData+sourceData')
            if (sourceData.records.unsubmitted) columns++
            if (sourceData.records.submitted) columns++
            if (sourceData.records.approved) columns++
            if (sourceData.records.declined) columns++
        }
        if (columns === 0) { columns = 1 }
        return (
            <div>
                {columns === 1 && localStorage.getItem("isNominated") == null &&
                    <>
                        <div className={"modal-backdrop fade " + (this.state.modalState ? "show  d-block" : "d-none")}></div>
                        <div className={"modal fade" + (this.state.modalState ? " show d-block" : " d-none")} tabIndex="-1" role="dialog">
                            <div className="modal-dialog" role="document">
                                <div className="modal-content">
                                    <div className="modal-header">
                                        <h4 className="modal-title">Project Director Notification:</h4>
                                        <button type="button" className="close" onClick={this.handleClose}>
                                            <span>&times;</span>
                                        </button>
                                    </div>
                                    <div className="modal-body">
                                        {
                                            sourceData && sourceData.records && sourceData.records.nomineeForProjects !== null ?
                                                sourceData && sourceData.records && sourceData.records.nomineeForProjects !== null &&
                                                    <>
                                                        <h6 className="pl-3 pb-2">
                                                            You have been nominated as PD for this project:</h6>
                                                        <div style={{ maxHeight: "280px", overflowY: "scroll" }}>
                                                            {sourceData.records.nomineeForProjects.map((projects) => {
                                                                return (
                                                                    <div class="col-md-12">
                                                                        <span className="pb-2 font-weight-bold d-block">{projects.code} {projects.name}</span>
                                                                        <span className="pb-3 font-weight-bold d-block">Project Manager: {projects.projectManager.firstName + " " + projects.projectManager.lastName}</span>
                                                                    </div>
                                                                )
                                                            })
                                                            }
                                                        </div>
                                                        <h6 className="pl-3 pb-2">If you do not agree to take on the PD role for this project, you must ask the PM to find another PD.</h6>
                                                    </>
                                                    :
                                                    <SpinnerOverlay isLoading={sourceData && sourceData.progress}>
                                                
                                                        <span>Loading...</span>
                                                    </SpinnerOverlay>
                                        }
                                    </div>
                                    <div className="modal-footer" style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center"
                                    }}>
                                        <button type="button" style={{ backgroundColor: "#007bff", color: "white" }} className="btn" onClick={this.handleShow}>&nbsp;&nbsp;&nbsp;Okay&nbsp;&nbsp;&nbsp;</button>
                                        {/* <button type="button" className="btn btn-primary">OK</button> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                }
                <SpinnerOverlay isLoading={sourceData && sourceData.progress}>
                    {
                        (sourceData && sourceData.records) ?
                            <Container>
                                <Row>
                                    {
                                        sourceData.records.unsubmitted ?
                                            <Col xs={6} md={12 / columns} className="count-warning">
                                                <div>
                                                    <Link to={quickLinks.unsubmitted || "/"}>
                                                        <p className="big-count">{sourceData.records.unsubmitted}</p>
                                                        draft
                                                    </Link>
                                                </div>
                                            </Col>
                                            : ""
                                    }
                                    {
                                        sourceData.records.submitted ?
                                            <Col xs={6} md={12 / columns} className="count-info" >
                                                <div>
                                                    <Link to={quickLinks.submitted || "/"}>
                                                        <p className="big-count">{sourceData.records.submitted}</p>
                                                        pending approval
                                                    </Link>
                                                </div>
                                            </Col>
                                            : ""
                                    }
                                    {
                                        sourceData.records.approved ?
                                            <Col xs={6} md={12 / columns} className="count-success" >
                                                <div>
                                                    <Link to={quickLinks.approved || "/"}>
                                                        <p className="big-count">{sourceData.records.approved}</p>
                                                        pending payment
                                                    </Link>
                                                </div>
                                            </Col>
                                            : ""
                                    }
                                    {
                                        sourceData.records.declined ?
                                            <Col xs={6} md={12 / columns} className="count-danger" >
                                                <div>
                                                    <Link to={quickLinks.declined || "/"}>
                                                        <p className="big-count">{sourceData.records.declined}</p>
                                                        declined
                                                    </Link>
                                                </div>
                                            </Col>
                                            : ""
                                    }
                                </Row>
                            </Container>
                            :
                            <span>Loading...</span>
                    }
                </SpinnerOverlay>
            </div>
        );
    }
}