import ScopeConstants from "constants/ScopeConstants"
import { ScopeShape } from "@gman/gman-redux"
import PropTypes from "prop-types"

export const ReportTimesheetHoursScope = {
    key: ScopeConstants.REPORT_TIMESHEET_HOURS,
    selector: (state) => state.reportTimesheetHours,
    tokenSelector: (state) => state.authentication.token
}

ReportTimesheetHoursScope.propTypes = PropTypes.shape(ScopeShape).isRequired