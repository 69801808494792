import ScopeConstants from "constants/ScopeConstants"
import { FormActionReducer } from "@gman/gman-redux"

export const ExpenseCalculationReducer = (
  state = {
    ...FormActionReducer.defaultPageState(),
    ...FormActionReducer.defaultSearchOptions("Description"),
    ...FormActionReducer.defaultRecordState(),
    ...FormActionReducer.defaultTabs(),
    calculation: [],
    isLoading: false,
    isStale: true
  },
  action) => {

  if (action.scope === ScopeConstants.EXPENSE_MINE) {
    if (action.type === "STALE_VALUE") {
      return {
        ...state,
        isStale: true
      }
    }
    if (action.type === "RECALCULATE_EXPENSE_COMMENCE") {
      return {
        ...state,
        isLoading: true,
        isStale: false
      }
    }
    if (action.type === "RECALCULATE_EXPENSE_COMPLETE") {
      let data = [...state.calculation];
      data[action.index] = action.calculation;
      return {
        calculation: data,
        isLoading: false,
        isStale: false
      }
    }
  }

  return state
}