import ScopeConstants from "constants/ScopeConstants";
import { FormActionReducer } from "@gman/gman-redux";

export const ClassificationsReducer = (
  state = {
    ...FormActionReducer.defaultPageState(),
    ...FormActionReducer.defaultSearchOptions("Name"),
    ...FormActionReducer.defaultRecordState()
  },
  action
) => {
  if (action.scope === ScopeConstants.CLASSIFICATION) {
    if (action.type === "SHOW_ALL") {
      return {
        ...state,
        searchOptions: {
          ...state.searchOptions,
          pageSize: state.searchOptions.totalRecords
        }
      };
    }
    return FormActionReducer.reduce(action, state);
  }

  return state;
};
