import React from "react";
import { withFormik } from "formik";
import * as Yup from "yup";
import { SelectOption } from "@gman/gman-redux";
import Icon from "components/common/Icon";
import RequiredStar from "components/common/RequiredStar";
import VariationStatusConstants from "constants/VariationStatusConstants";
import VariationTypeConstants from "constants/VariationTypeConstants";
import SelectStyles from "components/common/SelectStyles";

import {
    Button,
    ButtonGroup,
    Col,
    Container,
    FormGroup,
    Label,
    Row
} from "reactstrap";

const BaseForm = props => {
    const {
        values,
        dirty,
        errors,
        handleChange,
        handleBlur,
        isSubmitting,
        onSelectEntry,
        validateForm,
        customfilterFunc
    } = props;
    return (
        <div>
            <h6>
                <Icon folderOpen />
                Project Details
            </h6>
            <hr />
            <Container>
                <Row>
                    <Col xs={12}>
                        <FormGroup>
                            <RequiredStar />
                            <Label for="workItem">Project</Label>
                            <SelectOption
                                styles={SelectStyles.colourStyles()}
                                isClearable={true}
                                disabled={isSubmitting}
                                value={values.workItem}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                id="workItem"
                                invalid={errors.workItem && errors.workItem.length > 0}
                                stateFunc={state => {
                                    return { ...state.workItems };
                                }}
                                filterFunc={record => customfilterFunc ? customfilterFunc(record) : record}
                                valueFunc={record => record}
                                labelFunc={record => record.name + " (" + record.code + ")"}
                                sortFunc={(a, b) => a.type.localeCompare(b.type)}
                                matchFunc={record => record.workItemId}
                            />
                            {errors.workItem && ( <small className="text-danger">{errors.workItem}</small> )}
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col xs={12}>
                        <FormGroup>
                            <Label for="workItem.costCode">Cost Code</Label>
                            <SelectOption
                                isClearable={true}
                                disabled={!(values.workItem && values.workItem.costCodeGroupId)}
                                value={values.workItem && values.workItem.costCode}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                id="workItem.costCode"
                                invalid={errors.costCode && errors.costCode.length > 0}
                                stateFunc={state => { return { ...state.costCodes }; }}
                                valueFunc={record => ({
                                    costCodeId: record.costCodeId,
                                    costCodeGroupId: record.costCodeGroupId,
                                    code: record.code,
                                    description: record.description
                                })}
                                labelFunc={record => record.code + " (" + record.description + ")" }
                                filterFunc={record =>
                                    record.disabled === false &&
                                    record.costCodeGroupId ===
                                    (values.workItem && values.workItem.costCodeGroupId)
                                }
                                sortFunc={(a, b) => a.code.localeCompare(b.code)}
                                matchFunc={record => record.costCodeId}
                            />
                            {errors.variation && ( <small className="text-danger">{errors.variation}</small>)}
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col xs={12}>
                        <FormGroup>
                            <Label for="workItem.variation">Variation Code</Label>
                            <SelectOption
                                isClearable={true}
                                disableIfEmpty
                                disabled={isSubmitting}
                                value={values.workItem && values.workItem.variation}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                id="workItem.variation"
                                invalid={errors.variation && errors.variation.length > 0}
                                stateFunc={state => {
                                    return {
                                        ...state.variations,
                                        records: state.variations.records.filter(
                                            m => m.status !== VariationStatusConstants.CLOSED
                                        )
                                    };
                                }}
                                valueFunc={record => ({
                                    variationId: record.variationId,
                                    number: record.number,
                                    description: record.description
                                })}
                                labelFunc={record =>
                                    (record.type === VariationTypeConstants.VARIATION ? "Variation" : "") +
                                    (record.type === VariationTypeConstants.RISK ? "Risk" : "") + " " + record.number + " (" + record.description + ")"
                                }
                                filterFunc={record =>
                                    record.projectId ===
                                    (values.workItem && values.workItem.workItemId) &&
                                    record.status !== VariationStatusConstants.DRAFT
                                }
                                sortFunc={(a, b) => a.number.localeCompare(b.number)}
                                matchFunc={record => record.variationId}
                            />
                            {errors.variation && ( <small className="text-danger">{errors.variation}</small> )}
                        </FormGroup>
                    </Col>
                </Row>
            </Container>
            <Container>
                <ButtonGroup className="float-right">
                    <Button
                        outline
                        disabled={isSubmitting || !dirty}
                        color="warning"
                        onClick={() =>
                            validateForm().then(errors => {
                                if (!(Object.keys(errors).length > 0)) {
                                    onSelectEntry(values);
                                }
                            })
                        }>
                        Select
                    </Button>
                </ButtonGroup>
            </Container>
        </div>
    );
};

const WorkSelectForm = withFormik({
    displayName: "WorkSelectForm",
    enableReinitialize: true,
    initialValues: ({ value }) => ({
        number: value.project
    })
})(BaseForm);

export default WorkSelectForm;
