import React from "react"
import { withFormik } from 'formik';
import { AutoComplete } from "@gman/gman-react-bootstrap"
import Icon from "components/common/Icon"
import { SelectOption } from "@gman/gman-redux"
import {
    Button,
    Col,
    Container,
    Form,
    FormGroup,
    Input,
    Label,
    Row
} from "reactstrap"


const BaseForm = props => {
    const {
        values,
        touched,
        errors,
        handleChange,
        handleBlur,
        handleSubmit,
        handleReset
    } = props
    return (
        <Container>
            <Row>
                <Col>
                    <FormGroup>
                        <Label for="clientId">Search By</Label>
                        <Input
                            type="select"
                            name="searchBy"
                            id="searchBy"
                            value={values.searchBy}
                            onChange={handleChange}
                            onBlur={handleBlur}
                        >
                            <option value=''>Select</option>
                            <option value="Client">Client</option>
                            <option value="Project">Project</option>
                            <option value="CostCode">Cost Code</option>
                            <option value="User">Employee</option>
                            <option value="WeekEndingDate">Week Ending Date</option>
                            <option value="ChargeAmount">Charge Amount</option>
                            <option value="InvoiceNumber">Invoice Number</option>
                        </Input>
                    </FormGroup>
                </Col>
                {
                    values.searchBy == "Client" && <Col>
                        <FormGroup>
                            <Label for="clientId">Client</Label>
                            <SelectOption
                                isClearable={true}
                                value={values.clientId}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                id="clientId"
                                name="clientId"
                                stateFunc={(state) => { return { ...state.clients } }}
                                valueFunc={(record) => record.clientId}
                                labelFunc={(record) => record.name + " (" + record.code + ")"}
                                sortFunc={(a, b) => a.name.localeCompare(b.name)}
                            />
                        </FormGroup>
                    </Col>
                }
                {
                    values.searchBy == "Project" && <Col>
                        <FormGroup>
                            <Label for="projectId">Project</Label>
                            <SelectOption
                                isClearable={true}
                                value={values.projectId}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                id="projectId"
                                name="projectId"
                                stateFunc={(state) => {
                                    return {
                                        ...state.projectsAny
                                    }
                                }}
                                valueFunc={(record) => record.projectId}
                                labelFunc={(record) => record.name + " (" + record.code + ")"}
                                sortFunc={(a, b) => a.name.localeCompare(b.name)}
                            />
                        </FormGroup>
                    </Col>
                }
                {
                    values.searchBy == "CostCode" &&
                    <Col>
                        <FormGroup>
                            <Label for="costCodeId">Cost Code</Label>
                            <SelectOption
                                isClearable={true}
                                value={values.costCodeId}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                id="costCodeId"
                                name="costCodeId"
                                stateFunc={(state) => {
                                    return {
                                        ...state.costCodes
                                    }
                                }}
                                valueFunc={(record) => record.costCodeId}
                                labelFunc={record =>
                                    record.code + " (" + record.description + ")"
                                }
                                sortFunc={(a, b) => a.code.localeCompare(b.code)}
                            />
                        </FormGroup>
                    </Col>
                }
                {
                    values.searchBy == "User" &&
                    <Col>
                        <FormGroup>
                            <Label for="userId">User</Label>
                            <SelectOption
                                isClearable={true}
                                value={values.userId}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                id="userId"
                                name="userId"
                                stateFunc={(state) => { return { ...state.users } }}
                                valueFunc={(record) => record.userId}
                                labelFunc={(record) => record.firstName + " " + record.lastName}
                                filterFunc={(record) => (record.dateEnded === null)}
                                sortFunc={(a, b) => a.firstName.localeCompare(b.firstName)}
                            />
                        </FormGroup>
                    </Col>
                }
                {
                    values.searchBy == "WeekEndingDate" && 
                    <Col>
                        <FormGroup>
                            <Label for="weekEnding">Week Ending</Label>
                            <Input
                                type="date"
                                name="weekEnding"
                                id="weekEnding"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.weekEnding || ""}
                            />
                        </FormGroup>
                    </Col>
                }
                {
                    values.searchBy == "ChargeAmount" && 
                    <Col>
                        <FormGroup>
                            <Label for="chargeAmount">Charge Amount</Label>
                            <Input
                                type="number"
                                name="chargeAmount"
                                id="chargeAmount"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.chargeAmount || ""}
                            />
                        </FormGroup>
                    </Col>
                }
                {
                    values.searchBy == "InvoiceNumber" && 
                    <Col>
                        <FormGroup>
                            <Label for="invoiceNumber">Invoice Number</Label>
                            <Input
                                type="text"
                                name="invoiceNumber"
                                id="invoiceNumber"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.invoiceNumber || ""}
                            />
                        </FormGroup>
                    </Col>
                }
                {
                    <Col>
                        <FormGroup>
                            <Button outline color="primary" onClick={handleSubmit} style={{ marginTop: '1.1rem' }}>Search</Button>
                            <Button outline color="secondary"
                                style={{ marginTop: '1.1rem', marginLeft: '1rem' }}
                                onClick={
                                    (e) => {
                                        handleReset();
                                        handleSubmit(e);
                                    }
                                }>Clear</Button>
                        </FormGroup>
                    </Col>
                }

            </Row>
        </Container> 
    )
}

const FilterForm = withFormik({
    displayName: "EditDataFilterForm",
    enableReinitialize: true,
    initialValues: ({ value }) => ({
        searchBy: ''
    }),
    handleSubmit: (values, { props, setSubmitting }) => {
        let filterOption = {
            searchBy: values.searchBy,
            clientId: values.clientId
        }

        switch (values.searchBy) {
            case "Client":
                filterOption.searchValue = values.clientId;
                break;
            case "Project":
                filterOption.searchValue = values.projectId;
                break;
            case "CostCode":
                filterOption.searchValue = values.costCodeId;
                break;
            case "User":
                filterOption.searchValue = values.userId;
                break;
            case "WeekEndingDate":
                filterOption.searchValue = values.weekEnding;
                break;
            case "ChargeAmount":
                filterOption.searchValue = values.chargeAmount;
                break;
            case "InvoiceNumber":
                filterOption.searchValue = values.invoiceNumber;
                break;
        }
        props.handleSubmit(filterOption);
    }
})(BaseForm)

export default FilterForm