import ScopeConstants from "constants/ScopeConstants"
import { ScopeShape } from "@gman/gman-redux"
import PropTypes from "prop-types"

export const TimesheetTeamScope = {
    key: ScopeConstants.TIMESHEET_TEAM,
    selector: (state) => state.timesheetsTeam,
    tokenSelector: (state) => state.authentication.token,
    fetchEndpoint: "/api/Timesheet/SearchTeam/",
    singleEndpoint: "/api/Timesheet/GetTeam/",
    updateEndpoint: "/api/Timesheet/UpdateTeam",
    deleteEndpoint: "/api/Timesheet/DeleteTeam/",
    defaultRecord: {
        status: 0
    }
}

TimesheetTeamScope.propTypes = PropTypes.shape(ScopeShape).isRequired