import { connect } from "react-redux"
import { FormActions, FormEvents, ModalConstants,DispatchActions } from "@gman/gman-redux"
import UserList from "components/users/layout/UserList"
import UserCardExtended from "components/users/layout/UserCardExtended"
import { UserScope } from "components/users/redux/UserScope"
import { ManagerScope } from "components/users/redux/ManagerScope"

const mapDispatchToProps = (dispatch) => {
  var scope = UserScope
  return {
    ...FormEvents.getTableHeaderRowEvents(dispatch, scope),
    ...FormEvents.getFeedbackEvents(dispatch, scope),
    ...FormEvents.getPaginatorEvents(dispatch, scope),
    ...FormEvents.getModalEventsForModalsOnNewOnly(dispatch, scope),
    ...FormEvents.getTabEvents(dispatch, scope),
    ...FormEvents.getRecordStateEvents(dispatch, scope),
    onShowAllRecords: () => {
      dispatch({ ...DispatchActions.scopedDispatch(scope.key, "SHOW_ALL") });
      dispatch(FormActions.fetchRecords(scope));
    },
    onPageSizeChange: (pageSize) => {
      dispatch({...DispatchActions.scopedDispatch(scope.key, "CHANGE_PAGE_SIZE"), pageSize: pageSize });
      dispatch(FormActions.fetchRecords(scope));
    },
    confirmEvents: {
      onSubmitUpdate: record => {
        dispatch(FormActions.updateRecord(scope, record))
        dispatch(FormActions.hideModal(scope, ModalConstants.UPDATE))
        dispatch(FormActions.fetchRecords(ManagerScope))
      },
      onConfirmDelete: record => {
        dispatch(FormActions.deleteRecord(scope, record))
        dispatch(FormActions.hideModal(scope, ModalConstants.DELETE))
        dispatch(FormActions.fetchRecords(ManagerScope))
      }
    },
    onLoadDependentData: () => {
      dispatch(FormActions.fetchAll(ManagerScope))
    }
  }
}

const UserListContainer = connect(
  (state) => {
    return {
      sourceData: UserScope.selector(state)
    }
  },
  mapDispatchToProps
)(UserList)

const UserContainerExtended = connect(
  (state) => {
    return { sourceData: UserScope.selector(state) }
  },
  mapDispatchToProps
)(UserCardExtended)

export { UserListContainer, UserContainerExtended }