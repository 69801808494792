import ScopeConstants from "constants/ScopeConstants"
import { ScopeShape } from "@gman/gman-redux"
import PropTypes from "prop-types"

export const ReportUnpaidInvoicesScope = {
    key: ScopeConstants.REPORT_INVOICES_UNPAID,
    selector: (state) => state.reportUnpaidInvoices,
    tokenSelector: (state) => state.authentication.token
}

ReportUnpaidInvoicesScope.propTypes = PropTypes.shape(ScopeShape).isRequired