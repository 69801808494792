import React, { Component } from "react"
import {
    TimesheetMineExtendedContainer,
    TimesheetTeamExtendedContainer,
    TimesheetAnyExtendedContainer,
    TimesheetListMineContainer,
    TimesheetListTeamContainer,
    TimesheetListAnyContainer
} from "components/timesheets/redux/TimesheetContainers"

export class TimesheetMinePage extends Component { render() { return (<TimesheetListMineContainer status={this.props.match.params.status} />) } }
export class TimesheetTeamPage extends Component { render() { return (<TimesheetListTeamContainer status={this.props.match.params.status} />) } }
export class TimesheetAnyPage extends Component { render() { return (<TimesheetListAnyContainer status={this.props.match.params.status} />) } }

export class TimesheetMineExtendedPage extends Component { render() { return (<TimesheetMineExtendedContainer timesheetId={this.props.match.params.timesheetId} />) } }
export class TimesheetTeamExtendedPage extends Component { render() { return (<TimesheetTeamExtendedContainer timesheetId={this.props.match.params.timesheetId} />) } }
export class TimesheetAnyExtendedPage extends Component { render() { return (<TimesheetAnyExtendedContainer timesheetId={this.props.match.params.timesheetId} />) } }