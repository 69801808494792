import React, { Component } from "react"
import {
    Card,
    CardBody,
    CardHeader,
    Container,
    Col,
    Nav,
    NavItem,
    NavLink,
    Row,
    TabContent,
    TabPane
} from "reactstrap"
import UserFormBasic from "components/users/form/UserFormBasic"
import { SpinnerOverlay } from "@gman/gman-react-bootstrap"
import FeedbackAlert from "components/common/FeedbackAlert"
import Icon from "components/common/Icon"
import { RestrictedComponent } from "components/authentication/redux/AuthorizationWrappers"
import { UserAuthenticationContainer } from "components/userAuthentications/redux/UserAuthenticationContainers"
import { UserClassificationListContainer } from "components/userClassifications/redux/UserClassificationContainers"

const UserAuthenticationTab = RestrictedComponent((props) =>
    <NavItem>
        <NavLink onClick={props.onChangeTab}>
            <Icon authorization />
            Authorization
        </NavLink>
    </NavItem>, p => p.canWriteUsers)

class UserCardExtended extends Component {

    componentDidMount() {
        if (this.props.userId) {
            this.props.recordStateEvents.onLoadSingle(this.props.userId)
            this.props.onLoadDependentData()
        }
    }

    componentWillUnmount = () => {
        this.props.feedbackEvents.onFlushFeedback()
    }

    render() {
        var sourceData = this.props.sourceData
        var confirmEvents = this.props.confirmEvents
        var feedbackEvents = this.props.feedbackEvents
        var tabEvents = this.props.tabEvents
        return (
            <Card>
                <CardHeader>
                    <h6>
                        <Icon person />
                        {
                            sourceData.recordToUpdate ?
                                sourceData.recordToUpdate.firstName + " " +
                                sourceData.recordToUpdate.lastName :
                                "Unknown"
                        }
                    </h6>
                </CardHeader>
                <CardBody>
                    <FeedbackAlert {...feedbackEvents} feedback={sourceData.feedback} />
                    <Container>
                        <Row>
                            <Col>
                                <Nav tabs>
                                    <NavItem>
                                        <NavLink onClick={() => tabEvents.onChangeTab("1")}>
                                            <Icon folderOpen />Details
                                        </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink onClick={() => tabEvents.onChangeTab("2")}>
                                            <Icon school />Classifications
                                        </NavLink>
                                    </NavItem>
                                    <UserAuthenticationTab onChangeTab={() => tabEvents.onChangeTab("3")} />
                                </Nav>
                            </Col>
                        </Row>
                    </Container>
                    <div>
                        <TabContent activeTab={sourceData.activeTab}>
                            <TabPane tabId="1">
                                <br />
                                <Container>
                                    <Row>
                                        <Col>
                                            <SpinnerOverlay isLoading={sourceData.inProgress}>
                                                <UserFormBasic {...confirmEvents} {...sourceData.recordToUpdate} />
                                            </SpinnerOverlay>
                                        </Col>
                                    </Row>
                                </Container>
                            </TabPane>
                            <TabPane tabId="2">
                                <br />
                                <Container>
                                    <Row>
                                        <Col>
                                            <UserClassificationListContainer userId={this.props.userId} />
                                        </Col>
                                    </Row>
                                </Container>
                            </TabPane>
                            <TabPane tabId="3">
                                <br />
                                <Container>
                                    <Row>
                                        <Col>
                                            <UserAuthenticationContainer userId={this.props.userId} />
                                        </Col>
                                    </Row>
                                </Container>
                            </TabPane>
                        </TabContent>
                    </div>
                </CardBody>
            </Card>
        )
    }
}

export default UserCardExtended