import React from "react"
import { Link } from "react-router-dom"
import Moment from "moment"
import Currency from "components/common/Currency"
import Icon from "components/common/Icon"
import { LookupLabel } from "@gman/gman-redux"
import InvoiceTimingTypeConstants from "constants/InvoiceTimingTypeConstants";

import {
    Col,
    Container,
    Input,
    Label,
    Row
} from "reactstrap"

const label = { xs: 12, md: 3 }
const field = { xs: 12, md: 9 }


const getInvoiceBeforeAfter = invoiceDay => {
    switch (invoiceDay) {
        case 0:
            return "Before";
        case 1:
            return "After";
        default:
            return "";
    }
}

const getInvoiceDayOfMonth = invoiceDay => {
    switch (invoiceDay) {
        case 0:
            return "first";
        case 1:
            return "second";
        case 2:
            return "third";
        case 3:
            return "fourth";
        case 4:
            return "last";
        default:
            return "";
    }
}

const getInvoiceDay = invoiceDay => {
    switch (invoiceDay) {
        case 0:
            return "Sunday";
        case 1:
            return "Monday";
        case 2:
            return "Tuesday";
        case 3:
            return "Wednesday";
        case 4:
            return "Thursday";
        case 5:
            return "Friday";
        case 6:
            return "Saturday";
        default:
            return "";
    }
}


const ProjectDisplayExtended = props => {
    const { values } = props
    console.log(values);
    var readOnlyClass = "form-control-plaintext form-control read-only-label"
    return (
        <div>
            {values.proposalId ?
                proposalLink(values.proposalId)
                :
                ""}
            <h6>
                <Icon folderOpen />
                Project Details
                </h6>
            <hr />
            <Container>
                <Row>
                    <Col {...label} className="align-self-center">
                        <Label for="name">Project Name</Label>
                    </Col>
                    <Col {...field}>
                        <Input className={readOnlyClass} value={values.name} />
                    </Col>
                    <Col {...label} className="align-self-center">
                        <Label for="code">Project Code</Label>
                    </Col>
                    <Col {...field}>
                        <Input className={readOnlyClass} value={values.code} />
                    </Col>
                    <Col {...label} className="align-self-center">
                        <Label for="projectManagerId">Project Manager</Label>
                    </Col>
                    <Col {...field}>
                        <LookupLabel
                            className={readOnlyClass}
                            value={values.projectManagerId}
                            stateFunc={(state) => { return { ...state.managers } }}
                            valueFunc={(record) => record.userId}
                            labelFunc={(record) => record.firstName + " " + record.lastName}
                        />
                    </Col>
                    <Col {...label} className="align-self-center">
                        <Label for="projectDirectorId">Project Director</Label>
                    </Col>
                    <Col {...field}>
                        <LookupLabel
                            className={readOnlyClass}
                            value={values.projectDirectorId}
                            stateFunc={(state) => { return { ...state.managers } }}
                            valueFunc={(record) => record.userId}
                            labelFunc={(record) => record.firstName + " " + record.lastName}
                        />
                    </Col>
                    <Col {...label} className="align-self-center">
                        <Label for="officeId">CMP Office</Label>
                    </Col>
                    <Col {...field}>
                        <LookupLabel
                            className={readOnlyClass}
                            value={values.officeId}
                            stateFunc={(state) => { return { ...state.offices } }}
                            valueFunc={(record) => record.officeId}
                            labelFunc={(record) => record.name}
                        />
                    </Col>
                </Row>
            </Container >
            <h6>
                <Icon client />
                Client Details
                </h6>
            <hr />
            <Container>
                <Row>
                    <Col {...label} className="align-self-center">
                        <Label for="clientId">Client</Label>
                    </Col>
                    <Col {...field}>
                        <LookupLabel
                            className={readOnlyClass}
                            value={values.clientId}
                            stateFunc={(state) => { return { ...state.clients } }}
                            valueFunc={(record) => record.clientId}
                            labelFunc={(record) => record.name + " (" + record.code + ")"}
                        />
                    </Col>
                    <Col {...label} className="align-self-center">
                        <Label for="clientProjectNumber">Client Project Number</Label>
                    </Col>
                    <Col {...field}>
                        <Input className={readOnlyClass} value={values.clientProjectNumber} />
                    </Col>
                    <Col {...label} className="align-self-center">
                        <Label for="clientProjectManager">Client Project Manager</Label>
                    </Col>
                    <Col {...field}>
                        <Input className={readOnlyClass} value={values.clientProjectManager} />
                    </Col>
                    <Col {...label} className="align-self-center">
                        <Label for="clientPurchaseOrder">Client Purchase Order</Label>
                    </Col>
                    <Col {...field}>
                        <Input className={readOnlyClass} value={values.clientPurchaseOrder} />
                    </Col>
                    <Col {...label} className="align-self-center">
                        <Label for="markup">Subcontractor Mark up (%)</Label>
                    </Col>
                    <Col {...field}>
                        <Input className={readOnlyClass} value={values.markup} />
                    </Col>
                </Row>
            </Container >
            <h6>
                <Icon clock />
                Fee and Program
                </h6>
            <hr />
            <Container>
                <Row>
                    <Col {...label} className="align-self-center">
                        <Label for="costCodeGroupId">Cost Code Group</Label>
                    </Col>
                    <Col {...field}>
                        <LookupLabel
                            className={readOnlyClass}
                            value={values.costCodeGroupId}
                            stateFunc={(state) => { return { ...state.costCodeGroupsAll } }}
                            valueFunc={(record) => record.costCodeGroupId}
                            labelFunc={(record) => record.name + (record.description ? " (" + record.description + ")" : "")}
                        />
                    </Col>
                    <Col {...label} className="align-self-center">
                        <Label for="contractType">Contract Type</Label>
                    </Col>
                    <Col {...field}>
                        <LookupLabel
                            className={readOnlyClass}
                            value={values.contractType}
                            stateFunc={(state) => { return { ...state.contractTypes } }}
                            valueFunc={(record) => record.typeId}
                            labelFunc={(record) => record.description}
                        />
                    </Col>
                    <Col {...label} className="align-self-center">
                        <Label for="contractType">Invoice Timing</Label>
                    </Col>
                    <Col {...field}>
                        {
                            values.invoiceTimingType != null ?
                            values.invoiceTimingType == InvoiceTimingTypeConstants.NumberedDayOfMonth ?
                                <Input className={readOnlyClass} value={"Day " + values.invoiceNumberdDay + " Of every month"} /> :
                                    <Input className={readOnlyClass} value={(values.invoiceDayBeforeAfter ? values.invoiceDayBeforeAfter + " days " + getInvoiceBeforeAfter(values.invoiceBeforeAfter) + " " : "" ) + getInvoiceDayOfMonth(values.invoiceDayOfMonth) + " " + getInvoiceDay(values.invoiceWeekDayOfMonth) + " Of every month"} /> :
                                        <Input className={readOnlyClass} value="" />
                        }
                    </Col>
                    <Col {...label} className="align-self-center">
                        <Label for="LabourFee">CMP Labour Fee</Label>
                    </Col>
                    <Col {...field}>
                        {/* <Label className="customLabel" for="LabourFee">{values.labourFee && (Currency(values.labourFee))}</Label> */}
                        <Input className={readOnlyClass} value={values.labourFee && (Currency(values.labourFee))} />
                    </Col>
                    <Col {...label} className="align-self-center">
                        <Label for="Expenses">Expenses</Label>
                    </Col>
                    <Col {...field}>
                        <Input className={readOnlyClass} value={values.expenses && (Currency(values.expenses))} />
                    </Col>
                    <Col {...label} className="align-self-center">
                        <Label for="originalSubcontractorFee">Subcontractors Fee</Label>
                    </Col>
                    <Col {...field}>
                        <Input className={readOnlyClass} value={values.originalSubcontractorFee && (Currency(values.originalSubcontractorFee))} />
                    </Col>
                    <Col {...label} className="align-self-center">
                        <Label for="originalFee">Original Fee - Total</Label>
                    </Col>
                    <Col {...field}>
                        <Input className={readOnlyClass} value={values.originalFee && (Currency(values.originalFee))} />
                    </Col>
                    
                </Row>
                <Row>
                    <Col {...label} className="align-self-center">
                        <Label for="startDate">Start Date</Label>
                    </Col>
                    <Col {...field}>
                        <Input className={readOnlyClass} value={values.startDate && Moment(values.startDate).format("DD/MM/YYYY")} />
                    </Col>
                    <Col {...label} className="align-self-center">
                        <Label for="endDate">End Date (anticipated)</Label>
                    </Col>
                    <Col {...field}>
                        <Input className={readOnlyClass} value={values.endDate && Moment(values.endDate).format("DD/MM/YYYY")} />
                    </Col>
                    <Col {...label}>
                        <Label className="mt-2" for="comments">Comments</Label>
                    </Col>
                    <Col {...field}>
                        <Input rows={27} type="textarea" className={readOnlyClass} value={values.comments} />
                    </Col>
                </Row>
            </Container>
        </div >
    )
}

const proposalLink = (proposalId) => {
    return (
        <div>
            <h6>
                <Icon proposal />
                <Link to={"/proposalSheet/" + proposalId}>Associated Proposal</Link>
            </h6>
            <hr />
        </div>
    )
}

export default ProjectDisplayExtended