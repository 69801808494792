import React, { Component } from "react"
import { ChargeRateListContainer, ChargeRateListExtendedContainer } from "components/chargeRates/redux/ChargeRateContainers"
import ChargeRateCardBasic from "components/chargeRates/layout/ChargeRateCardBasic"
import ChargeRateCardBasicExtended from "components/chargeRates/layout/ChargeRateCardBasicExtended"

export class ChargeRatePageBasic extends Component {
    render() { return (<ChargeRateCardBasic props={this.props}/>) }
}

export class ChargeRateCardBasicPageExtended extends Component {
    render() {
        return (<ChargeRateCardBasicExtended props={this.props} />)
    }
}

export class ChargeRatePage extends Component {
    render() { return (<ChargeRateListContainer />) }
}

export class ChargeRateListExtended extends Component {
    render() { return (<ChargeRateListExtendedContainer />) }
}
