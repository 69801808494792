import React, { useState } from "react"
import { Button, ButtonGroup, Input } from "reactstrap"
import { FormModal } from "@gman/gman-react-bootstrap"
import { ModalActions } from "@gman/gman-redux"
import Moment from "moment"
import WorkSelectForm from "components/common/WorkSelectForm"
import WorkItemCell from "components/common/WorkItemCell"
import Icon from "components/common/Icon"
import { withFormik } from "formik"
import ScopeConstants from "constants/ScopeConstants";
import ExpenseStatusBadge from "components/common/ExpenseStatusBadge"
import ClientInvoiceStatusBadge from "components/common/ClientInvoiceStatusBadge"
import InvoiceStatusConstants from "constants/InvoiceStatusConstants"

const BaseForm = props => {
    const {
        values,
        modals,
        onShowModal,
        onHideModal,
        dirty,
        errors,
        setFieldValue,
        handleChange,
        handleBlur,
        handleWorkItemChange,
        handleFormChange,
        handleCostItemsStausChange,
        isOpneInModal
    } = props;

    return (
        <tr>
            <td style={{ whiteSpace: 'pre' }}> {values.user.firstName + ' ' + values.user.lastName} </td>
            <td style={{ whiteSpace: 'pre' }}> {Moment(values.effectiveDate).format("DD/MM/YYYY")}</td>
            <td className="weekend">
                <div style={{ width: '325px' }}>
                    {values.workItem ? <WorkItemCell workItem={values.workItem} /> : ""}
                    <Button 
                    disabled={values.clientInvoice && values.clientInvoice.status != InvoiceStatusConstants.DRAFT}
                       
                       block color="light" size="sm" onClick={() => onShowModal("SELECT_WORK_ITEM")}>
                        <Icon book />
                        Select Project
                    </Button>
                </div>
            </td>
            <td style={{ whiteSpace: 'pre' }}> {values.project != null ? values.project.clientProjectNumber : ''}</td>
            <td style={{ whiteSpace: 'pre' }}> {values.description} </td>
            <td style={{ whiteSpace: 'pre' }}> {values.expenseType.description} </td>
            <td style={{ textAlign: 'center' }}>
                <Input
                    onChange={
                        (e) => {
                            handleChange(e);
                            handleFormChange(e);
                        }
                    }
                    disabled={values.clientInvoice && values.clientInvoice.status != InvoiceStatusConstants.DRAFT}

                    checked={values.chargeClient}
                    value={values.chargeClient}
                    onBlur={handleBlur}
                    type="checkbox"
                    id="chargeClient"
                    name="chargeClient"
                    style={{ position: "initial" }}
                />
            </td>
            <td style={{ whiteSpace: 'pre' }}> {values.reimburseMe == true ? "Yes" : "No"} </td>
            <td style={{ whiteSpace: 'pre' }}> {values.amountCalculated} </td>
            <td>
                <ExpenseStatusBadge value={values.status} />
            </td>
            <td className="text-center">
                <Input
                    type="radio"
                    value={0}
                    style={{ position: 'unset', margin: 0 }}
                    name="costItemStatus"
                    disabled={values.clientInvoice && values.clientInvoice.status != InvoiceStatusConstants.DRAFT}
                    onChange={
                        (e) => {
                            handleCostItemsStausChange(e);
                            handleChange(e);
                        }
                    }
                    checked={values.costItemStatus == 0 ? true : false}
                />
            </td>
            <td className="text-center">
                <Input
                    type="radio"
                    value={1}
                    style={{ position: 'unset', margin: 0 }}
                    name="costItemStatus"
                    disabled={values.clientInvoice && values.clientInvoice.status != InvoiceStatusConstants.DRAFT}
                    onChange={
                        (e) => {
                            handleCostItemsStausChange(e);
                            handleChange(e);
                        }
                    }
                    checked={values.costItemStatus == 1 ? true : false}
                />
            </td>
            <td className="text-center">
                <Input
                    type="radio"
                    value={2}
                    style={{ position: 'unset', margin: 0 }}
                    name="costItemStatus"
                    disabled={values.clientInvoice && values.clientInvoice.status != InvoiceStatusConstants.DRAFT}
                    onChange={
                        (e) => {
                            handleCostItemsStausChange(e);
                            handleChange(e);
                        }
                    }
                    checked={values.costItemStatus == 2 ? true : false}
                />
            </td>
            <td>{values.clientInvoice ? values.clientInvoice.number : ""}</td>
            <td>
                {
                    values.clientInvoice ? <ClientInvoiceStatusBadge value={values.clientInvoice.status} /> : ""
                }
            </td>
            <FormModal
                size="lg"
                isVisible={ModalActions.IsVisible(modals, "SELECT_WORK_ITEM")}
                onHide={() => onHideModal("SELECT_WORK_ITEM")}
                title={"Select Project"}>
                <WorkSelectForm
                    {...ConstructWorkItem(values.workItem)}
                    onSelectEntry={
                        (selection) => {
                            var workItem = selection.workItem;
                            setFieldValue("workItem", workItem);
                            onHideModal("SELECT_WORK_ITEM");
                            handleWorkItemChange(workItem);
                        }
                    }
                    customfilterFunc={record =>
                        record.type !== ScopeConstants.LEAVE ||
                        (record.type === ScopeConstants.LEAVE &&
                            (record.code === "MISC" ||
                                record.code === "R&D ACTIVITY"))
                    }
                />
            </FormModal>
        </tr>

    )
}

const ConstructWorkItem = (workItem) => {
    return {
        workItem: {
            workItemId: workItem && workItem.workItemId,
            costCodeGroupId: workItem && workItem.costCodeGroupId,
            type: workItem && workItem.type,
            name: workItem && workItem.name,
            variation: workItem && (workItem.variation),
            costCode: {
                costCodeId: workItem && workItem.costCode && (workItem.costCode.costCodeId),
                costCodeGroupId: workItem && workItem.costCodeGroupId
            }
        }
    }
}

const ExpenseEditRowForm = withFormik({
    displayName: "ExpenseEditRowForm",
    enableReinitialize: true,
    handleSubmit: (values, { props, setSubmitting }) => {
        setSubmitting(false)
    }
})(BaseForm)

export default ExpenseEditRowForm