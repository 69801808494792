import React, { Component } from "react"
import { Redirect } from "react-router"
import Moment from "moment"
import TableHeaderRow from "components/common/TableHeaderRow"
import PropTypes from "prop-types"
import { Card, CardBody, CardHeader, Table, FormGroup, Button } from "reactstrap"
import OutstandingTimesheetRow from "components/outstandingTimesheets/layout/OutstandingTimesheetRow"
import { TimesheetShape } from "shapes/TimesheetShape"
import { ModalActions } from "@gman/gman-redux"
import FeedbackAlert from "components/common/FeedbackAlert"
import {
    FormModal,
    Paginator,
    SpinnerOverlay
} from "@gman/gman-react-bootstrap"

class OutstandingTimesheetList extends Component {

    componentDidMount() {
        this.props.recordStateEvents.onClearSearch()
        this.props.onLoadDependentData()
    }

    componentWillUnmount = () => {
        this.props.feedbackEvents.onFlushFeedback()
        this.props.redirectEvents.onClearRedirect()
    }

    render() {
        var sourceData = this.props.sourceData
        var modals = sourceData.modals
        var confirmEvents = this.props.confirmEvents
        var feedbackEvents = this.props.feedbackEvents
        var modalEvents = this.props.modalEvents
        var paginatorEvents = this.props.paginatorEvents
        var tableHeaderRowEvents = this.props.tableHeaderRowEvents

        if (sourceData.redirect) {
            return (<Redirect to={sourceData.redirect} />)
        }

        var recordToUpdate = sourceData.recordToUpdate

        return (
            <Card>
                <CardHeader>
                    <h6>Outstanding Timesheets</h6>
                </CardHeader>
                <CardBody>
                    <FeedbackAlert {...feedbackEvents} feedback={sourceData.feedback} />
                    <SpinnerOverlay isLoading={sourceData.inProgress}>
                        <Table hover size="sm">
                            <thead>
                                <TableHeaderRow
                                    onSortBy={tableHeaderRowEvents.onSortBy}
                                    disabled={sourceData.inProgress}
                                    headers={[
                                        { sortBy: "WeekEnding", label: "Week Ending" },
                                        { sortBy: "Status", label: "Status" },
                                        { sortBy: "FirstName", label: "User" }
                                    ]}
                                />
                            </thead>
                            <tbody>
                                {
                                    sourceData.records.map((record, index) => (
                                        <OutstandingTimesheetRow
                                            suffix={this.props.suffix}
                                            key={index}
                                            {...record}
                                            onShowCreate={() => modalEvents.onShowNew({...record})}
                                            disabled={sourceData.inProgress} />
                                    ))
                                }
                            </tbody>
                        </Table>
                        <Paginator {...paginatorEvents} searchOptions={sourceData.searchOptions} />
                    </SpinnerOverlay>
                </CardBody>
                <FormModal
                    isVisible={ModalActions.IsUpdateVisible(modals)}
                    onHide={modalEvents.onHideEdit}
                    title={"Confirm Create Timesheet"}>
                    <FormGroup>
                        <h6>Confirm timesheet creation for the following:</h6>
                        <hr/>
                        {
                            recordToUpdate ?
                                <div>
                                    <b>Date:</b> {Moment(recordToUpdate.weekEnding).format("DD/MM/YYYY")}
                                    <hr/>
                                    {
                                        recordToUpdate.user ?
                                            <div>
                                                <b>User:</b> {recordToUpdate.user.firstName + " " + recordToUpdate.user.lastName}
                                            </div>
                                            : ""
                                    }
                                    <hr/>
                                </div>
                                : ""
                        }
                        <Button
                            className="float-right"
                            color="success"
                            onClick={() => confirmEvents.onSubmitUpdate(
                                {
                                    weekEnding: recordToUpdate.weekEnding,
                                    userId: recordToUpdate.userId
                                }
                            )}
                        >
                            Confirm
                        </Button>
                    </FormGroup>
                </FormModal>
            </Card >
        )
    }
}

OutstandingTimesheetList.propTypes = {
    sourceData: PropTypes.shape({
        records: PropTypes.arrayOf(TimesheetShape)
    })
}

export default OutstandingTimesheetList