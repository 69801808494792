import React from "react"
import { connectedRouterRedirect } from "redux-auth-wrapper/history4/redirect"
import locationHelperBuilder from "redux-auth-wrapper/history4/locationHelper"
import connectedAuthWrapper from "redux-auth-wrapper/connectedAuthWrapper"

const locationHelper = locationHelperBuilder({})

const RestrictedRoute = (SelectorFunc) => connectedRouterRedirect({
    redirectPath: state => (state.authentication && state.authentication.isLoggedIn) ? "/" : "/login",
    allowRedirectBack: true,
    authenticatedSelector: state => PermissionSelector(state, SelectorFunc),
    wrapperDisplayName: "RestrictedRoute"
})

const ProjectManagerRoute = RestrictedRoute(p => p.canReadWorkItems)
const UserManagerRoute = RestrictedRoute(p => p.canReadUsers)

const UserIsAuthenticated = connectedRouterRedirect({
    redirectPath: "/login",
    authenticatedSelector: state => state.authentication.isLoggedIn,
    wrapperDisplayName: "UserIsAuthenticated"
})

const UserIsNotAuthenticated = connectedRouterRedirect({
    redirectPath: (state, ownProps) => locationHelper.getRedirectQueryParam(ownProps) || "/",
    allowRedirectBack: false,
    authenticatedSelector: state => (!state.authentication) || (!state.authentication.isLoggedIn),
    wrapperDisplayName: "UserIsNotAuthenticated"
})

const RestrictedComponent = (Component, SelectorFunc, FailureComponent = () => <div />) => connectedAuthWrapper({
    authenticatedSelector: state => PermissionSelector(state, SelectorFunc),
    wrapperDisplayName: "RestrictedComponent",
    FailureComponent: FailureComponent
})(Component)

const PermissionSelector = (state, flagSelector) => {
    if (state !== null) {
        return state !== null &&
            state.authentication !== null &&
            state.authentication.permissions !== null &&
            flagSelector(state.authentication.permissions) === true
    }
    return false;
}

function WrapRestriction(Component, selector, FailureComponent) { return RestrictedComponent(Component, selector, FailureComponent) }

export {
    RestrictedComponent,
    RestrictedRoute,
    ProjectManagerRoute,
    UserManagerRoute,
    UserIsAuthenticated,
    UserIsNotAuthenticated,
    WrapRestriction
}