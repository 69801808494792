import React from "react"
import Dropzone from "react-dropzone"
import { Button, Container, Row, Col } from "reactstrap"
import FeedbackAlert from "components/common/FeedbackAlert"
import { SpinnerOverlay } from "@gman/gman-react-bootstrap"
import { FeedbackConstants } from "@gman/gman-redux"

const FileUploader = props => {
    const {
        feedbackEvents,
        feedback,
        uploadedFiles,
        onUploadAccept,
        onUploadReject,
        onUploadSubmit,
        multiple,
        maxSize,
        accept,
        isUploading,
        uploadFeedback
    } = props
    var containsFile = uploadedFiles && uploadedFiles.length > 0
    return (
        <SpinnerOverlay isLoading={isUploading}>
            <Container>
                {
                    feedback && feedbackEvents ?
                        <FeedbackAlert {...feedbackEvents} feedback={feedback} />
                        : ""
                }
                <Row>
                    <Col md={12} xl={6}>
                        <Dropzone
                            className="dropZone"
                            activeClassName="dropZoneActive"
                            multiple={multiple}
                            accept={accept}
                            maxSize={maxSize}
                            onDropAccepted={onUploadAccept}
                            onDropRejected={() => onUploadReject("The file could not be uploaded. Please ensure the file type is of the correct extension.")}
                        >
                            {props.children}
                        </Dropzone>
                    </Col>
                    <Col md={12} xl={6}>
                        {
                            uploadedFiles ?
                                uploadedFiles.map((uploadedFile, index) => (
                                    <div className="dropZoneReport" key={index}>
                                        <a target="_blank" href={uploadedFile.preview}>
                                            <div>{uploadedFile.name}</div>
                                            {
                                                isImage(uploadedFile.type) ?
                                                    <img className="dropZoneThumbnail" src={uploadedFile.preview} />
                                                    : ""
                                            }
                                        </a>
                                        {
                                            uploadFeedback && uploadFeedback.length > index ?
                                                <div>
                                                    <br />
                                                    <FeedbackAlert
                                                        feedback={
                                                            [
                                                                {
                                                                    status: uploadFeedback[index] && uploadFeedback[index].uploadSuccess ? FeedbackConstants.SUCCESS : FeedbackConstants.ERROR,
                                                                    messages: [uploadFeedback[index] && uploadFeedback[index].message]
                                                                }
                                                            ]
                                                        }
                                                    />
                                                    <hr />
                                                </div>
                                                : ""
                                        }
                                    </div>
                                ))
                                :
                                <div className="dropZoneReport">
                                    No file uploaded.
                            </div>
                        }
                    </Col>
                </Row>
                <Button
                    outline
                    className="float-right"
                    disabled={isUploading || !containsFile}
                    color="success"
                    onClick={onUploadSubmit}>Submit</Button>
            </Container>
        </SpinnerOverlay>
    )
}

function isImage(type) {
    switch (type) {
        case "image/jpeg":
        case "image/png":
        case "image/bmp":
            return true
        default:
            return false
    }
}

export default FileUploader