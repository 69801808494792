import { connect } from "react-redux"
import ClientList from "components/clients/layout/ClientList"
import ClientCardExtended from "components/clients/layout/ClientCardExtended"
import { ClientScope } from "components/clients/redux/ClientScope"
import { FormActions, FormEvents, ModalConstants, DispatchActions } from "@gman/gman-redux"

const mapDispatchToProps = (dispatch) => { return mapDispatchToPropsClient(dispatch, ClientScope) }
const mapDispatchToPropsClient = (dispatch,scope) => {
  return {
    ...FormEvents.getConfirmEventsForModalsOnNewOnly(dispatch, scope),
    ...FormEvents.getFeedbackEvents(dispatch, scope),
    ...FormEvents.getModalEventsForModalsOnNewOnly(dispatch, scope),
    ...FormEvents.getPaginatorEvents(dispatch, scope),
    ...FormEvents.getRecordStateEvents(dispatch, scope),
    ...FormEvents.getTableHeaderRowEvents(dispatch, scope),
    ...FormEvents.getTabEvents(dispatch, scope),
    onPageSizeChange: (pageSize) => {
      dispatch({...DispatchActions.scopedDispatch(scope.key, "CHANGE_PAGE_SIZE"), pageSize: pageSize });
      dispatch(FormActions.fetchRecords(scope));
    },
  }
}

const ClientContainerExtended = connect(
  state => { return { sourceData: ClientScope.selector(state) } },
  mapDispatchToProps
)(ClientCardExtended)

const ClientListContainer = connect(
  (state) => {
    return {
      sourceData: ClientScope.selector(state)
    }
  },
  mapDispatchToProps
)(ClientList)

export { ClientContainerExtended, ClientListContainer }