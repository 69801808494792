import ScopeConstants from "constants/ScopeConstants"
import { FormActionReducer } from "@gman/gman-redux"

export const OutstandingTimesheetsTeamReducer = (
    state = {
        ...FormActionReducer.defaultPageState(),
        ...FormActionReducer.defaultSearchOptions("WeekEnding", 10),
        ...FormActionReducer.defaultRecordState()
    },
    action) => {

    if (action.scope === ScopeConstants.OUTSTANDING_TIMESHEET_TEAM) {
        return FormActionReducer.reduce(action, state)
    }

    return state
}