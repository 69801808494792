import ScopeConstants from "constants/ScopeConstants"
import { FormActionReducer, FormActionConstants } from "@gman/gman-redux"

export const CostRateReducer = (
    state = {
        ...FormActionReducer.defaultPageState(),
        ...FormActionReducer.defaultRecordState(),
        ...FormActionReducer.defaultSearchOptions("", 20),
        ...FormActionReducer.defaultTabs()
    },
    action) => {
    if (action.scope === ScopeConstants.COST_RATE) {
        if (action.type === FormActionConstants.RECEIVE_RECORDS) {
            return {
                ...state,
                currentRatesType: action.currentRatesType,
                usingRatesType: action.usingRatesType,
                records: action.records,
                searchOptions: {
                    ...state.searchOptions,
                    totalRecords: action.totalRecords
                }
            }
        }
        if (action.type === FormActionConstants.UPDATE_SORT) {
            return {
                ...state,
                searchOptions: {
                    ...state.searchOptions,
                    column: action.column
                }
            }
        }

        return FormActionReducer.reduce(action, state)
    }

    return state
}