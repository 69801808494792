import { connect } from "react-redux"
import ClientInvoiceList from "components/clientInvoices/layout/ClientInvoiceList"
import ClientInvoiceCardExtended from "components/clientInvoices/layout/ClientInvoiceCardExtended"
import { ClientScope } from "components/clients/redux/ClientScope"
import { StaticProjectScope } from "components/static/redux/StaticProjectScope"
import { ClientInvoiceScope } from "components/clientInvoices/redux/ClientInvoiceScope"
import InvoiceStatusConstants from "constants/InvoiceStatusConstants"
import { DispatchActions, FormActions, FormEvents, FetchActions } from "@gman/gman-redux"
import { TimesheetEntryEditListScope } from "components/timesheetEntries/redux/TimesheetEntryEditListScope";
import FileSaver from "file-saver"
import { ProjectAnyScope } from "components/projects/redux/ProjectAnyScope"
import ProjectStatusConstants from "constants/ProjectStatusConstants"

const mapDispatchToProps = (dispatch) => {
    var scope = ClientInvoiceScope
    return {
        ...FormEvents.getConfirmEventsForModalsOnNewOnly(dispatch, scope),
        ...FormEvents.getFeedbackEvents(dispatch, scope),
        ...FormEvents.getModalEventsForModalsOnNewOnly(dispatch, scope),
        ...FormEvents.getPaginatorEvents(dispatch, scope),
        ...FormEvents.getRecordStateEvents(dispatch, scope),
        ...FormEvents.getTableHeaderRowEvents(dispatch, scope),
        //...FormEvents.getUploadFileEvents(dispatch, scope),
        ...FormEvents.getRedirectEvents(dispatch, scope),
        uploadEvents: {
            ...FormEvents.getUploadFileEvents(dispatch, scope).uploadEvents,
            onUploadAccept: fileData => {
                dispatch({ ...DispatchActions.scopedDispatch(scope.key, "CLEAR_UPLOAD_FEEDBACK") })
                dispatch(FormActions.acceptUpload(scope, fileData))
            }
        },
        onLoadDependentData: () => {
            dispatch(FormActions.fetchAll(ClientScope))
            const {
                pathname
            } = window.location
            let urlParams = pathname.split('/')[1];
            console.log(pathname, pathname.split('/'), urlParams, 'urlParams');
            if (urlParams != 'projectSheet') {
                dispatch(FormActions.fetchAll(StaticProjectScope))
            }
            // dispatch(FormActions.fetchAll(StaticProjectScope))
            dispatch(FormActions.fetchAll(ProjectAnyScope, { status: ProjectStatusConstants.INPROGRESS }))
        },
        onUploadSubmit: fileData => {
            dispatch({ ...DispatchActions.scopedDispatch(scope.key, "CLEAR_UPLOAD_FEEDBACK") })
            dispatch(FormActions.fetchUpload(scope, "/api/ClientInvoice/Upload/", fileData, "The files were uploaded successfully.", true))
                .then((result) => {
                    if (result && result.error) { }
                    else {
                        dispatch({ ...DispatchActions.scopedDispatch(scope.key, "APPLY_UPLOAD_FEEDBACK"), record: result.record })
                    }
                })
                .catch((error) => { FormActions.handleErrorResponse(error, scope, dispatch) })
                .finally(() => { dispatch(FormActions.isUploadingStop(scope)) })
        },
        onConfirmPaid: record => {
            record.status = InvoiceStatusConstants.PAID
            dispatch(FormActions.doPost(scope, record, "/api/ClientInvoice/MarkAsPaid", "The invoice was successfully marked as paid.", true))
                .then(function () {
                    dispatch({ ...DispatchActions.scopedDispatch(scope.key, "CLIENT_INVOICE_STATUS_CHANGED"), isClientInvoiceStatusChanged: true });
                });
            dispatch(FormActions.hideModal(scope, "MARK_AS_PAID"))
            dispatch({ ...DispatchActions.scopedDispatch(scope.key, "SET_LAST_PAYMENT_DATE"), paymentDate: record.paymentDate })
        },
        onClearUploadFeedback: () => {
            dispatch({ ...DispatchActions.scopedDispatch(scope.key, "CLEAR_UPLOAD_FEEDBACK") })
        },
        fetchClientInvoice: (clientInvoiceId, modal) => {
            return dispatch(FormActions.fetchSingle(scope, clientInvoiceId))
                .then(record => {
                    dispatch(FormActions.showModal(scope, modal));
                })
        },
        setCostItemDate: record => {
            dispatch({ ...DispatchActions.scopedDispatch(scope.key, "CLIENT_INVOICE_COST_ITEM_DATE"), invoiceDate: record.dateCreated })
        },
        onConfirmPublish: record => {
            dispatch(FormActions.doPost(scope, record, "/api/ClientInvoice/Update", "The invoice was successfully published.", true))
                .then(function () {
                    dispatch({ ...DispatchActions.scopedDispatch(scope.key, "CLIENT_INVOICE_STATUS_CHANGED"), isClientInvoiceStatusChanged: true })
                });
        },
        onConfirmReturnToDraft: record => {
            dispatch(FormActions.doPost(scope, record, "/api/ClientInvoice/ReturnToDraft", "The invoice was successfully drafted.", true))
                .then(function () {
                    dispatch({ ...DispatchActions.scopedDispatch(scope.key, "CLIENT_INVOICE_STATUS_CHANGED"), isClientInvoiceStatusChanged: true })
                });
        },
        onDownLoadClientInvoiceReport: clientInvoiceId => {
            dispatch(function (dispatch, getState) {
                var authToken = "";
                var fileName = "";
                if (scope.tokenSelector) {
                    authToken = scope.tokenSelector(getState())
                }

                dispatch(FormActions.inProgressStart(scope))
                FetchActions.fetchApiGet("/api/ClientInvoice/ClientInvoiceDetails?clientInvoiceId=" + clientInvoiceId, authToken)
                    .then(function (response) {
                        fileName = extractFileName(response.headers)
                        return Promise.resolve(response.blob())
                    }).then(function (blob) {
                        FileSaver.saveAs(blob, fileName);
                    })
                    .then(() => { dispatch(FormActions.inProgressStop(scope)) })
                    .catch((error) => { FormActions.handleErrorResponse(error, scope, dispatch) })
            })
        },
        addClientInvoice: record => {
            dispatch(FormActions.doPost(scope, record, "/api/ClientInvoice/Add", "The invoice was successfully created.", true));
        },
        updateClientInvoiceNumber: record => {
            dispatch(FormActions.doPost(scope, record, "/api/ClientInvoice/UpdateInvoiceNumber", "The invoice number was successfully updated.", true))
                .then(function (result) {
                    if (!result.error) {
                        dispatch(FormActions.hideModal(scope, "UPDATE_CLIENT_INVOICE_NUMBER"))
                    }
                });
        }
    }
}

function extractFileName(headers) {
    var disposition = headers.get("content-disposition")
    var fileName = disposition.match(/filename\*?=((['"])[\s\S]*?\2|[^;\n]*)/)[1]
    return fileName
}

const ClientInvoiceContainerExtended = connect(
    state => {
        return {
            sourceData: ClientInvoiceScope.selector(state),
            lastInvoicePayments: state.lastInvoicePayments
        }
    },
    mapDispatchToProps
)(ClientInvoiceCardExtended)

const ClientInvoiceListContainer = connect(
    (state, ownProps) => {
        return {
            sourceData: ClientInvoiceScope.selector(state),
            lastInvoicePayments: state.lastInvoicePayments,
            projectId: ownProps.projectId,
            projectViewTabEvents: ownProps.projectViewTabEvents
        }
    },
    mapDispatchToProps
)(ClientInvoiceList)

export { ClientInvoiceContainerExtended, ClientInvoiceListContainer }