import ScopeConstants from "constants/ScopeConstants"
import { ScopeShape } from "@gman/gman-redux"
import PropTypes from "prop-types"

export const SystemScope = {
    key: ScopeConstants.SYSTEM,
    selector: (state) => state.systemVariables,
    tokenSelector: (state) => state.authentication.token,
    fetchEndpoint: "/api/System/Search/",
    updateEndpoint: "/api/System/Update",
    deleteEndpoint: "/api/System/Delete",
    defaultRecord: {
        name: ""
    }
}

SystemScope.propTypes = PropTypes.shape(ScopeShape).isRequired