import React, { Component } from "react"
import TableHeaderRow from "components/common/TableHeaderRow"
import { Table } from "reactstrap"
import ChargeRateListRowExtended from "components/chargeRates/layout/ChargeRateListRowExtended"
import ChargeRateMenuOptions from "components/chargeRates/form/ChargeRateMenuOptions"
import FeedbackAlert from "components/common/FeedbackAlert"
import { ModalActions } from "@gman/gman-redux"
import {
    ConfirmDelete,
    FormModal,
    Paginator,
    SpinnerOverlay
} from "@gman/gman-react-bootstrap"

class ChargeRateListExtended extends Component {

    componentDidMount() {
        if (this.props.currentRatesType) {
            this.props.onGetRateList(this.props.principalId, this.props.currentRatesType)
        }
    }

    componentWillUnmount = () => {
        this.props.feedbackEvents.onFlushFeedback()
    }

    render() {
        var sourceData = this.props.sourceData;
        var modals = sourceData.modals;
        var confirmEvents = this.props.confirmEvents;
        var feedbackEvents = this.props.feedbackEvents;
        var modalEvents = this.props.modalEvents;
        var paginatorEvents = this.props.paginatorEvents;
        var tableHeaderRowEvents = this.props.tableHeaderRowEvents;

        var principalId = this.props.principalId;
        var currentRatesType = this.props.currentRatesType;
        var usingRatesType = sourceData.usingRatesType;
        var principal = this.props.principal;
        console.log(usingRatesType);
        return (
            <div>
                <FeedbackAlert {...feedbackEvents} feedback={sourceData.feedback} />
                <SpinnerOverlay isLoading={sourceData.inProgress}>
                    <ChargeRateMenuOptions
                        modals={modals}
                        onPublishNewVersion={this.props.onPublishNewVersion}
                        onGetRates={this.props.onGetRates}
                        currentRatesType={currentRatesType}
                        usingRateType={usingRatesType}
                        principalId={principalId}
                        principal={principal}
                        {...modalEvents}
                        {...confirmEvents}
                        {...sourceData}
                    />
                    <Table responsive hover>
                        <thead>
                            <TableHeaderRow
                                onSortBy={(s) => tableHeaderRowEvents.onSortBy(s, principalId, currentRatesType, 'rateList')}
                                disabled={sourceData.inProgress}
                                headers={[
                                    { sortBy: "Name", label: "Rates" },
                                    { sortBy: "Status", label: "Status" },
                                    { sortBy: "Comment", label: "Comment" }
                                ]}
                            />
                        </thead>
                        <tbody>
                            {
                                sourceData.records.map((record, index) => (
                                    <ChargeRateListRowExtended
                                        key={index}
                                        disabled={sourceData.inProgress}
                                        usingRatesType={sourceData.usingRatesType}
                                        currentRatesType={sourceData.currentRatesType}
                                        onShowDelete={() => modalEvents.onShowDelete(record)}
                                        {...record}
                                    />
                                ))
                            }
                        </tbody>
                    </Table>
                    <Paginator
                        onPageChange={(p) => paginatorEvents.onPageChange(p, principalId, currentRatesType)}
                        searchOptions={sourceData.searchOptions}
                    />
                    <FormModal
                        isVisible={ModalActions.IsDeleteVisible(sourceData.modals)}
                        onHide={modalEvents.onHideDelete}
                        title={"Confirm Delete"}>
                        <ConfirmDelete onConfirmDelete={() => confirmEvents.onConfirmDelete(sourceData.recordToUpdate, currentRatesType, principalId)} />
                    </FormModal>
                </SpinnerOverlay>
            </div>
        )
    }
}

export default ChargeRateListExtended