import ScopeConstants from "constants/ScopeConstants"
import { FormActionReducer } from "@gman/gman-redux"

export const TimesheetsTeamReducer = (
    state = {
        ...FormActionReducer.defaultPageState(),
        ...FormActionReducer.defaultSearchOptions("WeekEnding", 10, true),
        ...FormActionReducer.defaultRecordState()
    },
    action) => {

    if (action.scope === ScopeConstants.TIMESHEET_TEAM) {
        if (action.type === "CHANGE_PAGE_SIZE") {
            return {
                ...state,
                searchOptions: {
                    ...state.searchOptions,
                    pageSize: action.pageSize
                }
            }
        }

        if (action.type === "APPROVAL_MESSAGES") {
            return {
                ...state,
                approvalMessages: action.record
            }
        }

        return FormActionReducer.reduce(action, state)
    }

    return state
}