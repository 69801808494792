import { connect } from "react-redux"
import { FormActions, FormEvents, ModalConstants, DispatchActions, FetchActions } from "@gman/gman-redux"
import { TimesheetEntryEditListScope } from "components/timesheetEntries/redux/TimesheetEntryEditListScope";
import TimesheetEntryEditList from "components/timesheetEntries/layout/TimesheetEntryEditList"
import { ClientInvoiceScope } from "components/clientInvoices/redux/ClientInvoiceScope"

const mapDispatchToPropsAny = (dispatch) => { return mapDispatchToProps(dispatch, TimesheetEntryEditListScope) }

const mapDispatchToProps = (dispatch, scope) => {
    return {
        ...FormEvents.getConfirmEventsForModalsOnAll(dispatch, scope),
        ...FormEvents.getFeedbackEvents(dispatch, scope),
        ...FormEvents.getModalEventsForModalsOnAll(dispatch, scope),
        ...FormEvents.getPaginatorEvents(dispatch, scope),
        ...FormEvents.getRecordStateEvents(dispatch, scope),
        ...FormEvents.getTableHeaderRowEvents(dispatch, scope),
        onTimeSheetEditRowChange: record => {
            return dispatch(FormActions.updateRecord(scope, record))
                .then(function () {
                    dispatch(FormActions.fetchRecords(ClientInvoiceScope));
                });
        },
        onPageSizeChange: (pageSize) => {
            dispatch({ ...DispatchActions.scopedDispatch(scope.key, "CHANGE_PAGE_SIZE"), pageSize: pageSize });
            dispatch(FormActions.fetchRecords(scope));
        },
        clearClientInvoiceStatusState: () => {
            dispatch({ ...DispatchActions.scopedDispatch(ClientInvoiceScope.key, "CLIENT_INVOICE_STATUS_CHANGED"), isClientInvoiceStatusChanged: false });
        },
        onUpdateCostItemStatus: record => {
            dispatch(function (dispatch, getState) {
                var authToken = "";
                if (scope.tokenSelector) {
                    authToken = scope.tokenSelector(getState())
                }
                //dispatch(FormActions.inProgressStart(scope))
                FetchActions.fetchApiPost("/api/TimesheetEntry/UpdateInvoiceStatus", record, authToken)
                    .catch((error) => { FormActions.handleErrorResponse(error, scope, dispatch) })
            })
        }
    }
}

const TimesheetEntryEditListContainers = connect(
    (state, ownProps) => {
        return {
            sourceData: TimesheetEntryEditListScope.selector(state),
            projectId: ownProps.projectId,
            showFilter: ownProps.showFilter,
            invoiceDate: ownProps.invoiceDate,
            isClientInvoiceStatusChanged: ClientInvoiceScope.selector(state).isClientInvoiceStatusChanged,
            isOpneInModal: ownProps.isOpneInModal,
            activeTab: ownProps.activeTab,
            expectedClientInvoiceId: ownProps.expectedClientInvoiceId
        }
    },
    mapDispatchToPropsAny
)(TimesheetEntryEditList)

export {
    TimesheetEntryEditListContainers
}