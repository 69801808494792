import ScopeConstants from "constants/ScopeConstants"
import { FormActionReducer } from "@gman/gman-redux"

export const SubContractorInvoicesReducer = (
  state = {
    ...FormActionReducer.defaultPageState(),
    ...FormActionReducer.defaultSearchOptions("Number"),
    ...FormActionReducer.defaultRecordState(),
    ...FormActionReducer.defaultUploadState()
  },
  action) => {

  if (action.scope === ScopeConstants.SUB_CONTRACTOR_INVOICE) {
    if (action.type === "APPLY_UPLOAD_FEEDBACK") {
      return {
        ...state,
        uploadFeedback: action.record
      }
    }
    if (action.type === "CLEAR_UPLOAD_FEEDBACK") {
      return {
        ...state,
        uploadFeedback: []
      }
    }
    return FormActionReducer.reduce(action, state)
  }

  return state
}