import React from "react"
import { Card, CardBody, CardHeader } from "reactstrap"
import { ClientInvoiceListContainer } from "components/clientInvoices/redux/ClientInvoiceContainers"
import Icon from "components/common/Icon"

const ClientInvoiceCardBasic = () => {
    return (
        <Card>
            <CardHeader>
                <h6>
                    <Icon invoice />
                    Invoices
                </h6>
            </CardHeader>
            <CardBody>
                <ClientInvoiceListContainer />
            </CardBody>
        </Card >
    )
}

export default ClientInvoiceCardBasic