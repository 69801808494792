import React from "react"
import { Badge } from "reactstrap"
import VariationStatusConstants from "constants/VariationStatusConstants"

const VariationStatusBadge = props => {
    const { value } = props
    return (
        <Badge color={MapVariationStatusColour(value)}>
            {MapVariationStatusLabel(value)}
        </Badge>
    )
}

const MapVariationStatusLabel = value => {
    switch (value) {
        case VariationStatusConstants.DRAFT:
            return "DRAFT"
        case VariationStatusConstants.PROPOSED:
            return "PROPOSED"
        case VariationStatusConstants.APPROVED:
            return "APPROVED"
        case VariationStatusConstants.REJECTED:
            return "REJECTED"
        case VariationStatusConstants.CLOSED:
            return "CLOSED";
        default:
            return "UNKNOWN"
    }
}

const MapVariationStatusColour = value => {
    switch (value) {
        case VariationStatusConstants.DRAFT:
            return "warning"
        case VariationStatusConstants.PROPOSED:
            return "primary"
        case VariationStatusConstants.APPROVED:
            return "success"
        case VariationStatusConstants.REJECTED:
            return "danger"
        case VariationStatusConstants.CLOSED:
            return "secondary";
        default:
            return "default"
    }
}

export default VariationStatusBadge