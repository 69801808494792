import React from "react"
import Moment from "moment"
import { Link } from "react-router-dom"
import { withFormik } from "formik"
import * as Yup from "yup"
import Icon from "components/common/Icon"
import RequiredStar from "components/common/RequiredStar"
import ProposalStatusConstants from "constants/ProposalStatusConstants"
import SelectStyles from "components/common/SelectStyles"
import { AuthorizedPublishingToolbar, AuthorizedSelectOption, AuthorizedInputField } from "components/authentication/controls/AuthorizedControls"
import {
    Col,
    Container,
    Form,
    FormGroup,
    InputGroup,
    InputGroupText,
    InputGroupAddon,
    Label,
    Row
} from "reactstrap"

const PublishingToolbarGroup = AuthorizedPublishingToolbar(p => p.canWriteWorkItems)
const SelectOptions = AuthorizedSelectOption(p => p.canWriteWorkItems)
const InputField = AuthorizedInputField(p => p.canWriteWorkItems)

const BaseForm = props => {
    const {
        values,
        dirty,
        errors,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
        onConfirmPublish,
        onConfirmConvert,
        modals,
        modalEvents,
        validateForm
    } = props
    var isDraft = values.status === ProposalStatusConstants.DRAFT
    return (
        <div>
            <Form noValidate>
                {values.projectId ?
                    projectLink(values.projectId)
                    :
                    ""}
                <h6>
                    <Icon folderOpen />
                    Proposal Details
                </h6>
                <hr />
                <Container>
                    <Row>
                        <Col xs={12} md={6}>
                            <FormGroup>
                                <RequiredStar />
                                <Label for="name">Proposal Name</Label>
                                <InputField
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.name || ""}
                                    type="text"
                                    name="name"
                                    id="name"
                                    placeholder="Enter a proposal name"
                                    invalid={errors.name} />
                                {errors.name && <small className="text-danger">{errors.name}</small>}
                            </FormGroup>
                        </Col>
                        <Col xs={12} md={6}>
                            <FormGroup>
                                <Label for="code">Proposal Code</Label>
                                <InputGroup>
                                    <InputField
                                        disabled
                                        value={values.code || "Pending"}
                                        type="text"
                                        name="code"
                                        id="code"
                                        placeholder="Proposal Code"
                                        invalid={errors.code} />
                                </InputGroup>
                                {errors.code && <small className="text-danger">{errors.code}</small>}
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12} md={6}>
                            <FormGroup>
                                <RequiredStar />
                                <Label for="proposalManagerId">Proposal Manager</Label>
                                <SelectOptions
                                    styles={errors.proposalManagerId && errors.proposalManagerId.length > 0 ? SelectStyles.errorStyles() : {}}
                                    isClearable={true}
                                    disabled={isSubmitting}
                                    value={values.proposalManagerId}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    id="proposalManagerId"
                                    invalid={errors.proposalManagerId && errors.proposalManagerId.length > 0}
                                    stateFunc={(state) => { return { ...state.managers } }}
                                    valueFunc={(record) => record.userId}
                                    labelFunc={(record) => record.firstName + " " + record.lastName}
                                    sortFunc={(a, b) => a.firstName.localeCompare(b.firstName)}
                                />
                                {errors.proposalManagerId && <small className="text-danger">{errors.proposalManagerId}</small>}
                            </FormGroup>
                        </Col>
                        <Col xs={12} md={6}>
                            <FormGroup>
                                <RequiredStar />
                                <Label for="officeId">CMP Office</Label>
                                <SelectOptions
                                    styles={errors.officeId && errors.officeId.length > 0 ? SelectStyles.errorStyles() : {}}
                                    isClearable={true}
                                    disabled={isSubmitting}
                                    value={values.officeId}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    id="officeId"
                                    invalid={errors.officeId && errors.officeId.length > 0}
                                    stateFunc={(state) => { return { ...state.offices } }}
                                    valueFunc={(record) => record.officeId}
                                    labelFunc={(record) => record.name}
                                    sortFunc={(a, b) => a.name.localeCompare(b.name)}
                                />
                                {errors.officeId && <small className="text-danger">{errors.officeId}</small>}
                            </FormGroup>
                        </Col>
                    </Row>
                </Container>
                <h6>
                    <Icon client />
                    Client Details
                </h6>
                <hr />
                <Container>
                    <Row>
                        <Col xs={12} md={6}>
                            <FormGroup>
                                <RequiredStar />
                                <Label for="clientId">Client</Label>
                                <SelectOptions
                                    styles={errors.clientId && errors.clientId.length > 0 ? SelectStyles.errorStyles() : {}}
                                    isClearable={true}
                                    disabled={isSubmitting || !isDraft}
                                    value={values.clientId}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    id="clientId"
                                    invalid={errors.clientId && errors.clientId.length > 0}
                                    stateFunc={(state) => { return { ...state.clients } }}
                                    valueFunc={(record) => record.clientId}
                                    labelFunc={(record) => record.name + " (" + record.code + ")"}
                                    sortFunc={(a, b) => a.name.localeCompare(b.name)}
                                />
                                {errors.clientId && <small className="text-danger">{errors.clientId}</small>}
                            </FormGroup>
                        </Col>
                        <Col xs={12} md={6}>
                            <FormGroup>
                                <Label for="clientProjectManager">Client Project Manager</Label>
                                <InputField
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.clientProjectManager || ""}
                                    type="text"
                                    name="clientProjectManager"
                                    id="clientProjectManager"
                                    placeholder="Enter a client project manager"
                                    invalid={errors.clientProjectManager} />
                                {errors.clientProjectManager && <small className="text-danger">{errors.clientProjectManager}</small>}
                            </FormGroup>
                        </Col>
                    </Row>
                </Container>
                <h6>
                    <Icon clock />
                    Fee and Program
                </h6>
                <hr />
                <Container>
                    <Row>
                        <Col xs={12} md={6}>
                            <FormGroup>
                                <RequiredStar />
                                <Label for="likelihood">Likelihood</Label>
                                <InputGroup>
                                    <InputField
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.likelihood || ""}
                                        type="number"
                                        step={1}
                                        name="likelihood"
                                        id="likelihood"
                                        placeholder="Enter the likelihood of the proposal"
                                        invalid={errors.likelihood} />
                                    <InputGroupAddon addonType="append">
                                        <InputGroupText>%</InputGroupText>
                                    </InputGroupAddon>
                                </InputGroup>
                                {errors.likelihood && <small className="text-danger">{errors.likelihood}</small>}
                            </FormGroup>
                        </Col>
                        <Col xs={12} md={6}>
                            <FormGroup>
                                <RequiredStar />
                                <Label for="estimatedFee">Estimated Fee</Label>
                                <InputGroup>
                                    <InputGroupAddon addonType="prepend">
                                        <InputGroupText>$</InputGroupText>
                                    </InputGroupAddon>
                                    <InputField
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.estimatedFee || ""}
                                        type="text"
                                        name="estimatedFee"
                                        id="estimatedFee"
                                        placeholder="Enter the estimated fee"
                                        invalid={errors.estimatedFee} />
                                </InputGroup>
                                {errors.estimatedFee && <small className="text-danger">{errors.estimatedFee}</small>}
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12} md={6}>
                            <FormGroup>
                                <RequiredStar />
                                <Label for="targetProposalCost">Target Proposal Cost</Label>
                                <InputGroup>
                                    <InputGroupAddon addonType="prepend">
                                        <InputGroupText>$</InputGroupText>
                                    </InputGroupAddon>
                                    <InputField
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.targetProposalCost || ""}
                                        type="text"
                                        name="targetProposalCost"
                                        id="targetProposalCost"
                                        placeholder="Enter the estimated cost to prepare the proposal"
                                        invalid={errors.targetProposalCost} />
                                </InputGroup>
                                {errors.targetProposalCost && <small className="text-danger">{errors.targetProposalCost}</small>}
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12} md={6}>
                            <FormGroup>
                                <Label for="submittedFee">Submitted Fee - Total</Label>
                                <InputGroup>
                                    <InputGroupAddon addonType="prepend">
                                        <InputGroupText>$</InputGroupText>
                                    </InputGroupAddon>
                                    <InputField
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.submittedFee || ""}
                                        type="text"
                                        name="submittedFee"
                                        id="submittedFee"
                                        placeholder="Enter the submitted fee"
                                        invalid={errors.submittedFee} />
                                </InputGroup>
                                {errors.submittedFee && <small className="text-danger">{errors.submittedFee}</small>}
                            </FormGroup>
                        </Col>
                        <Col xs={12} md={6}>
                            <FormGroup>
                                <Label for="submittedSubcontractorFee">Submitted Fee - CMP Labour Component</Label>
                                <InputGroup>
                                    <InputGroupAddon addonType="prepend">
                                        <InputGroupText>$</InputGroupText>
                                    </InputGroupAddon>
                                    <InputField
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.submittedSubcontractorFee || ""}
                                        type="text"
                                        name="submittedSubcontractorFee"
                                        id="submittedSubcontractorFee"
                                        placeholder="Enter portion of the fee that is CMP time only"
                                        invalid={errors.submittedSubcontractorFee} />
                                </InputGroup>
                                {errors.submittedSubcontractorFee && <small className="text-danger">{errors.submittedSubcontractorFee}</small>}
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12} md={6}>
                            <FormGroup>
                                <RequiredStar />
                                <Label for="awardDate">Award Date</Label>
                                <InputField
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={Moment(values.awardDate).format("YYYY-MM-DD") || ""}
                                    type="date"
                                    name="awardDate"
                                    id="awardDate"
                                    placeholder="Award Date"
                                    invalid={errors.awardDate} />
                                {errors.awardDate && <small className="text-danger">{errors.awardDate}</small>}
                            </FormGroup>
                        </Col>
                        <Col xs={12} md={6}>
                            <FormGroup>
                                <RequiredStar />
                                <Label for="endDate">End Date</Label>
                                <InputField
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={Moment(values.endDate).format("YYYY-MM-DD") || ""}
                                    type="date"
                                    name="endDate"
                                    id="endDate"
                                    placeholder="End Date"
                                    invalid={errors.endDate} />
                                {errors.endDate && <small className="text-danger">{errors.endDate}</small>}
                            </FormGroup>
                        </Col>
                    </Row>
                </Container>
                <h6>
                    <Icon workflow />
                    Workflow
                </h6>
                <hr />
                <Container>
                    <Row>
                        <Col xs={12} md={6}>
                            <FormGroup>
                                <Label for="status">Proposal Status</Label>
                                <InputField
                                    disabled={isDraft}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    type="select"
                                    name="status"
                                    value={values.status || ""}
                                    invalid={errors.status}
                                    id="status"
                                >
                                    <option disabled={!isDraft} value={0}>Draft</option>
                                    <option value={1}>In Progress</option>
                                    <option value={2}>No Go</option>
                                    <option value={3}>Won</option>
                                    <option value={4}>Lost</option>
                                </InputField>
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12}>
                            <FormGroup>
                                <Label for="comments">Comments</Label>
                                <InputField
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.comments || ""}
                                    type="textarea"
                                    name="comments"
                                    id="comments"
                                    placeholder="Enter comments / feedback about the proposal"
                                    style={{ resize: "none" }}
                                    rows={3}
                                    invalid={errors.comments} />
                                {errors.comments && <small className="text-danger">{errors.comments}</small>}
                            </FormGroup>
                        </Col>
                    </Row>
                </Container>
                <PublishingToolbarGroup
                    canPublish={values.status === ProposalStatusConstants.DRAFT && !dirty}
                    canConvert={(values.status === ProposalStatusConstants.WON && (values.projectId === null) && !dirty) === true}
                    canSaveDraft={!isSubmitting && dirty}
                    modals={modals}
                    modalEvents={modalEvents}
                    recordToUpdate={values}
                    onConfirmSaveDraft={handleSubmit}
                    onConfirmPublish={(e) => {
                        validateForm()
                        onConfirmPublish(e)
                    }}
                    onConfirmConvert={onConfirmConvert}
                    convertEntityDescription="Project"
                />
            </Form>
        </div >
    )
}

const projectLink = (projectId) => {
    return (
        <div>
            <h6>
                <Icon project />
                <Link to={"/projectSheet/" + projectId}>Associated Project</Link>
            </h6>
            <hr />
        </div>
    )
}

const validationSchema = Yup.object({
    name: Yup.string()
        .required("Name is required"),
    clientId: Yup.string()
        .required("Client is required")
        .nullable(),
    officeId: Yup.string()
        .required("Office is required")
        .nullable(),
    proposalManagerId: Yup.string()
        .required("Proposal manager is required")
        .nullable(),
    awardDate: Yup.date()
        .required("Award date is required")
        .typeError("Must be a date"),
    estimatedFee: requiredNumberValidation(),
    targetProposalCost: requiredNumberValidation(),
    submittedFee: numberValidation(),
    likelihood: requiredNumberValidation("Likelihood must be numeric")
        .max(100)
        .min(0),
    comments: Yup.string()
        .max(2048, "Maximum length is 2048 characters")
        .nullable(),
    endDate: Yup.date()
        .when(
            "awardDate",
            (awardDate, schema) => (
                awardDate ?
                    schema
                        .min(awardDate, "End date cannot precede Award date")
                        .required("End date is required")
                        .typeError("Must be a date")
                    :
                    schema
                        .required("End date is required")
                        .typeError("Must be a date"))
        ),
    submittedSubcontractorFee:
        numberValidation()
            .when(
                "submittedFee",
                (submittedFee, schema) => (
                    submittedFee ?
                        schema
                            .max(submittedFee, "CMP labour component cannot exceed total fee")
                        :
                        schema
                ))
})

function requiredNumberValidation() {
    return numberValidation()
        .required("Value is required")
}

function numberValidation() {
    return Yup.number()
        .default(0)
        .nullable()
        .round()
        .min(0, "Must be positive")
        .typeError("Must be a number")
}

const ProposalFormExtended = withFormik({
    displayName: "ProposalFormExtended",
    enableReinitialize: true,
    validationSchema: validationSchema,
    mapPropsToValues: ({ ...props }) => ({
        ...props,
        awardDate: props.awardDate || "",
        endDate: props.endDate || ""
    }),
    handleSubmit: (values, { props, setSubmitting }) => {
        props.onSubmitUpdate(values)
        setSubmitting(false)
    }
})(BaseForm)

export default ProposalFormExtended