import React from "react"
import Moment from "moment"
import { Link } from "react-router-dom"
import PropTypes from "prop-types"
import { ProjectShape } from "shapes/ProjectShape"
import ActiveCell from "components/common/ActiveCell"
import ProjectStatusBadge from "components/common/ProjectStatusBadge"
import { AuthorizedSummaryToolbar } from "components/authentication/controls/AuthorizedControls"

const Toolbar = AuthorizedSummaryToolbar(p => p.canWriteWorkItems)

const ProjectRow = (
  {
    projectId,
    client,
    name,
    code,
    status,
    projectManager,
    dateCreated,
    onShowEdit,
    onShowDelete,
    disabled
  }) => (
    <tr>
      <ActiveCell active={!disabled}>
        <Link to={"/projectSheet/" + projectId} onClick={onShowEdit}>
          {name}
        </Link>
      </ActiveCell>
      <ActiveCell active={!disabled}>{code}</ActiveCell>
      <ActiveCell active={!disabled}>
        <Link to={"/clientDetails/" + client.clientId + "?"}>
          {client.name}
        </Link>
      </ActiveCell>
      <ActiveCell active={!disabled}>
        {projectManager ?
          <Link to={"/userDetails/" + projectManager.userId}>
            {projectManager && projectManager.firstName + " " + projectManager.lastName}
          </Link>
          : ""}
      </ActiveCell>
      <td>{Moment(dateCreated).format("DD/MM/YYYY")}</td>
      <td>
        <ProjectStatusBadge value={status} />
      </td>
      <td>
        <Toolbar disabled={disabled} onShowDelete={onShowDelete} />
      </td>
    </tr>
  )

ProjectRow.propTypes = PropTypes.shape(ProjectShape).isRequired

export default ProjectRow 