import React from "react"
import Moment from "moment"
import { Link } from "react-router-dom"
import ActiveCell from "components/common/ActiveCell"
import Currency from "components/common/Currency"
import ExpenseStatusBadge from "components/common/ExpenseStatusBadge"
import { Button, ButtonGroup } from "reactstrap"
import { MdModeEdit, MdDelete } from "react-icons/md"
import { FaDollarSign, FaThumbsUp, FaThumbsDown } from "react-icons/fa"
import ExpenseStatusConstants from "constants/ExpenseStatusConstants"
import CommentBadge from "components/common/CommentBadge"

const ExpenseRow = (
    {
        index,
        description,
        effectiveDate,
        dateSubmitted,
        dateApproved,
        expenseType,
        amountCalculated,
        taxCalculated,
        status,
        user,
        comments,
        onShowEdit,
        onShowDelete,
        onShowApprove,
        onShowReject,
        onShowPaid,
        onAddSimilar,
        disabled,
        workItem,
        workflowPermissions,
        chargeClient,
        reimburseMe
    }) => (
        <tr>
            <ActiveCell active={!disabled}>{description}</ActiveCell>
            <td>{Moment(effectiveDate).format("DD/MM/YYYY")}</td>
            <td>{dateSubmitted == null ? "" : Moment(dateSubmitted).format("DD/MM/YYYY")}</td>
            <td>{dateApproved == null ? "" : Moment(dateApproved).format("DD/MM/YYYY")}</td>
            <td>{workItem.name}</td>
            <td>{expenseType.description}</td>
            <ActiveCell active={!disabled}>{Currency(amountCalculated)}</ActiveCell>
            <ActiveCell active={!disabled}>{Currency(taxCalculated)}</ActiveCell>
            <td>
                <ExpenseStatusBadge value={status} />
            </td>
            <td>
                {
                    (status === ExpenseStatusConstants.DECLINED && comments) ?
                        <CommentBadge comment={comments} index={index} />
                        : ""
                }
            </td>
            <ActiveCell active={!disabled}>
                {user ?
                    <Link to={"/userDetails/" + user.userId}>
                        {user && user.firstName + " " + user.lastName}
                    </Link>
                    : ""}
            </ActiveCell>
            <td style={{ textAlign: "center" }}>
                {chargeClient ? "Yes" : ""}
            </td>
            <td style={{ textAlign: "center" }}>
                {reimburseMe ? "Yes" : ""}
            </td>
            <td>
                {
                    <div>
                        {
                            workflowPermissions ?
                                WorkflowToolbar(
                                    disabled,
                                    status === ExpenseStatusConstants.SUBMITTED,
                                    status === ExpenseStatusConstants.APPROVED,
                                    workflowPermissions,
                                    onShowEdit,
                                    onShowDelete,
                                    onShowApprove,
                                    onShowReject,
                                    onAddSimilar,
                                    onShowPaid,
                                    status === ExpenseStatusConstants.NOT_REIMBURSABLE)
                                : ""
                        }
                    </div>
                }
            </td>
        </tr>
    )

function WorkflowToolbar(
    disabled,
    isSubmitted,
    isApproved,
    workflowPermissions,
    onShowEdit,
    onShowDelete,
    onShowApprove,
    onShowReject,
    onAddSimilar,
    onShowPaid,
    NotReimbursable
) {
    return (
        <ButtonGroup size="sm" className="float-right">
            {
                workflowPermissions.canEdit && onAddSimilar ?
                    <Button outline size="sm" color="info" disabled={disabled} onClick={onAddSimilar}>
                        <MdModeEdit /> Add Similar
          </Button>
                    : ""
            }
            {
                workflowPermissions.canEdit && onShowEdit ?
                    <Button outline size="sm" color="info" disabled={disabled} onClick={onShowEdit}>
                        <MdModeEdit /> Edit
          </Button>
                    : ""
            }
            {
                workflowPermissions.canApprove && onShowApprove && isSubmitted ?
                    <Button outline size="sm" color="success" disabled={disabled} onClick={onShowApprove}>
                        <FaThumbsUp /> Approve
          </Button>
                    : ""
            }
            {
                workflowPermissions.canApprove && onShowReject && isSubmitted ?
                    <Button outline size="sm" color="warning" disabled={disabled} onClick={onShowReject}>
                        <FaThumbsDown /> Decline
          </Button>
                    : ""
            }
            {
                workflowPermissions.canMarkPaid && onShowPaid && isApproved && !NotReimbursable ?
                    <Button outline size="sm" color="success" disabled={disabled} onClick={onShowPaid}>
                        <FaDollarSign /> Paid
          </Button>
                    : ""
            }
            {
                workflowPermissions.canEdit && onShowDelete ?
                    <Button outline size="sm" color="danger" disabled={disabled} onClick={onShowDelete}>
                        <MdDelete /> Delete
          </Button>
                    : ""
            }
        </ButtonGroup>
    )
}

export default ExpenseRow 