import React from "react"
import { Badge } from "reactstrap"
import ChargeListStatusConstants from "constants/ChargeListStatusConstants"

const ChargeListStatusBadge = props => {
    const { value } = props
    return (
        <Badge color={MapChargeistStatusColour(value)}>
            {MapChargeListStatusLabel(value)}
        </Badge>
    )
}

const MapChargeListStatusLabel = value => {
    switch (value) {
        case ChargeListStatusConstants.DRAFT:
            return "DRAFT"
        case ChargeListStatusConstants.ACTIVE:
            return "ACTIVE"
        case ChargeListStatusConstants.CLOSED:
            return "CLOSED"
        default:
            return "UNKNOWN"
    }
}

const MapChargeistStatusColour = value => {
    switch (value) {
        case ChargeListStatusConstants.DRAFT:
            return "warning"
        case ChargeListStatusConstants.ACTIVE:
            return "success"
        case ChargeListStatusConstants.CLOSED:
            return "light"
        default:
            return "default"
    }
}

export default ChargeListStatusBadge