import React from "react"
import Moment from "moment"
import { withFormik } from "formik"
import * as Yup from "yup"
import RequiredStar from "components/common/RequiredStar"
import { AuthorizedButton, AuthorizedInputField } from "components/authentication/controls/AuthorizedControls"

import {
    Col,
    Container,
    Form,
    FormGroup,
    Label,
    Row
} from "reactstrap"

const SubmitButton = AuthorizedButton(p => p.canWriteExpenseMonthlyDueDates)
const InputField = AuthorizedInputField(p => p.canWriteExpenseMonthlyDueDates)

const BaseForm = props => {
    const {
        values,
        dirty,
        errors,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting
    } = props
    return (
        <div>
            <Form noValidate onSubmit={handleSubmit}>
                <Container>
                    <Row>
                        <Col xs={12} md={12}>
                            <FormGroup>
                                <RequiredStar />
                                <Label for="dueDate">Due Date</Label>
                                <InputField
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.dueDate ? Moment(values.dueDate).format("YYYY-MM-DD") : ""}
                                    type="date"
                                    name="dueDate"
                                    id="dueDate"
                                    placeholder="Enter a Due Date"
                                    invalid={errors.dueDate} />
                                {errors.dueDate && <small className="text-danger">{errors.dueDate}</small>}
                            </FormGroup>
                        </Col>
                    </Row>
                </Container>
                <SubmitButton outline className="float-right" disabled={isSubmitting || !dirty} color="success" type="submit">Submit</SubmitButton>
            </Form>
        </div >
    )
}

const validationSchema = Yup.object({
    dueDate: Yup.date()
        .required("Due Date is required"),
})


const ExpenseMonthlyDueDatesFormBasic = withFormik({
    displayName: "ExpenseTypeFormBasic",
    enableReinitialize: true,
    validationSchema: validationSchema,
    initialValues: ({ value }) => ({
        dueDate: value.dueDate
    }),
    handleSubmit: (values, { props, setSubmitting }) => {
        props.onSubmitUpdate(values)
        setSubmitting(false)
    }
})(BaseForm)

export default ExpenseMonthlyDueDatesFormBasic