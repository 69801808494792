import React, { Component } from "react"
import { connect } from "react-redux"
import PropTypes from "prop-types"
import { Input } from "reactstrap"

const mapStateToProps = (state, props) => {
    return {
        value: props.valueFunc(props.stateFunc(state)),
        isLoading: props.loadingFunc ? props.loadingFunc(props.stateFunc(state)) : false,
        isStale: props.staleFunc ? props.staleFunc(props.stateFunc(state)) : false
    }
}

class CalculatedAmount extends Component {

    componentWillReceiveProps(nextProps) {
        console.log("Its here ", this.props.value);
        if (nextProps.value != this.props.value) {
            if (this.props.onChange) {
                this.props.onChange(nextProps.value);
            }
        }
    }

    render() {
        return (
            <Input
                disabled
                type="text"
                value={
                    this.props.isLoading ?
                        "Calculating..." :
                        this.props.isStale ?
                            "-" :
                            (this.props.value || 0).toFixed(2)}
            />
        )
    }
}

CalculatedAmount.propTypes = {
    valueFunc: PropTypes.func.isRequired,
    stateFunc: PropTypes.func.isRequired,
    loadingFunc: PropTypes.func,
    staleFunc: PropTypes.func
}

export default connect(mapStateToProps)(CalculatedAmount)