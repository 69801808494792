import React from "react"
import { ModalActions } from "@gman/gman-redux"
import {
    ConfirmPublish,
    FormModal
} from "@gman/gman-react-bootstrap"
import {
    Button,
    ButtonGroup,
    FormGroup,
} from "reactstrap"

const PublishingToolbar = (
    {
        recordToUpdate,
        onConfirmSaveDraft,
        onConfirmPublish,
        onConfirmConvert,        
        modalEvents,
        convertEntityDescription,
        modals,
        canSaveDraft,
        canPublish,
        canConvert
    }) => (
        <ButtonGroup className="float-right">
            {
                onConfirmSaveDraft ?
                    <Button
                        outline
                        disabled={!canSaveDraft}
                        color="warning"
                        type="submit"
                        onClick={onConfirmSaveDraft}
                    >
                        Save Draft
                        </Button>
                    : ""
            }
            {
                onConfirmPublish ?
                    <Button
                        outline
                        disabled={!canPublish}
                        color="success"
                        onClick={() => modalEvents.onShowPublish(recordToUpdate)}
                    >
                        Publish
                    </Button>
                    : ""
            }
            {
                onConfirmConvert ?
                    <Button
                        outline
                        disabled={!canConvert}
                        color="primary"
                        onClick={() => modalEvents.onShowModal("CONVERT")}
                    >
                        Convert to {convertEntityDescription}
                    </Button>
                    : ""
            }

            <FormModal
                isVisible={ModalActions.IsPublishVisible(modals)}
                onHide={modalEvents.onHidePublish}
                title={"Confirm Publish"}>
                <ConfirmPublish onConfirmPublish={() => onConfirmPublish(recordToUpdate)} />
            </FormModal>
            <FormModal
                isVisible={ModalActions.IsVisible(modals, "CONVERT")}
                onHide={() => modalEvents.onHideModal("CONVERT")}
                title={"Confirm Convert to " + convertEntityDescription}>
                <FormGroup>
                    Convert this record to a (draft) {convertEntityDescription}?
                    <Button className="float-right" color="success" onClick={() => onConfirmConvert(recordToUpdate)}>Convert</Button>
                </FormGroup>
            </FormModal>
        </ButtonGroup>
    )

export default PublishingToolbar