import React from "react"
import {
    Button,
    Card,
    CardBody,
    CardHeader,
    Col,
    Container,
    Row
} from "reactstrap"
import Icon from "components/common/Icon"
import { SpinnerOverlay } from "@gman/gman-react-bootstrap"
import FileUploader from "components/common/FileUploader"

const ReportUploadFileCardBasic = props => {
    const {
        feedbackEvents,
        sourceData,
        onRunExport,
        onUploadSubmit,
        uploadEvents
    } = props;
    return (
        <Card>
            <CardHeader>
                <h6>
                    <Icon invoice />
                    {sourceData.reportName}
                </h6>
            </CardHeader>
            <CardBody>
                <SpinnerOverlay isLoading={sourceData.inProgress}>
                    <Container>
                        {
                            onUploadSubmit ? (
                                <Row>
                                    <Col xs={12} md={6}>
                                        <p className="lead">{sourceData.reportDescription}</p>
                                    </Col>
                                    <Col xs={12} md={6}>
                                        <FileUploader
                                            feedback={sourceData.feedback}
                                            feedbackEvents={feedbackEvents}
                                            uploadedFiles={sourceData.fileData}
                                            isUploading={sourceData.isUploading}
                                            uploadFeedback={sourceData.uploadFeedback}
                                            multiple={true}
                                            accept=".xls, .xlsx"
                                            maxSize={6291456}
                                            {...uploadEvents}
                                            onUploadSubmit={() => onUploadSubmit(sourceData.fileData)}>
                                            <div>
                                                Click here to upload a file or drag a file into this zone.
                                            <br />
                                                Files must be .XLS or .XLSX
                                        </div>
                                        </FileUploader>
                                    </Col>

                                </Row>
                            ) : ('')
                        }
                    </Container>
                </SpinnerOverlay>
            </CardBody>
        </Card>
    );
}

export default ReportUploadFileCardBasic;