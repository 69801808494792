import ScopeConstants from "constants/ScopeConstants"
import { ScopeShape } from "@gman/gman-redux"
import InvoiceStatusConstants from "constants/InvoiceStatusConstants";
import PropTypes from "prop-types"

export const SubContractorInvoiceScope = {
    key: ScopeConstants.SUB_CONTRACTOR_INVOICE,
    selector: (state) => state.subContractorInvoices,
    tokenSelector: (state) => state.authentication.token,
    fetchEndpoint: "/api/SubContractorInvoice/Search/",
    singleEndpoint: "/api/SubContractorInvoice/Get/",
    updateEndpoint: "/api/SubContractorInvoice/Update/",
    deleteEndpoint: "/api/SubContractorInvoice/Delete/",
    defaultRecord: { 
        status: InvoiceStatusConstants.UNPAID
    }
}

SubContractorInvoiceScope.propTypes = PropTypes.shape(ScopeShape).isRequired