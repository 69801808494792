export const RoleTypesReducer = (
    state = {
        records: [
            { typeId: 0, description: "Basic User" },
            { typeId: 1, description: "Accounting" },
            { typeId: 2, description: "HR Manager" },
            { typeId: 3, description: "Project Manager" },
            { typeId: 4, description: "Project Administrator" },
            { typeId: 5, description: "Systems Administrator" }
        ]
    }) => { return state }