
import keyMirror from "keymirror"

const ChargeRateTypeConstants = keyMirror({
    STANDARD: null,
    CLIENT: null,
    PROJECT: null,
    PROPOSAL: null,
    COST: null,
    STANDARDCONTRACTOR:null
})

export default ChargeRateTypeConstants