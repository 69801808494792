import ScopeConstants from "constants/ScopeConstants"
import { FormActionReducer } from "@gman/gman-redux"

export const ReportSubContractorInvoicesReducer = (
  state = {
    ...FormActionReducer.defaultPageState(),
    ...FormActionReducer.defaultSearchOptions(),
    reportName: "Sub Contractor Invoices Report",
    reportDescription: "An export of Sub Contractor invoices, in CSV format."
  },
  action) => {

  if (action.scope === ScopeConstants.REPORT_SUB_CONTRACTOR_INVOICES_UNPAID) {
    return FormActionReducer.reduce(action, state)
  }

  return state
}