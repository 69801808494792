import ScopeConstants from "constants/ScopeConstants"
import { FormActionReducer } from "@gman/gman-redux"

export const ReportTimesheetHoursReducer = (
  state = {
    ...FormActionReducer.defaultPageState(),
    ...FormActionReducer.defaultSearchOptions(),
    reportName: "Timesheet Hours Report",
    reportDescription: "An export of timesheet hours, structured in a format compatible for loading into the XLS-based system."
  },
    action) => {
  if (action.scope === ScopeConstants.REPORT_TIMESHEET_HOURS) {
    return FormActionReducer.reduce(action, state)
  }

  return state
}