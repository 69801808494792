import ScopeConstants from "constants/ScopeConstants"
import VariationStatusConstants from "constants/VariationStatusConstants"

export const VariationScope = {
    key: ScopeConstants.VARIATION,
    selector: (state) => state.variations,
    tokenSelector: (state) => state.authentication.token,
    fetchEndpoint: "/api/Variation/Search/",
    singleEndpoint: "/api/Variation/Get/",
    updateEndpoint: "/api/Variation/Update/",
    deleteEndpoint: "/api/Variation/Delete/",
    defaultRecord: {
        number: "",
        description: "",
        status: VariationStatusConstants.DRAFT
    }
}