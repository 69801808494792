import ScopeConstants from "constants/ScopeConstants"
import ProjectStatusConstants from "constants/ProjectStatusConstants"

export const ProjectMineScope = {
    key: ScopeConstants.PROJECT_MINE,
    selector: (state) => state.projectsMine,
    tokenSelector: (state) => state.authentication.token,
    fetchEndpoint: "/api/Project/SearchMine/",
    singleEndpoint: "/api/Project/GetAny/",
    updateEndpoint: "/api/Project/UpdateAny/",
    deleteEndpoint: "/api/Project/DeleteAny/",
    publishEndpoint: "/api/Project/PublishAny/",
    defaultRecord: {
        name: "",
        code: "",
        status: ProjectStatusConstants.DRAFT
    }
}