import ScopeConstants from "constants/ScopeConstants"
import { FormActionReducer } from "@gman/gman-redux"

export const TimesheetEntryHistoryReducer = (
  state = {
    ...FormActionReducer.defaultPageState(),
    ...FormActionReducer.defaultSearchOptions("Type", 10000),
    ...FormActionReducer.defaultRecordState()
  },
  action) => {

  if (action.scope === ScopeConstants.TIMESHEET_ENTRY_HISTORY) {
    return FormActionReducer.reduce(action, state)
  }
  
  return state
}