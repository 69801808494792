import React, { Component } from "react";
import PropTypes from "prop-types";
import { Table } from "reactstrap";
import {
    ConfirmDelete,
    FormModal,
    Paginator,
    SpinnerOverlay
} from "@gman/gman-react-bootstrap";
import ReactDOM from 'react-dom';
import TableHeaderRow from "components/common/TableHeaderRow";
import FilterForm from "components/common/FilterForm";
import ExpenseEditRow from "./ExpenseEditRow";
import ExpenseEditRowForm from "components/expenses/form/ExpenseEditRowForm";
import { ModalActions } from "@gman/gman-redux";
import ScopeConstants from "constants/ScopeConstants";
import PageSizeSelect from "components/common/PageSizeSelect"

class ExpenseEditList extends Component {
    state = {
        isRowChanges: false
    }

    constructor(props) {
        super(props);
        this.handleExpenseFilterChange = this.handleExpenseFilterChange.bind(this);
    }

    componentDidMount() {
        let body = document.getElementsByTagName("body")[0];
        if (this.props.projectId) {
            var searchModel = {
                searchBy: 'Project',
                searchValue: this.props.projectId
            }

            if (this.props.expectedClientInvoiceId) {
                searchModel.expectedClientInvoiceId = this.props.expectedClientInvoiceId;
                document.addEventListener('mousedown', this.handleOutsideModalClick, true);
            } else {
                body.addEventListener('mousedown', this.handleOutsideClick, true);
            }
            this.props.recordStateEvents.onClearSearch(searchModel);
        } else {
            this.props.recordStateEvents.onClearSearch();
            body.addEventListener('mousedown', this.handleOutsideClick);
        }
    }

    componentDidUpdate(prevProps) {
        // Typical usage (don't forget to compare props):
        if (this.props.activeTab == 2 && prevProps.activeTab != this.props.activeTab) {
            if (this.props.projectId) {
                var searchModel = {
                    searchBy: 'Project',
                    searchValue: this.props.projectId
                }

                if (this.props.expectedClientInvoiceId) {
                    searchModel.expectedClientInvoiceId = this.props.expectedClientInvoiceId;
                }
                this.props.recordStateEvents.onClearSearch(searchModel);
            } else {
                this.props.recordStateEvents.onClearSearch();
            }
        }
    }

    componentWillUnmount = () => {
        let body = document.getElementsByTagName("body")[0];
        document.removeEventListener('mousedown', this.handleOutsideModalClick, true);
        body.removeEventListener('mousedown', this.handleOutsideClick, true);
        if (this.props.projectId) {
            this.props.clearClientInvoiceStatusState();
            var searchModel = {
                // searchBy: 'Project',
                // searchValue: this.props.projectId
            }

            // this.props.recordStateEvents.onClearSearch(searchModel);
        }
    };

    handleExpenseFilterChange(values) {
        this.props.recordStateEvents.onClearSearch(values);
    }

    handleFormChange = (e) => {
        let { name, checked } = e.target;
        this.props.sourceData.recordToUpdate[name] = checked;
        this.setState({ isRowChanges: true });
    }

    handleCostItemsStausChange = (e) => {
        if (this.state.isRowChanges) {
            let { name, value } = e.target;
            this.props.sourceData.recordToUpdate[name] = value;
            this.setState({ isRowChanges: true });
        }
    }

    handleWorkItemChange = (workItem) => {
        this.props.sourceData.recordToUpdate['workItem'] = workItem;
        let recordToUpdate = this.props.sourceData.recordToUpdate;
        var workItemId = workItem && workItem.workItemId;
        if (workItem && workItem.type == ScopeConstants.PROJECT_ANY) {
            recordToUpdate.variationId = workItem.variation ? workItem.variation.variationId : null;
            recordToUpdate.costCodeId = workItem.costCode ? workItem.costCode.costCodeId : null;
            recordToUpdate.proposalId = null;
            recordToUpdate.leaveId = null;
            recordToUpdate.projectId = workItemId;
        } else if (workItem && workItem.type == ScopeConstants.PROPOSAL_ANY) {
            recordToUpdate.variationId = workItem.variation ? workItem.costCode.variationId : null;
            recordToUpdate.costCodeId = workItem.costCode ? workItem.costCode.costCodeId : null;
            recordToUpdate.projectId = null;
            recordToUpdate.leaveId = null;
            recordToUpdate.proposalId = workItemId;
        } else if (workItem && workItem.type == ScopeConstants.LEAVE) {
            recordToUpdate.variationId = workItem.variation ? workItem.variation.variationId : null;
            recordToUpdate.costCodeId = workItem.costCode ? workItem.costCode.costCodeId : null;
            recordToUpdate.projectId = null;
            recordToUpdate.proposalId = null;
            recordToUpdate.leaveId = workItemId;
        } else {
            recordToUpdate.variationId = null;
            recordToUpdate.costCodeId = "";
            recordToUpdate.projectId = null;
            recordToUpdate.proposalId = null;
            recordToUpdate.leaveId = null;
        }
        this.setState({ isRowChanges: true });
    }

    handleOutsideClick = (e) => {
        if (this.props.isOpneInModal) {
            e.stopPropagation();
            return;
        }
        let that = this;
        let modals = that.props.sourceData.modals;
        const domNode = ReactDOM.findDOMNode(this);
        if (!ModalActions.IsVisible(modals, "SELECT_WORK_ITEM")) {
            if (!domNode || !domNode.contains(e.target)) {
                if (ModalActions.IsUpdateVisible(modals)) {
                    if (that.state.isRowChanges) {
                        let recordToUpdate = that.props.sourceData.recordToUpdate;
                        that.props.onExpenseRowChangeSave(recordToUpdate)
                            .then((result) => {
                                that.props.modalEvents.onHideEdit();
                                that.setState({ isRowChanges: false });
                            });
                    } else {
                        that.props.modalEvents.onHideEdit();
                        that.setState({ isRowChanges: false });
                    }
                }
            }
        }
    }

    handleOutsideModalClick = (e) => {
        if (this.props.isOpneInModal) {
            e.stopPropagation();
            let that = this;
            let modals = that.props.sourceData.modals;
            const domNode = ReactDOM.findDOMNode(this);
            if (!ModalActions.IsVisible(modals, "SELECT_WORK_ITEM")) {
                if (!domNode || !domNode.contains(e.target)) {
                    if (ModalActions.IsUpdateVisible(modals)) {
                        if (that.state.isRowChanges) {
                            let recordToUpdate = that.props.sourceData.recordToUpdate;
                            that.props.onExpenseRowChangeSave(recordToUpdate)
                                .then((result) => {
                                    that.props.modalEvents.onHideEdit();
                                    that.setState({ isRowChanges: false });
                                });
                        } else {
                            that.props.modalEvents.onHideEdit();
                            that.setState({ isRowChanges: false });
                        }
                    }
                }
            }
        }

    }

    render() {
        var sourceData = this.props.sourceData;
        var paginatorEvents = this.props.paginatorEvents;
        var tableHeaderRowEvents = this.props.tableHeaderRowEvents;
        var modalEvents = this.props.modalEvents;
        var modals = sourceData.modals;

        return (
            <div className="">
                <SpinnerOverlay isLoading={sourceData.inProgress}>
                    {
                        this.props.showFilter && <FilterForm handleSubmit={this.handleExpenseFilterChange} />
                    }

                    <Table
                        responsive
                        hover>
                        <thead>
                            <TableHeaderRow
                                onSortBy={tableHeaderRowEvents.onSortBy}
                                disabled={sourceData.inProgress}
                                headers={[
                                    {
                                        sortBy: "User.FirstName",
                                        label: "Employee"
                                    },
                                    {
                                        sortBy: "effectiveDate",
                                        label: "Date"
                                    },
                                    {
                                        sortBy: "Project.Name",
                                        label: "Project",
                                        className: "weekend"
                                    },
                                    {
                                        sortBy: "Project.ClientProjectNumber",
                                        label: "Client Project Number",
                                    },
                                    {
                                        sortBy: "Description",
                                        label: "Description",
                                    },
                                    {
                                        sortBy: "ExpenseType.Description",
                                        label: "Type"
                                    },
                                    {
                                        sortBy: "ChargeClient",
                                        label: "Charge to Client"
                                    },
                                    {
                                        sortBy: "ReimburseMe",
                                        label: "Reimburse Me"
                                    },
                                    {
                                        sortBy: "AmountCalculated",
                                        label: " Amount (incl. GST)"
                                    },
                                    {
                                        sortBy: "Status",
                                        label: "Employee Reimbursement Status"
                                    },
                                    {
                                        label: "Invoice"
                                    },
                                    {
                                        label: "Defer"
                                    },
                                    {
                                        label: "Write Off"
                                    },
                                    {
                                        sortBy: "Status",
                                        label: "Client Invoice Number"
                                    },
                                    {
                                        sortBy: "Status",
                                        label: "Client Invoice Status"
                                    },
                                ]}
                            />
                        </thead>
                        <tbody>
                            {sourceData.records.map((record, index) => {
                                return record.expenseId ===
                                    sourceData.recordToUpdate.expenseId &&
                                    ModalActions.IsUpdateVisible(modals) ?
                                    (
                                        <ExpenseEditRowForm
                                            {...modalEvents}
                                            modals={modals}
                                            key={index}
                                            onHide={modalEvents.onHideEdit}
                                            handleWorkItemChange={this.handleWorkItemChange}
                                            handleFormChange={this.handleFormChange}
                                            handleCostItemsStausChange={this.handleCostItemsStausChange}
                                            isOpneInModal={this.props.isOpneInModal}
                                            {...sourceData.recordToUpdate}
                                        />
                                    ) :
                                    (
                                        <ExpenseEditRow
                                            key={index}
                                            index={index}
                                            onShowEdit={
                                                () => {
                                                    let that = this;
                                                    if (ModalActions.IsUpdateVisible(modals) && that.state.isRowChanges) {
                                                        let recordToUpdate = sourceData.recordToUpdate;
                                                        that.props.onExpenseRowChangeSave(recordToUpdate)
                                                            .then((result) => {
                                                                modalEvents.onShowEdit(record);
                                                                that.setState({ isRowChanges: false });
                                                            });
                                                    } else {
                                                        modalEvents.onShowEdit(record);
                                                        that.setState({ isRowChanges: false });
                                                    }
                                                }
                                            }
                                            onCostItemStatusChanged={
                                                (record) => {
                                                    let that = this;
                                                    that.props.onUpdateCostItemStatus(record);
                                                }
                                            }
                                            isOpneInModal={this.props.isOpneInModal}
                                            {...record}
                                        />
                                    );
                            })}
                        </tbody>
                    </Table>
                    <Paginator
                        {...paginatorEvents}
                        searchOptions={sourceData.searchOptions}
                    />
                    <PageSizeSelect disabled={sourceData.inProgress} defaultPageSize={sourceData.searchOptions.pageSize} onChange={this.props.onPageSizeChange} />
                </SpinnerOverlay>
            </div>
        );
    }
}

export default ExpenseEditList;
