import { connect } from "react-redux"
import SystemList from "components/system/layout/SystemList"
import { SystemScope } from "components/system/redux/SystemScope"
import { FormEvents, FormActions } from "@gman/gman-redux"

const mapDispatchToProps = (dispatch) => {
    var scope = SystemScope
    return {
        ...FormEvents.getConfirmEventsForModalsOnAll(dispatch, scope),
        ...FormEvents.getTableHeaderRowEvents(dispatch, scope),
        ...FormEvents.getFeedbackEvents(dispatch, scope),
        ...FormEvents.getPaginatorEvents(dispatch, scope),
        ...FormEvents.getModalEventsForModalsOnAll(dispatch, scope),
        ...FormEvents.getRecordStateEvents(dispatch, scope),
        updateMiscMessage: record => {
            dispatch(FormActions.doPost(scope, record, "/api/System/UpdateMiscMessage", "MISC code timesheet message updated successfully.", true))
        }
    }
}

const SystemListContainer = connect(
    state => { return { sourceData: SystemScope.selector(state) } },
    mapDispatchToProps
)(SystemList)

export { SystemListContainer }