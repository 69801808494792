import React, { Component } from "react"
import TableHeaderRow from "components/common/TableHeaderRow"
import { Table, Card, CardBody, CardHeader, CardTitle, CardSubtitle  } from "reactstrap"
import ChargeRateRow from "components/chargeRates/layout/ChargeRateRow"
import ChargeRateMenuOptions from "components/chargeRates/form/ChargeRateMenuOptions"
import FeedbackAlert from "components/common/FeedbackAlert"
import ChargeListStatusBadge from "components/common/ChargeListStatusBadge"
import {
    Paginator,
    SpinnerOverlay
} from "@gman/gman-react-bootstrap"
import Icon from "components/common/Icon"
import ChargeRateTypeConstants from "constants/ChargeRateTypeConstants"

class ChargeRateList extends Component {

    componentDidMount() {
        if (this.props.currentRatesType) {
            this.props.onGetRateChargeList(this.props.principalId, this.props.currentRatesType)
        }
    }

    componentWillUnmount = () => {
        this.props.feedbackEvents.onFlushFeedback()
    }

    render() {
        var sourceData = this.props.sourceData
        var modals = sourceData.modals
        var confirmEvents = this.props.confirmEvents
        var feedbackEvents = this.props.feedbackEvents
        var modalEvents = this.props.modalEvents
        var paginatorEvents = this.props.paginatorEvents
        var tableHeaderRowEvents = this.props.tableHeaderRowEvents

        var principalId = this.props.principalId
        var currentRatesType = this.props.currentRatesType
        var usingRateType = this.props.usingRateType
        var principal = this.props.principal;
        var rateListName = sourceData.rateListName;
        var rateListStatus = sourceData.status;
        return (
            <Card>
                <CardHeader>
                    <CardTitle>
                        <Icon chargeRate />
                        {
                            currentRatesType.toUpperCase() == ChargeRateTypeConstants.COST
                                ? "Cost Rates" :
                                currentRatesType.toUpperCase() == ChargeRateTypeConstants.STANDARD
                                    ? "Standard Rates" :
                                    currentRatesType.toUpperCase() == ChargeRateTypeConstants.CLIENT
                                        ? "Client Rates" :
                                        currentRatesType.toUpperCase() == ChargeRateTypeConstants.PROJECT
                                            ? "Project Rates" :
                                            currentRatesType.toUpperCase() == ChargeRateTypeConstants.PROPOSAL
                                                ? "Proposal Rates" : ""
                        }
                    </CardTitle>
                    <hr />
                    <CardSubtitle>
                        <small>
                            <i>{rateListName ? rateListName : "Loading..."}</i>
                        </small>
                    </CardSubtitle>
                    <br />
                    <ChargeListStatusBadge value={rateListStatus} />
                </CardHeader>
                <CardBody>
                    <div>
                        <FeedbackAlert {...feedbackEvents} feedback={sourceData.feedback} />
                        <SpinnerOverlay isLoading={sourceData.inProgress}>
                            <Table responsive hover>
                                <thead>
                                    <TableHeaderRow
                                        onSortBy={(s) => tableHeaderRowEvents.onSortBy(s, principalId, currentRatesType, 'rates')}
                                        disabled={sourceData.inProgress}
                                        headers={[
                                            { sortBy: "Classification.Name", label: "Classification" },
                                            { sortBy: "EffectiveDate", label: "Effective Date" },
                                            { sortBy: "Rate", label: "Rate (excl. GST)" }
                                        ]}
                                    />
                                </thead>
                                <tbody>
                                    {
                                        sourceData.records.map((record, index) => (
                                            <ChargeRateRow key={index} {...record} disabled={sourceData.inProgress} />
                                        ))
                                    }
                                </tbody>
                            </Table>
                            <Paginator
                                onPageChange={(p) => paginatorEvents.onPageChange(p, principalId, currentRatesType)}
                                searchOptions={sourceData.searchOptions}
                            />
                        </SpinnerOverlay>
                    </div>
                </CardBody>
            </Card>
        )
    }
}

export default ChargeRateList