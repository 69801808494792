import React, { Component } from "react"
import Moment from "moment"
import { Redirect } from "react-router"
import TableHeaderRow from "components/common/TableHeaderRow"
import PropTypes from "prop-types"
import { Card, CardBody, CardHeader, Table } from "reactstrap"
import TimesheetRow from "./TimesheetRow"
import { TimesheetShape } from "shapes/TimesheetShape"
import { ModalActions } from "@gman/gman-redux"
import FeedbackAlert from "components/common/FeedbackAlert"
import TimesheetFormBasic from "components/timesheets/form/TimesheetFormBasic"
import TimesheetStatusConstants from "constants/TimesheetStatusConstants"
import PageSizeSelect from "components/common/PageSizeSelect"
import { AuthorizedAddButton } from "components/authentication/controls/AuthorizedControls"
import {
    ConfirmDelete,
    FormModal,
    Paginator,
    SpinnerOverlay
} from "@gman/gman-react-bootstrap"

const AddButton = AuthorizedAddButton(p => p.canCompleteOwnTimesheet)

class TimesheetList extends Component {

    componentDidMount() {
        var filter = {}
        if (this.props.status) {
            filter = {
                status: this.props.status
            }
            if (this.props.status == TimesheetStatusConstants.DRAFT) {
                filter.weekEnding = Moment()
            }
        }
        this.props.recordStateEvents.onClearSearch({ ...filter })
        this.props.onLoadDependentData()
    }

    componentWillUnmount = () => {
        this.props.feedbackEvents.onFlushFeedback()
        this.props.redirectEvents.onClearRedirect()
    }

    render() {
        var sourceData = this.props.sourceData
        var modals = sourceData.modals
        var confirmEvents = this.props.confirmEvents
        var feedbackEvents = this.props.feedbackEvents
        var modalEvents = this.props.modalEvents
        var paginatorEvents = this.props.paginatorEvents
        var tableHeaderRowEvents = this.props.tableHeaderRowEvents
        var canSelectUser = this.props.canSelectUser

        if (sourceData.redirect) {
            return (<Redirect to={sourceData.redirect} />)
        }

        return (
            <Card>
                <CardHeader>
                    <h6>Timesheets</h6>
                </CardHeader>
                <CardBody>
                    <FeedbackAlert {...feedbackEvents} feedback={sourceData.feedback} />
                    <SpinnerOverlay isLoading={sourceData.inProgress}>
                        {
                            modalEvents.onShowNew ? <AddButton disabled={sourceData.inProgress} styleClass="float-right mb-2" onClick={() => modalEvents.onShowNew()} /> : ""
                        }
                        <Table hover size="sm">
                            <thead>
                                <TableHeaderRow
                                    {...tableHeaderRowEvents}
                                    disabled={sourceData.inProgress}
                                    headers={[
                                        { sortBy: "WeekEnding", label: "Week Ending" },
                                        { sortBy: "Status", label: "Status" },
                                        { sortBy: "User.FirstName", label: "User" }
                                    ]}
                                />
                            </thead>
                            <tbody>
                                {
                                    sourceData.records.map((record, index) => (
                                        <TimesheetRow
                                            suffix={this.props.suffix}
                                            key={index}
                                            {...record}
                                            onShowDelete={() => modalEvents.onShowDelete(record)}
                                            onShowEdit={() => modalEvents.onShowEdit(record)}
                                            disabled={sourceData.inProgress} />
                                    ))
                                }
                            </tbody>
                        </Table>
                        <Paginator {...paginatorEvents} searchOptions={sourceData.searchOptions} />
                        <PageSizeSelect disabled={sourceData.inProgress} defaultPageSize={sourceData.searchOptions.pageSize} onChange={this.props.onPageSizeChange} />
                    </SpinnerOverlay>
                </CardBody>
                <FormModal
                    isVisible={ModalActions.IsDeleteVisible(sourceData.modals)}
                    onHide={modalEvents.onHideDelete}
                    title={"Confirm Delete"}>
                    <ConfirmDelete onConfirmDelete={() => confirmEvents.onConfirmDelete(sourceData.recordToUpdate)} />
                </FormModal>
                <FormModal
                    isVisible={ModalActions.IsUpdateVisible(modals)}
                    onHide={modalEvents.onHideEdit}
                    title={"Create Timesheet"}>
                    <TimesheetFormBasic
                        onSubmitUpdate={confirmEvents.onSubmitUpdate}
                        canSelectUser={canSelectUser}
                        {...sourceData.recordToUpdate} />
                </FormModal>
            </Card >
        )
    }
}

TimesheetList.propTypes = {
    sourceData: PropTypes.shape({
        records: PropTypes.arrayOf(TimesheetShape)
    })
}

export default TimesheetList