import ScopeConstants from "constants/ScopeConstants"
import { FormActionReducer } from "@gman/gman-redux"

export const ExpenseEditListReducer = (
    state = {
        ...FormActionReducer.defaultPageState(),
        ...FormActionReducer.defaultSearchOptions("Description", 10),
        ...FormActionReducer.defaultRecordState()
    },
    action) => {

    if (action.scope === ScopeConstants.EXPENSE_EDIT_LIST) {
        if (action.type === "CHANGE_PAGE_SIZE") {
            return {
                ...state,
                searchOptions: {
                    ...state.searchOptions,
                    pageSize: action.pageSize
                }
            }
        }
        return FormActionReducer.reduce(action, state)
    }

    return state
}