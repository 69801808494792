import ScopeConstants from "constants/ScopeConstants"
import { FormActionReducer } from "@gman/gman-redux"

export const TimesheetEntriesReducer = (
  state = {
    ...FormActionReducer.defaultPageState(),
    ...FormActionReducer.defaultSearchOptions("TimesheetEntryId", 10000),
    ...FormActionReducer.defaultRecordState(),
    linkedExpense: {}
  },
  action) => {

  if (action.scope === ScopeConstants.TIMESHEET_ENTRY) {
    if (action.type === "PRE_FILL_LINKED_EXPENSE") {
      return {
        ...state,
        linkedExpense: action.record
      }
    }
    return FormActionReducer.reduce(action, state)
  }

  return state
}