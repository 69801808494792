import { connect } from "react-redux"
import UserAuthenticationCardBasic from "components/userAuthentications/layout/UserAuthenticationCardBasic"
import { ClassificationScope } from "components/classifications/redux/ClassificationScope"
import { FormActions, FormEvents, ModalConstants } from "@gman/gman-redux"
import { UserAuthenticationScope } from "components/userAuthentications/redux/UserAuthenticationScope";

const mapDispatchToProps = (dispatch) => {
  var scope = UserAuthenticationScope
  return {
    ...FormEvents.getTableHeaderRowEvents(dispatch, scope),
    ...FormEvents.getFeedbackEvents(dispatch, scope),
    ...FormEvents.getPaginatorEvents(dispatch, scope),
    ...FormEvents.getRecordStateEvents(dispatch, scope),
    ...FormEvents.getConfirmEventsForModalsOnNewOnly(dispatch, scope),
    onLoadDependentData: () => {
      dispatch(FormActions.fetchAll(ClassificationScope))
    },
    onSubmitUpdate: (record) => {
      dispatch(FormActions.doPost(scope, record, scope.updateEndpoint, "The record was updated successfully.", false))
      dispatch(FormActions.hideModal(scope, ModalConstants.UPDATE))
    }
  }
}

const UserAuthenticationContainer = connect(
  (state, ownProps) => {
    return {
      sourceData: UserAuthenticationScope.selector(state),
      userId: ownProps.userId
    }
  },
  mapDispatchToProps
)(UserAuthenticationCardBasic)

export { UserAuthenticationContainer }