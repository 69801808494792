import { connect } from "react-redux"
import UserClassificationList from "components/userClassifications/layout/UserClassificationList"
import { ClassificationScope } from "components/classifications/redux/ClassificationScope"
import { FormActions, FormEvents } from "@gman/gman-redux"
import { UserClassificationScope } from "components/userClassifications/redux/UserClassificationScope";

const mapDispatchToProps = (dispatch) => {
  var scope = UserClassificationScope
  return {
    ...FormEvents.getTableHeaderRowEvents(dispatch, scope),
    ...FormEvents.getFeedbackEvents(dispatch, scope),
    ...FormEvents.getPaginatorEvents(dispatch, scope),
    ...FormEvents.getModalEventsForModalsOnAll(dispatch, scope),
    ...FormEvents.getRecordStateEvents(dispatch, scope),
    ...FormEvents.getConfirmEventsForModalsOnAll(dispatch, scope),
    onLoadDependentData: () => {
      dispatch(FormActions.fetchAll(ClassificationScope))
    }
  }
}

const UserClassificationListContainer = connect(
  (state, ownProps) => {
    return {
      sourceData: UserClassificationScope.selector(state),
      userId: ownProps.userId
    }
  },
  mapDispatchToProps
)(UserClassificationList)

export { UserClassificationListContainer }