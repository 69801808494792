import React from "react";
import Moment from "moment";
import { withFormik } from "formik";
import * as Yup from "yup";
import { SelectOption } from "@gman/gman-redux";
import Icon from "components/common/Icon";
import RequiredStar from "components/common/RequiredStar";
import CalculatedAmount from "components/common/CalculatedAmount";
import SelectStyles from "components/common/SelectStyles";
import {
    AuthorizedButton,
    AuthorizedInputField
} from "components/authentication/controls/AuthorizedControls";
import ExpenseTypeConstants from "constants/ExpenseTypeConstants";
import ScopeConstants from "constants/ScopeConstants";
import WorkflowToolbar from "components/common/WorkflowToolbar";
import VariationStatusConstants from "constants/VariationStatusConstants";
import VariationTypeConstants from "constants/VariationTypeConstants";
import ExpenseStatusConstants from "constants/ExpenseStatusConstants";
import ExpenseTaxStatusConstants from "constants/ExpenseTaxStatusConstants";
import FeedbackAlert from "components/common/FeedbackAlert";
import {
    Col,
    Container,
    Button,
    Form,
    FormGroup,
    Input,
    InputGroup,
    InputGroupAddon,
    InputGroupText,
    Label,
    Row
} from "reactstrap";
import { SpinnerOverlay } from "@gman/gman-react-bootstrap";

const SubmitButton = AuthorizedButton(p => p.canCompleteOwnExpenses);
const InputField = AuthorizedInputField(p => p.canCompleteOwnExpenses);

const handleStale = onMakeStale => {
    onMakeStale();
};
const handleRecalculate = (
    amountUnit,
    taxCalculated,
    taxStatus,
    expenseTypeId,
    effectiveDate,
    onRecalculate,
    index
) => {
    if (effectiveDate) {
        onRecalculate(
            amountUnit,
            taxCalculated,
            taxStatus,
            expenseTypeId,
            effectiveDate,
            index
        );
    }
};

const BaseForm = props => {
    const {
        values,
        errors,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
        setFieldValue,
        onRecalculate,
        onMakeStale,
        modals,
        modalEvents,
        onConfirmSubmit,
        onConfirmUnsubmit,
        onConfirmApprove,
        onConfirmReject,
        feedbackEvents,
        feedback,
        inProgress,
        dirty,
        systemData
    } = props;
    var isPaid = ((values.status || 0) == ExpenseStatusConstants.PAID) === true;
    return (
        <div>
            <SpinnerOverlay isLoading={inProgress}>
                <Form noValidate onSubmit={handleSubmit}>
                    {
                        feedback && feedbackEvents ? (
                            <FeedbackAlert {...feedbackEvents} feedback={feedback} />
                        ) : ("")
                    }
                    <h6>
                        <Icon folderOpen />
                        Project Details
                    </h6>
                    <hr />
                    <Container>
                        <Row>
                            <Col xs={12}>
                                <FormGroup>
                                    <RequiredStar />
                                    <Label for="workItem">Project</Label>
                                    <SelectOption
                                        styles={SelectStyles.colourStyles()}
                                        isClearable={true}
                                        disabled={isSubmitting}
                                        value={values.workItem}
                                        onChange={e => {
                                            var workItemId =
                                                e.target.value && e.target.value.workItemId;
                                            if (
                                                e.target.value &&
                                                e.target.value.type == ScopeConstants.PROJECT_ANY
                                            ) {
                                                setFieldValue("variationId", null);
                                                setFieldValue("proposalId", null);
                                                setFieldValue("leaveId", null);
                                                setFieldValue("projectId", workItemId);
                                            } else if (
                                                e.target.value &&
                                                e.target.value.type == ScopeConstants.PROPOSAL_ANY
                                            ) {
                                                setFieldValue("variationId", null);
                                                setFieldValue("costCodeId", "");
                                                setFieldValue("projectId", null);
                                                setFieldValue("leaveId", null);
                                                setFieldValue("proposalId", workItemId);
                                            } else if (
                                                e.target.value &&
                                                e.target.value.type == ScopeConstants.LEAVE
                                            ) {
                                                setFieldValue("variationId", null);
                                                setFieldValue("costCodeId", "");
                                                setFieldValue("projectId", null);
                                                setFieldValue("proposalId", null);
                                                setFieldValue("leaveId", workItemId);
                                            } else {
                                                setFieldValue("variationId", null);
                                                setFieldValue("costCodeId", "");
                                                setFieldValue("projectId", null);
                                                setFieldValue("proposalId", null);
                                                setFieldValue("leaveId", null);
                                            }
                                            handleChange(e);
                                        }}
                                        onBlur={handleBlur}
                                        id="workItem"
                                        invalid={errors.workItem && errors.workItem.length > 0}
                                        filterFunc={record =>
                                            record.type !== ScopeConstants.LEAVE ||
                                            (record.type === ScopeConstants.LEAVE &&
                                                (record.code === "MISC" ||
                                                    record.code === "R&D ACTIVITY"))
                                        }
                                        stateFunc={state => {
                                            return { ...state.workItems };
                                        }}
                                        valueFunc={record => record}
                                        labelFunc={record => record.name + " (" + record.code + ")"}
                                        sortFunc={(a, b) => a.name.localeCompare(b.name)}
                                        matchFunc={record => record.workItemId}
                                    />
                                    {
                                        errors.workItem && errors.workItem.workItemId && (
                                        <small className="text-danger">
                                            {errors.workItem.workItemId}
                                        </small>
                                        )
                                    }
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12}>
                                <FormGroup>
                                    <Label for="costCode">Cost Code</Label>
                                    <SelectOption
                                        isClearable={true}
                                        disableIfEmpty
                                        disabled={!values.projectId}
                                        value={values.costCode}
                                        onChange={e => {
                                            var costCodeId =
                                                e.target.value && e.target.value.costCodeId;
                                            if (e.target.value) {
                                                setFieldValue("costCodeId", costCodeId);
                                            } else {
                                                setFieldValue("costCodeId", "");
                                            }
                                            handleChange(e);
                                        }}
                                        onBlur={handleBlur}
                                        id="costCode"
                                        invalid={errors.costCode && errors.costCode.length > 0}
                                        stateFunc={state => {
                                            return { ...state.costCodes };
                                        }}
                                        valueFunc={record => ({
                                            costCodeId: record.costCodeId,
                                            code: record.code,
                                            description: record.description
                                        })}
                                        labelFunc={record =>
                                            record.code + " (" + record.description + ")"
                                        }
                                        filterFunc={record =>
                                            record.disabled === false &&
                                            record.costCodeGroupId ===
                                            (values.workItem && values.workItem.costCodeGroupId)
                                        }
                                        sortFunc={(a, b) => a.code.localeCompare(b.code)}
                                        matchFunc={record => record.costCodeId}
                                    />
                                    {
                                        errors.variation && (
                                        <small className="text-danger">{errors.variation}</small>
                                        )
                                    }
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12}>
                                <FormGroup>
                                    <Label for="workItem.variation">Variation Code</Label>
                                    <SelectOption
                                        isClearable={true}
                                        disableIfEmpty
                                        disabled={isSubmitting}
                                        value={values.variation}
                                        onChange={e => {
                                            var variationId =
                                                e.target.value && e.target.value.variationId;
                                            setFieldValue("variationId", variationId);
                                            handleChange(e);
                                        }}
                                        onBlur={handleBlur}
                                        id="variation"
                                        invalid={errors.variation && errors.variation.length > 0}
                                        stateFunc={state => {
                                            return { ...state.variations };
                                        }}
                                        valueFunc={record => ({
                                            variationId: record.variationId,
                                            number: record.number,
                                            description: record.description
                                        })}
                                        labelFunc={record =>
                                            (record.type === VariationTypeConstants.VARIATION ? "Variation" : "") +
                                            (record.type === VariationTypeConstants.RISK ? "Risk" : "") +
                                            " " + record.number + " (" + record.description + ")"
                                        }
                                        filterFunc={record =>
                                            record.projectId ===
                                            (values.workItem && values.workItem.workItemId) &&
                                            record.status !== VariationStatusConstants.DRAFT
                                        }
                                        sortFunc={(a, b) => a.number.localeCompare(b.number)}
                                        matchFunc={record => record.variationId}
                                    />
                                    {errors.variation && (
                                        <small className="text-danger">{errors.variation}</small>
                                    )}
                                </FormGroup>
                            </Col>
                        </Row>
                    </Container>
                    <h6>
                        <Icon expenseType />
                        Expense Details
                    </h6>
                    <hr />
                    <Container>
                        <Row>
                            <Col xs={12} md={6}>
                                <FormGroup>
                                    <RequiredStar />
                                    <Label for="description">Description</Label>
                                    <InputField
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.description || ""}
                                        type="text"
                                        name="description"
                                        id="description"
                                        placeholder="Enter a description"
                                        invalid={errors.description}
                                    />
                                    {errors.description && (
                                        <small className="text-danger">{errors.description}</small>
                                    )}
                                </FormGroup>
                            </Col>
                            <Col xs={12} md={6}>
                                <FormGroup>
                                    <RequiredStar />
                                    <Label for="effectiveDate">Expense Date</Label>
                                    <InputField
                                        onChange={e => {
                                            handleChange(e);
                                            handleStale(onMakeStale);
                                        }}
                                        onBlur={e => {
                                            handleBlur(e);
                                        }}
                                        value={
                                            values.effectiveDate ? Moment(values.effectiveDate).format("YYYY-MM-DD") : ""
                                        }
                                        type="date"
                                        name="effectiveDate"
                                        id="effectiveDate"
                                        placeholder="Date of expense"
                                        invalid={errors.effectiveDate}
                                    />
                                    {errors.effectiveDate && (
                                        <small className="text-danger">
                                            {errors.effectiveDate}
                                        </small>
                                    )}
                                </FormGroup>
                            </Col>
                        </Row>
                        {
                            values.expenses.map((item, index) => {
                                var isMileage = (item.expenseType && item.expenseType.code === ExpenseTypeConstants.MILEAGE) === true;
                                var isCustomTax = ((item.taxStatus || 0) == ExpenseTaxStatusConstants.CUSTOM) === true;
                                return (
                                    <Row>
                                        <Col xs={12} md={3}>
                                            <FormGroup>
                                                <Input
                                                    onChange={handleChange}
                                                    value={item.expenseTypeId || ""}
                                                    type="hidden"
                                                    name={`expenses[${index}].expenseTypeId`}
                                                    id="expenseTypeId"
                                                />
                                                <RequiredStar />
                                                <Label for="expenseTypeId">Expense Type</Label>
                                                <SelectOption
                                                    isClearable={true}
                                                    disabled={isSubmitting}
                                                    value={item.expenseType}
                                                    onChange={e => {
                                                        setFieldValue(
                                                            `expenses[${index}].expenseTypeId`,
                                                            e.target.value && e.target.value.expenseTypeId
                                                        );
                                                        setFieldValue(
                                                            `expenses[${index}].expenseType`,
                                                            e.target.value
                                                        );
                                                        handleChange(e);
                                                        if (
                                                            e.target.value &&
                                                            e.target.value.code === ExpenseTypeConstants.MILEAGE
                                                        ) {
                                                            setFieldValue(
                                                                `expenses[${index}].taxStatus`,
                                                                ExpenseTaxStatusConstants.TAXFREE
                                                            );
                                                        }
                                                        handleRecalculate(
                                                            item.amountUnit,
                                                            item.taxCalculated,
                                                            item.taxStatus,
                                                            e.target.value && e.target.value.expenseTypeId,
                                                            values.effectiveDate,
                                                            onRecalculate,
                                                            index
                                                        );
                                                    }}
                                                    onBlur={handleBlur}
                                                    id="expenseType"
                                                    name={`expenses[${index}].expenseType`}
                                                    invalid={
                                                        errors.expenseTypeId &&
                                                        errors.expenseTypeId.length > 0
                                                    }
                                                    stateFunc={state => {
                                                        return { ...state.expenseTypes };
                                                    }}
                                                    valueFunc={record => record}
                                                    labelFunc={record => record.description}
                                                    sortFunc={(a, b) => a.code - b.code}
                                                    matchFunc={record => record.expenseTypeId}
                                                />
                                                {
                                                    errors.expenseTypeId && (
                                                    <small className="text-danger">
                                                        {errors.expenseTypeId}
                                                    </small>
                                                    )
                                                }
                                            </FormGroup>
                                        </Col>
                                        <Col xs={12} md={3}>
                                            <FormGroup>
                                                {item.expenseType ? (
                                                    isMileage ?
                                                        (
                                                        <div>
                                                            <RequiredStar />
                                                            <Label for="amountUnit">Distance</Label>
                                                            <InputGroup>
                                                                <InputField
                                                                    onChange={e => {
                                                                        handleChange(e);
                                                                        handleStale(onMakeStale);
                                                                    }}
                                                                    onBlur={e => {
                                                                        handleBlur(e);
                                                                        handleRecalculate(
                                                                            e.target.value,
                                                                            item.taxCalculated,
                                                                            item.taxStatus,
                                                                            item.expenseTypeId,
                                                                            values.effectiveDate,
                                                                            onRecalculate,
                                                                            index
                                                                        );
                                                                    }}
                                                                    value={item.amountUnit || ""}
                                                                    type="text"
                                                                    id="amountUnit"
                                                                    name={`expenses[${index}].amountUnit`}
                                                                    invalid={errors.amountUnit}
                                                                />
                                                                <InputGroupAddon addonType="append">
                                                                    <InputGroupText>kms</InputGroupText>
                                                                </InputGroupAddon>
                                                            </InputGroup>
                                                            {
                                                                errors.amountUnit && (
                                                                <small className="text-danger">
                                                                    {errors.amountUnit}
                                                                </small>
                                                                )
                                                            }
                                                        </div>
                                                        ) : (
                                                            <div>
                                                                <RequiredStar />
                                                                <Label for="amountUnit">Amount (inc. GST)</Label>
                                                                <InputGroup>
                                                                    <InputGroupAddon addonType="prepend">
                                                                        <InputGroupText>$</InputGroupText>
                                                                    </InputGroupAddon>
                                                                    <InputField
                                                                        onChange={e => {
                                                                            handleChange(e);
                                                                            handleStale(onMakeStale);
                                                                        }}
                                                                        onBlur={e => {
                                                                            handleBlur(e);
                                                                            handleRecalculate(
                                                                                e.target.value,
                                                                                item.taxCalculated,
                                                                                item.taxStatus,
                                                                                item.expenseTypeId,
                                                                                values.effectiveDate,
                                                                                onRecalculate,
                                                                                index
                                                                            );
                                                                        }}
                                                                        value={item.amountUnit || ""}
                                                                        type="text"
                                                                        name={`expenses[${index}].amountUnit`}
                                                                        id="amountUnit"
                                                                        invalid={errors.amountUnit}
                                                                    />
                                                                </InputGroup>
                                                                {
                                                                    errors.amountUnit && (
                                                                    <small className="text-danger">
                                                                        {errors.amountUnit}
                                                                    </small>
                                                                    )
                                                                }
                                                            </div>
                                                        )
                                                ) : ("")}
                                            </FormGroup>
                                        </Col>
                                        {isMileage ? (
                                            <Col xs={12} md={{ size: 3, offset: 3 }}>
                                                <Label for="amountCalculated">Calculated Amount</Label>
                                                <InputGroup>
                                                    <InputGroupAddon addonType="prepend">
                                                        <InputGroupText>$</InputGroupText>
                                                    </InputGroupAddon>
                                                    <CalculatedAmount
                                                        stateFunc={state => {
                                                            return { ...state.expenseCalculations };
                                                        }}
                                                        valueFunc={state => {
                                                            return (
                                                                state.calculation[index] &&
                                                                state.calculation[index].amountCalculated
                                                            );
                                                        }}
                                                        loadingFunc={state => {
                                                            return state.isLoading;
                                                        }}
                                                        staleFunc={state => {
                                                            return state.isStale;
                                                        }}
                                                        onChange={(data) => {
                                                            setFieldValue(`expenses[${index}].calculatedAmount`, data);
                                                        }}
                                                    />
                                                </InputGroup>
                                                {
                                                    systemData &&
                                                    values.expenses &&
                                                    values.expenses[index] &&
                                                    values.expenses[index].calculatedAmount >= systemData.dailyMileageClaimLimit && (
                                                        <small className="text-danger">
                                                            Mileage cap reached
                                                        </small>
                                                    )
                                                }
                                            </Col>
                                        ) : ("")}
                                        {!isMileage ? (
                                            <Col xs={12} md={3}>
                                                <Label for="taxCalculated">GST Amount</Label>
                                                <InputGroup>
                                                    <InputGroupAddon addonType="prepend">
                                                        <InputGroupText>$</InputGroupText>
                                                    </InputGroupAddon>
                                                    <InputField
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        value={item.taxCalculated || ""}
                                                        type={isCustomTax ? "text" : "hidden"}
                                                        name={`expenses[${index}].taxCalculated`}
                                                        id="taxCalculated"
                                                        invalid={errors.taxCalculated}
                                                    />
                                                    {!isCustomTax ? (
                                                        <CalculatedAmount
                                                            stateFunc={state => {
                                                                return { ...state.expenseCalculations };
                                                            }}
                                                            valueFunc={state => {
                                                                return (
                                                                    state.calculation[index] &&
                                                                    state.calculation[index].taxCalculated
                                                                );
                                                            }}
                                                            loadingFunc={state => {
                                                                return state.isLoading;
                                                            }}
                                                            staleFunc={state => {
                                                                return state.isStale;
                                                            }}
                                                        />
                                                    ) : ("")}
                                                </InputGroup>
                                                {
                                                    errors.taxCalculated && (
                                                    <small className="text-danger">
                                                        {errors.taxCalculated}
                                                    </small>
                                                    )
                                                }
                                            </Col>
                                        ) : ("")}
                                        {!isMileage ? (
                                            <Col xs={12} md={3}>
                                                <FormGroup check>
                                                    <Label check>
                                                        <Input
                                                            onChange={e => {
                                                                handleChange(e);
                                                                handleRecalculate(
                                                                    item.amountUnit,
                                                                    item.taxCalculated,
                                                                    e.target.value,
                                                                    item.expenseTypeId,
                                                                    values.effectiveDate,
                                                                    onRecalculate,
                                                                    index
                                                                );
                                                                setFieldValue(
                                                                    `expenses[${index}].isCustomTax`,
                                                                    ((e.target.value || 0) ==
                                                                        ExpenseTaxStatusConstants.CUSTOM) ===
                                                                    true
                                                                );
                                                                setFieldValue(
                                                                    `expenses[${index}].taxCalculated`,
                                                                    0
                                                                );
                                                            }}
                                                            onBlur={handleBlur}
                                                            checked={
                                                                ((item.taxStatus || 0) ==
                                                                    ExpenseTaxStatusConstants.CALCULATED) ===
                                                                true
                                                            }
                                                            type="radio"
                                                            name={`expenses[${index}].taxStatus`}
                                                            value={0}
                                                        />{" "}
                                                        GST-inclusive
                                                    </Label>
                                                </FormGroup>
                                                <br />
                                                <FormGroup check>
                                                    <Label check>
                                                        <Input
                                                            onChange={e => {
                                                                handleChange(e);
                                                                handleRecalculate(
                                                                    item.amountUnit,
                                                                    item.taxCalculated,
                                                                    e.target.value,
                                                                    item.expenseTypeId,
                                                                    values.effectiveDate,
                                                                    onRecalculate,
                                                                    index
                                                                );
                                                                setFieldValue(
                                                                    `expenses[${index}].isCustomTax`,
                                                                    ((e.target.value || 0) ==
                                                                        ExpenseTaxStatusConstants.CUSTOM) ===
                                                                    true
                                                                );
                                                                setFieldValue(
                                                                    `expenses[${index}].taxCalculated`,
                                                                    0
                                                                );
                                                            }}
                                                            onBlur={handleBlur}
                                                            checked={
                                                                ((item.taxStatus || 0) ==
                                                                    ExpenseTaxStatusConstants.TAXFREE) ===
                                                                true
                                                            }
                                                            type="radio"
                                                            name={`expenses[${index}].taxStatus`}
                                                            value={1}
                                                        />{" "}
                                                        GST-free
                                                    </Label>
                                                </FormGroup>
                                                <br />
                                                <FormGroup check>
                                                    <Label check>
                                                        <Input
                                                            onChange={e => {
                                                                handleChange(e);
                                                                handleRecalculate(
                                                                    item.amountUnit,
                                                                    item.taxCalculated,
                                                                    e.target.value,
                                                                    item.expenseTypeId,
                                                                    values.effectiveDate,
                                                                    onRecalculate,
                                                                    index
                                                                );
                                                                setFieldValue(
                                                                    `expenses[${index}].isCustomTax`,
                                                                    ((e.target.value || 0) ==
                                                                        ExpenseTaxStatusConstants.CUSTOM) ===
                                                                    true
                                                                );
                                                                setFieldValue(
                                                                    `expenses[${index}].taxCalculated`,
                                                                    0
                                                                );
                                                            }}
                                                            onBlur={handleBlur}
                                                            checked={
                                                                ((item.taxStatus || 0) ==
                                                                    ExpenseTaxStatusConstants.CUSTOM) ===
                                                                true
                                                            }
                                                            type="radio"
                                                            name={`expenses[${index}].taxStatus`}
                                                            value={2}
                                                        />{" "}
                                                        Custom tax amount
                                                    </Label>
                                                </FormGroup>
                                                <Input
                                                    onChange={handleChange}
                                                    value={item.isCustomTax}
                                                    type="hidden"
                                                    name={`expenses[${index}].isCustomTax`}
                                                    id="isCustomTax"
                                                />
                                            </Col>
                                        ) : ("")}
                                        <Col xs={3} md={3}></Col>
                                        <Col xs={4} md={3}>
                                            <FormGroup>
                                                <Label for="reimburseMe">
                                                    <Input
                                                        onChange={e => {
                                                            handleChange(e);
                                                            setFieldValue(
                                                                `expenses[${index}].reimburseMe`,
                                                                e.target.checked
                                                            );
                                                        }}
                                                        checked={item.reimburseMe}
                                                        value={item.reimburseMe}
                                                        type="checkbox"
                                                        name={`expenses[${index}].reimburseMe`}
                                                        id="reimburseMe"
                                                    />
                                                    Reimburse Me
                                                </Label>
                                            </FormGroup>
                                        </Col>
                                        <Col xs={4} md={3}>
                                            <FormGroup>
                                                <Label for="chargeClient">
                                                    <Input
                                                        onChange={e => {
                                                            handleChange(e);
                                                            setFieldValue(
                                                                `expenses[${index}].chargeClient`,
                                                                e.target.checked
                                                            );
                                                        }}
                                                        checked={item.chargeClient}
                                                        value={item.chargeClient}
                                                        type="checkbox"
                                                        name={`expenses[${index}].chargeClient`}
                                                        id="chargeClient"
                                                    />
                                                    Charge Client
                                                </Label>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                );
                            })
                        }
                    </Container>
                    <br />
                    <Container>
                        <Row>
                            {!values.expenseId ? (
                                <Col>
                                    <Button
                                        outline
                                        size="sm"
                                        color="info"
                                        type="button"
                                        onClick={() =>
                                            addNewExpense(values.expenses, setFieldValue)
                                        }>
                                        + Add another expense
                                    </Button>
                                </Col>
                                ) : ("")}
                                <Col>
                                    {errors.isPaid && (
                                        <small className="float-right text-danger">
                                            {errors.isPaid}
                                        </small>
                                    )}
                                </Col>
                        </Row>
                        <br />
                        <Row>
                            <Col>
                                <SubmitButton
                                    outline
                                    className="float-right"
                                    disabled={isSubmitting || !dirty || isPaid}
                                    color="success"
                                    type="submit">
                                    Save & Submit
                                </SubmitButton>
                            </Col>
                        </Row>
                    </Container>
                    <br />
                    <Container>
                        <Row>
                            <Col>
                                <WorkflowToolbar
                                    {...values}
                                    {...modals}
                                    {...modalEvents}
                                    isSubmitted={
                                        values.status === ExpenseStatusConstants.SUBMITTED && !dirty
                                    }
                                    onConfirmApprove={() =>
                                        onConfirmApprove(CleanseItem(values)[0])
                                    }
                                    onConfirmReject={comments =>
                                        onConfirmReject({
                                            ...CleanseItem(values)[0],
                                            comments: comments
                                        })
                                    }
                                    isDraft={
                                        (values.status === ExpenseStatusConstants.DRAFT ||
                                            values.status === ExpenseStatusConstants.DECLINED) &&
                                        !dirty
                                    }
                                    onConfirmSubmit={() =>
                                        onConfirmSubmit(CleanseItem(values)[0])
                                    }
                                    onConfirmUnsubmit={() =>
                                        onConfirmUnsubmit(CleanseItem(values)[0])
                                    }
                                    workflowPermissions={values.workflowPermissions}
                                />
                            </Col>
                        </Row>
                    </Container>
                </Form>
            </SpinnerOverlay>
        </div>
    );
};

const validationSchema = Yup.object({
    workItem: Yup.object().shape({
        workItemId: Yup.string()
            .required("Project is required")
            .nullable()
    }),

    description: Yup.string().required("Description is required"),
    effectiveDate: Yup.date()
        .required("Expense date is required")
        .typeError("Must be a date"),
    isPaid: Yup.number().when("status", {
        is: ExpenseStatusConstants.PAID,
        then: Yup.number().required("Paid expenses cannot be edited")
    }),
    expenses: Yup.array()
        .of(
            Yup.object().shape({
                amountUnit: requiredNumberValidation(),
                taxCalculated: Yup.number().when("isCustomTax", {
                    is: true,
                    then: requiredNumberValidation()
                }),
                expenseTypeId: Yup.string()
                    .nullable()
                    .required("Expense Type is required")
            })
        )
        .min(0, "Please add at least one Expense")
});

function requiredNumberValidation() {
    return numberValidation().required("Value is required");
}

function numberValidation() {
    return Yup.number()
        .nullable()
        .round()
        .min(0, "Must be positive")
        .typeError("Must be a number");
}

const CleanseItem = values => {
    const data = values.expenses.map(item => ({
        expenseId: values.expenseId,
        effectiveDate: values.effectiveDate,
        status: values.status,
        description: values.description,
        costCodeId: values.costCodeId,
        projectId: values.projectId,
        proposalId: values.proposalId,
        leaveId: values.leaveId,
        userId: values.userId,
        variationId: values.variationId,
        amountUnit: item.amountUnit,
        amountCalculated: item.amountCalculated,
        taxCalculated: item.taxCalculated,
        expenseTypeId: item.expenseTypeId,
        taxStatus: item.taxStatus,
        reimburseMe: item.reimburseMe,
        chargeClient: item.chargeClient,
        dateSubmitted: item.dateSubmitted,
        dateApproved: item.dateApproved,
        costItemStatus: item.costItemStatus
    }));
    return data;
};
const addNewExpense = (values, setFieldValue) => {
    const data = [
        ...values,
        {
            expenseType: "",
            amountUnit: 0,
            amountCalculated: 0,
            taxCalculated: 0,
            expenseTypeId: "",
            taxStatus: ExpenseTaxStatusConstants.CALCULATED,
            isCustomTax: false,
            reimburseMe: true,
            chargeClient: true
        }
    ];
    setFieldValue("expenses", data);
};
const initialValues = value => {
    const data = value;
    if (data.expenseType) {
        data.expenses = [
            {
                expenseType: data.expenseType,
                amountUnit: data.amountUnit,
                amountCalculated: data.amountCalculated,
                taxCalculated: data.taxCalculated,
                expenseTypeId: data.expenseTypeId,
                taxStatus: data.taxStatus,
                reimburseMe: data.reimburseMe,
                chargeClient: data.chargeClient,
                isCustomTax:
                    ((data.taxStatus || 0) == ExpenseTaxStatusConstants.CUSTOM) === true,
                dateSubmitted: data.dateSubmitted,
                dateApproved: data.dateApproved,
                costItemStatus: data.costItemStatus
            }
        ];
    }
    else {
        data.expenses = [
            {
                expenseType: "",
                amountUnit: 0,
                amountCalculated: 0,
                taxCalculated: 0,
                expenseTypeId: "",
                taxStatus: ExpenseTaxStatusConstants.CALCULATED,
                isCustomTax: false,
                reimburseMe: true,
                chargeClient: true
            }
        ];
    } 
    delete data.expenseType;
    delete data.amountUnit;
    delete data.amountCalculated;
    delete data.taxCalculated;
    delete data.expenseTypeId;
    delete data.taxStatus;
    delete data.isCustomTax;
    return data;
};

const ExpenseFormBasic = withFormik({
    displayName: "ExpenseFormBasic",
    enableReinitialize: true,
    validationSchema: validationSchema,
    mapPropsToValues: ({ ...props }) => initialValues(props),
    handleSubmit: (values, { props, setSubmitting }) => {
        var expense = CleanseItem(values);
        props.onConfirmSaveAndSubmit(expense);
        setSubmitting(false);
    }
})(BaseForm);

export default ExpenseFormBasic;
