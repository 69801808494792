import React from "react"
import { AuthorizedSummaryToolbar } from "components/authentication/controls/AuthorizedControls"

const Toolbar = AuthorizedSummaryToolbar(p => p.canWriteExpenseTypes)

const ExpenseTypeRow = (
    {
        description,
        code,
        onShowEdit,
        onShowDelete,
        disabled
    }) => (
        <tr>
            <td>{description}</td>
            <td>{code}</td>
            <td>
                <Toolbar disabled={disabled} onShowEdit={onShowEdit} onShowDelete={onShowDelete} />
            </td>
        </tr>
    )

export default ExpenseTypeRow 