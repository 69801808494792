import ScopeConstants from "constants/ScopeConstants"
import { ScopeShape } from "@gman/gman-redux"
import PropTypes from "prop-types"

export const ReportProjectFinancialsScope  = {
    key: ScopeConstants.REPORT_PROJECT_FINANCIALS,
    selector: (state) => {
        return state.reportProjectFinancials
    },
    tokenSelector: (state) => state.authentication.token
}

ReportProjectFinancialsScope.propTypes = PropTypes.shape(ScopeShape).isRequired