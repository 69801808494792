import React, { Component } from "react"
import {
    ExpenseListMineContainer,
    ExpenseListTeamContainer,
    ExpenseListAnyContainer,
    ExpenseListMineByDueDateContainer,
    ExpenseListAnyByDueDateContainer,
    ExpenseListTeamByDueDateContainer
} from "components/expenses/redux/ExpenseContainers"

export class ExpenseMinePage extends Component { render() { return (<ExpenseListMineContainer status={this.props.match.params.status} {...this.props} />) } }
export class ExpenseTeamPage extends Component { render() { return (<ExpenseListTeamContainer status={this.props.match.params.status} {...this.props} />) } }
export class ExpenseAnyPage extends Component { render() { return (<ExpenseListAnyContainer status={this.props.match.params.status} {...this.props} />) } }
export class ExpenseListMineByDueDatePage extends Component { render() { return (<ExpenseListMineByDueDateContainer duedate={this.props.match.params.duedate} {...this.props} />) } }
export class ExpenseListAnyByDueDatePage extends Component { render() { return (<ExpenseListAnyByDueDateContainer duedate={this.props.match.params.duedate} {...this.props} />) } }
export class ExpenseListTeamByDueDatePage extends Component { render() { return (<ExpenseListTeamByDueDateContainer duedate={this.props.match.params.duedate} {...this.props} />) } }