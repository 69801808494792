import React from "react"
import PropTypes from "prop-types"
import Moment from "moment"
import WorkItemCell from "components/common/WorkItemCell"
import ExpenseStatusBadge from "components/common/ExpenseStatusBadge"
import { Input } from "reactstrap"
import ClientInvoiceStatusBadge from "components/common/ClientInvoiceStatusBadge"
import { withFormik } from "formik"
import InvoiceStatusConstants from "constants/InvoiceStatusConstants"
import ExpenseStatusConstants from "constants/ExpenseStatusConstants"

const BaseForm = (
    {
        index,
        values,
        onShowEdit,
        handleChange,
        setFieldValue,
        isOpneInModal,
        onCostItemStatusChanged
    }) => {

    let a = ""
    if (isOpneInModal) {
        a = "expnse_costItemStatus_modal";
    } else {
        a = "expnse_costItemStatus";
    }

    if (values[a + index] == undefined) {
        values[a + index] = values.costItemStatus;
    }

    if (values.costItemStatus == null) {
        values[a + index] = 0;
    }

    return (
        <tr>
            <td onClick={onShowEdit} style={{ whiteSpace: 'pre' }}> {values.user.firstName + ' ' + values.user.lastName} </td>
            <td onClick={onShowEdit} style={{ whiteSpace: 'pre' }}> {Moment(values.effectiveDate).format("DD/MM/YYYY")}</td>
            <td onClick={onShowEdit} className="weekend">
                <div style={{ width: '325px' }}>
                    {
                        values.workItem ? <WorkItemCell workItem={values.workItem} /> : ""
                    }
                </div>
            </td>
            <td onClick={onShowEdit} style={{ whiteSpace: 'pre' }}>{values.project != null ? values.project.clientProjectNumber : ''}</td>
            <td onClick={onShowEdit} style={{ whiteSpace: 'pre' }}> {values.description} </td>
            <td onClick={onShowEdit} style={{ whiteSpace: 'pre' }}> {values.expenseType.description} </td>
            <td onClick={onShowEdit} style={{ whiteSpace: 'pre' }}> {values.chargeClient ? 'Yes' : 'No'} </td>
            <td style={{ whiteSpace: 'pre' }}> {values.reimburseMe == true ? "Yes" : "No"} </td>
            <td onClick={onShowEdit} style={{ whiteSpace: 'pre' }}> {values.amountCalculated} </td>
            <td>
                <ExpenseStatusBadge value={values.status} />
            </td>
            <td className="text-center">
                <Input
                    type="radio"
                    value={0}
                    style={{ position: 'unset', margin: 0 }}
                    name={a + index}
                    checked={values[a + index] == 0 ? true : false}
                    disabled={values.status == ExpenseStatusConstants.NOT_REIMBURSABLE || (values.clientInvoice && values.clientInvoice.status != InvoiceStatusConstants.DRAFT)}
                    onChange={
                        (e) => {
                            handleChange(e);
                            onCostItemStatusChanged({
                                expenseId: values.expenseId,
                                costItemStatus: 0
                            });
                            setFieldValue(a + index, 0);
                        }
                    }
                />
            </td>
            <td className="text-center">
                <Input
                    type="radio"
                    value={1}
                    style={{ position: 'unset', margin: 0 }}
                    name={a + index}
                    checked={values[a + index] == 1 ? true : false}
                    disabled={values.status == ExpenseStatusConstants.NOT_REIMBURSABLE || (values.clientInvoice && values.clientInvoice.status != InvoiceStatusConstants.DRAFT)}
                    onChange={
                        (e) => {
                            handleChange(e);
                            onCostItemStatusChanged({
                                expenseId: values.expenseId,
                                costItemStatus: 1
                            });
                            setFieldValue(a + index, 1);
                        }
                    }
                />
            </td>
            <td className="text-center">
                <Input
                    type="radio"
                    value={2}
                    style={{ position: 'unset', margin: 0 }}
                    name={a + index}
                    checked={values[a + index] == 2 ? true : false}
                    disabled={values.status == ExpenseStatusConstants.NOT_REIMBURSABLE || (values.clientInvoice && values.clientInvoice.status != InvoiceStatusConstants.DRAFT)}
                    onChange={
                        (e) => {
                            handleChange(e);
                            onCostItemStatusChanged({
                                expenseId: values.expenseId,
                                costItemStatus: 2
                            });
                            setFieldValue(a + index, 2);
                        }
                    }
                />
            </td>
            <td>{values.clientInvoice ? values.clientInvoice.number : ""}</td>
            <td>
                {
                    values.clientInvoice ? <ClientInvoiceStatusBadge value={values.clientInvoice.status} /> : ""
                }
            </td>
        </tr>
    )
}
const ExpenseEditRow = withFormik({
    displayName: "ExpenseEditRow",
    enableReinitialize: true
})(BaseForm)


export default ExpenseEditRow