import React, { Component } from "react"
import { Route } from "react-router"
import { Layout } from "./components/Layout"
import { Home } from "./components/Home"
import { LoginPage } from "components/authentication/page/AuthenticationPages"
import { ChargeRatePageBasic, ChargeRateCardBasicPageExtended } from "components/chargeRates/page/ChargeRatePages"
import { ClassificationPage } from "components/classifications/page/ClassificationPages"
import { CostRatePageBasic } from "components/costRates/page/CostRatePage"
import { ClientPageBasic, ClientPageExtended } from "components/clients/page/ClientPages"
import { ClientInvoicePageBasic, ClientInvoicePageExtended } from "components/clientInvoices/page/ClientInvoicePages"
import { SubContractorInvoicePageBasic, SubContractorInvoicePageExtended } from "components/subContractorInvoices/page/SubContractorInvoicePages"
import { CostCodeGroupExtendedPage } from "components/costCodeGroups/page/CostCodeGroupPages"
import { ExpenseMinePage, ExpenseTeamPage, ExpenseAnyPage, ExpenseListMineByDueDatePage, ExpenseListAnyByDueDatePage, ExpenseListTeamByDueDatePage } from "components/expenses/page/ExpensePages"
import { ExpenseTypePageBasic } from "components/expenseTypes/page/ExpenseTypePages"
import { ExpenseMonthlyDueDatesPageBasic } from "components/expenseMonthlyDueDates/page/ExpenseMonthlyDueDatesPages"
import { OutstandingTimesheetPageMine, OutstandingTimesheetPageTeam, OutstandingTimesheetPageAll } from "components/outstandingTimesheets/page/OutstandingTimesheetPages"
import { OfficePageBasic } from "components/offices/page/OfficePages"
import { OpportunityMinePage, OpportunityAnyPage, OpportunityEditPage, OpportunityReadPage } from "components/opportunities/page/OpportunityPages"
import { ProjectMinePage, ProjectAnyPage, ProjectEditPage, ProjectReadPage } from "components/projects/page/ProjectPages"
import { ProposalMinePage, ProposalAnyPage, ProposalEditPage, ProposalReadPage } from "components/proposals/page/ProposalPages"
import { SystemPageBasic } from "components/system/page/SystemPages"
import { TimesheetHoursPage, UnpaidInvoicesPage, HistoricalInvoicesPage, ReportSubContractorInvoicesInvoicesPage, ReportIndividualInvoiceNumbersPage, ReportChargeRatesPage,ReportProjectFinancialsPage } from "components/reports/page/ReportPages"
import { TimesheetMinePage, TimesheetTeamPage, TimesheetAnyPage, TimesheetMineExtendedPage, TimesheetTeamExtendedPage, TimesheetAnyExtendedPage } from "components/timesheets/pages/TimesheetPages"
import { UserPageBasic, UserPageExtended } from "components/users/page/UserPages"
import { VariationPage } from "components/variations/page/VariationPages"
import { EditDataPage } from "components/editData/page/EditDataPages"
import { WorkbenchExportPage } from "components/workbenchExports/page/WorkbenchExportPages"
import {
    RestrictedRoute,
    UserIsAuthenticated,
    UserIsNotAuthenticated
} from "components/authentication/redux/AuthorizationWrappers"

export default class App extends Component {
    displayName = App.name

    render() {
        return (
            <Layout location={this.props.location}>
                <Route exact path="/" component={UserIsAuthenticated(Home)} />
                <Route path="/login" component={UserIsNotAuthenticated(LoginPage)} />
                <Route path="/costRate" exact component={RestrictedRoute(p => p.canReadChargeRates)(CostRatePageBasic)} />
                <Route path="/chargeRate/:currentRatesType" exact component={RestrictedRoute(p => p.canReadChargeRates)(ChargeRatePageBasic)} />
                <Route path="/chargeRate/:currentRatesType/:principalId" component={RestrictedRoute(p => p.canReadChargeRates)(ChargeRateCardBasicPageExtended)} />
                <Route path="/classification" component={RestrictedRoute(p => p.canReadClassifications)(ClassificationPage)} />
                <Route path="/client" component={RestrictedRoute(p => p.canReadClients)(ClientPageBasic)} />
                <Route path="/clientDetails/:clientId" component={RestrictedRoute(p => p.canReadClients)(ClientPageExtended)} />
                <Route path="/clientInvoice" component={RestrictedRoute(p => p.canMarkItemsAsPaid)(ClientInvoicePageBasic)} />
                <Route path="/clientInvoiceDetails/:clientInvoiceId" component={RestrictedRoute(p => p.canMarkItemsAsPaid)(ClientInvoicePageExtended)} />
                <Route path="/subContractorInvoice" component={RestrictedRoute(p => p.canWriteSubContractorInvoice || p.canMarkSubContractorInvoiceAsPaid)(SubContractorInvoicePageBasic)} />
                <Route path="/subContractorInvoiceDetails/:subContractorInvoiceId" component={RestrictedRoute(p => p.canWriteSubContractorInvoice || p.canMarkSubContractorInvoiceAsPaid)(SubContractorInvoicePageExtended)} />
                <Route path="/costCodeGroupDetails/:costCodeGroupId" component={RestrictedRoute(p => p.canReadCostCodes)(CostCodeGroupExtendedPage)} />
                <Route exact path="/expense/mine/" component={RestrictedRoute(p => p.canCompleteOwnExpenses)(ExpenseMinePage)} />
                <Route exact path="/expense/mine/:status" component={RestrictedRoute(p => p.canCompleteOwnExpenses)(ExpenseListMineByDueDatePage)} />
                <Route path="/expense/mine/due/:duedate?" component={RestrictedRoute(p => p.canCompleteOwnExpenses)(ExpenseListMineByDueDatePage)} />
                <Route exact path="/expense/team/" component={RestrictedRoute(p => p.canWriteWorkItems)(ExpenseTeamPage)} />
                <Route exact path="/expense/team/:status" component={RestrictedRoute(p => p.canCompleteOwnExpenses)(ExpenseListTeamByDueDatePage)} />
                <Route path="/expense/team/due/:duedate?" component={RestrictedRoute(p => p.canCompleteOwnExpenses)(ExpenseListTeamByDueDatePage)} />
                <Route exact path="/expense/any" component={RestrictedRoute(p => p.canReadAnyExpenses)(ExpenseAnyPage)} />
                <Route exact path="/expense/any/:status" component={RestrictedRoute(p => p.canCompleteOwnExpenses)(ExpenseListAnyByDueDatePage)} />
                <Route path="/expense/any/due/:duedate?" component={RestrictedRoute(p => p.canCompleteOwnExpenses)(ExpenseListAnyByDueDatePage)} />
                <Route path="/expenseType" component={RestrictedRoute(p => p.canReadExpenseTypes)(ExpenseTypePageBasic)} />
                <Route path="/expenseMonthlyDueDates" component={RestrictedRoute(p => p.canReadExpenseMonthlyDueDates)(ExpenseMonthlyDueDatesPageBasic)} />
                <Route path="/outstandingTimesheet/mine" component={RestrictedRoute(p => p.canCompleteOwnTimesheet)(OutstandingTimesheetPageMine)} />
                <Route path="/outstandingTimesheet/team" component={RestrictedRoute(p => p.canWriteWorkItems)(OutstandingTimesheetPageTeam)} />
                <Route path="/outstandingTimesheet/any" component={RestrictedRoute(p => p.canReadAnyTimesheets)(OutstandingTimesheetPageAll)} />
                <Route path="/office" component={RestrictedRoute(p => p.canReadOffices)(OfficePageBasic)} />
                <Route path="/opportunity/mine" component={RestrictedRoute(p => p.canWriteWorkItems)(OpportunityMinePage)} />
                <Route path="/opportunity/any" component={RestrictedRoute(p => p.canReadAllOpportunities)(OpportunityAnyPage)} />
                <Route path="/opportunityDetails/:opportunityId" component={RestrictedRoute(p => p.canWriteWorkItems)(OpportunityEditPage)} />
                <Route path="/opportunitySheet/:opportunityId" component={RestrictedRoute(p => p.canReadWorkItems)(OpportunityReadPage)} />
                <Route path="/project/any/" component={RestrictedRoute(p => p.canReadWorkItems)(ProjectAnyPage)} />
                <Route path="/project/mine/" component={RestrictedRoute(p => p.canWriteWorkItems)(ProjectMinePage)} />
                <Route path="/proposal/any/" component={RestrictedRoute(p => p.canReadWorkItems)(ProposalAnyPage)} />
                <Route path="/proposal/mine/" component={RestrictedRoute(p => p.canWriteWorkItems)(ProposalMinePage)} />
                <Route path="/proposalDetails/:proposalId" component={RestrictedRoute(p => p.canWriteWorkItems)(ProposalEditPage)} />
                <Route path="/proposalSheet/:proposalId" component={RestrictedRoute(p => p.canReadWorkItems)(ProposalReadPage)} />
                <Route path="/report/timesheetHours" component={RestrictedRoute(p => p.canReadReports)(TimesheetHoursPage)} />
                <Route path="/report/unpaidInvoices" component={RestrictedRoute(p => p.canViewUnpaidInvoices)(UnpaidInvoicesPage)} />
                <Route path="/report/historicalInvoices" component={RestrictedRoute(p => p.canViewHistoricalInvoices)(HistoricalInvoicesPage)} />
                <Route path="/report/subContractorInvoices" component={RestrictedRoute(p => p.canReadReports)(ReportSubContractorInvoicesInvoicesPage)} />
                <Route path="/report/individualInvoiceNumbers" component={RestrictedRoute(p => p.canReadReports)(ReportIndividualInvoiceNumbersPage)} />
                <Route path="/report/chargeRates" component={RestrictedRoute(p => p.canExportChargeRatesReport)(ReportChargeRatesPage)} />
                <Route path="/system" component={RestrictedRoute(p => p.canReadSystem)(SystemPageBasic)} />
                <Route path="/report/projectFinancials" component={RestrictedRoute(p => p.canReadReports)(ReportProjectFinancialsPage)} />
                <Route path="/timesheet/mine/:status?" component={RestrictedRoute(p => p.canCompleteOwnTimesheet)(TimesheetMinePage)} />
                <Route path="/timesheet/team/:status?" component={RestrictedRoute(p => p.canWriteWorkItems)(TimesheetTeamPage)} />
                <Route path="/timesheet/any/:status?" component={RestrictedRoute(p => p.canReadAnyTimesheets)(TimesheetAnyPage)} />
                <Route path="/timesheetEntry/mine/:timesheetId" component={RestrictedRoute(p => p.canCompleteOwnTimesheet)(TimesheetMineExtendedPage)} />
                <Route path="/timesheetEntry/team/:timesheetId" component={RestrictedRoute(p => p.canWriteWorkItems)(TimesheetTeamExtendedPage)} />
                <Route path="/timesheetEntry/any/:timesheetId" component={RestrictedRoute(p => p.canReadAnyTimesheets)(TimesheetAnyExtendedPage)} />
                <Route path="/user" component={RestrictedRoute(p => p.canReadUsers)(UserPageBasic)} />
                <Route path="/userDetails/:userId" component={RestrictedRoute(p => p.canReadUsers)(UserPageExtended)} />
                <Route path="/variation" component={VariationPage} />
                <Route path="/workbenchExport" component={RestrictedRoute(p => p.canViewWorkbenchExports)(WorkbenchExportPage)} />
            </Layout>
        )
    }
}
