import React, { Component } from "react"
import TableHeaderRow from "components/common/TableHeaderRow"
import PropTypes from "prop-types"
import { Button, Card, CardBody, CardHeader, FormGroup, Table, ButtonGroup } from "reactstrap"
import ExpenseRow from "./ExpenseRow"
import ExpenseDueDateRow from "./ExpenseDueDateRow"
import { ExpenseShape } from "shapes/ExpenseShape"
import { ModalActions } from "@gman/gman-redux"
import FeedbackAlert from "components/common/FeedbackAlert"
import RejectReasonForm from "components/common/RejectReasonForm"
import ExpenseFormBasic from "components/expenses/form/ExpenseFormBasic"
import { AuthorizedAddButton } from "components/authentication/controls/AuthorizedControls"
import ScopeConstants from "constants/ScopeConstants"
import PageSizeSelect from "components/common/PageSizeSelect"
import Moment from "moment"
import {
    ConfirmDelete,
    FormModal,
    Paginator,
    SpinnerOverlay
} from "@gman/gman-react-bootstrap"
import { FaDownload } from "react-icons/fa"

const AddButton = AuthorizedAddButton(p => p.canCompleteOwnExpenses)

class ExpenseByDueDateList extends Component {

    componentDidMount() {
        this.props.recordStateEvents.onClearSearch({ status: this.props.status })
        console.log("window.location.pathname",window.location.pathname.split("/")[2])
        if(window.location.pathname.split("/")[2] == "mine"){
            this.props.onLoadDependentData()
        }
    }

    componentWillUnmount = () => {
        this.props.feedbackEvents.onFlushFeedback()
    }

    render() {
        var sourceData = this.props.sourceData
        var modals = sourceData.modals
        var recordStateEvents = this.props.recordStateEvents
        var confirmEvents = this.props.confirmEvents
        var feedbackEvents = this.props.feedbackEvents
        var modalEvents = this.props.modalEvents
        var paginatorEvents = this.props.paginatorEvents
        var tableHeaderRowEvents = this.props.tableHeaderRowEvents
        var suffix = this.props.suffix;
        var systemData = this.props.systemData && this.props.systemData.length > 0 ? this.props.systemData[0] : {};
        return (
            <Card>
                <CardHeader>
                    <h6>{this.props.cardHeader}</h6>
                </CardHeader>
                <CardBody>
                    {
                        !ModalActions.IsUpdateVisible(modals) ?
                            <FeedbackAlert {...feedbackEvents} feedback={sourceData.feedback} />
                            : ""
                    }
                    <SpinnerOverlay isLoading={sourceData.inProgress}>
                        <Table hover size="sm">
                            <thead>
                                <TableHeaderRow
                                    {...tableHeaderRowEvents}
                                    disabled={sourceData.inProgress}
                                    headers={[
                                        {label: "Monthly Due Date"},
                                        {label: "Mileage Submitted"},
                                        {label: "Mileage Approved"},
                                        {label: "Mileage Paid"},
                                        {label: "Expense Submitted"},
                                        {label: "Expenses Approved"},
                                        {label: "Expenses Paid"},
                                    ]}
                                />
                            </thead>
                            <tbody>
                                {
                                    sourceData.records.map((record, index) => (
                                        <ExpenseDueDateRow
                                            key={index}
                                            index={index}
                                            {...record}
                                            disabled={sourceData.inProgress}
                                            suffix={suffix}
                                            onConfirmPaiedAllByDueDate={() => modalEvents.onConfirmPaiedAllByDueDate(record)}
                                            onConfirmApproveAllByDueDate={() => modalEvents.onConfirmApproveAllByDueDate(record)}
                                            onExport={() => this.props.onRunExport(record) }
                                        />
                                    ))
                                }
                            </tbody>
                        </Table>
                        <Paginator {...paginatorEvents} searchOptions={sourceData.searchOptions} />
                        <ButtonGroup className="float-right">
                        {
                            modalEvents.onShowNew ?
                                <AddButton disabled={sourceData.inProgress}
                                    onClick={() => modalEvents.onShowNew({ workflowPermissions: { canSubmit: true } })}
                                />
                                : ""
                            }
                            <Button outline size="sm" color="info" onClick={this.props.onRunExpenseExport}>
                                <FaDownload /> Download All
                            </Button>
                        </ButtonGroup>
                        <PageSizeSelect disabled={sourceData.inProgress} defaultPageSize={sourceData.searchOptions.pageSize} onChange={this.props.onPageSizeChange} />
                    </SpinnerOverlay>
                </CardBody>
                <FormModal
                    isVisible={ModalActions.IsVisible(modals, "EXPENSE_APPROVE_ALL_BY_DUE_DATE")}
                    onHide={() => modalEvents.onHideModal("EXPENSE_APPROVE_ALL_BY_DUE_DATE")}
                    title={"Confirm Approve All"}>
                    <FormGroup>
                        Approve all this expenses?
                        <Button
                            className="float-right"
                            color="success"
                            onClick={
                                () => {
                                    this.props.onConfirmApproveAllByDueDate(sourceData.recordToUpdate);
                                }
                            }>
                            Confirm
                        </Button>
                    </FormGroup>
                </FormModal>
                <FormModal
                    isVisible={ModalActions.IsVisible(modals, "EXPENSE_PAIED_ALL_BY_DUE_DATE")}
                    onHide={() => modalEvents.onHideModal("EXPENSE_PAIED_ALL_BY_DUE_DATE")}
                    title={"Confirm Mark as Paid"}>
                    <FormGroup>
                        Mark all this expense as paid?
                        <Button
                            className="float-right"
                            color="success"
                            onClick={
                                () => {
                                    this.props.onConfirmPaiedAllByDueDate(sourceData.recordToUpdate);
                                }
                            }>
                            Confirm
                        </Button>
                    </FormGroup>
                </FormModal>
                <FormModal
                    size="lg"
                    isVisible={ModalActions.IsUpdateVisible(modals)}
                    onHide={modalEvents.onHideEdit}
                    title={"Update Expense"}>
                    <ExpenseFormBasic
                        {...sourceData.recordToUpdate}
                        {...sourceData.calculation}
                        {...ConstructWorkItem(sourceData.recordToUpdate)}
                        inProgress={sourceData.inProgress}
                        systemData={systemData}
                        effectiveDate={
                            sourceData.recordToUpdate &&
                                sourceData.recordToUpdate.effectiveDate
                                ? sourceData.recordToUpdate.effectiveDate : ""
                        }
                        modals={sourceData.modals}
                        modalEvents={modalEvents}
                        feedback={sourceData.feedback}
                        feedbackEvents={feedbackEvents}
                        onConfirmApprove={this.props.onConfirmApprove}
                        onConfirmReject={this.props.onConfirmReject}
                        onConfirmSubmit={this.props.onConfirmSubmit}
                        onConfirmUnsubmit={this.props.onConfirmUnsubmit}
                        onConfirmSaveAndSubmit={(model) => {
                            this.props.onConfirmSaveAndSubmit(model, this.props.history);
                        }}
                        onSubmitUpdate={confirmEvents.onSubmitUpdate}
                        onRecalculate={(
                            amountUnit,
                            taxCalculated,
                            taxStatus,
                            expenseTypeId,
                            effectiveDate,
                            index
                        ) =>
                            this.props.onRecalculateAmount(
                                amountUnit,
                                taxCalculated,
                                taxStatus,
                                expenseTypeId,
                                effectiveDate,
                                index
                            )
                        }
                        onMakeStale={() => this.props.onMakeStale()}
                    />
                </FormModal>
            </Card >
        )
    }
}

const ConstructWorkItem = (record) => {
    return {
        workItem: {
            workItemId: (record.proposalId ? record.proposalId : record.projectId ? record.projectId : record.leaveId ? record.leaveId : null),
            costCodeGroupId: record.projectId ? record.project && record.project.costCodeGroupId : null
        },
        costCode: {
            costCodeId: record.costCodeId
        },
        type: ScopeConstants.PROJECT_ANY,
        variation: record.variation
    }
}

ExpenseByDueDateList.propTypes = {
    sourceData: PropTypes.shape({
        records: PropTypes.arrayOf(ExpenseShape)
    })
}

export default ExpenseByDueDateList