import React from "react"
import { withFormik } from "formik"
import * as Yup from "yup"
import RequiredStar from "components/common/RequiredStar"
import SelectStyles from "components/common/SelectStyles"
import { AuthorizedButton, AuthorizedSelectOption } from "components/authentication/controls/AuthorizedControls"
import { ResetPasswordContainer } from "components/resetPasswords/redux/ResetPasswordContainers"

import {
    Form,
    FormGroup,
    Label
} from "reactstrap"

const SubmitButton = AuthorizedButton(p => p.canWriteUsers)
const SelectOptions = AuthorizedSelectOption(p => p.canWriteWorkItems)

const BaseForm = props => {
    const {
        values,
        dirty,
        errors,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting
    } = props
    return (
        <div>
            <Form noValidate onSubmit={handleSubmit}>
                <FormGroup>
                    <RequiredStar />
                    <Label for="roleType">Role</Label>
                    <SelectOptions
                        styles={errors.roleType && errors.roleType.length > 0 ? SelectStyles.errorStyles() : {}}
                        isClearable={true}
                        disabled={isSubmitting}
                        value={values.roleType}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        id="roleType"
                        invalid={errors.roleType && errors.roleType.length > 0}
                        stateFunc={(state) => { return { ...state.roleTypes } }}
                        valueFunc={(record) => record.typeId}
                        labelFunc={(record) => record.description}
                    />
                    {errors.roleType && <small className="text-danger">{errors.roleType}</small>}
                </FormGroup>
                <SubmitButton outline className="float-right" disabled={isSubmitting || !dirty} color="success" type="submit">Submit</SubmitButton>
            </Form>
            <ResetPasswordContainer userAuthenticationId={values} />
        </div>
    )
}

const validationSchema = Yup.object({
    roleType: Yup.string()
        .required("Role type is required")
        .nullable()
})

const UserAuthenticationFormBasic = withFormik({
    displayName: "UserAuthenticationFormBasic",
    enableReinitialize: true,
    validationSchema: validationSchema,
    mapPropsToValues: ({ ...props }) => ({
        ...props,
        userAuthenticationId: props.userAuthenticationId
    }),
    handleSubmit: (values, { props, setSubmitting }) => {
        props.onSubmitUpdate(values)
        setSubmitting(false)
    }
})(BaseForm)

export default UserAuthenticationFormBasic