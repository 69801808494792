import ScopeConstants from "constants/ScopeConstants"

export const CostCodeGroupAllScope = {
    key: ScopeConstants.COST_CODE_GROUP_ALL,
    selector: (state) => state.costCodeGroupsAll,
    tokenSelector: (state) => state.authentication.token,
    fetchEndpoint: "/api/CostCodeGroup/Search/",
    singleEndpoint: "/api/CostCodeGroup/Get/",
    updateEndpoint: "/api/CostCodeGroup/Update/",
    deleteEndpoint: "/api/CostCodeGroup/Delete/",
    defaultRecord: {
        name: "",
        description: ""
    }
}