import React, { Component } from "react"
import { CostRateListContainer } from "components/costRates/redux/CostRateContainer"
import { Card, CardBody, CardHeader } from "reactstrap"
import Icon from "components/common/Icon"


export class CostRatePageBasic extends Component {
    render() {
        return (
            <Card>
                <CardHeader>
                    <h6>
                        <Icon chargeRate />
                        Cost Rates
                </h6>
                </CardHeader>
                <CardBody>
                    { <CostRateListContainer /> }
                </CardBody>
            </Card >
        )
    }
}