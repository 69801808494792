import React, { Component } from "react"
import PropTypes from "prop-types"
import { CostCodeShape } from "shapes/CostCodeShape"
import { Table } from "reactstrap"
import CostCodeRow from "components/costCodes/layout/CostCodeRow"
import CostCodeFormBasic from "components/costCodes/form/CostCodeFormBasic"
import TableHeaderRow from "components/common/TableHeaderRow"
import FeedbackAlert from "components/common/FeedbackAlert"
import { ModalActions } from "@gman/gman-redux"
import { AuthorizedAddButton } from "components/authentication/controls/AuthorizedControls"
import {
    ConfirmDelete,
    FormModal,
    Paginator,
    SpinnerOverlay
} from "@gman/gman-react-bootstrap"

const AddButton = AuthorizedAddButton(p => p.canWriteCostCodes)

class CostCodeList extends Component {

    componentDidMount() {
        this.props.recordStateEvents.onClearSearch({ costCodeGroupId: this.props.costCodeGroupId })
    }

    componentWillUnmount = () => {
        this.props.feedbackEvents.onFlushFeedback()
    }

    render() {
        var sourceData = this.props.sourceData
        var confirmEvents = this.props.confirmEvents
        var feedbackEvents = this.props.feedbackEvents
        var modalEvents = this.props.modalEvents
        var paginatorEvents = this.props.paginatorEvents
        var tableHeaderRowEvents = this.props.tableHeaderRowEvents

        return (
            <div>
                <FeedbackAlert {...feedbackEvents} feedback={sourceData.feedback} />
                <SpinnerOverlay isLoading={sourceData.inProgress}>
                    <Table responsive hover size="sm">
                        <thead>
                            <TableHeaderRow
                                {...tableHeaderRowEvents}
                                disabled={sourceData.inProgress}
                                headers={[
                                    { sortBy: "Code", label: "Code" },
                                    { sortBy: "Description", label: "Description" },
                                    { sortBy: "Disabled", label: "Disabled?" }
                                ]}
                            />
                        </thead>
                        <tbody>
                            {
                                sourceData.records.map((record, index) => (
                                    <CostCodeRow
                                        key={index}
                                        {...record}
                                        onShowDelete={() => modalEvents.onShowDelete(record)}
                                        onShowEdit={() => modalEvents.onShowEdit(record)}
                                        isDisabled={record.disabled}
                                        disabled={sourceData.inProgress} />
                                ))
                            }
                        </tbody>
                    </Table>
                    <Paginator {...paginatorEvents} searchOptions={sourceData.searchOptions} />
                    <AddButton disabled={sourceData.inProgress} onClick={() => modalEvents.onShowNew({ costCodeGroupId: this.props.costCodeGroupId })} />
                </SpinnerOverlay>

                <FormModal
                    isVisible={ModalActions.IsDeleteVisible(sourceData.modals)}
                    onHide={modalEvents.onHideDelete}
                    title={"Confirm Delete"}>
                    <ConfirmDelete onConfirmDelete={() => confirmEvents.onConfirmDelete(sourceData.recordToUpdate)} />
                </FormModal>
                <FormModal
                    size="lg"
                    isVisible={ModalActions.IsUpdateVisible(sourceData.modals)}
                    onHide={modalEvents.onHideEdit}
                    title={"Update Cost Code"}>
                    <CostCodeFormBasic onSubmitUpdate={confirmEvents.onSubmitUpdate}
                        {...sourceData.recordToUpdate} />
                </FormModal>
            </div>
        )
    }
}

CostCodeList.propTypes = {
    sourceData: PropTypes.shape({
        records: PropTypes.arrayOf(CostCodeShape)
    })
}

export default CostCodeList