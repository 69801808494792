import React from "react"
import { withFormik } from "formik"
import * as Yup from "yup"
import RequiredStar from "components/common/RequiredStar"
import { SelectOption } from "@gman/gman-redux"

import {
    Col,
    Button,
    Form,
    FormGroup,
    Input,
    Label
} from "reactstrap"

const BaseForm = props => {
    const {
        values,
        dirty,
        errors,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
        canSelectUser
    } = props
    return (
        <div>
            <Form noValidate onSubmit={handleSubmit}>
                {
                    canSelectUser ?
                        <Col xs={12}>
                            <FormGroup>
                                <RequiredStar />
                                <Label for="userId">User</Label>
                                <SelectOption
                                    isClearable={true}
                                    disabled={isSubmitting}
                                    value={values.userId}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    id="userId"
                                    invalid={errors.userId && errors.userId.length > 0}
                                    stateFunc={(state) => { return { ...state.users } }}
                                    valueFunc={(record) => record.userId}
                                    labelFunc={(record) => record.firstName + " " + record.lastName}
                                    filterFunc={(record) => (record.dateEnded === null)}
                                    sortFunc={(a, b) => a.firstName.localeCompare(b.firstName)}
                                />
                                {errors.userId && <small className="text-danger">{errors.userId}</small>}
                            </FormGroup>
                        </Col>
                        : ""
                }
                <Col xs={12}>
                    <FormGroup>
                        <RequiredStar />
                        <Label for="weekEnding">Week Ending</Label>
                        <SelectOption
                            isClearable={true}
                            disabled={isSubmitting}
                            value={values.weekEnding}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            id="weekEnding"
                            invalid={errors.weekEnding && errors.weekEnding.length > 0}
                            stateFunc={(state) => { return { ...state.weeksEnding } }}
                            valueFunc={(record) => record.date}
                            labelFunc={(record) => record.label}
                        />
                        {errors.weekEnding && <small className="text-danger">{errors.weekEnding}</small>}
                    </FormGroup>
                </Col>
                <Input
                    onChange={handleChange}
                    value={canSelectUser}
                    type="hidden"
                    name="canSelectUser"
                    id="canSelectUser"
                />
                <Button outline className="float-right" disabled={isSubmitting || !dirty} color="success" type="submit">Create</Button>
            </Form>
        </div>
    )
}

const validationSchema = Yup.object({
    weekEnding: Yup.string()
        .required("Week ending is required"),
    userId: Yup.string()
        .nullable()
        .when("canSelectUser", {
            is: true,
            then: Yup.string()
                .required("User is required")
        })
})

const TimesheetFormBasic = withFormik({
    displayName: "TimesheetFormBasic",
    enableReinitialize: true,
    validationSchema: validationSchema,
    initialValues: ({ value }) => ({
        weekEnding: value.weekEnding,
        userId: value.userId
    }),
    handleSubmit: (values, { props, setSubmitting }) => {
        props.onSubmitUpdate(values)
        setSubmitting(false)
    }
})(BaseForm)

export default TimesheetFormBasic