import React from "react"
import { Alert, UncontrolledAlert } from "reactstrap"
import { FeedbackConstants } from "@gman/gman-redux"

const FeedbackAlert = props => {
    var feedback = props.feedback

    var onDismiss = props.onDismissFeedback ? (id => props.onDismissFeedback(id)) : () => { }

    return (
        <div class="container">
            <div class="row">
                <div class="col-12">
                    {feedback.map((feedbackItem, index) =>
                    (
                        props.onDismissFeedback ?
                            <Alert
                                size="sm"
                                key={index}
                                color={MapStatusToColor(feedbackItem.status)}
                                toggle={() => onDismiss(feedbackItem.id)}>
                                {
                                    feedbackItem.messages.map((message, index) => (
                                        <p class="mb-0" key={index}>
                                            {message}
                                        </p>
                                    ))
                                }
                            </Alert>
                            :
                            <UncontrolledAlert
                                size="sm"
                                key={index}
                                color={MapStatusToColor(feedbackItem.status)}>
                                {
                                    feedbackItem.messages.map((message, index) => (
                                        <p class="mb-0" key={index}>
                                            {message}
                                        </p>
                                    ))
                                }
                            </UncontrolledAlert>
                    )
                    )}
                </div>
            </div>
        </div>
    )
}

const MapStatusToColor = value => {
    switch (value) {
        case FeedbackConstants.SUCCESS:
            return "success"
        case FeedbackConstants.WARNING:
            return "warning"
        case FeedbackConstants.ERROR:
            return "danger"
        default:
            return "info"
    }
}

export default FeedbackAlert