import React from "react"
import Currency from "components/common/Currency"
import VariationStatusBadge from "components/common/VariationStatusBadge"
import { AuthorizedSummaryToolbar } from "components/authentication/controls/AuthorizedControls"
import VariationTypeConstants from "constants/VariationTypeConstants"

const Toolbar = AuthorizedSummaryToolbar(p => p.canWriteWorkItems)

const VariationRow = (
    {
        number,
        description,
        status,
        type,
        daysExtension,
        variationFee,
        onShowEdit,
        onShowDelete,
        disabled
    }) => (
        <tr>
            <td>{number}</td>
            <td>{description}</td>
            <td>
                <VariationStatusBadge value={status} />
            </td>
            <td>
                {type === VariationTypeConstants.VARIATION ? "Variation" : ""}
                {type === VariationTypeConstants.RISK ? "Risk" : ""}
            </td>
            <td>{daysExtension}</td>
            <td>{Currency(variationFee)}</td>
            <td>
                <Toolbar disabled={disabled} onShowEdit={onShowEdit} onShowDelete={onShowDelete} />
            </td>
        </tr>
    )

export default VariationRow 