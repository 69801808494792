import ScopeConstants from "constants/ScopeConstants"
import { FormActionReducer } from "@gman/gman-redux"

export const CostCodesReducer = (
  state = {
    ...FormActionReducer.defaultPageState(),
    ...FormActionReducer.defaultSearchOptions("Code"),
    ...FormActionReducer.defaultRecordState(),
    ...FormActionReducer.defaultTabs()
  },
  action) => {

  if (action.scope === ScopeConstants.COST_CODE) {
    return FormActionReducer.reduce(action, state)
  }

  return state
}