import ScopeConstants from "constants/ScopeConstants"

export const CostCodeScope = {
    key: ScopeConstants.COST_CODE,
    selector: (state) => state.costCodes,
    tokenSelector: (state) => state.authentication.token,
    fetchEndpoint: "/api/CostCode/Search/",
    singleEndpoint: "/api/CostCode/Get/",
    updateEndpoint: "/api/CostCode/Update/",
    deleteEndpoint: "/api/CostCode/Delete/",
    defaultRecord: {
        code: "",
        description: ""
    }
}