import React, { useEffect, useState } from "react";
import TableHeaderRow from "components/common/TableHeaderRow";
import { Card, CardBody, CardHeader, Table } from "reactstrap";
import ClassificationRow from "components/classifications/layout/ClassificationRow";
import ClassificationFormBasic from "components/classifications/form/ClassificationFormBasic";
import { ModalActions } from "@gman/gman-redux";
import Icon from "components/common/Icon";
import FeedbackAlert from "components/common/FeedbackAlert";
import { AuthorizedAddButton } from "components/authentication/controls/AuthorizedControls";
import {
  ConfirmDelete,
  FormModal,
  Paginator,
  SpinnerOverlay
} from "@gman/gman-react-bootstrap";

const AddButton = AuthorizedAddButton(p => p.canWriteClassifications);
let clientChargeRate = "sameAsStandardChargeRate";

const ClassificationList = (props) => {
  const sourceData = props.sourceData;
  const modals = sourceData.modals;
  const confirmEvents = props.confirmEvents;
  const feedbackEvents = props.feedbackEvents;
  const modalEvents = props.modalEvents;
  const paginatorEvents = props.paginatorEvents;
  const tableHeaderRowEvents = props.tableHeaderRowEvents;
  const arrRadios1 = [
    {
      id: "1",
      text: "sameAsStandardChargeRate",
      name: "Same as standard charge rate",
      checked: true
    },
    {
      id: "2",
      text: "asPerClassification",
      name: "As per existing classification",
      checked: false
    }
  ];
  // const [arrRadios, setArrRadios] = useState(arrRadios1);

  const [arrRadios, setArrRadios] = useState(arrRadios1);

  useEffect(() => {
    props.recordStateEvents.onClearSearch();
    // Specify how to clean up after this effect:
    return function cleanup() {
      props.feedbackEvents.onFlushFeedback();
    };
  }, []);
  useEffect(() => {
    if (sourceData.searchOptions.totalRecords !== sourceData.records.length) props.onShowAllRecords();

    console.log(sourceData.recordToUpdate, 'sourceData.recordToUpdate')
  }, [sourceData]);


  const handleRadioChange = (e) => {

    console.log(e.target.value);
    arrRadios.forEach((a) => {
      if (a.id === e.target.value) {
        a.checked = true;
      } else {
        a.checked = false;
        dispatchEvent(new Event(`rb-deselect-${e.target.value}-${a.id}`));
      }
    });

    console.log(arrRadios, 'arrRadios', sourceData.records)
    setArrRadios([...arrRadios]);
  };

  return (
    <Card>
      <CardHeader>
        <h6>
          <Icon school />
          Classifications
        </h6>
      </CardHeader>
      <CardBody>
        <FeedbackAlert {...feedbackEvents} feedback={sourceData.feedback} />
        <SpinnerOverlay isLoading={sourceData.inProgress}>
          <Table responsive hover size="sm">
            <thead>
              <TableHeaderRow
                {...tableHeaderRowEvents}
                disabled={sourceData.inProgress}
                headers={[
                  { sortBy: "Name", label: "Name" },
                  { sortBy: "Description", label: "Description" }
                ]}
              />
            </thead>
            <tbody>
              {sourceData.records.map((record, index) => (
                <ClassificationRow
                  key={index}
                  {...record}
                  onShowDelete={() => modalEvents.onShowDelete(record)}
                  onShowEdit={() => modalEvents.onShowEdit(record)}
                  disabled={sourceData.inProgress}
                />
              ))}
            </tbody>
          </Table>
          <Paginator
            {...paginatorEvents}
            searchOptions={sourceData.searchOptions}
          />
          <AddButton
            disabled={sourceData.inProgress}
            onClick={() => modalEvents.onShowNew()}
          />
        </SpinnerOverlay>

        <FormModal
          isVisible={ModalActions.IsDeleteVisible(modals)}
          onHide={modalEvents.onHideDelete}
          title={"Confirm Delete"}
        >
          <ConfirmDelete
            onConfirmDelete={() =>
              confirmEvents.onConfirmDelete(sourceData.recordToUpdate)
            }
          />
        </FormModal>
        <FormModal
          isVisible={ModalActions.IsUpdateVisible(modals)}
          onHide={modalEvents.onHideEdit}
          title={"Update Classification"}
        >
          <ClassificationFormBasic
            onSubmitUpdate={confirmEvents.onSubmitUpdate}
            arrRadios={arrRadios}
            classificationList={sourceData.records}
            handleRadioChange={handleRadioChange}
            {...sourceData.recordToUpdate}
          />
        </FormModal>
      </CardBody>
    </Card>
  );
};

export default ClassificationList;
