import React from "react"
import { ModalActions } from "@gman/gman-redux"
import { FormModal } from "@gman/gman-react-bootstrap"
import RejectReasonForm from "components/common/RejectReasonForm"
import {Button, ButtonGroup, FormGroup, Modal, ModalFooter, ModalHeader, ModalBody} from "reactstrap"


import Icon from "components/common/Icon"
const WorkflowToolbar = props => {
    const {
        isDraft,
        isSubmitted,
        workflowPermissions,
        onConfirmSubmit,
        onConfirmUnsubmit,
        onConfirmApprove,
        onConfirmReject,
        onConfirmClosed,
        onShowModal,
        onHideModal,
        modals,
        SourceDataRecord
    } = props
    console.log(SourceDataRecord,'SourceData')
    let showTable = false;
    let SourceDataRecordArray = [];
   let miscSourceDataRecordArray = [];
   let uniqueObject = {};
    console.log(SourceDataRecord , 'SourceDataRecord')
    if (SourceDataRecord && SourceDataRecord.records.length) {

        for (var i in SourceDataRecord.records) {
            if (SourceDataRecord.records[i].project !== null) {

                let objTitle = SourceDataRecord.records[i].project.projectId;

                uniqueObject[objTitle] = SourceDataRecord.records[i];

            }

        }

        for (i in uniqueObject) {

            SourceDataRecordArray.push(uniqueObject[i]);

        }

        SourceDataRecordArray.map(records => {

            if (records.project !== null && records.project.timesheetMessage !== null){

                showTable = true;

            }

        })

        SourceDataRecord && SourceDataRecord.records.length && SourceDataRecord.records.map((records) => {
            if (records.systemMsg !== null) {
                showTable = true;
                miscSourceDataRecordArray.push(records)
            }
        })
        console.log(miscSourceDataRecordArray , SourceDataRecordArray,'SourceDataRecordArray')

    }
    return (
        <div>
            <ButtonGroup className="float-right">
                {workflowPermissions && workflowPermissions.canSubmit && onConfirmSubmit ? (
                    <Button outline disabled={!isDraft} color="primary" onClick={() => onShowModal("SUBMIT")}>
                        Submit
                    </Button>
                ) : ("")}

                {workflowPermissions && workflowPermissions.canSubmit && onConfirmUnsubmit ? (
                    <Button outline disabled={!isSubmitted} color="warning" onClick={() => onShowModal("UNSUBMIT")}>
                        Un-submit
                    </Button>
                ) : ("")}

                {workflowPermissions && workflowPermissions.canApprove && onConfirmApprove ? (
                    <Button outline disabled={!isSubmitted} color="primary" onClick={() => onShowModal("APPROVE")}>
                        Approve
                    </Button>
                ) : ("")}

                {workflowPermissions && workflowPermissions.canApprove && onConfirmClosed ? (
                    <Button outline disabled={!isSubmitted} color="primary" onClick={() => onShowModal("CLOSE")}>
                        Close
                    </Button>
                ) : ("")}

                {workflowPermissions && workflowPermissions.canApprove && onConfirmReject ? (
                    <Button outline disabled={!isSubmitted} color="danger" onClick={() => onShowModal("REJECT")}>
                        Reject
                    </Button>
                ) : ("")}
            </ButtonGroup>

            {workflowPermissions && workflowPermissions.canSubmit ? (
                <div>

                    <Modal  isOpen={ModalActions.IsVisible(modals, "SUBMIT")} onHide={() => onHideModal("SUBMIT")} title={"Confirm Submit"}

                    modalTransition={{ timeout: 500 }}>

                    <ModalHeader>

                    <h5 class="modal-title">Confirm Submit</h5>

                    </ModalHeader>

                    <ModalBody>
                    <div class="mb-3">
                                {showTable ? 
                                  <div class="row">

                                  <div class="col-md-12">
    
                                      {SourceDataRecordArray.length>0 && SourceDataRecordArray.map((records) => {
    
                                              if (records.project.timesheetMessage !== null) {
    
                                                  return ( <div class="d-block mb-2">
    
                                                      <div class="d-flex rowHeading" >
    
                                                        <Icon project /> <h6 className="heading pl-2">{records.project.code} - {records.project.name}</h6>
    
                                                      </div>
    
                                                      <p class="pl-4">{records.project.timesheetMessage}</p>
    
                                               </div>);
    
                                           }
    
                                       })}

                                    
                                     {miscSourceDataRecordArray.length > 0  ?
                                      
    
                                         ( <div class="d-block mb-2">

                                            <div class="d-flex rowHeading" >

                                            <Icon leave /> <h6 className="heading pl-2">{miscSourceDataRecordArray[0].leave.code} - {miscSourceDataRecordArray[0].leave.name}</h6>

                                            </div>

                                            <p class="pl-4">{miscSourceDataRecordArray[0].systemMsg}</p>

                                     </div>)

                                 
                                     
                                     :''}
                                   </div>
    
                               </div>
                                :'Submit this record?'}
                                 {/* <div className="modal-backdrop fade show"></div> */}
                            </div>
                    </ModalBody>

                    <ModalFooter>

                    <Button className="float-right" color="success" onClick={onConfirmSubmit}>

                            Continue

                            </Button>

                            <Button className="float-right" color="danger" onClick={() => onHideModal("SUBMIT")}>

                            Cancel

                            </Button>

                    </ModalFooter>
                    </Modal>
                        <FormModal isVisible={ModalActions.IsVisible(modals, "CLOSE")}onHide={() => onHideModal("CLOSE")} title={"Confirm Close"}>
                        <FormGroup>
                            Close this record?
                            <Button className="float-right" color="success" onClick={onConfirmClosed}>
                                Close
                            </Button>
                        </FormGroup>
                    </FormModal>
                    <FormModal isVisible={ModalActions.IsVisible(modals, "UNSUBMIT")} onHide={() => onHideModal("UNSUBMIT")} title={"Confirm Un-submit"}>
                        <FormGroup>
                            Un-submit this record?
                            <Button className="float-right" color="warning" onClick={onConfirmUnsubmit}>
                                Un-submit
                            </Button>
                        </FormGroup>
                    </FormModal>
                </div>) : ("")
            }
            {


                 
                workflowPermissions && workflowPermissions.canApprove ?
                (<div>

                            <Modal  isOpen={ModalActions.IsVisible(modals, "APPROVE")} onHide={() => onHideModal("APPROVE")}

                            modalTransition={{ timeout: 500 }}>

                            <ModalHeader>

                            <h5 class="modal-title">Confirm Approve</h5>

                            </ModalHeader>

                            <ModalBody>

                            <div class="mb-3">
                                {showTable ? 
                              <div class="row">

                              <div class="col-md-12">

                                  {SourceDataRecordArray.length>0 && SourceDataRecordArray.map((records) => {

                                          if (records.project.timesheetMessage !== null) {

                                              return ( <div class="d-block mb-2">

                                                  <div class="d-flex rowHeading" >

                                                    <Icon project /> <h6 className="heading pl-2">{records.project.code} - {records.project.name}</h6>

                                                  </div>

                                                  <p class="pl-4">{records.project.timesheetMessage}</p>

                                           </div>);

                                       }

                                   })}

                                    {miscSourceDataRecordArray.length > 0  ?
                                      
    
                                      ( <div class="d-block mb-2">

                                         <div class="d-flex rowHeading" >

                                         <Icon leave /> <h6 className="heading pl-2">{miscSourceDataRecordArray[0].leave.code} - {miscSourceDataRecordArray[0].leave.name}</h6>

                                         </div>

                                         <p class="pl-4">{miscSourceDataRecordArray[0].systemMsg}</p>

                                  </div>)

                              
                                  
                                  :''}

                               </div>

                           </div>
                                :'Approve this record?'}
                            </div>
                        

                            </ModalBody>

                            <ModalFooter>
                                    
                    <Button className="float-right" color="success" onClick={onConfirmApprove}>

                        Continue

                        </Button>

                        <Button className="float-right" color="danger" onClick={() => onHideModal("APPROVE")}>

                        Cancel

                        </Button>


                            </ModalFooter>



                            </Modal>






                                        <FormModal isVisible={ModalActions.IsVisible(modals, "REJECT")} onHide={() => onHideModal("REJECT")} title={"Confirm Reject"}>
                        <RejectReasonForm onConfirmReject={onConfirmReject} />
                    </FormModal>
                </div>) : ("")
            }
        </div>
    );
}


export default WorkflowToolbar