import ScopeConstants from "constants/ScopeConstants"
import { FormActionReducer } from "@gman/gman-redux"

export const ReportIndividualInvoiceNumbersReducer = (
  state = {
    ...FormActionReducer.defaultPageState(),
    ...FormActionReducer.defaultSearchOptions(),
    ...FormActionReducer.defaultUploadState(),
    reportName: "Upload Client Invoice Numbers",
        reportDescription: "Upload an XLS or XLSX file with the ID of timesheet rows in the first column, and an invoice number in the second column. Invoice numbers will be recorded against the timesheet rows."
  },
  action) => {
  if (action.scope === ScopeConstants.REPORT_INDIVIDUAL_INVOICE_NUMBERS) {
    if (action.type === "APPLY_UPLOAD_FEEDBACK") {
      return {
        ...state,
        uploadFeedback: action.record
      }
    }
    if (action.type === "CLEAR_UPLOAD_FEEDBACK") {
      return {
        ...state,
        uploadFeedback: []
      }
    }
    return FormActionReducer.reduce(action, state)
  }

  return state
}