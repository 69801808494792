import ScopeConstants from "constants/ScopeConstants"
import { ScopeShape } from "@gman/gman-redux"
import PropTypes from "prop-types"

export const UserScope = {
    key: ScopeConstants.USER,
    selector: (state) => state.users,
    tokenSelector: (state) => state.authentication.token,
    fetchEndpoint: "/api/User/Search/",
    singleEndpoint: "/api/User/Get/",
    updateEndpoint: "/api/User/Update/",
    deleteEndpoint: "/api/User/Delete/",
    defaultRecord: {
        emailAddress: "",
        firstName: "",
        lastName: "",
        managerId: ""
    }
}

UserScope.propTypes = PropTypes.shape(ScopeShape).isRequired