import React, { Component } from "react"
import { Link } from "react-router-dom"
import { Progress, UncontrolledPopover, PopoverBody } from "reactstrap"
import { SpinnerOverlay, Paginator } from "@gman/gman-react-bootstrap"
import { ProjectSummaryProgress } from "./ProjectSummaryProgress"

function _interopDefault (ex) { return (ex && (typeof ex === 'object') && 'default' in ex) ? ex['default'] : ex; }
var toNumber = _interopDefault(require('lodash.tonumber'));
export class ProjectSummaryList extends Component {
    constructor(props) {
        super(props);
    
        this.toggle1 = this.toggle1.bind(this);
        this.toggle2 = this.toggle2.bind(this);
        this.state = {
          popoverOpen1: false,
          popoverOpen2: false
        };
    }

    toggle1() {
        this.setState({
          popoverOpen1: !this.state.popoverOpen1
        });
    }

    toggle2() {
        this.setState({
          popoverOpen2: !this.state.popoverOpen2
        });
    }

    componentDidMount() {
        this.props.recordStateEvents.onClearSearch()
    }

    getMaxOriginalFees(record) {
        var budget = (record.budget).toFixed(2);
        var cost = (record.costs).toFixed(2);
        if (parseFloat(budget) > parseFloat(cost)) {
            return budget;
        } else {
            return cost;
        }
    }

    render() {
        var sourceData = this.props.sourceData;
        console.log(sourceData, "sourceData");
        console.log(sourceData.inProgress, "sourceData.inProgress");
        var paginatorEvents = this.props.paginatorEvents;
        sourceData.records.map((record, index) => {
            record.projectNumber = record.projectNumber.trim()
        })
        return (
            <div>
                <SpinnerOverlay isLoading={sourceData && sourceData.inProgress}>
                    <table className="table">
                        <tr>
                            {/* <th colspan="4"></th>*/}
                            <th></th>
                            <th><Progress bar value="100" className="p-1">Budget</Progress><Progress bar value="100" className="p-1" color="warning">Cost</Progress></th> {/* changed by avantika task-3.1*/}
                            <th colspan="2"></th>
                            <th colspan="2">Last Week</th>
                            <th colspan="2">Last Month</th>
                        </tr>
                        <tr>
                            <th>Project</th>
                            <th>Financial Snapshot</th>
                            <th>Next Invoice</th>
                            <th>Client Invoice Status</th>
                            <th>Staff</th>
                            {/*<th>Hours</th>*/} {/* changed by avantika task-3.1*/}
                            <th>Hrs</th>
                            <th>Staff</th>
                            {/*<th>Hours</th>*/} {/* changed by avantika task-3.1*/}
                            <th>Hrs</th>
                        </tr>
                        {
                            sourceData && sourceData.records && sourceData.records.map((record, index) =>
                                <tr>
                                    <td>
                                        <Link to={"/projectSheet/" + record.projectId}>
                                            {record.projectNumber} {record.projectName}
                                        </Link>
                                    </td>
                                    <td>
                                        <div className="d-flex align-items-center">
                                            {/*<span>Budget</span>*/} {/* changed by avantika task-3.1*/}
                                            {/*<span className="ml-2">*/}
                                            
                                            <ProjectSummaryProgress data1={record.budget} data2={record.costs} id={record.projectNumber} />
                                        </div>
                                        <div className="d-flex align-items-center mt-1">
                                            {/*<span>Cost</span>*/} {/* changed by avantika task-3.1*/}
                                            {/*<span className="ml-4">*/}

                                            <ProjectSummaryProgress data1={record.costs} data2={record.budget} id={record.projectNumber} color='warning' />
                                        </div>
                                    </td>
                                    <td>
                                        {
                                            record.nextInvoice == "Overdue" ? <span style={{ color: 'red', fontWeight: '600' }}>Overdue</span> : <span>{record.nextInvoice}</span>
                                        }
                                    </td>
                                    <td>
                                        {
                                            // record.missingPayment == "Yes" ? <span style={{ color: 'red', fontWeight: '600' }}>Yes</span> : <span style={{ color: 'rgb(15, 81, 50)', fontWeight: '600' }}>{record.missingPayment}</span>
                                            record.missingPayment != "None" && record.missingPayment !=  "N/A"
                                            ? ( record.missingPayment == "Pending Publish" ? <span style={{ color: 'orange', fontWeight: '600' }}>{record.missingPayment}</span> : <span style={{ color: 'red', fontWeight: '600' }}>{record.missingPayment}</span> )
                                            : <span style={{ color: 'rgb(15, 81, 50)', fontWeight: '600' }}>{record.missingPayment}</span>
                                        }
                                    </td>
                                    <td>{record.lastWeekStaff}</td>
                                    <td>{record.lastWeekHours}</td>
                                    <td>{record.lastMonthStaff}</td>
                                    <td>{record.lastMonthHours}</td>
                                </tr>)
                        }
                    </table>
                    <Paginator {...paginatorEvents} searchOptions={sourceData.searchOptions} />
                </SpinnerOverlay>
            </div>
        )
    }
}