import React from "react"
import { Provider } from "react-redux"
import { BrowserRouter as Router, Route } from "react-router-dom"
import PropTypes from "prop-types"
import App from "./App"
import FullScreenApp from "./FullScreenApp"

const Root = ({ store }) => (
    <Provider store={store}>
        <Router>
            <div>
                <Route path="/:filter?" component={App} test="app"/>
                <Route path="/:filter?" component={FullScreenApp} test="fullscreen"/>
            </div>
        </Router>
    </Provider>
)

Root.propTypes = {
    store: PropTypes.object.isRequired
}

export default Root