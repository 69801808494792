import { connect } from "react-redux"
import { ProjectFinancialScope } from "components/projectsFinancial/redux/ProjectFinancialScope"
import ProjectFinancialCardBasic from "components/projectsFinancial/layout/ProjectFinancialCardBasic"
import { FormActions, FormEvents } from "@gman/gman-redux"

const mapDispatchToProps = (dispatch) => {
    var scope = ProjectFinancialScope
    return {
        ...FormEvents.getTableHeaderRowEvents(dispatch, scope),
        ...FormEvents.getFeedbackEvents(dispatch, scope),
        ...FormEvents.getPaginatorEvents(dispatch, scope),
        ...FormEvents.getModalEventsForModalsOnAll(dispatch, scope),
        ...FormEvents.getRecordStateEvents(dispatch, scope),
        ...FormEvents.getConfirmEventsForModalsOnAll(dispatch, scope),
        onLoaData: (projectId) => {
            return dispatch(FormActions.fetchSingle(scope, projectId));
        }
    }
}

const ProjectFinancialContainer = connect(
    (state, ownProps) => {
        return {
            sourceData: ProjectFinancialScope.selector(state),
            projectId: ownProps.projectId
        }
    },
    mapDispatchToProps
)(ProjectFinancialCardBasic)

export { ProjectFinancialContainer }