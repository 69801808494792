import React, { Component } from "react"
import { ClientContainerExtended } from "components/clients/redux/ClientContainers"
import ClientCardBasic from "components/clients/layout/ClientCardBasic"

export class ClientPageBasic extends Component {
    render() { return (<ClientCardBasic />) }
}

export class ClientPageExtended extends Component {
    render() { return (<ClientContainerExtended clientId={this.props.match.params.clientId} />) }
}