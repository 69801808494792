import ScopeConstants from "constants/ScopeConstants"
import { ScopeShape } from "@gman/gman-redux"
import PropTypes from "prop-types"

export const OfficeScope = {
    key: ScopeConstants.OFFICE,
    selector: (state) => state.offices,
    tokenSelector: (state) => state.authentication.token,
    fetchEndpoint: "/api/Office/Search/",
    updateEndpoint: "/api/Office/Update",
    deleteEndpoint: "/api/Office/Delete",
    defaultRecord: {
        name: ""
    }
}

OfficeScope.propTypes = PropTypes.shape(ScopeShape).isRequired