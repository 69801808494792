import { connect } from "react-redux"
import WorkbenchExportList from "components/workbenchExports/layout/WorkbenchExportList"
import { WorkbenchExportScope } from "components/workbenchExports/redux/WorkbenchExportScope"
import { FetchActions, FormActions, FormEvents } from "@gman/gman-redux"
import FileSaver from "file-saver"

const mapDispatchToProps = (dispatch) => {
    var scope = WorkbenchExportScope
    return {
        ...FormEvents.getTableHeaderRowEvents(dispatch, scope),
        ...FormEvents.getFeedbackEvents(dispatch, scope),
        ...FormEvents.getPaginatorEvents(dispatch, scope),
        ...FormEvents.getModalEventsForModalsOnAll(dispatch, scope),
        ...FormEvents.getRecordStateEvents(dispatch, scope),
        ...FormEvents.getConfirmEventsForModalsOnAll(dispatch, scope),
        onLoadDependentData: () => { },
        onConfirmRunExport: () => {
            dispatch(FormActions.doPost(scope, {}, "/api/WorkbenchExport/Export", "The workbench export was successfully run.", true))
                .then((result) => {
                    if (result && result.error) { }
                    else {
                        dispatch(FormActions.hideModal(scope, "CONFIRM_RUN_EXPORT"))
                    }
                })
        },
        onDownloadExport: (id) => {
            dispatch(function (dispatch, getState) {
                var authToken = ""
                var fileName = ""
                if (scope.tokenSelector) {
                    authToken = scope.tokenSelector(getState())
                }
                dispatch(FormActions.inProgressStart(scope))
                FetchActions.fetchApiGet("/api/WorkbenchExport/Download/" + id, authToken)
                    .then(function (response) {
                        fileName = extractFileName(response.headers)
                        return Promise.resolve(response.blob())
                    }).then(function (blob) {
                        FileSaver.saveAs(blob, fileName);
                    })
                    .then(() => { dispatch(FormActions.inProgressStop(scope)) })
                    .catch((error) => { FormActions.handleErrorResponse(error, scope, dispatch) })
            })
        }
    }
}

function extractFileName(headers) {
    var disposition = headers.get("content-disposition")
    var fileName = disposition.match(/filename\*?=((['"])[\s\S]*?\2|[^;\n]*)/)[1]
    return fileName
}

const WorkbenchExportListContainer = connect(
    (state) => {
        return {
            sourceData: WorkbenchExportScope.selector(state)
        }
    },
    mapDispatchToProps
)(WorkbenchExportList)

export { WorkbenchExportListContainer }