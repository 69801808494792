import React, { Component } from "react"
import { ClientInvoiceContainerExtended } from "components/clientInvoices/redux/ClientInvoiceContainers"
import ClientInvoiceCardBasic from "components/clientInvoices/layout/ClientInvoiceCardBasic"

export class ClientInvoicePageBasic extends Component {
    render() { return (<ClientInvoiceCardBasic />) }
}

export class ClientInvoicePageExtended extends Component {
    render() { return (<ClientInvoiceContainerExtended clientInvoiceId={this.props.match.params.clientInvoiceId} />) }
}