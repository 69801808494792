import React from "react"
import { Card, CardBody, CardHeader } from "reactstrap"
import { UserListContainer } from "components/users/redux/UserContainers"
import Icon from "components/common/Icon"

const UserCardBasic = () => {
    return (
        <Card>
            <CardHeader>
                <h6>
                    <Icon person />
                    People
                </h6>
            </CardHeader>
            <CardBody>
                <UserListContainer />
            </CardBody>
        </Card >
    )
}

export { UserCardBasic }