import React, { Component } from "react"
import { Redirect } from "react-router"
import PropTypes from "prop-types"
import { ProjectShape } from "shapes/ProjectShape"
import { Table, Button } from "reactstrap"
import ProjectRow from "components/projects/layout/ProjectRow"
import ProjectFormBasic from "components/projects/form/ProjectFormBasic"
import { ModalActions } from "@gman/gman-redux"
import TableHeaderRow from "components/common/TableHeaderRow"
import FeedbackAlert from "components/common/FeedbackAlert"
import PageSizeSelect from "components/common/PageSizeSelect"
import { AuthorizedAddButton } from "components/authentication/controls/AuthorizedControls"
import {
    ConfirmDelete,
    FormModal,
    Paginator,
    SpinnerOverlay
} from "@gman/gman-react-bootstrap"

const AddButton = AuthorizedAddButton(p => p.canWriteWorkItems)

class ProjectList extends Component {

    componentDidMount() {
        this.props.recordStateEvents.onClearSearch({ clientId: this.props.clientId })        
        //this.props.onLoadDependentData()
        this.props.onloadClients();
    }

    componentWillUnmount = () => {
        this.props.feedbackEvents.onFlushFeedback()
        this.props.redirectEvents.onClearRedirect()
    }

    render() {
        var sourceData = this.props.sourceData
        sourceData.recordToUpdate = {... sourceData.recordToUpdate , markup:this.props.client ? this.props.client.markup : 0}        
        if (sourceData.redirect) {
            return (<Redirect to={sourceData.redirect} />)
        }

        var modals = sourceData.modals
        var confirmEvents = this.props.confirmEvents
        var feedbackEvents = this.props.feedbackEvents
        var modalEvents = this.props.modalEvents
        var paginatorEvents = this.props.paginatorEvents
        var tableHeaderRowEvents = this.props.tableHeaderRowEvents
        console.log("sourceData-aaa",sourceData);
        return (
            <div>
                <FeedbackAlert {...feedbackEvents} feedback={sourceData.feedback} />
                <SpinnerOverlay isLoading={sourceData.inProgress}>
                    <AddButton disabled={sourceData.inProgress} styleClass="float-right mb-2" onClick={() => modalEvents.onShowNew({ clientId: this.props.clientId })} />
                    <Table responsive hover size="sm">
                        <thead>
                            <TableHeaderRow
                                {...tableHeaderRowEvents}
                                disabled={sourceData.inProgress}
                                headers={[
                                    { sortBy: "Name", label: "Name" },
                                    { sortBy: "Code", label: "Code" },
                                    { sortBy: "Client.Name", label: "Client" },
                                    { sortBy: "ProjectManager.FirstName", label: "Project Manager" },
                                    { sortBy: "DateCreated", label: "Date Created" },
                                    { sortBy: "Status", label: "Status" }
                                ]}
                            />
                        </thead>
                        <tbody>
                            {
                                sourceData.records.map((project, index) => (
                                    <ProjectRow
                                        key={index}
                                        {...project}
                                        onShowDelete={() => modalEvents.onShowDelete(project)}
                                        onShowEdit={() => modalEvents.onShowEdit(project)}
                                        disabled={sourceData.inProgress} />
                                ))
                            }
                        </tbody>
                    </Table>
                    <Paginator {...paginatorEvents} searchOptions={sourceData.searchOptions} />
                    {this.props.onShowAllRecords ? <input type="button" class="btn btn-sm btn-outline-success" disabled={sourceData.inProgress} onClick={this.props.onShowAllRecords} value="Show All Records"></input> : ''}
                    <PageSizeSelect disabled={sourceData.inProgress} defaultPageSize={sourceData.searchOptions.pageSize} onChange={this.props.onPageSizeChange} />
                </SpinnerOverlay>
                <FormModal
                    isVisible={ModalActions.IsDeleteVisible(modals)}
                    onHide={modalEvents.onHideDelete}
                    title={"Confirm Delete"}>
                    <ConfirmDelete onConfirmDelete={() => confirmEvents.onConfirmDelete(sourceData.recordToUpdate)} />
                </FormModal>
                <FormModal
                    isVisible={ModalActions.IsUpdateVisible(modals)}
                    onHide={modalEvents.onHideEdit}
                    title={"Create Project"}>
                    <ProjectFormBasic onSubmitUpdate={confirmEvents.onSubmitUpdate}
                        {...sourceData.recordToUpdate} />
                </FormModal>
            </div>
        )
    }
}

ProjectList.propTypes = {
    sourceData: PropTypes.shape({
        records: PropTypes.arrayOf(ProjectShape)
    })
}

export default ProjectList