import React from "react"
import { Link } from "react-router-dom"
import AddButton from "components/common/AddButton"
import { Button } from "reactstrap"
import { Input } from "reactstrap"
import { SelectOption } from "@gman/gman-redux"
import { SummaryToolbar } from "@gman/gman-react-bootstrap"
import PublishingToolbar from "components/common/PublishingToolbar"
import InvoiceToolbar from "components/common/InvoiceToolbar"
import WorkbenchToolbar from "components/common/WorkbenchToolbar"
import { WrapRestriction } from "components/authentication/redux/AuthorizationWrappers"

function AuthorizedAddButton(selector) {
    return WrapRestriction((props) => <AddButton {...props} />, selector)
}

function AuthorizedButton(selector) {
    return WrapRestriction((props) => <Button {...props}>{props.children}</Button>, selector)
}

function AuthorizedInputField(selector) {
    return WrapRestriction(
        (props) => <Input {...props}>{props.children}</Input>,
        selector,
        (props) => <Input {...props} readOnly={true}>{props.children}</Input>
    )
}

function AuthorizedDropDownField(selector) {
    return WrapRestriction(
        (props) => <Input type="select" {...props}>{props.children}</Input>,
        selector,
        (props) => <Input type="select" {...props} disabled={true}>{props.children}</Input>
    )
}

function AuthorizedPublishingToolbar(selector) {
    return WrapRestriction((props) => <PublishingToolbar {...props} />, selector)
}

function AuthorizedSelectOption(selector) {
    return WrapRestriction(
        (props) => <SelectOption {...props} />,
        selector,
        (props) => <SelectOption {...props} disabled={true} />
    )
}

function AuthorizedSummaryToolbar(selector) {
    return WrapRestriction((props) => <SummaryToolbar {...props} />, selector)
}

function AuthorizedWorkbenchToolbar(selector) {
    return WrapRestriction((props) => <WorkbenchToolbar {...props} />, selector)
}

function AuthorizedInvoiceToolbar(selector) {
    return WrapRestriction((props) => <InvoiceToolbar {...props} />, selector)
}

function AuthorizedLink(selector) {
    return WrapRestriction(
        (props) =>
            <Link to={props.to} onClick={props.onClick}>
                {props.children}
            </Link>,
        selector,
        (props) => <div>
            {props.children}
        </div>
    )
}

export {
    AuthorizedAddButton,
    AuthorizedButton,
    AuthorizedInputField,
    AuthorizedInvoiceToolbar,
    AuthorizedLink,
    AuthorizedPublishingToolbar,
    AuthorizedSelectOption,
    AuthorizedSummaryToolbar,
    AuthorizedWorkbenchToolbar,
    AuthorizedDropDownField
}
