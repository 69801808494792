import { connect } from "react-redux"
import CostCodeCardBasic from "components/costCodes/layout/CostCodeCardBasic"
import CostCodeList from "components/costCodes/layout/CostCodeList"
import { CostCodeScope } from "components/costCodes/redux/CostCodeScope"
import { FormEvents } from "@gman/gman-redux"

const mapDispatchToProps = (dispatch) => {
  var scope = CostCodeScope
  return {
    ...FormEvents.getTableHeaderRowEvents(dispatch, scope),
    ...FormEvents.getFeedbackEvents(dispatch, scope),
    ...FormEvents.getPaginatorEvents(dispatch, scope),
    ...FormEvents.getModalEventsForModalsOnAll(dispatch, scope),
    ...FormEvents.getRecordStateEvents(dispatch, scope),
    ...FormEvents.getConfirmEventsForModalsOnAll(dispatch, scope)
  }
}

const CostCodeContainer = connect(
  state => { return { sourceData: CostCodeScope.selector(state) } },
  mapDispatchToProps
)(CostCodeCardBasic)

const CostCodeListContainer = connect(
  (state, ownProps) => {
    return {
      sourceData: CostCodeScope.selector(state),
      costCodeGroupId: ownProps.costCodeGroupId
    }
  },
  mapDispatchToProps
)(CostCodeList)

export { CostCodeContainer, CostCodeListContainer }