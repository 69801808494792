
import React from "react"
import { Link } from "react-router-dom"
import PropTypes from "prop-types"
import { ClientShape } from "shapes/ClientShape"
import ActiveCell from "components/common/ActiveCell"
import { AuthorizedSummaryToolbar } from "components/authentication/controls/AuthorizedControls"

const Toolbar = AuthorizedSummaryToolbar(p => p.canWriteClients)

const ClientRow = (
  {
    clientId,
    name,
    code,
    onShowEdit,
    onShowDelete,
    active,
    disabled
  }) => (
    <tr>
      <ActiveCell active={active}>
        <Link to={"/clientDetails/" + clientId} onClick={onShowEdit}>
          {name}
        </Link>
      </ActiveCell>
      <ActiveCell active={active}>{code}</ActiveCell>
      <td>
        <Toolbar disabled={disabled} onShowDelete={onShowDelete} />
      </td>
    </tr>
  )

  ClientRow.propTypes = PropTypes.shape(ClientShape).isRequired

export default ClientRow 