import ScopeConstants from "constants/ScopeConstants"
import { FormActionReducer } from "@gman/gman-redux"

function emptyState() {
    return {
        inProgress: false,        
        token: "",
        userId: "",
        displayName: "",
        displayRole: "",
        permissions: [],
        environment: {},
        isLoggedIn: false
    }
}

export const AuthenticationReducer = (
    state = {
        ...emptyState(),
        modals: {},
        feedback: []
    },
    action) => {

    if (action.scope === ScopeConstants.AUTHENTICATION) {

        if (action.type == "LOGIN_SUCCESS") {
            var newState =
            {
                inProgress: false,
                feedback: [],
                token: action.token,
                userId: action.userId,
                displayName: action.displayName,
                displayRole: action.displayRole,
                permissions: action.permissions,
                environment: action.environment,
                isLoggedIn: true,
                modals: {},
                feedback: []
            }
            return newState
        }
        if (action.type == "LOGOUT_SUCCESS") {
            var newState =
            {
                ...emptyState(),
                modals: state.modals,
                feedback: state.feedback
            }
            return newState
        }
        return FormActionReducer.reduce(action, state)
    }

    return state
}