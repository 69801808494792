import React, { Component } from "react"
import { Card, CardBody, Row, Col } from "reactstrap"
import ProjectFinancialChart from './ProjectFinancialChart'


class ProjectFinancialCardBasic extends Component {
    constructor(props) {
        super(props);
        var self = this;
    }
    
    componentDidMount() {
        if (this.props.projectId) {
            this.props.onLoaData(this.props.projectId)
        }
    }

  
    render() {
      var getMaxValue = 0;
      var self = this;
      var sourceData = this.props.sourceData;
      console.log("Render Did mount", sourceData)
      // this.renderChart(sourceData)
      return (
            <Card>
                <CardBody>
                    <Row>
                        <Col className="col-lg-12">
                          {sourceData && sourceData.recordToUpdate && !sourceData.inProgress ? 
                            <ProjectFinancialChart sourceProjectData={sourceData} /> : ""}
                        </Col>
                    </Row>
                </CardBody>
            </Card>
        )
    }
}

export default ProjectFinancialCardBasic