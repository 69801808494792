import ScopeConstants from "constants/ScopeConstants"
import { FormActionReducer } from "@gman/gman-redux"

export const ReportProjectFinancialsReducer = (
  state = {
    ...FormActionReducer.defaultPageState(),
    ...FormActionReducer.defaultSearchOptions(),
    reportName: "Project Financial Report",
    reportDescription: "An export of all project financial details, in CSV format."
  },
  action) => {
 console.log(action.scope , 'action.scope')
  if (action.scope === ScopeConstants.REPORT_PROJECT_FINANCIALS) {
    return FormActionReducer.reduce(action, state)
  }

  return state
}