import React, { Component } from "react"
import { Popover, PopoverHeader, PopoverBody } from "reactstrap"
import Icon from "components/common/Icon"

class CommentBadge extends Component {

    constructor(props) {
        super(props)

        this.toggle = this.toggle.bind(this)
        this.state = {
            popoverOpen: false
        };
    }

    toggle() {
        this.setState({
            popoverOpen: !this.state.popoverOpen
        })
    }

    render() {
        return (
            this.props.comment ?
                <div
                    onClick={() => this.toggle()}
                    className={this.props.className + " comment-box"}
                    id={"commentTooltip_" + this.props.index}
                >
                    <h5>
                        <Icon commentNotEmpty />
                    </h5>
                    <Popover
                        trigger="focus"
                        isOpen={this.state.popoverOpen}
                        placement="right-end"
                        target={"commentTooltip_" + this.props.index}
                        toggle={this.toggle}
                    >
                        <PopoverHeader>
                            Comment
                        </PopoverHeader>
                        <PopoverBody>
                            {this.props.comment}
                        </PopoverBody>
                    </Popover>

                </div>
                :
                <div className={this.props.className + " comment-box"}>
                    <h5>
                        <Icon commentEmpty />
                    </h5>
                </div>
        )
    }
}

export default CommentBadge