import React from "react"
import { withFormik } from "formik"
import * as Yup from "yup"
import RequiredStar from "components/common/RequiredStar"
import { AuthorizedButton, AuthorizedInputField } from "components/authentication/controls/AuthorizedControls"

import {
    Form,
    FormGroup,
    Label
} from "reactstrap"

const SubmitButton = AuthorizedButton(p => p.canWriteOffices)
const InputField = AuthorizedInputField(p => p.canWriteOffices)

const BaseForm = props => {
    const {
        values,
        dirty,
        errors,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting
    } = props
    return (
        <div>
            <Form noValidate onSubmit={handleSubmit}>
                <FormGroup>
                    <RequiredStar />
                    <Label for="name">Office Name</Label>
                    <InputField
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.name || ""}
                        type="text"
                        name="name"
                        id="name"
                        placeholder="Enter an office name"
                        invalid={errors.name} />
                    {errors.name && <small className="text-danger">{errors.name}</small>}
                </FormGroup>
                <SubmitButton outline className="float-right" disabled={isSubmitting || !dirty} color="success" type="submit">Submit</SubmitButton>
            </Form>
        </div>
    )
}

const validationSchema = Yup.object({
    name: Yup.string()
        .required("Name is required")
})

const OfficeFormBasic = withFormik({
    displayName: "OfficeFormBasic",
    enableReinitialize: true,
    validationSchema: validationSchema,
    initialValues: ({ value }) => ({
        name: value.name
    }),
    handleSubmit: (values, { props, setSubmitting }) => {
        props.onSubmitUpdate(values)
        setSubmitting(false)
    }
})(BaseForm)

export default OfficeFormBasic