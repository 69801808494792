import React from "react"
import { Card, CardBody, CardHeader } from "reactstrap"
import { ProjectListMineContainer } from "components/projects/redux/ProjectContainers"
import Icon from "components/common/Icon"

const ProjectCardMineBasic = () => {
    return (
        <Card>
            <CardHeader>
                <h6>
                    <Icon project />
                    My Projects
                </h6>
            </CardHeader>
            <CardBody>
                <ProjectListMineContainer />
            </CardBody>
        </Card >
    )
}

export default ProjectCardMineBasic
