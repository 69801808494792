import React from "react"
import { Card, CardBody, CardHeader } from "reactstrap"
import { VariationListContainer } from "components/variations/redux/VariationContainers"
import Icon from "components/common/Icon"

const VariationCardBasic = () => {
    return (
        <Card>
            <CardHeader>
                <h6>
                    <Icon variation />
                    Variations
                </h6>
            </CardHeader>
            <CardBody>
                <VariationListContainer />
            </CardBody>
        </Card >
    )
}

export default VariationCardBasic