import React from "react"
import {
    Button,
    ButtonGroup
} from "reactstrap"
import { MdDelete, MdModeEdit } from "react-icons/md"
import { FaDownload } from "react-icons/fa"

const WorkbenchToolbar = props => {
    const {
        disabled,
        onShowDelete,
        onShowEdit,
        onShowDownload
    } = props

    return (
        <div>
            <ButtonGroup size="sm" className="float-right">
                {
                    (onShowDownload) ?
                        <Button
                            outline
                            disabled={disabled}
                            color="success"
                            onClick={onShowDownload}
                        >
                            <FaDownload /> Download
                        </Button>
                        : ""
                }
                {
                    (onShowEdit) ?
                        <Button
                            outline
                            disabled={disabled}
                            color="info"
                            onClick={onShowEdit}
                        >
                            <MdModeEdit /> Edit
                        </Button>
                        : ""
                }
                {
                    (onShowDelete) ?
                        <Button
                            outline
                            disabled={disabled}
                            color="danger"
                            onClick={onShowDelete}
                        >
                            <MdDelete /> Delete
                        </Button>
                        : ""
                }
            </ButtonGroup>
        </div>
    )
}

export default WorkbenchToolbar