import ScopeConstants from "constants/ScopeConstants"
import ExpenseStatusConstants from "constants/ExpenseStatusConstants"

export const ExpensesAnyByDueDateScope = {
    key: ScopeConstants.EXPENSE_ANY_BY_DUE_DATE,
    selector: (state) => state.expensesAnyByDueDate,
    tokenSelector: (state) => state.authentication.token,
    fetchEndpoint: "/api/Expense/SearchAnyByDueDate/",
    singleEndpoint: "/api/Expense/GetAny/",
    updateEndpoint: "/api/Expense/UpdateAny/",
    deleteEndpoint: "/api/Expense/DeleteAny/",
    defaultRecord: {
        name: "",
        code: "",
        status: ExpenseStatusConstants.DRAFT
    }
}