import ScopeConstants from "constants/ScopeConstants"
import { FormActionReducer } from "@gman/gman-redux"

export const ReportChargeRatesReducer = (
  state = {
    ...FormActionReducer.defaultPageState(),
    ...FormActionReducer.defaultSearchOptions(),
    reportName: "Charge Rates",
    reportDescription: "An export of charge rates, in CSV format."
  },
  action) => {

  if (action.scope === ScopeConstants.REPORT_CHARGE_RATES) {
    return FormActionReducer.reduce(action, state)
  }

  return state
}