import React from "react"
import { Link } from "react-router-dom"
import Moment from "moment"
import Currency from "components/common/Currency"
import Icon from "components/common/Icon"
import { LookupLabel } from "@gman/gman-redux"

import {
    Col,
    Container,
    Input,
    Label,
    Row
} from "reactstrap"

const label = { xs: 12, md: 3 }
const field = { xs: 12, md: 9 }

const OpportunityDisplayExtended = props => {
    const { values } = props
    var readOnlyClass = "form-control-plaintext form-control read-only-label"
    return (
        <div>
            {values.proposalId ?
                proposalLink(values.proposalId)
                :
                ""}
            <h6>
                <Icon folderOpen />
                Opportunity Details
                </h6>
            <hr />
            <Container>
                <Row>
                    <Col {...label}>
                        <Label for="name">Opportunity Name</Label>
                    </Col>
                    <Col {...field}>
                        <Input className={readOnlyClass} value={values.name} />
                    </Col>
                    <Col {...label}>
                        <Label for="code">Opportunity Code</Label>
                    </Col>
                    <Col {...field}>
                        <Input className={readOnlyClass} value={values.code} />
                    </Col>
                    <Col {...label}>
                        <Label for="officeId">CMP Office</Label>
                    </Col>
                    <Col {...field}>
                        <LookupLabel
                            className={readOnlyClass}
                            value={values.officeId}
                            stateFunc={(state) => { return { ...state.offices } }}
                            valueFunc={(record) => record.officeId}
                            labelFunc={(record) => record.name}
                        />
                    </Col>
                </Row>
            </Container>
            <h6>
                <Icon client />
                Client Details
                </h6>
            <hr />
            <Container>
                <Row>
                    <Col {...label}>
                        <Label for="clientId">Client</Label>
                    </Col>
                    <Col {...field}>
                        <LookupLabel
                            className={readOnlyClass}
                            value={values.clientId}
                            stateFunc={(state) => { return { ...state.clients } }}
                            valueFunc={(record) => record.clientId}
                            labelFunc={(record) => record.name + " (" + record.code + ")"}
                        />
                    </Col>
                </Row>
            </Container>
            <h6>
                <Icon clock />
                Fee and Program
                </h6>
            <hr />
            <Container>
                <Row>
                    <Col {...label}>
                        <Label for="likelihood">Likelihood</Label>
                    </Col>
                    <Col {...field}>
                        <Input className={readOnlyClass} value={(values.likelihood && (values.likelihood + "%") || "")} />
                    </Col>
                    <Col {...label}>
                        <Label for="estimatedFee">Estimated Fee</Label>
                    </Col>
                    <Col {...field}>
                        <Input className={readOnlyClass} value={values.estimatedFee && (Currency(values.estimatedFee))} />
                    </Col>
                </Row>
                <Row>
                    <Col {...label}>
                        <Label for="startDate">Start Date</Label>
                    </Col>
                    <Col {...field}>
                        <Input className={readOnlyClass} value={Moment(values.startDate && values.startDate).format("DD/MM/YYYY")} />
                    </Col>
                    <Col {...label}>
                        <Label for="endDate">End Date (anticipated)</Label>
                    </Col>
                    <Col {...field}>
                        <Input className={readOnlyClass} value={values.endDate && Moment(values.endDate).format("DD/MM/YYYY")} />
                    </Col>
                    <Col {...label}>
                        <Label for="comments">Comments</Label>
                    </Col>
                    <Col {...field}>
                        <Input rows={27} type="textarea" className={readOnlyClass} value={values.comments} />
                    </Col>
                </Row>
            </Container >
        </div >
    )
}

const proposalLink = (proposalId) => {
    return (
        <div>
            <h6>
                <Icon project />
                <Link to={"/proposalSheet/" + proposalId}>Associated Proposal</Link>
            </h6>
            <hr />
        </div>
    )
}

export default OpportunityDisplayExtended