import React from "react"
import { Link } from "react-router-dom"
import Moment from "moment"
import PropTypes from "prop-types"
import { TimesheetShape } from "shapes/TimesheetShape"
import ActiveCell from "components/common/ActiveCell"
import TimesheetStatusBadge from "components/common/TimesheetStatusBadge"
import { AuthorizedSummaryToolbar } from "components/authentication/controls/AuthorizedControls"

const Toolbar = AuthorizedSummaryToolbar(p => p.canCompleteOwnTimesheet)

const TimesheetRow = (
    {
        weekEnding,
        timesheetId,
        status,
        active,
        user,
        disabled,
        suffix,
        workflowPermissions,
        onShowDelete
    }) => (
        <tr>
            <ActiveCell active={active}>
                <Link to={"/timesheetEntry/" + suffix + "/" + timesheetId}>
                    {Moment(weekEnding).format("DD/MM/YYYY")}
                </Link>
            </ActiveCell>
            <td>
                <TimesheetStatusBadge value={status} />
            </td>
            <ActiveCell active={!disabled}>
                {user ?
                    <Link to={"/userDetails/" + user.userId}>
                        {user && user.firstName + " " + user.lastName}
                    </Link>
                    : ""}
            </ActiveCell>
            <td>
                {
                    workflowPermissions && workflowPermissions.canEdit || workflowPermissions.canEditApproved ?
                        <Toolbar disabled={disabled} onShowDelete={onShowDelete} />
                        : ""
                }
            </td>
        </tr>
    )

TimesheetRow.propTypes = PropTypes.shape(TimesheetShape).isRequired

export default TimesheetRow 