import React from "react"
import Moment from "moment"
import { withFormik } from "formik"
import * as Yup from "yup"
import RequiredStar from "components/common/RequiredStar"

import {
    Container,
    Col,
    Form,
    FormGroup,
    Label,
    Row,
    Button,
    Input
} from "reactstrap"

const BaseForm = props => {
    const {
        values,
        errors,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting
    } = props
    // values.customAmount = Number(values.invoiceAmount)
    //console.log(values.initCustomValue, values.customAmount, Number(values.invoiceAmount), "sales")
    values.customAmount = Number(values.initCustomValue) !== 0 ? Number(values.customAmount) === 0 ? '' : Number(values.customAmount) :  Number(values.invoiceAmount) + Number(values.customAmount)
    values.initCustomValue = 1
    return (
        <div>
            <Form noValidate onSubmit={handleSubmit}>
                <Container>
                    <Row>
                        <Col xs={12}>
                            <FormGroup>
                                <RequiredStar />
                                <Label for="invoiceAmount">Custom Amount (excl. GST)</Label>
                                <Input
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.customAmount}
                                    type="number"
                                    name="customAmount"
                                    id="customAmount"
                                    placeholder="Custom Amount"
                                    invalid={errors.customAmount} />
                                {errors.customAmount && <small className="text-danger">{errors.customAmount}</small>}
                            </FormGroup>
                        </Col>
                    </Row>
                </Container>
                <Button outline className="float-right" disabled={isSubmitting} color="success" type="submit">Update</Button>
            </Form>
        </div>
    )
}

const validationSchema = Yup.object({
    invoiceAmount: numberValidation()
})

function numberValidation() {
    return Yup.number()
        .default(0)
        .nullable()
        .required("Value is required")
        .round()
        .typeError("Must be a number");
}

const ClientInoviceEditAmountForm = withFormik({
    displayName: "ClientInoviceEditAmountForm",
    validationSchema: validationSchema,
    enableReinitialize: true,
    mapPropsToValues: ({ ...props }) => {
        return ({
            ...props,
            customAmount: 0,
            initCustomValue: 0
        })
    },
    handleSubmit: (values, { props, setSubmitting }) => {
        values.invoiceAmount = Number(values.customAmount);
        props.onSubmitUpdate(values);
        setSubmitting(false)
    }
})(BaseForm)

export default ClientInoviceEditAmountForm