import React, { Component } from "react"
import {
    SpinnerOverlay
} from "@gman/gman-react-bootstrap";
import Currency from "components/common/Currency"
import Chart from "react-apexcharts"


class ProjectFinancialChart extends Component {
    constructor(props) {
        super(props);
        var self = this;
        const _ = require("lodash");

        this.state = {
        
            series: [{
                    name: 'CMP Labour & Expenses - Original',
                    data: [0,0,0,0]
                }, {
                    name: 'Subcontractors - Original',
                    data: [0,0,0,0]
                }, {
                    name: 'CMP Labour & Expenses - Variations',
                    data: [0,0,0,0]
                }, {
                    name: 'Subcontractors - Variations',
                    data: [0,0,0,0]
                }, {
                    name: 'Invoiced-To-Date',
                    data: [0,0,0,0]
                }, {
                    name: 'Paid-To-Date',
                    data: [0,0,0,0]
                }],
            options: {
                chart: {
                    type: 'bar',
                    toolbar: {
                        show: false
                    },
                    height: 350,
                    stacked: true,
                },
                plotOptions: {
                    bar: {
                        horizontal: true,
                        barHeight: '50%',
                        dataLabels: {
                            position: 'right'
                        }
                    },
                },
                stroke: {
                    width: 1,
                    colors: ['#fff']
                },
                xaxis: {
                    categories: ['Current Fee', 'Cost to Date', 'Invoiced to Date', 'Paid to Date'],
                    labels: {
                        formatter: function (val) {
                        return self.financialCaption(val)
                        },
                        style: {
                            fontWeight: 'bold',
                            fontSize: 12,
                        },
                    },
                    axisBorder: {
                        show: false
                    },
                },
                yaxis: {
                    title: {
                        text: undefined
                    },
                    labels:{
                        style: {
                            fontWeight: 700,
                            fontSize: 12,
                        },
                        offsetY: 5,
                        formatter: function (val, index) {
                            if (index !== undefined) {
                                let w = index['w']
                                let dataPointIndex = index['dataPointIndex']
                                return [val, self.financialCaption(w.globals.stackedSeriesTotals[dataPointIndex])]
                            } else {
                                return val
                            }
                        }
                    }
                },
                tooltip: {
                y: {
                    formatter: function (val) {
                        return self.financialCaption(val)
                    }
                },
                },
                dataLabels: {
                    enabled: false,
                },
                fill: {
                    opacity: 1,
                    colors: ['#4472c4', '#9cadda', '#70ad47', '#a0cb8c', '#ffd78b', '#ffc000']
                    },
                legend: {
                    position: 'right',
                    fontSize: '14px',
                    horizontalAlign: 'center',
                    
                    markers:{
                        width: 40,
                        height: 30,
                        fillColors: ['#4472c4', '#9cadda', '#70ad47', '#a0cb8c', '#ffd78b', '#ffc000']
                    },
                    itemMargin: {
                        horizontal: 0,
                        vertical: 10
                    },
                }
            },
        
        
        };
      }
    
    componentDidMount() {
        
        var getMaxValue = 0;
        var self = this;
        var sourceData = this.props.sourceProjectData;
        console.log("Did mount", sourceData)
        if (sourceData && sourceData.recordToUpdate && !sourceData.inProgress) {

          this.renderChart(sourceData)
        }
    }

    renderChart = (sourceData) => {
      if (sourceData && sourceData.recordToUpdate){

      this.setState({series: [{
          name: 'CMP Labour & Expenses - Original',
          data: [
            parseFloat(sourceData.recordToUpdate.originalFee), 
            parseFloat(sourceData.recordToUpdate.originalCost), 
            0, 0]
          }, {
          name: 'Subcontractors - Original',
          data: [
            parseFloat(sourceData.recordToUpdate.subcontractorsOriginalFee), 
            parseFloat(sourceData.recordToUpdate.subcontractorsCost), 
            0, 0]
          }, {
          name: 'CMP Labour & Expenses - Variations',
          data: [
            parseFloat(sourceData.recordToUpdate.variationsOriginalFee), 
            parseFloat(sourceData.recordToUpdate.variationsOriginalCost), 
            0, 0]
          }, {
          name: 'Subcontractors - Variations',
          data: [
            parseFloat(sourceData.recordToUpdate.variationsSubcontractorsOriginalFee), 
            parseFloat(sourceData.recordToUpdate.variationsSubcontractorsCost), 
            0, 0]
          }, {
            name: 'Invoiced-To-Date',
            data: [0,0,parseFloat(sourceData.recordToUpdate.totalInvoiced),0]
          }, {
            name: 'Paid-To-Date',
            data: [0,0,0,parseFloat(sourceData.recordToUpdate.totalPaidInvoiced)]
          }]
      })

      }
    }

    financialCaption = (value) => {
      return value > 0 ?
          (Currency(value)) : (0);
    };
  
    render() {
        var getMaxValue = 0;
        var self = this;
        var sourceData = this.props.sourceProjectData;
        return (
            <div id="chart">
                <SpinnerOverlay isLoading={sourceData.inProgress}>
                    <Chart options={this.state.options} series={this.state.series} type="bar" height={350} />
                </SpinnerOverlay>
            </div>
        )
    }
}

export default ProjectFinancialChart