import React, { Component } from "react";
import {
    Card,
    CardBody,
    CardHeader,
    CardTitle,
    Container,
    Col,
    Nav,
    NavItem,
    NavLink,
    Row,
    TabContent,
    TabPane
} from "reactstrap";
import Icon from "components/common/Icon";
import { AuthorizedButton } from "components/authentication/controls/AuthorizedControls"
import { FaDownload } from "react-icons/fa"

import { TimesheetEntryEditListContainers } from "components/timesheetEntries/redux/TimesheetEntryEditListContainers";
import { ExpenseEditListContainer } from "components/expenses/redux/ExpenseEditListContainer";
import { SubContractorInvoiceEditListContainer } from "components/subContractorInvoices/redux/SubContractorInvoiceEditListContainer";
const DownloadInvoiceDataButton = AuthorizedButton(p => p.canDownloadClientInvoiceData)

const workflowPermissions = { canEdit: true, canEditApproved: true };
let props = ''
class EditDataPage extends Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
        this.props.tabEvents.onChangeTab("1");
        if (this.props.showFilter) {
            console.log("this.props", this.props);
            this.props.onLoadDependentData();
        }

        props = this.props
    }

    // downloadProjectExtended(props) {
    //     let projectId = window.location.href.split('/')[4];
    //     this.props.onDownLoadProjectExtendedReport()
    // }

    render() {
        var sourceData = this.props.sourceData;
        var tabEvents = this.props.tabEvents;

        return (
            <React.Fragment>
                <Container>
                    <Row>
                        <Col>
                            <Nav tabs>
                                <NavItem>
                                    <NavLink onClick={() => tabEvents.onChangeTab("1")}>
                                        <Icon lineChart />
                                        Timesheet Entries
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink onClick={() => tabEvents.onChangeTab("2")}>
                                        <Icon expense />
                                        Expenses
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink onClick={() => tabEvents.onChangeTab("3")}>
                                        <Icon invoice />
                                        Sub Contractor Invoices
                                    </NavLink>
                                </NavItem>
                                <DownloadInvoiceDataButton
                                    className="mb-2"
                                    size="sm"
                                    outline
                                    color="info"
                                    onClick={this.props.onDownLoadProjectExtendedReport}
                                >
                                    <FaDownload /> Download
                                </DownloadInvoiceDataButton>
                            </Nav>

                        </Col>
                    </Row>
                </Container>
                <div>
                    <TabContent activeTab={sourceData.activeTab}>
                        <TabPane tabId="1">
                            <br />
                            {
                                sourceData.activeTab == 1 ? <Container fluid={true}>
                                    <Row>
                                        <Col>
                                            <TimesheetEntryEditListContainers
                                                isOpneInModal={this.props.isOpneInModal}
                                                invoiceDate={this.props.invoiceDate}
                                                projectId={this.props.projectId}
                                                showFilter={this.props.showFilter != undefined ? this.props.showFilter : true}
                                                activeTab={sourceData.activeTab}
                                                expectedClientInvoiceId={this.props.expectedClientInvoiceId}
                                            />
                                        </Col>
                                    </Row>
                                </Container> : null
                            }
                        </TabPane>
                        <TabPane tabId="2">
                            <br />
                            {
                                sourceData.activeTab == 2 ? <Container fluid={true}>
                                    <Row>
                                        <Col>
                                            <ExpenseEditListContainer
                                                isOpneInModal={this.props.isOpneInModal}
                                                invoiceDate={this.props.invoiceDate}
                                                projectId={this.props.projectId}
                                                showFilter={this.props.showFilter != undefined ? this.props.showFilter : true}
                                                activeTab={sourceData.activeTab}
                                                expectedClientInvoiceId={this.props.expectedClientInvoiceId}
                                            />
                                        </Col>
                                    </Row>
                                </Container> : null
                            }
                        </TabPane>
                        <TabPane tabId="3">
                            <br />
                            {
                                sourceData.activeTab == 3 ? <Container fluid={true}>
                                    <Row>
                                        <Col>
                                            <SubContractorInvoiceEditListContainer
                                                isOpneInModal={this.props.isOpneInModal}
                                                invoiceDate={this.props.invoiceDate}
                                                projectId={this.props.projectId}
                                                showFilter={this.props.showFilter != undefined ? this.props.showFilter : true}
                                                activeTab={sourceData.activeTab}
                                                expectedClientInvoiceId={this.props.expectedClientInvoiceId}
                                            />
                                        </Col>
                                    </Row>
                                </Container> : null
                            }
                        </TabPane>
                    </TabContent>
                </div>
            </React.Fragment >
        );
    }
}

export default EditDataPage;
