import React, { Component } from "react"
import { Link } from "react-router-dom"
import { ModalActions } from "@gman/gman-redux"
import ChangePasswordForm from "components/authentication/form/ChangePasswordForm"
import anonymous from "assets/anonymous.jpg"
import {
    Button,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    UncontrolledDropdown
} from "reactstrap"
import { FormModal } from "@gman/gman-react-bootstrap"

class LoginStatusBar extends Component {

    constructor(props) {
        super(props)
        this.toggle = this.toggle.bind(this)
        this.state = {
            isOpen: false
        }
    }

    toggle() {
        this.setState({
            isOpen: !this.state.isOpen
        })
    }

    render() {
        var sourceData = this.props.sourceData
        var modalEvents = this.props.modalEvents
        var feedbackEvents = this.props.feedbackEvents
        var modals = sourceData && sourceData.modals || {}

        return (
            <div className="login-status">
                <div className="login-status-wrapper">
                    {
                        sourceData.isLoggedIn ?
                            <UncontrolledDropdown size="sm">
                                <DropdownToggle className="login-bar" color="light" caret>
                                    <img className="portrait-small" src={anonymous} />
                                </DropdownToggle>
                                <DropdownMenu right>
                                    <DropdownItem header>
                                        {sourceData.displayName} - {sourceData.displayRole}
                                    </DropdownItem>
                                    <DropdownItem divider />
                                    <DropdownItem tag={Link} to={"/userDetails/" + sourceData.userId}>
                                        <small>My Profile</small>
                                    </DropdownItem>
                                    <DropdownItem divider />
                                    <DropdownItem onClick={() => modalEvents.onShowModal("CHANGE_PASSWORD")}>
                                        <small>Change Password</small>
                                    </DropdownItem>
                                    <DropdownItem onClick={() => modalEvents.onShowModal("CONFIRM_LOGOUT")}>
                                        <small>Sign Out</small>
                                    </DropdownItem>
                                </DropdownMenu>
                            </UncontrolledDropdown>
                            :
                            <div className="logged-out-status">
                                You are not logged in. <Link to={"/login/"}>Click here to login</Link>
                            </div>
                    }
                </div>
                <FormModal
                    isVisible={ModalActions.IsVisible(modals, "CONFIRM_LOGOUT")}
                    onHide={() => modalEvents.onHideModal("CONFIRM_LOGOUT")}
                    title={"Sign out"}>
                    Sign out of the system?
                    <Button
                        className="float-right"
                        color="warning"
                        onClick={this.props.onConfirmLogout}
                    >
                        Log out
                    </Button>
                </FormModal>
                <FormModal
                    isVisible={ModalActions.IsVisible(modals, "CHANGE_PASSWORD")}
                    onHide={() => modalEvents.onHideModal("CHANGE_PASSWORD")}
                    title={"Change Password"}>
                    <ChangePasswordForm
                        inProgress={sourceData.inProgress}
                        feedbackEvents={feedbackEvents}
                        feedback={sourceData.feedback}
                        onSubmitChangePassword={this.props.onConfirmChangePassword}
                    />
                </FormModal>
            </div>
        )
    }
}

export default LoginStatusBar