import React from "react"
import Moment from "moment"
import { withFormik } from "formik"
import * as Yup from "yup"
import RequiredStar from "components/common/RequiredStar"
import { AuthorizedButton, AuthorizedInputField } from "components/authentication/controls/AuthorizedControls"

import {
    Form,
    FormGroup,
    Label
} from "reactstrap"

const SubmitButton = AuthorizedButton(p => p.canWriteSystem)
const InputField = AuthorizedInputField(p => p.canWriteSystem)

const BaseForm = props => {
    const {
        values,
        dirty,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting
    } = props
    return (
        <div>
            <Form noValidate onSubmit={handleSubmit}>
                <FormGroup>
                    <Label for="miscMessage">Timesheet MISC code message</Label>
                    <InputField
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.system ? values.system.miscMessage :  ""}
                        type="text"
                        name="system.miscMessage"
                        id="system.miscMessage"
                        placeholder="Enter a message to be shown upon submission or approval a timesheet"/>
                </FormGroup>
                <SubmitButton outline className="float-right" disabled={isSubmitting || !dirty} color="success" type="submit">Submit</SubmitButton>
            </Form>
        </div>
    )
}

const SystemFormExtended = withFormik({
    displayName: "SystemFormExtended",
    enableReinitialize: true,
    handleSubmit: (values, { props, setSubmitting }) => {
        props.onSubmitUpdate(values.system);
        setSubmitting(false)
    }
})(BaseForm)

export default SystemFormExtended