import { connect } from "react-redux"
import ExpenseMonthlyDueDatesList from "components/expenseMonthlyDueDates/layout/ExpenseMonthlyDueDatesList"
import { ExpenseMonthlyDueDatesScope } from "components/expenseMonthlyDueDates/redux/ExpenseMonthlyDueDatesScope"
import { FormActions, FormEvents, DispatchActions } from "@gman/gman-redux"

const mapDispatchToProps = (dispatch) => {
    var scope = ExpenseMonthlyDueDatesScope
    return {
        ...FormEvents.getConfirmEventsForModalsOnAll(dispatch, scope),
        ...FormEvents.getTableHeaderRowEvents(dispatch, scope),
        ...FormEvents.getFeedbackEvents(dispatch, scope),
        ...FormEvents.getPaginatorEvents(dispatch, scope),
        ...FormEvents.getModalEventsForModalsOnAll(dispatch, scope),
        ...FormEvents.getRecordStateEvents(dispatch, scope),
        onPageSizeChange: (pageSize) => {
            dispatch({ ...DispatchActions.scopedDispatch(scope.key, "CHANGE_PAGE_SIZE"), pageSize: pageSize });
            dispatch(FormActions.fetchRecords(scope));
        },
    }
}

const ExpenseMonthlyDueDatesListContainer = connect(
    state => { return { sourceData: ExpenseMonthlyDueDatesScope.selector(state) } },
    mapDispatchToProps
)(ExpenseMonthlyDueDatesList)

export { ExpenseMonthlyDueDatesListContainer }