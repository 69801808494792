import ScopeConstants from "constants/ScopeConstants"
import ProjectStatusConstants from "constants/ProjectStatusConstants"
import ProposalStatusConstants from "constants/ProposalStatusConstants"
import { FormActionConstants } from "@gman/gman-redux"

export const WorkItemsReducer = (
    state = { records: [] },
    action) => {

    if (action.type === FormActionConstants.RECEIVE_RECORDS) {
        var records = action.records
        switch (action.scope) {

            case (ScopeConstants.PROJECT_ANY):
                records = records.filter(function (record) {
                    return record.status === ProjectStatusConstants.INPROGRESS
                })

                var mergedRecords = mergeWorkItems(state.records, records, o => o.projectId, ScopeConstants.PROJECT_ANY)

                return ({ records: mergedRecords })

            case (ScopeConstants.PROPOSAL_ANY):
                records = records.filter(function (record) {
                    return record.status === ProposalStatusConstants.INPROGRESS
                })

                var mergedRecords = mergeWorkItems(state.records, records, o => o.proposalId, ScopeConstants.PROPOSAL_ANY)

                return ({ records: mergedRecords })

            case (ScopeConstants.LEAVE):
                var mergedRecords = mergeWorkItems(state.records, records, o => o.leaveId, ScopeConstants.LEAVE)

                return ({ records: mergedRecords })

            default:
                return state
        }
    }

    return state
}

const mergeWorkItems = (currentRecords, newRecords, propertyFunc, scopeConstant) => {

    for (var i = 0; i < currentRecords.length; i++) {
        var found = false;
        if (currentRecords[i].type === scopeConstant) {
            for (var j = 0; j < newRecords.length; j++) {
                if (propertyFunc(newRecords[j]) === currentRecords[i].workItemId && newRecords[j].type === scopeConstant) {
                    found = true
                }
            }
            if (!found) {
                currentRecords.splice(i, 1)
            }
        }
    }

    for (var i = 0; i < newRecords.length; i++) {
        var found = false;
        for (var j = 0; j < currentRecords.length; j++) {
            if (propertyFunc(newRecords[i]) === currentRecords[j].workItemId && currentRecords[j].type === scopeConstant) {
                found = true
                currentRecords[j] = normalise(newRecords[i], propertyFunc, scopeConstant)
            }
        }
        if (!found) {
            currentRecords.push(normalise(newRecords[i], propertyFunc, scopeConstant))
        }
    }
    return currentRecords
}

const normalise = (record, propertyFunc, type) => {
    return {
        type: type,
        name: record.name,
        code: record.code,
        workItemId: propertyFunc(record),
        clientId: record.clientId,
        costCodeGroupId: record.costCodeGroupId
    }
}