import { connect } from "react-redux";
import Moment from "moment";
import ExpenseList from "components/expenses/layout/ExpenseList";
import ExpenseByDueDateList from "components/expenses/layout/ExpenseByDueDateList";
import { ExpenseAnyScope } from "components/expenses/redux/ExpenseAnyScope";
import { ExpenseMineScope } from "components/expenses/redux/ExpenseMineScope";
import { ExpensesMineByDueDateScope } from "components/expenses/redux/ExpensesMineByDueDateScope";
import { ExpensesAnyByDueDateScope } from "components/expenses/redux/ExpensesAnyByDueDateScope";
import { ExpensesTeamByDueDateScope } from "components/expenses/redux/ExpensesTeamByDueDateScope";
import { ExpenseTeamScope } from "components/expenses/redux/ExpenseTeamScope";
import { ExpenseCalculations } from "components/expenses/redux/ExpenseCalculations";
import {
    FormActions,
    FormEvents,
    ModalConstants,
    DispatchActions,
    FetchActions
} from "@gman/gman-redux";
import { CostCodeScope } from "components/costCodes/redux/CostCodeScope";
import { CostCodeGroupAllScope } from "components/costCodeGroups/redux/CostCodeGroupAllScope";
import { ExpenseTypeScope } from "components/expenseTypes/redux/ExpenseTypeScope";
import { LeaveScope } from "components/leave/redux/LeaveScope";
import { ProjectAnyScope } from "components/projects/redux/ProjectAnyScope";
import { ProposalAnyScope } from "components/proposals/redux/ProposalAnyScope";
import { VariationScope } from "components/variations/redux/VariationScope";
import { SystemScope } from "components/system/redux/SystemScope";
import ExpenseStatusConstants from "constants/ExpenseStatusConstants";
import ProjectStatusConstants from "constants/ProjectStatusConstants";
import ProposalStatusConstants from "constants/ProposalStatusConstants";
import FileSaver from "file-saver";

const mapDispatchToPropsMine = dispatch => {
    return mapDispatchToProps(dispatch, ExpenseMineScope, "mine", true);
};

const mapDispatchToPropsTeam = dispatch => {
    return mapDispatchToProps(dispatch, ExpenseTeamScope, "team", false);
};

const mapDispatchToPropsAny = dispatch => {
    return mapDispatchToProps(dispatch, ExpenseAnyScope, "any", false);
};

const mapDispatchToPropsMineByDueDate = dispatch => {
    return mapDispatchToProps(dispatch, ExpensesMineByDueDateScope, "mine", true);
};

const mapDispatchToPropsAnyByDueDate = dispatch => {
    return mapDispatchToProps(dispatch, ExpensesAnyByDueDateScope, "any", true);
};

const mapDispatchToPropsTeamByDueDate = dispatch => {
    return mapDispatchToProps(dispatch, ExpensesTeamByDueDateScope, "team", true);
};

const mapDispatchToProps = (dispatch, scope, suffix, canAdd) => {
    var result = {
        ...FormEvents.getConfirmEventsForModalsOnAll(dispatch, scope),
        ...FormEvents.getTableHeaderRowEvents(dispatch, scope),
        ...FormEvents.getFeedbackEvents(dispatch, scope),
        ...FormEvents.getPaginatorEvents(dispatch, scope),
        ...FormEvents.getRecordStateEvents(dispatch, scope),
        ...ExpenseCalculations.getExpenseCalculation(dispatch, scope),
        onLoadDependentData: () => {
            dispatch(FormActions.fetchAll(SystemScope));
            dispatch(FormActions.fetchAll(CostCodeScope));
            dispatch(FormActions.fetchAll(CostCodeGroupAllScope));
            dispatch(FormActions.fetchAll(ExpenseTypeScope));
            dispatch(
                FormActions.fetchAll(ProjectAnyScope, {
                    status: ProjectStatusConstants.INPROGRESS
                })
            );
            dispatch(
                FormActions.fetchAll(ProposalAnyScope, {
                    status: ProposalStatusConstants.INPROGRESS
                })
            );
            dispatch(FormActions.fetchAll(VariationScope));
            dispatch(FormActions.fetchAll(LeaveScope));
        },
        onPageSizeChange: pageSize => {
            dispatch({
                ...DispatchActions.scopedDispatch(scope.key, "CHANGE_PAGE_SIZE"),
                pageSize: pageSize
            });
            dispatch(FormActions.fetchRecords(scope));
        },
        suffix: suffix,
        onConfirmSubmit: record => {
            record.status = ExpenseStatusConstants.SUBMITTED;
            dispatch(
                FormActions.doPost(
                    scope,
                    record,
                    "/api/Expense/Submit" + suffix,
                    "The expense was submitted successfully.",
                    true
                )
            ).then(result => {
                dispatch(FormActions.hideModal(scope, "SUBMIT"));
                if (result && result.error) {
                    record.status = ExpenseStatusConstants.DRAFT;
                    dispatch(FormActions.loadRecord(scope, record));
                } else {
                    dispatch(FormActions.hideModal(scope, ModalConstants.UPDATE));
                }
            });
        },
        onConfirmSaveAndSubmit: (records, history) => {
            records.forEach(record => {
                dispatch(
                    FormActions.doPost(
                        scope,
                        record,
                        "/api/Expense/Update" + suffix,
                        "The expense was updated successfully.",
                        true
                    )
                ).then(result => {
                    if (result && result.error) {
                    } else {
                        record = result.record;
                        record.status = ExpenseStatusConstants.SUBMITTED;
                        dispatch(
                            FormActions.doPost(
                                scope,
                                record,
                                "/api/Expense/Submit" + suffix,
                                "The expense was submitted successfully.",
                                false
                            )
                        ).then(result => {
                            dispatch(FormActions.hideModal(scope, "SUBMIT"));
                            if (result && result.error) {
                                record.status = ExpenseStatusConstants.DRAFT;
                                dispatch(FormActions.loadRecord(scope, record));
                            } else {
                                dispatch(FormActions.hideModal(scope, ModalConstants.UPDATE));
                            }
                            if (result && result.record) {
                                var dueDate = Moment(result.record.dueDate).format("DDMMYYYY");
                                history.push("/expense/" + suffix +"/due/" + dueDate);
                            }
                        });
                    }
                });
            });
        },
        onConfirmReject: record => {
            record.status = ExpenseStatusConstants.DECLINED;
            dispatch(
                FormActions.doPost(
                    scope,
                    record,
                    "/api/Expense/Update" + suffix,
                    "The expense was declined successfully.",
                    true
                )
            );
            dispatch(FormActions.hideModal(scope, "REJECT"));
            dispatch(FormActions.hideModal(scope, "EXPENSE_REJECT"));
            dispatch(FormActions.hideModal(scope, ModalConstants.UPDATE));
        },
        onConfirmApprove: record => {
            record.status = ExpenseStatusConstants.APPROVED;
            dispatch(
                FormActions.doPost(
                    scope,
                    record,
                    "/api/Expense/Update" + suffix,
                    "The expense was approved successfully.",
                    true
                )
            );
            dispatch(FormActions.hideModal(scope, "APPROVE"));
            dispatch(FormActions.hideModal(scope, "EXPENSE_APPROVE"));
            dispatch(FormActions.hideModal(scope, ModalConstants.UPDATE));
        },
        onConfirmUnsubmit: record => {
            record.status = ExpenseStatusConstants.DRAFT;
            dispatch(
                FormActions.doPost(
                    scope,
                    record,
                    "/api/Expense/Update" + suffix,
                    "The expense was un-submitted successfully.",
                    true
                )
            );
            dispatch(FormActions.hideModal(scope, "UNSUBMIT"));
            dispatch(FormActions.hideModal(scope, ModalConstants.UPDATE));
        },
        onConfirmPaid: record => {
            record.status = ExpenseStatusConstants.PAID;
            dispatch(
                FormActions.doPost(
                    scope,
                    record,
                    "/api/Expense/Update" + suffix,
                    "The expense was marked paid successfully.",
                    true
                )
            );
            dispatch(FormActions.hideModal(scope, "MARK_AS_PAID"));
            dispatch(FormActions.hideModal(scope, ModalConstants.UPDATE));
        },
        confirmEvents: {
            onSubmitUpdate: record => {
                dispatch(FormActions.updateRecord(scope, record));
                dispatch(FormActions.emptyRecord(scope));
            },
            onConfirmDelete: record => {
                dispatch(FormActions.deleteRecord(scope, record));
                dispatch(FormActions.hideModal(scope, ModalConstants.DELETE));
            }
        },
        onConfirmApproveAll: (clearSearch, records) => {
            let guids = records.filter((record) => record.status == ExpenseStatusConstants.SUBMITTED).map((record) => record.expenseId);
            dispatch(
                FormActions.doPost(
                    scope,
                    guids,
                    "/api/Expense/ApproveAllExpense" + suffix,
                    "All expenses were approved successfully.",
                    true
                )
            ).then(result => {
                if (result && result.error) {
                    dispatch(FormActions.clearSearch(scope, clearSearch));
                }
            });;
            dispatch(FormActions.hideModal(scope, "APPROVE"));
            dispatch(FormActions.hideModal(scope, "EXPENSE_APPROVE_ALL"));
            dispatch(FormActions.hideModal(scope, ModalConstants.UPDATE));
        },
        modalEvents: {
            ...FormEvents.getModalEventsForModalsOnAll(dispatch, scope).modalEvents,
            onShowApprove: record => {
                dispatch(FormActions.loadRecord(scope, record));
                dispatch(FormActions.showModal(scope, "EXPENSE_APPROVE"));
            },
            onShowReject: record => {
                dispatch(FormActions.loadRecord(scope, record));
                dispatch(FormActions.showModal(scope, "EXPENSE_REJECT"));
            },
            onShowPaid: record => {
                dispatch(FormActions.loadRecord(scope, record));
                dispatch(FormActions.showModal(scope, "MARK_AS_PAID"));
            },
            onShowApproveAll: () => {
                dispatch(FormActions.showModal(scope, "EXPENSE_APPROVE_ALL"));
            },
            onConfirmApproveAllByDueDate: record => {
                dispatch(FormActions.loadRecord(scope, record));
                dispatch(FormActions.showModal(scope, "EXPENSE_APPROVE_ALL_BY_DUE_DATE"));
            },
            onConfirmPaiedAllByDueDate: record => {
                dispatch(FormActions.loadRecord(scope, record));
                dispatch(FormActions.showModal(scope, "EXPENSE_PAIED_ALL_BY_DUE_DATE"));
            }
        },
        onRunExport: record => {
            var dueDate = Moment(record.dueDate, 'YYYY/MM/DD');
            dispatch(function (dispatch, getState) {
                var authToken = ""
                var fileName = ""
                if (scope.tokenSelector) {
                    authToken = scope.tokenSelector(getState())
                }
                dispatch(FormActions.inProgressStart(scope))
                FetchActions.fetchApiGet("/api/Expense/MonthlyExpensesReport" + suffix +"?dueDate=" + dueDate.format('YYYY/MM/DD'), authToken)
                .then(function (response) {
                    fileName = extractFileName(response.headers)
                    return Promise.resolve(response.blob())
                }).then(function (blob) {
                    FileSaver.saveAs(blob, fileName);
                })
                .then(() => { dispatch(FormActions.inProgressStop(scope)) })
                .catch((error) => { FormActions.handleErrorResponse(error, scope, dispatch) })
            })
        },
        onConfirmApproveAllByDueDate: record => {
            dispatch(
                FormActions.doPost(
                    scope,
                    record,
                    "/api/Expense/ApproveExpenseByDueDate" + suffix,
                    "All expenses were approved successfully.",
                    true
                )
            )
            dispatch(FormActions.hideModal(scope, "EXPENSE_APPROVE_ALL_BY_DUE_DATE"));
            dispatch(FormActions.hideModal(scope, ModalConstants.UPDATE));
        },
        onConfirmPaiedAllByDueDate: record => {
            dispatch(
                FormActions.doPost(
                    scope,
                    record,
                    "/api/Expense/PaiedExpenseByDueDate" + suffix,
                    "All expenses were mark as paid.",
                    true
                )
            )
            dispatch(FormActions.hideModal(scope, "EXPENSE_PAIED_ALL_BY_DUE_DATE"));
            dispatch(FormActions.hideModal(scope, ModalConstants.UPDATE));
        },
        onRunExpenseExport: record => {
            dispatch(function (dispatch, getState) {
                var authToken = ""
                var fileName = ""
                if (scope.tokenSelector) {
                    authToken = scope.tokenSelector(getState())
                }
                dispatch(FormActions.inProgressStart(scope))
                FetchActions.fetchApiGet("/api/Expense/ExpensesReport" + suffix, authToken)
                    .then(function (response) {
                        fileName = extractFileName(response.headers)
                        return Promise.resolve(response.blob())
                    }).then(function (blob) {
                        FileSaver.saveAs(blob, fileName);
                    })
                    .then(() => { dispatch(FormActions.inProgressStop(scope)) })
                    .catch((error) => { FormActions.handleErrorResponse(error, scope, dispatch) })
            })
        },
    };

    if (!canAdd) {
        result.modalEvents.onShowNew = null;
    }

    return result;
};

function extractFileName(headers) {
    var disposition = headers.get("content-disposition")
    var fileName = disposition.match(/filename\*?=((['"])[\s\S]*?\2|[^;\n]*)/)[1]
    return fileName
}

const ExpenseListMineContainer = connect((state, ownProps) => {
    return {
        sourceData: ExpenseMineScope.selector(state),
        systemData: SystemScope.selector(state).records,
        cardHeader: "My Expenses",
        ...ownProps
    };
}, mapDispatchToPropsMine)(ExpenseByDueDateList);

const ExpenseListTeamContainer = connect((state, ownProps) => {
    return {
        sourceData: ExpenseTeamScope.selector(state),
        systemData: SystemScope.selector(state).records,
        cardHeader: "Team Expenses",
        ...ownProps
    };
}, mapDispatchToPropsTeam)(ExpenseByDueDateList);

const ExpenseListAnyContainer = connect((state, ownProps) => {
    return {
        sourceData: ExpenseAnyScope.selector(state),
        systemData: SystemScope.selector(state).records,
        cardHeader: "All Expenses",
        ...ownProps
    };
}, mapDispatchToPropsAny)(ExpenseByDueDateList);

const ExpenseListMineByDueDateContainer = connect((state, ownProps) => {
    return {
        sourceData: ExpensesMineByDueDateScope.selector(state),
        cardHeader: "My Expenses",
        systemData: SystemScope.selector(state).records,
        ...ownProps
    };
}, mapDispatchToPropsMineByDueDate)(ExpenseList);

const ExpenseListAnyByDueDateContainer = connect((state, ownProps) => {
    return {
        sourceData: ExpensesAnyByDueDateScope.selector(state),
        cardHeader: "All Expenses",
        systemData: SystemScope.selector(state).records,
        ...ownProps
    };
}, mapDispatchToPropsAnyByDueDate)(ExpenseList);

const ExpenseListTeamByDueDateContainer = connect((state, ownProps) => {
    return {
        sourceData: ExpensesTeamByDueDateScope.selector(state),
        cardHeader: "Team Expenses",
        systemData: SystemScope.selector(state).records,
        ...ownProps
    };
}, mapDispatchToPropsTeamByDueDate)(ExpenseList);

export {
    ExpenseListMineContainer,
    ExpenseListTeamContainer,
    ExpenseListAnyContainer,
    ExpenseListMineByDueDateContainer,
    ExpenseListAnyByDueDateContainer,
    ExpenseListTeamByDueDateContainer
};
