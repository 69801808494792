import React, { Component } from "react";
import TableHeaderRow from "components/common/TableHeaderRow";
import { withRouter } from "react-router";
import PropTypes from "prop-types";
import {
    Button,
    Card,
    CardBody,
    CardHeader,
    FormGroup,
    Table,
    ButtonGroup
} from "reactstrap";
import ExpenseRow from "./ExpenseRow";
import { ExpenseShape } from "shapes/ExpenseShape";
import { ModalActions } from "@gman/gman-redux";
import FeedbackAlert from "components/common/FeedbackAlert";
import RejectReasonForm from "components/common/RejectReasonForm";
import ExpenseFormBasic from "components/expenses/form/ExpenseFormBasic";
import { AuthorizedAddButton, AuthorizedButton } from "components/authentication/controls/AuthorizedControls";
import ScopeConstants from "constants/ScopeConstants";
import PageSizeSelect from "components/common/PageSizeSelect";
import Moment from "moment";
import {
    ConfirmDelete,
    FormModal,
    Paginator,
    SpinnerOverlay
} from "@gman/gman-react-bootstrap";
import ExpenseTaxStatusConstants from "constants/ExpenseTaxStatusConstants";

const AddButton = AuthorizedAddButton(p => p.canCompleteOwnExpenses);
const ApproveAllTeamExpenseButton = AuthorizedButton(p => p.canApproveTeamExpense);
const ApproveAllAnyExpenseButton = AuthorizedButton(p =>  p.canApproveAnyExpense);

class ExpenseList extends Component {
    componentDidMount() {
        var dueDate = Moment(this.props.duedate, 'DD-MM-YYYY');
        if (dueDate.isValid()) {
            this.props.recordStateEvents.onClearSearch({ dueDate: dueDate.format('MM/DD/YYYY') });
        } else {
            this.props.recordStateEvents.onClearSearch({ status: this.props.match.params.status });
        }
        this.props.onLoadDependentData();
    }

    componentWillUnmount = () => {
        this.props.feedbackEvents.onFlushFeedback();
    };
    onAddSimilar = record => {
        const newRecord = { ...record, workflowPermissions: { canSubmit: true } };

        var isCustomTax = ((newRecord.taxStatus || 0) == ExpenseTaxStatusConstants.CUSTOM) === true;
        if (!isCustomTax) {
            // recalculate the text amount
            this.props.onRecalculateAmount(
                newRecord.amountUnit,
                newRecord.taxCalculated,
                newRecord.taxStatus,
                newRecord.expenseTypeId,
                newRecord.effectiveDate,
                0
            );
        }

        delete newRecord.expenseId;
        newRecord.effectiveDate = null;
        newRecord.status = 0;
        newRecord.dateApproved = null;
        newRecord.dateSubmitted = null;
        return newRecord;
    };
    render() {
        var sourceData = this.props.sourceData;
        var modals = sourceData.modals;
        var recordStateEvents = this.props.recordStateEvents;
        var confirmEvents = this.props.confirmEvents;
        var hideButtons = this.props.hideButtons;
        var feedbackEvents = this.props.feedbackEvents;
        var modalEvents = this.props.modalEvents;
        var paginatorEvents = this.props.paginatorEvents;
        var tableHeaderRowEvents = this.props.tableHeaderRowEvents;
        var workflowPermissions = this.props.workflowPermissions;
        const {
            history,
            location: { pathname },
            match: {
                params: { status }
            },
            suffix
        } = this.props;
        let dueDate = Moment(this.props.duedate, 'DD-MM-YYYY');
        var systemData = this.props.systemData && this.props.systemData.length > 0 ? this.props.systemData[0] : {};

        return (
            <Card>
                <CardHeader>
                    <h6>{this.props.cardHeader} {dueDate.isValid() ? (" - Month to " + dueDate.format('DD/MM/YYYY')) : "" }</h6>
                </CardHeader>
                <CardBody>
                    {
                        !ModalActions.IsUpdateVisible(modals) ? (
                            <FeedbackAlert {...feedbackEvents} feedback={sourceData.feedback} />
                        ) : ("")
                    }
                    <SpinnerOverlay isLoading={sourceData.inProgress}>
                        <Table hover size="sm">
                            <thead>
                                <TableHeaderRow
                                    {...tableHeaderRowEvents}
                                    disabled={sourceData.inProgress}
                                    headers={[
                                        { sortBy: "Description", label: "Description" },
                                        { sortBy: "EffectiveDate", label: "Date" },
                                        { sortBy: "DateSubmitted", label: "Date Submitted" },
                                        { sortBy: "DateApproved", label: "Date Approved" },
                                        { label: "Project" },
                                        {
                                            sortBy: "ExpenseType.Description",
                                            label: "Expense Type"
                                        },
                                        { sortBy: "AmountCalculated", label: "Amount (inc. GST)" },
                                        { sortBy: "TaxCalculated", label: "GST" },
                                        { sortBy: "Status", label: "Status" },
                                        { label: "" },
                                        { sortBy: "User.FirstName", label: "User" },
                                        { sortBy: "chargeClient", label: "Charge Client" },
                                        { sortBy: "reimburseMe", label: "Reimburse Me" }
                                    ]}
                                />
                            </thead>
                            <tbody>
                                {sourceData.records.map((record, index) => (
                                    <ExpenseRow
                                        key={index}
                                        index={index}
                                        {...record}
                                        onShowDelete={() => modalEvents.onShowDelete(record)}
                                        onAddSimilar={
                                            modalEvents.onShowNew
                                                ? () => modalEvents.onShowNew(this.onAddSimilar(record))
                                                : undefined
                                        }
                                        onShowEdit={() => {
                                            recordStateEvents.onLoadSingle(record.expenseId);
                                            modalEvents.onShowEdit(record);
                                        }}
                                        onShowApprove={() => this.props.onConfirmApprove(record)}
                                        onShowPaid={() => modalEvents.onShowPaid(record)}
                                        onShowReject={() => modalEvents.onShowReject(record)}
                                        disabled={sourceData.inProgress}
                                    />
                                ))}
                            </tbody>
                        </Table>
                        <Paginator
                            {...paginatorEvents}
                            searchOptions={sourceData.searchOptions}
                        />
                        <ButtonGroup size="sm" className="float-right">
                            {
                                !status && !hideButtons ? (
                                <Button
                                    className="float-right ml-1"
                                    outline size="sm" color="info"
                                    onClick={() =>
                                        history.push('/expense/' + suffix + '/submitted/')
                                    }>
                                    Pending Approval
                                </Button>
                                ) : ("")
                            }
                            {
                                modalEvents.onShowNew && !hideButtons ? (
                                <AddButton
                                    disabled={sourceData.inProgress}
                                        onClick={() => {
                                            this.props.onMakeStale();
                                            modalEvents.onShowNew({
                                                workflowPermissions: { canSubmit: true }
                                            });
                                        }
                                    }/>
                                ) : ("")
                            }
                            {
                                !hideButtons && suffix == "team" ? (
                                    <ApproveAllTeamExpenseButton outline size="sm" color="success"
                                        disabled={sourceData.inProgress}
                                        onClick={() =>
                                            modalEvents.onShowApproveAll()
                                        }>
                                        Approve All
                                    </ApproveAllTeamExpenseButton>
                                ) : ("")
                            }
                            {
                                !hideButtons ? (
                                    <ApproveAllAnyExpenseButton outline size="sm" color="success"
                                        disabled={sourceData.inProgress}
                                        onClick={() =>
                                            modalEvents.onShowApproveAll()
                                        }>
                                        Approve All
                                    </ApproveAllAnyExpenseButton>
                                ) : ("")
                            }
                        </ButtonGroup>
                        <PageSizeSelect
                            disabled={sourceData.inProgress}
                            defaultPageSize={sourceData.searchOptions.pageSize}
                            onChange={this.props.onPageSizeChange} />
                    </SpinnerOverlay>
                </CardBody>
                <FormModal
                    isVisible={ModalActions.IsDeleteVisible(sourceData.modals)}
                    onHide={modalEvents.onHideDelete}
                    title={"Confirm Delete"}>
                    <ConfirmDelete
                        onConfirmDelete={() =>
                            confirmEvents.onConfirmDelete(sourceData.recordToUpdate)
                        }
                    />
                </FormModal>
                <FormModal
                    isVisible={ModalActions.IsVisible(modals, "EXPENSE_APPROVE")}
                    onHide={() => modalEvents.onHideModal("EXPENSE_APPROVE")}
                    title={"Confirm Approve"}>
                    <FormGroup>
                        Approve this expense?
                        <Button
                            className="float-right"
                            color="success"
                            onClick={() =>
                                this.props.onConfirmApprove(sourceData.recordToUpdate)
                            }>
                            Confirm
                        </Button>
                    </FormGroup>
                </FormModal>
                <FormModal
                    isVisible={ModalActions.IsVisible(modals, "EXPENSE_APPROVE_ALL")}
                    onHide={() => modalEvents.onHideModal("EXPENSE_APPROVE_ALL")}
                    title={"Confirm Approve All"}>
                    <FormGroup>
                        Approve all of these expenses?
                        <Button
                            className="float-right"
                            color="success"
                            onClick={
                                () => {
                                    var dueDate = Moment(this.props.duedate, 'DD-MM-YYYY');
                                    if (dueDate.isValid()) {
                                        this.props.onConfirmApproveAll({ dueDate: dueDate.format('MM/DD/YYYY') }, sourceData.records)
                                    } else {
                                        this.props.onConfirmApproveAll({ status: 'submitted' }, sourceData.records)
                                    }
                                }
                            }>
                            Confirm
                        </Button>
                    </FormGroup>
                </FormModal>
                <FormModal
                    isVisible={ModalActions.IsVisible(modals, "MARK_AS_PAID")}
                    onHide={() => modalEvents.onHideModal("MARK_AS_PAID")}
                    title={"Confirm Mark as Paid"}>
                    <FormGroup>
                        Mark this expense as paid?
                        <Button
                            className="float-right"
                            color="success"
                            onClick={() =>
                                this.props.onConfirmPaid(sourceData.recordToUpdate)
                            }>
                            Confirm
                        </Button>
                    </FormGroup>
                </FormModal>
                <FormModal
                    isVisible={ModalActions.IsVisible(modals, "EXPENSE_REJECT")}
                    onHide={() => modalEvents.onHideModal("EXPENSE_REJECT")}
                    title={"Confirm Decline"}>
                    <RejectReasonForm
                        onConfirmReject={comments =>
                            this.props.onConfirmReject({
                                ...sourceData.recordToUpdate,
                                comments: comments
                            })
                        }
                    />
                </FormModal>
                <FormModal
                    size="lg"
                    isVisible={ModalActions.IsUpdateVisible(modals)}
                    onHide={modalEvents.onHideEdit}
                    title={"Update Expense"}>
                    <ExpenseFormBasic
                        {...sourceData.recordToUpdate}
                        {...sourceData.calculation}
                        {...ConstructWorkItem(sourceData.recordToUpdate)}
                        inProgress={sourceData.inProgress}
                        effectiveDate={
                            sourceData.recordToUpdate &&
                                sourceData.recordToUpdate.effectiveDate
                                    ? sourceData.recordToUpdate.effectiveDate : ""
                        }
                        modals={sourceData.modals}
                        modalEvents={modalEvents}
                        feedback={sourceData.feedback}
                        feedbackEvents={feedbackEvents}
                        onConfirmApprove={this.props.onConfirmApprove}
                        onConfirmReject={this.props.onConfirmReject}
                        onConfirmSubmit={this.props.onConfirmSubmit}
                        onConfirmUnsubmit={this.props.onConfirmUnsubmit}
                        onConfirmSaveAndSubmit={(model) => {
                            this.props.onConfirmSaveAndSubmit(model, this.props.history);
                        }}
                        onSubmitUpdate={confirmEvents.onSubmitUpdate}
                        systemData={systemData}
                        onRecalculate={(
                            amountUnit,
                            taxCalculated,
                            taxStatus,
                            expenseTypeId,
                            effectiveDate,
                            index
                        ) =>
                            this.props.onRecalculateAmount(
                                amountUnit,
                                taxCalculated,
                                taxStatus,
                                expenseTypeId,
                                effectiveDate,
                                index
                            )
                        }
                        onMakeStale={() => this.props.onMakeStale()}
                    />
                </FormModal>
            </Card>
        );
    }
}

const ConstructWorkItem = record => {
    return {
        workItem: {
            workItemId: record.proposalId ? record.proposalId : record.projectId ? record.projectId : record.leaveId ? record.leaveId : null,
            costCodeGroupId: record.projectId ? record.project && record.project.costCodeGroupId : null
        },
        costCode: {
            costCodeId: record.costCodeId
        },
        type: ScopeConstants.PROJECT_ANY,
        variation: record.variation
    };
};

ExpenseList.propTypes = {
    sourceData: PropTypes.shape({
        records: PropTypes.arrayOf(ExpenseShape)
    })
};

export default withRouter(ExpenseList);
