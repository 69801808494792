import React, { Component } from "react"
import { Link } from "react-router-dom"
import { Redirect } from "react-router"
import {
    Card,
    CardBody,
    CardHeader,
    CardTitle,
    CardSubtitle,
    Container,
    Col,
    Nav,
    NavItem,
    NavLink,
    Row,
    TabContent,
    TabPane
} from "reactstrap"
import { SpinnerOverlay } from "@gman/gman-react-bootstrap"
import ProposalDisplayExtended from "components/proposals/form/ProposalDisplayExtended"
import ProposalFormExtended from "components/proposals/form/ProposalFormExtended"
import ProposalStatusBadge from "components/common/ProposalStatusBadge"
import FeedbackAlert from "components/common/FeedbackAlert"
import { RestrictedComponent } from "components/authentication/redux/AuthorizationWrappers"
import Icon from "components/common/Icon"

import ChargeRateTypeConstants from "constants/ChargeRateTypeConstants"
import { ChargeRateListContainer, ChargeRateListExtendedContainer } from "components/chargeRates/redux/ChargeRateContainers"

const EditRecordLink = RestrictedComponent((props) =>
    <Link className="editor-span" to={"/proposalDetails/" + props.recordId}>
        <Icon edit />
    </Link>, p => p.canWriteWorkItems)

const ChargeRatesTab = RestrictedComponent((props) =>
    <NavItem>
        <NavLink onClick={props.onChangeTab}>
            <Icon chargeRate />
            Charge Rates
        </NavLink>
    </NavItem>, p => p.canReadChargeRates)

class ProposalCardExtended extends Component {

    componentDidMount() {
        if (this.props.proposalId) {
            this.props.recordStateEvents.onLoadSingle(this.props.proposalId)
            this.props.onLoadDependentData()
            this.props.tabEvents.onChangeTab("1")
        }
    }

    componentWillUnmount = () => {
        this.props.feedbackEvents.onFlushFeedback()
        this.props.redirectEvents.onClearRedirect()
    }
    render() {
        var readOnly = this.props.readOnly
        var sourceData = this.props.sourceData
        var markup = sourceData.recordToUpdate.client ? sourceData.recordToUpdate.client.markup : 0;
        if (sourceData.redirect) {
            return (<Redirect to={sourceData.redirect} />)
        }

        var modals = sourceData.modals;
        var confirmEvents = this.props.confirmEvents
        var feedbackEvents = this.props.feedbackEvents
        var modalEvents = this.props.modalEvents
        var tabEvents = this.props.tabEvents
        console.log("sourceData.recordToUpdate-aaa",this.props.sourceData.recordToUpdate);
        return (
            <Card>
                <CardHeader>
                    <CardTitle>
                        {
                            sourceData.recordToUpdate ?
                                sourceData.recordToUpdate.name :
                                "Loading..."
                        }
                        {readOnly ? <EditRecordLink recordId={this.props.proposalId} /> : ""}
                    </CardTitle>
                    <hr />
                    <CardSubtitle>
                        <Icon proposal />
                        <small>
                            <i>Proposal</i>
                        </small>
                    </CardSubtitle>
                    <br />
                    <ProposalStatusBadge value={sourceData.recordToUpdate.status} />
                </CardHeader>
                <CardBody>
                    <FeedbackAlert {...feedbackEvents} feedback={sourceData.feedback} />
                    <Container>
                        <Row>
                            <Col>
                                <Nav tabs>
                                    <NavItem>
                                        <NavLink onClick={() => tabEvents.onChangeTab("1")}>
                                            <Icon folderOpen />Details
                                        </NavLink>
                                    </NavItem>
                                    <ChargeRatesTab onChangeTab={() => tabEvents.onChangeTab("2")} />
                                </Nav>
                            </Col>
                        </Row>
                    </Container>
                    <div>
                        <TabContent activeTab={sourceData.activeTab}>
                            <TabPane tabId="1">
                                <br />
                                <Container>
                                    <Row>
                                        <Col>
                                            <SpinnerOverlay isLoading={sourceData.inProgress}>
                                                {readOnly ?
                                                    <ProposalDisplayExtended values={sourceData.recordToUpdate} /> :
                                                    <div>
                                                        <ProposalFormExtended
                                                            modals={modals}
                                                            modalEvents={modalEvents}
                                                            onSubmitUpdate={confirmEvents.onSubmitUpdate}
                                                            onConfirmConvert={this.props.onConfirmConvert}
                                                            {...confirmEvents}
                                                            {...sourceData.recordToUpdate}
                                                            markup = {markup}
                                                        />
                                                    </div>
                                                }
                                            </SpinnerOverlay>
                                        </Col>
                                    </Row>
                                </Container>
                            </TabPane>
                            <TabPane tabId="2">
                                <br />
                                <Container>
                                    <Row>
                                        <Col>
                                            <ChargeRateListExtendedContainer
                                                principalId={this.props.proposalId}
                                                principal={sourceData.recordToUpdate}
                                                currentRatesType={ChargeRateTypeConstants.PROPOSAL}
                                            />
                                        </Col>
                                    </Row>
                                </Container>
                            </TabPane>
                        </TabContent>
                    </div>
                </CardBody >
            </Card >
        )
    }
}

export default ProposalCardExtended