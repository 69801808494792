import React, { Component } from "react"
import { Card, CardBody, CardHeader, CardTitle, CardSubtitle } from "reactstrap"
import { SpinnerOverlay } from "@gman/gman-react-bootstrap"
import SubContractorInvoiceFormExtended from "components/subContractorInvoices/form/SubContractorInvoiceFormExtended"
import InvoiceStatusBadge from "components/common/InvoiceStatusBadge"
import FeedbackAlert from "components/common/FeedbackAlert"
import Icon from "components/common/Icon"

class SubContractorInvoiceCardExtended extends Component {

    componentDidMount() {
        if (this.props.subContractorInvoiceId) {
            this.props.recordStateEvents.onLoadSingle(this.props.subContractorInvoiceId)
            this.props.onLoadDependentData()
        }
    }

    componentWillUnmount = () => {
        this.props.feedbackEvents.onFlushFeedback()        
        this.props.redirectEvents.onClearRedirect()
    }

    render() {
        var sourceData = this.props.sourceData
        var lastInvoicePayments = this.props.lastInvoicePayments
        var modals = sourceData.modals;
        var confirmEvents = this.props.confirmEvents
        var feedbackEvents = this.props.feedbackEvents
        var modalEvents = this.props.modalEvents
        
        return (
            <Card>
                <CardHeader>
                    <CardTitle>
                        {
                            sourceData.recordToUpdate ?
                                sourceData.recordToUpdate.number :
                                "Loading..."
                        }
                    </CardTitle>
                    <hr />
                    <CardSubtitle>
                        <Icon invoice />
                        <small>
                            <i>Sub Contractor Invoice</i>
                        </small>
                    </CardSubtitle>
                    <br />
                    <InvoiceStatusBadge value={sourceData.recordToUpdate.status} />
                </CardHeader>
                <CardBody>
                    <FeedbackAlert {...feedbackEvents} feedback={sourceData.feedback} />
                    <SpinnerOverlay isLoading={sourceData.inProgress}>
                        <div>
                            <SubContractorInvoiceFormExtended
                                modals={modals}
                                modalEvents={modalEvents}
                                {...confirmEvents}
                                {...sourceData.recordToUpdate}
                                {...lastInvoicePayments}
                            />
                        </div>
                    </SpinnerOverlay>
                </CardBody>
            </Card>
        )
    }
}

export default SubContractorInvoiceCardExtended