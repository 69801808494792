import React, { Component } from "react"
import { SubContractorInvoiceContainerExtended } from "components/subContractorInvoices/redux/SubContractorInvoiceContainers"
import SubContractorInvoiceCardBasic from "components/subContractorInvoices/layout/SubContractorInvoiceCardBasic"

export class SubContractorInvoicePageBasic extends Component {
    render() { return (<SubContractorInvoiceCardBasic />) }
}

export class SubContractorInvoicePageExtended extends Component {
    render() { return (<SubContractorInvoiceContainerExtended subContractorInvoiceId={this.props.match.params.subContractorInvoiceId} />) }
}