import { connect } from "react-redux"
import OutstandingTimesheetList from "components/outstandingTimesheets/layout/OutstandingTimesheetList"
import { OutstandingTimesheetMineScope } from "components/outstandingTimesheets/redux/OutstandingTimesheetMineScope"
import { OutstandingTimesheetTeamScope } from "components/outstandingTimesheets/redux/OutstandingTimesheetTeamScope"
import { OutstandingTimesheetAllScope } from "components/outstandingTimesheets/redux/OutstandingTimesheetAllScope"
import { FormActions, FormEvents, ModalConstants } from "@gman/gman-redux"
import { UserScope } from "components/users/redux/UserScope"

const mapDispatchToPropsMine = (dispatch) => { return mapDispatchToProps(dispatch, OutstandingTimesheetMineScope, "mine", true) }
const mapDispatchToPropsTeam = (dispatch) => { return mapDispatchToProps(dispatch, OutstandingTimesheetTeamScope, "team", true) }
const mapDispatchToPropsAll = (dispatch) => { return mapDispatchToProps(dispatch, OutstandingTimesheetAllScope, "any", true) }

const mapDispatchToProps = (dispatch, scope, suffix, canAdd) => {
    var result = {
        ...FormEvents.getTableHeaderRowEvents(dispatch, scope),
        ...FormEvents.getFeedbackEvents(dispatch, scope),
        ...FormEvents.getPaginatorEvents(dispatch, scope),
        ...FormEvents.getModalEventsForModalsOnAll(dispatch, scope),
        ...FormEvents.getRecordStateEvents(dispatch, scope),
        ...FormEvents.getRedirectEvents(dispatch, scope),
        onLoadDependentData: () => {
            dispatch(FormActions.fetchAll(UserScope))
        },
        confirmEvents: {
            ...FormEvents.getConfirmEventsForModalsOnNewOnly(dispatch, scope).confirmEvents,
            onSubmitUpdate: (record) => {
                var redirect = { urlFunc: (o) => "/timesheetEntry/mine/" + o.timesheetId }
                dispatch(FormActions.doPost(scope, record, scope.updateEndpoint, "The record was updated successfully.", true, redirect))
                    .then(() => { })
                    .finally(() => { dispatch(FormActions.hideModal(scope, ModalConstants.UPDATE)) })
            }
        },
        suffix: suffix
    }

    if (!canAdd) {
        result.modalEvents.onShowNew = null
    }

    return result;
}

const OutstandingTimesheetListMineContainer = connect(
    (state) => {
        return {
            sourceData: OutstandingTimesheetMineScope.selector(state),
            canSelectUser: false
        }
    },
    mapDispatchToPropsMine
)(OutstandingTimesheetList)

const OutstandingTimesheetListTeamContainer = connect(
    (state) => {
        return {
            sourceData: OutstandingTimesheetTeamScope.selector(state),
            canSelectUser: true
        }
    },
    mapDispatchToPropsTeam
)(OutstandingTimesheetList)

const OutstandingTimesheetListAllContainer = connect(
    (state) => {
        return {
            sourceData: OutstandingTimesheetAllScope.selector(state),
            canSelectUser: true
        }
    },
    mapDispatchToPropsAll
)(OutstandingTimesheetList)

export {
    OutstandingTimesheetListMineContainer,
    OutstandingTimesheetListTeamContainer,
    OutstandingTimesheetListAllContainer
}