import React from "react"
import { ModalActions } from "@gman/gman-redux"
import ChargeRateTypeConstants from "constants/ChargeRateTypeConstants"
import { ChargeRatesDraftFormContainer } from "components/chargeRates/redux/ChargeRateContainers"
import { RestrictedComponent } from "components/authentication/redux/AuthorizationWrappers"
import Icon from "components/common/Icon"
import { FormModal } from "@gman/gman-react-bootstrap"
import Moment from "moment";
import {
    Badge,
    Button,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    FormGroup,
    UncontrolledButtonDropdown
} from "reactstrap"

const ChargeRateMenu =
    RestrictedComponent(
        (props) =>
            <UncontrolledButtonDropdown size="sm" className="float-right">
                <DropdownToggle color="light" caret>
                    Using <b>{MapChargeRateToLabel(props.usingRatesType)}</b> Rates
                </DropdownToggle>
                <DropdownMenu>
                    <DropdownItem header>Workflow</DropdownItem>
                    <DropdownItem onClick={() => {
                        let that = props;
                        that.onGetRates(that.principalId, that.currentRatesType).then((result) => {
                            that.onShowModal("DRAFT");
                        })

                    }}>
                        <Icon addVersion />Create new version
                    </DropdownItem>
                </DropdownMenu>
            </UncontrolledButtonDropdown>,
        p => p.canWriteChargeRates,
        (props) =>
            <Badge color="light" className="float-right">
                <big>Using {MapChargeRateToLabel(props.usingRatesType)} Rates</big>
            </Badge>
    )

const ChargeRateMenuOptions = props => {
    const {
        principalId,
        currentRatesType,
        onPublishNewVersion,
        onGetRates,
        onShowModal,
        onHideModal,
        modals,
        principal,
    } = props

    let {
        name,
        comment,
        effectiveDate
    } = props
    // set default rate list name, effective date and comment
    if (currentRatesType != "STANDARD" && principal) {
        name = name || principal.code + '-from-' + Moment(new Date()).format("YY.MM.DD");
    } else {
        name = name || currentRatesType.toUpperCase() + '-from-' + Moment(new Date()).format("YY.MM.DD");
    }
    
    effectiveDate = effectiveDate || Moment(new Date()).format("YYYY-MM-DD");
    comment = comment || '';
    return (
        <div>
            <ChargeRateMenu {...props} onGetRates={onGetRates} principalId={principalId} currentRatesType={currentRatesType}/>

            <FormModal
                isVisible={ModalActions.IsVisible(modals, "VERSION")}
                onHide={() => onHideModal("VERSION")}
                title={"Confirm Publish of New Rates"}>
                <FormGroup>
                    <div>All projects using this set of rates will use the updated set of rates from the date you have set.</div>
                    <Button
                        className="float-right"
                        color="success"
                        onClick={() => {
                            let that = props;
                            onHideModal("VERSION")
                            onGetRates(principalId, currentRatesType).then((result) => {
                                that.onShowModal("DRAFT");
                            })
                            
                        }}>
                        Create
                    </Button>
                </FormGroup>
            </FormModal>
            <FormModal
                size="lg"
                isVisible={ModalActions.IsVisible(modals, "DRAFT")}
                onHide={() => onHideModal("DRAFT")}
                title={"New Charge Rates"}>
                <ChargeRatesDraftFormContainer
                    modals={modals}
                    onShowModal={onShowModal}
                    onHideModal={onHideModal}
                    principalId={principalId}
                    principal={principal}
                    name={name}
                    effectiveDate={effectiveDate}
                    comment={comment}
                    currentRatesType={currentRatesType}
                    onSubmitUpdate={onPublishNewVersion}
                />
            </FormModal>
        </div>
    )
}

const MapChargeRateToLabel = value => {
    switch (value) {
        case ChargeRateTypeConstants.STANDARD:
            return "Standard"
        case ChargeRateTypeConstants.CLIENT:
            return "Client"
        case ChargeRateTypeConstants.PROJECT:
            return "Project"
        case ChargeRateTypeConstants.PROPOSAL:
            return "Proposal"
        case ChargeRateTypeConstants.COST:
            return "Cost"
        case ChargeRateTypeConstants.STANDARDCONTRACTOR:
            return "Standard Contractor"
            
        default:
            return "Unknown"
    }
}

export default ChargeRateMenuOptions