import React from "react"
import { withFormik } from "formik"
import * as Yup from "yup"
import Icon from "components/common/Icon"
import RequiredStar from "components/common/RequiredStar"
import { AuthorizedButton, AuthorizedInputField } from "components/authentication/controls/AuthorizedControls"

import {
    Col,
    Container,
    Form,
    FormGroup,
    Label,
    Row
} from "reactstrap"

const SubmitButton = AuthorizedButton(p => p.canWriteCostCodes)
const InputField = AuthorizedInputField(p => p.canWriteCostCodes)

const BaseForm = props => {
    const {
        values,
        dirty,
        errors,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting
    } = props
    return (
        <div>
            <Form noValidate onSubmit={handleSubmit}>
                <h6>
                    <Icon folderOpen />
                    Cost Code Group Details
                </h6>
                <hr />
                <Container>
                    <Row>
                        <Col xs={12} md={6}>
                            <FormGroup>
                                <RequiredStar />
                                <Label for="name">Cost Code Group Name</Label>
                                <InputField
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.name || ""}
                                    type="text"
                                    name="name"
                                    id="name"
                                    placeholder="Enter a cost code group name"
                                    invalid={errors.name} />
                                {errors.name && <small className="text-danger">{errors.name}</small>}
                            </FormGroup>
                        </Col>
                        <Col xs={12} md={6}>
                            <FormGroup>
                                <Label for="associatedStage">Associated Stage</Label>
                                <InputField
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.associatedStage || ""}
                                    type="text"
                                    name="associatedStage"
                                    id="associatedStage"
                                    placeholder="Enter an associated stage"
                                    invalid={errors.associatedStage} />
                                {errors.associatedStage && <small className="text-danger">{errors.associatedStage}</small>}
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12}>
                            <FormGroup>
                                <Label for="description">Description</Label>
                                <InputField
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.description || ""}
                                    type="text"
                                    name="description"
                                    id="description"
                                    placeholder="Enter a cost code group description"
                                    invalid={errors.description} />
                                {errors.description && <small className="text-danger">{errors.description}</small>}
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <SubmitButton outline className="float-right" disabled={isSubmitting || !dirty} color="success" type="submit">Submit</SubmitButton>
                        </Col>
                    </Row>
                </Container>
            </Form>
        </div>
    )
}

const validationSchema = Yup.object({
    name: Yup.string()
        .required("Name is required"),
    associatedStage:
        Yup
            .number("Associated stage must be a number")
            .min(0)
            .integer("Associated stage must be a whole number")
            .typeError("Associated stage must be a number")
})

const CostCodeGroupFormBasic = withFormik({
    displayName: "CostCodeGroupFormBasic",
    enableReinitialize: true,
    validationSchema: validationSchema,
    initialValues: ({ value }) => ({
        name: value.name,
        description: value.description
    }),
    handleSubmit: (values, { props, setSubmitting }) => {
        props.onSubmitUpdate(values)
        setSubmitting(false)
    }
})(BaseForm)

export { CostCodeGroupFormBasic }