import ScopeConstants from "constants/ScopeConstants"
import ExpenseStatusConstants from "constants/ExpenseStatusConstants"

export const ExpenseMineScope = {
    key: ScopeConstants.EXPENSE_MINE,
    selector: (state) => state.expensesMine,
    tokenSelector: (state) => state.authentication.token,
    fetchEndpoint: "/api/Expense/SearchMine/",
    singleEndpoint: "/api/Expense/GetMine/",
    updateEndpoint: "/api/Expense/UpdateMine/",
    deleteEndpoint: "/api/Expense/DeleteMine/",
    defaultRecord: {
        status: ExpenseStatusConstants.DRAFT
    }
}