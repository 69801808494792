import React from "react"
import { Badge } from "reactstrap"
import ProjectStatusConstants from "constants/ProjectStatusConstants"

const ProjectStatusBadge = props => {
    const { value } = props
    return (
        <Badge color={MapProjectStatusColour(value)}>
            {MapProjectStatusLabel(value)}
        </Badge>
    )
}

const MapProjectStatusLabel = value => {
    switch (value) {
        case ProjectStatusConstants.DRAFT:
            return "DRAFT"
        case ProjectStatusConstants.INPROGRESS:
            return "IN PROGRESS"
        case ProjectStatusConstants.COMPLETED:
            return "COMPLETED"
        default:
            return "UNKNOWN"
    }
}

const MapProjectStatusColour = value => {
    switch (value) {
        case ProjectStatusConstants.DRAFT:
            return "warning"
        case ProjectStatusConstants.INPROGRESS:
            return "success"
        case ProjectStatusConstants.COMPLETED:
            return "light"
        default:
            return "default"
    }
}

export default ProjectStatusBadge