import React, { Component } from "react"
import { NavMenu } from "./NavMenu"
import { Footer } from "./Footer"
import { Container } from "reactstrap"

export class Layout extends Component {
    displayName = Layout.name

    render() {
        const { pathname } = this.props.location;
        console.log(pathname);
        return (
            <Container>
                {
                    pathname.indexOf("/editData") == -1 && pathname.indexOf("/projectDetails") == -1 && pathname.indexOf("/projectSheet") == -1 ?
                        (
                            <React.Fragment>
                                <NavMenu />
                                {this.props.children}
                                <Footer />
                            </React.Fragment>
                        ) : this.props.children
                }
            </Container>
        )
    }
}
