import ScopeConstants from "constants/ScopeConstants"
import ProposalStatusConstants from "constants/ProposalStatusConstants"

export const ProposalMineScope = {
    key: ScopeConstants.PROPOSAL_MINE,
    selector: (state) => state.proposalsMine,
    tokenSelector: (state) => state.authentication.token,
    fetchEndpoint: "/api/Proposal/SearchMine/",
    singleEndpoint: "/api/Proposal/GetAny/",
    updateEndpoint: "/api/Proposal/UpdateAny/",
    deleteEndpoint: "/api/Proposal/DeleteAny/",
    publishEndpoint: "/api/Proposal/PublishAny/",
    defaultRecord: {
        name: "",
        code: "",
        status: ProposalStatusConstants.DRAFT
    }
}