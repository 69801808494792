import React from "react"
import { withFormik } from "formik"
import * as Yup from "yup"
import RequiredStar from "components/common/RequiredStar"
import { AuthorizedButton, AuthorizedInputField, AuthorizedDropDownField } from "components/authentication/controls/AuthorizedControls"

import {
    Form,
    FormGroup,
    Label,
    Input,
    Col,
    Row
} from "reactstrap"

const SubmitButton = AuthorizedButton(p => p.canWriteClassifications)
const InputField = AuthorizedInputField(p => p.canWriteClassifications)
const DropdownField = AuthorizedDropDownField(p => p.canWriteClassifications)
const field = { xs: 12, md: 9 }


const BaseForm = props => {
    const {
        values,
        dirty,
        errors,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
        arrRadios,
        handleRadioChange,
        classificationList
        // handleRadioChange
    } = props
    // values.classification = classificationList[0].name;
    // var readOnlyClass = "form-control-plaintext form-control read-only-label"
    return (
        <div>
            <Form noValidate onSubmit={handleSubmit}>
                <FormGroup>
                    <RequiredStar />
                    <Label for="name">Classification Name</Label>
                    <InputField
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.name || ""}
                        type="text"
                        name="name"
                        id="name"
                        placeholder="Enter a classification name"
                        invalid={errors.name} />
                    {errors.name && <small className="text-danger">{errors.name}</small>}
                </FormGroup>
                <FormGroup>
                    <Label for="description">Description</Label>
                    <InputField
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.description || ""}
                        type="text"
                        name="description"
                        id="description"
                        placeholder="Enter a classification description"
                        invalid={errors.description} />
                    {errors.description && <small className="text-danger">{errors.description}</small>}
                </FormGroup>
                {!props.classificationId &&
                    <FormGroup>
                        <RequiredStar />
                        <Label for="standardchargerate">Standard Charge Rates</Label>
                        <InputField
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.standardchargerate || ""}
                            type="text"
                            name="standardchargerate"
                            id="standardchargerate"
                            placeholder="Enter a standard charge rate"
                            invalid={errors.standardchargerate} />
                        {errors.standardchargerate && <small className="text-danger">{errors.standardchargerate}</small>}
                    </FormGroup>
                }
                {!props.classificationId &&
                    <FormGroup>
                        <RequiredStar />
                        <Label for="standardcontractorchargerate">Standard Contractor Charge Rate</Label>
                        <InputField
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.standardcontractorchargerate || ""}
                            type="text"
                            name="standardcontractorchargerate"
                            id="standardcontractorchargerate"
                            placeholder="Enter a standard contractor charge rate"
                            invalid={errors.standardcontractorchargerate} />
                        {errors.standardcontractorchargerate && <small className="text-danger">{errors.standardcontractorchargerate}</small>}
                    </FormGroup>
                }
                {!props.classificationId &&
                    <FormGroup>
                        <RequiredStar />
                        <Label for="costrate">Cost Rate</Label>
                        <InputField
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.costrate || ""}
                            type="text"
                            name="costrate"
                            id="costrate"
                            placeholder="Enter a cost rate"
                            invalid={errors.costrate} />
                        {errors.costrate && <small className="text-danger">{errors.costrate}</small>}
                    </FormGroup>


                }
                {!props.classificationId &&

                    <FormGroup>
                        <RequiredStar />
                        <Label for="clientchargerate">Client Charge Rate</Label>
                        <div class="mb-2 mt-2" cssModule="width:80%">
                            <Row className="col-12 d-flex justify-content-between">
                                {arrRadios.map((res) => {
                                    console.log(res, 'res')
                                    return (
                                        <Label className="d-flex">
                                            <input
                                                onclick={(e) => { console.log(e) }}
                                                onChange={handleRadioChange}
                                                // onBlur={handleBlur}
                                                type="radio"
                                                name={res.text}
                                                value={res.id}
                                                checked={res.checked}
                                                style={{ position: 'unset', margin: 0 }}
                                            // disabled={isSubmitting || values.contractType == "4"}
                                            />
                                            <span className="ml-1">{res.name}</span>
                                        </Label>
                                    )

                                })}
                            </Row>
                        </div>
                    </FormGroup>
                }
                {!props.classificationId &&

                    <FormGroup>
                        {arrRadios.map((res) => {
                            if (res.checked === true && res.text === 'asPerClassification') {
                                return (
                                    <FormGroup>
                                        <RequiredStar />
                                        <Label for="classifications">Classification</Label>
                                        <DropdownField
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.classifications || ""}
                                            name="classifications"
                                            id="classifications"
                                            placeholder="Select classification"
                                            invalid={errors.classifications} >
                                            {classificationList.map((classification) => {
                                                return (
                                                    <option value={classification.name}>{classification.name}</option>
                                                )
                                            })}
                                        </DropdownField>
                                    </FormGroup>
                                )

                            }

                        })}
                    </FormGroup>
                }
                <SubmitButton outline className="float-right" disabled={isSubmitting || !dirty} color="success" type="submit">Submit</SubmitButton>
            </Form>
        </div>
    )
}

const validationSchema = Yup.object({
    name: Yup.string().required("Name is required"),
    standardchargerate: numberValidation(),
    standardcontractorchargerate: numberValidation(),
    costrate: numberValidation()
})

function numberValidation() {
    return Yup.number()
        /*.default(0)*/
        .nullable()
        .required("Standard charge rate is required")
        .min(0, "Must be positive")
        .round()
        .moreThan(-1, 'Enter valid Standard charge Rate')
        .typeError("Must be a number")
}

const ClassificationFormBasic = withFormik({
    displayName: "ClassificationFormBasic",
    enableReinitialize: true,
    validationSchema: validationSchema,
    initialValues: ({ value }) => {
        console.log({ value }, 'ttt')
        return ({
            name: value.name,
            description: value.description,
            // clientChargeRate: value.clientChargeRate
        })
    },
    handleSubmit: (values, { props, setSubmitting }) => {
        let checkRadio = values.arrRadios.find((res) => res.checked == true)
        values.ClientChargeRateOption = checkRadio.id;
        let selectedClassfication = values.classificationList.find((res) => res.name == values.classifications)
        if (selectedClassfication && selectedClassfication.classificationId)
        {
            values.ClientChargeRateAsPerClassification = selectedClassfication.classificationId;
        }
        if (values.arrRadios) {
            delete values.arrRadios;
        }
        delete values.classificationList;
        delete values.classifications
        
        props.onSubmitUpdate(values)
        setSubmitting(false)
    }
})(BaseForm)

export default ClassificationFormBasic