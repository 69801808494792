import React from "react"
import Moment from "moment"
import { AuthorizedSummaryToolbar } from "components/authentication/controls/AuthorizedControls"
import ActiveCell from "components/common/ActiveCell"

const Toolbar = AuthorizedSummaryToolbar(p => p.canWriteUsers)

const UserClassificationRow = (
    {
        effectiveDate,
        classification,
        onShowEdit,
        onShowDelete,
        disabled
    }) => (
        <tr>
            <td>{Moment(effectiveDate).format("DD/MM/YYYY")}</td>
            <ActiveCell active={!disabled}>
                {classification && classification.name}
            </ActiveCell>
            <td>
                <Toolbar disabled={disabled} onShowEdit={onShowEdit} onShowDelete={onShowDelete} />
            </td>
        </tr>
    )

export default UserClassificationRow 