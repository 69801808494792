import React from "react"
import { withFormik } from "formik"
import * as Yup from "yup"
import { SelectOption } from "@gman/gman-redux"
import RequiredStar from "components/common/RequiredStar"

import {
    Button,
    Col,
    Container,
    Form,
    FormGroup,
    Input,
    Label,
    Row
} from "reactstrap"

const BaseForm = props => {
    const {
        values,
        dirty,
        errors,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting
    } = props
    return (
        <div>
            <Form noValidate onSubmit={handleSubmit}>
                <Container>
                    <Row>
                        <Col xs={12}>
                            <FormGroup>
                                <RequiredStar />
                                <Label for="projectName">Project Name</Label>
                                <Input
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.name || ""}
                                    type="text"
                                    name="name"
                                    id="name"
                                    placeholder="Enter a project name"
                                    invalid={errors.name} />
                                {errors.name && <small className="text-danger">{errors.name}</small>}
                            </FormGroup>
                        </Col>
                        <Col xs={12}>
                            <FormGroup>
                                <RequiredStar />
                                <Label for="clientId">Client</Label>
                                <SelectOption
                                    isClearable={true}
                                    disabled={isSubmitting}
                                    value={values.clientId}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    id="clientId"
                                    invalid={errors.clientId && errors.clientId.length > 0}
                                    stateFunc={(state) => { return { ...state.clients } }}
                                    valueFunc={(record) => record.clientId}
                                    labelFunc={(record) => record.name + " (" + record.code + ")"}
                                    sortFunc={(a, b) => a.name.localeCompare(b.name)}
                                />
                                {errors.clientId && <small className="text-danger">{errors.clientId}</small>}
                            </FormGroup>
                        </Col>
                    </Row>
                    <Button outline className="float-right" disabled={isSubmitting || !dirty} color="warning" type="submit">Create Draft</Button>
                </Container>
            </Form>
        </div>
    )
}

const validationSchema = Yup.object({
    name: Yup.string()
        .required("Name is required"),
    clientId: Yup.string()
        .required("Client is required")
        .nullable()
})

const ProjectFormBasic = withFormik({
    displayName: "ProjectFormBasic",
    enableReinitialize: true,
    validationSchema: validationSchema,
    initialValues: ({ value }) => ({
        name: value.name,
        code: value.code,
        clientId: value.clientId
    }),
    handleSubmit: (values, { props, setSubmitting }) => {
        props.onSubmitUpdate(values, true)
        setSubmitting(false)
    }
})(BaseForm)

export default ProjectFormBasic