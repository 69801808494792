import ScopeConstants from "constants/ScopeConstants"
import { FormActionReducer } from "@gman/gman-redux"

export const ReportHistoricalInvoicesReducer = (
  state = {
    ...FormActionReducer.defaultPageState(),
    ...FormActionReducer.defaultSearchOptions(),
    reportName: "Historical Invoices Report",
    reportDescription: "An export of all invoices, in CSV format."
  },
  action) => {

  if (action.scope === ScopeConstants.REPORT_INVOICES_HISTORICAL) {
    return FormActionReducer.reduce(action, state)
  }

  return state
}