import React from "react"
import Moment from "moment"
import { Link } from "react-router-dom"
import ActiveCell from "components/common/ActiveCell"
import OpportunityStatusBadge from "components/common/OpportunityStatusBadge"
import { AuthorizedSummaryToolbar } from "components/authentication/controls/AuthorizedControls"

const Toolbar = AuthorizedSummaryToolbar(p => p.canWriteWorkItems)

const OpportunityRow = (
  {
    opportunityId,
    name,
    code,
    status,
    createdBy,
    dateCreated,
    onShowEdit,
    onShowDelete,
    active,
    disabled
  }) => (
    <tr>
      <ActiveCell active={active}>
        <Link to={"/opportunitySheet/" + opportunityId} onClick={onShowEdit}>
          {name}
        </Link>
      </ActiveCell>
      <ActiveCell active={active}>{code}</ActiveCell>
      <ActiveCell active={!disabled}>
        {createdBy ?
          <Link to={"/userDetails/" + createdBy.userId}>
            {createdBy && createdBy.firstName + " " + createdBy.lastName}
          </Link>
          : ""}
      </ActiveCell>
      <td>{Moment(dateCreated).format("DD/MM/YYYY")}</td>
      <td>
        <OpportunityStatusBadge value={status} />
      </td>
      <td>
        <Toolbar disabled={disabled} onShowDelete={onShowDelete} />
      </td>
    </tr>
  )

export default OpportunityRow 