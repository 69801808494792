import React, { Component } from "react"
import { AuthorizedButton } from "components/authentication/controls/AuthorizedControls"
import { FormModal, SpinnerOverlay } from "@gman/gman-react-bootstrap"
import { ModalActions } from "@gman/gman-redux"
import { Alert, FormGroup } from "reactstrap"
import FeedbackAlert from "components/common/FeedbackAlert"

const SubmitButton = AuthorizedButton(p => p.canWriteUsers)

class ResetPasswordCardBasic extends Component {

    componentWillUnmount = () => {
        this.props.feedbackEvents.onFlushFeedback()
    }

    render = () => {
        var sourceData = this.props.sourceData
        var modalEvents = this.props.modalEvents
        var feedbackEvents = this.props.feedbackEvents
        var modals = sourceData && sourceData.modals || {}

        return (
            <div>
                <SpinnerOverlay isLoading={sourceData.inProgress}>
                    <SubmitButton
                        outline
                        className="float-right"
                        disabled={sourceData.inProgress}
                        color="warning"
                        onClick={() => modalEvents.onShowModal("CONFIRM_RESET_PASSWORD")}
                    >
                        Reset Password
                    </SubmitButton>
                    <FormModal
                        isVisible={ModalActions.IsVisible(modals, "CONFIRM_RESET_PASSWORD")}
                        onHide={() => modalEvents.onHideModal("CONFIRM_RESET_PASSWORD")}
                        title={"Confirm Password Reset"}>
                        <FormGroup>
                            Confirm password reset?
                        <SubmitButton
                                className="float-right"
                                color="success"
                                onClick={() => this.props.onConfirmPasswordReset(this.props.userAuthenticationId)}
                            >
                                Confirm
                        </SubmitButton>
                        </FormGroup>
                    </FormModal>
                    <FormModal
                        isVisible={!sourceData.acknowledged}
                        onHide={this.props.onAcknowledgeReset}
                        title={"New password"}>
                        <FeedbackAlert {...feedbackEvents} feedback={sourceData.feedback} />
                        <FormGroup>
                            {sourceData.passwordClearText ?
                                <div>
                                    The user's password has been reset to the following:
                                    <hr />
                                    <div className="password-letters" >
                                        {sourceData.passwordClearText}
                                    </div>
                                    <hr />
                                    <Alert color="warning">
                                        Note: after you have made note of the password above, click the <b>Acknowledge</b> button to close this window.
                                        <br />
                                        <br />
                                        <b>After this window is closed, the password will <u>not</u> be shown again.</b>
                                        <br />
                                        <br />
                                        It is recommended that the user change their password <b>immediately</b> on next login.
                                    </Alert>
                                </div>
                                : ""
                            }
                            <SubmitButton
                                className="float-right"
                                color="success"
                                onClick={this.props.onAcknowledgeReset}
                            >
                                Acknowledge
                            </SubmitButton>
                        </FormGroup>
                    </FormModal>
                </SpinnerOverlay>
            </div>
        )
    }
}


export default ResetPasswordCardBasic
