import React from "react";
import Moment from "moment";
import { SelectOption } from "@gman/gman-redux";
import ScopeConstants from "constants/ScopeConstants";
import { withFormik } from "formik";
import * as Yup from "yup";
import InvoiceStatusConstants from "constants/InvoiceStatusConstants";
import InvoicePaymentStatusConstants from "constants/InvoiceStatusConstants"
import VariationStatusConstants from "constants/VariationStatusConstants";
import VariationTypeConstants from "constants/VariationTypeConstants";
import Icon from "components/common/Icon";
import RequiredStar from "components/common/RequiredStar";
import SelectStyles from "components/common/SelectStyles";
import FeedbackAlert from "components/common/FeedbackAlert"
import {
    AuthorizedButton,
    AuthorizedSelectOption,
    AuthorizedInputField,
    AuthorizedDropDownField
} from "components/authentication/controls/AuthorizedControls";
import {
    Col,
    Container,
    Form,
    FormGroup,
    Input,
    InputGroup,
    InputGroupText,
    InputGroupAddon,
    Label,
    Row
} from "reactstrap";
import { SpinnerOverlay } from "@gman/gman-react-bootstrap";

const DropDownField = AuthorizedDropDownField(p => p.canWriteSubContractorInvoice && p.canMarkSubContractorInvoiceAsPaid);
const InputField = AuthorizedInputField(p => p.canWriteSubContractorInvoice);
const SubmitButton = AuthorizedButton(p => p.canWriteSubContractorInvoice);
const PaidInputFiled = AuthorizedInputField(p => p.canWriteSubContractorInvoice && p.canMarkSubContractorInvoiceAsPaid);

const BaseForm = props => {
    const {
        values,
        dirty,
        errors,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
        setFieldValue,
        workItem,
        feedbackEvents,
        feedback,
        inProgress
    } = props;
    console.log('values',values)
    var isPaid = isPaidStatus(values.status);
    var invoiceStatus = isPaid;
    return (
        <div>
                <Form noValidate onSubmit={handleSubmit}>
                    {
                        feedback && feedbackEvents ? (
                            <FeedbackAlert {...feedbackEvents} feedback={feedback} />
                        ) : ("")
                    }
                    <h6>
                        <Icon folderOpen />
                    Invoice Details
                </h6>
                    <hr />
                    <Container>
                        <Row>
                            <Col xs={12} md={6}>
                                <FormGroup>
                                    <RequiredStar />
                                    <Label for="subContractor">Sub Contractor</Label>
                                    <InputField
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.subContractor || ""}
                                        type="text"
                                        name="subContractor"
                                        id="subContractor"
                                        placeholder="Enter the sub contractor"
                                        invalid={errors.subContractor}
                                        disabled={isSubmitting || invoiceStatus}
                                    />
                                    {errors.subContractor && (
                                        <small className="text-danger">{errors.subContractor}</small>
                                    )}
                                </FormGroup>
                            </Col>
                            <Col xs={12} md={6}>
                                <FormGroup>
                                    <RequiredStar />
                                    <Label for="number">Invoice Number</Label>
                                    <InputField
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.number || ""}
                                        type="text"
                                        name="number"
                                        id="number"
                                        placeholder="Enter the invoice number"
                                        invalid={errors.number}
                                        disabled={isSubmitting || invoiceStatus}
                                    />
                                    {errors.number && (
                                        <small className="text-danger">{errors.number}</small>
                                    )}
                                </FormGroup>
                            </Col>
                            <Col xs={12} md={6}>
                                <FormGroup>
                                    <RequiredStar />
                                    <Label for="invoiceDate">Invoice Date</Label>
                                    <InputField
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={
                                            values.invoiceDate
                                                ? Moment(values.invoiceDate).format("YYYY-MM-DD")
                                                : ""
                                        }
                                        type="date"
                                        name="invoiceDate"
                                        id="invoiceDate"
                                        placeholder="Invoice Date"
                                        invalid={errors.invoiceDate}
                                        disabled={isSubmitting || invoiceStatus}
                                    />
                                    {errors.invoiceDate && (
                                        <small className="text-danger">{errors.invoiceDate}</small>
                                    )}
                                </FormGroup>
                            </Col>
                            <Col xs={12} md={6}>
                                <FormGroup>
                                    <RequiredStar />
                                    <Label for="dueDate">Due Date</Label>
                                    <InputField
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={
                                            values.dueDate
                                                ? Moment(values.dueDate).format("YYYY-MM-DD")
                                                : ""
                                        }
                                        type="date"
                                        name="dueDate"
                                        id="dueDate"
                                        placeholder="Due Date"
                                        invalid={errors.dueDate}
                                        disabled={isSubmitting || invoiceStatus}
                                    />
                                    {errors.dueDate && (
                                        <small className="text-danger">{errors.dueDate}</small>
                                    )}
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12} md={6}>
                                <FormGroup>
                                    <RequiredStar />
                                    <Label for="invoiceAmount">Amount (excl. GST)</Label>
                                    <InputGroup>
                                        <InputGroupAddon addonType="prepend">
                                            <InputGroupText>$</InputGroupText>
                                        </InputGroupAddon>
                                        <InputField
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.invoiceAmount || ""}
                                            type="text"
                                            name="invoiceAmount"
                                            id="invoiceAmount"
                                            placeholder="Enter the invoice amount"
                                            invalid={errors.invoiceAmount}
                                            disabled={isSubmitting || invoiceStatus}
                                        />
                                    </InputGroup>
                                    {errors.invoiceAmount && (
                                        <small className="text-danger">{errors.invoiceAmount}</small>
                                    )}
                                </FormGroup>
                            </Col>
                            {isPaid ? (
                                <Col xs={12} md={6}>
                                    <FormGroup>
                                        <Label for="paymentDate">Date Paid</Label>
                                        <PaidInputFiled
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={
                                                values.paymentDate
                                                    ? Moment(values.paymentDate).format("YYYY-MM-DD")
                                                    : ""
                                            }
                                            type="date"
                                            name="paymentDate"
                                            id="paymentDate"
                                            placeholder="Payment Date"
                                            invalid={errors.paymentDate}
                                            disabled={isSubmitting}
                                        />
                                        {errors.paymentDate && (
                                            <small className="text-danger">{errors.paymentDate}</small>
                                        )}
                                    </FormGroup>
                                </Col>
                            ) : 
                            <Col xs={12} md={6}>
                                    <FormGroup>
                                        <Label for="description">Description</Label>
                                        <InputField
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.description || ""}
                                            type="text"
                                            name="description"
                                            id="description"
                                            placeholder="Enter description about the invoice"
                                            style={{ resize: "none" }}
                                            rows={3}
                                            invalid={errors.description} />
                                        {errors.description && <small className="text-danger">{errors.description}</small>}
                                    </FormGroup>
                                </Col>
                            }
                    </Row>
                    {
                        isPaid ?
                            <Row>
                                <Col xs={12}>
                                    <FormGroup>
                                        <Label for="description">Description</Label>
                                        <InputField
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.description || ""}
                                            type="text"
                                            name="description"
                                            id="description"
                                            placeholder="Enter description about the invoice"
                                            style={{ resize: "none" }}
                                            rows={3}
                                            invalid={errors.description} />
                                        {errors.description && <small className="text-danger">{errors.description}</small>}
                                    </FormGroup>
                                </Col>
                            </Row> : ""
                    }
                    <Row>
                        <Col xs={12}>
                            <FormGroup>
                                <RequiredStar />
                                <Label for="workItem">Project</Label>
                                <SelectOption
                                    styles={SelectStyles.colourStyles()}
                                    isClearable={true}
                                    disabled={isSubmitting || invoiceStatus}
                                    value={values.workItem}
                                    onChange={e => {
                                        var workItemId =
                                            e.target.value && e.target.value.workItemId;
                                        if (
                                            e.target.value &&
                                            e.target.value.type == ScopeConstants.PROJECT_ANY
                                        ) {
                                            setFieldValue("variationId", null);
                                            setFieldValue("projectId", workItemId);
                                        } else if (
                                            e.target.value &&
                                            e.target.value.type == ScopeConstants.PROPOSAL_ANY
                                        ) {
                                            setFieldValue("variationId", null);
                                            setFieldValue("costCodeId", "");
                                            setFieldValue("projectId", null);
                                        } else if (
                                            e.target.value &&
                                            e.target.value.type == ScopeConstants.LEAVE
                                        ) {
                                            setFieldValue("variationId", null);
                                            setFieldValue("costCodeId", "");
                                            setFieldValue("projectId", null);
                                        } else {
                                            setFieldValue("variationId", null);
                                            setFieldValue("costCodeId", "");
                                            setFieldValue("projectId", null);
                                        }
                                        handleChange(e);
                                    }}
                                    onBlur={handleBlur}
                                    id="workItem"
                                    invalid={errors.workItem && errors.workItem.length > 0}
                                    filterFunc={record =>
                                        record.type !== ScopeConstants.LEAVE ||
                                        (record.type === ScopeConstants.LEAVE &&
                                            (record.code === "MISC" ||
                                                record.code === "R&D ACTIVITY"))
                                    }
                                    stateFunc={state => {
                                        return { ...state.workItems };
                                    }}
                                    valueFunc={record => record}
                                    labelFunc={record => record.name + " (" + record.code + ")"}
                                    sortFunc={(a, b) => a.name.localeCompare(b.name)}
                                    matchFunc={record => record.workItemId}
                                />
                                {errors.workItem && errors.workItem.workItemId && (
                                    <small className="text-danger">
                                        {errors.workItem.workItemId}
                                    </small>
                                )}
                            </FormGroup>
                        </Col>
                        <Col xs={12}>
                            <FormGroup>
                                <Label for="costCode">Cost Code</Label>
                                <SelectOption
                                    isClearable={true}
                                    disableIfEmpty
                                    disabled={!values.projectId || invoiceStatus}
                                    value={values.costCode}
                                    onChange={e => {
                                        var costCodeId =
                                            e.target.value && e.target.value.costCodeId;
                                        if (e.target.value) {
                                            setFieldValue("costCodeId", costCodeId);
                                        } else {
                                            setFieldValue("costCodeId", "");
                                        }
                                        handleChange(e);
                                    }}
                                    onBlur={handleBlur}
                                    id="costCode"
                                    invalid={errors.costCode && errors.costCode.length > 0}
                                    stateFunc={state => {
                                        return { ...state.costCodes };
                                    }}
                                    valueFunc={record => {
                                        return ({
                                            costCodeId: record.costCodeId,
                                            code: record.code,
                                            description: record.description
                                        })
                                    } }
                                    labelFunc={record =>
                                        {
                        
                                            if ((record.code+'').indexOf('4') > -1) {
                                                return record.code + " (" + record.description + ")";
                                            }
                                            
                                        }
                                    }
                                    filterFunc={record =>
                                        {
                                            if ((record.code+'').indexOf('4') > -1) {
                                            return record.disabled === false &&
                                                record.costCodeGroupId ===
                                                (values.workItem && values.workItem.costCodeGroupId);
                                            }
                                        }
                                    }
                                    sortFunc={(a, b) => a.code.localeCompare(b.code)}
                                    matchFunc={record => record.costCodeId}
                                />
                                {errors.variation && (
                                    <small className="text-danger">{errors.variation}</small>
                                )}
                            </FormGroup>
                        </Col>
                        <Col xs={12}>
                            <FormGroup>
                                <Label for="workItem.variation">Variation Code</Label>
                                <SelectOption
                                    isClearable={true}
                                    disableIfEmpty
                                    disabled={isSubmitting || invoiceStatus}
                                    value={values.variation}
                                    onChange={e => {
                                        var variationId =
                                            e.target.value && e.target.value.variationId;
                                        setFieldValue("variationId", variationId);
                                        handleChange(e);
                                    }}
                                    onBlur={handleBlur}
                                    id="variation"
                                    invalid={errors.variation && errors.variation.length > 0}
                                    stateFunc={state => {
                                        return { ...state.variations };
                                    }}
                                    valueFunc={record => ({
                                        variationId: record.variationId,
                                        number: record.number,
                                        description: record.description
                                    })}
                                    labelFunc={record =>
                                        (record.type === VariationTypeConstants.VARIATION
                                            ? "Variation"
                                            : "") +
                                        (record.type === VariationTypeConstants.RISK
                                            ? "Risk"
                                            : "") +
                                        " " +
                                        record.number +
                                        " (" +
                                        record.description +
                                        ")"
                                    }
                                    filterFunc={record =>
                                        record.projectId ===
                                        (values.workItem && values.workItem.workItemId) &&
                                        record.status !== VariationStatusConstants.DRAFT
                                    }
                                    sortFunc={(a, b) => a.number.localeCompare(b.number)}
                                    matchFunc={record => record.variationId}
                                />
                                {errors.variation && (
                                    <small className="text-danger">{errors.variation}</small>
                                )}
                            </FormGroup>
                        </Col>
                            
                </Row>
                    
                    </Container>
                    <h6>
                        <Icon workflow />
                    Workflow
                </h6>
                    <hr />
                    <Container>
                        <Row>
                            <Col xs={12} md={6}>
                                <FormGroup>
                                    <Label for="status">Invoice Status</Label>
                                    <DropDownField
                                        onChange={e => {
                                            handleChange(e);
                                            setFieldValue("isPaid", isPaidStatus(e.target.value));
                                            setFieldValue("paymentDate", values.lastPaymentDate || "");
                                        }}
                                        onBlur={handleBlur}
                                        type="select"
                                        name="status"
                                        value={values.status || ""}
                                        invalid={errors.status}
                                        id="status">
                                        <option value={0}>Unpaid</option>
                                        <option value={1}>Paid</option>
                                    </DropDownField>
                                </FormGroup>
                            </Col>
                            <Col xs={12} md={6}>
                            <FormGroup>
                                <RequiredStar />
                                <Label for="markup">Subcontractor Mark up (%)</Label>
                                <InputField
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.markup}
                                    type="number"
                                    name="markup"
                                    id="markup"
                                    placeholder="Enter a subcontractor mark up"
                                    invalid={errors.markup} />
                                {errors.markup && <small className="text-danger">{errors.markup}</small>}
                            </FormGroup>
                        </Col>
                        </Row>
                    </Container>
                    <Input
                        onChange={handleChange}
                        value={isPaid}
                        type="hidden"
                        name="isPaid"
                        id="isPaid"
                    />
                    <SubmitButton
                        outline
                        className="float-right"
                        disabled={isSubmitting || !dirty}
                        color="success"
                        type="submit"
                    >
                        Submit
                    </SubmitButton>
                </Form>

        </div>
    );
};

function isPaidStatus(status) {
    var paidStatus =
        (status || "").toString() === InvoiceStatusConstants.PAID.toString();
    return paidStatus;
}

const validationSchema = Yup.object({
    number: Yup.string().required("Number is required"),
    projectId: Yup.string()
        .required("Project is required")
        .nullable(),
    subContractor: Yup.string()
        .required("Sub Contractor is required")
        .nullable(),
    dueDate: Yup.date()
        .required("Due date is required")
        .typeError("Must be a date"),
    invoiceDate: Yup.date()
        .required("Invoice date is required")
        .typeError("Must be a date"),
    invoiceAmount: numberValidation(),
    paymentDate: Yup.date().when("isPaid", {
        is: true,
        then: Yup.date()
            .required("Payment date is required")
            .typeError("Must be a date")
            .min(Yup.ref("invoiceDate"), "Payment date cannot precede Invoice date")
            .max(Moment(), "Payment date cannot be a future date")
    }),
    description: Yup.string()
        .required("Description is required")
        .nullable(),
    markup: markupValidation(),
});

function markupValidation(){
    return Yup.number()
    .default(0)
    .nullable()
    .required("Value is required")
    .typeError("Must be a number")
    .min(0, "Must be positive")
}

function requiredNumberValidation() {
    return numberValidation()
        .required("Value is required")
}

function numberValidation() {
    return Yup.number()
        .default(0)
        .nullable()
        .required("Value is required")
        .round()
        .positive("Must be positive")
        .typeError("Must be a number");
}

const SubContractorInvoiceFormExtended = withFormik({
    displayName: "SubContractorInvoiceFormExtended",
    enableReinitialize: true,
    validationSchema: validationSchema,
    mapPropsToValues: ({ ...props }) => {
        console.log(props ,'props')
        return ({
            ...props,
            invoiceDate: props.invoiceDate || "",
            paymentDate: props.paymentDate || "",
            isPaid: isPaidStatus(props.status),
            projectId: props.workItem && props.workItem.workItemId ? props.workItem.workItemId : "",
        });
    },
    handleSubmit: (values, { props, setSubmitting }) => {
        props.onSubmitUpdate(values);

        setSubmitting(false);
    }
})(BaseForm);

export default SubContractorInvoiceFormExtended;


