import { combineReducers } from "redux"
import { AuthenticationReducer } from "components/authentication/redux/AuthenticationReducer"
import { ChargeRatesReducer } from "components/chargeRates/redux/ChargeRatesReducer"
import { ChargeRateListReducer } from "components/chargeRates/redux/ChargeRateListReducer"
import { ClassificationsReducer } from "components/classifications/redux/ClassificationsReducer"
import { ClientsReducer } from "components/clients/redux/ClientsReducer"
import { CostRateReducer } from "components/costRates/redux/CostRateReducer"
import { ClientInvoicesReducer } from "components/clientInvoices/redux/ClientInvoicesReducer"
import { SubContractorInvoicesReducer } from "components/subContractorInvoices/redux/SubContractorInvoicesReducer"
import { SubContractorInvoiceEditListReducer } from "components/subContractorInvoices/redux/SubContractorInvoiceEditListReducer"
import { ContractTypeReducer } from "components/contractTypes/redux/ContractTypeReducer"
import { CostCodesReducer } from "components/costCodes/redux/CostCodesReducer"
import { CostCodeGroupsReducer } from "components/costCodeGroups/redux/CostCodeGroupsReducer"
import { CostCodeGroupsAllReducer } from "components/costCodeGroups/redux/CostCodeGroupsAllReducer"
import { DashboardReducer } from "components/dashboards/redux/DashboardReducer"
import { ExpenseCalculationReducer } from "components/expenses/redux/ExpenseCalculationReducer"
import { ExpensesMineReducer } from "components/expenses/redux/ExpensesMineReducer"
import { ExpensesMineByDueDateReducer } from "components/expenses/redux/ExpensesMineByDueDateReducer"
import { ExpensesTeamReducer } from "components/expenses/redux/ExpensesTeamReducer"
import { ExpensesTeamByDueDateReducer } from "components/expenses/redux/ExpensesTeamByDueDateReducer"
import { ExpensesAnyReducer } from "components/expenses/redux/ExpensesAnyReducer"
import { ExpensesAnyByDueDateReducer } from "components/expenses/redux/ExpensesAnyByDueDateReducer"
import { ExpenseTypesReducer } from "components/expenseTypes/redux/ExpenseTypesReducer"
import { ExpenseEditListReducer } from "components/expenses/redux/ExpenseEditListReducer"
import { ExpenseMonthlyDueDatesReducer } from "components/expenseMonthlyDueDates/redux/ExpenseMonthlyDueDatesReducer"
import { LeaveReducer } from "components/leave/redux/LeaveReducer"
import { LastInvoicePaymentsReducer } from "components/lastInvoicePayments/redux/LastInvoicePaymentsReducer"
import { ManagersReducer } from "components/users/redux/ManagersReducer"
import { OutstandingTimesheetsMineReducer } from "components/outstandingTimesheets/redux/OutstandingTimesheetsMineReducer"
import { OutstandingTimesheetsTeamReducer } from "components/outstandingTimesheets/redux/OutstandingTimesheetsTeamReducer"
import { OutstandingTimesheetsAllReducer } from "components/outstandingTimesheets/redux/OutstandingTimesheetsAllReducer"
import { OfficesReducer } from "components/offices/redux/OfficesReducer"
import { OpportunitiesMineReducer } from "components/opportunities/redux/OpportunitiesMineReducer"
import { OpportunitiesAnyReducer } from "components/opportunities/redux/OpportunitiesAnyReducer"
import { ProjectsMineReducer } from "components/projects/redux/ProjectsMineReducer"
import { ProjectsAnyReducer } from "components/projects/redux/ProjectsAnyReducer"
import { ProposalsMineReducer } from "components/proposals/redux/ProposalsMineReducer"
import { ProposalsAnyReducer } from "components/proposals/redux/ProposalsAnyReducer"
import { ReportTimesheetHoursReducer } from "components/reports/redux/ReportTimesheetHoursReducer"
import { ReportSubContractorInvoicesReducer } from "components/reports/redux/ReportSubContractorInvoicesReducer"
import { ReportUnpaidInvoicesReducer } from "components/reports/redux/ReportUnpaidInvoicesReducer"
import { ReportHistoricalInvoicesReducer } from "components/reports/redux/ReportHistoricalInvoicesReducer"
import { ReportIndividualInvoiceNumbersReducer } from "components/reports/redux/ReportIndividualInvoiceNumbersReducer"
import { ReportChargeRatesReducer } from "components/reports/redux/ReportChargeRatesReducer"
import { ReportProjectFinancialsReducer } from "components/reports/redux/ReportProjectFinancialsReducer"
import { ResetPasswordsReducer } from "components/resetPasswords/redux/ResetPasswordsReducer"
import { StaticProjectsReducer } from "components/static/redux/StaticProjectsReducer"
import { SystemsReducer } from "components/system/redux/SystemsReducer"
import { RoleTypesReducer } from "components/roleTypes/redux/RoleTypesReducer"
import { TimesheetsMineReducer } from "components/timesheets/redux/TimesheetsMineReducer"
import { TimesheetsTeamReducer } from "components/timesheets/redux/TimesheetsTeamReducer"
import { TimesheetsAnyReducer } from "components/timesheets/redux/TimesheetsAnyReducer"
import { TimesheetEntriesReducer } from "components/timesheetEntries/redux/TimesheetEntriesReducer"
import { TimesheetEntryEditListReducer } from "components/timesheetEntries/redux/TimesheetEntryEditListReducer";
import { TimesheetEntryHistoryReducer } from "components/timesheetEntriesHistory/redux/TimesheetEntryHistoryReducer"
import { UsersReducer } from "components/users/redux/UsersReducer"
import { UserAuthenticationsReducer } from "components/userAuthentications/redux/UserAuthenticationsReducer"
import { UserClassificationsReducer } from "components/userClassifications/redux/UserClassificationsReducer"
import { VariationsReducer } from "components/variations/redux/VariationsReducer"
import { WeekEndingReducer } from "components/weeksEnding/redux/WeekEndingReducer"
import { WorkItemsReducer } from "components/workItems/redux/WorkItemsReducer"
import { WorkbenchExportsReducer } from "components/workbenchExports/redux/WorkbenchExportsReducer"
import { EditDataReducer } from "components/editData/redux/EditDataReducer"
import { InvoiceWeekDayReducer } from "components/invoiceWeekDays/InvoiceWeekDayReducer";
import { InvoiceDayReducer } from "components/invoiceDays/InvoiceDayReducer";
import { ProjectFinancialReducer } from "components/projectsFinancial/redux/ProjectFinancialReducer";
import { ProjectSummaryDirectorReducer } from "components/projectSummary/redux/ProjectSummaryDirectorReducer";
import { ProjectSummaryManagerReducer } from "components/projectSummary/redux/ProjectSummaryManagerReducer";
import { ClientChargeRateTypeReducer } from "components/clientChargeRateType/ClientChargeRateTypeReducer";
import { InvoiceBeforeAfterReducer } from "../components/invoiceBeforeAfter/InvoiceBeforeAfterReducer"

export default combineReducers({
    authentication: AuthenticationReducer,
    chargeRates: ChargeRatesReducer,
    chargeRateList: ChargeRateListReducer,
    classifications: ClassificationsReducer,
    costRate: CostRateReducer,
    clients: ClientsReducer,
    clientInvoices: ClientInvoicesReducer,
    subContractorInvoices: SubContractorInvoicesReducer,
    subContractorInvoicesEditList : SubContractorInvoiceEditListReducer,
    contractTypes: ContractTypeReducer,
    costCodes: CostCodesReducer,
    costCodeGroups: CostCodeGroupsReducer,
    costCodeGroupsAll: CostCodeGroupsAllReducer,
    dashboards: DashboardReducer,
    expenseCalculations: ExpenseCalculationReducer,
    expensesAny: ExpensesAnyReducer,
    expensesAnyByDueDate: ExpensesAnyByDueDateReducer,
    expensesTeam: ExpensesTeamReducer,
    expensesTeamByDueDate: ExpensesTeamByDueDateReducer,
    expensesMine: ExpensesMineReducer,
    expensesMineByDueDate: ExpensesMineByDueDateReducer,
    expenseTypes: ExpenseTypesReducer,
    expenseDueDates: ExpenseMonthlyDueDatesReducer,
    expenseEditList: ExpenseEditListReducer,
    leave: LeaveReducer,
    lastInvoicePayments: LastInvoicePaymentsReducer,
    managers: ManagersReducer,
    offices: OfficesReducer,
    opportunitiesAny: OpportunitiesAnyReducer,
    opportunitiesMine: OpportunitiesMineReducer,
    projectsAny: ProjectsAnyReducer,
    projectsMine: ProjectsMineReducer,
    proposalsAny: ProposalsAnyReducer,
    proposalsMine: ProposalsMineReducer,
    reportProjectFinancials:ReportProjectFinancialsReducer,
    reportTimesheetHours: ReportTimesheetHoursReducer,
    subContractorInvoicesReport: ReportSubContractorInvoicesReducer,
    reportUnpaidInvoices: ReportUnpaidInvoicesReducer,
    reportHistoricalInvoices: ReportHistoricalInvoicesReducer,
    reportIndividualInvoiceNumbersReducer: ReportIndividualInvoiceNumbersReducer,
    reportChargeRates: ReportChargeRatesReducer,
    resetPasswords: ResetPasswordsReducer,
    managers: ManagersReducer,
    outstandingTimesheetsMine: OutstandingTimesheetsMineReducer,
    outstandingTimesheetsTeam: OutstandingTimesheetsTeamReducer,
    outstandingTimesheetsAll: OutstandingTimesheetsAllReducer,
    staticProjects: StaticProjectsReducer,
    systemVariables: SystemsReducer,
    roleTypes: RoleTypesReducer,
    timesheetsMine: TimesheetsMineReducer,
    timesheetsTeam: TimesheetsTeamReducer,
    timesheetsAny: TimesheetsAnyReducer,
    timesheetEntries: TimesheetEntriesReducer,
    timesheetEntryEditList: TimesheetEntryEditListReducer,
    timesheetEntriesHistory: TimesheetEntryHistoryReducer,
    users: UsersReducer,
    userAuthentications: UserAuthenticationsReducer,
    userClassifications: UserClassificationsReducer,
    variations: VariationsReducer,
    weeksEnding: WeekEndingReducer,
    workItems: WorkItemsReducer,
    workbenchExports: WorkbenchExportsReducer,
    editData: EditDataReducer,
    invoiceWeekDayReducer: InvoiceWeekDayReducer,
    invoiceDayReducer: InvoiceDayReducer,
    invoiceBeforeAfterReducer: InvoiceBeforeAfterReducer,
    projectFinancial: ProjectFinancialReducer,
    projectSummaryManagerReducer: ProjectSummaryManagerReducer,
    projectSummaryDirectorReducer: ProjectSummaryDirectorReducer,
    clientChargeRateType: ClientChargeRateTypeReducer
})