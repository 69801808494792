import React, { Component } from "react"
import { Card, CardFooter } from "reactstrap"
import Moment from "moment"

class FooterBasic extends Component {

    render() {
        var sourceData = this.props.sourceData
        var environment = sourceData.environment
        return (
            <Card>
                <CardFooter className="page-footer">
                    {environment.stage ?
                        <small className="float-right">
                            {environment.stage && environment.stage.substring(0, 1)}
                            {environment.version}
                        </small>
                        :
                        <small className="float-right">
                            Copyright {Moment().format("YYYY")} © CMP Consulting Group. All Rights Reserved.<br />
                            <a href="http://www.gman.com.au">G-MAN Solutions</a><br />
                        </small>
                    }
                </CardFooter>
            </Card>
        )
    }
}

export default FooterBasic