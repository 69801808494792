import React, { Component } from "react"
import { Route } from "react-router"
import { FullScreenLayout } from "./components/FullScreenLayout"
import { EditDataPage } from "components/editData/page/EditDataPages"
import { Home } from "./components/Home"
import { LoginPage } from "components/authentication/page/AuthenticationPages"
import {
    RestrictedRoute,
    UserIsAuthenticated,
    UserIsNotAuthenticated
} from "components/authentication/redux/AuthorizationWrappers"
import { ProjectMinePage, ProjectAnyPage, ProjectEditPage, ProjectReadPage } from "components/projects/page/ProjectPages"

export default class FullScreenApp extends Component {
    displayName = FullScreenApp.name

    render() {
        return (
            <FullScreenLayout location={this.props.location}>
                <Route path="/projectDetails/:projectId" component={RestrictedRoute(p => p.canWriteWorkItems)(ProjectEditPage)} />
                <Route path="/projectSheet/:projectId" component={RestrictedRoute(p => p.canReadWorkItems)(ProjectReadPage)} />
                <Route path="/editData" component={RestrictedRoute(p => p.canReadSystem)(EditDataPage)} />
            </FullScreenLayout>
        )
    }
}
