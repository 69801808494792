import React, { Component } from "react";
import {
    Card,
    CardBody,
    CardHeader,
    CardTitle
} from "reactstrap";
import { EditDataContainer } from "components/editData/redux/EditDataContainers";

class EditDataCard extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <Card>
                <CardHeader>
                    <CardTitle>Edit Data</CardTitle>
                </CardHeader>
                <CardBody>
                    <EditDataContainer showFilter={true} />
                </CardBody>
            </Card>    
        )
    }
}

export default EditDataCard;