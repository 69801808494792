import ScopeConstants from "constants/ScopeConstants"

var SelectStyles = {

    colourStyles() {
        return {
            control: styles => ({ ...styles, backgroundColor: "white" }),
            option: (styles, { data, isDisabled, isFocused, isSelected }) => {
                return {
                    ...styles,
                    ...SelectStyles.dot(
                        data.value.type === ScopeConstants.PROJECT_ANY ? "Project" : (data.value.type === ScopeConstants.PROPOSAL_ANY ? "Proposal" : "Miscellaneous"),
                        data.value.type === ScopeConstants.PROJECT_ANY ? "#444" : (data.value.type === ScopeConstants.PROPOSAL_ANY ? "#aaa" : "#b58287")
                    )
                };
            },
            singleValue: (styles, { data }) => (
                {
                    ...styles,
                    ...SelectStyles.dot(
                        "",
                        data.value.type === ScopeConstants.PROJECT_ANY ? "#444" : (data.value.type === ScopeConstants.PROPOSAL_ANY ? "#aaa" : "#b58287")
                    )
                }
            )
        }
    },

    errorStyles() {
        return {
            control: styles => (
                { 
                ...styles, 
                borderWidth: "1px",
                borderStyle: "solid",
                borderColor: "#dc3545"
             })
        }
    },

    dot(text, color) {
        return {
            alignItems: "center",
            display: "flex",

            ":before": {
                backgroundColor: color,
                borderRadius: 10,
                content: '" "',
                display: "block",
                marginRight: 8,
                height: 10,
                width: 10,
            },
            ":after": {
                position: "absolute",
                right: "2px",
                fontSize: "10px",
                content: "\"" + text + "\"",
                display: "block"
            }
        }
    }
}

export default SelectStyles