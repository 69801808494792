import React from "react"
import { withFormik } from "formik"
import * as Yup from "yup"
import RequiredStar from "components/common/RequiredStar"
import { FormModal } from "@gman/gman-react-bootstrap"
import { ModalActions } from "@gman/gman-redux"
import Moment from "moment";
import Icon from "components/common/Icon";

import {
    Button,
    Col,
    Container,
    Form,
    FormGroup,
    Input,
    Label,
    Row
} from "reactstrap"

const BaseForm = props => {
    const {
        onShowModal,
        onHideModal,
        modals,
        values,
        principal,
        dirty,
        errors,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
        setFieldValue,
        currentRatesType
    } = props
    return (
        <div>
            <Form noValidate onSubmit={handleSubmit}>
                <Container>
                    <Row>
                        <Col xs={12} md={6}>
                            <FormGroup>
                                <RequiredStar />
                                <Label for="name">Charge Rate List Name</Label>
                                <Input
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    type="text"
                                    name="name"
                                    id="name"
                                    value={values.name}
                                    invalid={errors.name}
                                />
                                {errors.name && <small className="text-danger">{errors.name}</small>}
                            </FormGroup>
                        </Col>
                        <Col xs={12} md={6}>
                            <FormGroup>
                                <RequiredStar />
                                <Label for="effectiveDate">Effective Date</Label>
                                <Input
                                    onChange={
                                        e => {
                                            let name;
                                            if (currentRatesType != "STANDARD" && currentRatesType != "COST" && currentRatesType != "STANDARDCONTRACTOR") {
                                                name = principal.code + '-from-' + Moment(e.target.value).format("YY.MM.DD");
                                            } else {
                                                name = currentRatesType.toUpperCase() + '-from-' + Moment(e.target.value).format("YY.MM.DD");
                                            }
                                            setFieldValue('name', name);
                                            handleChange(e);
                                        }
                                    }
                                    onBlur={handleBlur}
                                    type="date"
                                    name="effectiveDate"
                                    id="effectiveDate"
                                    value={values.effectiveDate}
                                    invalid={errors.effectiveDate}
                                />
                                {errors.effectiveDate && <small className="text-danger">{errors.effectiveDate}</small>}
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12} md={12}>
                            <FormGroup>
                                <Label for="comment">Comment</Label>
                                <Input
                                    onChange={
                                        (e) => {
                                             console.log("textareaComment",e.target.value.substring(0, 1));
                                             if(e.target.value.substring(0, 1) == "+" || e.target.value.substring(0, 1) == "-" || e.target.value.substring(0, 1) == "="){
                                                alert("“Do not use “-” “+” or “=” as your first character”");
                                             }
                                             else{
                                                 handleChange(e);
                                             }
                                        }
                                    }
                                    onBlur={handleBlur}
                                    type="textarea"
                                    name="comment"
                                    id="comment"
                                    value={values.comment || ""}
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                    <h6>
                        <Icon chargeRate />
                        Charge Rate
                    </h6>
                    <hr />
                    {
                        values
                            .chargeRates
                            .sort((a, b) => a.classification.name.localeCompare(b.classification.name))
                            .map((chargeRate, index) => (
                                <Row key={index}>
                                    <Col xs={12} md={6}>
                                        <FormGroup>
                                            <RequiredStar />
                                            <Label for="name">
                                                {chargeRate.classification.name}
                                            </Label>
                                        </FormGroup>
                                    </Col>
                                    <Col xs={12} md={6}>
                                        <FormGroup>
                                            <Input
                                                size="sm"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={chargeRate.rate}
                                                type="text"
                                                name={"chargeRates[" + index + "].rate"}
                                                id={"chargeRates[" + index + "].rate"}
                                                placeholder="Enter the new rate"
                                                invalid={errors.chargeRates && errors.chargeRates.length >= index && errors.chargeRates[index] && errors.chargeRates[index].rate}
                                            />
                                            {
                                                errors.chargeRates && errors.chargeRates.length >= index && errors.chargeRates[index] && errors.chargeRates[index].rate &&
                                                <small className="text-danger">
                                                    {errors.chargeRates && errors.chargeRates.length >= index && errors.chargeRates[index] && errors.chargeRates[index].rate}
                                                </small>
                                            }
                                        </FormGroup>
                                    </Col>
                                </Row>

                            ))
                    }
                </Container>
                <Button
                    outline
                    className="float-right"
                    disabled={isSubmitting || !dirty}
                    color="success"
                    onClick={() => onShowModal("CONFIRM_CHARGE_RATES_PUBLISH")}
                >
                    Publish
                    </Button>
                <FormModal
                    isVisible={ModalActions.IsVisible(modals, "CONFIRM_CHARGE_RATES_PUBLISH")}
                    onHide={() => onHideModal("CONFIRM_CHARGE_RATES_PUBLISH")}
                    title={"Confirm Publish of New Rates?"}>
                    <FormGroup>
                        <p>WARNING: All projects using this set of rates will use the updated set of rates from the date you have set. <b>If the date is in the past, this will overwrite the previously used charge rates.</b> Don't do this unless you know what you are doing.</p>
                        <Button
                            className="float-right"
                            color="success"
                            onClick={handleSubmit}
                        >
                            Confirm
                    </Button>
                    </FormGroup>
                </FormModal>
            </Form>
        </div>
    )
}

const validationSchema = Yup.object({
    name: Yup.string().required("Rate list name is required"),
    chargeRates: Yup.array().of(
        Yup.object()
            .shape({
                rate: numberValidation()
            }))
})

function numberValidation() {
    return Yup.number()
        .default(0)
        .nullable()
        .required("Value is required")
        .round()
        .moreThan(-1, 'Enter valid Amount')
        .typeError("Must be a number")
}

const ChargeRatesDraftForm = withFormik({
    displayName: "ChargeRatesDraftForm",
    enableReinitialize: true,
    validationSchema: validationSchema,
    initialValues: ({ value }) => ({
        name: value.name,
        effectiveDate: value.effectiveDate,
        comment: value.comment,
        description: value.description,
        chargeRates: value.chargeRates
    }),
    handleSubmit: (values, { props, setSubmitting }) => {
        props.onSubmitUpdate(values)
        setSubmitting(false)
    }
})(BaseForm)

export default ChargeRatesDraftForm