import React from "react"
import PropTypes from "prop-types"
import Moment from "moment"
import ActiveCell from "components/common/ActiveCell"
import { Link } from "react-router-dom"
import Currency from "components/common/Currency"
import { Button, ButtonGroup } from "reactstrap"
import { MdModeEdit, MdDelete } from "react-icons/md"
import { FaDollarSign, FaThumbsUp, FaThumbsDown, FaDownload } from "react-icons/fa"
import ExpenseStatusConstants from "constants/ExpenseStatusConstants"

const ExpenseDueDateRow = (
    {
        index,
        dueDate,
        totalMileageApproved,
        totalMileagePaid,
        totalExpenseApproved,
        totalExpensePaid,
        totalMileageSubmitted,
        totalExpenseSubmitted,
        disabled,
        status,
        workflowPermissions,
        onConfirmApproveAllByDueDate,
        onConfirmPaiedAllByDueDate,
        onExport,
        suffix
    }) => {
    return (
        <tr>
            <ActiveCell active={!disabled}>
                {
                    dueDate ?
                        <Link to={"/expense/" + suffix + "/due/" + Moment(dueDate).format("DDMMYYYY") }> { Moment(dueDate).format("DD/MM/YYYY") } </Link> : ""
                }
            </ActiveCell>
            <td className="text-center"> {totalMileageSubmitted == 0 ? "-" : Currency(totalMileageSubmitted)} </td>
            <td className="text-center"> {totalMileageApproved == 0 ? "-" : Currency(totalMileageApproved)} </td>
            <td className="text-center"> {totalMileagePaid == 0 ? "-" : Currency(totalMileagePaid)} </td>
            <td className="text-center"> {totalExpenseSubmitted == 0 ? "-" : Currency(totalExpenseSubmitted)} </td>
            <td className="text-center"> {totalExpenseApproved == 0 ? "-" : Currency(totalExpenseApproved)} </td>
            <td className="text-center"> {totalExpensePaid == 0 ? "-" : Currency(totalExpensePaid)} </td>
            <td style={{width: '300px'}}>
                {
                    <div>
                        {
                            workflowPermissions ?
                                WorkflowToolbar(
                                    disabled,
                                    status === ExpenseStatusConstants.SUBMITTED,
                                    status === ExpenseStatusConstants.APPROVED,
                                    workflowPermissions,
                                    onConfirmApproveAllByDueDate,
                                    onConfirmPaiedAllByDueDate,
                                    onExport)
                                : ""
                        }
                    </div>
                }
            </td>
        </tr>
    )
}


function WorkflowToolbar(
    disabled,
    isSubmitted,
    isApproved,
    workflowPermissions,
    onConfirmApproveAllByDueDate,
    onConfirmPaiedAllByDueDate,
    onExport) {
    return (
        <ButtonGroup size="sm" className="float-right">
            {
                workflowPermissions.canApprove && onExport ?
                    <Button outline size="sm" color="info" disabled={disabled} onClick={onExport}>
                        <FaDownload /> Download
                    </Button>
                    : ""
            }
            {
                workflowPermissions.canMarkPaid && onConfirmPaiedAllByDueDate ?
                    <Button outline size="sm" color="success" disabled={disabled} onClick={onConfirmPaiedAllByDueDate}>
                        <FaDollarSign /> Paid
                    </Button>
                    : ""
            }
           
        </ButtonGroup>
    )
}

export default ExpenseDueDateRow 