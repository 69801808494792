import { connect } from "react-redux";
import SubContractorInvoiceList from "components/subContractorInvoices/layout/SubContractorInvoiceList";
import SubContractorInvoiceCardExtended from "components/subContractorInvoices/layout/SubContractorInvoiceCardExtended";
import { CostCodeScope } from "components/costCodes/redux/CostCodeScope";
import { ProjectAnyScope } from "components/projects/redux/ProjectAnyScope";
import { VariationScope } from "components/variations/redux/VariationScope";
import { StaticProjectScope } from "components/static/redux/StaticProjectScope";
import ProjectStatusConstants from "constants/ProjectStatusConstants";
import { SubContractorInvoiceScope } from "components/subContractorInvoices/redux/SubContractorInvoiceScope";
import InvoiceStatusConstants from "constants/InvoiceStatusConstants";
import { DispatchActions, FormActions, FormEvents, ModalConstants } from "@gman/gman-redux";

const mapDispatchToProps = dispatch => {
    var scope = SubContractorInvoiceScope;
    return {
        ...FormEvents.getConfirmEventsForModalsOnNewOnly(dispatch, scope),
        ...FormEvents.getFeedbackEvents(dispatch, scope),
        ...FormEvents.getPaginatorEvents(dispatch, scope),
        ...FormEvents.getRecordStateEvents(dispatch, scope),
        ...FormEvents.getTableHeaderRowEvents(dispatch, scope),
        ...FormEvents.getModalEventsForModalsOnAll(dispatch, scope),
        ...FormEvents.getRedirectEvents(dispatch, scope),
        modalConfirmEvents: {
            ...FormEvents.getConfirmEventsForModalsOnAll(dispatch, scope)
        },
        uploadEvents: {
            ...FormEvents.getUploadFileEvents(dispatch, scope).uploadEvents,
            onUploadAccept: fileData => {
                dispatch({
                    ...DispatchActions.scopedDispatch(scope.key, "CLEAR_UPLOAD_FEEDBACK")
                });
                dispatch(FormActions.acceptUpload(scope, fileData));
            }
        },
        onLoadDependentData: () => {
            dispatch(FormActions.fetchAll(CostCodeScope));
            dispatch(
                FormActions.fetchAll(ProjectAnyScope, {
                    status: ProjectStatusConstants.INPROGRESS
                })
            );
            dispatch(FormActions.fetchAll(VariationScope));
        },
        onUploadSubmit: fileData => {
            dispatch({
                ...DispatchActions.scopedDispatch(scope.key, "CLEAR_UPLOAD_FEEDBACK")
            });
            dispatch(
                FormActions.fetchUpload(
                    scope,
                    "/api/SubContractorInvoice/Upload/",
                    fileData,
                    "The files were uploaded successfully.",
                    true
                )
            )
                .then(result => {
                    if (result && result.error) {
                    } else {
                        dispatch({
                            ...DispatchActions.scopedDispatch(
                                scope.key,
                                "APPLY_UPLOAD_FEEDBACK"
                            ),
                            record: result.record
                        });
                    }
                })
                .catch(error => {
                    FormActions.handleErrorResponse(error, scope, dispatch);
                })
                .finally(() => {
                    dispatch(FormActions.isUploadingStop(scope));
                });
        },
        onConfirmPaid: record => {
            record.status = InvoiceStatusConstants.PAID;
            dispatch(
                FormActions.doPost(
                    scope,
                    record,
                    "/api/SubContractorInvoice/MarkAsPaid",
                    "The invoice was successfully marked as paid.",
                    true
                )
            );
            dispatch(FormActions.hideModal(scope, "MARK_AS_PAID"));
            dispatch({
                ...DispatchActions.scopedDispatch(scope.key, "SET_LAST_PAYMENT_DATE"),
                paymentDate: record.paymentDate
            });
        },
        onClearUploadFeedback: () => {
            dispatch({
                ...DispatchActions.scopedDispatch(scope.key, "CLEAR_UPLOAD_FEEDBACK")
            });
        }
    };
};

const SubContractorInvoiceContainerExtended = connect(state => {
    return {
        sourceData: SubContractorInvoiceScope.selector(state),
        lastInvoicePayments: state.lastInvoicePayments
    };
}, mapDispatchToProps)(SubContractorInvoiceCardExtended);

const SubContractorInvoiceListContainer = connect((state, ownProps) => {
    return {
        sourceData: SubContractorInvoiceScope.selector(state),
        lastInvoicePayments: state.lastInvoicePayments,
        projectId: ownProps.projectId,
        markup: ownProps.markup
    };
}, mapDispatchToProps)(SubContractorInvoiceList);

export {
    SubContractorInvoiceContainerExtended,
    SubContractorInvoiceListContainer
};
