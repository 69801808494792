import React, { Component } from "react"
import PropTypes from "prop-types"
import { Table } from "reactstrap"
import { UserRow } from "components/users/layout/UserRow"
import UserFormBasic from "components/users/form/UserFormBasic"
import { UserShape } from "shapes/UserShape"
import TableHeaderRow from "components/common/TableHeaderRow"
import { ModalActions } from "@gman/gman-redux"
import PageSizeSelect from "components/common/PageSizeSelect"
import { AuthorizedAddButton } from "components/authentication/controls/AuthorizedControls"
import {
    ConfirmDelete,
    FormModal,
    Paginator,
    SpinnerOverlay
} from "@gman/gman-react-bootstrap"
import FeedbackAlert from "components/common/FeedbackAlert"

const AddButton = AuthorizedAddButton(p => p.canWriteUsers)

class UserList extends Component {

    componentDidMount() {
        this.props.recordStateEvents.onClearSearch({ clientId: this.props.clientId })
        this.props.onLoadDependentData()
    }

    componentWillUnmount = () => {
        this.props.feedbackEvents.onFlushFeedback()
    }

    render() {
        var sourceData = this.props.sourceData
        var confirmEvents = this.props.confirmEvents
        var feedbackEvents = this.props.feedbackEvents
        var modalEvents = this.props.modalEvents
        var paginatorEvents = this.props.paginatorEvents
        var tableHeaderRowEvents = this.props.tableHeaderRowEvents

        return (
            <div>
                <FeedbackAlert {...feedbackEvents} feedback={sourceData.feedback} />
                <SpinnerOverlay isLoading={sourceData.inProgress}>
                    <Table hover size="sm">
                        <thead>
                            <TableHeaderRow
                                {...tableHeaderRowEvents}
                                disabled={sourceData.inProgress}
                                headers={[
                                    { sortBy: "EmailAddress", label: "Email" },
                                    { sortBy: "FirstName", label: "First Name" },
                                    { sortBy: "LastName", label: "Last Name" },
                                    { sortBy: "Manager.FirstName", label: "Resource Coordinator" },
                                    { sortBy: "UserId", label: "Classification" }
                                ]}
                            />
                        </thead>
                        <tbody>
                            {
                                sourceData.records.map((user, index) => (
                                    <UserRow
                                        key={index}
                                        {...user}
                                        onShowDelete={() => modalEvents.onShowDelete(user)}
                                        onShowEdit={() => modalEvents.onShowEdit(user)}
                                        disabled={sourceData.inProgress} />
                                ))
                            }
                        </tbody>
                    </Table>
                    <Paginator {...paginatorEvents} searchOptions={sourceData.searchOptions} />
                    <AddButton disabled={sourceData.inProgress} onClick={() => modalEvents.onShowNew()} />
                    {this.props.onShowAllRecords ? <input type="button" class="btn btn-sm btn-outline-success" disabled={sourceData.inProgress} onClick={this.props.onShowAllRecords} value="Show All Records"></input> : ''}
                    <PageSizeSelect disabled={sourceData.inProgress} defaultPageSize={sourceData.searchOptions.pageSize} onChange={this.props.onPageSizeChange} />
                </SpinnerOverlay>

                <FormModal
                    isVisible={ModalActions.IsDeleteVisible(sourceData.modals)}
                    onHide={modalEvents.onHideDelete}
                    title={"Confirm Delete"}>
                    <ConfirmDelete onConfirmDelete={() => confirmEvents.onConfirmDelete(sourceData.recordToUpdate)} />
                </FormModal>
                <FormModal
                    size="lg"
                    isVisible={ModalActions.IsUpdateVisible(sourceData.modals)}
                    onHide={modalEvents.onHideEdit}
                    title={"Create User"}>
                    <UserFormBasic
                        onSubmitUpdate={confirmEvents.onSubmitUpdate}
                        {...sourceData.recordToUpdate} />
                </FormModal>
            </div>
        )
    }
}

UserList.propTypes = {
    sourceData: PropTypes.shape({
        records: PropTypes.arrayOf(UserShape)
    })
}

export default UserList