import React from "react"
import { Badge } from "reactstrap"
import ExpenseStatusConstants from "constants/ExpenseStatusConstants"

const ExpenseStatusBadge = props => {
    const { value } = props
    return (
        <Badge color={MapExpenseStatusColour(value)}>
            {MapExpenseStatusLabel(value)}
        </Badge>
    )
}

const MapExpenseStatusLabel = value => {
    switch (value) {
        case ExpenseStatusConstants.DRAFT:
            return "DRAFT"
        case ExpenseStatusConstants.SUBMITTED:
            return "SUBMITTED"
        case ExpenseStatusConstants.APPROVED:
            return "APPROVED"
        case ExpenseStatusConstants.DECLINED:
            return "DECLINED"
        case ExpenseStatusConstants.PAID:
            return "PAID"
        case ExpenseStatusConstants.NOT_REIMBURSABLE:
            return "NOT REIMBURSABLE"
        default:
            return "UNKNOWN"
    }
}

const MapExpenseStatusColour = value => {
    switch (value) {
        case ExpenseStatusConstants.DRAFT:
            return "warning"
        case ExpenseStatusConstants.SUBMITTED:
            return "primary"
        case ExpenseStatusConstants.APPROVED:
            return "success"
        case ExpenseStatusConstants.DECLINED:
            return "danger"
        case ExpenseStatusConstants.PAID:
            return "light"
        case ExpenseStatusConstants.NOT_REIMBURSABLE:
            return "light"
        default:
            return "default"
    }
}

export default ExpenseStatusBadge