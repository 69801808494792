import React, { Component } from "react"
import { Link } from "react-router-dom"
import { Progress, UncontrolledPopover, PopoverBody } from "reactstrap"

function _interopDefault (ex) { return (ex && (typeof ex === 'object') && 'default' in ex) ? ex['default'] : ex; }
var toNumber = _interopDefault(require('lodash.tonumber'));
export class ProjectSummaryProgress extends Component{
    constructor(props) {
        super(props);
    
        this.toggle = this.toggle.bind(this);
        this.state = {
          popoverOpen: false
        };
    }

    toggle() {
        this.setState({
          popoverOpen: !this.state.popoverOpen
        });
    }

    getMaxOriginalFees(data1, data2) {
        var data1 = (data1).toFixed(2);
        var data2 = (data2).toFixed(2);
        if (parseFloat(data1) > parseFloat(data2)) {
            return data1;
        } else {
            return data2;
        }
    }

    render(){
        var data1 = this.props.data1
        var data2 = this.props.data2
        var projectNumber = this.props.id
        var color = this.props.color
        var data1_in_k = Math.round(data1 / 1000)
        var data2_in_k = Math.round(data2 / 1000)
        return (
            <span style={{width:(toNumber(data1_in_k) / toNumber(Math.max(data1_in_k, data2_in_k))  * 100) < 10 ? 10 +'%' : (toNumber(data1_in_k) / toNumber(Math.max(data1_in_k, data2_in_k))  * 100) +'%', height: '15px'}}>
                <Progress 
                id={color ? projectNumber+"2" : projectNumber}
                multi 
                max={data1_in_k && data2_in_k ? this.getMaxOriginalFees(data1_in_k, data2_in_k) : 0}>
                <Progress
                    bar 
                    value={100} 
                    className="p-1"
                    color={color ? color : ''}
                    max={100}>
                        {
                            (toNumber(data1) / toNumber(Math.max(data1, data2))  * 100) > 70 ? <span>${Math.round(data1 / 1000)}k</span> : ''
                        }
                </Progress>
                </Progress>
                <UncontrolledPopover placement="bottom" isOpen={this.state.popoverOpen} target={color ? projectNumber+"2" : projectNumber} toggle={this.toggle} trigger="hover">
                    <PopoverBody>
                        {
                            data1 > 999 ? <span>${Math.round(data1 / 1000)}k</span> : <span>${Math.round(data1 / 1000)}k</span>
                        }
                    </PopoverBody>
                </UncontrolledPopover>
            </span>
        )
    }

}