import ScopeConstants from "constants/ScopeConstants"
import { DispatchActions, FetchActions } from "@gman/gman-redux"

var ExpenseCalculations = {
    getExpenseCalculation(dispatch, scope) {
        return {
            onMakeStale: () => {
                dispatch({ ...DispatchActions.scopedDispatch(ScopeConstants.EXPENSE_MINE, "STALE_VALUE") })
            },
            onRecalculateAmount: (amountUnit, taxCalculated, taxStatus, expenseTypeId, effectiveDate, index) => {
                dispatch(function (dispatch, getState) {
                    var authToken = ""
                    if (scope.tokenSelector) {
                        authToken = scope.tokenSelector(getState())
                    }
                    var request = {
                        amountUnit: amountUnit || 0.00,
                        taxCalculated: taxCalculated || 0.00,
                        taxStatus: taxStatus,
                        expenseTypeId: expenseTypeId || "",
                        effectiveDate: effectiveDate
                    }
                    dispatch({ ...DispatchActions.scopedDispatch(ScopeConstants.EXPENSE_MINE, "RECALCULATE_EXPENSE_COMMENCE") })
                    FetchActions.fetchApiPost("/api/Expense/Calculate", request, authToken)
                        .then((json) => {
                            dispatch({ ...DispatchActions.scopedDispatch(ScopeConstants.EXPENSE_MINE, "RECALCULATE_EXPENSE_COMPLETE"), calculation: json, index })
                        })
                })
            }
        }
    }
}

export { ExpenseCalculations }