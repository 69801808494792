import { connect } from "react-redux"
import ClassificationList from "components/classifications/layout/ClassificationList"
import { ClassificationScope } from "components/classifications/redux/ClassificationScope"
import { FormActions, FormEvents, DispatchActions } from "@gman/gman-redux"

const mapDispatchToProps = (dispatch) => {
  var scope = ClassificationScope
  return {
    ...FormEvents.getConfirmEventsForModalsOnAll(dispatch, scope),
    ...FormEvents.getTableHeaderRowEvents(dispatch, scope),
    ...FormEvents.getFeedbackEvents(dispatch, scope),
    ...FormEvents.getPaginatorEvents(dispatch, scope),
    ...FormEvents.getModalEventsForModalsOnAll(dispatch, scope),
    ...FormEvents.getRecordStateEvents(dispatch, scope),
    onShowAllRecords: () => {
      dispatch({ ...DispatchActions.scopedDispatch(scope.key, "SHOW_ALL") });
      dispatch(FormActions.fetchRecords(scope));
    },
  }
}

const ClassificationContainer = connect(
  state => { return { sourceData: ClassificationScope.selector(state) } },
  mapDispatchToProps
)(ClassificationList)

export default ClassificationContainer