import keyMirror from "keymirror"

const DashboardElementConstants = keyMirror({
    EXPENSE_QUICK_NUMBERS_MINE: null,
    EXPENSE_QUICK_NUMBERS_TEAM: null,
    EXPENSE_QUICK_NUMBERS_ALL: null,
    TIMESHEET_QUICK_NUMBERS_MINE: null,
    TIMESHEET_QUICK_NUMBERS_TEAM: null,
    TIMESHEET_QUICK_NUMBERS_ALL: null,
    TIMESHEET_HOURS: null,
    PROJECT_SUMMARY_AS_PROJECT_MANAGER: null,
    PROJECT_SUMMARY_AS_PROJECT_DIRECTOR: null
})

export default DashboardElementConstants