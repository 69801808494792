import React, { Component } from "react"
import PropTypes from "prop-types"
import { VariationShape } from "shapes/VariationShape"
import { Table } from "reactstrap"
import VariationRow from "components/variations/layout/VariationRow"
import VariationFormBasic from "components/variations/form/VariationFormBasic"
import TableHeaderRow from "components/common/TableHeaderRow"
import FeedbackAlert from "components/common/FeedbackAlert"
import { ModalActions } from "@gman/gman-redux"
import { AuthorizedAddButton } from "components/authentication/controls/AuthorizedControls"
import {
    ConfirmDelete,
    FormModal,
    Paginator,
    SpinnerOverlay
} from "@gman/gman-react-bootstrap"

const AddButton = AuthorizedAddButton(p => p.canWriteWorkItems)

class VariationList extends Component {

    componentDidMount() {
        this.props.recordStateEvents.onClearSearch({ projectId: this.props.projectId })
        this.props.onLoadDependentData()
    }

    componentWillUnmount = () => {
        this.props.feedbackEvents.onFlushFeedback()
    }

    render() {
        var sourceData = this.props.sourceData
        var modals = sourceData.modals
        var confirmEvents = this.props.confirmEvents
        var feedbackEvents = this.props.feedbackEvents
        var modalEvents = this.props.modalEvents
        var paginatorEvents = this.props.paginatorEvents
        var tableHeaderRowEvents = this.props.tableHeaderRowEvents

        return (
            <div>
                {
                    !ModalActions.IsUpdateVisible(modals) ?
                        <FeedbackAlert {...feedbackEvents} feedback={sourceData.feedback} />
                        : ""
                }
                <SpinnerOverlay isLoading={sourceData.inProgress}>
                    <Table responsive hover size="sm">
                        <thead>
                            <TableHeaderRow
                                {...tableHeaderRowEvents}
                                disabled={sourceData.inProgress}
                                headers={[
                                    { sortBy: "Number", label: "Number" },
                                    { sortBy: "Description", label: "Description" },
                                    { sortBy: "Status", label: "Status" },
                                    { sortBy: "Type", label: "Type" },
                                    { sortBy: "DaysExtension", label: "Days Extension" },
                                    { sortBy: "VariationFee", label: "Total Original Fee" }
                                ]}
                            />
                        </thead>
                        <tbody>
                            {
                                sourceData.records.map((record, index) => (
                                    <VariationRow
                                        key={index}
                                        {...record}
                                        onShowDelete={() => modalEvents.onShowDelete(record)}
                                        onShowEdit={() => modalEvents.onShowEdit(record)}
                                        disabled={sourceData.inProgress} />
                                ))
                            }
                        </tbody>
                    </Table>
                    <Paginator {...paginatorEvents} searchOptions={sourceData.searchOptions} />
                    <AddButton disabled={sourceData.inProgress} onClick={() => modalEvents.onShowNew({ projectId: this.props.projectId })} />
                </SpinnerOverlay>

                <FormModal
                    isVisible={ModalActions.IsDeleteVisible(sourceData.modals)}
                    onHide={modalEvents.onHideDelete}
                    title={"Confirm Delete"}>
                    <ConfirmDelete onConfirmDelete={() => confirmEvents.onConfirmDelete(sourceData.recordToUpdate)} />
                </FormModal>
                <FormModal
                    size="lg"
                    isVisible={ModalActions.IsUpdateVisible(sourceData.modals)}
                    onHide={modalEvents.onHideEdit}
                    title={"Update Variation"}>
                    <VariationFormBasic
                        onSubmitUpdate={confirmEvents.onSubmitUpdate}
                        feedback={sourceData.feedback}
                        feedbackEvents={feedbackEvents}
                        {...sourceData.recordToUpdate} />
                </FormModal>
            </div>
        )
    }
}

VariationList.propTypes = {
    sourceData: PropTypes.shape({
        records: PropTypes.arrayOf(VariationShape)
    })
}

export default VariationList