import React from "react"
import Moment from "moment"
import { withFormik } from "formik"
import * as Yup from "yup"
import Icon from "components/common/Icon"
import RequiredStar from "components/common/RequiredStar"
import { AuthorizedButton, AuthorizedSelectOption, AuthorizedInputField } from "components/authentication/controls/AuthorizedControls"
import SelectStyles from "components/common/SelectStyles"

import {
    Col,
    Container,
    Form,
    FormGroup,
    Label,
    Row
} from "reactstrap"

const SubmitButton = AuthorizedButton(p => p.canWriteUsers)
const SelectOptions = AuthorizedSelectOption(p => p.canWriteUsers)
const InputField = AuthorizedInputField(p => p.canWriteUsers)

const BaseForm = props => {
    const {
        values,
        dirty,
        errors,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting
    } = props
    return (
        <div>
            <Form noValidate onSubmit={handleSubmit}>
                <h6>
                    <Icon folderOpen />
                    User Details
                </h6>
                <hr />
                <Container>
                    <Row>
                        <Col xs={12} md={6}>
                            <FormGroup>
                                <RequiredStar />
                                <Label for="firstName">First Name</Label>
                                <InputField
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.firstName || ""}
                                    type="text"
                                    name="firstName"
                                    id="firstName"
                                    placeholder="First Name"
                                    invalid={errors.firstName && errors.firstName.length > 0}
                                />
                                {errors.firstName && <small className="text-danger">{errors.firstName}</small>}
                            </FormGroup>
                        </Col>
                        <Col xs={12} md={6}>
                            <FormGroup>
                                <RequiredStar />
                                <Label for="lastName">Last Name</Label>
                                <InputField
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.lastName || ""}
                                    type="text"
                                    name="lastName"
                                    id="lastName"
                                    placeholder="Last Name"
                                    invalid={errors.lastName && errors.lastName.length > 0}
                                />
                                {errors.lastName && <small className="text-danger">{errors.lastName}</small>}
                            </FormGroup>
                        </Col>

                    </Row>
                    <Row>
                        <Col xs={12} md={6}>
                            <FormGroup>
                                <RequiredStar />
                                <Label for="emailAddress">Email Address</Label>
                                <InputField
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.emailAddress || ""}
                                    type="text"
                                    name="emailAddress"
                                    id="emailAddress"
                                    placeholder="Enter an email address"
                                    invalid={errors.emailAddress && errors.emailAddress.length > 0}
                                />
                                {errors.emailAddress && <small className="text-danger">{errors.emailAddress}</small>}
                            </FormGroup>
                        </Col>
                        <Col xs={12} md={6}>
                            <FormGroup>
                                <Label for="managerId">Approver</Label>
                                <SelectOptions
                                    styles={errors.managerId && errors.managerId.length > 0 ? SelectStyles.errorStyles() : {}}
                                    isClearable={true}
                                    disabled={isSubmitting}
                                    value={values.managerId}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    id="managerId"
                                    invalid={errors.managerId && errors.managerId.length > 0}
                                    stateFunc={(state) => { return { ...state.managers } }}
                                    valueFunc={(record) => record.userId}
                                    labelFunc={(record) => record.firstName + " " + record.lastName}
                                    sortFunc={(a, b) => a.firstName.localeCompare(b.firstName)}
                                />
                                {errors.managerId && <small className="text-danger">{errors.managerId}</small>}
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12} md={6}>
                            <FormGroup>
                                <RequiredStar />
                                <Label for="dateStarted">Start Date</Label>
                                <InputField
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.dateStarted ? Moment(values.dateStarted).format("YYYY-MM-DD") : ""}
                                    type="date"
                                    name="dateStarted"
                                    id="dateStarted"
                                    placeholder="Employee Start Date"
                                    invalid={errors.dateStarted} />
                                {errors.dateStarted && <small className="text-danger">{errors.dateStarted}</small>}
                            </FormGroup>
                        </Col>
                        <Col xs={12} md={6}>
                            <FormGroup>
                                <Label for="dateEnded">End Date</Label>
                                <InputField
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.dateEnded ? Moment(values.dateEnded).format("YYYY-MM-DD") : ""}
                                    type="date"
                                    name="dateEnded"
                                    id="dateEnded"
                                    placeholder="Employee End Date"
                                    invalid={errors.dateEnded} />
                                {errors.dateEnded && <small className="text-danger">{errors.dateEnded}</small>}
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12} md={6}>
                            <FormGroup>
                                <Label for="careerStartYear">Year Career Began</Label>
                                <InputField
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.careerStartYear || ""}
                                    type="number"
                                    name="careerStartYear"
                                    id="careerStartYear"
                                    placeholder="Year Career Began"
                                    invalid={errors.careerStartYear} />
                                {errors.careerStartYear && <small className="text-danger">{errors.careerStartYear}</small>}
                            </FormGroup>
                        </Col>
                    </Row>
                </Container>
                <h6>
                    <Icon workbench />
                    Workbench Details
                </h6>
                <hr />
                <Container>
                    <Row>
                        <Col xs={12} md={6}>
                            <FormGroup>
                                <RequiredStar />
                                <Label for="employeeNumber">Employee Number<br /><small>this number <u>must</u> match the WorkBench employee number</small></Label>
                                <InputField
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.employeeNumber || ""}
                                    type="text"
                                    name="employeeNumber"
                                    id="employeeNumber"
                                    placeholder="Enter an employee number"
                                    invalid={errors.employeeNumber && errors.employeeNumber.length > 0}
                                />
                                {errors.employeeNumber && <small className="text-danger">{errors.employeeNumber}</small>}
                            </FormGroup>
                        </Col>
                    </Row>
                </Container>
                <h6>
                    <Icon workflow />
                    Workflow
                </h6>
                <Container>
                    <Row>
                        <Col xs={12}>
                            <FormGroup>
                                <Label for="comments">Comments</Label>
                                <InputField
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.comments || ""}
                                    type="textarea"
                                    name="comments"
                                    id="comments"
                                    placeholder="Enter comments"
                                    style={{ resize: "none" }}
                                    rows={3}
                                    invalid={errors.comments} />
                                {errors.comments && <small className="text-danger">{errors.comments}</small>}
                            </FormGroup>
                        </Col>
                    </Row>
                </Container>
                <SubmitButton outline className="float-right" disabled={isSubmitting || !dirty} color="success" type="submit">Submit</SubmitButton>
            </Form>
        </div>
    )
}

const validationSchema = Yup.object({
    emailAddress: Yup.string()
        .required("Email address is required"),
    firstName: Yup.string()
        .required("First name is required"),
    lastName: Yup.string()
        .required("Last name is required"),
    employeeNumber: Yup.string()
        .nullable()
        .required("Employee Number is required"),
    managerId: Yup.string()
        .nullable(),
    dateStarted: Yup.date()
        .required("Start date is required")
        .typeError("Must be a date"),
    careerStartYear: numberValidation()
        .integer("Must be integer")
        .strict()
        .min(1980, "Must be greater than 1980")
        .required("Employee career start year is required"),
    dateEnded: Yup.date()
        .nullable()
        .typeError("Must be a date")
        .min(Yup.ref("dateStarted"), "End date cannot precede Start date")
})

function numberValidation() {
    return Yup.number()
        .default(0)
        .nullable()
        .round()
        .min(0, "Must be positive")
        .typeError("Must be a number")
}

const UserFormBasic = withFormik({
    displayName: "UserFormBasic",
    enableReinitialize: true,
    validationSchema: validationSchema,
    handleSubmit: (values, { props, setSubmitting }) => {
        props.onSubmitUpdate(values)
        setSubmitting(false)
    }
})(BaseForm)

export default UserFormBasic