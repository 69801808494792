import React from "react"
import { withFormik } from "formik"
import * as Yup from "yup"
import Icon from "components/common/Icon"
import RequiredStar from "components/common/RequiredStar"
import { AuthorizedButton, AuthorizedInputField } from "components/authentication/controls/AuthorizedControls"
import { SelectOption } from "@gman/gman-redux"

import {
    Col,
    Container,
    Form,
    FormGroup,
    Label,
    Row
} from "reactstrap"

const SubmitButton = AuthorizedButton(p => p.canWriteClients)
const InputField = AuthorizedInputField(p => p.canWriteClients)

const BaseForm = props => {
    const {
        values,
        dirty,
        errors,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
    } = props
    return (
        <div>
            <Form noValidate onSubmit={handleSubmit}>
                <h6>
                    <Icon folderOpen />
                    Client Details
                </h6>
                <hr />
                <Container>
                    <Row>
                        <Col>
                            <FormGroup>
                                <RequiredStar />
                                <Label for="name">Client Name</Label>
                                <InputField
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.name || ""}
                                    type="text"
                                    name="name"
                                    id="name"
                                    placeholder="Enter a client name"
                                    invalid={errors.name} />
                                {errors.name && <small className="text-danger">{errors.name}</small>}
                            </FormGroup>
                        </Col>
                        <Col>
                            <FormGroup>
                                <RequiredStar />
                                <Label for="clientCode">Client Code</Label>
                                <InputField
                                    readonly
                                    disabled
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.code || ""}
                                    type="text"
                                    name="code"
                                    id="code"
                                    placeholder="Client Code"
                                    invalid={errors.code} />
                                {errors.code && <small className="text-danger">{errors.code}</small>}
                            </FormGroup>
                        </Col>
                        <Col>
                            <FormGroup>
                                <RequiredStar />
                                <Label for="appliedChargeRatesType">Standard charge rates to be used</Label>
                                <SelectOption
                                    isClearable={true}
                                    disabled={isSubmitting}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    stateFunc={(state) => { return { ...state.clientChargeRateType } }}
                                    valueFunc={(record) => record.rateTypeId}
                                    labelFunc={(record) => record.description}
                                    value={values.appliedChargeRatesType}
                                    id="appliedChargeRatesType"
                                />
                                {errors.appliedChargeRatesType && <small className="text-danger">{errors.appliedChargeRatesType}</small>}
                            </FormGroup>
                        </Col>
                        <Col>
                            <FormGroup>
                                <RequiredStar />
                                <Label for="markup">Default Subcontractor Mark up (%)</Label>
                                <InputField
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.markup}
                                    type="number"
                                    name="markup"
                                    id="markup"
                                    placeholder="Enter a subcontractor mark up"
                                    invalid={errors.markup} />
                                {errors.markup && <small className="text-danger">{errors.markup}</small>}
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <SubmitButton outline className="float-right" disabled={isSubmitting || !dirty} color="success" type="submit">Submit</SubmitButton>
                        </Col>
                    </Row>
                </Container>
            </Form>
        </div>
    )
}

const validationSchema = Yup.object({
    name: Yup.string()
        .required("Name is required"),
    code: Yup.string()
        .required("Client code is required")
        .matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed for this field "),
    appliedChargeRatesType: Yup.string()
        .required("Standard charge rates to be used is required")
        .nullable(),
    markup: markupValidation(),
})

function markupValidation(){
    return Yup.number()
    .default(0)
    .nullable()
    .required("Value is required")
    .typeError("Must be a number")
    .min(0, "Must be positive")
}

function numberValidation() {
    return Yup.number()
        .default(0)
        .nullable()
        .required("Value is required")
        .positive("Must be positive")
        .typeError("Must be a number")
        // .min(0, "Must be more than zero")
        // .moreThan(0, "Must be more than zero")
}

const ClientFormExtended = withFormik({
    displayName: "ClientFormExtended",
    enableReinitialize: true,
    validationSchema: validationSchema,
    initialValues: ({ value }) => ({
        name: value.name,
        code: value.code,
        active: value.active,
        markup: value.markup
    }),
    handleSubmit: (values, { props, setSubmitting }) => {
        props.onSubmitUpdate(values)
        setSubmitting(false)
    }
})(BaseForm)

export default ClientFormExtended