import React, { useState } from "react"
import { Button, ButtonGroup, Input } from "reactstrap"
import { FormModal } from "@gman/gman-react-bootstrap"
import { ModalActions } from "@gman/gman-redux"
import Moment from "moment"
import WorkSelectForm from "components/common/WorkSelectForm"
import WorkItemCell from "components/common/WorkItemCell"
import { AuthorizedLink } from "components/authentication/controls/AuthorizedControls"
import Icon from "components/common/Icon"
import { withFormik } from "formik"
import ScopeConstants from "constants/ScopeConstants";
import ActiveCell from "components/common/ActiveCell";
import InvoiceStatusBadge from "components/common/InvoiceStatusBadge";
import InvoiceStatusConstants from "constants/InvoiceStatusConstants";
import ClientInvoiceStatusBadge from "components/common/ClientInvoiceStatusBadge"
import Currency from "components/common/Currency"

export const InvoiceStatusConstantsSubcontractor = {
    UNPAID: 0,
    PAID: 1,
}
const InvoiceLink = AuthorizedLink(p => p.canMarkItemsAsPaid)

const BaseForm = props => {
    const {
        index,
        values,
        modals,
        onShowModal,
        onHideModal,
        dirty,
        errors,
        setFieldValue,
        handleChange,
        handleBlur,
        handleWorkItemChange,
        handleFormChange,
        handleTextFiledChange,
        handleDateFiledChange,
        handleNumberFiledChange,
        handleCostItemsStausChange
    } = props;
   return (
        <tr>
             <ActiveCell>
                {
                    (values.status == InvoiceStatusConstantsSubcontractor.PAID || (values.clientInvoice && values.clientInvoice.status != InvoiceStatusConstants.DRAFT)) ?
                        values.number:
                        textField("number", values.number, handleChange, handleBlur, handleTextFiledChange, "75px")
                }
            </ActiveCell>
            <ActiveCell>
                {
                    (values.status == InvoiceStatusConstantsSubcontractor.PAID || (values.clientInvoice && values.clientInvoice.status != InvoiceStatusConstants.DRAFT )) ? 
                        values.subContractor:
                        textField("subContractor", values.subContractor, handleChange, handleBlur, handleTextFiledChange, "150px")
                }
            </ActiveCell>
            <td>
                {values.project ? values.project.code : ""}
            </td>
            <td className="weekend">
                <div style={{ width: '325px' }}>
                    {
                        values.workItem ?
                            <WorkItemCell
                                workItem={values.workItem}
                                projectColumnWidth={8}
                                projectCodeColumnWidth={4}
                                projectVariationColumnWidth={8}
                                projectCostCodeColumnWidth={4}
                                hideProjectCode = {true}
                            />
                            : ""
                    }
                    {
                        (values.status == InvoiceStatusConstantsSubcontractor.PAID || (values.clientInvoice && values.clientInvoice.status != InvoiceStatusConstants.DRAFT )) ? 
                            "" :
                            <Button block color="light" size="sm" onClick={() => onShowModal("SELECT_WORK_ITEM")}>
                                <Icon book />
                                Select Project
                            </Button>
                    }
                </div>
            </td>
            <ActiveCell>
                {
                    (values.status == InvoiceStatusConstantsSubcontractor.PAID || (values.clientInvoice && values.clientInvoice.status != InvoiceStatusConstants.DRAFT )) ? 
                        values.description :
                        textArea("description", values.description, handleChange, handleBlur, handleTextFiledChange, "250px")
                }
            </ActiveCell>
            <td>
                {
                    (values.status == InvoiceStatusConstantsSubcontractor.PAID || (values.clientInvoice && values.clientInvoice.status != InvoiceStatusConstants.DRAFT )) ? 
                        Currency(values.invoiceAmount) :
                        numberField("invoiceAmount", values.invoiceAmount, handleChange, handleBlur, handleNumberFiledChange, "75px")
                }
            </td>
            <td>
                {
                    (values.status == InvoiceStatusConstantsSubcontractor.PAID || (values.clientInvoice && values.clientInvoice.status != InvoiceStatusConstants.DRAFT )) ? 
                        values.markup :
                        numberField("markup", values.markup, handleChange, handleBlur, handleNumberFiledChange, "75px")
                }
            </td>
            <td>
                {
                    Currency(values.totalChargeToClient) 
                }
            </td>
            <td>
                {
                    (values.status == InvoiceStatusConstantsSubcontractor.PAID || (values.clientInvoice && values.clientInvoice.status != InvoiceStatusConstants.DRAFT )) ? 
                        Moment(values.invoiceDate).format("DD/MM/YYYY") :
                        dateField("invoiceDate", Moment(values.invoiceDate).format("YYYY-MM-DD"), handleChange, handleBlur, handleDateFiledChange, "165px")
                }
            </td>
            <td>
                {
                    (values.status == InvoiceStatusConstantsSubcontractor.PAID || (values.clientInvoice && values.clientInvoice.status != InvoiceStatusConstants.DRAFT )) ? 
                        Moment(values.dueDate).format("DD/MM/YYYY") :
                        dateField("dueDate", Moment(values.dueDate).format("YYYY-MM-DD"), handleChange, handleBlur, handleDateFiledChange, "165px")
                }
            </td>
            {/* <td>
                {
                    (values.status == InvoiceStatusConstantsSubcontractor.PAID || (values.clientInvoice && values.clientInvoice.status != InvoiceStatusConstants.DRAFT )) ? 
                     values.paymentDate ? Moment(values.paymentDate).format("DD/MM/YYYY") : "" :
                     dateField("paymentDate", Moment(values.paymentDate).format("YYYY-MM-DD"), handleChange, handleBlur, handleDateFiledChange, "165px")
                }
            </td> */}
             <td>
                {
                    values.paymentDate ? Moment(values.paymentDate).format("DD/MM/YYYY") : ""
                }
            </td>
            <td>
                <InvoiceStatusBadge value={values.status} />
            </td>
            <td className="text-center">
                <Input
                    type="radio"
                    value={0}
                    style={{ position: 'unset', margin: 0 }}
                    name="costItemStatus"
                    disabled={values.clientInvoice && values.clientInvoice.status != InvoiceStatusConstants.DRAFT}
                    onChange={
                        (e) => {
                            handleCostItemsStausChange(e);
                            handleChange(e);
                        }
                    }
                    checked={values.costItemStatus == 0 ? true : false}
                />
            </td>
            <td className="text-center">
                <Input
                    type="radio"
                    value={1}
                    style={{ position: 'unset', margin: 0 }}
                    name="costItemStatus"
                    disabled={values.clientInvoice && values.clientInvoice.status != InvoiceStatusConstants.DRAFT}
                    onChange={
                        (e) => {
                            handleCostItemsStausChange(e);
                            handleChange(e);
                        }
                    }
                    checked={values.costItemStatus == 1 ? true : false}
                />
            </td>
            <td className="text-center">
                <Input
                    type="radio"
                    value={2}
                    style={{ position: 'unset', margin: 0 }}
                    name="costItemStatus"
                    disabled={values.clientInvoice && values.clientInvoice.status != InvoiceStatusConstants.DRAFT}
                    onChange={
                        (e) => {
                            handleCostItemsStausChange(e);
                            handleChange(e);
                        }
                    }
                    checked={values.costItemStatus == 2 ? true : false}
                />
            </td>
            <td>{values.clientInvoice ? values.clientInvoice.number : ""}</td>
            <td>
                {
                    values.clientInvoice ? <ClientInvoiceStatusBadge value={values.clientInvoice.status} /> : ""
                }
            </td>
            <FormModal
                size="lg"
                isVisible={ModalActions.IsVisible(modals, "SELECT_WORK_ITEM")}
                onHide={() => onHideModal("SELECT_WORK_ITEM")}
                title={"Select Project"}>
                <WorkSelectForm
                    {...ConstructWorkItem(values.workItem)}
                    onSelectEntry={
                        (selection) => {
                            var workItem = selection.workItem;
                            setFieldValue("workItem", workItem);
                            onHideModal("SELECT_WORK_ITEM");
                            handleWorkItemChange(workItem);
                        }
                    }
                    customfilterFunc={record =>
                        record.type !== ScopeConstants.LEAVE ||
                        (record.type === ScopeConstants.LEAVE &&
                            (record.code === "MISC" ||
                                record.code === "R&D ACTIVITY"))
                    }
                />
            </FormModal>
        </tr>
    )
}

const getNumber = (value) => {
    var number = parseFloat(value)
    if (isNaN(number))
        return 0

    return number
}

const textField = (id, value, handleChange, handleBlur, handleTextFiledChange, columnWidth) => {
    const style = {
        zIndex: 10,
        width: columnWidth
    }
    return <>
        {
            <Input
                onChange={(e) => {
                    handleTextFiledChange(e);
                    handleChange(e);
                }}
                onBlur={handleBlur}
                style={style}
                value={value}
                onClick={(event) => event.stopPropagation()}
                type="text"
                name={id}
                id={id}
            />
        }

    </>
}

const textArea = (id, value, handleChange, handleBlur, handleTextFiledChange, columnWidth) => {
    const style = {
        zIndex: 10,
        width: columnWidth
    }
    return <>
        {
            <Input
                onChange={(e) => {
                    handleTextFiledChange(e);
                    handleChange(e);
                }}
                onBlur={handleBlur}
                style={style}
                value={value}
                onClick={(event) => event.stopPropagation()}
                type="textarea"
                name={id}
                id={id}
            />
        }

    </>
}

const dateField = (id, value, handleChange, handleBlur, handleDateFiledChange, columnWidth) => {
    const style = {
        zIndex: 10,
        width: columnWidth
    }
    return <>
        {
            <Input
                onChange={(e) => {
                    handleDateFiledChange(e);
                    handleChange(e);
                }}
                onBlur={handleBlur}
                value={value}
                style={style}
                onClick={(event) => event.stopPropagation()}
                type="date"
                name={id}
                id={id}
            />
        }
    </>
}

const numberField = (id, value, handleChange, handleBlur, handleNumberFiledChange, columnWidth) => {
    const style = {
        zIndex: 10,
        width: columnWidth
    }
    return <>
        {
            <Input
                onChange={(e) => {
                    handleNumberFiledChange(e);
                    handleChange(e);
                }}
                onBlur={handleBlur}
                value={value}
                onClick={(event) => event.stopPropagation()}
                style={style}
                type="number"
                name={id}
                id={id}
            />
        }
    </>
}

const ConstructWorkItem = (workItem) => {
    return {
        workItem: {
            workItemId: workItem && workItem.workItemId,
            costCodeGroupId: workItem && workItem.costCodeGroupId,
            type: workItem && workItem.type,
            name: workItem && workItem.name,
            variation: workItem && (workItem.variation),
            costCode: {
                costCodeId: workItem && workItem.costCode && (workItem.costCode.costCodeId),
                costCodeGroupId: workItem && workItem.costCodeGroupId
            }
        }
    }
}

const SubContractorInvoiceEditRowForm = withFormik({
    displayName: "SubContractorInvoiceEditRowForm",
    enableReinitialize: true,
    handleSubmit: (values, { props, setSubmitting }) => {
        setSubmitting(false)
    }
})(BaseForm)

export default SubContractorInvoiceEditRowForm