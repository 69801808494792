import { connect } from "react-redux"
import EditDataCardExtended from "components/editData/layout/EditDataCardExtended"
import { FormActions, FormEvents, ModalConstants, DispatchActions, FetchActions } from "@gman/gman-redux"
import { EditDataScope } from "components/editData/redux/EditDataScope"
import { CostCodeScope } from "components/costCodes/redux/CostCodeScope"
import { CostCodeGroupAllScope } from "components/costCodeGroups/redux/CostCodeGroupAllScope"
import { ProposalAnyScope } from "components/proposals/redux/ProposalAnyScope"
import { ClientScope } from "components/clients/redux/ClientScope"
import { ProjectAnyScope } from "components/projects/redux/ProjectAnyScope"
import { LeaveScope } from "components/leave/redux/LeaveScope"
import { UserScope } from "components/users/redux/UserScope"
import { VariationScope } from "components/variations/redux/VariationScope"
import ProjectStatusConstants from "constants/ProjectStatusConstants"
import ProposalStatusConstants from "constants/ProposalStatusConstants"
import EditDataCard from "components/editData/layout/EditDataCard";
import FileSaver from "file-saver"

const mapDispatchToPropsAny = (dispatch) => { return mapDispatchToProps(dispatch, EditDataScope) }

const mapDispatchToProps = (dispatch, scope) => {
    return {
        ...FormEvents.getTabEvents(dispatch, scope),
        onLoadDependentData: () => {
            dispatch(FormActions.fetchAll(CostCodeScope))
            dispatch(FormActions.fetchAll(CostCodeGroupAllScope))
            dispatch(FormActions.fetchAll(ClientScope))
            dispatch(FormActions.fetchAll(UserScope))
            dispatch(FormActions.fetchAll(ProjectAnyScope))
            dispatch(FormActions.fetchAll(ProposalAnyScope))
            dispatch(FormActions.fetchRecords(LeaveScope, LeaveScope.fetchEndpoint, { page: 1, pageSize: 100000, orderBy: "DisplayOrder" }))
            dispatch(FormActions.fetchAll(VariationScope))
        },

        onDownLoadProjectExtendedReport: () => {
            console.log('I am inside')
            let projectId = window.location.href.split('/')[4];

            dispatch(function (dispatch, getState) {
                var authToken = "";
                var fileName = "";
                if (scope.tokenSelector) {
                    authToken = scope.tokenSelector(getState())
                }

                dispatch(FormActions.inProgressStart(scope))
                FetchActions.fetchApiGet("api/Project/ProjectDataDownload?projectId=" + projectId, authToken)
                    .then(function (response) {
                        fileName = extractFileName(response.headers)
                        return Promise.resolve(response.blob())
                    }).then(function (blob) {
                        FileSaver.saveAs(blob, fileName);
                    })
                    .then(() => { dispatch(FormActions.inProgressStop(scope)) })
                    .catch((error) => { FormActions.handleErrorResponse(error, scope, dispatch) })
            })
        },
    }

}


function extractFileName(headers) {
    var disposition = headers.get("content-disposition")
    var fileName = disposition.match(/filename\*?=((['"])[\s\S]*?\2|[^;\n]*)/)[1]
    return fileName
}

const EditDataContainer = connect(
    (state, ownProps) => {
        console.log(ownProps, 'ownProps')

        localStorage.setItem('expectedClientInvoiceId', ownProps.expectedClientInvoiceId)

        return {
            sourceData: EditDataScope.selector(state),
            projectId: ownProps.projectId,
            isOpneInModal: ownProps.isOpneInModal,
            // expectedClientInvoiceId: "64b3b7af-c3f5-4bf1-a8af-24601d9294cf"
            expectedClientInvoiceId: ownProps.expectedClientInvoiceId
        }


    },
    mapDispatchToPropsAny
)(EditDataCardExtended)

const EditDataCardContainer = connect(
    (state, ownProps) => {
        return {
            sourceData: EditDataScope.selector(state)
        }
    },
    mapDispatchToPropsAny
)(EditDataCard)

export {
    EditDataContainer,
    EditDataCardContainer
}