import React from "react"
import Moment from "moment"
import { withFormik } from "formik"
import * as Yup from "yup"
import RequiredStar from "components/common/RequiredStar"
import SelectStyles from "components/common/SelectStyles"
import { AuthorizedButton, AuthorizedInputField, AuthorizedSelectOption } from "components/authentication/controls/AuthorizedControls"

import {
    Col,
    Container,
    Form,
    FormGroup,
    Label,
    Row
} from "reactstrap"

const SubmitButton = AuthorizedButton(p => p.canWriteUsers)
const InputField = AuthorizedInputField(p => p.canWriteUsers)
const SelectOptions = AuthorizedSelectOption(p => p.canWriteWorkItems)

const BaseForm = props => {
    const {
        values,
        dirty,
        errors,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting
    } = props
    return (
        <div>
            <Form noValidate onSubmit={handleSubmit}>
                <Container>
                    <Row>
                        <Col xs={12} md={6}>
                            <FormGroup>
                                <Label for="approvalDate">Effective Date</Label>
                                <InputField
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.effectiveDate ? Moment(values.effectiveDate).format("YYYY-MM-DD") : ""}
                                    type="date"
                                    name="effectiveDate"
                                    id="effectiveDate"
                                    placeholder="Effective Date"
                                    invalid={errors.effectiveDate} />
                                {errors.effectiveDate && <small className="text-danger">{errors.effectiveDate}</small>}
                            </FormGroup>
                        </Col>
                        <Col xs={12} md={6}>
                            <FormGroup>
                                <RequiredStar />
                                <Label for="classificationId">Classification</Label>
                                <SelectOptions
                                    styles={errors.classificationId && errors.classificationId.length > 0 ? SelectStyles.errorStyles() : {}}
                                    isClearable={true}
                                    disabled={isSubmitting}
                                    value={values.classificationId}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    id="classificationId"
                                    invalid={errors.classificationId && errors.classificationId.length > 0}
                                    stateFunc={(state) => { return { ...state.classifications } }}
                                    valueFunc={(record) => record.classificationId}
                                    labelFunc={(record) => record.name}
                                    sortFunc={(a, b) => a.name.localeCompare(b.name)}
                                />
                                {errors.classificationId && <small className="text-danger">{errors.classificationId}</small>}
                            </FormGroup>
                        </Col>
                    </Row>
                </Container>
                <SubmitButton outline className="float-right" disabled={isSubmitting || !dirty} color="success" type="submit">Submit</SubmitButton>
            </Form>
        </div>
    )
}

const validationSchema = Yup.object({
    effectiveDate: Yup.date()
        .required("Expense date is required")
        .typeError("Must be a date"),
    classificationId: Yup.string()
        .required("Classification is required")
})

const UserClassificationFormBasic = withFormik({
    displayName: "UserClassificationFormBasic",
    enableReinitialize: true,
    validationSchema: validationSchema,
    initialValues: ({ value }) => ({
        name: value.name
    }),
    handleSubmit: (values, { props, setSubmitting }) => {
        props.onSubmitUpdate(values)
        setSubmitting(false)
    }
})(BaseForm)

export default UserClassificationFormBasic