import ScopeConstants from "constants/ScopeConstants"
import { ScopeShape } from "@gman/gman-redux"
import PropTypes from "prop-types"

export const ReportSubContractorInvoicesScope = {
    key: ScopeConstants.REPORT_SUB_CONTRACTOR_INVOICES_UNPAID,
    selector: (state) => state.subContractorInvoicesReport,
    tokenSelector: (state) => state.authentication.token
}

ReportSubContractorInvoicesScope.propTypes = PropTypes.shape(ScopeShape).isRequired