import ScopeConstants from "constants/ScopeConstants"

export const EditDataScope = {
    key: ScopeConstants.EDIT_DATA,
    selector: (state) => state.editData,
    tokenSelector: (state) => state.authentication.token,
    filter: {
        client: "",
        unInvoiced: "",
        autoCompelete: ""
    }
}