import React, { useState } from "react"
import PropTypes from "prop-types"
import { TimesheetEntryShape } from "shapes/TimesheetEntryShape"
import TimeCell from "components/common/TimeCell"
import Moment from "moment"
import WorkItemCell from "components/common/WorkItemCell"
import { Input } from "reactstrap"
import ClientInvoiceStatusBadge from "components/common/ClientInvoiceStatusBadge"
import { withFormik } from "formik"
import InvoiceStatusConstants from "constants/InvoiceStatusConstants"

const BaseForm = (
    {
        index,
        values,
        onShowEdit,
        isOpneInModal,
        onCostItemStatusChanged,
        handleChange,
        setFieldValue
    }) => {
    let a = "costItemStatus_timesheet_modal"
    if (isOpneInModal) {
        a = "costItemStatus_timesheet_modal";
    } else {
        a = "costItemStatus_timesheet";
    }

    if (values[a + index] == undefined) {
        values[a + index] = values.costItemStatus;
    }

    //console.log(values["costItemStatus_timesheet" + index], values.comment);
    return (
        <tr>
            <td onClick={onShowEdit} style={{ whiteSpace: 'pre' }}> {values.timesheet.user.firstName + ' ' + values.timesheet.user.lastName} </td>
            <td onClick={onShowEdit} style={{ whiteSpace: 'pre' }}> {Moment(values.timesheet.weekEnding).format("DD/MM/YYYY")}</td>
            <td onClick={onShowEdit}>
                <div style={{width: '600px'}}>
                    {
                        values.workItem ?
                            <WorkItemCell
                                workItem={values.workItem}
                                projectColumnWidth={8}
                                projectCodeColumnWidth={4}
                                projectVariationColumnWidth={8}
                                projectCostCodeColumnWidth={4}/>
                            : ""
                    }
                </div>
            </td>
            <TimeCell onClick={onShowEdit} value={values.saturdayHours} className="weekend" style={{ whiteSpace: 'pre'}} />
            <TimeCell onClick={onShowEdit} value={values.sundayHours} className="weekend" style={{ whiteSpace: 'pre' }}/>
            <TimeCell onClick={onShowEdit} value={values.mondayHours} style={{ whiteSpace: 'pre' }}/>
            <TimeCell onClick={onShowEdit} value={values.tuesdayHours} style={{ whiteSpace: 'pre' }}/>
            <TimeCell onClick={onShowEdit} value={values.wednesdayHours} style={{ whiteSpace: 'pre' }}/>
            <TimeCell onClick={onShowEdit} value={values.thursdayHours} style={{ whiteSpace: 'pre' }}/>
            <TimeCell onClick={onShowEdit} value={values.fridayHours} style={{ whiteSpace: 'pre' }}/>
            <td className="weekend">
                {
                    (
                        getNumber(values.saturdayHours) +
                        getNumber(values.sundayHours) +
                        getNumber(values.mondayHours) +
                        getNumber(values.tuesdayHours) +
                        getNumber(values.wednesdayHours) +
                        getNumber(values.thursdayHours) +
                        getNumber(values.fridayHours)
                    ).toFixed(2)
                }
            </td>
            <td onClick={onShowEdit}>
                <div style={{ width: '225px' }}>
                    {values.comment }
                </div>
            </td>
            {/* <td>
                {values.costRate == null ? "" : (getNumber(values.costRate)).toFixed(2)}
            </td> */}
            <td>
                {values.chargeRate == null ? "" : (getNumber(values.chargeRate)).toFixed(2)}
            </td>
            <td>
                {values.chargeRate == null ?
                    "" :
                    ((
                        getNumber(values.saturdayHours) +
                        getNumber(values.sundayHours) +
                        getNumber(values.mondayHours) +
                        getNumber(values.tuesdayHours) +
                        getNumber(values.wednesdayHours) +
                        getNumber(values.thursdayHours) +
                        getNumber(values.fridayHours)
                    ) * getNumber(values.chargeRate)).toFixed(2)
            }
            </td>
            <td className="text-center">
                <Input
                    type="radio"
                    value={0}
                    style={{ position: 'unset', margin: 0 }}
                    name={a + index}
                    checked={values[a + index] == 0 ? true : false}
                    disabled={values.clientInvoice && values.clientInvoice.status != InvoiceStatusConstants.DRAFT}
                    onChange={
                        (e) => {
                            handleChange(e);
                            onCostItemStatusChanged({
                                timesheetEntryId: values.timesheetEntryId,
                                costItemStatus: 0
                            });
                            setFieldValue(a + index, 0);
                        }
                    }/>
            </td>
            <td className="text-center">
                <Input
                    type="radio"
                    value={1}
                    style={{ position: 'unset', margin: 0 }}
                    name={a + index}
                    checked={values[a + index] == 1 ? true : false}
                    disabled={values.clientInvoice && values.clientInvoice.status != InvoiceStatusConstants.DRAFT}
                    onChange={
                        (e) => {
                            handleChange(e);
                            onCostItemStatusChanged({
                                timesheetEntryId: values.timesheetEntryId,
                                costItemStatus: 1
                            });
                            setFieldValue(a + index, 1);
                        }
                    } />
            </td>
            <td className="text-center">
                <Input
                    type="radio"
                    value={2}
                    style={{ position: 'unset', margin: 0 }}
                    name={a + index}
                    checked={values[a + index] == 2 ? true : false}
                    disabled={values.clientInvoice && values.clientInvoice.status != InvoiceStatusConstants.DRAFT}
                    onChange={
                        (e) => {
                            handleChange(e);
                            onCostItemStatusChanged({
                                timesheetEntryId: values.timesheetEntryId,
                                costItemStatus: 2
                            });
                            setFieldValue(a + index, 2);
                        }
                    } />
            </td>
            <td>{values.clientInvoice ? values.clientInvoice.number : ""}</td>
            <td>
                {
                    values.clientInvoice ? <ClientInvoiceStatusBadge value={values.clientInvoice.status} /> : ""
                }
            </td>
        </tr>
    )
}

const getNumber = (value) => {
    var number = parseFloat(value)
    if (isNaN(number))
        return 0

    return number
}

//TimesheetEntryEditRow.propTypes = PropTypes.shape(TimesheetEntryShape).isRequired

const TimesheetEntryEditRow = withFormik({
    displayName: "TimesheetEntryEditRow",
    enableReinitialize: true
})(BaseForm)


export default TimesheetEntryEditRow 