import { connect } from "react-redux"
import TimesheetEntryList from "components/timesheetEntries/layout/TimesheetEntryList"
import { DispatchActions, FormActions, FormEvents } from "@gman/gman-redux"
import { TimesheetEntryScope } from "components/timesheetEntries/redux/TimesheetEntryScope"
import { CostCodeScope } from "components/costCodes/redux/CostCodeScope"
import { CostCodeGroupAllScope } from "components/costCodeGroups/redux/CostCodeGroupAllScope"
import { ExpenseTypeScope } from "components/expenseTypes/redux/ExpenseTypeScope"
import { LeaveScope } from "components/leave/redux/LeaveScope"
import { ProjectAnyScope } from "components/projects/redux/ProjectAnyScope"
import { ProposalAnyScope } from "components/proposals/redux/ProposalAnyScope"
import { TimesheetEntryHistoryScope } from "components/timesheetEntriesHistory/redux/TimesheetEntryHistoryScope"
import { VariationScope } from "components/variations/redux/VariationScope"
import { ExpenseCalculations } from "components/expenses/redux/ExpenseCalculations"
import ExpenseStatusConstants from "constants/ExpenseStatusConstants"
import ProjectStatusConstants from "constants/ProjectStatusConstants"
import ProposalStatusConstants from "constants/ProposalStatusConstants"

const mapDispatchToProps = (dispatch) => {
    var scope = TimesheetEntryScope
    return {
        ...FormEvents.getConfirmEventsForModalsOnAll(dispatch, scope),
        ...FormEvents.getFeedbackEvents(dispatch, scope),
        ...FormEvents.getModalEventsForModalsOnAll(dispatch, scope),
        ...FormEvents.getPaginatorEvents(dispatch, scope),
        ...FormEvents.getRecordStateEvents(dispatch, scope),
        ...FormEvents.getTableHeaderRowEvents(dispatch, scope),
        ...FormEvents.getRedirectEvents(dispatch, scope),
        ...ExpenseCalculations.getExpenseCalculation(dispatch, scope),
        onLoadDependentData: () => {
            dispatch(FormActions.fetchAll(CostCodeScope))
            dispatch(FormActions.fetchAll(CostCodeGroupAllScope))
            dispatch(FormActions.fetchAll(ExpenseTypeScope))
            dispatch(FormActions.fetchAll(ProjectAnyScope, { status: ProjectStatusConstants.INPROGRESS }))
            dispatch(FormActions.fetchAll(ProposalAnyScope, { status: ProposalStatusConstants.INPROGRESS }))
            dispatch(FormActions.fetchAll(TimesheetEntryHistoryScope))
            dispatch(FormActions.fetchAll(VariationScope))
            dispatch(FormActions.fetchRecords(LeaveScope, LeaveScope.fetchEndpoint, { page: 1, pageSize: 100000, orderBy: "DisplayOrder" }))
        },
        onAddLinkedExpense: workItem => {
            var record = {
                workItemId: workItem && workItem.workItemId,
                workItem: { ...workItem, costCodeGroupId: workItem && workItem.costCodeGroupId },
                costCode: { costCodeId: workItem && workItem.costCode && workItem.costCode.costCodeId },
                variation: workItem && workItem.variation
            }
            dispatch({ ...DispatchActions.scopedDispatch(scope.key, "PRE_FILL_LINKED_EXPENSE"), record: record })
            dispatch(FormActions.showModal(scope, "LINKED_EXPENSE"))
        },
        onSubmitLinkedExpense: records => {
            records.forEach(record => {
                record.status = ExpenseStatusConstants.DRAFT
                dispatch(FormActions.doPost(scope, record, "/api/Expense/UpdateMine", "The expense was updated successfully.", false))
                    .then((result) => {
                        if (result && result.error) { }
                        else {
                            record = result.record
                            record.status = ExpenseStatusConstants.SUBMITTED
                            dispatch({ ...DispatchActions.scopedDispatch(TimesheetEntryScope.key, "PRE_FILL_LINKED_EXPENSE"), record: record })
                            dispatch(FormActions.doPost(scope, record, "/api/Expense/SubmitMine", "The expense was submitted successfully.", true))
                                .then((result) => {
                                    dispatch(FormActions.hideModal(scope, "SUBMIT"))
                                    if (result && result.error) {
                                        record.status = ExpenseStatusConstants.DRAFT
                                        dispatch(FormActions.loadRecord(scope, record))
                                    }
                                    else {
                                        dispatch(FormActions.hideModal(scope, "LINKED_EXPENSE"))
                                    }
                                })
                        }
                    })
            })
        },
        onAddHistorical: record => {
            return dispatch(FormActions.doPost(scope, record.workItemEntries, "/api/TimesheetEntryHistory/Append", "The items were appended successfully.", true))
                .then(() => {
                    dispatch(FormActions.clearSearch(scope, { timesheetId: record.timesheetId }))
                    dispatch(FormActions.hideModal(scope, "ENTRY_HISTORY"))
                })
        },
        onAddLastWeek: record => {
            return dispatch(FormActions.doPost(scope, record.timesheetId, "/api/TimesheetEntryLastWeek/Append", "The items from last week were appended successfully.", true))
            .then(() => {
                dispatch(FormActions.clearSearch(scope, { timesheetId: record.timesheetId }))
                dispatch(FormActions.hideModal(scope, "LAST_ENTRY_HISTORY"))
            })
        },
        onTimeSheetEditRowChange: record => {
            if (record.workItem && (!record.workItem.costCode || !record.workItem.costCode.costCodeId)) {
                record.workItem.costCode = null;
            }

            if (record.workItem && (!record.workItem.variation || !record.workItem.variation.variationId)) {
                record.workItem.variation = null;
            }
            return dispatch(FormActions.updateRecord(scope, record));
        }
    }
}

const TimesheetEntryListContainer = connect(
    (state, ownProps) => {
        return {
            sourceData: TimesheetEntryScope.selector(state),
            timesheetId: ownProps.timesheetId,
            status: ownProps.status,
            weekEnding: ownProps.weekEnding,
            linkedExpense: state.linkedExpense
        }
    },
    mapDispatchToProps
)(TimesheetEntryList)

export { TimesheetEntryListContainer }