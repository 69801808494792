import React from "react"
import PropTypes from "prop-types"
import ActiveSort from "components/common/ActiveSort"
import { AutoComplete } from "@gman/gman-react-bootstrap"
import Icon from "components/common/Icon"

const TableHeaderRow = props => {
    const {
        disabled,
        headers,
        onSortBy,
        onUpdateAutoComplete
    } = props
    return (
        <tr>
            {headers.map((header, index) => (
                <th key={index} width={header.width ? header.width : "auto"} className={header.className ? header.className : ""}>
                    {
                        onSortBy ?
                            <ActiveSort className={props.className} onSortBy={() => onSortBy(header.sortBy)} disabled={disabled}>{header.label}</ActiveSort>
                            :
                            <div>{header.label}</div>
                    }
                </th>
            ))
            }
            {onUpdateAutoComplete ?
                <th width={"200px"}>
                    <AutoComplete onSearch={onUpdateAutoComplete} disabled={disabled}>
                        <Icon search />
                    </AutoComplete>
                </th>
                : <th width="auto" />
            }
        </tr>
    )
}

TableHeaderRow.RequiredProps = props => {
    return {
        onUpdateAutoComplete: props.onUpdateAutoComplete,
        onSortBy: props.onSortBy
    }
}

TableHeaderRow.propTypes = {
    disabled: PropTypes.bool.isRequired,
    onUpdateAutoComplete: PropTypes.func,
    headers: PropTypes.arrayOf(
        PropTypes.shape({
            sortBy: PropTypes.string,
            label: PropTypes.string.isRequired,
        })
    )
}

export default TableHeaderRow