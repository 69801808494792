import React from "react"
import { Link } from "react-router-dom"
import Moment from "moment"
import ActiveCell from "components/common/ActiveCell"
import ClientInvoiceStatusBadge from "components/common/ClientInvoiceStatusBadge"
import { AuthorizedLink, AuthorizedInvoiceToolbar, AuthorizedButton } from "components/authentication/controls/AuthorizedControls"
import InvoiceStatusConstants from "constants/InvoiceStatusConstants"
import Currency from "components/common/Currency"
import {
    Button,
    ButtonGroup
} from "reactstrap"
import { MdDelete } from "react-icons/md"
import { FaPen, FaDollarSign, FaDownload, FaRegPaperPlane } from "react-icons/fa"

const InvoiceLink = AuthorizedLink(p => p.canMarkItemsAsPaid)
const Toolbar = AuthorizedInvoiceToolbar(p => p.canMarkItemsAsPaid)
const PublishInvoiceButton = AuthorizedButton(p => p.canPublishClientInvoice)
const ReturnToDraftInvoiceButton = AuthorizedButton(p => p.canPublishClientInvoice)
const DownloadInvoiceDataButton = AuthorizedButton(p => p.canDownloadClientInvoiceData)
const DeleteInvoiceButton = AuthorizedButton(p => p.canDeleteClientInvoiceData)
const MarkAsPaidButton = AuthorizedButton(p => p.canMarkItemsAsPaid)

const ClientInvoiceRow = (
    {
        clientInvoiceId,
        client,
        project,
        invoiceAmount,
        number,
        status,
        dateCreated,
        datePaid,
        dateIssued,
        onShowDelete,
        onShowMarkPaid,
        onEditInoviceAmount,
        onInovicePublish,
        onInoviceReturnToDraft,
        amountBasedOnCosts,
        onDownLoadClientInvoiceReport,
        onInvoiceNumberClick,
        active
    }) => (
    <tr>
        <ActiveCell active={active} style={{ width: '200px' }}>
            {status !== InvoiceStatusConstants.DRAFT ?
             <a href="javascript:void(0)">
                {number}
             </a>
            :
            <a href="javascript:void(0)" onClick={onInvoiceNumberClick}>
                 {number}
            </a>
            }
           
        </ActiveCell>
        <td style={{ width: '150px' }}>
            <p>
                {amountBasedOnCosts ? Currency(amountBasedOnCosts) : ""}
            </p>
        </td>
        <td style={{ width: '150px' }}>
            <p>
                {invoiceAmount ? Currency(invoiceAmount) : ""} &nbsp;

                {
                    status == InvoiceStatusConstants.DRAFT ?
                        <Button
                    
                            outline
                            color="info"
                            size="sm"
                            onClick={onEditInoviceAmount}
                            disabled={status !== InvoiceStatusConstants.DRAFT}
                        ><FaPen /> Edit
                        </Button>:""
                }
               
            </p>
        </td>
        <td style={{ width: '125px' }}>{Moment(dateCreated).format("DD/MM/YYYY")}</td>
       
        <td style={{ width: '125px' }}>{dateIssued && Moment(dateIssued).format("DD/MM/YYYY")}</td>
        <td style={{ width: '125px' }}>{datePaid && Moment(datePaid).format("DD/MM/YYYY")}</td>
        <td style={{ width: '100px' }}>
            <ClientInvoiceStatusBadge value={status} />
        </td>
        <td style={{ width: '300px' }}>
            <ButtonGroup size="sm" className="float-right">
                {
                    status === InvoiceStatusConstants.DRAFT  ?
                        <PublishInvoiceButton
                            outline
                            disabled={status === InvoiceStatusConstants.PAID}
                            color="info"
                            onClick={onInovicePublish}>
                            <FaRegPaperPlane /> Publish
                    </PublishInvoiceButton> : ""
                }

                {
                    status === InvoiceStatusConstants.PUBLISH ?
                    <ReturnToDraftInvoiceButton
                            outline
                            disabled = {status === InvoiceStatusConstants.PAID}
                            color="warning"
                            onClick={onInoviceReturnToDraft}>
                            <FaRegPaperPlane /> Return to draft
                    </ReturnToDraftInvoiceButton> : ""
                }

                <DownloadInvoiceDataButton
                    outline
                    color="info"
                    onClick={onDownLoadClientInvoiceReport}>
                    <FaDownload /> Download
                </DownloadInvoiceDataButton>

                {
                    status != InvoiceStatusConstants.PAID ?
                        <MarkAsPaidButton
                            outline
                            disabled={status === InvoiceStatusConstants.PAID || status === InvoiceStatusConstants.DRAFT}
                            color="success"
                            onClick={onShowMarkPaid}>
                            <FaDollarSign /> Mark Paid 
                        </MarkAsPaidButton> : ""
                }

                {
                    status === InvoiceStatusConstants.DRAFT ?
                        <DeleteInvoiceButton
                            outline
                            disabled={status === InvoiceStatusConstants.PAID}
                            color="danger"
                            onClick={onShowDelete}>
                            <MdDelete /> Delete
                        </DeleteInvoiceButton> : ""
                }


            </ButtonGroup>
        </td>
    </tr>
)

export default ClientInvoiceRow