import ScopeConstants from "constants/ScopeConstants"
import { FormActionReducer } from "@gman/gman-redux"

export const OfficesReducer = (
  state = {
    ...FormActionReducer.defaultPageState(),
    ...FormActionReducer.defaultSearchOptions("Name"),
    ...FormActionReducer.defaultRecordState()
  },
  action) => {

  if (action.scope === ScopeConstants.OFFICE) {
    return FormActionReducer.reduce(action, state)
  }

  return state
}