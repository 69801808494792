import { connect } from "react-redux"
import ProjectCardExtended from "components/projects/layout/ProjectCardExtended"
import ProjectList from "components/projects/layout/ProjectList"
import { FormActions, FormEvents, ModalConstants, DispatchActions } from "@gman/gman-redux"
import { ProjectAnyScope } from "components/projects/redux/ProjectAnyScope"
import { ProjectMineScope } from "components/projects/redux/ProjectMineScope"
import { CostCodeScope } from "components/costCodes/redux/CostCodeScope"
import { CostCodeGroupAllScope } from "components/costCodeGroups/redux/CostCodeGroupAllScope"
import { ClientScope } from "components/clients/redux/ClientScope"
import { ManagerScope } from "components/users/redux/ManagerScope"
import { OfficeScope } from "components/offices/redux/OfficeScope"
import { ClientInvoiceScope } from "components/clientInvoices/redux/ClientInvoiceScope"
import { ProjectFinancialScope } from "components/projectsFinancial/redux/ProjectFinancialScope"
import { SubContractorInvoiceScope } from "components/subContractorInvoices/redux/SubContractorInvoiceScope";
import InvoiceTimingTypeConstants from "constants/InvoiceTimingTypeConstants";

const mapDispatchToPropsMine = (dispatch) => { return mapDispatchToMineProps(dispatch, ProjectMineScope) }

const mapDispatchToPropsAny = (dispatch) => { return mapDispatchToProps(dispatch, ProjectAnyScope) }


const mapDispatchToMineProps = (dispatch, scope) => {
    return {
        ...FormEvents.getFeedbackEvents(dispatch, scope),
        ...FormEvents.getModalEventsForModalsOnNewOnly(dispatch, scope),
        ...FormEvents.getPaginatorEvents(dispatch, scope),
        ...FormEvents.getRecordStateEvents(dispatch, scope),
        ...FormEvents.getTableHeaderRowEvents(dispatch, scope),
        ...FormEvents.getTabEvents(dispatch, scope),
        ...FormEvents.getRedirectEvents(dispatch, scope),
        onLoadDependentData: () => {
            dispatch(FormActions.fetchAll(CostCodeScope))
            dispatch(FormActions.fetchAll(CostCodeGroupAllScope))
            dispatch(FormActions.fetchAll(ClientScope))
            dispatch(FormActions.fetchAll(ManagerScope))
            dispatch(FormActions.fetchAll(OfficeScope))
        },
        onShowAllRecords: () => {
            dispatch({ ...DispatchActions.scopedDispatch(scope.key, "SHOW_ALL") });
            dispatch(FormActions.fetchRecords(scope));
        },
        onPageSizeChange: (pageSize) => {
            dispatch({ ...DispatchActions.scopedDispatch(scope.key, "CHANGE_PAGE_SIZE"), pageSize: pageSize });
            dispatch(FormActions.fetchRecords(scope));
        },
        confirmEvents: {
            ...FormEvents.getConfirmEventsForModalsOnNewOnly(dispatch, scope).confirmEvents,
            onSubmitUpdate: (record, isNew = false) => {
                var redirect = isNew ? { urlFunc: (o) => "/projectDetails/" + o.projectId } : {}
                dispatch(FormActions.doPost(scope, record, scope.updateEndpoint, "The record was updated successfully.", true, redirect))
                dispatch(FormActions.hideModal(scope, ModalConstants.UPDATE))
            },
            onConfirmPublish: record => {
                var redirect = { urlFunc: () => "/project/any/" }
                record.invoiceTimingType = record.invoiceTimingType == "numberedDayOfMonth" ? InvoiceTimingTypeConstants.NumberedDayOfMonth : InvoiceTimingTypeConstants.WeeklyDayOfMonth;
                console.log("onConfirmPublish-record",record);
                dispatch(FormActions.doPost(scope, record, scope.publishEndpoint, "The record was published successfully.", true, redirect))
                var abc = FormActions.hideModal(scope, ModalConstants.PUBLISH);
                dispatch(abc);
            }
        },
        onTabChange: tabIndex => {
            dispatch(FormActions.changeTab(scope, tabIndex));
            dispatch({ ...DispatchActions.scopedDispatch(ClientInvoiceScope.key, "CLIENT_INVOICE_COST_ITEM_DATE"), invoiceDate: '' })
        },
        onloadClients:()=>{
            dispatch(FormActions.fetchAll(ClientScope));
        },
        reloadProjectFinancial: (projectId) => {
            return dispatch(FormActions.fetchSingle(ProjectFinancialScope, projectId));
        },
        reloadSubcontractorInvoice:()=>{
            return dispatch(FormActions.fetchAll(SubContractorInvoiceScope));
        }
    }
}

const mapDispatchToProps = (dispatch, scope) => {
    return {
        ...FormEvents.getFeedbackEvents(dispatch, scope),
        ...FormEvents.getModalEventsForModalsOnNewOnly(dispatch, scope),
        ...FormEvents.getPaginatorEvents(dispatch, scope),
        ...FormEvents.getRecordStateEvents(dispatch, scope),
        ...FormEvents.getTableHeaderRowEvents(dispatch, scope),
        ...FormEvents.getTabEvents(dispatch, scope),
        ...FormEvents.getRedirectEvents(dispatch, scope),
        onLoadDependentData: () => {
            dispatch(FormActions.fetchAll(CostCodeScope))
            dispatch(FormActions.fetchAll(CostCodeGroupAllScope))
            dispatch(FormActions.fetchAll(ClientScope))
            dispatch(FormActions.fetchAll(ManagerScope))
            dispatch(FormActions.fetchAll(OfficeScope))
        },
        onPageSizeChange: (pageSize) => {
            dispatch({ ...DispatchActions.scopedDispatch(scope.key, "CHANGE_PAGE_SIZE"), pageSize: pageSize });
            dispatch(FormActions.fetchRecords(scope));
        },
        confirmEvents: {
            ...FormEvents.getConfirmEventsForModalsOnNewOnly(dispatch, scope).confirmEvents,
            onSubmitUpdate: (record, isNew = false) => {
                var redirect = isNew ? { urlFunc: (o) => "/projectDetails/" + o.projectId } : {}
                dispatch(FormActions.doPost(scope, record, scope.updateEndpoint, "The record was updated successfully.", true, redirect))
                dispatch(FormActions.hideModal(scope, ModalConstants.UPDATE))
            },
            onConfirmPublish: record => {
                record.invoiceTimingType = record.invoiceTimingType == "numberedDayOfMonth" ? InvoiceTimingTypeConstants.NumberedDayOfMonth : InvoiceTimingTypeConstants.WeeklyDayOfMonth;
                console.log("onConfirmPublish-record",record);              
                var redirect = { urlFunc: () => "/project/any/" }
                dispatch(FormActions.doPost(scope, record, scope.publishEndpoint, "The record was published successfully.", true, redirect))
                dispatch(FormActions.hideModal(scope, ModalConstants.PUBLISH))
            }
        },
        onTabChange: tabIndex => {
            dispatch(FormActions.changeTab(scope, tabIndex));
            dispatch({ ...DispatchActions.scopedDispatch(ClientInvoiceScope.key, "CLIENT_INVOICE_COST_ITEM_DATE"), invoiceDate: '' })
        },
        onloadClients:()=>{
            dispatch(FormActions.fetchAll(ClientScope));
        },
        reloadProjectFinancial: (projectId) => {
            return dispatch(FormActions.fetchSingle(ProjectFinancialScope, projectId));
        },
        reloadSubcontractorInvoice:()=>{
            return dispatch(FormActions.fetchAll(SubContractorInvoiceScope));
        },
    }
}

const ProjectListMineContainer = connect(
    state => {
        return { sourceData: ProjectMineScope.selector(state) }
    },
    mapDispatchToPropsMine
)(ProjectList)

const ProjectListAnyContainer = connect(
    (state, ownProps) => {console.log("ProjectListAnyContainer",ownProps); return { sourceData: ProjectAnyScope.selector(state),client:ownProps.client} },
    mapDispatchToPropsAny
)(ProjectList)

const DetailsContainer = (readOnly) =>
    connect(
        (state, ownProps) => {
            return {
                sourceData: ProjectAnyScope.selector(state),
                projectId: ownProps.projectId,
                readOnly: readOnly
            }
        },
        mapDispatchToPropsAny
    )(ProjectCardExtended)

const ProjectEditContainer = DetailsContainer(false)

const ProjectReadContainer = DetailsContainer(true)

export {
    ProjectEditContainer,
    ProjectReadContainer,
    ProjectListMineContainer,
    ProjectListAnyContainer
}