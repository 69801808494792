import React from "react"
import { Link } from "react-router-dom"
import Moment from "moment"
import ActiveCell from "components/common/ActiveCell"
import InvoiceStatusBadge from "components/common/InvoiceStatusBadge"
import { AuthorizedLink, AuthorizedInvoiceToolbar, AuthorizedButton } from "components/authentication/controls/AuthorizedControls"
import InvoiceStatusConstants from "constants/InvoiceStatusConstants"
import Currency from "components/common/Currency"
import { Button, ButtonGroup } from "reactstrap"
import { MdModeEdit, MdDelete } from "react-icons/md"
import { FaDollarSign, FaThumbsUp, FaThumbsDown } from "react-icons/fa"
import ClientInvoiceStatusBadge from "components/common/ClientInvoiceStatusBadge"

const InvoiceLink = AuthorizedLink(p => p.canWriteSubContractorInvoice)
const Toolbar = AuthorizedInvoiceToolbar(p => p.canMarkSubContractorInvoiceAsPaid)

const SubContractorInvoiceRow = (
    {
        subContractorInvoiceId,
        subContractor,
        project,
        invoiceAmount,
        number,
        status,
        invoiceDate,
        paymentDate,
        dueDate,
        onShowDelete,
        onShowEdit,
        onShowMarkPaid,
        active,
        workflowPermissions,
        description,
        clientInvoice,
        markup,
        totalChargeToClient
    }) => {
    console.log(project , paymentDate , 'project');
    console.log(totalChargeToClient , 'totalChargeToClient');
    return (
        <tr>
            <ActiveCell active={active}>
                {paymentDate ?
                 <a href="javascript:void(0)">
                 {number}
               </a>
                 
                :
                <a href="javascript:void(0)" onClick={onShowEdit}>
                  {number}
                </a>
                }
               
            </ActiveCell>
            <ActiveCell active={active}>
                {subContractor}
            </ActiveCell>
            <ActiveCell active={active}>
                <Link to={"/projectSheet/" + (project && project.projectId)}>
                    {project && project.code}
                </Link>
            </ActiveCell>
            <ActiveCell active={active}>
                <Link to={"/projectSheet/" + (project && project.projectId)}>
                    {project && project.name}
                </Link>
            </ActiveCell>
            <td>{description || ''}</td>
            <td> {invoiceAmount != null ?Currency(invoiceAmount):Currency(0)}</td>
            <td>{markup}</td>
            <td>{Currency(totalChargeToClient)}</td>
            <td>{Moment(invoiceDate).format("DD/MM/YYYY")}</td>
            <td>{Moment(dueDate).format("DD/MM/YYYY")}</td>
            <td>{paymentDate && Moment(paymentDate).format("DD/MM/YYYY")}</td>
            <td>
                &nbsp;&nbsp;&nbsp;<InvoiceStatusBadge value={status} />
            </td>
            <td>
                {clientInvoice ? clientInvoice.number : ""}
            </td>
            <td>
                &nbsp;&nbsp;&nbsp;{
                    clientInvoice ? <ClientInvoiceStatusBadge value={clientInvoice.status} /> : ""
                }
            </td>
            <td>
                {
                    <div>
                        {
                            workflowPermissions ?
                                WorkflowToolbar(
                                    status,
                                    workflowPermissions,
                                    onShowDelete,
                                    onShowMarkPaid
                                )
                                : ""
                        }
                    </div>
                }
            </td>
        </tr>
    )
}

function WorkflowToolbar(
    isPaid,
    workflowPermissions,
    onShowDelete,
    onShowPaid,
) {
    return (
        <ButtonGroup size="sm" className="float-right">
            {
                workflowPermissions.canMarkPaid && onShowPaid ?
                    <Button outline size="sm" color="success" disabled={isPaid} onClick={onShowPaid}>
                        <FaDollarSign /> Mark Paid 
                    </Button>
                    : ""
            }
            {
                workflowPermissions.canEdit && onShowDelete ?
                    <Button outline size="sm" color="danger" disabled={isPaid} onClick={onShowDelete}>
                        <MdDelete /> Delete
                    </Button>
                    : ""
            }
        </ButtonGroup>
    )
}

export default SubContractorInvoiceRow 