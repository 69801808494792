import React from "react"
import Moment from "moment"
import { AuthorizedWorkbenchToolbar } from "components/authentication/controls/AuthorizedControls"

const Toolbar = AuthorizedWorkbenchToolbar(p => p.canViewWorkbenchExports)

const WorkbenchExportRow = (
    {
        code,
        name,
        exportDate,
        numberOfRows,
        isImported,
        disabled,
        onShowEdit,
        onShowDelete,
        onShowDownload
    }) => (
        <tr>
            <td>{code}</td>
            <td>{name}</td>
            <td>{Moment(exportDate).format("DD/MM/YYYY")}</td>
            <td>{numberOfRows}</td>
            <td>{isImported ? "Yes" : "No"}</td>
            <td>
                <Toolbar
                    disabled={disabled}
                    onShowEdit={onShowEdit}
                    onShowDelete={isImported ? null : onShowDelete}
                    onShowDownload={onShowDownload}
                />
            </td>
        </tr>
    )

export default WorkbenchExportRow 