import React from "react"
import Moment from "moment"
import { withFormik } from "formik"
import * as Yup from "yup"
import InvoiceStatusConstants from "constants/InvoiceStatusConstants"
import Icon from "components/common/Icon"
import RequiredStar from "components/common/RequiredStar"
import SelectStyles from "components/common/SelectStyles"
import { AuthorizedButton, AuthorizedSelectOption, AuthorizedInputField } from "components/authentication/controls/AuthorizedControls"
import {
    Col,
    Container,
    Form,
    FormGroup,
    Input,
    InputGroup,
    InputGroupText,
    InputGroupAddon,
    Label,
    Row
} from "reactstrap"

const SelectOptions = AuthorizedSelectOption(p => p.canMarkItemsAsPaid)
const InputField = AuthorizedInputField(p => p.canMarkItemsAsPaid)
const SubmitButton = AuthorizedButton(p => p.canMarkItemsAsPaid)

const BaseForm = props => {
    const {
        values,
        dirty,
        errors,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
        setFieldValue
    } = props
    var isPaid = isPaidStatus(values.status)
    return (
        <div>
            <Form noValidate onSubmit={handleSubmit}>
                <h6>
                    <Icon folderOpen />
                    Invoice Details
                </h6>
                <hr />
                <Container>
                    <Row>
                        <Col xs={12} md={6}>
                            <FormGroup>
                                <RequiredStar />
                                <Label for="number">Invoice Number</Label>
                                <InputField
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.number || ""}
                                    type="text"
                                    name="number"
                                    id="number"
                                    placeholder="Enter the invoice number"
                                    invalid={errors.number} />
                                {errors.number && <small className="text-danger">{errors.number}</small>}
                            </FormGroup>
                        </Col>
                        <Col xs={12} md={6}>
                            <FormGroup>
                                <RequiredStar />
                                <Label for="invoiceDate">Invoice Date</Label>
                                <InputField
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.invoiceDate ? Moment(values.invoiceDate).format("YYYY-MM-DD") : ""}
                                    type="date"
                                    name="invoiceDate"
                                    id="invoiceDate"
                                    placeholder="Invoice Date"
                                    invalid={errors.invoiceDate} />
                                {errors.invoiceDate && <small className="text-danger">{errors.invoiceDate}</small>}
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12} md={6}>
                            <FormGroup>
                                <RequiredStar />
                                <Label for="invoiceAmount">Amount</Label>
                                <InputGroup>
                                    <InputGroupAddon addonType="prepend">
                                        <InputGroupText>$</InputGroupText>
                                    </InputGroupAddon>
                                    <InputField
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.invoiceAmount || ""}
                                        type="text"
                                        name="invoiceAmount"
                                        id="invoiceAmount"
                                        placeholder="Enter the invoice amount"
                                        invalid={errors.invoiceAmount} />
                                </InputGroup>
                                {errors.invoiceAmount && <small className="text-danger">{errors.invoiceAmount}</small>}
                            </FormGroup>
                        </Col>
                        {
                            isPaid ?
                                <Col xs={12} md={6}>
                                    <FormGroup>
                                        <Label for="paymentDate">Date Paid</Label>
                                        <InputField
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.paymentDate ? Moment(values.paymentDate).format("YYYY-MM-DD") : ""}
                                            type="date"
                                            name="paymentDate"
                                            id="paymentDate"
                                            placeholder="Payment Date"
                                            invalid={errors.paymentDate} />
                                        {errors.paymentDate && <small className="text-danger">{errors.paymentDate}</small>}
                                    </FormGroup>
                                </Col>
                                : ""
                        }
                    </Row>
                    <Row>
                        <Col xs={12} md={6}>
                            <FormGroup>
                                <RequiredStar />
                                <Label for="clientId">Client</Label>
                                <SelectOptions
                                    styles={errors.clientId && errors.clientId.length > 0 ? SelectStyles.errorStyles() : {}}
                                    isClearable={true}
                                    disabled={isSubmitting}
                                    value={values.clientId}
                                    onChange={e => {
                                        handleChange(e)
                                        setFieldValue("projectId", null)
                                    }}
                                    onBlur={handleBlur}
                                    id="clientId"
                                    invalid={errors.clientId && errors.clientId.length > 0}
                                    stateFunc={(state) => { return { ...state.clients } }}
                                    valueFunc={(record) => record.clientId}
                                    labelFunc={(record) => record.name + " (" + record.code + ")"}
                                    sortFunc={(a, b) => a.name.localeCompare(b.name)}
                                />
                                {errors.clientId && <small className="text-danger">{errors.clientId}</small>}
                            </FormGroup>
                        </Col>
                        <Col xs={12} md={6}>
                            <FormGroup>
                                <RequiredStar />
                                <Label for="projectId">Project</Label>
                                <SelectOptions
                                    styles={errors.projectId && errors.projectId.length > 0 ? SelectStyles.errorStyles() : {}}
                                    isClearable={true}
                                    disabled={isSubmitting}
                                    value={values.projectId}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    id="projectId"
                                    invalid={errors.projectId && errors.projectId.length > 0}
                                    stateFunc={(state) => { return { ...state.staticProjects } }}
                                    valueFunc={(record) => record.projectId}
                                    labelFunc={(record) => record.name}
                                    filterFunc={(record) => (record.clientId === (values.clientId))}
                                    sortFunc={(a, b) => a.name.localeCompare(b.name)}
                                />
                                {errors.projectId && <small className="text-danger">{errors.projectId}</small>}
                            </FormGroup>
                        </Col>
                    </Row>
                </Container>
                <h6>
                    <Icon workflow />
                    Workflow
                </h6>
                <hr />
                <Container>
                    <Row>
                        <Col xs={12} md={6}>
                            <FormGroup>
                                <Label for="status">Invoice Status</Label>
                                <InputField
                                    onChange={e => {
                                        handleChange(e)
                                        setFieldValue("isPaid", isPaidStatus(e.target.value))
                                        setFieldValue("paymentDate", values.lastPaymentDate || "")
                                    }}
                                    onBlur={handleBlur}
                                    type="select"
                                    name="status"
                                    value={values.status || ""}
                                    invalid={errors.status}
                                    id="status"
                                >
                                    <option value={InvoiceStatusConstants.DRAFT}>Draft</option>
                                    <option value={InvoiceStatusConstants.PAID}>Paid</option>
                                </InputField>
                            </FormGroup>
                        </Col>
                    </Row>
                </Container>
                <Input
                    onChange={handleChange}
                    value={isPaid}
                    type="hidden"
                    name="isPaid"
                    id="isPaid"
                />
                <SubmitButton outline className="float-right" disabled={isSubmitting || !dirty} color="success" type="submit">Submit</SubmitButton>
            </Form>
        </div >
    )
}

function isPaidStatus(status) {
    var paidStatus =
        ((status || "").toString() === InvoiceStatusConstants.PAID.toString())
    return paidStatus
}

const validationSchema = Yup.object({
    number: Yup.string()
        .required("Number is required"),
    clientId: Yup.string()
        .required("Client is required")
        .nullable(),
    projectId: Yup.string()
        .required("Project is required")
        .nullable(),
    invoiceDate: Yup.date()
        .required("Invoice date is required")
        .typeError("Must be a date"),
    invoiceAmount: numberValidation(),
    paymentDate: Yup.date()
        .when("isPaid", {
            is: true,
            then: Yup.date()
                .required("Payment date is required")
                .typeError("Must be a date")
                .min(Yup.ref("invoiceDate"), "Payment date cannot precede Invoice date")
                .max(Moment(), "Payment date cannot be a future date")
        })
})

function numberValidation() {
    return Yup.number()
        .default(0)
        .nullable()
        .required("Value is required")
        .round()
        .positive("Must be positive")
        .typeError("Must be a number")
}

const ClientInvoiceFormExtended = withFormik({
    displayName: "ClientInvoiceFormExtended",
    enableReinitialize: true,
    validationSchema: validationSchema,
    mapPropsToValues: ({ ...props }) => ({
        ...props,
        invoiceDate: props.invoiceDate || "",
        paymentDate: props.paymentDate || "",
        isPaid: isPaidStatus(props.status)
    }),
    handleSubmit: (values, { props, setSubmitting }) => {
        props.onSubmitUpdate(values)
        setSubmitting(false)
    }
})(BaseForm)

export default ClientInvoiceFormExtended