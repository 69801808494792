import React, { Component } from "react";
import { Redirect } from "react-router";
import ReactDOM from 'react-dom';
import TableHeaderRow from "components/common/TableHeaderRow";
import PropTypes from "prop-types";
import { ButtonGroup, Table, Progress } from "reactstrap";
import TimesheetEntryRow from "./TimesheetEntryRow";
import TimesheetEntryForm from "../form/TimesheetEntryForm";
import TimesheetStatusConstants from "constants/TimesheetStatusConstants";
import ScopeConstants from "constants/ScopeConstants";
import { TimesheetEntryShape } from "shapes/TimesheetEntryShape";
import { ModalActions } from "@gman/gman-redux";
import { AuthorizedAddButton } from "components/authentication/controls/AuthorizedControls";
import ExpenseFormBasic from "components/expenses/form/ExpenseFormBasic";
import { TimesheetEntryHistoryContainer } from "components/timesheetEntriesHistory/redux/TimesheetEntryHistoryContainers";
import FeedbackAlert from "components/common/FeedbackAlert";
import { FeedbackConstants } from "@gman/gman-redux";
import {
    ConfirmDelete,
    FormModal,
    Paginator,
    SpinnerOverlay
} from "@gman/gman-react-bootstrap";
import { TimesheetEntryScope } from "../redux/TimesheetEntryScope";
import moment from 'moment';

const AddButton = AuthorizedAddButton(p => p.canCompleteOwnTimesheet);

class TimesheetEntryList extends Component {
    state = {
        isRowChanges: false
    }
    componentDidMount() {
        this.props.recordStateEvents.onClearSearch({
            timesheetId: this.props.timesheetId
        });
        this.props.onLoadDependentData();
        document.addEventListener('mousedown', this.handleOutsideClick);
    }

    componentDidUpdate(prevProps) {
        if (this.props.timesheetId !== prevProps.timesheetId) {
            this.props.recordStateEvents.onClearSearch({
                timesheetId: prevProps.timesheetId
            });
        }
    }

    componentWillUnmount = () => {
        this.props.feedbackEvents.onFlushFeedback();
        this.props.redirectEvents.onClearRedirect();
        document.removeEventListener('mousedown', this.handleOutsideClick);
    };

    reduceHours = records => {
        return records.reduce(
            (prev, next) =>
                prev +
                next.saturdayHours +
                next.sundayHours +
                next.mondayHours +
                next.tuesdayHours +
                next.wednesdayHours +
                next.thursdayHours +
                next.fridayHours,
            0
        );
    };

    hoursCaption = (description, value) => {
        return value > 0 ?
        (
            <span>
                {value >= 4 ?
                    (
                        <small>
                            <b>{description}</b>
                        </small>
                    ) : ("")
                }
                <small>&nbsp;{value} hr</small>
            </span>
        ) : ("");
    };
    handleLastWeekAdd = () => {
        this.props.onAddLastWeek({ timesheetId: this.props.timesheetId });
    };

    handleHoursChange = (e) => {
        let { name, value } = e.target;
        var number = parseFloat(value)
        this.props.sourceData.recordToUpdate[name] = number;
        this.setState({ isRowChanges: true });
    }

    handleCommentChange = (e) => {
        let { name, value } = e.target;
        this.props.sourceData.recordToUpdate[name] = value;
        this.setState({ isRowChanges: true });
    }

    handleWorkItemChange = (workItem) => {
        this.props.sourceData.recordToUpdate['workItem'] = workItem;
        this.setState({ isRowChanges: true });
    }

    handleOutsideClick = (e) => {
        let that = this;
        let modals = that.props.sourceData.modals;
        const domNode = ReactDOM.findDOMNode(this);
        if (!ModalActions.IsVisible(modals, "SELECT_WORK_ITEM") && 
            !ModalActions.IsVisible(modals, "LAST_ENTRY_HISTORY") && 
            !ModalActions.IsDeleteVisible(modals) &&
            !ModalActions.IsVisible(modals, "LINKED_EXPENSE") && 
            !ModalActions.IsVisible(modals, "ENTRY_HISTORY") &&
            !ModalActions.IsVisible(modals, "LAST_ENTRY_HISTORY")) {
            if (!domNode || !domNode.contains(e.target)) {
                if (ModalActions.IsUpdateVisible(modals)) {
                    if (this.state.isRowChanges) {
                        let sourceData = this.props.sourceData;
                        sourceData.recordToUpdate.mondayHours = sourceData.recordToUpdate.mondayHours || 0.0;
                        sourceData.recordToUpdate.tuesdayHours = sourceData.recordToUpdate.tuesdayHours || 0.0;
                        sourceData.recordToUpdate.wednesdayHours = sourceData.recordToUpdate.wednesdayHours || 0.0;
                        sourceData.recordToUpdate.thursdayHours = sourceData.recordToUpdate.thursdayHours || 0.0;
                        sourceData.recordToUpdate.fridayHours = sourceData.recordToUpdate.fridayHours || 0.0;
                        sourceData.recordToUpdate.saturdayHours = sourceData.recordToUpdate.saturdayHours || 0.0;
                        sourceData.recordToUpdate.sundayHours = sourceData.recordToUpdate.sundayHours || 0.0;
                        this.props.onTimeSheetEditRowChange(sourceData.recordToUpdate)
                            .then((result) => {
                                that.props.modalEvents.onHideEdit();
                                that.setState({ isRowChanges: false });
                            });
                    } else {
                        that.props.modalEvents.onHideEdit();
                        that.setState({ isRowChanges: false });
                    }
                }
            }
        }
    }

    render() {
        var appSetting = window.AppSettings;
        var status = this.props.status;
        var sourceData = this.props.sourceData;
        if (sourceData.redirect) {
            return <Redirect to={sourceData.redirect} />;
        }

        var modals = sourceData.modals;
        var workflowPermissions = this.props.workflowPermissions;
        var confirmEvents = this.props.confirmEvents;
        var feedbackEvents = this.props.feedbackEvents;
        var hideButtons = this.props.hideButtons;
        var modalEvents = this.props.modalEvents;
        var paginatorEvents = this.props.paginatorEvents;
        var tableHeaderRowEvents = this.props.tableHeaderRowEvents;
        var weekstartDate = this.props.weekEnding ? moment(this.props.weekEnding).day(0) : '';

        var totalHours = 0.0;
        var leaveHours = 0.0;
        var projectHours = 0.0;
        var proposalHours = 0.0;

        var canEditApproved = workflowPermissions.canEditApproved ? true : false;

        if (sourceData.records) {
            var leave = sourceData.records.filter(function (e) {
                return e.workItem.type == ScopeConstants.LEAVE || e.workItem.clientId == appSetting.CmpClientId;
            });
            var projects = sourceData.records.filter(function (e) {
                return e.workItem.type == ScopeConstants.PROJECT_ANY && e.workItem.clientId != appSetting.CmpClientId;
            });
            var proposals = sourceData.records.filter(function (e) {
                return e.workItem.type == ScopeConstants.PROPOSAL_ANY && e.workItem.clientId != appSetting.CmpClientId;
            });

            leaveHours = this.reduceHours(leave);
            projectHours = this.reduceHours(projects);
            proposalHours = this.reduceHours(proposals);
            totalHours = (leaveHours + projectHours + proposalHours).toFixed(2);
        }
        return (
            <div>
                <SpinnerOverlay isLoading={sourceData.inProgress}>
                    <div className="text-center">
                        <Progress multi max={37.5}>
                            <Progress
                                max={37.5}
                                striped
                                bar
                                color="info"
                                value={projectHours}>
                                {this.hoursCaption("PROJECT", projectHours)}
                            </Progress>
                            <Progress
                                max={37.5}
                                striped
                                bar
                                color="secondary"
                                value={proposalHours}>
                                {this.hoursCaption("PROPOSAL", proposalHours)}
                            </Progress>
                            <Progress
                                max={37.5}
                                striped
                                bar
                                color="danger"
                                value={leaveHours}>
                                {this.hoursCaption("MISC", leaveHours)}
                            </Progress>
                        </Progress>
                    </div>
                    <br />
                    <Table
                        responsive
                        hover
                        size="sm"
                        bordered
                        className="timesheet-table">
                        <thead>
                            <TableHeaderRow
                                onSortBy={tableHeaderRowEvents.onSortBy}
                                disabled={sourceData.inProgress}
                                headers={[
                                    {
                                        sortBy: "ProjectId",
                                        label: "Project",
                                        className: "timesheet-work-item"
                                    },
                                    {
                                        sortBy: "SaturdayHours",
                                        label: "Sat " + (this.props.weekEnding ? moment(this.props.weekEnding).day(0).add(-1, 'days').format("Do") : ""),
                                        className: "timesheet-time weekend"
                                    },
                                    {
                                        sortBy: "SundayHours",
                                        label: "Sun " + (this.props.weekEnding ? moment(this.props.weekEnding).day(0).format("Do") : ""),
                                        className: "timesheet-time weekend"
                                    },
                                    {
                                        sortBy: "MondayHours",
                                        label: "Mon " + (this.props.weekEnding ? moment(this.props.weekEnding).day(0).add(1, 'days').format("Do") : ""),
                                        className: "timesheet-time"
                                    },
                                    {
                                        sortBy: "TuesdayHours",
                                        label: "Tue " + (this.props.weekEnding ? moment(this.props.weekEnding).day(0).add(2, 'days').format("Do") : ""),
                                        className: "timesheet-time"
                                    },
                                    {
                                        sortBy: "WednesdayHours",
                                        label: "Wed " + (this.props.weekEnding ? moment(this.props.weekEnding).day(0).add(3, 'days').format("Do") : ""),
                                        className: "timesheet-time"
                                    },
                                    {
                                        sortBy: "ThursdayHours",
                                        label: "Thu " + (this.props.weekEnding ? moment(this.props.weekEnding).day(0).add(4, 'days').format("Do") : ""),
                                        className: "timesheet-time"
                                    },
                                    {
                                        sortBy: "FridayHours",
                                        label: "Fri " + (this.props.weekEnding ? moment(this.props.weekEnding).day(0).add(5, 'days').format("Do") : ""),
                                        className: "timesheet-time"
                                    },
                                    { label: "Total", className: "timesheet-time weekend" },
                                    {
                                        sortBy: "Comment",
                                        label: "Comment",
                                        className: "timesheet-comments"
                                    }
                                ]}
                            />
                        </thead>
                        <tbody>
                            <tr>
                                <td colSpan={11}>
                                    <FeedbackAlert
                                        {...feedbackEvents}
                                        feedback={sourceData.feedback.filter(function (e) {
                                            return e.status !== FeedbackConstants.SUCCESS;
                                        })}
                                    />
                                </td>
                            </tr>
                            {sourceData.records.map((record, index) => {
                                return record.timesheetEntryId ===
                                    sourceData.recordToUpdate.timesheetEntryId &&
                                    ModalActions.IsUpdateVisible(modals) ? (
                                        <TimesheetEntryForm
                                            {...modalEvents}
                                            modals={modals}
                                            key={index}
                                            onSubmitUpdate={confirmEvents.onSubmitUpdate}
                                            onHide={modalEvents.onHideEdit}
                                            handleHoursChange={this.handleHoursChange}
                                            handleCommentChange={this.handleCommentChange}
                                            handleWorkItemChange={this.handleWorkItemChange}
                                            {...sourceData.recordToUpdate}/>
                                    ) : (
                                        <TimesheetEntryRow
                                            key={index}
                                            index={index}
                                            {...record}
                                            onShowDelete={() => modalEvents.onShowDelete(record)}
                                            onShowEdit={
                                                () => {
                                                    let that = this;
                                                    if (status !== TimesheetStatusConstants.COMPLETED || canEditApproved) {
                                                        if (ModalActions.IsUpdateVisible(modals) && that.state.isRowChanges) {
                                                            sourceData.recordToUpdate.mondayHours = sourceData.recordToUpdate.mondayHours || 0.0;
                                                            sourceData.recordToUpdate.tuesdayHours = sourceData.recordToUpdate.tuesdayHours || 0.0;
                                                            sourceData.recordToUpdate.wednesdayHours = sourceData.recordToUpdate.wednesdayHours || 0.0;
                                                            sourceData.recordToUpdate.thursdayHours = sourceData.recordToUpdate.thursdayHours || 0.0;
                                                            sourceData.recordToUpdate.fridayHours = sourceData.recordToUpdate.fridayHours || 0.0;
                                                            sourceData.recordToUpdate.saturdayHours = sourceData.recordToUpdate.saturdayHours || 0.0;
                                                            sourceData.recordToUpdate.sundayHours = sourceData.recordToUpdate.sundayHours || 0.0;
                                                            that.props.onTimeSheetEditRowChange(sourceData.recordToUpdate)
                                                                .then((result) => {
                                                                    modalEvents.onShowEdit(record);
                                                                    that.setState({ isRowChanges: false });
                                                                });
                                                        } else {
                                                            modalEvents.onShowEdit(record);
                                                            that.setState({ isRowChanges: false });
                                                        }
                                                    }
                                                }
                                            }
                                            onAddLinkedExpense={this.props.onAddLinkedExpense}
                                            workflowPermissions={workflowPermissions}
                                            disabled={
                                                sourceData.inProgress ||
                                                (status !== TimesheetStatusConstants.DRAFT &&
                                                    !canEditApproved)
                                            }/>
                                    );
                            })}
                            {!sourceData.recordToUpdate.timesheetEntryId &&
                                ModalActions.IsUpdateVisible(modals) ?
                                (
                                    <TimesheetEntryForm
                                        {...modalEvents}
                                        modals={modals}
                                        onSubmitUpdate={confirmEvents.onSubmitUpdate}
                                        onHide={modalEvents.onHideEdit}
                                        handleHoursChange={this.handleHoursChange}
                                        handleCommentChange={this.handleCommentChange}
                                        handleWorkItemChange={this.handleWorkItemChange}
                                        {...sourceData.recordToUpdate}/>
                                ) : ( <tr />)}
                        </tbody>
                        <tfoot>
                            <tr>
                                <th>Total</th>
                                <th className="weekend">
                                    {sourceData.records
                                        .reduce((prev, next) => prev + next.saturdayHours, 0)
                                        .toFixed(2)}
                                </th>
                                <th className="weekend">
                                    {sourceData.records
                                        .reduce((prev, next) => prev + next.sundayHours, 0)
                                        .toFixed(2)}
                                </th>
                                <th>
                                    {sourceData.records
                                        .reduce((prev, next) => prev + next.mondayHours, 0)
                                        .toFixed(2)}
                                </th>
                                <th>
                                    {sourceData.records
                                        .reduce((prev, next) => prev + next.tuesdayHours, 0)
                                        .toFixed(2)}
                                </th>
                                <th>
                                    {sourceData.records
                                        .reduce((prev, next) => prev + next.wednesdayHours, 0)
                                        .toFixed(2)}
                                </th>
                                <th>
                                    {sourceData.records
                                        .reduce((prev, next) => prev + next.thursdayHours, 0)
                                        .toFixed(2)}
                                </th>
                                <th>
                                    {sourceData.records
                                        .reduce((prev, next) => prev + next.fridayHours, 0)
                                        .toFixed(2)}
                                </th>
                                <th className="weekend">{totalHours}</th>
                            </tr>
                        </tfoot>
                    </Table>
                    <Paginator
                        {...paginatorEvents}
                        searchOptions={sourceData.searchOptions}
                    />
                    {hideButtons ? null : (
                        <ButtonGroup className="float-right">
                            {(status === TimesheetStatusConstants.DRAFT &&
                                workflowPermissions &&
                                workflowPermissions.canEdit) ||
                                canEditApproved ?
                                (
                                    <AddButton
                                        disabled={
                                            sourceData.inProgress ||
                                            ModalActions.IsUpdateVisible(modals)
                                        }
                                        onClick={() => {
                                            modalEvents.onShowNew({
                                                ...TimesheetEntryScope.defaultRecord,
                                                timesheetId: this.props.timesheetId
                                            });
                                            modalEvents.onShowModal("SELECT_WORK_ITEM");
                                        }}
                                        caption="Add Project"
                                    />
                                ) : ("")}
                            {(status === TimesheetStatusConstants.DRAFT &&
                                workflowPermissions &&
                                workflowPermissions.canEdit) ||
                                canEditApproved ?
                                (
                                    <AddButton
                                        disabled={
                                            sourceData.inProgress ||
                                            ModalActions.IsUpdateVisible(modals)
                                        }
                                        onClick={() => modalEvents.onShowModal("ENTRY_HISTORY")}
                                        caption="Add Recent Projects"
                                    />
                                ) : ("")}
                            {(status === TimesheetStatusConstants.DRAFT &&
                                workflowPermissions &&
                                workflowPermissions.canEdit) ||
                                canEditApproved ? (
                                    <AddButton
                                        disabled={
                                            sourceData.inProgress ||
                                            ModalActions.IsUpdateVisible(modals)
                                        }
                                        onClick={() => modalEvents.onShowModal("LAST_ENTRY_HISTORY")}
                                        caption="Add Last Week Projects"
                                    />
                                ) : ("")}
                        </ButtonGroup>
                    )}
                </SpinnerOverlay>

                <FormModal
                    isVisible={ModalActions.IsDeleteVisible(modals)}
                    onHide={modalEvents.onHideDelete}
                    title={"Confirm Delete"}>
                    <ConfirmDelete
                        onConfirmDelete={() =>
                            confirmEvents.onConfirmDelete(sourceData.recordToUpdate)
                        }
                    />
                </FormModal>
                <FormModal
                    size="lg"
                    isVisible={ModalActions.IsVisible(modals, "LINKED_EXPENSE")}
                    onHide={() => modalEvents.onHideModal("LINKED_EXPENSE")}
                    title={"Update Expense"}>
                    <ExpenseFormBasic
                        {...sourceData.calculation}
                        {...ConstructLinkedExpense(
                            sourceData.linkedExpense,
                            this.props.weekEnding
                        )}
                        inProgress={sourceData.inProgress}
                        modals={sourceData.modals}
                        modalEvents={modalEvents}
                        feedback={sourceData.feedback}
                        feedbackEvents={feedbackEvents}
                        onSubmitUpdate={this.props.onSubmitLinkedExpense}
                        onConfirmSubmit={this.props.onSubmitLinkedExpense}
                        onConfirmSaveAndSubmit={this.props.onSubmitLinkedExpense}
                        onRecalculate={(
                            amountUnit,
                            taxCalculated,
                            taxStatus,
                            expenseTypeId,
                            effectiveDate,
                            index
                        ) =>
                            this.props.onRecalculateAmount(
                                amountUnit,
                                taxCalculated,
                                taxStatus,
                                expenseTypeId,
                                effectiveDate,
                                index
                            )
                        }
                        onMakeStale={() => this.props.onMakeStale()}
                    />
                </FormModal>
                <FormModal
                    size="lg"
                    isVisible={ModalActions.IsVisible(modals, "ENTRY_HISTORY")}
                    onHide={() => modalEvents.onHideModal("ENTRY_HISTORY")}
                    title={"Add from recent timesheets"}>
                    <TimesheetEntryHistoryContainer
                        timesheetId={this.props.timesheetId}
                        onSubmitUpdate={this.props.onAddHistorical}
                    />
                </FormModal>
                <FormModal
                    size="lg"
                    isVisible={ModalActions.IsVisible(modals, "LAST_ENTRY_HISTORY")}
                    onHide={() => modalEvents.onHideModal("LAST_ENTRY_HISTORY")}
                    title={"Add last week projects to timesheets"}>
                    <div>
                        <span style={{ float: 'left', lineHeight: '2.5rem'}}>
                            {" "}
                            Add all of the previous week's projects to this timesheet?
                        </span>
                        <span style={{ float: 'right' }}>
                            <input
                                type="button"
                                value="Yes"
                                class="btn btn-success"
                                onClick={this.handleLastWeekAdd}></input>
                            &nbsp;&nbsp;
                            <input
                                type="button"
                                value="No"
                                class="btn btn-warning"
                                onClick={() => modalEvents.onHideModal("LAST_ENTRY_HISTORY")}></input>
                        </span>
                        
                    </div>
                </FormModal>
            </div>
        );
    }
}

const ConstructLinkedExpense = (record, defaultDate) => {
    return {
        ...record,
        projectId:
            record &&
                record.workItem &&
                record.workItem.type === ScopeConstants.PROJECT_ANY
                ? record.workItem.workItemId
                : null,
        proposalId:
            record &&
                record.workItem &&
                record.workItem.type === ScopeConstants.PROPOSAL_ANY
                ? record.workItem.workItemId
                : null,
        leaveId:
            record && record.workItem && record.workItem.type === ScopeConstants.LEAVE
                ? record.workItem.workItemId
                : null,
        variationId: record && record.variation && record.variation.variationId,
        costCodeId: record && record.costCode && record.costCode.costCodeId,
        workflowPermissions: {
            canSubmit: true
        },
        effectiveDate: record.effectiveDate || defaultDate
    };
};

TimesheetEntryList.propTypes = {
    sourceData: PropTypes.shape({
        records: PropTypes.arrayOf(TimesheetEntryShape)
    })
};

export default TimesheetEntryList;
