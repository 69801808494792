import React from "react";
import { Link } from "react-router-dom"
import Moment from "moment";
import { AuthorizedSummaryToolbar } from "components/authentication/controls/AuthorizedControls";
import MonthsConstants from "constants/MonthsConstants";
import ActiveCell from "components/common/ActiveCell"

const Toolbar = AuthorizedSummaryToolbar(p => p.canWriteExpenseMonthlyDueDates);

const getMonth = month => {
  var obj = Object.entries(MonthsConstants).find(m => m[1] === month);
  return obj[0];
};

const ExpenseMonthlyDueDatesRow = ({
  month,
  year,
  dueDate,
  onShowEdit,
  onShowDelete,
  disabled
}) => (
  <tr>
    {/* <td>{getMonth(month)}</td> */}
    <ActiveCell active>
      <Link to={`/expense/due/${dueDate}`}>
        {getMonth(month)}
      </Link>
    </ActiveCell>
    <td>{year}</td>
    <td>{Moment(dueDate).format("DD/MM/YYYY")}</td>
    <td>
      <Toolbar
        disabled={disabled}
        onShowEdit={onShowEdit}
        // onShowDelete={onShowDelete}
      />
    </td>
  </tr>
);

export default ExpenseMonthlyDueDatesRow;
