import ScopeConstants from "constants/ScopeConstants"
import { ScopeShape } from "@gman/gman-redux"
import PropTypes from "prop-types"

export const TimesheetMineScope = {
    key: ScopeConstants.TIMESHEET_MINE,
    selector: (state) => state.timesheetsMine,
    tokenSelector: (state) => state.authentication.token,
    fetchEndpoint: "/api/Timesheet/SearchMine/",
    singleEndpoint: "/api/Timesheet/GetMine/",
    updateEndpoint: "/api/Timesheet/UpdateMine/",
    deleteEndpoint: "/api/Timesheet/DeleteMine/",
    defaultRecord: {
        status: 0
    }
}

TimesheetMineScope.propTypes = PropTypes.shape(ScopeShape).isRequired