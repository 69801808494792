import { connect } from "react-redux"
import { FormEvents } from "@gman/gman-redux"
import TimesheetEntryHistorySelectForm from "components/timesheetEntriesHistory/form/TimesheetEntryHistorySelectForm"
import { TimesheetEntryHistoryScope } from "components/timesheetEntriesHistory/redux/TimesheetEntryHistoryScope"

const mapDispatchToProps = (dispatch) => {
  var scope = TimesheetEntryHistoryScope
  return {
    ...FormEvents.getPaginatorEvents(dispatch, scope),
    ...FormEvents.getRecordStateEvents(dispatch, scope),
    ...FormEvents.getTableHeaderRowEvents(dispatch, scope)
  }
}

const TimesheetEntryHistoryContainer = connect(
  (state) => {
    return {
      sourceData: TimesheetEntryHistoryScope.selector(state)
    }
  },
  mapDispatchToProps
)(TimesheetEntryHistorySelectForm)

export { TimesheetEntryHistoryContainer }