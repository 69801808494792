import React from "react"
import {
    Button,
    ButtonGroup
} from "reactstrap"
import { MdDelete } from "react-icons/md"
import { FaDollarSign, FaDownload, FaRegPaperPlane } from "react-icons/fa"

const InvoiceToolbar = props => {
    const {
        isPaid,
        onShowDelete,
        onShowMarkPaid,
        onInovicePublish
    } = props

    return (
        <div>
            <ButtonGroup size="sm" className="float-right">
                {
                    (onShowMarkPaid) ?
                        <Button
                            outline
                            disabled={isPaid}
                            color="info"
                            onClick={onInovicePublish}
                        >
                            <FaRegPaperPlane /> Publish
                        </Button>
                        : ""
                }
                {
                    (onShowMarkPaid) ?
                        <Button
                            outline
                            disabled={isPaid}
                            color="info"
                            onClick={onShowMarkPaid}
                        >
                            <FaDownload /> Download
                        </Button>
                        : ""
                }
                {
                    (onShowMarkPaid) ?
                        <Button
                            outline
                            disabled={isPaid}
                            color="success"
                            onClick={onShowMarkPaid}
                        >
                            <FaDollarSign /> Mark Paid
                        </Button>
                        : ""
                }
                {
                    (onShowDelete) ?
                        <Button
                            outline
                            disabled={isPaid}
                            color="danger"
                            onClick={onShowDelete}
                        >
                            <MdDelete /> Delete
                        </Button>
                        : ""
                }
            </ButtonGroup>
        </div>
    )
}

export default InvoiceToolbar