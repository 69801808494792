import ScopeConstants from "constants/ScopeConstants"
import { FormActionConstants } from "@gman/gman-redux"

export const DashboardReducer = (
    state = {},
    action) => {
    if (action.scope === ScopeConstants.DASHBOARD) {
        if (action.element) {
            switch (action.type) {
                case (FormActionConstants.RECEIVE_RECORDS):
                    return {
                        ...state,
                        [action.element]: {
                            ...state[action.element],
                            records: action.records
                        }
                    }
                case (FormActionConstants.IN_PROGRESS_START):
                case (FormActionConstants.IN_PROGRESS_STOP):
                    return {
                        ...state,
                        [action.element]: {
                            ...state[action.element],
                            progress: (action.type === FormActionConstants.IN_PROGRESS_START) ? true : false
                        }
                    }
                case "CHANGE_PAGE_SIZE":
                    return {
                        ...state,
                        [action.element]: {
                            ...state[action.element],
                            progress: (action.type === FormActionConstants.IN_PROGRESS_START) ? true : false,
                            searchOptions: {
                                ...state[action.element].searchOptions,
                                pageSize: action.pageSize
                            }
                        }
                    }
            }
        }
    }

    return state
}