import ScopeConstants from "constants/ScopeConstants"
import ExpenseStatusConstants from "constants/ExpenseStatusConstants"

export const ExpenseEditListScope = {
    key: ScopeConstants.EXPENSE_EDIT_LIST,
    selector: (state) => state.expenseEditList,
    tokenSelector: (state) => state.authentication.token,
    fetchEndpoint: "/api/Expense/filter/",
    singleEndpoint: "/api/Expense/GetAny/",
    updateEndpoint: "/api/Expense/UpdateAny/",
    deleteEndpoint: "/api/Expense/DeleteAny/",
    defaultRecord: {
        name: "",
        code: "",
        status: ExpenseStatusConstants.DRAFT
    }
}