import React, { Component } from "react"
import { Card, CardBody, CardHeader, Row, Col } from "reactstrap"
import {
    ExpenseQuickNumbersMineContainer,
    TimesheetQuickNumbersMineContainer,
    TimesheetQuickNumbersTeamContainer,
    TimesheetQuickNumbersAllContainer,
    ExpenseQuickNumbersAnyContainer,
    ExpenseQuickNumbersTeamContainer
} from "components/dashboards/redux/DashboardContainers"
import { ProjectSummaryListManagerContainer, ProjectSummaryListDirectorContainer } from "components/projectSummary/redux/ProjectSummaryContainers"
import Icon from "components/common/Icon"
import { RestrictedComponent } from "components/authentication/redux/AuthorizationWrappers"; 

const TimesheetTeamNumbers =
    RestrictedComponent(() =>
        <Row>
            <Col xs={12} md={12}>
                <Card>
                    <CardHeader>
                        <h6>
                            <Icon lineChart />
                            Team Timesheets
                        </h6>
                    </CardHeader>
                    <CardBody>
                        <TimesheetQuickNumbersTeamContainer />
                    </CardBody>
                </Card >
            </Col>
        </Row>, p => p.canWriteWorkItems)

const TimesheetAllNumbers =
    RestrictedComponent(() =>
        <Row>
            <Col xs={12} md={12}>
                <Card>
                    <CardHeader>
                        <h6>
                            <Icon lineChart />
                            All Timesheets
                        </h6>
                    </CardHeader>
                    <CardBody>
                        <TimesheetQuickNumbersAllContainer />
                    </CardBody>
                </Card >
            </Col>
        </Row>, p => p.canReadAnyTimesheets)

const ExpenseQuickNumbersTeam = 
    RestrictedComponent(() =>
        <Col xs={12} md={12}>
            <Card>
                <CardHeader>
                    <h6>
                        <Icon lineChart />
                        Team Expenses
                    </h6>
                </CardHeader>
                <CardBody>
                    <ExpenseQuickNumbersTeamContainer />
                </CardBody>
            </Card >
        </Col>, p => p.canWriteWorkItems)

const ExpenseQuickNumbersAny =
    RestrictedComponent(() =>
        <Col xs={12} md={12}>
            <Card>
                <CardHeader>
                    <h6>
                        <Icon lineChart />
                        All Expenses
                    </h6>
                </CardHeader>
                <CardBody>
                    <ExpenseQuickNumbersAnyContainer />
                </CardBody>
            </Card >
        </Col>, p => p.canReadAnyExpenses)

const ExpenseProjectForPD =
    RestrictedComponent(() =>
    <Col xs={12} md={12}>
        <Card>
            <CardHeader>
                <h6>
                    <Icon lineChart />
                    My Projects as PD
                </h6>
            </CardHeader>
            <CardBody>
                <ProjectSummaryListDirectorContainer />
            </CardBody>
        </Card >
    </Col>, p => {
        console.log(p , 'p')
        return p.canViewOwnProjectAsPMandPD
    })


const ExpensesProjectForPM = 
        RestrictedComponent(() =>
        <Col xs={12} md={12}>
            <Card>
                <CardHeader>
                    <h6>
                        <Icon lineChart />
                        My Projects as PM
                    </h6>
                </CardHeader>
                <CardBody>
                    <ProjectSummaryListManagerContainer />
                </CardBody>
            </Card >
        </Col>, p => p.canViewOwnProjectAsPMandPD)

export class Home extends Component {
    displayName = Home.name

    render() {
        return (
            <div>
                <Row>
                    <Col xs={12} md={6}>
                        <Row>
                            <Col xs={12} md={12}>
                                <Card>
                                    <CardHeader>
                                        <h6>
                                            <Icon lineChart />
                                            My Timesheets
                                        </h6>
                                    </CardHeader>
                                    <CardBody>
                                        <TimesheetQuickNumbersMineContainer />
                                    </CardBody>
                                </Card >
                            </Col>
                            <Col xs={12} md={12}>
                                <TimesheetTeamNumbers />
                            </Col>
                            <Col xs={12} md={12}>
                                <TimesheetAllNumbers />
                            </Col>
                            <ExpensesProjectForPM />
                        </Row>
                    </Col>
                    <Col xs={12} md={6}>
                        <Row>
                            <Col xs={12} md={12}>
                                <Card>
                                    <CardHeader>
                                        <h6>
                                            <Icon lineChart />
                                            My Expenses
                                        </h6>
                                    </CardHeader>
                                    <CardBody>
                                        <ExpenseQuickNumbersMineContainer />
                                    </CardBody>
                                </Card >
                            </Col>
                            <ExpenseQuickNumbersTeam />
                            <ExpenseQuickNumbersAny />
                            <ExpenseProjectForPD />
                        </Row>
                    </Col>
                </Row>
            </div>
        )
    }
}
