import { connect } from "react-redux"
import { AuthenticationScope } from "components/authentication/redux/AuthenticationScope"
import { FormEvents, FeedbackConstants, FormActions, DispatchActions, FetchActions } from "@gman/gman-redux"
import LoginCardBasic from "../layout/LoginCardBasic"
import LoginStatusBar from "../layout/LoginStatusBar"
import FooterBasic from "../layout/FooterBasic"

const mapDispatchToProps = (dispatch) => {
    var scope = AuthenticationScope
    return {
        ...FormEvents.getFeedbackEvents(dispatch, scope),
        ...FormEvents.getModalEventsForModalsOnAll(dispatch, scope),
        onSubmitLogin: (values) => {
            dispatch(FormActions.inProgressStart(scope))
            FetchActions.fetchApiPost("/api/Authentication/Login/", values)
                .then(data => {
                    localStorage.setItem("AuthRole",data.displayRole);
                    dispatch({ ...DispatchActions.scopedDispatch(scope.key, "LOGIN_SUCCESS"), ...data })
                })
                .catch((error) => {
                    error.json()
                        .then((json) => {
                            dispatch(FormActions.addFeedback(scope, json.content, FeedbackConstants.ERROR))
                        })
                })
                .finally(() => {
                    dispatch(FormActions.inProgressStop(scope))
                })
        },
        onConfirmLogout: () => {
            dispatch({ ...DispatchActions.scopedDispatch(scope.key, "LOGOUT_SUCCESS") })
            dispatch(FormActions.addFeedback(scope, "You have logged out successfully.", FeedbackConstants.SUCCESS))
            dispatch(FormActions.hideModal(scope, "CONFIRM_LOGOUT"))
        },
        onConfirmChangePassword: (values) => {
            dispatch(FormActions.doPost(scope, values, "/api/Authentication/ChangePassword/", "", false))
                .then(() => {
                    dispatch(FormActions.addFeedback(scope, "Your password was successfully changed and will take effect on next login.", FeedbackConstants.SUCCESS))
                })
        }
    }
}

const AuthenticationLoginContainer = connect(
    state => {
        { return { sourceData: AuthenticationScope.selector(state) } }
    },
    mapDispatchToProps
)(LoginCardBasic)

const AuthenticationStatusContainer = connect(
    state => {
        { return { sourceData: AuthenticationScope.selector(state) } }
    },
    mapDispatchToProps
)(LoginStatusBar)

const AuthenticationFooterContainer = connect(
    state => {
        { return { sourceData: AuthenticationScope.selector(state) } }
    }
)(FooterBasic)

export { AuthenticationLoginContainer, AuthenticationStatusContainer, AuthenticationFooterContainer }