import React from "react"
import { AuthenticationFooterContainer } from "components/authentication/redux/AuthenticationContainers"

class Footer extends React.Component {

    constructor(props) {
        super(props)
    }

    render() {
        return (
            <AuthenticationFooterContainer />            
        )
    }
}

export { Footer }