import React from "react"
import { Card, CardBody, CardHeader } from "reactstrap"
import { SystemListContainer } from "components/system/redux/SystemContainers"
import Icon from "components/common/Icon"
import SystemFormExtended from "components/system/form/SystemFormExtended"


const SystemCardBasic = () => {
    return (
        <Card>
            <CardHeader>
                <h6>
                    <Icon system/>
                    System
                </h6>
            </CardHeader>
            <CardBody>
                <SystemListContainer />
            </CardBody>
        </Card >
    )
}

export default SystemCardBasic