import { connect } from "react-redux"
import OfficeList from "components/offices/layout/OfficeList"
import { OfficeScope } from "components/offices/redux/OfficeScope"
import { FormEvents } from "@gman/gman-redux"

const mapDispatchToProps = (dispatch) => {
  var scope = OfficeScope
  return {
    ...FormEvents.getConfirmEventsForModalsOnAll(dispatch, scope),
    ...FormEvents.getTableHeaderRowEvents(dispatch, scope),
    ...FormEvents.getFeedbackEvents(dispatch, scope),
    ...FormEvents.getPaginatorEvents(dispatch, scope),
    ...FormEvents.getModalEventsForModalsOnAll(dispatch, scope),
    ...FormEvents.getRecordStateEvents(dispatch, scope)
  }
}

const OfficeListContainer = connect(
  state => { return { sourceData: OfficeScope.selector(state) } },
  mapDispatchToProps
)(OfficeList)

export { OfficeListContainer }