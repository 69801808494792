import React from "react"
import { Card, CardBody, CardHeader } from "reactstrap"
import { ChargeRateListContainer, ChargeRateListExtendedContainer } from "components/chargeRates/redux/ChargeRateContainers"
import ChargeRateTypeConstants from "constants/ChargeRateTypeConstants"
import Icon from "components/common/Icon"

const ChargeRateCardBasic = (props) => {
    const {
        currentRatesType
    } = props.props.match.params
    return (
        <Card>
            <CardHeader>
                <h6>
                    <Icon chargeRate />
                    {
                        currentRatesType.toUpperCase() == ChargeRateTypeConstants.COST
                            ? "Cost Rates" :
                            currentRatesType.toUpperCase() == ChargeRateTypeConstants.STANDARD
                                ? "Standard Rates" :
                                currentRatesType.toUpperCase() == ChargeRateTypeConstants.STANDARDCONTRACTOR
                                    ? "Standard Contractor Rates" :
                                    currentRatesType.toUpperCase() == ChargeRateTypeConstants.CLIENT
                                        ? "Client Rates" :
                                        currentRatesType.toUpperCase() == ChargeRateTypeConstants.PROJECT
                                            ? "Project Rates" :
                                            currentRatesType.toUpperCase() == ChargeRateTypeConstants.PROPOSAL 
                                                ? "Proposal Rates" : ""
                    }
                </h6>
            </CardHeader>
            <CardBody>
                <ChargeRateListExtendedContainer currentRatesType={currentRatesType.toUpperCase()} />
                { /* <ChargeRateListContainer currentRatesType={ChargeRateTypeConstants.STANDARD} />  */ }
            </CardBody>
        </Card >
    )
}

export default ChargeRateCardBasic