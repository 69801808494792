import React, { Component } from "react"
import { Button, FormGroup, Table } from "reactstrap"
import WorkbenchExportRow from "components/workbenchExports/layout/WorkbenchExportRow"
import WorkbenchExportFormBasic from "components/workbenchExports/form/WorkbenchExportFormBasic"
import TableHeaderRow from "components/common/TableHeaderRow"
import FeedbackAlert from "components/common/FeedbackAlert"
import { ModalActions } from "@gman/gman-redux"
import { AuthorizedAddButton } from "components/authentication/controls/AuthorizedControls"
import {
    ConfirmDelete,
    FormModal,
    Paginator,
    SpinnerOverlay
} from "@gman/gman-react-bootstrap"

const AddButton = AuthorizedAddButton(p => p.canViewWorkbenchExports)

class WorkbenchExportList extends Component {

    componentDidMount() {
        this.props.recordStateEvents.onClearSearch()
        this.props.onLoadDependentData()
    }

    componentWillUnmount = () => {
        this.props.feedbackEvents.onFlushFeedback()
    }

    render() {
        var sourceData = this.props.sourceData
        var modals = sourceData.modals
        var confirmEvents = this.props.confirmEvents
        var feedbackEvents = this.props.feedbackEvents
        var modalEvents = this.props.modalEvents
        var paginatorEvents = this.props.paginatorEvents
        var tableHeaderRowEvents = this.props.tableHeaderRowEvents

        return (
            <div>
                {
                    !ModalActions.IsUpdateVisible(modals) && !ModalActions.IsVisible(modals, "CONFIRM_RUN_EXPORT") ?
                        <FeedbackAlert {...feedbackEvents} feedback={sourceData.feedback} />
                        : ""
                }
                <SpinnerOverlay isLoading={sourceData.inProgress}>
                    <Table responsive hover size="sm">
                        <thead>
                            <TableHeaderRow
                                {...tableHeaderRowEvents}
                                disabled={sourceData.inProgress}
                                headers={[
                                    { sortBy: "Code", label: "Code" },
                                    { sortBy: "Name", label: "Name" },
                                    { sortBy: "ExportDate", label: "Export Date" },
                                    { sortBy: "NumberOfRows", label: "Number of rows" },
                                    { sortBy: "IsImported", label: "Imported into Workbench?" }
                                ]}
                            />
                        </thead>
                        <tbody>
                            {
                                sourceData.records.map((record, index) => (
                                    <WorkbenchExportRow
                                        key={index}
                                        {...record}
                                        onShowDelete={() => modalEvents.onShowDelete(record)}
                                        onShowEdit={() => modalEvents.onShowEdit(record)}
                                        onShowDownload={() => this.props.onDownloadExport(record.workbenchExportId)}
                                        disabled={sourceData.inProgress} />
                                ))
                            }
                        </tbody>
                    </Table>
                    <Paginator {...paginatorEvents} searchOptions={sourceData.searchOptions} />
                    <AddButton disabled={sourceData.inProgress} caption="Run Export" onClick={() => modalEvents.onShowModal("CONFIRM_RUN_EXPORT")} />
                </SpinnerOverlay>
                <FormModal
                    isVisible={ModalActions.IsDeleteVisible(sourceData.modals)}
                    onHide={modalEvents.onHideDelete}
                    title={"Confirm Delete"}>
                    <ConfirmDelete onConfirmDelete={() => confirmEvents.onConfirmDelete(sourceData.recordToUpdate)} />
                </FormModal>
                <FormModal
                    isVisible={ModalActions.IsUpdateVisible(sourceData.modals)}
                    onHide={modalEvents.onHideEdit}
                    title={"Update Workbench Export"}>
                    <WorkbenchExportFormBasic
                        onSubmitUpdate={confirmEvents.onSubmitUpdate}
                        feedback={sourceData.feedback}
                        feedbackEvents={feedbackEvents}
                        {...sourceData.recordToUpdate} />
                </FormModal>
                <FormModal
                    isVisible={ModalActions.IsVisible(sourceData.modals, "CONFIRM_RUN_EXPORT")}
                    onHide={() => modalEvents.onHideModal("CONFIRM_RUN_EXPORT")}
                    title={"Confirm Run Workbench Export"}>
                    <SpinnerOverlay isLoading={sourceData.inProgress}>
                        <FormGroup>
                            <FeedbackAlert {...feedbackEvents} feedback={sourceData.feedback} />
                            Run a new Workbench export?
                            <Button
                                className="float-right"
                                color="success"
                                onClick={() => this.props.onConfirmRunExport()}
                            >
                                Confirm
                            </Button>
                        </FormGroup>
                    </SpinnerOverlay>
                </FormModal>
            </div>
        )
    }
}

export default WorkbenchExportList