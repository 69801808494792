import React, { Component } from "react"
import Moment from "moment";
import { withRouter } from "react-router";
import {
    Card,
    CardBody,
    CardHeader,
    CardTitle,
    CardSubtitle,
    Container,
    Col,
    Row,
    Button,
    FormGroup
} from "reactstrap"
import TimesheetStatusBadge from "components/common/TimesheetStatusBadge"
import { TimesheetEntryListContainer } from "components/timesheetEntries/redux/TimesheetEntryContainers"
import WorkflowToolbar from "components/common/WorkflowToolbar"
import FeedbackAlert from "components/common/FeedbackAlert"
import Icon from "components/common/Icon"
import TimesheetStatusConstants from "constants/TimesheetStatusConstants"
import { FormModal } from "@gman/gman-react-bootstrap"
import { ModalActions } from "@gman/gman-redux";

class TimesheetCardExtended extends Component {

    componentDidMount() {
        if (this.props.timesheetId) {
            this.props.recordStateEvents.onLoadSingle(this.props.timesheetId)
            this.props.onLoadDependentData()
        }
    }

    componentWillUnmount = () => {
        this.props.feedbackEvents.onFlushFeedback()
    }

    render() {
  
        var SourceDataRecord = this.props.SourceDataRecord;
        var sourceData = this.props.sourceData;
        var modalEvents = this.props.modalEvents;
        var feedbackEvents = this.props.feedbackEvents;
        var modals = sourceData.modals;

        var currentDate = Moment();
        var weekStart = currentDate.clone().startOf('week');
        var weekEnd = currentDate.clone().endOf('week');
        var isTimeSheetDateIsWithInCurrentWeek = Moment(sourceData.recordToUpdate.weekEnding).isBetween(weekStart, weekEnd);
        const {
            history
        } = this.props;
        //console.log(this.props.sourceData ,  this.props.SourceDataRecord);
        return (
            <div>
                <Card>
                    <CardHeader>
                        <CardTitle>
                            {
                                sourceData.recordToUpdate ?
                                    <div className="row">
                                        <div className="col-lg-4">
                                            <h6>
                                                {
                                                    sourceData.recordToUpdate.user ? <span>{sourceData.recordToUpdate.user.firstName} {sourceData.recordToUpdate.user.lastName} </span> : ""
                                                }
                                            </h6>
                                            <h6>
                                                Timesheet for week ending {Moment(sourceData.recordToUpdate.weekEnding).format("MMM Do YYYY")}
                                            </h6>
                                        </div>
                                        <div className="col-lg-3 d-flex align-items-center">
                                            <Button
                                                outline
                                                color="primary"
                                                className="mr-2 d-flex align-items-center"
                                                onClick={
                                                    () => this.props.getPreviousTimesheet({
                                                        weekEnding: sourceData.recordToUpdate.weekEnding,
                                                        userId: sourceData.recordToUpdate.userId
                                                    }, history)
                                                }>
                                                <Icon
                                                    arrowBack
                                                    className="d-flex" l
                                                    eftSpace
                                                />
                                            Previous Week
                                        </Button>
                                            <Button
                                                outline
                                                color="primary"
                                                className="d-flex align-items-center"
                                                onClick={
                                                    () => this.props.getNextWeekTimesheet({
                                                        weekEnding: sourceData.recordToUpdate.weekEnding,
                                                        userId: sourceData.recordToUpdate.userId
                                                    }, history)
                                                }>
                                                Next Week
                                            <Icon
                                                    arrowForward
                                                    className="d-flex"
                                                    rightSpace
                                                />
                                            </Button>
                                        </div>
                                    </div>
                                    :
                                    "Loading..."
                            }
                        </CardTitle>
                        <hr />
                        <CardSubtitle>
                            <Icon clock />
                            <small>
                                <i>Timesheet</i>
                            </small>
                        </CardSubtitle>
                        <br />
                        <TimesheetStatusBadge value={sourceData.recordToUpdate.status} />
                    </CardHeader>
                    <CardBody>
                        <FeedbackAlert {...feedbackEvents} feedback={sourceData.feedback} />
                        <Container>
                            <Row>
                                <Col>
                                    <TimesheetEntryListContainer
                                        timesheetId={this.props.timesheetId}
                                        weekEnding={sourceData.recordToUpdate && sourceData.recordToUpdate.weekEnding}
                                        status={sourceData.recordToUpdate.status}
                                        workflowPermissions={sourceData.recordToUpdate && sourceData.recordToUpdate.workflowPermissions}
                                    />
                                </Col>
                            </Row>
                            {
                                sourceData.recordToUpdate.status == TimesheetStatusConstants.DRAFT && isTimeSheetDateIsWithInCurrentWeek == false ?
                                    <Row className="mt-3">
                                        <Col>
                                            <div className="text-danger float-right">This timesheet is not for the current week</div>
                                        </Col>
                                    </Row> : ""
                            }
                            <Row>
                                <Col>
                                    <br />
                                    {sourceData.recordToUpdate.workflowPermissions ?
                                        <WorkflowToolbar
                                            {...sourceData}
                                            {...modalEvents}
                                            SourceDataRecord  = {SourceDataRecord}
                                            onConfirmApprove={() => this.props.onConfirmApprove(sourceData.recordToUpdate)}
                                            onConfirmSubmit={() => this.props.onConfirmSubmit(sourceData.recordToUpdate)}
                                            //onConfirmSubmit={() => this.props.getTimesheetApprovalMessages(sourceData.recordToUpdate.timesheetId)}
                                            onConfirmUnsubmit={() => this.props.onConfirmUnsubmit(sourceData.recordToUpdate)}
                                            workflowPermissions={sourceData.recordToUpdate.workflowPermissions}
                                            isSubmitted={(sourceData.recordToUpdate.status === TimesheetStatusConstants.INPROGRESS)}
                                            isDraft={(sourceData.recordToUpdate.status === TimesheetStatusConstants.DRAFT)}
                                        />
                                        : ""
                                    }
                                </Col>
                            </Row>
                        </Container>
                    </CardBody >
                </Card >
                {/* <div>
                    {
                        sourceData.approvalMessages && sourceData.approvalMessages.map((record, index) => {
                            <FormModal isVisible={ModalActions.IsVisible(modals, record.title)} onHide={() => this.props.hideApprovalMessagesModal(record.title, index)} title={record.title}>
                                <FormGroup>
                                    {record.message}
                                    <Button className="float-right" color="success">Continue</Button>
                                </FormGroup>
                            </FormModal>
                        })
                    }
                </div> */}
            </div>
        )
    }
}

export default withRouter(TimesheetCardExtended)