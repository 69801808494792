import React from "react"
import {
    FaBook,    
    FaBriefcase,
    FaCreditCard,
    FaDollarSign,    
    FaEdit,
    FaFileInvoice,
    FaFileInvoiceDollar,
    FaFlagCheckered,
    FaHammer,
    FaHourglassHalf,    
    FaLock,
    FaFileMedicalAlt,
    FaRegComment,
    FaRegCommentDots,
    FaRegChartBar,
    FaSearch,
    FaSearchDollar,
    FaTasks,
    FaUnlock,
    FaUsers,
    FaWallet,
    FaRegSmile,
} from "react-icons/fa"
import {
    MdAccessTime,
    MdAdd,    
    MdApps,
    MdAssignment,
    MdClose,
    MdContentCopy,
    MdDone,
    MdDoneAll,
    MdFolderOpen,
    MdLocationCity,
    MdMonetizationOn,
    MdMoreHoriz,
    MdPeople,
    MdPerson,
    MdPublish,
    MdSchool,
    MdSettings,
    MdShowChart,
    MdSmokeFree,
    MdGroup
} from "react-icons/md"
import{
    GiChicken,
    GiChickenOven,
    GiAustralia
} from "react-icons/gi";
import{
    IoIosEgg,
    IoIosConstruct,
    IoIosCalendar,
    IoIosArrowBack,
    IoIosArrowForward
} from "react-icons/io";

const Icon = props => {
    const {
        leftSpace,
        rightSpace = true,
        className
    } = props

    if (props.add) return (FormatIcon(<MdAdd />, leftSpace, rightSpace))
    if (props.addVersion) return (FormatIcon(<MdContentCopy />, leftSpace, rightSpace))
    if (props.authorization) return (FormatIcon(<FaUnlock />, leftSpace, rightSpace))
    if (props.book) return (FormatIcon(<FaBook />, leftSpace, rightSpace))
    if (props.commentEmpty) return (FormatIcon(<FaRegComment />, leftSpace, rightSpace))
    if (props.commentNotEmpty) return (FormatIcon(<FaRegCommentDots />, leftSpace, rightSpace))
    if (props.costCode) return (FormatIcon(<FaSearchDollar />, leftSpace, rightSpace))
    if (props.costCodeGroup) return (FormatIcon(<MdAssignment />, leftSpace, rightSpace))
    if (props.chargeRate) return (FormatIcon(<MdMonetizationOn />, leftSpace, rightSpace))    
    if (props.checkeredFlag) return (FormatIcon(<FaFlagCheckered />, leftSpace, rightSpace))    
    if (props.cog) return (FormatIcon(<MdSettings />, leftSpace, rightSpace))
    if (props.client) return (FormatIcon(<GiAustralia />, leftSpace, rightSpace))
    if (props.clock) return (FormatIcon(<MdAccessTime />, leftSpace, rightSpace))    
    if (props.close) return (FormatIcon(<MdClose />, leftSpace, rightSpace))    
    if (props.dollar) return (FormatIcon(<FaDollarSign />, leftSpace, rightSpace))    
    if (props.done) return (FormatIcon(<MdDone />, leftSpace, rightSpace))
    if (props.edit) return (FormatIcon(<FaEdit />, leftSpace, rightSpace))   
    if (props.expense) return (FormatIcon(<FaWallet />, leftSpace, rightSpace))    
    if (props.expenseType) return (FormatIcon(<FaCreditCard />, leftSpace, rightSpace))    
    if (props.folderOpen) return (FormatIcon(<MdFolderOpen />, leftSpace, rightSpace))
    if (props.group) return (FormatIcon(<FaUsers />, leftSpace, rightSpace))
    if (props.hammer) return (FormatIcon(<FaHammer />, leftSpace, rightSpace))
    if (props.hours) return (FormatIcon(<FaHourglassHalf />, leftSpace, rightSpace))
    if (props.invoice) return (FormatIcon(<FaFileInvoice />, leftSpace, rightSpace))
    if (props.invoiceHistory) return (FormatIcon(<FaFileMedicalAlt />, leftSpace, rightSpace))
    if (props.invoiceUnpaid) return (FormatIcon(<FaFileInvoiceDollar />, leftSpace, rightSpace))
    if (props.leave) return (FormatIcon(<FaBriefcase />, leftSpace, rightSpace))
    if (props.lineChart) return (FormatIcon(<MdShowChart />, leftSpace, rightSpace))
    if (props.lock) return (FormatIcon(<FaLock />, leftSpace, rightSpace))
    if (props.moreOptions) return (FormatIcon(<MdMoreHoriz />, leftSpace, rightSpace))
    if (props.office) return (FormatIcon(<MdLocationCity />, leftSpace, rightSpace))
    if (props.opportunity) return (FormatIcon(<IoIosEgg />, leftSpace, rightSpace))        
    if (props.people) return (FormatIcon(<MdPeople />, leftSpace, rightSpace))
    if (props.person) return (FormatIcon(<MdPerson />, leftSpace, rightSpace))        
    if (props.project) return (FormatIcon(<GiChickenOven />, leftSpace, rightSpace))        
    if (props.proposal) return (FormatIcon(<GiChicken />, leftSpace, rightSpace))        
    if (props.publish) return (FormatIcon(<MdPublish />, leftSpace, rightSpace))        
    if (props.reports) return (FormatIcon(<FaRegChartBar />, leftSpace, rightSpace))        
    if (props.school) return (FormatIcon(<MdSchool />, leftSpace, rightSpace))
    if (props.search) return (FormatIcon(<FaSearch />, leftSpace, rightSpace))
    if (props.smiley) return (FormatIcon(<FaRegSmile />, leftSpace, rightSpace))
    if (props.system) return (FormatIcon(<IoIosConstruct />, leftSpace, rightSpace))
    if (props.workbench) return (FormatIcon(<MdApps />, leftSpace, rightSpace))
    if (props.workflow) return (FormatIcon(<MdDoneAll />, leftSpace, rightSpace))
    if (props.variation) return (FormatIcon(<FaTasks />, leftSpace, rightSpace))
    if (props.calendar) return (FormatIcon(<IoIosCalendar />, leftSpace, rightSpace))
    if (props.arrowBack) return (FormatIcon(<IoIosArrowBack />, leftSpace, rightSpace, className))
    if (props.arrowForward) return (FormatIcon(<IoIosArrowForward />, leftSpace, rightSpace, className))
    
    return (<MdSmokeFree />)
}

const FormatIcon = (icon, leftSpace, rightSpace, className) => {
    return (
        <span className={ className ? className : "" }>
            {Space(leftSpace)}
            {icon}
            {Space(rightSpace)}
        </span>
    )
}

const Space = (space) => {
    if (space)
        return (" ")
    else
        return ("")
}

export default Icon