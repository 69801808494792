import ScopeConstants from "constants/ScopeConstants"
import { ScopeShape } from "@gman/gman-redux"
import PropTypes from "prop-types"

export const ExpenseTypeScope = {
    key: ScopeConstants.EXPENSE_TYPE,
    selector: (state) => state.expenseTypes,
    tokenSelector: (state) => state.authentication.token,
    fetchEndpoint: "/api/ExpenseType/Search/",
    updateEndpoint: "/api/ExpenseType/Update",
    deleteEndpoint: "/api/ExpenseType/Delete",
    defaultRecord: {
        code: ""
    }
}

ExpenseTypeScope.propTypes = PropTypes.shape(ScopeShape).isRequired