import React from "react"
import { Link } from "react-router-dom"
import Moment from "moment"
import PropTypes from "prop-types"
import { TimesheetShape } from "shapes/TimesheetShape"
import ActiveCell from "components/common/ActiveCell"
import TimesheetStatusBadge from "components/common/TimesheetStatusBadge"
import TimesheetStatusConstants from "constants/TimesheetStatusConstants"
import { AuthorizedAddButton } from "components/authentication/controls/AuthorizedControls"

const AddButton = AuthorizedAddButton(p => p.canCompleteOwnTimesheet)

const OutstandingTimesheetRow = (
    {
        timesheetId,
        weekEnding,
        user,
        status,
        disabled,
        workflowPermissions,
        onShowCreate
    }) => (
        <tr>
            {status === TimesheetStatusConstants.MISSING ?
                <td>
                    {Moment(weekEnding).format("DD/MM/YYYY")}
                </td>
                :
                <td>
                    <Link to={"/timesheetEntry/mine/" + timesheetId}>
                        {Moment(weekEnding).format("DD/MM/YYYY")}
                    </Link>
                </td>
            }
            <td>
                <TimesheetStatusBadge value={status} />
            </td>
            <ActiveCell active={!disabled}>
                {user ?
                    <Link to={"/userDetails/" + user.userId}>
                        {user && user.firstName + " " + user.lastName}
                    </Link>
                    : ""}
            </ActiveCell>
            <td>
                {
                    status === TimesheetStatusConstants.MISSING &&
                        workflowPermissions && workflowPermissions.canEdit ?
                        <AddButton disabled={disabled} caption="Add" onClick={onShowCreate} />
                        : ""
                }
            </td>
        </tr>
    )

    OutstandingTimesheetRow.propTypes = PropTypes.shape(TimesheetShape).isRequired

export default OutstandingTimesheetRow 