import { connect } from "react-redux"
import TimesheetList from "components/timesheets/layout/TimesheetList"
import TimesheetCardExtended from "components/timesheets/layout/TimesheetCardExtended"
import { TimesheetMineScope } from "components/timesheets/redux/TimesheetMineScope"
import { TimesheetTeamScope } from "components/timesheets/redux/TimesheetTeamScope"
import { TimesheetAnyScope } from "components/timesheets/redux/TimesheetAnyScope"
import TimesheetStatusConstants from "constants/TimesheetStatusConstants"
import { FormActions, FormEvents, ModalConstants, DispatchActions, FetchActions, FeedbackConstants } from "@gman/gman-redux"
import { UserScope } from "components/users/redux/UserScope"
import { TimesheetEntryScope } from "../../timesheetEntries/redux/TimesheetEntryScope"

const mapDispatchToPropsMine = (dispatch) => { return mapDispatchToProps(dispatch, TimesheetMineScope, "mine", true) }

const mapDispatchToPropsTeam = (dispatch) => { return mapDispatchToProps(dispatch, TimesheetTeamScope, "team", false) }

const mapDispatchToPropsAny = (dispatch) => { return mapDispatchToProps(dispatch, TimesheetAnyScope, "any", true) }

const mapDispatchToProps = (dispatch, scope, suffix, canAdd) => {
    var result = {
        ...FormEvents.getTableHeaderRowEvents(dispatch, scope),
        ...FormEvents.getFeedbackEvents(dispatch, scope),
        ...FormEvents.getPaginatorEvents(dispatch, scope),
        ...FormEvents.getModalEventsForModalsOnAll(dispatch, scope),
        ...FormEvents.getRecordStateEvents(dispatch, scope),
        ...FormEvents.getRedirectEvents(dispatch, scope),
        onLoadDependentData: () => {
            dispatch(FormActions.fetchAll(UserScope))
        },
        suffix: suffix,
        onConfirmSubmit: record => {
            //this.getTimesheetApprovalMessages(record.timesheetId)
            dispatch(FormActions.doPost(scope, record, "/api/Timesheet/Submit" + suffix, "The timesheet was submitted successfully.", false))
            dispatch(FormActions.hideModal(scope, "SUBMIT"))
        },
        onConfirmReject: record => {
            record.status = TimesheetStatusConstants.DRAFT
            dispatch(FormActions.doPost(scope, record, "/api/Timesheet/Update" + suffix, "The timesheet was rejected successfully.", false))
            dispatch(FormActions.hideModal(scope, "REJECT"))
        },
        onConfirmApprove: record => {
            record.status = TimesheetStatusConstants.COMPLETED
            dispatch(FormActions.doPost(scope, record, "/api/Timesheet/Update" + suffix, "The timesheet was approved successfully.", false))
                .then(result => {
                    if (result.error == true) {
                        record.status = TimesheetStatusConstants.INPROGRESS
                    }
                });
            dispatch(FormActions.hideModal(scope, "APPROVE"))
        },
        onConfirmUnsubmit: record => {
            record.status = TimesheetStatusConstants.DRAFT
            dispatch(FormActions.doPost(scope, record, "/api/Timesheet/Update" + suffix, "The timesheet was un-submitted successfully.", false))
            dispatch(FormActions.hideModal(scope, "UNSUBMIT"))
        },
        onPageSizeChange: (pageSize) => {
            dispatch({ ...DispatchActions.scopedDispatch(scope.key, "CHANGE_PAGE_SIZE"), pageSize: pageSize });
            dispatch(FormActions.fetchRecords(scope));
        },
        confirmEvents: {
            ...FormEvents.getConfirmEventsForModalsOnAll(dispatch, scope).confirmEvents,
            onSubmitUpdate: (record) => {
                var redirect = { urlFunc: (o) => "/timesheetEntry/mine/" + o.timesheetId }
                dispatch(FormActions.doPost(scope, record, scope.updateEndpoint, "The record was updated successfully.", true, redirect))
                dispatch(FormActions.hideModal(scope, ModalConstants.UPDATE))
            }
        },
        getNextWeekTimesheet: (record, history) => {
            dispatch(function (dispatch, getState) {
                var authToken = scope.tokenSelector(getState());
                FetchActions.fetchApiPost("/api/Timesheet/GetNextWeekTimesheet" + suffix, record, authToken)
                    .then(result => {
                        if (result) {
                            // dispatch(FormActions.loadRecord(scope, result));
                            history.push("/timesheetEntry/" + suffix + "/" + result.timesheetId);
                        }
                    })
                    .catch(function (error) {
                        error.json().then(function (error) {
                            dispatch(FormActions.addFeedback(scope, error.content, FeedbackConstants.ERROR));
                        })
                    });
            });
        },
        getPreviousTimesheet: (record, history) => {
            dispatch(function (dispatch, getState) {
                var authToken = scope.tokenSelector(getState());
                FetchActions.fetchApiPost("/api/Timesheet/GetPreviousWeekTimesheet" + suffix, record, authToken)
                    .then(result => {
                        //console.log(result);
                        if (result) {
                            // dispatch(FormActions.loadRecord(scope, result));
                            history.push("/timesheetEntry/" + suffix + "/" + result.timesheetId);
                        }
                    })
                    .catch(function (error) {
                        error.json().then(function (error) {
                            dispatch(FormActions.addFeedback(scope, error.content, FeedbackConstants.ERROR));
                        })
                    });
            });
        },
        getTimesheetApprovalMessages: (timesheetId) => {
            dispatch(function (dispatch, getState) {
                dispatch({ ...DispatchActions.scopedDispatch(scope.key, "APPROVAL_MESSAGES"), record: [] })
                var authToken = "";
                if (scope.tokenSelector) {
                    authToken = scope.tokenSelector(getState());
                    dispatch(FormActions.inProgressStart(scope));
                    FetchActions.fetchApiGet("/api/Timesheet/GetTimesheetApprovalMessages" + suffix + "?timesheetId=" + timesheetId, authToken)
                        .then(function (response) {
                            dispatch(FormActions.inProgressStop(scope));
                            return Promise.resolve(response.json());
                        })
                        .then((data) => {
                            dispatch({ ...DispatchActions.scopedDispatch(scope.key, "APPROVAL_MESSAGES"), record: data });
                            return Promise.resolve(true);
                        })
                        .catch((error) => {
                            FormActions.handleErrorResponse(error, scope, dispatch)
                        });
                }
            });
        },
        hideApprovalMessagesModal: (modalName, index) => {
            dispatch(FormActions.hideModal(scope, modalName));
        }
    }

    if (!canAdd) {
        result.modalEvents.onShowNew = null
    }

    return result;
}

const TimesheetMineExtendedContainer = connect(
    (state, ownProps) => {
        return {
            sourceData: TimesheetMineScope.selector(state),
            SourceDataRecord: TimesheetEntryScope.selector(state),
            timesheetId: ownProps.timesheetId
        }
    },
    mapDispatchToPropsMine
)(TimesheetCardExtended)

const TimesheetTeamExtendedContainer = connect(
    (state, ownProps) => {
        return {
            sourceData: TimesheetTeamScope.selector(state),
            SourceDataRecord: TimesheetEntryScope.selector(state),
            timesheetId: ownProps.timesheetId
        }
    },
    mapDispatchToPropsTeam
)(TimesheetCardExtended)

const TimesheetAnyExtendedContainer = connect(
    (state, ownProps) => {
        return {
            sourceData: TimesheetAnyScope.selector(state),
            SourceDataRecord: TimesheetEntryScope.selector(state),
            timesheetId: ownProps.timesheetId
        }
    },
    mapDispatchToPropsAny
)(TimesheetCardExtended)

const TimesheetListMineContainer = connect(
    (state, ownProps) => {
        return {
            sourceData: TimesheetMineScope.selector(state),
            canSelectUser: false,
            status: ownProps.status
        }
    },
    mapDispatchToPropsMine
)(TimesheetList)

const TimesheetListTeamContainer = connect(
    (state, ownProps) => {
        return {
            sourceData: TimesheetTeamScope.selector(state),
            canSelectUser: false,
            status: ownProps.status
        }
    },
    mapDispatchToPropsTeam
)(TimesheetList)

const TimesheetListAnyContainer = connect(
    (state, ownProps) => {
        return {
            sourceData: TimesheetAnyScope.selector(state),
            canSelectUser: true,
            status: ownProps.status
        }
    },
    mapDispatchToPropsAny
)(TimesheetList)

export {
    TimesheetMineExtendedContainer,
    TimesheetTeamExtendedContainer,
    TimesheetAnyExtendedContainer,
    TimesheetListMineContainer,
    TimesheetListTeamContainer,
    TimesheetListAnyContainer
}