import React from "react"
import { Button } from "reactstrap"
import Icon from "components/common/Icon"

const AddButton = props => {
    const { onClick, disabled, caption, styleClass } = props
    return (
        <Button
            size="sm"
            className={styleClass ? styleClass : "float-right"}
            disabled={disabled}
            outline
            color="primary"
            onClick={onClick}
        >
            <Icon add />{caption ? caption : "Add"}
        </Button>
    )
}

export default AddButton