import React, { Component } from "react"
import { OpportunityReadContainer, OpportunityEditContainer } from "components/opportunities/redux/OpportunityContainers"

import OpportunityCardMineBasic from "components/opportunities/layout/OpportunityCardMineBasic"
import OpportunityCardAnyBasic from "components/opportunities/layout/OpportunityCardAnyBasic"

export class OpportunityMinePage extends Component { render() { return (<OpportunityCardMineBasic />) } }
export class OpportunityAnyPage extends Component { render() { return (<OpportunityCardAnyBasic />) } }

export class OpportunityReadPage extends Component { render() { return (<OpportunityReadContainer opportunityId={this.props.match.params.opportunityId} />) } }
export class OpportunityEditPage extends Component { render() { return (<OpportunityEditContainer opportunityId={this.props.match.params.opportunityId} />) } }