import React from "react"
import Moment from "moment"
import { withFormik } from "formik"
import * as Yup from "yup"
import RequiredStar from "components/common/RequiredStar"
import { AuthorizedButton, AuthorizedInputField } from "components/authentication/controls/AuthorizedControls"

import {
    Container,
    Col,
    Form,
    FormGroup,
    Label,
    Row
} from "reactstrap"

const InputField = AuthorizedInputField(p => p.canMarkItemsAsPaid)
const SubmitButton = AuthorizedButton(p => p.canMarkItemsAsPaid)

const BaseForm = props => {
    const {
        values,
        errors,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting
    } = props
    return (
        <div>
            <Form noValidate onSubmit={handleSubmit}>
                <Container>
                    <Row>
                        <Col xs={12}>
                            <FormGroup>
                                <RequiredStar />
                                <Label for="paymentDate">Date Paid</Label>
                                <InputField
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.datePaid ? Moment(values.datePaid).format("YYYY-MM-DD") : ""}
                                    type="date"
                                    name="datePaid"
                                    id="datePaid"
                                    placeholder="Payment Date"
                                    invalid={errors.datePaid} />
                                {errors.datePaid && <small className="text-danger">{errors.datePaid}</small>}
                            </FormGroup>
                        </Col>
                    </Row>
                </Container>
                <InputField
                    onChange={handleChange}
                    value={values.invoiceDate ? Moment(values.invoiceDate).format("YYYY-MM-DD") : ""}
                    type="hidden"
                    name="invoiceDate"
                    id="invoiceDate"
                />
                <SubmitButton outline className="float-right" disabled={isSubmitting} color="success" type="submit">Submit</SubmitButton>
            </Form>
        </div>
    )
}

const validationSchema = Yup.object({
    paymentDate: Yup.date()
        .when(
            "invoiceDate",
            (invoiceDate, schema) => (
                invoiceDate ?
                    schema
                        .min(invoiceDate, "Payment date cannot precede Invoice date")
                        .required("Payment date is required")
                        .typeError("Must be a date")
                        .max(Moment(), "Payment date cannot be a future date")
                    :
                    schema)
        )
})

const ClientInvoiceConfirmPaidForm = withFormik({
    displayName: "ClientInvoiceConfirmPaidForm",
    enableReinitialize: true,
    validationSchema: validationSchema,
    mapPropsToValues: ({ ...props }) => ({
        ...props,
        paymentDate: props.lastPaymentDate || ""
    }),
    handleSubmit: (values, { props, setSubmitting }) => {
        props.onConfirmPaid(values)
        setSubmitting(false)
    }
})(BaseForm)

export default ClientInvoiceConfirmPaidForm