import React from "react"
import { withFormik } from "formik"
import * as Yup from "yup"
import RequiredStar from "components/common/RequiredStar"
import FeedbackAlert from "components/common/FeedbackAlert"
import { SpinnerOverlay } from "@gman/gman-react-bootstrap"

import {
    Button,
    Col,
    Container,
    Form,
    FormGroup,
    Input,
    Label,
    Row
} from "reactstrap"

const BaseForm = props => {
    const {
        inProgress,
        values,
        dirty,
        errors,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
        feedback,
        feedbackEvents
    } = props
    return (
        <div>
            <SpinnerOverlay isLoading={inProgress}>
                <Form noValidate onSubmit={handleSubmit}>
                    {
                        feedback && feedbackEvents ?
                            <FeedbackAlert {...feedbackEvents} feedback={feedback} />
                            : ""
                    }
                    <Container>
                        <Row>
                            <Col xs={12}>
                                <FormGroup>
                                    <RequiredStar />
                                    <Label for="currentPassword">Current Password</Label>
                                    <Input
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.currentPassword || ""}
                                        type="password"
                                        name="currentPassword"
                                        id="currentPassword"
                                        placeholder="Current Password"
                                        invalid={errors.currentPassword} />
                                    {errors.currentPassword && <small className="text-danger">{errors.currentPassword}</small>}
                                </FormGroup>
                            </Col>
                            <Col xs={12}>
                                <FormGroup>
                                    <RequiredStar />
                                    <Label for="newPassword1">New Password</Label>
                                    <Input
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.newPassword1 || ""}
                                        type="password"
                                        name="newPassword1"
                                        id="newPassword1"
                                        placeholder="New Password"
                                        invalid={errors.newPassword1} />
                                    {errors.newPassword1 && <small className="text-danger">{errors.newPassword1}</small>}
                                </FormGroup>
                            </Col>
                            <Col xs={12}>
                                <FormGroup>
                                    <RequiredStar />
                                    <Label for="newPassword2">Confirm Password</Label>
                                    <Input
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.newPassword2 || ""}
                                        type="password"
                                        name="newPassword2"
                                        id="newPassword2"
                                        placeholder="Confirm New Password"
                                        invalid={errors.newPassword2} />
                                    {errors.newPassword2 && <small className="text-danger">{errors.newPassword2}</small>}
                                </FormGroup>
                            </Col>
                        </Row>
                    </Container>
                    <Button outline className="float-right" disabled={isSubmitting || !dirty} color="warning" type="submit">Submit</Button>
                </Form>
            </SpinnerOverlay>
        </div>
    )
}

const validationSchema = Yup.object({
    currentPassword: Yup.string()
        .required("Current password is required"),
    newPassword1:
        Yup.string()
            .required("New password is required"),
    newPassword2:
        Yup.string()
            .oneOf([Yup.ref("newPassword1"), null], "New passwords must match")
            .required("Confirm password is required")
})

const ChangePasswordForm = withFormik({
    displayName: "ChangePasswordForm",
    enableReinitialize: true,
    validationSchema: validationSchema,
    handleSubmit: (values, { props, setSubmitting }) => {
        props.onSubmitChangePassword(
            {
                currentPassword: values.currentPassword,
                newPassword1: values.newPassword1,
                newPassword2: values.newPassword2
            })
        setSubmitting(false)
    }
})(BaseForm)

export default ChangePasswordForm