import React from "react"
import { AuthorizedSummaryToolbar } from "components/authentication/controls/AuthorizedControls"

const Toolbar = AuthorizedSummaryToolbar(p => p.canWriteCostCodes)

const CostCodeRow = (
    {
        code,
        description,
        onShowEdit,
        onShowDelete,
        disabled,
        isDisabled
    }) => (
        <tr>
            <td>{code}</td>
            <td>{description}</td>
            <td>{isDisabled ? "Yes" : "No"}</td>
            <td>
                <Toolbar
                    disabled={disabled}
                    onShowEdit={onShowEdit}
                    onShowDelete={onShowDelete}
                />
            </td>
        </tr>
    )

export default CostCodeRow 