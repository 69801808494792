import React, { Component } from "react"
import TableHeaderRow from "components/common/TableHeaderRow"
import { ModalActions } from "@gman/gman-redux"
import { Table, Button } from "reactstrap"
import CostRateRow from "components/costRates/layout/CostRateRow"
import FeedbackAlert from "components/common/FeedbackAlert"
import { FormModal } from "@gman/gman-react-bootstrap"
import {
    Paginator,
    SpinnerOverlay
} from "@gman/gman-react-bootstrap"
import { CostRateFormContainer } from "components/costRates/redux/CostRateContainer"


class CostRateList extends Component {
    componentDidMount() {
       this.props.onGetRates();
    }

    componentWillUnmount = () => {
        this.props.feedbackEvents.onFlushFeedback()
    }

    render() {
        var sourceData = this.props.sourceData
        var modals = sourceData.modals
        var confirmEvents = this.props.confirmEvents
        var feedbackEvents = this.props.feedbackEvents
        var modalEvents = this.props.modalEvents
        var paginatorEvents = this.props.paginatorEvents
        var tableHeaderRowEvents = this.props.tableHeaderRowEvents

        return (
            <div>
                <FeedbackAlert {...feedbackEvents} feedback={sourceData.feedback} />
                <SpinnerOverlay isLoading={sourceData.inProgress}>
                    <Button
                        outline
                        className="float-right mb-2"
                        color="success"
                        size="sm"
                        onClick={() => {
                            modalEvents.onShowModal("DRAFT");
                        }}>
                        Update Cost Rates
                    </Button>
                    <Table responsive hover>
                        <thead>
                            <TableHeaderRow
                                onSortBy={(s) => tableHeaderRowEvents.onSortBy(s)}
                                disabled={sourceData.inProgress}
                                headers={[
                                    { sortBy: "Classification.Name", label: "Classification" },
                                    { sortBy: "EffectiveDate", label: "Effective Date" },
                                    { sortBy: "Rate", label: "Rate" }
                                ]}
                            />
                        </thead>
                        <tbody>
                            {
                                sourceData.records.map((record, index) => (
                                    <CostRateRow key={index} {...record} disabled={sourceData.inProgress} />
                                ))
                            }
                        </tbody>
                    </Table>
                    <Paginator
                        onPageChange={(p) => paginatorEvents.onPageChange(p)}
                        searchOptions={sourceData.searchOptions}
                    />
                    <FormModal
                        size="lg"
                        isVisible={ModalActions.IsVisible(modals, "DRAFT")}
                        onHide={() => modalEvents.onHideModal("DRAFT")}
                        title={"Update Cost Rates"}>
                        <CostRateFormContainer
                            {...modalEvents}
                            {...confirmEvents}
                            modals={modals}
                            onSubmitUpdate={this.props.onPublishNewVersion}
                        />
                    </FormModal>
                </SpinnerOverlay>
            </div>
        )
    }
}

export default CostRateList