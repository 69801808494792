import { connect } from "react-redux"
import CostCodeGroupCardExtended from "components/costCodeGroups/layout/CostCodeGroupCardExtended"
import CostCodeGroupList from "components/costCodeGroups/layout/CostCodeGroupList"
import { CostCodeGroupScope } from "components/costCodeGroups/redux/CostCodeGroupScope"
import { FormEvents } from "@gman/gman-redux"

const mapDispatchToProps = (dispatch) => {
  var scope = CostCodeGroupScope
  return {
    ...FormEvents.getConfirmEventsForModalsOnNewOnly(dispatch, scope),
    ...FormEvents.getFeedbackEvents(dispatch, scope),
    ...FormEvents.getModalEventsForModalsOnNewOnly(dispatch, scope),
    ...FormEvents.getPaginatorEvents(dispatch, scope),
    ...FormEvents.getRecordStateEvents(dispatch, scope),
    ...FormEvents.getTableHeaderRowEvents(dispatch, scope),
    ...FormEvents.getTabEvents(dispatch, scope),
  }
}

const CostCodeGroupExtendedContainer = connect(
  (state, ownProps) => {
    return {
      sourceData: CostCodeGroupScope.selector(state),
      costCodeGroupId: ownProps.costCodeGroupId
    }
  },
  mapDispatchToProps
)(CostCodeGroupCardExtended)

const CostCodeGroupListContainer = connect(
  (state, ownProps) => {
    return {
      sourceData: CostCodeGroupScope.selector(state),
      clientId: ownProps.clientId
    }
  },
  mapDispatchToProps
)(CostCodeGroupList)

export { CostCodeGroupExtendedContainer, CostCodeGroupListContainer }