import React, { Component } from "react"
import { Redirect } from "react-router"
import { Card, CardBody, CardHeader } from "reactstrap"
import LoginForm from "components/authentication/form/LoginForm"
import Icon from "components/common/Icon"
import { SpinnerOverlay } from "@gman/gman-react-bootstrap"
import FeedbackAlert from "components/common/FeedbackAlert"

class LoginCardBasic extends Component {

    render() {
        var feedbackEvents = this.props.feedbackEvents
        var sourceData = this.props.sourceData

        if (this.props.sourceData && this.props.sourceData.isLoggedIn === true) {
            return <Redirect to="/" />
        }

        return (
            <Card>
                <CardHeader>
                    <h6>
                        <Icon lock />
                        Login
                </h6>
                </CardHeader>
                <CardBody>
                    <SpinnerOverlay isLoading={sourceData.inProgress}>
                        <FeedbackAlert {...feedbackEvents} feedback={sourceData.feedback} />
                        <LoginForm onSubmitLogin={this.props.onSubmitLogin} />
                    </SpinnerOverlay>
                </CardBody>
            </Card >
        )
    }
}

export default LoginCardBasic