import { connect } from "react-redux"
import ExpenseTypeList from "components/expenseTypes/layout/ExpenseTypeList"
import { ExpenseTypeScope } from "components/expenseTypes/redux/ExpenseTypeScope"
import { FormEvents } from "@gman/gman-redux"

const mapDispatchToProps = (dispatch) => {
  var scope = ExpenseTypeScope
  return {
    ...FormEvents.getConfirmEventsForModalsOnAll(dispatch, scope),
    ...FormEvents.getTableHeaderRowEvents(dispatch, scope),
    ...FormEvents.getFeedbackEvents(dispatch, scope),
    ...FormEvents.getPaginatorEvents(dispatch, scope),
    ...FormEvents.getModalEventsForModalsOnAll(dispatch, scope),
    ...FormEvents.getRecordStateEvents(dispatch, scope)
  }
}

const ExpenseTypeListContainer = connect(
  state => { return { sourceData: ExpenseTypeScope.selector(state) } },
  mapDispatchToProps
)(ExpenseTypeList)

export { ExpenseTypeListContainer }