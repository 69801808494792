import React from "react"
import Moment from "moment"
import { withFormik } from "formik"
import * as Yup from "yup"
import { SelectOption } from "@gman/gman-redux"
import VariationStatusConstants from "constants/VariationStatusConstants"
import VariationTypeConstants from "constants/VariationTypeConstants"
import Icon from "components/common/Icon"
import RequiredStar from "components/common/RequiredStar"
import FeedbackAlert from "components/common/FeedbackAlert"

import {
    Button,
    ButtonGroup,
    Col,
    Container,
    Form,
    FormGroup,
    Input,
    InputGroup,
    InputGroupAddon,
    InputGroupText,
    Label,
    Row
} from "reactstrap"

const BaseForm = props => {
    const {
        values,
        dirty,
        errors,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
        setFieldValue,
        feedbackEvents,
        feedback
    } = props
    var isFinalised = isFinalisedStatus(values.status)
    return (
        <div>
            <Form noValidate onSubmit={handleSubmit}>
                {
                    feedback && feedbackEvents ?
                        <FeedbackAlert {...feedbackEvents} feedback={feedback} />
                        : ""
                }
                <h6>
                    <Icon folderOpen />
                    Variation Details
                </h6>
                <hr />
                <Container>
                    <Row>
                        <Col xs={12} md={6}>
                            <FormGroup>
                                <RequiredStar />
                                <Label for="number">Variation Number</Label>
                                <Input
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.number || ""}
                                    type="text"
                                    name="number"
                                    id="number"
                                    placeholder="Enter a variation number"
                                    invalid={errors.number} />
                                {errors.number && <small className="text-danger">{errors.number}</small>}
                            </FormGroup>
                        </Col>
                        <Col xs={12} md={6}>
                            <FormGroup>
                                <RequiredStar />
                                <Label for="description">Description</Label>
                                <Input
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.description || ""}
                                    type="text"
                                    name="description"
                                    id="description"
                                    placeholder="Enter a variation description"
                                    invalid={errors.description} />
                                {errors.description && <small className="text-danger">{errors.description}</small>}
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12} md={6}>
                            <FormGroup>
                                <RequiredStar />
                                <Label for="submissionDate">Date Submitted to Client</Label>
                                <Input
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.submissionDate ? Moment(values.submissionDate).format("YYYY-MM-DD") : ""}
                                    type="date"
                                    name="submissionDate"
                                    id="submissionDate"
                                    placeholder="Date Submitted to Client"
                                    invalid={errors.submissionDate} />
                                {errors.submissionDate && <small className="text-danger">{errors.submissionDate}</small>}
                            </FormGroup>
                        </Col>
                        <Col xs={12} md={6}>
                            <FormGroup>
                                <RequiredStar />
                                <Label for="projectId">Project</Label>
                                <SelectOption
                                    isClearable={true}
                                    disabled={true}
                                    value={values.projectId}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    id="projectId"
                                    invalid={errors.projectId && errors.projectId.length > 0}
                                    stateFunc={(state) => { return { ...state.staticProjects } }}
                                    valueFunc={(record) => record.projectId}
                                    labelFunc={(record) => record.name}
                                    sortFunc={(a, b) => a.name.localeCompare(b.name)}
                                />
                                {errors.projectId && <small className="text-danger">{errors.projectId}</small>}
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12} md={6}>
                            <FormGroup>
                                <RequiredStar />
                                <Label for="type">Variation Type</Label>
                                <Input
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    type="select"
                                    name="type"
                                    value={values.type || ""}
                                    invalid={errors.type}
                                    id="type"
                                >
                                    <option value={VariationTypeConstants.VARIATION}>Variation</option>
                                    <option value={VariationTypeConstants.RISK}>Risk</option>
                                </Input>
                            </FormGroup>
                        </Col>
                    </Row>
                </Container>
                <h6>
                    <Icon clock />
                    Fee and Program
                </h6>
                <hr />
                <Container>
                    <Row>
                        <Col xs={12} md={6}>
                            <FormGroup>
                                <RequiredStar />
                                <Label for="labourFee">CMP Labour Fee</Label>
                                <InputGroup>
                                    <InputGroupAddon addonType="prepend">
                                        <InputGroupText>$</InputGroupText>
                                    </InputGroupAddon>
                                    <Input
                                        onChange={e => {
                                            var updatedOriginalFee = Number(e.target.value) + Number(values.expenses) + Number(values.subcontractorFee);
                                            console.log(e.target.value, values.expenses, values.subcontractorFee);
                                            setFieldValue(
                                                'variationFee',
                                                updatedOriginalFee.toFixed(2)
                                            );
                                            handleChange(e);
                                        }}
                                        onBlur={handleBlur}
                                        value={values.labourFee || ""}
                                        type="text"
                                        name="labourFee"
                                        id="labourFee"
                                        placeholder="Enter the CMP labour fee"
                                        invalid={errors.labourFee} />
                                </InputGroup>
                                {errors.labourFee && <small className="text-danger">{errors.labourFee}</small>}
                            </FormGroup>
                        </Col>
                        <Col xs={12} md={6}>
                            <FormGroup>
                                <RequiredStar />
                                <Label for="expenses">Expenses</Label>
                                <InputGroup>
                                    <InputGroupAddon addonType="prepend">
                                        <InputGroupText>$</InputGroupText>
                                    </InputGroupAddon>
                                    <Input
                                        onChange={e => {
                                            var updatedOriginalFee = Number(e.target.value) + Number(values.labourFee) + Number(values.subcontractorFee)
                                            setFieldValue(
                                                'variationFee',
                                                updatedOriginalFee.toFixed(2)
                                            );
                                            handleChange(e);
                                        }}
                                        onBlur={handleBlur}
                                        value={values.expenses || ""}
                                        type="text"
                                        name="expenses"
                                        id="expenses"
                                        placeholder="Enter the expenses"
                                        invalid={errors.expenses} />
                                </InputGroup>
                                {errors.expenses && <small className="text-danger">{errors.expenses}</small>}
                            </FormGroup>
                        </Col>
                        <Col xs={12} md={6}>
                            <FormGroup>
                                <RequiredStar />
                                <Label for="subcontractorFee">Subcontractors Fee</Label>
                                <InputGroup>
                                    <InputGroupAddon addonType="prepend">
                                        <InputGroupText>$</InputGroupText>
                                    </InputGroupAddon>
                                    <Input
                                        onChange={e => {
                                            var updatedOriginalFee = Number(e.target.value) + Number(values.labourFee) + Number(values.expenses)
                                            setFieldValue(
                                                'variationFee',
                                                updatedOriginalFee.toFixed(2)
                                            );
                                            handleChange(e);
                                        }}
                                        onBlur={handleBlur}
                                        value={values.subcontractorFee || ""}
                                        type="text"
                                        name="subcontractorFee"
                                        id="subcontractorFee"
                                        placeholder="Enter the subcontractors fee"
                                        invalid={errors.subcontractorFee} />
                                </InputGroup>
                                {errors.subcontractorFee && <small className="text-danger">{errors.subcontractorFee}</small>}
                            </FormGroup>
                        </Col>
                        <Col xs={12} md={6}>
                            <FormGroup>
                                <RequiredStar />
                                <Label for="variationFee">Total Original Fee</Label>
                                <InputGroup>
                                    <InputGroupAddon addonType="prepend">
                                        <InputGroupText>$</InputGroupText>
                                    </InputGroupAddon>
                                    <Input
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.variationFee || ""}
                                        type="text"
                                        name="variationFee"
                                        id="variationFee"
                                        placeholder="Enter the variation fee"
                                        invalid={errors.variationFee}
                                        disabled={true}                                    />
                                </InputGroup>
                                {errors.variationFee && <small className="text-danger">{errors.variationFee}</small>}
                            </FormGroup>
                        </Col>
                        <Col xs={12} md={6}>
                            <FormGroup>
                                <Label for="daysExtension">Days Extension</Label>
                                <Input
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.daysExtension || ""}
                                    type="number"
                                    step={1}
                                    name="daysExtension"
                                    id="daysExtension"
                                    placeholder="Enter the days extension"
                                    invalid={errors.daysExtension} />
                                {errors.daysExtension && <small className="text-danger">{errors.daysExtension}</small>}
                            </FormGroup>
                        </Col>
                    </Row>
                </Container>
                <h6>
                    <Icon workflow />
                    Workflow
                </h6>
                <hr />
                <Container>
                    <Row>
                        <Col xs={12} md={6}>
                            <FormGroup>
                                <Label for="status">Variation Status</Label>
                                <Input
                                    onChange={e => {
                                        handleChange(e)
                                        setFieldValue("isFinalised", isFinalisedStatus(e.target.value))
                                    }}
                                    onBlur={handleBlur}
                                    type="select"
                                    name="status"
                                    value={values.status || ""}
                                    invalid={errors.status}
                                    id="status"
                                >
                                    <option value={VariationStatusConstants.DRAFT}>Draft</option>
                                    <option value={VariationStatusConstants.PROPOSED}>Proposed</option>
                                    <option value={VariationStatusConstants.APPROVED}>Approved</option>
                                    <option value={VariationStatusConstants.REJECTED}>Rejected</option>
                                    <option value={VariationStatusConstants.CLOSED}>Closed</option>
                                </Input>
                            </FormGroup>
                        </Col>
                        {
                            isFinalised ?
                                <Col xs={12} md={6}>
                                    <FormGroup>
                                        <Label for="approvalDate">Approval / Rejection Date</Label>
                                        <Input
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.approvalDate ? Moment(values.approvalDate).format("YYYY-MM-DD") : ""}
                                            type="date"
                                            name="approvalDate"
                                            id="approvalDate"
                                            placeholder="Approval Date"
                                            invalid={errors.approvalDate} />
                                        {errors.approvalDate && <small className="text-danger">{errors.approvalDate}</small>}
                                    </FormGroup>
                                </Col>
                                : ""
                        }
                    </Row>
                    <ButtonGroup className="float-right">
                        <Button outline disabled={isSubmitting || !dirty} color="warning" type="submit">Update</Button>
                    </ButtonGroup>
                </Container>
                <Input
                    onChange={handleChange}
                    value={isFinalised}
                    type="hidden"
                    name="isFinalised"
                    id="isFinalised"
                />
            </Form>
        </div>
    )
}

function isFinalisedStatus(status) {
    var finalisedStatus =
        ((status || "").toString() === VariationStatusConstants.APPROVED.toString() ||
            (status || "").toString() === VariationStatusConstants.REJECTED.toString())
    return finalisedStatus
}

const validationSchema = Yup.object({
    number: Yup.string()
        .required("Number is required")
        .matches(/^[0-9]{2}$/, "Must be two digit number"),
    description: Yup.string()
        .required("Description is required"),
    projectId: Yup.string()
        .required("Project is required")
        .nullable(),
    
    submissionDate: Yup.date()
        .required("Submission date is required")
        .typeError("Must be a date"),
    approvalDate: Yup.date()
        .when("isFinalised", {
            is: true,
            then: Yup.date()
                .required("Approval date is required")
                .typeError("Must be a date")
                .min(Yup.ref("submissionDate"), "Approval / Rejection date cannot precede Submission date")
        }),
    variationFee:
        numberValidation()
            .default(0)
            .min(0, "Must be positive"),
    subcontractorFee:
        numberValidation()
            .default(0)
            .min(0, "Must be positive"),
    labourFee:
        numberValidation()
            .min(1, "Must be greater than zero"),
    expenses:
        numberValidation()
            .default(0)
            .min(0, "Must be positive"),
})

function numberValidation() {
    return Yup.number()
        .nullable()
        .required("Value is required")
        .round()
        .positive("Must be positive")
        .typeError("Must be a number")
}

const VariationFormBasic = withFormik({
    displayName: "VariationFormBasic",
    enableReinitialize: true,
    validationSchema: validationSchema,
    mapPropsToValues: ({ ...props }) => ({
        ...props,
        submissionDate: props.submissionDate || "",
        labourFee: props.labourFee || "0",
        expenses: props.expenses || "0",
        subcontractorFee: props.subcontractorFee || "0",
        approvalDate: props.approvalDate || "",
        isFinalised: isFinalisedStatus(props.status)
    }),
    handleSubmit: (values, { props, setSubmitting }) => {
        props.onSubmitUpdate(values)
        setSubmitting(false)
    }
})(BaseForm)

export default VariationFormBasic