import ScopeConstants from "constants/ScopeConstants"
import { FormActionReducer, FormActionConstants } from "@gman/gman-redux"

export const ChargeRateListReducer = (
  state = {
    ...FormActionReducer.defaultPageState(),
    ...FormActionReducer.defaultRecordState(),
    ...FormActionReducer.defaultSearchOptions("EffectiveDate", 200, true),
    ...FormActionReducer.defaultTabs()
  },
  action) => {

  if (action.scope === ScopeConstants.CHARGE_RATE_LIST) {
    if (action.type === FormActionConstants.RECEIVE_RECORDS) {
      return {
        ...state,
        currentRatesType: action.currentRatesType,
        usingRatesType: action.usingRatesType,
        records: action.records,
        searchOptions: {
          ...state.searchOptions,
          totalRecords: action.totalRecords
        }
      }
    }

    return FormActionReducer.reduce(action, state)
  }

  return state
}