import ScopeConstants from "constants/ScopeConstants"
import OpportunityStatusConstants from "constants/OpportunityStatusConstants"

export const OpportunityAnyScope = {
    key: ScopeConstants.OPPORTUNTIY_ANY,
    selector: (state) => state.opportunitiesAny,
    tokenSelector: (state) => state.authentication.token,
    fetchEndpoint: "/api/Opportunity/SearchAny/",
    singleEndpoint: "/api/Opportunity/GetAny/",
    updateEndpoint: "/api/Opportunity/UpdateAny/",
    deleteEndpoint: "/api/Opportunity/DeleteAny/",
    publishEndpoint: "/api/Opportunity/PublishAny/",
    defaultRecord: {
        name: "",
        code: "",
        status: OpportunityStatusConstants.DRAFT
    }
}