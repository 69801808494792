import ScopeConstants from "constants/ScopeConstants"
import { ScopeShape } from "@gman/gman-redux"
import PropTypes from "prop-types"

export const ClassificationScope = {
    key: ScopeConstants.CLASSIFICATION,
    selector: (state) => state.classifications,
    tokenSelector: (state) => state.authentication.token,
    fetchEndpoint: "/api/Classification/Search/",
    updateEndpoint: "/api/Classification/Update",
    deleteEndpoint: "/api/Classification/Delete",
    defaultRecord: {
        name: ""
    }
}

ClassificationScope.propTypes = PropTypes.shape(ScopeShape).isRequired