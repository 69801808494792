import React from "react"
import PropTypes from "prop-types"
import { Button } from "reactstrap"
import { TimesheetEntryShape } from "shapes/TimesheetEntryShape"
import TimeCell from "components/common/TimeCell"
import CommentBadge from "components/common/CommentBadge"
import Icon from "components/common/Icon"
import WorkItemCell from "components/common/WorkItemCell"
import { AuthorizedSummaryToolbar } from "components/authentication/controls/AuthorizedControls"
import ScopeConstants from "constants/ScopeConstants"
import { UncontrolledTooltip } from 'reactstrap';
import VariationStatusConstants from "constants/VariationStatusConstants"

const Toolbar = AuthorizedSummaryToolbar(p => p.canCompleteOwnTimesheet)

const TimesheetEntryRow = (
    {
        index,
        workItem,
        saturdayHours,
        sundayHours,
        mondayHours,
        tuesdayHours,
        wednesdayHours,
        thursdayHours,
        fridayHours,
        comment,
        workflowPermissions,
        onShowEdit,
        onShowDelete,
        onAddLinkedExpense,
        disabled
    }) => {
        const editEvent = workItem.variation ? workItem.variation.status === 'Closed'? undefined : onShowEdit : onShowEdit;
        return (
        <tr>
            <td onClick={editEvent}>
                <WorkItemCell workItem={workItem} />
            </td>
            <TimeCell onClick={editEvent} value={saturdayHours} className="weekend" />
            <TimeCell onClick={editEvent} value={sundayHours} className="weekend" />
            <TimeCell onClick={editEvent} value={mondayHours} />
            <TimeCell onClick={editEvent} value={tuesdayHours} />
            <TimeCell onClick={editEvent} value={wednesdayHours} />
            <TimeCell onClick={editEvent} value={thursdayHours} />
            <TimeCell onClick={editEvent} value={fridayHours} />
            <TimeCell onClick={editEvent} value=
                {
                    (
                        saturdayHours +
                        sundayHours +
                        mondayHours +
                        tuesdayHours +
                        wednesdayHours +
                        thursdayHours +
                        fridayHours
                    )
                }
                className="weekend"
            />
            <td onClick={editEvent}>
                <CommentBadge className="hide-on-larger" index={index} comment={comment} />
                <div className="hide-on-smaller">
                    {comment}
                </div>
            </td>
            <td>
                {
                    workflowPermissions && workflowPermissions.canEdit || workflowPermissions.canEditApproved ?
                        // <Toolbar disabled={disabled} onShowEdit={onShowEdit} onShowDelete={onShowDelete} showText={false}>
                        <Toolbar disabled={disabled} onShowDelete={onShowDelete}  showText={false}>
                            {
                                workItem && workflowPermissions.canAddLinked &&
                                    (workItem.type === ScopeConstants.PROJECT_ANY || workItem.type === ScopeConstants.PROPOSAL_ANY ||
                                        (workItem.type === ScopeConstants.LEAVE && (workItem.code === "MISC" || workItem.code === "R&D ACTIVITY"))) === true ?
                                    <Button id={"expenseTooltip" + index} outline onClick={() => onAddLinkedExpense(workItem)}>
                                        <Icon expense />
                                    </Button>
                                    : ""
                            }
                            {
                                workItem && workflowPermissions.canAddLinked &&
                                    (workItem.type === ScopeConstants.PROJECT_ANY || workItem.type === ScopeConstants.PROPOSAL_ANY ||
                                        (workItem.type === ScopeConstants.LEAVE && (workItem.code === "MISC" || workItem.code === "R&D ACTIVITY"))) === true ?
                                    <UncontrolledTooltip placement="top" delay={{ show: 0, hide: 0 }} target={"expenseTooltip" + index}>
                                        Add a related expense
                                    </UncontrolledTooltip>
                                    : ""
                            }
                        </Toolbar>
                        : ""
                }
            </td>
        </tr>
         ) }

TimesheetEntryRow.propTypes = PropTypes.shape(TimesheetEntryShape).isRequired

export default TimesheetEntryRow 