import { FormModal } from "@gman/gman-react-bootstrap"
import { ModalActions } from "@gman/gman-redux"
import Icon from "components/common/Icon"
import { withFormik } from "formik"
import React, { useState } from "react"
import { Button, ButtonGroup, Input } from "reactstrap"
import * as Yup from "yup"
import TimesheetEntryCommentForm from "./TimesheetEntryCommentForm"
import TimesheetEntryWorkSelectForm from "./TimesheetEntryWorkSelectForm"
import WorkItemCell from "components/common/WorkItemCell"

const BaseForm = props => {
    const {
        values,
        modals,
        dirty,
        errors,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
        onHide,
        onShowModal,
        onHideModal,
        setFieldValue,
        handleHoursChange,
        handleCommentChange,
        handleWorkItemChange
    } = props
    return (
        <tr>
            <td>
                {
                    values.workItem ?

                        <WorkItemCell workItem={values.workItem} />
                        : ""
                }
                <Button block color="light" size="sm" onClick={() => onShowModal("SELECT_WORK_ITEM")}>
                    <Icon book />
                    Select Project
                        </Button>
                {errors.workItem && <small className="text-danger">{errors.workItem.workItemId}</small>}
            </td>
            <th onClick={handleSubmit}>{hoursField("saturdayHours", values.saturdayHours, errors.saturdayHours, handleChange, handleBlur, handleHoursChange, handleWorkItemChange, values.workItem)}</th>
            <th onClick={handleSubmit}>{hoursField("sundayHours", values.sundayHours, errors.sundayHours, handleChange, handleBlur, handleHoursChange, handleWorkItemChange, values.workItem)}</th>
            <th onClick={handleSubmit}>{hoursField("mondayHours", values.mondayHours, errors.mondayHours, handleChange, handleBlur, handleHoursChange, handleWorkItemChange, values.workItem)}</th>
            <th onClick={handleSubmit}>{hoursField("tuesdayHours", values.tuesdayHours, errors.tuesdayHours, handleChange, handleBlur, handleHoursChange, handleWorkItemChange, values.workItem)}</th>
            <th onClick={handleSubmit}>{hoursField("wednesdayHours", values.wednesdayHours, errors.wednesdayHours, handleChange, handleBlur, handleHoursChange, handleWorkItemChange, values.workItem)}</th>
            <th onClick={handleSubmit}>{hoursField("thursdayHours", values.thursdayHours, errors.thursdayHours, handleChange, handleBlur, handleHoursChange, handleWorkItemChange, values.workItem)}</th>
            <th onClick={handleSubmit}>{hoursField("fridayHours", values.fridayHours, errors.fridayHours, handleChange, handleBlur, handleHoursChange, handleWorkItemChange, values.workItem)}</th>
            <th onClick={handleSubmit}>
                {
                    (
                        getNumber(values.saturdayHours) +
                        getNumber(values.sundayHours) +
                        getNumber(values.mondayHours) +
                        getNumber(values.tuesdayHours) +
                        getNumber(values.wednesdayHours) +
                        getNumber(values.thursdayHours) +
                        getNumber(values.fridayHours)
                    ).toFixed(2)
                }
            </th>
            <th onClick={handleSubmit}>
                <Input
                    onChange={
                        (e) => {
                             console.log("textareaComment",e.target.value.substring(0, 1));
                             if(e.target.value.substring(0, 1) == "+" || e.target.value.substring(0, 1) == "-" || e.target.value.substring(0, 1) == "="){
                                alert("“Do not use “-” “+” or “=” as your first character”");
                             }
                             else{
                                 handleChange(e);
                                 handleCommentChange(e);
                             }
                        }
                    }
                    onBlur={handleBlur}
                    onClick={(event)=>event.stopPropagation()}
                    value={values.comment || ""}
                    type="textarea"
                    name="comment"
                    onShowEdit
                    id="comment"
                />
            </th>
            <th>
                {/* <div>
                    <ButtonGroup size="sm">
                        <Button outline className="float-right" disabled={isSubmitting || !dirty} color="success" onClick={handleSubmit}><Icon done rightSpace={false} /></Button>
                        <Button outline className="float-right" disabled={isSubmitting} color="secondary" onClick={onHide}><Icon close rightSpace={false} /></Button>
                    </ButtonGroup>
                </div> */}
            </th>
            <Input
                onChange={handleChange}
                value={values.workItem && values.workItem.workItemId || ""}
                type="hidden"
                name="workItemId"
                id="workItemId"
            />
            <FormModal
                size="lg"
                isVisible={ModalActions.IsVisible(modals, "SELECT_WORK_ITEM")}
                onHide={() => onHideModal("SELECT_WORK_ITEM")}
                title={"Select Project"}>
                <TimesheetEntryWorkSelectForm
                    {...ConstructWorkItem(values)}
                    onSelectEntry={(selection) => {
                        var workItem = selection.workItem
                        setFieldValue("workItem", workItem)
                        handleWorkItemChange(workItem);
                        onHideModal("SELECT_WORK_ITEM")
                    }
                    } />
            </FormModal>
            <FormModal
                isVisible={ModalActions.IsVisible(modals, "VIEW_COMMENT")}
                onHide={() => onHideModal("VIEW_COMMENT")}
                title={"Update Comment"}>
                <TimesheetEntryCommentForm
                    comment={values.comment}
                    onSelectEntry={(selection) => {
                        setFieldValue("comment", selection.comment)
                        onHideModal("VIEW_COMMENT")
                    }
                    } />
            </FormModal>
        </tr >
    )
}

const getNumber = (value) => {
    var number = parseFloat(value)

    if (isNaN(number))
        return 0

    return number
}

const hoursField = (id, value, error, handleChange, handleBlur, handleHoursChange, handleWorkItemChange, workItem) => {
    const style={
        zIndex:10
    }
    return <>
        {
            <Input
                className="hours"
                bsSize="sm"
                onChange={(e) => {
                    handleChange(e);
                    handleHoursChange(e);
                    handleWorkItemChange(workItem);
                }}
                onBlur={handleBlur}
                value={value}
                style={style}
                onClick={(event)=>event.stopPropagation()}
                type="number"
                step={0.25}
                name={id}
                id={id}
                invalid={error} />
        }

        </>
}

const ConstructWorkItem = (record) => {
    return {
        workItem: {
            workItemId: record.workItem && record.workItem.workItemId,
            costCodeGroupId: record.workItem && record.workItem.costCodeGroupId,
            type: record.workItem && record.workItem.type,
            name: record.workItem && record.workItem.name,
            variation: record.workItem && (record.workItem.variation),
            costCode: {
                costCodeId: record.workItem && record.workItem.costCode && (record.workItem.costCode.costCodeId),
                costCodeGroupId: record.workItem && record.workItem.costCodeGroupId
            }
        }
    }
}

const validationSchema = Yup.object({
    workItem: Yup.object().shape({
        workItemId: Yup.string()
            .required("Project is required")
            .nullable()
    }),
    saturdayHours: numberValidation(),
    sundayHours: numberValidation(),
    mondayHours: numberValidation(),
    tuesdayHours: numberValidation(),
    wednesdayHours: numberValidation(),
    thursdayHours: numberValidation(),
    fridayHours: numberValidation()
})

function numberValidation() {
    return Yup.string()
        .matches(/^(-)?\d+(\.(25|50|5|75|0|00)?)?$/, {
            message: "Must be numeric with .25 hour intervals",
            excludeEmptyString: true
        })
}

const TimesheetEntryForm = withFormik({
    displayName: "TimesheetEntryForm",
    enableReinitialize: true,
    validationSchema: validationSchema,
    handleSubmit: (values, { props, setSubmitting }) => {
        values.mondayHours = values.mondayHours || 0.0
        values.tuesdayHours = values.tuesdayHours || 0.0
        values.wednesdayHours = values.wednesdayHours || 0.0
        values.thursdayHours = values.thursdayHours || 0.0
        values.fridayHours = values.fridayHours || 0.0
        values.saturdayHours = values.saturdayHours || 0.0
        values.sundayHours = values.sundayHours || 0.0
        props.onSubmitUpdate(values)
        setSubmitting(false)
    }
})(BaseForm)

export default TimesheetEntryForm