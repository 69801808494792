import React from "react"
import PropTypes from "prop-types"
import { Link } from "react-router-dom"
import { UserShape } from "shapes/UserShape"
import ActiveCell from "components/common/ActiveCell"
import { AuthorizedSummaryToolbar } from "components/authentication/controls/AuthorizedControls"

const Toolbar = AuthorizedSummaryToolbar(p => p.canWriteUsers)

const UserRow = (
    {
        userId,
        firstName,
        lastName,
        latestClassification,
        manager,
        emailAddress,
        onShowEdit,
        onShowDelete,
        active,
        disabled
    }) => (
        <tr>
            <ActiveCell active={active}>
                <Link to={"/userDetails/" + userId} onClick={onShowEdit}>
                    {emailAddress}
                </Link>
            </ActiveCell>

            <ActiveCell active={active}>{firstName}</ActiveCell>
            <ActiveCell active={active}>{lastName}</ActiveCell>
            <ActiveCell active={active}>{manager && manager.firstName} {manager && manager.lastName}</ActiveCell>
            <ActiveCell active={active}>{latestClassification}</ActiveCell>
            <td>
                <Toolbar disabled={disabled} onShowDelete={onShowDelete} />
            </td>
        </tr>
    )

UserRow.propTypes = PropTypes.shape(UserShape).isRequired

export { UserRow }