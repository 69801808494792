import React, { Component } from "react";
import { Table } from "reactstrap";
import ReactDOM from 'react-dom';
import {
    ConfirmDelete,
    FormModal,
    Paginator,
    SpinnerOverlay
} from "@gman/gman-react-bootstrap";
import TableHeaderRow from "components/common/TableHeaderRow";
import TimesheetEntryEditRow from "./TimesheetEntryEditRow";
import TimesheetEntryEditRowForm from "../form/TimesheetEntryEditRowForm";
import FilterForm from "components/common/FilterForm";
import { ModalActions } from "@gman/gman-redux";
import PageSizeSelect from "components/common/PageSizeSelect"

class TimesheetEntryEditList extends Component {
    state = {
        isRowChanges: false
    }

    constructor(props) {
        super(props);
        this.handleTimesheetFilterChange = this.handleTimesheetFilterChange.bind(this);
    }

    componentDidMount() {
        let body = document.getElementsByTagName("body")[0];
        if (this.props.projectId) {
            console.log("componentDidMount-aaa", this.props.projectId);
            var searchModel = {
                searchBy: 'Project',
                searchValue: this.props.projectId
            }

            if (this.props.expectedClientInvoiceId) {
                searchModel.expectedClientInvoiceId = this.props.expectedClientInvoiceId;
                document.addEventListener('mousedown', this.handleOutsideModalClick, true);
            } else {
                body.addEventListener('mousedown', this.handleOutsideClick, true);
            }
            this.props.recordStateEvents.onClearSearch(searchModel);
            body.addEventListener('mousedown', this.handleOutsideClick, true);
        } else {
            this.props.recordStateEvents.onClearSearch();
            body.addEventListener('mousedown', this.handleOutsideClick, true);
        }
    }

    componentDidUpdate(prevProps) {
        // Typical usage (don't forget to compare props):
        if (this.props.activeTab == 1 && prevProps.activeTab != this.props.activeTab) {
            if (this.props.projectId) {
                console.log("componentDidUpdate-aaa", this.props.projectId);
                var searchModel = {
                    searchBy: 'Project',
                    searchValue: this.props.projectId
                }


                if (this.props.expectedClientInvoiceId) {
                    searchModel.expectedClientInvoiceId = this.props.expectedClientInvoiceId;
                }
                this.props.recordStateEvents.onClearSearch(searchModel);
            } else {
                this.props.recordStateEvents.onClearSearch();
            }
        }
    }

    componentWillUnmount = () => {
        let body = document.getElementsByTagName("body")[0];
        document.removeEventListener('mousedown', this.handleOutsideModalClick, true);
        body.removeEventListener('mousedown', this.handleOutsideClick, true);
        if (this.props.projectId) {
            console.log("componentWillUnmount-aaa", this.props.projectId);
            this.props.clearClientInvoiceStatusState();
            var searchModel = {
                // searchBy: 'Project',
                // searchValue: this.props.projectId
            }

            // this.props.recordStateEvents.onClearSearch(searchModel);
        }
    };

    handleTimesheetFilterChange(values) {
        this.props.recordStateEvents.onClearSearch(values);
    }

    handleHoursChange = (e) => {
        let { name, value } = e.target;
        var number = parseFloat(value)
        this.props.sourceData.recordToUpdate[name] = number;
        this.setState({ isRowChanges: true });
    }

    handleCommentChange = (e) => {
        let { name, value } = e.target;
        this.props.sourceData.recordToUpdate[name] = value;
        this.setState({ isRowChanges: true });
    }

    handleWorkItemChange = (workItem) => {
        this.props.sourceData.recordToUpdate['workItem'] = workItem;
        this.setState({ isRowChanges: true });
    }

    handleCostItemsStausChange = (e) => {
        if (this.state.isRowChanges) {
            let { name, value } = e.target;
            this.props.sourceData.recordToUpdate[name] = value;
            this.setState({ isRowChanges: true });
        }
    }

    handleOutsideClick = (e) => {
        if (this.props.isOpneInModal) {
            e.stopPropagation();
            return;
        }
        let that = this;
        let modals = that.props.sourceData.modals;
        const domNode = ReactDOM.findDOMNode(this);
        if (!ModalActions.IsVisible(modals, "SELECT_WORK_ITEM")) {
            if (!domNode || !domNode.contains(e.target) || e.target.classList.contains("not-editable")) {
                if (ModalActions.IsUpdateVisible(modals)) {
                    if (this.state.isRowChanges) {
                        let recordToUpdate = this.props.sourceData.recordToUpdate;
                        recordToUpdate.mondayHours = recordToUpdate.mondayHours || 0.0;
                        recordToUpdate.tuesdayHours = recordToUpdate.tuesdayHours || 0.0;
                        recordToUpdate.wednesdayHours = recordToUpdate.wednesdayHours || 0.0;
                        recordToUpdate.thursdayHours = recordToUpdate.thursdayHours || 0.0;
                        recordToUpdate.fridayHours = recordToUpdate.fridayHours || 0.0;
                        recordToUpdate.saturdayHours = recordToUpdate.saturdayHours || 0.0;
                        recordToUpdate.sundayHours = recordToUpdate.sundayHours || 0.0;
                        this.props.onTimeSheetEditRowChange(recordToUpdate)
                            .then((result) => {
                                that.props.modalEvents.onHideEdit();
                                that.setState({ isRowChanges: false });
                            });
                    } else {
                        that.props.modalEvents.onHideEdit();
                        that.setState({ isRowChanges: false });
                    }
                }
            }
        }
    }

    handleOutsideModalClick = (e) => {
        if (this.props.isOpneInModal) {
            e.stopPropagation();

            let that = this;
            let modals = that.props.sourceData.modals;
            const domNode = ReactDOM.findDOMNode(this);
            if (!ModalActions.IsVisible(modals, "SELECT_WORK_ITEM")) {
                if (!domNode || !domNode.contains(e.target) || e.target.classList.contains("not-editable")) {
                    if (ModalActions.IsUpdateVisible(modals)) {
                        if (this.state.isRowChanges) {
                            let recordToUpdate = this.props.sourceData.recordToUpdate;
                            recordToUpdate.mondayHours = recordToUpdate.mondayHours || 0.0;
                            recordToUpdate.tuesdayHours = recordToUpdate.tuesdayHours || 0.0;
                            recordToUpdate.wednesdayHours = recordToUpdate.wednesdayHours || 0.0;
                            recordToUpdate.thursdayHours = recordToUpdate.thursdayHours || 0.0;
                            recordToUpdate.fridayHours = recordToUpdate.fridayHours || 0.0;
                            recordToUpdate.saturdayHours = recordToUpdate.saturdayHours || 0.0;
                            recordToUpdate.sundayHours = recordToUpdate.sundayHours || 0.0;
                            this.props.onTimeSheetEditRowChange(recordToUpdate)
                                .then((result) => {
                                    that.props.modalEvents.onHideEdit();
                                    that.setState({ isRowChanges: false });
                                });
                        } else {
                            that.props.modalEvents.onHideEdit();
                            that.setState({ isRowChanges: false });
                        }
                    }
                }
            }
        }

    }

    render() {
        var sourceData = this.props.sourceData;
        var paginatorEvents = this.props.paginatorEvents;
        var tableHeaderRowEvents = this.props.tableHeaderRowEvents;
        var modalEvents = this.props.modalEvents;
        var modals = sourceData.modals;
        var canEditApproved = sourceData.records.length > 0 && sourceData.records[0].timesheet.workflowPermissions.canEditApproved;

        return (
            <div className="">
                <SpinnerOverlay isLoading={sourceData.inProgress}>
                    {
                        this.props.showFilter && <FilterForm handleSubmit={this.handleTimesheetFilterChange} />
                    }

                    <Table
                        responsive
                        bordered
                        size="sm"
                        hover>
                        <thead>
                            <TableHeaderRow
                                onSortBy={tableHeaderRowEvents.onSortBy}
                                disabled={sourceData.inProgress}
                                headers={[
                                    {
                                        sortBy: "Timesheet.User.FirstName",
                                        label: "Employee"
                                    },
                                    {
                                        sortBy: "Timesheet.WeekEnding",
                                        label: "Week Ending"
                                    },
                                    {
                                        sortBy: "Project.Name",
                                        label: "Project"
                                    },
                                    {
                                        sortBy: "SaturdayHours",
                                        label: "Sat",
                                        className: "timesheet-time weekend"
                                    },
                                    {
                                        sortBy: "SundayHours",
                                        label: "Sun",
                                        className: "timesheet-time weekend"
                                    },
                                    {
                                        sortBy: "MondayHours",
                                        label: "Mon",
                                        className: "timesheet-time"
                                    },
                                    {
                                        sortBy: "TuesdayHours",
                                        label: "Tue",
                                        className: "timesheet-time"
                                    },
                                    {
                                        sortBy: "WednesdayHours",
                                        label: "Wed",
                                        className: "timesheet-time"
                                    },
                                    {
                                        sortBy: "ThursdayHours",
                                        label: "Thu",
                                        className: "timesheet-time"
                                    },
                                    {
                                        sortBy: "FridayHours",
                                        label: "Fri",
                                        className: "timesheet-time"
                                    },
                                    { label: "Total", className: "timesheet-time weekend" },
                                    {
                                        sortBy: "Comment",
                                        label: "Comment",
                                        className: "timesheet-comments"
                                    },
                                    // {
                                    //     sortBy: "costRate",
                                    //     label: "Cost Rate"
                                    // },
                                    {
                                        sortBy: "chargeRate",
                                        label: "Charge Rate"
                                    },
                                    {
                                        sortBy: "chargeRate",
                                        label: " Charge Amount"
                                    },
                                    {
                                        label: "Invoice"
                                    },
                                    {
                                        label: "Defer"
                                    },
                                    {
                                        label: "Write Off"
                                    },
                                    {
                                        sortBy: "InvoiceNumber",
                                        label: "Client Invoice Number",
                                        className: "timesheet-comments"
                                    },
                                    {
                                        sortBy: "InvoiceNumber",
                                        label: "Client Invoice Status",
                                        className: "timesheet-comments"
                                    }
                                ]}
                            />
                        </thead>
                        <tbody>
                            {sourceData.records.map((record, index) => {
                                return record.timesheetEntryId ===
                                    sourceData.recordToUpdate.timesheetEntryId &&
                                    ModalActions.IsUpdateVisible(modals) ?
                                    (
                                        <TimesheetEntryEditRowForm
                                            {...modalEvents}
                                            modals={modals}
                                            key={index}
                                            index={index}
                                            onHide={modalEvents.onHideEdit}
                                            handleHoursChange={this.handleHoursChange}
                                            handleCommentChange={this.handleCommentChange}
                                            handleWorkItemChange={this.handleWorkItemChange}
                                            handleCostItemsStausChange={this.handleCostItemsStausChange}
                                            isOpneInModal={this.props.isOpneInModal}
                                            {...sourceData.recordToUpdate}
                                        />
                                    ) :
                                    (
                                        <TimesheetEntryEditRow
                                            key={index}
                                            index={index}
                                            isOpneInModal={this.props.isOpneInModal}
                                            onShowEdit={
                                                () => {
                                                    let that = this;
                                                    if (canEditApproved) {
                                                        if (ModalActions.IsUpdateVisible(modals) && that.state.isRowChanges) {
                                                            let recordToUpdate = sourceData.recordToUpdate;
                                                            recordToUpdate.mondayHours = recordToUpdate.mondayHours || 0.0;
                                                            recordToUpdate.tuesdayHours = recordToUpdate.tuesdayHours || 0.0;
                                                            recordToUpdate.wednesdayHours = recordToUpdate.wednesdayHours || 0.0;
                                                            recordToUpdate.thursdayHours = recordToUpdate.thursdayHours || 0.0;
                                                            recordToUpdate.fridayHours = recordToUpdate.fridayHours || 0.0;
                                                            recordToUpdate.saturdayHours = recordToUpdate.saturdayHours || 0.0;
                                                            recordToUpdate.sundayHours = recordToUpdate.sundayHours || 0.0;
                                                            that.props.onTimeSheetEditRowChange(sourceData.recordToUpdate)
                                                                .then((result) => {
                                                                    modalEvents.onShowEdit(record);
                                                                    that.setState({ isRowChanges: false });
                                                                });
                                                        } else {
                                                            modalEvents.onShowEdit(record);
                                                            that.setState({ isRowChanges: false });
                                                        }
                                                    }
                                                }
                                            }
                                            onCostItemStatusChanged={
                                                (record) => {
                                                    let that = this;
                                                    that.props.onUpdateCostItemStatus(record);
                                                }
                                            }
                                            {...record} />
                                    );
                            })}
                        </tbody>
                    </Table>
                    <Paginator
                        {...paginatorEvents}
                        searchOptions={sourceData.searchOptions}
                    />
                    <PageSizeSelect disabled={sourceData.inProgress} defaultPageSize={sourceData.searchOptions.pageSize} onChange={this.props.onPageSizeChange} />
                </SpinnerOverlay>
            </div>
        );
    }
}

export default TimesheetEntryEditList;
