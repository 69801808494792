import React from "react"
import Moment from "moment"
import { withFormik } from "formik"
import * as Yup from "yup"
import Icon from "components/common/Icon"
import RequiredStar from "components/common/RequiredStar"
import ProjectStatusConstants from "constants/ProjectStatusConstants"
import SelectStyles from "components/common/SelectStyles"
import { AuthorizedPublishingToolbar, AuthorizedSelectOption, AuthorizedInputField, AuthorizedDropDownField } from "components/authentication/controls/AuthorizedControls"
import { SortActions } from "@gman/gman-redux"
import {
    Col,
    Container,
    Form,
    FormGroup,
    InputGroup,
    InputGroupText,
    InputGroupAddon,
    Label,
    Row,
    Input
} from "reactstrap";
import InvoiceTimingTypeConstants from "constants/InvoiceTimingTypeConstants";

const PublishingToolbarGroup = AuthorizedPublishingToolbar(p => p.canWriteWorkItems)
const SelectOptions = AuthorizedSelectOption(p => p.canWriteWorkItems)
const InputField = AuthorizedInputField(p => p.canWriteWorkItems)
const ProjectStatusInputField = AuthorizedDropDownField(p => p.canUpdateProjectStatus)

const BaseForm = props => {
    const {
        values,
        dirty,
        errors,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
        onConfirmPublish,
        modals,
        modalEvents,
        validateForm,
        setFieldValue
    } = props
    console.log(props , 'props')
    // var sourceData = this.props.sourceData;
    // console.log(sourceData , 'sourceData')
    var isCompleted;
    let AuthenticationRole= localStorage.getItem('AuthRole');
    console.log(AuthenticationRole , 'AuthenticationRole');
    var isDraft = values.status === ProjectStatusConstants.DRAFT;
    if (values.status === ProjectStatusConstants.COMPLETED) {
        if (AuthenticationRole === 'Administrator' || AuthenticationRole === 'Project Administrator'){
            isCompleted = true;
            console.log('I am in if');
        } else {
           isCompleted = false;
           console.log('I am in else');
        }
    }
    
   
   console.log("values.invoiceTimingType",values.invoiceTimingType);
    return (
        <div>
            <Form noValidate>
                <h6>
                    <Icon folderOpen />
                    Project Details
                </h6>
                <hr />
                <Container>
                    <Row>
                        <Col xs={12} md={6}>
                            <FormGroup>
                                <RequiredStar />
                                <Label for="name">Project Name</Label>
                                <InputField
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.name || ""}
                                    type="text"
                                    name="name"
                                    id="name"
                                    placeholder="Enter a project name"
                                    invalid={errors.name} />
                                {errors.name && <small className="text-danger">{errors.name}</small>}
                            </FormGroup>
                        </Col>
                        <Col xs={12} md={6}>
                            <FormGroup>
                                <Label for="code">Project Code</Label>
                                <InputGroup>
                                    <InputField
                                        disabled
                                        value={values.code || "Pending"}
                                        type="text"
                                        name="code"
                                        id="code"
                                        placeholder="Project Code"
                                        invalid={errors.code} />
                                </InputGroup>
                                {errors.code && <small className="text-danger">{errors.code}</small>}
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12} md={6}>
                            <FormGroup>
                                <RequiredStar />
                                <Label for="projectManagerId">Project Manager</Label>
                                <SelectOptions
                                    styles={errors.projectManagerId && errors.projectManagerId.length > 0 ? SelectStyles.errorStyles() : {}}
                                    isClearable={true}
                                    disabled={isSubmitting}
                                    value={values.projectManagerId}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    id="projectManagerId"
                                    invalid={errors.projectManagerId && errors.projectManagerId.length > 0}
                                    stateFunc={(state) => { return { ...state.managers } }}
                                    valueFunc={(record) => record.userId}
                                    labelFunc={(record) => record.firstName + " " + record.lastName}
                                    sortFunc={(a, b) => a.firstName.localeCompare(b.firstName)}
                                />
                                {errors.projectManagerId && <small className="text-danger">{errors.projectManagerId}</small>}
                            </FormGroup>
                        </Col>
                        <Col xs={12} md={6}>
                            <FormGroup>
                                <RequiredStar />
                                <Label for="projectDirectorId">Project Director</Label>
                                <SelectOptions
                                    styles={errors.projectDirectorId && errors.projectDirectorId.length > 0 ? SelectStyles.errorStyles() : {}}
                                    isClearable={true}
                                    disabled={isSubmitting}
                                    value={values.projectDirectorId}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    id="projectDirectorId"
                                    invalid={errors.projectDirectorId && errors.projectDirectorId.length > 0}
                                    stateFunc={(state) => { return { ...state.managers } }}
                                    valueFunc={(record) => record.userId}
                                    labelFunc={(record) => record.firstName + " " + record.lastName}
                                    sortFunc={(a, b) => a.firstName.localeCompare(b.firstName)}
                                />
                                {errors.projectDirectorId && <small className="text-danger">{errors.projectDirectorId}</small>}
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12} md={6}>
                            <FormGroup>
                                <RequiredStar />
                                <Label for="officeId">CMP Office</Label>
                                <SelectOptions
                                    styles={errors.officeId && errors.officeId.length > 0 ? SelectStyles.errorStyles() : {}}
                                    isClearable={true}
                                    disabled={isSubmitting}
                                    value={values.officeId}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    id="officeId"
                                    invalid={errors.officeId && errors.officeId.length > 0}
                                    stateFunc={(state) => { return { ...state.offices } }}
                                    valueFunc={(record) => record.officeId}
                                    labelFunc={(record) => record.name}
                                    sortFunc={(a, b) => a.name.localeCompare(b.name)}
                                />
                                {errors.officeId && <small className="text-danger">{errors.officeId}</small>}
                            </FormGroup>
                        </Col>
                    </Row>
                </Container>
                <h6>
                    <Icon client />
                    Client Details
                </h6>
                <hr />
                <Container>
                    <Row>
                        <Col xs={12} md={6}>
                            <FormGroup>
                                <RequiredStar />
                                <Label for="clientId">Client</Label>
                                <SelectOptions
                                    styles={errors.clientId && errors.clientId.length > 0 ? SelectStyles.errorStyles() : {}}
                                    isClearable={true}
                                    disabled={isSubmitting || !isDraft}
                                    value={values.clientId}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    id="clientId"
                                    invalid={errors.clientId && errors.clientId.length > 0}
                                    stateFunc={(state) => { return { ...state.clients } }}
                                    valueFunc={(record) => record.clientId}
                                    labelFunc={(record) => record.name + " (" + record.code + ")"}
                                    sortFunc={(a, b) => a.name.localeCompare(b.name)}
                                />
                                {errors.clientId && <small className="text-danger">{errors.clientId}</small>}
                            </FormGroup>
                        </Col>
                        <Col xs={12} md={6}>
                            <FormGroup>
                                <Label for="clientProjectNumber">Client Project Number</Label>
                                <InputField
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.clientProjectNumber || ""}
                                    type="text"
                                    name="clientProjectNumber"
                                    id="clientProjectNumber"
                                    placeholder="Enter a client project number"
                                    invalid={errors.clientProjectNumber} />
                                {errors.clientProjectNumber && <small className="text-danger">{errors.clientProjectNumber}</small>}
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12} md={6}>
                            <FormGroup>
                                <RequiredStar />
                                <Label for="clientProjectManager">Client Project Manager</Label>
                                <InputField
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.clientProjectManager || ""}
                                    type="text"
                                    name="clientProjectManager"
                                    id="clientProjectManager"
                                    placeholder="Enter a client project manager"
                                    invalid={errors.clientProjectManager} />
                                {errors.clientProjectManager && <small className="text-danger">{errors.clientProjectManager}</small>}
                            </FormGroup>
                        </Col>
                        <Col xs={12} md={6}>
                            <FormGroup>
                                <Label for="clientPurchaseOrder">Client Purchase Order</Label>
                                <InputField
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.clientPurchaseOrder || ""}
                                    type="text"
                                    name="clientPurchaseOrder"
                                    id="clientPurchaseOrder"
                                    placeholder="Enter a client purchase order number"
                                    invalid={errors.clientPurchaseOrder} />
                                {errors.clientPurchaseOrder && <small className="text-danger">{errors.clientPurchaseOrder}</small>}
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12} md={6}>
                            <FormGroup>
                                <RequiredStar />
                                <Label for="markup">Subcontractor Mark up (%)</Label>
                                <InputField
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.markup}
                                    type="number"
                                    name="markup"
                                    id="markup"
                                    placeholder="Enter a subcontractor mark up"
                                    invalid={errors.markup} />
                                {errors.markup && <small className="text-danger">{errors.markup}</small>}
                            </FormGroup>
                        </Col>
                    </Row>
                </Container>
                <h6>
                    <Icon clock />
                    Fee and Program
                </h6>
                <hr />
                <Container>
                    <Row>
                        <Col xs={12} md={6}>
                            <FormGroup>
                                <Label for="costCodeGroupId">Cost Code Group</Label>
                                <SelectOptions
                                    styles={errors.costCodeGroupId && errors.costCodeGroupId.length > 0 ? SelectStyles.errorStyles() : {}}
                                    isClearable={true}
                                    disabled={isSubmitting}
                                    value={values.costCodeGroupId}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    id="costCodeGroupId"
                                    invalid={errors.costCodeGroupId && errors.costCodeGroupId.length > 0}
                                    stateFunc={(state) => { return { ...state.costCodeGroupsAll } }}
                                    valueFunc={(record) => record.costCodeGroupId}
                                    labelFunc={(record) => record.name + (record.description ? " (" + record.description + ")" : "")}
                                    filterFunc={(record) => record.clientId === values.clientId}
                                    sortFunc={SortActions.NullCheckComparator(o => o.description)}
                                />
                                {errors.costCodeGroupId && <small className="text-danger">{errors.costCodeGroupId}</small>}
                            </FormGroup>
                        </Col>
                        <Col xs={12} md={6}>
                            <FormGroup>
                                <Label for="contractType">Contract Type</Label>
                                <SelectOptions
                                    styles={errors.contractType && errors.contractType.length > 0 ? SelectStyles.errorStyles() : {}}
                                    isClearable={true}
                                    disabled={isSubmitting}
                                    value={values.contractType}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    id="contractType"
                                    invalid={errors.contractType && errors.contractType.length > 0}
                                    stateFunc={(state) => { return { ...state.contractTypes } }}
                                    valueFunc={(record) => record.typeId}
                                    labelFunc={(record) => record.description}
                                />
                                {errors.contractType && <small className="text-danger">{errors.contractType}</small>}
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row className="mb-4">
                        <Row className="col-12">
                            <Label xs={12} md={12}>Invoice Timing</Label>
                            <Col xs={12} md={12} className="d-flex align-items-baseline">
                                <Label className="d-flex">
                                    <Input
                                        onChange={e => {
                                            setFieldValue("invoiceDayOfMonth", "");
                                            setFieldValue("invoiceWeekDayOfMonth", "");
                                            handleChange(e);
                                        }}
                                        onBlur={handleBlur}
                                        type="radio"
                                        name={`invoiceTimingType`}
                                        value="numberedDayOfMonth"
                                        style={{ position: 'unset', margin: 0 }}
                                        checked={values.invoiceTimingType === "numberedDayOfMonth"}
                                        disabled={isSubmitting || values.contractType == "4"}
                                    />
                                    <span className="ml-1">Day</span>
                                </Label>
                                <InputField
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    disabled={isSubmitting || values.invoiceTimingType == "weeklyDayOfMonth" || values.contractType == "4"}
                                    type="number"
                                    style={{ width: "80px" }}
                                    className="ml-3"
                                    min="1"
                                    max="28"
                                    value={values.invoiceNumberdDay}
                                    name="invoiceNumberdDay"
                                />
                                <span className="ml-2">Of every month</span>
                            </Col>
                            {errors.invoiceNumberdDay && <Col xs={12} md={12}><small className="text-danger">{errors.invoiceNumberdDay}</small></Col>}
                            <Col xs={12} md={12} className="d-flex align-items-baseline mt-2">
                                <Label className="d-flex">
                                    <Input
                                        onChange={e => {
                                            setFieldValue("invoiceNumberdDay", "");
                                            handleChange(e);
                                        }}
                                        onBlur={handleBlur}
                                        type="radio"
                                        name={`invoiceTimingType`}
                                        value="weeklyDayOfMonth"
                                        style={{ position: 'unset', margin: 0 }}
                                        checked={values.invoiceTimingType === "weeklyDayOfMonth"}
                                        disabled={isSubmitting || values.contractType == "4"}
                                    />
                                    {/* <span className="ml-1">The</span> */}
                                </Label>
                                <InputField
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    disabled={isSubmitting || values.invoiceTimingType == "numberedDayOfMonth" || values.contractType == "4"}
                                    type="number"
                                    style={{ width: "80px" }}
                                    className="ml-3"
                                    min="1"
                                    max="28"
                                    value={values.invoiceDayBeforeAfter}
                                    name="invoiceDayBeforeAfter"
                                />
                                <span className="ml-2">Days</span>
                                <span style={{width: "150px"}} className="ml-3">
                                    <SelectOptions
                                        isClearable={true}
                                        disabled={isSubmitting || values.invoiceTimingType == "numberedDayOfMonth" || values.contractType == "4"}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        stateFunc={(state) => { return { ...state.invoiceBeforeAfterReducer } }}
                                        valueFunc={(record) => record.dayId}
                                        labelFunc={(record) => record.description}
                                        value={values.invoiceBeforeAfter}
                                        id="invoiceBeforeAfter"
                                    />
                                </span>
                                <span style={{width: "150px"}} className="ml-3">
                                    <SelectOptions
                                        isClearable={true}
                                        disabled={isSubmitting || values.invoiceTimingType == "numberedDayOfMonth" || values.contractType == "4"}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        stateFunc={(state) => { return { ...state.invoiceDayReducer } }}
                                        valueFunc={(record) => record.dayId}
                                        labelFunc={(record) => record.description}
                                        value={values.invoiceDayOfMonth}
                                        id="invoiceDayOfMonth"
                                    />
                                </span>
                                <span style={{width: "150px", marginLeft: "10px"}}>
                                    <SelectOptions
                                        isClearable={true}
                                        disabled={isSubmitting || values.invoiceTimingType == "numberedDayOfMonth" || values.contractType == "4"}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.invoiceWeekDayOfMonth}
                                        stateFunc={(state) => { console.log("invoiceWeekDayOfMonth",values); return { ...state.invoiceWeekDayReducer } }}
                                        valueFunc={(record) => record.weekDayId}
                                        labelFunc={(record) => record.description}
                                        id="invoiceWeekDayOfMonth"
                                    />
                                </span>
                                <span className="ml-2">Of every month</span>
                                
                            </Col>
                            {errors.invoiceDayOfMonth && <Col xs={12} md={12}><small className="text-danger">{errors.invoiceDayOfMonth}</small></Col>}
                            {errors.invoiceWeekDayOfMonth && <Col xs={12} md={12}><small className="text-danger">{errors.invoiceWeekDayOfMonth}</small></Col>}
                        </Row>
                    </Row>
                    <Row>
                        <Col xs={12} md={6}>
                            <FormGroup>
                                <RequiredStar />
                                <Label for="labourFee">CMP Labour Fee</Label>
                                <InputGroup>
                                    <InputGroupAddon addonType="prepend">
                                        <InputGroupText>$</InputGroupText>
                                    </InputGroupAddon>
                                    <InputField
                                        onChange={e => {
                                            var updatedOriginalFee = Number(e.target.value) + Number(values.expenses) + Number(values.originalSubcontractorFee)
                                            setFieldValue(
                                                'originalFee',
                                                updatedOriginalFee.toFixed(2)
                                            );
                                            handleChange(e);
                                        }}
                                        onBlur={handleBlur}
                                        value={values.labourFee || ""}
                                        type="text"
                                        name="labourFee"
                                        id="labourFee"
                                        placeholder="Enter the CMP labour fee"
                                        invalid={errors.labourFee} />
                                </InputGroup>
                                {errors.labourFee && <small className="text-danger">{errors.labourFee}</small>}
                            </FormGroup>
                        </Col>
                        <Col xs={12} md={6}>
                            <FormGroup>
                                <RequiredStar />
                                <Label for="expenses">Expenses</Label>
                                <InputGroup>
                                    <InputGroupAddon addonType="prepend">
                                        <InputGroupText>$</InputGroupText>
                                    </InputGroupAddon>
                                    <InputField
                                        onChange={e => {
                                            var updatedOriginalFee = Number(e.target.value) + Number(values.labourFee) + Number(values.originalSubcontractorFee)
                                            setFieldValue(
                                                'originalFee',
                                                updatedOriginalFee.toFixed(2)
                                            );
                                            handleChange(e);
                                        }}
                                        onBlur={handleBlur}
                                        value={values.expenses || ""}
                                        type="text"
                                        name="expenses"
                                        id="expenses"
                                        placeholder="Enter the expenses"
                                        invalid={errors.expenses} />
                                </InputGroup>
                                {errors.expenses && <small className="text-danger">{errors.expenses}</small>}
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12} md={6}>
                            <FormGroup>
                                <RequiredStar />
                                <Label for="originalSubcontractorFee">Subcontractors</Label>
                                <InputGroup>
                                    <InputGroupAddon addonType="prepend">
                                        <InputGroupText>$</InputGroupText>
                                    </InputGroupAddon>
                                    <InputField
                                        onChange={e => {
                                            var updatedOriginalFee = Number(e.target.value) + Number(values.labourFee) + Number(values.expenses)
                                            setFieldValue(
                                                'originalFee',
                                                updatedOriginalFee.toFixed(2)
                                            );
                                            handleChange(e);
                                        }}
                                        onBlur={handleBlur}
                                        value={values.originalSubcontractorFee || ""}
                                        type="text"
                                        name="originalSubcontractorFee"
                                        id="originalSubcontractorFee"
                                        placeholder="Enter subcontractors fee"
                                        invalid={errors.originalSubcontractorFee} />
                                </InputGroup>
                                {errors.originalSubcontractorFee && <small className="text-danger">{errors.originalSubcontractorFee}</small>}
                            </FormGroup>
                        </Col>
                        <Col xs={12} md={6}>
                            <FormGroup>
                                <RequiredStar />
                                <Label for="originalFee">Original Fee - Total</Label>
                                <InputGroup>
                                    <InputGroupAddon addonType="prepend">
                                        <InputGroupText>$</InputGroupText>
                                    </InputGroupAddon>
                                    <InputField
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.originalFee || ""}
                                        type="text"
                                        name="originalFee"
                                        id="originalFee"
                                        placeholder="Enter the original fee"
                                        invalid={errors.originalFee}
                                        disabled={true} />
                                </InputGroup>
                                {errors.originalFee && <small className="text-danger">{errors.originalFee}</small>}
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12} md={6}>
                            <FormGroup>
                                <RequiredStar />
                                <Label for="startDate">Start Date</Label>
                                <InputField
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={Moment(values.startDate).format("YYYY-MM-DD") || ""}
                                    type="date"
                                    name="startDate"
                                    id="startDate"
                                    placeholder="Start Date"
                                    invalid={errors.startDate} />
                                {errors.startDate && <small className="text-danger">{errors.startDate}</small>}
                            </FormGroup>
                        </Col>
                        <Col xs={12} md={6}>
                            <FormGroup>
                                <RequiredStar />
                                <Label for="endDate">End Date (anticipated)</Label>
                                <InputField
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={Moment(values.endDate).format("YYYY-MM-DD") || ""}
                                    type="date"
                                    name="endDate"
                                    id="endDate"
                                    placeholder="End Date"
                                    invalid={errors.endDate} />
                                {errors.endDate && <small className="text-danger">{errors.endDate}</small>}
                            </FormGroup>
                        </Col>
                    </Row>
                </Container>
                <h6>
                    <Icon workflow />
                    Workflow
                </h6>
                <hr />
                <Container>
                    <Row>
                        <Col xs={6} md={6}>
                            <FormGroup>
                                <Label for="status">Project Status</Label>
                                <ProjectStatusInputField
                                    disabled={isDraft || isCompleted === false}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    type="select"
                                    name="status"
                                    value={values.status || ""}
                                    invalid={errors.status}
                                    id="status"
                                >
                                    <option disabled={!isDraft} value={ProjectStatusConstants.DRAFT}>Draft</option>
                                    <option value={ProjectStatusConstants.INPROGRESS}>In Progress</option>
                                    <option value={ProjectStatusConstants.COMPLETED}>Completed</option>
                                </ProjectStatusInputField>
                            </FormGroup>
                        </Col>
                        <Col xs={6} md={6}>
                            <FormGroup>
                                <Label for="timesheetMessage">Timesheet Message (optional - mainly for non-chargeable job codes)</Label>
                                <InputField
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.timesheetMessage || ""}
                                    type="text"
                                    name="timesheetMessage"
                                    id="timesheetMessage"
                                    placeholder="Enter a message to be shown upon submission or approval of a timesheet"
                                    rows={3} />
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12}>
                            <FormGroup>
                                <Label for="comments">Comments</Label>
                                <InputField
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.comments || ""}
                                    type="textarea"
                                    name="comments"
                                    id="comments"
                                    placeholder="Enter comments / feedback about the project"
                                    style={{ resize: "none" }}
                                    rows={3}
                                    invalid={errors.comments} />
                                {errors.comments && <small className="text-danger">{errors.comments}</small>}
                            </FormGroup>
                        </Col>
                    </Row>
                </Container>
                <PublishingToolbarGroup
                    canPublish={values.status === ProjectStatusConstants.DRAFT && !dirty}
                    canSaveDraft={!isSubmitting && dirty}
                    modals={modals}
                    modalEvents={modalEvents}
                    recordToUpdate={values}
                    onConfirmSaveDraft={handleSubmit}
                    onConfirmPublish={(e) => {
                        validateForm()
                        onConfirmPublish(e)
                    }}
                />
            </Form>
        </div >
    )
}

const validationSchema = Yup.object({
    name: Yup.string()
        .required("Name is required"),
    clientId: Yup.string()
        .required("Client is required")
        .nullable(),
    projectManagerId: Yup.string()
        .required("Project manager is required")
        .nullable(),
    projectDirectorId: Yup.string()
        .required("Project director is required")
        .nullable(),
    clientProjectManager: Yup.string()
        .required("Client project manager is required")
        .nullable(),
    officeId: Yup.string()
        .required("Office is required")
        .nullable(),
    contractType: Yup.string()
        .required("Contract type is required")
        .nullable(),
    startDate: Yup.date()
        .required("Start date is required")
        .typeError("Must be a date"),
    endDate: Yup.date()
        .when(
            "startDate",
            (startDate, schema) => (
                startDate ?
                    schema
                        .min(startDate, "End date cannot precede Start date")
                        .required("End date is required")
                        .typeError("Must be a date")
                    :
                    schema
                        .required("End date is required")
                        .typeError("Must be a date"))
    ),
    originalFee:
        requiredNumberValidation()
            .default(0)
            .min(0, "Must be positive"),
    originalSubcontractorFee:
        requiredNumberValidation()
            .default(0)
            .min(0, "Must be positive"),
    labourFee:
        requiredNumberValidation()
            .min(1, "Must be greater than zero"),
    expenses:
        requiredNumberValidation()
            .default(0)
            .min(0, "Must be positive"),
    invoiceNumberdDay:
        Yup.number()
            .when(
                ["invoiceTimingType", "contractType"],
                (invoiceTimingType, contractType, schema) => (
                    invoiceTimingType == "numberedDayOfMonth" && contractType != 4 ?
                        schema
                            .required("Invoice timing is required")
                            .min(1, "Must be less than or equal to 1")
                            .max(28, "Must be less than or equal to 28") : schema
                )),
    invoiceDayOfMonth:
        Yup.string()
            .when(
                ["invoiceTimingType", "contractType"],
                (invoiceTimingType, contractType, schema) => (
                    invoiceTimingType == "weeklyDayOfMonth" && contractType != 4 ?
                        schema
                            .required("Invoice timing is required") : schema
                )),
    invoiceWeekDayOfMonth:
        Yup.string()
            .when(
                ["invoiceTimingType", "contractType"],
                (invoiceTimingType, contractType, schema) => (
                    invoiceTimingType == "weeklyDayOfMonth" && contractType != 4 ?
                        schema
                            .required("Invoice week day is required") : schema
                )),
    markup: markupValidation(),
})

function markupValidation(){
    return Yup.number()
    .default(0)
    .nullable()
    .required("Value is required")
    .typeError("Must be a number")
    .min(0, "Must be positive")
}

function numberValidation() {
    return Yup.number()
        .default(0)
        .nullable()
        .required("Value is required")
        .positive("Must be positive")
        .typeError("Must be a number")
        .min(0, "Must be more than zero")
        .moreThan(0, "Must be more than zero")
}

function requiredNumberValidation() {
    return numberValidation()
        .required("Value is required")
}

const ProjectFormExtended = withFormik({
    
    displayName: "ProjectFormExtended",
    enableReinitialize: true,
    validationSchema: validationSchema,
    mapPropsToValues: ({ ...props }) => {
        console.log(props , 'props in project form extended')
        return ({
            ...props,
            startDate: props.startDate || "",
            endDate: props.endDate || "",
            invoiceTimingType: props.invoiceTimingType == InvoiceTimingTypeConstants.NumberedDayOfMonth ? "numberedDayOfMonth" : "weeklyDayOfMonth",
            invoiceNumberdDay: props.invoiceNumberdDay || "",
            invoiceDayOfMonth: props.invoiceDayOfMonth >= 0 && props.invoiceDayOfMonth != null ? props.invoiceDayOfMonth : "",
            invoiceWeekDayOfMonth: props.invoiceWeekDayOfMonth >= 0 && props.invoiceWeekDayOfMonth != null ? props.invoiceWeekDayOfMonth : "" 
        })
    },
    handleSubmit: (values, { props, setSubmitting }) => {
        values.invoiceTimingType = values.invoiceTimingType == "numberedDayOfMonth" ? InvoiceTimingTypeConstants.NumberedDayOfMonth : InvoiceTimingTypeConstants.WeeklyDayOfMonth;
        props.onSubmitUpdate(values)
        setSubmitting(false)
    }
})(BaseForm)


export default ProjectFormExtended