import React, { Component } from "react"
import {
    Card,
    CardBody,
    CardHeader,
    CardTitle,
    CardSubtitle,
    Container,
    Col,
    Nav,
    NavItem,
    NavLink,
    Row,
    TabContent,
    TabPane
} from "reactstrap"
import Icon from "components/common/Icon"
import { SpinnerOverlay } from "@gman/gman-react-bootstrap"
import FeedbackAlert from "components/common/FeedbackAlert"

import { CostCodeListContainer } from "components/costCodes/redux/CostCodeContainers"
import { CostCodeGroupFormBasic } from "components/costCodeGroups/form/CostCodeGroupFormBasic"

class CostCodeGroupCardExtended extends Component {

    componentDidMount() {
        if (this.props.costCodeGroupId) {
            this.props.recordStateEvents.onLoadSingle(this.props.costCodeGroupId)
            this.props.tabEvents.onChangeTab("1")
        }
    }

    componentWillUnmount = () => {
        this.props.feedbackEvents.onFlushFeedback()
    }

    render() {
        var sourceData = this.props.sourceData
        var confirmEvents = this.props.confirmEvents
        var feedbackEvents = this.props.feedbackEvents
        var tabEvents = this.props.tabEvents

        return (
            <Card>
                <CardHeader>
                    <CardTitle>
                        {
                            sourceData.recordToUpdate ?
                                sourceData.recordToUpdate.name :
                                "Loading..."
                        }
                    </CardTitle>
                    <hr />
                    <CardSubtitle>
                        <Icon costCodeGroup />
                        <small>
                            <i>Cost Code Group</i>
                        </small>
                    </CardSubtitle>
                    <br />
                </CardHeader>
                <CardBody>
                    <FeedbackAlert {...feedbackEvents} feedback={sourceData.feedback} />
                    <SpinnerOverlay isLoading={sourceData.inProgress}>
                        <CostCodeGroupFormBasic
                            onSubmitUpdate={confirmEvents.onSubmitUpdate}
                            {...sourceData.recordToUpdate} />
                    </SpinnerOverlay>
                    <div>
                        <Nav tabs>
                            <NavItem>
                                <NavLink onClick={() => tabEvents.onChangeTab("1")}>
                                    <Icon costCode />Cost Codes
                                </NavLink>
                            </NavItem>
                        </Nav>
                        <TabContent activeTab={sourceData.activeTab}>
                            <TabPane tabId="1">
                                <br />
                                <Container>
                                    <Row>
                                        <Col>
                                            <CostCodeListContainer costCodeGroupId={this.props.costCodeGroupId} />
                                        </Col>
                                    </Row>
                                </Container>
                            </TabPane>
                        </TabContent>
                    </div>
                </CardBody >
            </Card >
        )
    }
}

export default CostCodeGroupCardExtended