import "bootstrap/dist/css/bootstrap.min.css"
import "./css/index.sass"

import React from "react"
import { render } from "react-dom"
import { createStore, applyMiddleware } from "redux"
import thunkMiddleware from "redux-thunk"
import rootReducer from "./reducers"
import Root from "./Root"

window.AppSettings = {
    CmpClientId: 'ef0b5de8-0faa-46d3-b43d-75e168daa628'
};

const store = createStore(
  rootReducer,
  applyMiddleware(
    thunkMiddleware)
)

render(<Root store={store} />, document.getElementById("root"))