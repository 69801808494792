import React, { Component } from "react";
import { Redirect } from "react-router";
import TableHeaderRow from "components/common/TableHeaderRow";
import { ButtonGroup, Table } from "reactstrap";
import SubContractorInvoiceRow from "components/subContractorInvoices/layout/SubContractorInvoiceRow";
import SubContractorInvoiceFormExtended from "components/subContractorInvoices/form/SubContractorInvoiceFormExtended";
import SubContractorInvoiceConfirmPaidForm from "components/subContractorInvoices/form/SubContractorInvoiceConfirmPaidForm";
import FeedbackAlert from "components/common/FeedbackAlert";
import { ModalActions } from "@gman/gman-redux";
import { AuthorizedAddButton } from "components/authentication/controls/AuthorizedControls";
import FileUploader from "components/common/FileUploader";
import {
    ConfirmDelete,
    FormModal,
    Paginator,
    SpinnerOverlay
} from "@gman/gman-react-bootstrap";

const AddButton = AuthorizedAddButton(p => p.canWriteSubContractorInvoice);

class SubContractorInvoiceList extends Component {
    componentDidMount() {
        this.props.recordStateEvents.onClearSearch({
            projectId: this.props.projectId
        });
        this.props.onLoadDependentData();
    }

    componentWillUnmount = () => {
        this.props.redirectEvents.onClearRedirect();
    };

    render() {
        var sourceData = this.props.sourceData;
        sourceData.recordToUpdate = {...sourceData.recordToUpdate,markup :this.props.markup }
        var lastInvoicePayments = this.props.lastInvoicePayments;
        var confirmEvents = this.props.confirmEvents;
        var feedbackEvents = this.props.feedbackEvents;
        var hideButtons = this.props.hideButtons;
        var modalEvents = this.props.modalEvents;
        var paginatorEvents = this.props.paginatorEvents;
        var tableHeaderRowEvents = this.props.tableHeaderRowEvents;
        var recordStateEvents = this.props.recordStateEvents;
        var uploadEvents = this.props.uploadEvents;
        var workItem = this.props.workItem;
        var modalConfirmEvents = this.props.modalConfirmEvents.confirmEvents;
        console.log("Update-Invoice-aaa",sourceData.recordToUpdate);
        return (
            <div>
                {
                    !ModalActions.IsVisible(sourceData.modals, "UPLOAD_INVOICE") && !ModalActions.IsVisible(sourceData.modals, "UPDATE") ?
                        (<FeedbackAlert {...feedbackEvents} feedback={sourceData.feedback} />) :
                        ("")
                }
                <SpinnerOverlay isLoading={sourceData.inProgress}>
                    <Table hover size="sm" responsive>
                        <thead>
                            <TableHeaderRow
                                {...tableHeaderRowEvents}
                                disabled={sourceData.inProgress}
                                headers={[
                                    { sortBy: "Number", label: "Invoice Number" },
                                    { sortBy: "SubContractor", label: "Sub Contractor" },
                                    { sortBy: "Project.Code", label: "Project Number" },
                                    { sortBy: "Project.Name", label: "Project Name" },
                                    { sortBy: "Description", label: "Description" },
                                    { sortBy: "InvoiceAmount", label: "Amount (excl. GST)" },
                                    { sortBy: "Markup", label: "Mark Up (%)" },
                                    { sortBy: "InvoiceAmount", label: "Total Charged to Client (excl. GST)" },
                                    { sortBy: "InvoiceDate", label: "Invoice Date" },
                                    { sortBy: "DueDate", label: "Due Date" },
                                    { sortBy: "PaymentDate", label: "Date Paid" },
                                    { sortBy: "Status", label: "Subcontractor Invoice Status" },
                                    { sortBy: "ClientInvoice.Number", label: "Client Invoice Number" },
                                    { sortBy: "ClientInvoice.Status", label: "Client Invoice Status" }
                                ]}/>
                        </thead>
                        <tbody>
                            {
                                sourceData.records.map((record, index) => {
                                    console.log(record,"subContractorInvoice");
                                    return (
                                        <SubContractorInvoiceRow
                                            key={index}
                                            {...record}
                                            onShowDelete={() => modalEvents.onShowDelete(record)}
                                            onShowMarkPaid={() => {
                                                recordStateEvents.onLoadSingle(
                                                    record.subContractorInvoiceId
                                                );
                                                modalEvents.onShowModal("MARK_AS_PAID");
                                            } }
                                            onShowEdit={() => {
                                                modalEvents.onShowEdit(record);
                                            } }
                                            disabled={sourceData.inProgress} />
                                    );
                                })
                            }
                        </tbody>
                    </Table>
                    <Paginator {...paginatorEvents} searchOptions={sourceData.searchOptions}/>
                    {
                        hideButtons ? null : (
                            <ButtonGroup className="float-right">
                                {
                                    /*
                                        <AddButton
                                            disabled={sourceData.inProgress}
                                            caption="Upload"
                                            onClick={() => {
                                                uploadEvents.onClearUpload();
                                                this.props.onClearUploadFeedback();
                                                modalEvents.onShowModal("UPLOAD_INVOICE");
                                            }}/>
                                    */
                                }
                                <AddButton
                                    disabled={sourceData.inProgress}
                                    caption="Add"
                                    onClick={() => modalEvents.onShowNew()}/>
                            </ButtonGroup>
                    )}
                </SpinnerOverlay>
                <FormModal
                    isVisible={ModalActions.IsDeleteVisible(sourceData.modals)}
                    onHide={modalEvents.onHideDelete}
                    title={"Confirm Delete"}>
                    <ConfirmDelete
                        onConfirmDelete={() =>
                            modalConfirmEvents.onConfirmDelete(sourceData.recordToUpdate)
                        }/>
                </FormModal>
                <FormModal
                    size="lg"
                    isVisible={ModalActions.IsUpdateVisible(sourceData.modals)}
                    onHide={modalEvents.onHideEdit}
                    title={"Update Invoice"}>
                    <SubContractorInvoiceFormExtended
                        onSubmitUpdate={modalConfirmEvents.onSubmitUpdate}
                        workItem={workItem}
                        {...sourceData.recordToUpdate}
                        {...lastInvoicePayments}
                        feedbackEvents={feedbackEvents}
                        inProgress={sourceData.inProgress}
                        feedback={sourceData.feedback}/>
                </FormModal>
                <FormModal
                    isVisible={ModalActions.IsVisible(
                        sourceData.modals,
                        "UPLOAD_INVOICE"
                    )}
                    onHide={() => modalEvents.onHideModal("UPLOAD_INVOICE")}
                    title={"Upload Invoice"}>
                    <FileUploader
                        feedback={sourceData.feedback}
                        feedbackEvents={feedbackEvents}
                        uploadedFiles={sourceData.fileData}
                        isUploading={sourceData.isUploading}
                        uploadFeedback={sourceData.uploadFeedback}
                        multiple={true}
                        accept=".xls, .xlsx"
                        maxSize={6291456}
                        {...uploadEvents}
                        onUploadSubmit={() =>
                            this.props.onUploadSubmit(sourceData.fileData)
                        }>
                        <div>
                            Click here to upload a file or drag a file into this zone.
                        <br />
                            Files must be .XLS or .XLSX
                        </div>
                    </FileUploader>
                </FormModal>
                <FormModal
                    isVisible={ModalActions.IsVisible(sourceData.modals, "MARK_AS_PAID")}
                    onHide={() => modalEvents.onHideModal("MARK_AS_PAID")}
                    title={"Confirm Mark as Paid"}>
                    <SubContractorInvoiceConfirmPaidForm
                        {...sourceData.recordToUpdate}
                        {...lastInvoicePayments}
                        onConfirmPaid={this.props.onConfirmPaid}/>
                </FormModal>
            </div>
        );
    }
}

export default SubContractorInvoiceList;
