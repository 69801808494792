import ScopeConstants from "constants/ScopeConstants"
import { FormActionReducer } from "@gman/gman-redux"

export const ResetPasswordsReducer = (
    state = {
        ...FormActionReducer.defaultPageState(),
        acknowledged: true,
        passwordClearText: ""
    },
    action) => {

    if (action.scope === ScopeConstants.RESET_PASSWORD) {
        if (action.type === "CONFIRM_RESET_PASSWORD") {
            return {
                ...state,
                acknowledged: false,
                passwordClearText: action.passwordClearText
            }
        }
        if (action.type === "ACKNOWLEDGE_RESET_PASSWORD") {
            return {
                ...state,
                acknowledged: true,
                passwordClearText: ""
            }
        }
        return FormActionReducer.reduce(action, state)
    }

    return state
}