import React from "react"
import { Link } from "react-router-dom"
import Moment from "moment"
import ActiveCell from "components/common/ActiveCell"
import InvoiceStatusBadge from "components/common/InvoiceStatusBadge"
import { AuthorizedLink, AuthorizedInvoiceToolbar } from "components/authentication/controls/AuthorizedControls"
import InvoiceStatusConstants from "constants/InvoiceStatusConstants"
import Currency from "components/common/Currency"
import WorkItemCell from "components/common/WorkItemCell"
import ClientInvoiceStatusBadge from "components/common/ClientInvoiceStatusBadge"
import { Input } from "reactstrap"
import { withFormik } from "formik"

const InvoiceLink = AuthorizedLink(p => p.canMarkItemsAsPaid)
const Toolbar = AuthorizedInvoiceToolbar(p => p.canMarkItemsAsPaid)

const BaseForm = (
    {
        index,
        values,
        onShowEdit,
        handleChange,
        setFieldValue,
        isOpneInModal,
        onCostItemStatusChanged
    }) => {

    let a = ""
    if (isOpneInModal) {
        a = "sub_contractor_costItemStatus_modal";
    } else {
        a = "sub_contractor_costItemStatus";
    }

    if (values[a + index] == undefined) {
        values[a + index] = values.costItemStatus;
    }

    if (values.costItemStatus == null) {
        values[a + index] = 0;
    }
    return (
        <tr>
            <td onClick={onShowEdit} >
                <InvoiceLink to={"/SubContractorInvoiceDetails/" + values.subContractorInvoiceId}>
                    {values.number}
                </InvoiceLink>
            </td>
            <td onClick={onShowEdit} >
                {values.subContractor}
            </td>
            <td>
                {values.project ? values.project.code : ""}
            </td>
            <td onClick={onShowEdit} className="weekend">
                <div style={{ width: '325px' }}>
                    {
                        values.workItem ?
                            <WorkItemCell
                                workItem={values.workItem}
                                projectColumnWidth={8}
                                projectCodeColumnWidth={4}
                                projectVariationColumnWidth={8}
                                projectCostCodeColumnWidth={4}
                                hideProjectCode = {true}
                            />
                            : ""
                    }
                </div>
            </td>
            <td onClick={onShowEdit}>{values.description || ''}</td>
            <td onClick={onShowEdit} >{values.invoiceAmount != null ? Currency(values.invoiceAmount) :Currency(0)}</td>
            <td onClick={onShowEdit} >{values.markup}</td>
            <td onClick={onShowEdit} >{Currency(values.totalChargeToClient)}</td>
            <td onClick={onShowEdit} >{Moment(values.invoiceDate).format("DD/MM/YYYY")}</td>
            <td onClick={onShowEdit}>{Moment(values.dueDate).format("DD/MM/YYYY")}</td>
            <td onClick={onShowEdit}>{values.paymentDate ? Moment(values.paymentDate).format("DD/MM/YYYY") : ""}</td>
            <td onClick={onShowEdit} >
                <InvoiceStatusBadge value={values.status} />
            </td>
            <td className="text-center">
                <Input
                    type="radio"
                    value={0}
                    // disabled={true}
                    style={{ position: 'unset', margin: 0 }}
                    name={a + index}
                    checked={values[a + index] == 0 ? true : false}
                    disabled={values.clientInvoice && values.clientInvoice.status != InvoiceStatusConstants.DRAFT}
                    onChange={
                        (e) => {
                            handleChange(e);
                            onCostItemStatusChanged({
                                subContractorInvoiceId: values.subContractorInvoiceId,
                                costItemStatus: 0
                            });
                            setFieldValue(a + index, 0);
                        }
                    }
                />
            </td>
            <td className="text-center">
                <Input
                    type="radio"
                    value={1}
                    // disabled={true}
                    style={{ position: 'unset', margin: 0 }}
                    name={a + index}
                    checked={values[a + index] == 1 ? true : false}
                    disabled={values.clientInvoice && values.clientInvoice.status != InvoiceStatusConstants.DRAFT}
                    onChange={
                        (e) => {
                            handleChange(e);
                            onCostItemStatusChanged({
                                subContractorInvoiceId: values.subContractorInvoiceId,
                                costItemStatus: 1
                            });
                            setFieldValue(a + index, 1);
                        }
                    }
                />
            </td>
            <td className="text-center">
                <Input
                    type="radio"
                    value={2}
                    // disabled={true}
                    style={{ position: 'unset', margin: 0 }}
                    name={a + index}
                    checked={values[a + index] == 2 ? true : false}
                    disabled={values.clientInvoice && values.clientInvoice.status != InvoiceStatusConstants.DRAFT}
                    onChange={
                        (e) => {
                            handleChange(e);
                            onCostItemStatusChanged({
                                subContractorInvoiceId: values.subContractorInvoiceId,
                                costItemStatus: 2
                            });
                            setFieldValue(a + index, 2);
                        }
                    }
                />
            </td>
            <td>{values.clientInvoice ? values.clientInvoice.number : ""}</td>
            <td>
                {
                    values.clientInvoice ? <ClientInvoiceStatusBadge value={values.clientInvoice.status} /> : ""
                }
            </td>
        </tr>
    )
}

const SubContractorInvoiceEditRow = withFormik({
    displayName: "SubContractorInvoiceEditRow",
    enableReinitialize: true
})(BaseForm)


export default SubContractorInvoiceEditRow