import React, { Component } from "react"
import { Redirect } from "react-router"
import { Link } from "react-router-dom"
import {
    Card,
    CardBody,
    CardHeader,
    CardTitle,
    CardSubtitle,
    Container,
    Col,
    Nav,
    NavItem,
    NavLink,
    Row,
    TabContent,
    TabPane
} from "reactstrap"
import Icon from "components/common/Icon"
import { SpinnerOverlay } from "@gman/gman-react-bootstrap"
import ProjectDisplayExtended from "components/projects/form/ProjectDisplayExtended"
import ProjectFormExtended from "components/projects/form/ProjectFormExtended"
import ProjectStatusBadge from "components/common/ProjectStatusBadge"
import FeedbackAlert from "components/common/FeedbackAlert"
import ChargeRateTypeConstants from "constants/ChargeRateTypeConstants"
import { RestrictedComponent } from "components/authentication/redux/AuthorizationWrappers"
import { VariationListContainer } from "components/variations/redux/VariationContainers"
import { ChargeRateListContainer, ChargeRateListExtendedContainer } from "components/chargeRates/redux/ChargeRateContainers"
import { ClientInvoiceListContainer } from "components/clientInvoices/redux/ClientInvoiceContainers"
import { SubContractorInvoiceListContainer } from "components/subContractorInvoices/redux/SubContractorInvoiceContainers"
import { EditDataContainer } from "components/editData/redux/EditDataContainers";
import { ProjectFinancialContainer } from "components/projectsFinancial/redux/ProjectFinancialContainers"
import { InvoiceStatusConstantsSubcontractor } from "../../timesheetEntries/form/TimesheetEntryEditRowForm"
import ProjectStatusConstants from "../../../constants/ProjectStatusConstants"



let openDetail =  false;
const EditRecordLink = RestrictedComponent((props) =>
    <Link className="editor-span" onClick={props.onClick}  to={"/projectDetails/" + props.recordId}>
        <Icon edit />
    </Link>, p => p.canWriteWorkItems)

const ChargeRatesTab = RestrictedComponent((props) =>
    <NavItem>
        <NavLink onClick={props.onChangeTab}>
            <Icon chargeRate />
            Charge Rates
        </NavLink>
    </NavItem>, p => p.canReadChargeRates)

const ClientInvoicesTab = RestrictedComponent((props) =>
    <NavItem>
        <NavLink onClick={props.onChangeTab}>
            <Icon invoice />Client Invoices
        </NavLink>
    </NavItem>, p => p.canWriteWorkItems)

const SubContractorInvoicesTab = RestrictedComponent((props) =>
    <NavItem>
        <NavLink onClick={props.onChangeTab}>
            <Icon invoice />Sub Contractor Invoices
        </NavLink>
    </NavItem>, p => p.canWriteWorkItems)

const ProjectDataTab = RestrictedComponent((props) =>
    <NavItem>
        <NavLink onClick={props.onChangeTab}>
            <Icon system />Project Data
        </NavLink>
    </NavItem>, p => p.canWriteWorkItems)

class ProjectCardExtended extends Component {

    componentDidMount() {
        if (this.props.projectId) {
            this.props.recordStateEvents.onLoadSingle(this.props.projectId)
            this.props.onLoadDependentData()
            let urlParams = new URLSearchParams(window.location.search);
            let myParam = urlParams.get('tab');
            let tabOpen = parseInt(myParam) === 1 ? true: false;
            if (openDetail === true) {
                this.props.tabEvents.onChangeTab("1")
            } else {
                if (tabOpen === true) {
                    this.props.tabEvents.onChangeTab("1")
                    window.history.replaceState(null, null, window.location.pathname);
                } else {
                    if(this.props.sourceData.recordToUpdate.endDate === null){
                        this.props.tabEvents.onChangeTab("1")
                    } else {
                            this.props.tabEvents.onChangeTab("7")
                    }
                }
                
                
            }
           
            
            
        }
    }

    componentWillUnmount = () => {
        this.props.feedbackEvents.onFlushFeedback()
        this.props.redirectEvents.onClearRedirect()
    }

    render() {
        var self = this;
        var readOnly = this.props.readOnly
        var sourceData = this.props.sourceData;
        console.log(this.props.authData ,'ProjectCardExtended');
        var confirmEvents = this.props.confirmEvents
        var feedbackEvents = this.props.feedbackEvents
        var modalEvents = this.props.modalEvents
        var tabEvents = this.props.tabEvents
        var modals = sourceData.modals;
       
        var workItem = {
            workItemId: sourceData.recordToUpdate.projectId,
            type: "PROJECT_ANY",
            code: sourceData.recordToUpdate.code,
            name: sourceData.recordToUpdate.name,
            costCodeGroupId: sourceData.recordToUpdate.costCodeGroupId,
            costCode: null,
            variation: null,
        }
        console.log(this.props.sourceData.recordToUpdate , 'sourceDatasourceData')
        let expectedClientInvoiceId = localStorage.getItem('expectedClientInvoiceId');
        console.log(expectedClientInvoiceId , 'expectedClientInvoiceId-projectCard')

        if (sourceData.redirect) {
            return (<Redirect to={sourceData.redirect} />)
        }
        return (
            <Card>
                <CardHeader>
                    <CardTitle>
                        {
                            sourceData.recordToUpdate ?
                                sourceData.recordToUpdate.name :
                                "Loading..."
                        }
                        {readOnly ? <EditRecordLink onClick={() => {
                            openDetail = true;
                           
                        }} recordId={this.props.projectId} /> : ""}
                    </CardTitle>
                    <hr />
                    <div className="card-subtitle-wrapper">
                        <CardSubtitle>
                            <Icon project />
                            <small>
                                <i>Project</i>
                            </small>
                        </CardSubtitle>
                        <br />
                        <ProjectStatusBadge value={sourceData.recordToUpdate.status} />
                    </div>
                </CardHeader>
                <CardBody>
                    <FeedbackAlert {...feedbackEvents} feedback={sourceData.feedback} />
                    <Container>
                        <Row>
                            <Col>
                                <Nav tabs>
                                    <NavItem>
                                        <NavLink onClick={() => {
                                            self.props.onTabChange("7");
                                            openDetail = false;
                                            self.props.reloadProjectFinancial(self.props.projectId)
                                        }}>
                                            <Icon chargeRate />Financial
                                        </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink onClick={() => {
                                            openDetail = false;
                                            return self.props.onTabChange("1")
                                        }}>
                                            <Icon folderOpen />Details
                                        </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink onClick={() => {
                                            openDetail = false;
                                            return self.props.onTabChange("2")
                                        }}>
                                            <Icon variation />Variations
                                        </NavLink>
                                    </NavItem>
                                    <ChargeRatesTab onChangeTab={() => {
                                        openDetail = false;
                                        return self.props.onTabChange("3")
                                    }} />
                                    <ClientInvoicesTab onChangeTab={() => {
                                        openDetail = false;
                                        return self.props.onTabChange("4")
                                    }} />
                                    <SubContractorInvoicesTab onChangeTab={() => {
                                        openDetail = false;
                                        //self.props.reloadSubcontractorInvoice()
                                        return self.props.onTabChange("5")
                                    }} />
                                    <ProjectDataTab onChangeTab={() =>{
                                        openDetail = false;
                                        tabEvents.onChangeTab("6")
                                    } } />
                                </Nav>
                            </Col>
                        </Row>
                    </Container>
                    <div>
                        <TabContent activeTab={sourceData.activeTab}>
                            <TabPane tabId="7">
                                <br />
                                {
                                    sourceData.activeTab == 7 ? <Container>
                                    <Row>
                                        <Col>
                                            <ProjectFinancialContainer projectId={this.props.projectId} />
                                        </Col>
                                    </Row>
                                 </Container> : null
                                }
                                
                            </TabPane>
                            <TabPane tabId="1">
                                <br />
                                {
                                    sourceData.activeTab == 1 ? <Container>
                                    <Row>
                                        <Col>
                                            <SpinnerOverlay isLoading={sourceData.inProgress}>
                                                {readOnly ?
                                                    <ProjectDisplayExtended values={sourceData.recordToUpdate} /> :
                                                    <div class="hello test">
                                                        <ProjectFormExtended
                                                            modals={modals}
                                                            modalEvents={modalEvents}
                                                            onSubmitUpdate={confirmEvents.onSubmitUpdate}
                                                            openClickDetail={openDetail}
                                                            onConfirmPublish={confirmEvents.onConfirmPublish}
                                                            {...confirmEvents}
                                                            {...sourceData.recordToUpdate}
                                                        />
                                                    </div>
                                                }
                                            </SpinnerOverlay>
                                        </Col>
                                    </Row>
                                    </Container>  : null
                                }
                                
                            </TabPane>
                            <TabPane tabId="2">
                                <br />
                                {
                                    sourceData.activeTab == 2 ? <Container>
                                    <Row>
                                        <Col>
                                            <VariationListContainer projectId={this.props.projectId} />
                                        </Col>
                                    </Row>
                                    </Container> : null
                                }
                                
                            </TabPane>
                            <TabPane tabId="3">
                                <br />
                                {
                                    sourceData.activeTab == 3 ? <Container>
                                    <Row>
                                        <Col>
                                            <ChargeRateListExtendedContainer
                                                principalId={this.props.projectId}
                                                principal={sourceData.recordToUpdate}
                                                currentRatesType={ChargeRateTypeConstants.PROJECT}
                                            />
                                        </Col>
                                    </Row>
                                </Container> : null
                                }
                                
                            </TabPane>
                            <TabPane tabId="4">
                                <br />
                                <Container>
                                    <Row>
                                        <Col>
                                            <ClientInvoiceListContainer
                                                projectId={this.props.projectId}
                                                projectViewTabEvents={tabEvents}
                                            />
                                        </Col>
                                    </Row>
                                </Container>
                            </TabPane>
                            <TabPane tabId="5">
                                <br />
                                {
                                    sourceData.activeTab == 5 ?  <Container>
                                    <Row>
                                        <Col>
                                            <SubContractorInvoiceListContainer
                                                projectId={this.props.projectId}
                                                workItem={workItem}
                                                markup={sourceData.recordToUpdate.markup}
                                            />
                                        </Col>
                                    </Row>
                                </Container> : null
                                }
                               
                            </TabPane>
                            <TabPane tabId="6">
                                <br />
                                {
                                    sourceData.activeTab == 6 ? <Container fluid={true}  className="no-gutters-custom data-test">
                                    <Row>
                                        <Col>
                                            <EditDataContainer
                                                projectId={this.props.projectId}
                                                showFilter={false}
                                                // expectedClientInvoiceId = { expectedClientInvoiceId !== 'undefined' && typeof(expectedClientInvoiceId) !== undefined && expectedClientInvoiceId !== 'null' && expectedClientInvoiceId !== null ? expectedClientInvoiceId : ''}
                                            />
                                        </Col>
                                    </Row>
                                </Container> : null
                                }
                            </TabPane>
                        </TabContent>
                    </div>
                </CardBody>
            </Card>
        )
    }
}

export default ProjectCardExtended