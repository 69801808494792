import React, { Component } from "react"
import { NavMenu } from "./NavMenu"
import { Footer } from "./Footer"
import { Container } from "reactstrap"

export class FullScreenLayout extends Component {
    displayName = FullScreenLayout.name

    render() {
        const { pathname } = this.props.location;
        return (
            <Container fluid={true} className="no-gutters-custom">
                {
                    pathname.indexOf("/editData") >= 0 || pathname.indexOf("/projectDetails") >= 0 || pathname.indexOf("/projectSheet") >= 0 ?
                        (
                            <div className="fluid-container-wrapper">
                                <NavMenu />
                                {this.props.children}
                                <Footer />
                            </div>
                        ) : this.props.children
                }
            </Container>
        )
    }
}
