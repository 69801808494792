import React from "react"
import { withFormik } from "formik"
import * as Yup from "yup"
import Icon from "components/common/Icon"
import RequiredStar from "components/common/RequiredStar"
import { AuthorizedButton, AuthorizedInputField } from "components/authentication/controls/AuthorizedControls"

import {
    ButtonGroup,
    Col,
    Container,
    Form,
    FormGroup,
    Label,
    Row,
    Input,
    InputGroup,
    InputGroupAddon,
    InputGroupText
} from "reactstrap"

const SubmitButton = AuthorizedButton(p => p.canWriteCostCodes)
const InputField = AuthorizedInputField(p => p.canWriteCostCodes)

const BaseForm = props => {
    const {
        values,
        dirty,
        errors,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting
    } = props
    return (
        <div>
            <Form noValidate onSubmit={handleSubmit}>
                <h6>
                    <Icon folderOpen />
                    Cost Code Details
                </h6>
                <hr />
                <Container>
                    <Row>
                        <Col xs={12} md={6}>
                            <FormGroup>
                                <RequiredStar />
                                <Label for="code">Code</Label>
                                <InputField
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.code || ""}
                                    type="text"
                                    name="code"
                                    id="code"
                                    placeholder="Enter a cost code"
                                    invalid={errors.code} />
                                {errors.code && <small className="text-danger">{errors.code}</small>}
                            </FormGroup>
                        </Col>
                        <Col xs={12} md={6}>
                            <FormGroup>
                                <RequiredStar />
                                <Label for="description">Description</Label>
                                <InputField
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.description || ""}
                                    type="text"
                                    name="description"
                                    id="description"
                                    placeholder="Enter a cost code description"
                                    invalid={errors.description} />
                                {errors.description && <small className="text-danger">{errors.description}</small>}
                            </FormGroup>
                        </Col>
                        <Col xs={12} md={6}>
                            <FormGroup>
                                <InputGroup>
                                    <InputGroupAddon addonType="prepend">
                                        <InputGroupText>
                                            <InputField
                                                addon
                                                type="checkbox"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                checked={(values.disabled) === true}
                                                name="disabled"
                                                id="disabled"
                                            />
                                        </InputGroupText>
                                    </InputGroupAddon>
                                    <InputField
                                        readonly
                                        disabled
                                        placeholder={values.disabled === true ? "Disabled" : "Enabled"}
                                        invalid={errors.disabled} />
                                    {errors.disabled && <small className="text-danger">{errors.disabled}</small>}
                                </InputGroup>
                            </FormGroup>
                        </Col>
                    </Row>
                    <ButtonGroup className="float-right">
                        <SubmitButton outline disabled={isSubmitting || !dirty} color="warning" type="submit">Update</SubmitButton>
                    </ButtonGroup>
                </Container>
            </Form>
        </div>
    )
}

const validationSchema = Yup.object({
    code: Yup.string()
        .required("Code is required"),
    description: Yup.string()
        .required("Description is required")
})

const CostCodeFormBasic = withFormik({
    displayName: "CostCodeFormBasic",
    enableReinitialize: true,
    validationSchema: validationSchema,
    initialValues: ({ value }) => ({
        code: value.code,
        description: value.description
    }),
    handleSubmit: (values, { props, setSubmitting }) => {
        props.onSubmitUpdate(values)
        setSubmitting(false)
    }
})(BaseForm)

export default CostCodeFormBasic