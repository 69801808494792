import ScopeConstants from "constants/ScopeConstants"
import ExpenseStatusConstants from "constants/ExpenseStatusConstants"

export const ExpensesTeamByDueDateScope = {
    key: ScopeConstants.EXPENSE_TEAM_BY_DUE_DATE,
    selector: (state) => state.expensesTeamByDueDate,
    tokenSelector: (state) => state.authentication.token,
    fetchEndpoint: "/api/Expense/SearchTeamByDueDate/",
    singleEndpoint: "/api/Expense/GetTeam/",
    updateEndpoint: "/api/Expense/UpdateTeam/",
    deleteEndpoint: "/api/Expense/DeleteTeam/",
    defaultRecord: {
        name: "",
        code: "",
        status: ExpenseStatusConstants.DRAFT
    }
}