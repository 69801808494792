import React from "react"
import Moment from "moment"
import { withFormik } from "formik"
import * as Yup from "yup"
import RequiredStar from "components/common/RequiredStar"
import ClientInvoiceStatusConstants from "constants/ClientInvoiceStatusConstants"
import FeedbackAlert from "components/common/FeedbackAlert"
import { SpinnerOverlay } from "@gman/gman-react-bootstrap"
import { FeedbackConstants } from "@gman/gman-redux"

import {
    Container,
    Col,
    Form,
    FormGroup,
    Label,
    Row,
    Button,
    Input
} from "reactstrap"

const BaseForm = props => {
    const {
        values,
        errors,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
        inProgress,
        feedback,
        feedbackEvents
    } = props

    return (
        <div>
            <Form noValidate onSubmit={handleSubmit}>
                <Container>
                    {
                        feedback && feedbackEvents ?
                            <FeedbackAlert {...feedbackEvents} feedback={feedback} />
                            : ""
                    }
                    <Row>
                        <Col xs={12}>
                            <FormGroup>
                                <RequiredStar />
                                <Label for="number">Client Invoice Number</Label>
                                <Input
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.number}
                                    type="text"
                                    name="number"
                                    id="number"
                                    placeholder="Client invoice number"
                                    invalid={errors.number}/>
                                {errors.number && <small className="text-danger">{errors.number}</small>}
                            </FormGroup>
                        </Col>
                    </Row>
                </Container>
                <Button outline className="float-right" disabled={isSubmitting} color="success" type="submit">Update</Button>
            </Form>
        </div>
    )
}

const validationSchema = Yup.object({
    number: Yup.string()
        .required("Number is required"),
})

const ClientInvoiceNumberUpdateForm = withFormik({
    displayName: "ClientInvoiceNumberUpdateForm",
    validationSchema: validationSchema,
    enableReinitialize: true,
    mapPropsToValues: ({ ...props }) => ({
        ...props
    }),
    handleSubmit: (values, { props, setSubmitting }) => {
        debugger;
        props.onSubmitUpdate(values);
        setSubmitting(false)
    }
})(BaseForm)

export default ClientInvoiceNumberUpdateForm