import ScopeConstants from "constants/ScopeConstants"
import { ScopeShape } from "@gman/gman-redux"
import PropTypes from "prop-types"

export const ExpenseMonthlyDueDatesScope = {
    key: ScopeConstants.EXPENSE_DUE_DATE,
    selector: (state) => state.expenseDueDates,
    tokenSelector: (state) => state.authentication.token,
    fetchEndpoint: "/api/ExpenseDueDate/Search/",
    updateEndpoint: "/api/ExpenseDueDate/Update",
    deleteEndpoint: "/api/ExpenseDueDate/Delete",
    defaultRecord: {
        dueDate: ""
    }
}

ExpenseMonthlyDueDatesScope.propTypes = PropTypes.shape(ScopeShape).isRequired