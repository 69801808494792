import ScopeConstants from "constants/ScopeConstants"
import { FormActionReducer } from "@gman/gman-redux"

export const StaticProjectsReducer = (
    state = {
        ...FormActionReducer.defaultPageState(),
        ...FormActionReducer.defaultSearchOptions("DateCreated", 100000, true),
        ...FormActionReducer.defaultRecordState(true)
    },
    action) => {

    if (action.scope === ScopeConstants.STATIC_PROJECTS) {
        return FormActionReducer.reduce(action, state)
    }

    return state
}