import { connect } from "react-redux";
import ScopeConstants from "constants/ScopeConstants"
import ProposalCardExtended from "components/proposals/layout/ProposalCardExtended";
import ProposalList from "components/proposals/layout/ProposalList";
import {
  FormEvents,
  FormActions,
  ModalConstants,
  DispatchActions
} from "@gman/gman-redux";
import { ProjectAnyScope } from "components/projects/redux/ProjectAnyScope";
import { ProposalAnyScope } from "components/proposals/redux/ProposalAnyScope";
import { ProposalMineScope } from "components/proposals/redux/ProposalMineScope";
import { ClientScope } from "components/clients/redux/ClientScope";
import { ManagerScope } from "components/users/redux/ManagerScope";
import { OfficeScope } from "components/offices/redux/OfficeScope";

const mapDispatchToPropsMine = dispatch => {
  return mapDispatchToProps(dispatch, ProposalMineScope);
};

const mapDispatchToPropsAny = dispatch => {
  return mapDispatchToProps(dispatch, ProposalAnyScope);
};

const mapDispatchToProps = (dispatch, scope) => {
  return {
    ...FormEvents.getFeedbackEvents(dispatch, scope),
    ...FormEvents.getPaginatorEvents(dispatch, scope),
    ...FormEvents.getRecordStateEvents(dispatch, scope),
    ...FormEvents.getTableHeaderRowEvents(dispatch, scope),
    ...FormEvents.getModalEventsForModalsOnNewOnly(dispatch, scope),
    ...FormEvents.getTabEvents(dispatch, scope),
    ...FormEvents.getRedirectEvents(dispatch, scope),
    onConfirmConvert: record => {
      var redirect = { urlFunc: o => "/projectDetails/" + o.projectId+"?tab=1" };
      dispatch(
        FormActions.doPost(
          scope,
          record,
          "/api/Proposal/ConvertAny",
          "The record was converted successfully.",
          true,
          redirect
        )
      );
      dispatch(FormActions.hideModal(scope, "CONVERT"));
      dispatch(FormActions.fetchRecords(ProjectAnyScope));
    },
    onShowAllRecords:
      scope.key === ScopeConstants.PROPOSAL_ANY
        ? false
        : () => {
            dispatch({
              ...DispatchActions.scopedDispatch(scope.key, "SHOW_ALL")
            });
            dispatch(FormActions.fetchRecords(scope));
          },
    onPageSizeChange: pageSize => {
      dispatch({
        ...DispatchActions.scopedDispatch(scope.key, "CHANGE_PAGE_SIZE"),
        pageSize: pageSize
      });
      dispatch(FormActions.fetchRecords(scope));
    },
    onLoadDependentData: () => {
      dispatch(FormActions.fetchAll(ClientScope));
      dispatch(FormActions.fetchAll(ManagerScope));
      dispatch(FormActions.fetchAll(OfficeScope));
    },
    onloadClients:()=>{
      dispatch(FormActions.fetchAll(ClientScope));
    },
    confirmEvents: {
      ...FormEvents.getConfirmEventsForModalsOnNewOnly(dispatch, scope)
        .confirmEvents,
      onSubmitUpdate: (record, isNew = false) => {
        var redirect = isNew
          ? { urlFunc: o => "/proposalDetails/" + o.proposalId }
          : {};
        dispatch(
          FormActions.doPost(
            scope,
            record,
            scope.updateEndpoint,
            "The record was updated successfully.",
            true,
            redirect
          )
        );
        dispatch(FormActions.hideModal(scope, ModalConstants.UPDATE));
      },
      onConfirmPublish: record => {
        var redirect = { urlFunc: () => "/proposal/any/" };
        dispatch(
          FormActions.doPost(
            scope,
            record,
            scope.publishEndpoint,
            "The record was published successfully.",
            true,
            redirect
          )
        );
        dispatch(FormActions.hideModal(scope, ModalConstants.PUBLISH));
      },
    }
  };
};

const ProposalListMineContainer = connect((state, ownProps) => {
  return {
    sourceData: ProposalMineScope.selector(state),
    clientId: ownProps.clientId
  };
}, mapDispatchToPropsMine)(ProposalList);

const ProposalListAnyContainer = connect((state, ownProps) => {
  return {
    sourceData: ProposalAnyScope.selector(state),
    clientId: ownProps.clientId
  };
}, mapDispatchToPropsAny)(ProposalList);

const DetailsContainer = readOnly =>
  connect((state, ownProps) => {
    return {
      sourceData: ProposalAnyScope.selector(state),
      proposalId: ownProps.proposalId,
      readOnly: readOnly
    };
  }, mapDispatchToPropsAny)(ProposalCardExtended);

const ProposalEditContainer = DetailsContainer(false);

const ProposalReadContainer = DetailsContainer(true);

export {
  ProposalEditContainer,
  ProposalReadContainer,
  ProposalListMineContainer,
  ProposalListAnyContainer
};
