import ScopeConstants from "constants/ScopeConstants"
import { ScopeShape } from "@gman/gman-redux"
import InvoiceStatusConstants from "constants/InvoiceStatusConstants";
import PropTypes from "prop-types"

export const SubContractorInvoiceEditListScope = {
    key: ScopeConstants.SUB_CONTRACTOR_INVOICE_EDIT_LIST,
    selector: (state) => state.subContractorInvoicesEditList,
    tokenSelector: (state) => state.authentication.token,
    fetchEndpoint: "/api/SubContractorInvoice/Filter/",
    singleEndpoint: "/api/SubContractorInvoice/Get/",
    updateEndpoint: "/api/SubContractorInvoice/Update/",
    deleteEndpoint: "/api/SubContractorInvoice/Delete/",
    defaultRecord: {
        status: InvoiceStatusConstants.UNPAID
    }
}

SubContractorInvoiceEditListScope.propTypes = PropTypes.shape(ScopeShape).isRequired