import React from "react"
import { withFormik } from "formik"
import * as Yup from "yup"
import RequiredStar from "components/common/RequiredStar"
import { FormModal } from "@gman/gman-react-bootstrap"
import { ModalActions } from "@gman/gman-redux"
import Moment from "moment";
import Icon from "components/common/Icon";

import {
    Button,
    Col,
    Container,
    Form,
    FormGroup,
    Input,
    Label,
    Row
} from "reactstrap"

const BaseForm = props => {
    const {
        onShowModal,
        onHideModal,
        modals,
        values,
        dirty,
        errors,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting
    } = props
    return (
        <div>
            <Form noValidate onSubmit={handleSubmit}>
                <Container>
                    {
                        values
                            .chargeRates
                            .sort((a, b) => a.classification.name.localeCompare(b.classification.name))
                            .map((chargeRate, index) => (
                                <Row key={index}>
                                    <Col xs={12} md={6}>
                                        <FormGroup>
                                            <RequiredStar />
                                            <Label for="name">
                                                {chargeRate.classification.name}
                                            </Label>
                                        </FormGroup>
                                    </Col>
                                    <Col xs={12} md={6}>
                                        <FormGroup>
                                            <Input
                                                size="sm"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={chargeRate.rate || ""}
                                                type="text"
                                                name={"chargeRates[" + index + "].rate"}
                                                id={"chargeRates[" + index + "].rate"}
                                                placeholder="Enter the new rate"
                                                invalid={errors.chargeRates && errors.chargeRates.length >= index && errors.chargeRates[index] && errors.chargeRates[index].rate}
                                            />
                                            {
                                                errors.chargeRates && errors.chargeRates.length >= index && errors.chargeRates[index] && errors.chargeRates[index].rate &&
                                                <small className="text-danger">
                                                    {errors.chargeRates && errors.chargeRates.length >= index && errors.chargeRates[index] && errors.chargeRates[index].rate}
                                                </small>
                                            }
                                        </FormGroup>
                                    </Col>
                                </Row>

                            ))
                    }
                </Container>
                <Button
                    outline
                    className="float-right"
                    disabled={isSubmitting || !dirty}
                    color="success"
                    onClick={() => onShowModal("CONFIRM_CHARGE_RATES_PUBLISH")}
                >
                    Publish
                    </Button>
                <FormModal
                    isVisible={ModalActions.IsVisible(modals, "CONFIRM_CHARGE_RATES_PUBLISH")}
                    onHide={() => onHideModal("CONFIRM_CHARGE_RATES_PUBLISH")}
                    title={"Confirm Create Rates"}>
                    <FormGroup>
                        Confirm publish of new rates?
                        <Button
                            className="float-right"
                            color="success"
                            onClick={handleSubmit}
                        >
                            Confirm
                    </Button>
                    </FormGroup>
                </FormModal>
            </Form>
        </div>
    )
}

const validationSchema = Yup.object({
    chargeRates: Yup.array().of(
        Yup.object()
            .shape({
                rate: numberValidation()
            }))
})

function numberValidation() {
    return Yup.number()
        .default(0)
        .nullable()
        .required("Value is required")
        .round()
        .positive("Must be positive")
        .typeError("Must be a number")
}

const CostRateForm = withFormik({
    displayName: "ChargeRatesDraftForm",
    enableReinitialize: true,
    validationSchema: validationSchema,
    initialValues: ({ value }) => ({
        description: value.description,
        chargeRates: value.chargeRates
    }),
    handleSubmit: (values, { props, setSubmitting }) => {
        props.onSubmitUpdate(values)
        setSubmitting(false)
    }
})(BaseForm)

export default CostRateForm