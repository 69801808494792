import React, { Component } from "react"
import {
    Card,
    CardBody,
    CardHeader,
    CardTitle,
    CardSubtitle,
    Container,
    Col,
    Nav,
    NavItem,
    NavLink,
    Row,
    TabContent,
    TabPane
} from "reactstrap"
import Icon from "components/common/Icon"
import { SpinnerOverlay } from "@gman/gman-react-bootstrap"

import FeedbackAlert from "components/common/FeedbackAlert"
import ChargeRateTypeConstants from "constants/ChargeRateTypeConstants"

import ClientExtendedForm from "components/clients/form/ClientFormExtended"
import { ProjectListAnyContainer } from "components/projects/redux/ProjectContainers"
import { ProposalListAnyContainer } from "components/proposals/redux/ProposalContainers"
import { OpportunityListAnyContainer } from "components/opportunities/redux/OpportunityContainers"
import { CostCodeGroupListContainer } from "components/costCodeGroups/redux/CostCodeGroupContainers"
import { ChargeRateListContainer, ChargeRateListExtendedContainer } from "components/chargeRates/redux/ChargeRateContainers"
import { RestrictedComponent } from "components/authentication/redux/AuthorizationWrappers"

const ChargeRatesTab = RestrictedComponent((props) =>
    <NavItem>
        <NavLink onClick={props.onChangeTab}>
            <Icon chargeRate />
            Charge Rates
        </NavLink>
    </NavItem>, p => p.canReadChargeRates)

const CostCodeGroupsTab = RestrictedComponent((props) =>
    <NavItem>
        <NavLink onClick={props.onChangeTab}>
            <Icon costCodeGroup />Cost Code Groups
        </NavLink>
    </NavItem>, p => p.canReadCostCodes)

class ClientCardExtended extends Component {

    componentDidMount() {
        if (this.props.clientId) {
            this.props.recordStateEvents.onLoadSingle(this.props.clientId)
            this.props.tabEvents.onChangeTab("1")
        }
    }

    componentWillUnmount = () => {
        this.props.feedbackEvents.onFlushFeedback()
    }

    render() {
        var sourceData = this.props.sourceData
        var confirmEvents = this.props.confirmEvents
        var feedbackEvents = this.props.feedbackEvents
        var tabEvents = this.props.tabEvents
        console.log("sourceData-aaa",sourceData);
        return (
            <Card>
                <CardHeader>
                    <CardTitle>
                        {
                            sourceData.recordToUpdate ?
                                sourceData.recordToUpdate.name :
                                "Loading..."
                        }
                    </CardTitle>
                    <hr />
                    <CardSubtitle>
                        <Icon client />
                        <small>
                            <i>Client</i>
                        </small>
                    </CardSubtitle>
                </CardHeader>
                <CardBody>
                    <FeedbackAlert {...feedbackEvents} feedback={sourceData.feedback} />
                    <SpinnerOverlay isLoading={sourceData.inProgress}>
                        <ClientExtendedForm
                            onSubmitUpdate={confirmEvents.onSubmitUpdate}
                            {...sourceData.recordToUpdate} />
                    </SpinnerOverlay>
                    <div>
                        <Nav tabs>
                            <NavItem>
                                <NavLink onClick={() => tabEvents.onChangeTab("1")}>
                                    <Icon project />Projects
                                </NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink onClick={() => tabEvents.onChangeTab("2")}>
                                    <Icon proposal />Proposals
                                </NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink onClick={() => tabEvents.onChangeTab("3")}>
                                    <Icon opportunity />Opportunities
                                </NavLink>
                            </NavItem>
                            <CostCodeGroupsTab onChangeTab={() => tabEvents.onChangeTab("4")} />
                            <ChargeRatesTab onChangeTab={() => tabEvents.onChangeTab("5")} />
                        </Nav>
                        <TabContent activeTab={sourceData.activeTab}>
                            <TabPane tabId="1">
                                <Container>
                                    <Row>
                                        <Col>
                                            <ProjectListAnyContainer clientId={this.props.clientId} client={sourceData.recordToUpdate}/>
                                        </Col>
                                    </Row>
                                </Container>
                            </TabPane>
                            <TabPane tabId="2">
                                <Container>
                                    <Row>
                                        <Col>
                                            <ProposalListAnyContainer clientId={this.props.clientId} />
                                        </Col>
                                    </Row>
                                </Container>
                            </TabPane>
                            <TabPane tabId="3">
                                <Container>
                                    <Row>
                                        <Col>
                                            <OpportunityListAnyContainer clientId={this.props.clientId} />
                                        </Col>
                                    </Row>
                                </Container>
                            </TabPane>
                            <TabPane tabId="4">
                                <Container>
                                    <Row>
                                        <Col>
                                            <CostCodeGroupListContainer clientId={this.props.clientId} />
                                        </Col>
                                    </Row>
                                </Container>
                            </TabPane>
                            <TabPane tabId="5">
                                <Container>
                                    <Row>
                                        <Col>
                                            <ChargeRateListExtendedContainer
                                                principalId={this.props.clientId}
                                                currentRatesType={ChargeRateTypeConstants.CLIENT}
                                                principal={sourceData.recordToUpdate}
                                            />
                                        </Col>
                                    </Row>
                                </Container>
                            </TabPane>
                        </TabContent>
                    </div>
                </CardBody>
            </Card>
        )
    }
}

export default ClientCardExtended