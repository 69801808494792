import React from "react"
import { Card, CardBody, CardHeader } from "reactstrap"
import { ProjectListAnyContainer } from "components/projects/redux/ProjectContainers"
import Icon from "components/common/Icon"

const ProjectCardAnyBasic = () => {
    return (
        <Card>
            <CardHeader>
                <h6>
                    <Icon project />
                    All Projects
                </h6>
            </CardHeader>
            <CardBody>
                <ProjectListAnyContainer />
            </CardBody>
        </Card >
    )
}

export default ProjectCardAnyBasic
