import React, { Component } from "react"
import { 
    TimesheetHoursContainer,
    UnpaidInvoicesContainer,
    HistoricalInvoicesContainer,
    ReportSubContractorInvoicesContainer,
    ReportIndividualInvoiceNumbersContainer,
    ReportChargeRatesContainer,
    ReportProjectFinancialsContainer,
} from "components/reports/redux/ReportContainers"

export class TimesheetHoursPage extends Component { render() { return (<TimesheetHoursContainer />) } }
export class UnpaidInvoicesPage extends Component { render() { return (<UnpaidInvoicesContainer />) } }
export class HistoricalInvoicesPage extends Component { render() { return (<HistoricalInvoicesContainer />) } }
export class ReportSubContractorInvoicesInvoicesPage extends Component { render() { return (<ReportSubContractorInvoicesContainer />) } }
export class ReportIndividualInvoiceNumbersPage extends Component { render() { return (<ReportIndividualInvoiceNumbersContainer />) } }
export class ReportChargeRatesPage extends Component { render() { return (<ReportChargeRatesContainer />) } }
export class ReportProjectFinancialsPage extends Component { render() { return (<ReportProjectFinancialsContainer />) } }
