import ScopeConstants from "constants/ScopeConstants"
import { ScopeShape } from "@gman/gman-redux"
import PropTypes from "prop-types"

export const LeaveScope = {
    key: ScopeConstants.LEAVE,
    selector: (state) => state.leave,
    tokenSelector: (state) => state.authentication.token,
    fetchEndpoint: "/api/Leave/Search/",
    singleEndpoint: "/api/Leave/Get/"
}

LeaveScope.propTypes = PropTypes.shape(ScopeShape).isRequired