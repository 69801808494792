import ScopeConstants from "constants/ScopeConstants"
import { ScopeShape } from "@gman/gman-redux"
import PropTypes from "prop-types"

export const ClientInvoiceScope = {
    key: ScopeConstants.CLIENT_INVOICE,
    selector: (state) => state.clientInvoices,
    tokenSelector: (state) => state.authentication.token,
    fetchEndpoint: "/api/ClientInvoice/Search/",
    singleEndpoint: "/api/ClientInvoice/Get/",
    updateEndpoint: "/api/ClientInvoice/Update/",
    deleteEndpoint: "/api/ClientInvoice/Delete/",
    defaultRecord: { }
}

ClientInvoiceScope.propTypes = PropTypes.shape(ScopeShape).isRequired