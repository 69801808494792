import React from "react"
import PropTypes from "prop-types"
import { Button } from "reactstrap"

const ActiveSort = props => {
    const { onSortBy, disabled } = props
    return (
        <Button size="sm" className={props.className} disabled={disabled} color="link" onClick={onSortBy}>
            {props.children}
        </Button>
    )
}

ActiveSort.propTypes = {
    onSortBy: PropTypes.func.isRequired,
    disabled: PropTypes.bool.isRequired
}

export default ActiveSort