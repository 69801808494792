import React from "react"
import PropTypes from "prop-types"

const ActiveCell = props => {
    const { active, style } = props
    return (
        <td style={style}>
            <p className={(active === false) ? "text-muted" : ""}>
                {props.children}
            </p>
        </td>
    )
}

ActiveCell.propTypes = {
    active: PropTypes.bool
}

export default ActiveCell