import React from "react"
import { withFormik } from "formik"
import Icon from "components/common/Icon"
import FeedbackAlert from "components/common/FeedbackAlert"

import {
    Button,
    ButtonGroup,
    Col,
    Container,
    Form,
    FormGroup,
    Input,
    Label,
    Row
} from "reactstrap"

const BaseForm = props => {
    const {
        values,
        dirty,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
        feedbackEvents,
        feedback
    } = props
    return (
        <div>
            <Form noValidate onSubmit={handleSubmit}>
                {
                    feedback && feedbackEvents ?
                        <FeedbackAlert {...feedbackEvents} feedback={feedback} />
                        : ""
                }
                <h6>
                    <Icon workbench />
                    Export Details
                </h6>
                <hr />
                <Container>
                    <Row>
                        <Col xs={12}>
                            <FormGroup check>
                                <Label check>
                                    <Input
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        checked={(values.isImported) === true}
                                        type="checkbox"
                                        name="isImported"
                                    />
                                    {' '}
                                    This batch has been imported into Workbench
                                </Label>
                            </FormGroup>
                        </Col>
                    </Row>
                    <br />
                    <br />
                    <ButtonGroup className="float-right">
                        <Button outline disabled={isSubmitting || !dirty} color="warning" type="submit">Update</Button>
                    </ButtonGroup>
                </Container>
            </Form>
        </div>
    )
}

const WorkbenchExportFormBasic = withFormik({
    displayName: "WorkbenchExportFormBasic",
    enableReinitialize: true,
    mapPropsToValues: ({ ...props }) => ({
        ...props,
        exportDate: props.exportDate || "",
        code: props.code,
        name: props.name,
        numberOfRows: props.numberOfRows,
    }),
    handleSubmit: (values, { props, setSubmitting }) => {
        props.onSubmitUpdate(values)
        setSubmitting(false)
    }
})(BaseForm)

export default WorkbenchExportFormBasic