import React from "react"
import PropTypes from "prop-types"
import ScopeConstants from "constants/ScopeConstants"
import Icon from "components/common/Icon"
import { Col, Row, Table } from "reactstrap"

const WorkItemCell = props => {
    const {
        workItem,
        projectColumnWidth,
        projectCodeColumnWidth,
        projectVariationColumnWidth,
        projectCostCodeColumnWidth,
        hideProjectCode
    } = props
    return (
        <div>
            <Table>
                <Row>
                    <Col md={12} xl={projectColumnWidth ? projectColumnWidth : 6}>
                        {
                            workItem.type === ScopeConstants.PROJECT_ANY ?
                                <Icon project /> :
                                workItem.type === ScopeConstants.PROPOSAL_ANY ?
                                    <Icon proposal /> :
                                    <Icon leave />
                        }
                        {workItem.name}
                    </Col>
                    {hideProjectCode !== true &&  <Col md={12} xl={projectCodeColumnWidth ? projectCodeColumnWidth : 6}>
                        <small>
                            <b>{workItem.code}</b>
                        </small>
                    </Col>}
                   
                </Row>
                <Row>
                    <Col md={12} xl={projectVariationColumnWidth ? projectVariationColumnWidth : 6}>
                        <small>
                            {workItem.variation && workItem.variation.number} {workItem.variation && workItem.variation.description}
                        </small>
                    </Col>
                    <Col md={12} xl={projectCostCodeColumnWidth ? projectCostCodeColumnWidth : 6}>
                        <small>
                            {workItem.costCode && workItem.costCode.code} {workItem.costCode && workItem.costCode.description}
                        </small>
                    </Col>
                </Row>
            </Table>
        </div>
    )
}

WorkItemCell.propTypes = {
    workItem: PropTypes.shape({
        type: PropTypes.string,
        name: PropTypes.string,
        code: PropTypes.string
    })
}

export default WorkItemCell