import ScopeConstants from "constants/ScopeConstants"

export const LastInvoicePaymentsReducer = (
  state = {
    lastPaymentDate: ""
  },
  action) => {

  if (action.scope === ScopeConstants.CLIENT_INVOICE) {
    if (action.type === "SET_LAST_PAYMENT_DATE") {
      return {
        ...state,
        lastPaymentDate: action.paymentDate
      }
    }
  }

  return state
}