import React from "react";
import { Badge } from "reactstrap";
import TimesheetStatusConstants from "constants/TimesheetStatusConstants";

const TimesheetStatusBadge = props => {
  const { value } = props;
  return (
    <Badge color={MapTimesheetStatusColour(value)}>
      {MapTimesheetStatusLabel(value)}
    </Badge>
  );
};

const MapTimesheetStatusLabel = value => {
  switch (value) {
    case TimesheetStatusConstants.MISSING:
      return "MISSING";
    case TimesheetStatusConstants.DRAFT:
      return "DRAFT";
    case TimesheetStatusConstants.INPROGRESS:
      return "SUBMITTED";
    case TimesheetStatusConstants.COMPLETED:
      return "APPROVED";
    case TimesheetStatusConstants.INVOICED:
      return "INVOICED";
    default:
      return "UNKNOWN";
  }
};

const MapTimesheetStatusColour = value => {
  switch (value) {
    case TimesheetStatusConstants.MISSING:
      return "danger";
    case TimesheetStatusConstants.DRAFT:
      return "warning";
    case TimesheetStatusConstants.INPROGRESS:
      return "primary";
    case TimesheetStatusConstants.COMPLETED:
      return "success";
    case TimesheetStatusConstants.INVOICED:
      return "light";
    default:
      return "default";
  }
};

export default TimesheetStatusBadge;
