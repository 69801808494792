import React from "react"
import {
    Button,
    Card,
    CardBody,
    CardHeader,
    Col,
    Container,
    Row
} from "reactstrap"
import Icon from "components/common/Icon"
import { SpinnerOverlay } from "@gman/gman-react-bootstrap"
import FeedbackAlert from "components/common/FeedbackAlert"
import FileUploader from "components/common/FileUploader"

const ReportExportCardBasic = props => {
    const {
        feedbackEvents,
        sourceData,
        onRunExport,
        onUploadSubmit,
        uploadEvents
    } = props
    return (
        <Card>
            <CardHeader>
                <h6>
                    <Icon reports />
                    Reports
                </h6>
            </CardHeader>
            <CardBody>
                <SpinnerOverlay isLoading={sourceData.inProgress}>
                    <FeedbackAlert {...feedbackEvents} feedback={sourceData.feedback} />
                    <Container>
                        <Row>
                            <Col xs={12} md={6}>
                                <h5>{sourceData && sourceData.reportName}</h5>
                                <p className="lead">{sourceData && sourceData.reportDescription}</p>
                            </Col>
                            <Col xs={12} md={6}>
                                <Button
                                    outline
                                    block
                                    color="primary"
                                    onClick={onRunExport}
                                >
                                    Click to export
                                </Button>
                            </Col>
                        </Row>
                        {
                            /*
                            onUploadSubmit ? (
                            <Row>
                                <Col xs={12} md={6}>
                                    <h5>Uplaod Invoice Detail</h5>
                                </Col>
                                <Col xs={12} md={6}>
                                    <FileUploader
                                        feedback={sourceData.feedback}
                                        feedbackEvents={feedbackEvents}
                                        uploadedFiles={sourceData.fileData}
                                        isUploading={sourceData.isUploading}
                                        uploadFeedback={sourceData.uploadFeedback}
                                        multiple={true}
                                        accept=".xls, .xlsx"
                                        maxSize={6291456}
                                        {...uploadEvents}
                                        onUploadSubmit={() => onUploadSubmit(sourceData.fileData)}
                                    >
                                        <div>
                                            Click here to upload a file or drag a file into this zone.
                                            <br />
                                            Files must be .XLS or .XLSX
                                        </div>
                                    </FileUploader>
                                </Col>

                            </Row>
                        ) : ('')
                        */
                        }

                    </Container>
                </SpinnerOverlay>
            </CardBody>
        </Card>
    )
}

export default ReportExportCardBasic