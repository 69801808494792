import ScopeConstants from "constants/ScopeConstants"
import { ScopeShape } from "@gman/gman-redux"
import PropTypes from "prop-types"

export const TimesheetEntryEditListScope = {
    key: ScopeConstants.TIMESHEET_ENTRY_EDIT_LIST,
    selector: (state) => state.timesheetEntryEditList,
    tokenSelector: (state) => state.authentication.token,
    fetchEndpoint: "/api/TimesheetEntry/Filter/",
    singleEndpoint: "/api/TimesheetEntry/Get/",
    updateEndpoint: "/api/TimesheetEntry/Update/",
    deleteEndpoint: "/api/TimesheetEntry/Delete/",
    unauthorizedFallbackRedirect: "/timesheet/mine/",
    defaultRecord: {
        mondayHours: "",
        tuesdayHours: "",
        wednesdayHours: "",
        thursdayHours: "",
        fridayHours: "",
        saturdayHours: "",
        sundayHours: "",
        timesheetId: "",
        projectId: "",
        comment: ""
    }
}

TimesheetEntryEditListScope.propTypes = PropTypes.shape(ScopeShape).isRequired