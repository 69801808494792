import React from "react"
import { withFormik } from "formik"
import * as Yup from "yup"
import RequiredStar from "components/common/RequiredStar"
import { AuthorizedButton, AuthorizedInputField } from "components/authentication/controls/AuthorizedControls"

import {
    Col,
    Container,
    Form,
    FormGroup,
    Label,
    Row
} from "reactstrap"

const SubmitButton = AuthorizedButton(p => p.canWriteExpenseTypes)
const InputField = AuthorizedInputField(p => p.canWriteExpenseTypes)

const BaseForm = props => {
    const {
        values,
        dirty,
        errors,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting
    } = props
    return (
        <div>
            <Form noValidate onSubmit={handleSubmit}>
                <Container>
                    <Row>
                        <Col xs={12} md={12}>
                            <FormGroup>
                                <RequiredStar />
                                <Label for="description">Description</Label>
                                <InputField
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.description || ""}
                                    type="text"
                                    name="description"
                                    id="description"
                                    placeholder="Enter a description"
                                    invalid={errors.description} />
                                {errors.description && <small className="text-danger">{errors.description}</small>}
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12} md={12}>
                            <FormGroup>
                                <RequiredStar />
                                <Label for="code">Code / Sort Order</Label>
                                <InputField
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.code || 0}
                                    type="number"
                                    step={1}
                                    min={0}
                                    name="code"
                                    id="code"
                                    placeholder="Enter the code / sort order"
                                    invalid={errors.code} />
                                {errors.code && <small className="text-danger">{errors.code}</small>}
                            </FormGroup>
                        </Col>
                    </Row>
                </Container>
                <SubmitButton outline className="float-right" disabled={isSubmitting || !dirty} color="success" type="submit">Submit</SubmitButton>
            </Form>
        </div >
    )
}

const validationSchema = Yup.object({
    description: Yup.string()
        .required("Description is required"),
    code: numberValidation()
        .required("Code is required")
        .min(0, "Cannot be less than 0"),
})

function numberValidation() {
    return Yup.number()
        .default(0)
        .nullable()
        .required("Value is required")
        .round()
        .positive("Must be positive")
        .typeError("Must be a number")
}

const ExpenseTypeFormBasic = withFormik({
    displayName: "ExpenseTypeFormBasic",
    enableReinitialize: true,
    validationSchema: validationSchema,
    initialValues: ({ value }) => ({
        description: value.description
    }),
    handleSubmit: (values, { props, setSubmitting }) => {
        props.onSubmitUpdate(values)
        setSubmitting(false)
    }
})(BaseForm)

export default ExpenseTypeFormBasic