import React from "react"
import { Card, CardBody, CardHeader } from "reactstrap"
import { CostCodeListContainer } from "components/costCodes/redux/CostCodeContainers"
import Icon from "components/common/Icon"

const CostCodeCardBasic = () => {
    return (
        <Card>
            <CardHeader>
                <h6>
                    <Icon costCode />
                    Cost Codes
                </h6>
            </CardHeader>
            <CardBody>
                <CostCodeListContainer />
            </CardBody>
        </Card >
    )
}

export default CostCodeCardBasic