import React from "react"
import { Card, CardBody, CardHeader } from "reactstrap"
import { WorkbenchExportListContainer } from "components/workbenchExports/redux/WorkbenchExportContainers"
import Icon from "components/common/Icon"

const WorkbenchExportCardBasic = () => {
    return (
        <Card>
            <CardHeader>
                <h6>
                    <Icon workbench />
                    Workbench Exports
                </h6>
            </CardHeader>
            <CardBody>
                <WorkbenchExportListContainer />
            </CardBody>
        </Card >
    )
}

export default WorkbenchExportCardBasic