import React from "react"
import PropTypes from "prop-types"

const TimeCell = ({
    className,
    value,
    onClick,
    style
}) => {
    return (
        <td className={className} style={style} onClick={onClick}>
            {
                value != 0 ?
                    (<div>
                        {value.toFixed(2)}
                    </div>)
                    :
                    ('')
            }
        </td>
    )
}

TimeCell.propTypes = {
    active: PropTypes.bool
}

export default TimeCell