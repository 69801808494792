import ScopeConstants from "constants/ScopeConstants"

export const WorkbenchExportScope = {
    key: ScopeConstants.WORKBENCH_EXPORT,
    selector: (state) => state.workbenchExports,
    tokenSelector: (state) => state.authentication.token,
    fetchEndpoint: "/api/WorkbenchExport/Search/",
    singleEndpoint: "/api/WorkbenchExport/Get/",
    updateEndpoint: "/api/WorkbenchExport/Update/",
    deleteEndpoint: "/api/WorkbenchExport/Delete/",
    defaultRecord: {}
}