import ScopeConstants from "constants/ScopeConstants"
import ExpenseStatusConstants from "constants/ExpenseStatusConstants"

export const ExpensesMineByDueDateScope = {
    key: ScopeConstants.EXPENSE_MINE_BY_DUE_DATE,
    selector: (state) => state.expensesMineByDueDate,
    tokenSelector: (state) => state.authentication.token,
    fetchEndpoint: "/api/Expense/SearchMineByDueDate/",
    singleEndpoint: "/api/Expense/GetMine/",
    updateEndpoint: "/api/Expense/UpdateMine/",
    deleteEndpoint: "/api/Expense/DeleteMine/",
    defaultRecord: {
        status: ExpenseStatusConstants.DRAFT
    }
}