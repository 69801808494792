import React, { Component } from "react"
import UserAuthenticationFormBasic from "components/userAuthentications/form/UserAuthenticationFormBasic"
import FeedbackAlert from "components/common/FeedbackAlert"

class UserAuthenticationCardBasic extends Component {

    componentDidMount() {
        if (this.props.userId) {
            this.props.recordStateEvents.onLoadSingle(this.props.userId)
            this.props.onLoadDependentData()
        }
    }

    componentWillUnmount = () => {
        this.props.feedbackEvents.onFlushFeedback()
    }

    render() {
        var sourceData = this.props.sourceData
        var modalEvents = this.props.modalEvents
        var modals = sourceData.modals
        var feedbackEvents = this.props.feedbackEvents

        return (
            <div>
                <FeedbackAlert {...feedbackEvents} feedback={sourceData.feedback} />
                <UserAuthenticationFormBasic
                    {...sourceData.recordToUpdate}
                    modals={modals}
                    modalEvents={modalEvents}
                    onSubmitUpdate={this.props.onSubmitUpdate} />
            </div>
        )
    }
}

export default UserAuthenticationCardBasic