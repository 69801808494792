import React, { Component } from "react"
import { Link } from "react-router-dom"
import { Row, Col, Container } from "reactstrap"
import { SpinnerOverlay } from "@gman/gman-react-bootstrap"

export class TimesheetQuickNumbers extends Component {

    componentDidMount() {
        if (this.props.onLoadData) {
            this.props.onLoadData()
        }
    }

    render() {
        var sourceData = this.props.sourceData
        var quickLinks = this.props.quickLinks || {}
        var timesheetEntryLink = this.props.timesheetEntryLink || {}

        var columns = 0
        if (sourceData && sourceData.records) {
            if (sourceData.records.outstanding) columns++
            if (sourceData.records.current) columns++
            if (sourceData.records.submitted) columns++
        }
        if (columns === 0) { columns = 1 }
        return (
            <div>
                <SpinnerOverlay isLoading={sourceData && sourceData.progress}>
                    {
                        (sourceData && sourceData.records) ?
                            <Container>
                                <Row>
                                    {
                                        sourceData.records.outstanding ?
                                            <Col xs={6} md={12 / columns} className="count-danger">
                                                <div>
                                                    <Link to={sourceData.records.outstanding == 1 && sourceData.records.singleOutstandingId != null ? (timesheetEntryLink ? (timesheetEntryLink + sourceData.records.singleOutstandingId) : "") : (quickLinks.outstanding || "/")}>
                                                        <p className="big-count">{sourceData.records.outstanding}</p>
                                                        outstanding
                                                    </Link>
                                                </div>
                                            </Col>
                                            : ""
                                    }
                                    {
                                        sourceData.records.current ?
                                            <Col xs={6} md={12 / columns} className="count-warning">
                                                <div>
                                                    <Link to={sourceData.records.current == 1 && sourceData.records.singleCurrentId != null ? (timesheetEntryLink ? (timesheetEntryLink + sourceData.records.singleCurrentId) : "") : (quickLinks.current || "/")}>
                                                        <p className="big-count">{sourceData.records.current}</p>
                                                        current
                                                    </Link>
                                                </div>
                                            </Col>
                                            : ""
                                    }
                                    {
                                        sourceData.records.submitted ?
                                            <Col xs={6} md={12 / columns} className="count-info" >
                                                <div>
                                                    <Link to={sourceData.records.submitted == 1 && sourceData.records.singleSubmittedId != null ? (timesheetEntryLink ? (timesheetEntryLink + sourceData.records.singleSubmittedId) : "") : (quickLinks.submitted || "/")}>
                                                        <p className="big-count">{sourceData.records.submitted}</p>
                                                        pending approval
                                                    </Link>
                                                </div>
                                            </Col>
                                            : ""
                                    }
                                </Row>
                            </Container>
                            :
                            <span>Loading...</span>
                    }
                </SpinnerOverlay>
            </div>
        );
    }
}