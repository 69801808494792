import ScopeConstants from "constants/ScopeConstants"
import { ScopeShape } from "@gman/gman-redux"
import PropTypes from "prop-types"

export const ReportIndividualInvoiceNumbersScope = {
    key: ScopeConstants.REPORT_INDIVIDUAL_INVOICE_NUMBERS,
    selector: (state) => state.reportIndividualInvoiceNumbersReducer,
    tokenSelector: (state) => state.authentication.token
}

ReportIndividualInvoiceNumbersScope.propTypes = PropTypes.shape(ScopeShape).isRequired