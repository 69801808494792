import React from "react"
import { Link } from "react-router-dom"
import PropTypes from "prop-types"
import { CostCodeGroupShape } from "shapes/CostCodeGroupShape"
import ActiveCell from "components/common/ActiveCell"
import { AuthorizedSummaryToolbar } from "components/authentication/controls/AuthorizedControls"

const Toolbar = AuthorizedSummaryToolbar(p => p.canWriteCostCodes)

const CostCodeGroupRow = (
    {
        costCodeGroupId,
        name,
        description,
        onShowEdit,
        onShowDelete,
        disabled
    }) => (
        <tr>
            <ActiveCell active={!disabled}>
                <Link to={"/costCodeGroupDetails/" + costCodeGroupId} onClick={onShowEdit}>
                    {name}
                </Link>
            </ActiveCell>
            <ActiveCell active={!disabled}>{description}</ActiveCell>
            <td>
                <Toolbar disabled={disabled} onShowDelete={onShowDelete} />
            </td>
        </tr>
    )

CostCodeGroupRow.propTypes = PropTypes.shape(CostCodeGroupShape).isRequired

export default CostCodeGroupRow 