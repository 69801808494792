import React from "react"
import { Link } from "react-router-dom"
import Moment from "moment"
import Currency from "components/common/Currency"
import Icon from "components/common/Icon"
import { LookupLabel } from "@gman/gman-redux"

import {
    Col,
    Container,
    Input,
    Label,
    Row
} from "reactstrap"

const label = { xs: 12, md: 3 }
const field = { xs: 12, md: 9 }

const ProposalDisplayExtended = props => {
    const { values } = props
    var readOnlyClass = "form-control-plaintext form-control read-only-label"
    return (
        <div>
            {values.projectId ?
                projectLink(values.projectId)
                :
                ""}
            <h6>
                <Icon folderOpen />
                Proposal Details
                </h6>
            <hr />
            <Container>
                <Row>
                    <Col {...label}>
                        <Label for="name">Proposal Name</Label>
                    </Col>
                    <Col {...field}>
                        <Input className={readOnlyClass} value={values.name} />
                    </Col>
                    <Col {...label}>
                        <Label for="code">Proposal Code</Label>
                    </Col>
                    <Col {...field}>
                        <Input className={readOnlyClass} value={values.code} />
                    </Col>
                    <Col {...label}>
                        <Label for="proposalManagerId">Proposal Manager</Label>
                    </Col>
                    <Col {...field}>
                        <LookupLabel
                            className={readOnlyClass}
                            value={values.proposalManagerId}
                            stateFunc={(state) => { return { ...state.managers } }}
                            valueFunc={(record) => record.userId}
                            labelFunc={(record) => record.firstName + " " + record.lastName}
                        />
                    </Col>
                    <Col {...label}>
                        <Label for="officeId">CMP Office</Label>
                    </Col>
                    <Col {...field}>
                        <LookupLabel
                            className={readOnlyClass}
                            value={values.officeId}
                            stateFunc={(state) => { return { ...state.offices } }}
                            valueFunc={(record) => record.officeId}
                            labelFunc={(record) => record.name}
                        />
                    </Col>
                </Row>
            </Container >
            <h6>
                <Icon client />
                Client Details
                </h6>
            <hr />
            <Container>
                <Row>
                    <Col {...label}>
                        <Label for="clientId">Client</Label>
                    </Col>
                    <Col {...field}>
                        <LookupLabel
                            className={readOnlyClass}
                            value={values.clientId}
                            stateFunc={(state) => { return { ...state.clients } }}
                            valueFunc={(record) => record.clientId}
                            labelFunc={(record) => record.name + " (" + record.code + ")"}
                        />
                    </Col>
                    <Col {...label}>
                        <Label for="clientProjectManager">Client Project Manager</Label>
                    </Col>
                    <Col {...field}>
                        <Input className={readOnlyClass} value={values.clientProjectManager} />
                    </Col>
                </Row >
            </Container >
            <h6>
                <Icon clock />
                Fee and Program
                </h6>
            <hr />
            <Container>
                <Row>
                    <Col {...label}>
                        <Label for="likelihood">Likelihood</Label>
                    </Col>
                    <Col {...field}>
                        <Input className={readOnlyClass} value={(values.likelihood && (values.likelihood + "%") || "")} />
                    </Col>
                    <Col {...label}>
                        <Label for="estimatedFee">Estimated Fee</Label>
                    </Col>
                    <Col {...field}>
                        <Input className={readOnlyClass} value={values.estimatedFee && (Currency(values.estimatedFee))} />
                    </Col>
                    <Col {...label}>
                        <Label for="targetProposalCost">Target Proposal Cost</Label>
                    </Col>
                    <Col {...field}>
                        <Input className={readOnlyClass} value={values.targetProposalCost && (Currency(values.targetProposalCost))} />
                    </Col>
                    <Col {...label}>
                        <Label for="submittedFee">Submitted Fee - Total</Label>
                    </Col>
                    <Col {...field}>
                        <Input className={readOnlyClass} value={values.submittedFee && (Currency(values.submittedFee))} />
                    </Col>
                    <Col {...label}>
                        <Label for="submittedSubcontractorFee">Submitted Fee - CMP Labour Component</Label>
                    </Col>
                    <Col {...field}>
                        <Input className={readOnlyClass} value={values.submittedSubcontractorFee && (Currency(values.submittedSubcontractorFee))} />
                    </Col>
                    <Col {...label}>
                        <Label for="awardDate">Award Date</Label>
                    </Col>
                    <Col {...field}>
                        <Input className={readOnlyClass} value={values.awardDate && Moment(values.awardDate).format("DD/MM/YYYY")} />
                    </Col>
                    <Col {...label}>
                        <Label for="endDate">End Date (anticipated)</Label>
                    </Col>
                    <Col {...field}>
                        <Input className={readOnlyClass} value={values.endDate && Moment(values.endDate).format("DD/MM/YYYY")} />
                    </Col>
                    <Col {...label}>
                        <Label for="comments">Comments</Label>
                    </Col>
                    <Col {...field}>
                        <Input rows={27} type="textarea" className={readOnlyClass} value={values.comments} />
                    </Col>
                </Row >
            </Container >
        </div >
    )
}

const projectLink = (projectId) => {
    return (
        <div>
            <h6>
                <Icon project />
                <Link to={"/projectSheet/" + projectId}>Associated Project</Link>
            </h6>
            <hr />
        </div>
    )
}

export default ProposalDisplayExtended