import React from "react"
import PropTypes from "prop-types"
import Moment from "moment"
import Currency from "components/common/Currency"
import { ChargeRateShape } from "shapes/ChargeRateShape"
import { AuthorizedSummaryToolbar } from "components/authentication/controls/AuthorizedControls"

const Toolbar = AuthorizedSummaryToolbar(p => p.canWriteChargeRates)

const ChargeRateRow = (
    {
        classification,
        effectiveDate,
        rate,
        disabled
    }) => (
        <tr>
            <td>{classification.name}</td>
            <td>{Moment(effectiveDate).format("DD/MM/YYYY")}</td>
            <td>{Currency(rate)}</td>
            <td><Toolbar disabled={disabled} /></td>
        </tr>
    )



ChargeRateRow.propTypes = PropTypes.shape(ChargeRateShape).isRequired

export default ChargeRateRow 