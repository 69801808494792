import ScopeConstants from "constants/ScopeConstants"
import { FormActionReducer } from "@gman/gman-redux"

export const EditDataReducer = (
  state = {
    ...FormActionReducer.defaultRecordState(),
    ...FormActionReducer.defaultTabs()
  },
  action) => {

  if (action.scope === ScopeConstants.EDIT_DATA) {
    if (action.type === "CHANGE_CLIENT") {
      return {
        ...state,
        filter: { 
          ...state.filter,
          client: action.payload.client
        }
      }
    }
    if (action.type === "CHANGE_AUTO_COMPLETE") {
      return {
        ...state,
        filter: { 
          ...state.filter,
          autoCompelete: action.payload.autoCompelete
        }
      }
    }
    if (action.type === "CHANGE_ALL_INVOICED") {
      return {
        ...state,
        filter: { 
          ...state.filter,
          unInvoiced: action.payload.unInvoiced
        }
      }
    }
    return FormActionReducer.reduce(action, state)
  }

  return state
}