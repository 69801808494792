import React, { Component } from "react"
import { EditDataContainer } from "components/editData/redux/EditDataContainers"
import {
    Button
} from "reactstrap"

class ClientInvoiceCostItemForm extends Component {
    render() {
        return (
            <div>
                <EditDataContainer
                    projectId={this.props.projectId}
                    invoiceDate={this.props.dateCreated}
                    expectedClientInvoiceId= {this.props.expectedClientInvoiceId}
                    showFilter={this.props.showFilter}
                    isOpneInModal={this.props.isOpneInModal}
                />
                <Button outline onClick={this.props.onSubmitUpdate} className="float-right" color="success" type="button" >Update</Button>
            </div>
        )
    }
}

export default ClientInvoiceCostItemForm