import React from "react"
import { Badge } from "reactstrap"



const InvoiceStatusConstantsSubcontractor = {
    UNPAID: 0,
    PAID: 1,
}

const InvoiceStatusBadge = props => {
    const { value } = props
    return (
        <Badge color={MapInvoiceStatusColour(value)}>
            {MapInvoiceStatusLabel(value)}
        </Badge>
    )
}

const MapInvoiceStatusLabel = value => {
    console.log('value',value )
    switch (value) {
        case InvoiceStatusConstantsSubcontractor.UNPAID:
            return "UNPAID"
        case InvoiceStatusConstantsSubcontractor.PAID:
            return "PAID"
        default:
            return "UNKNOWN"
    }
}

const MapInvoiceStatusColour = value => {
    switch (value) {
        case InvoiceStatusConstantsSubcontractor.UNPAID:
            return "warning"
        case InvoiceStatusConstantsSubcontractor.PAID:
            return "success"
        default:
            return "default"
    }
}

export default InvoiceStatusBadge