import React, { Component } from "react"
import { Redirect } from "react-router"
import TableHeaderRow from "components/common/TableHeaderRow"
import { Button, ButtonGroup, FormGroup, Table } from "reactstrap"
import ClientInvoiceRow from "components/clientInvoices/layout/ClientInvoiceRow"
import ClientInvoiceFormExtended from "components/clientInvoices/form/ClientInvoiceFormExtended"
import ClientInvoiceConfirmPaidForm from "components/clientInvoices/form/ClientInvoiceConfirmPaidForm"
import FeedbackAlert from "components/common/FeedbackAlert"
import { ModalActions } from "@gman/gman-redux"
import { AuthorizedAddButton } from "components/authentication/controls/AuthorizedControls"
import FileUploader from "components/common/FileUploader"
import {
    ConfirmDelete,
    FormModal,
    Paginator,
    SpinnerOverlay
} from "@gman/gman-react-bootstrap"
import ClientInvoiceForm from "components/clientInvoices/form/ClientInvoiceForm"
import ClientInoviceEditAmountForm from "components/clientInvoices/form/ClientInoviceEditAmountForm"
import ClientInvoicePublishForm from "components/clientInvoices/form/ClientInvoicePublishForm"
import ClientInvoiceNumberUpdateForm from "components/clientInvoices/form/ClientInvoiceNumberUpdateForm"
import ClientInvoiceSelectProjectForm from "components/clientInvoices/form/ClientInvoiceSelectProjectForm"
import ClientInvoiceCostItemForm from "components/clientInvoices/form/ClientInvoiceCostItemForm"

const AddButton = AuthorizedAddButton(p => p.canPublishClientInvoice)

class ClientInvoiceList extends Component {

    componentDidMount() {
        this.props.recordStateEvents.onClearSearch({ projectId: this.props.projectId })
        this.props.onLoadDependentData()
    }

    componentWillUnmount = () => {
        this.props.redirectEvents.onClearRedirect()
    }

    render() {
        var self = this;
        var sourceData = this.props.sourceData
        var lastInvoicePayments = this.props.lastInvoicePayments
        var confirmEvents = this.props.confirmEvents
        var feedbackEvents = this.props.feedbackEvents
        var modalEvents = this.props.modalEvents
        var paginatorEvents = this.props.paginatorEvents
        var tableHeaderRowEvents = this.props.tableHeaderRowEvents
        var recordStateEvents = this.props.recordStateEvents
        var uploadEvents = this.props.uploadEvents
        var onFetchClientInvoice = this.props.fetchClientInvoice;
        var projectViewTabEvents = this.props.projectViewTabEvents;
        var onConfirmReturnToDraft = this.props.onConfirmReturnToDraft;

        if (sourceData.redirect) {
            return (<Redirect to={sourceData.redirect} />)
        }

        return (
            <div>
                {
                    !ModalActions.IsVisible(sourceData.modals, "UPLOAD_INVOICE") && !ModalActions.IsVisible(sourceData.modals, "UPDATE_CLIENT_INVOICE_NUMBER") ?
                        <FeedbackAlert {...feedbackEvents} feedback={sourceData.feedback} />
                        : ""
                }
                <SpinnerOverlay isLoading={sourceData.inProgress}>
                    <Table hover size="sm">
                        <thead>
                            <TableHeaderRow
                                {...tableHeaderRowEvents}
                                className="text-left pl-0"
                                disabled={sourceData.inProgress}
                                headers={[
                                    { sortBy: "Number", label: "Invoice Number" },
                                    { sortBy: "AmountBasedOnCosts", label: "Amount based on costs (excl. GST)" },
                                    { sortBy: "InvoiceAmount", label: "Amount (excl. GST)" },
                                    { sortBy: "DateCreated", label: "Date Created" },
                                    { sortBy: "DateIssued", label: "Date Issued" },
                                    { sortBy: "DatePaid", label: "Date Paid" },
                                    
                                    { sortBy: "Status", label: "Status" }
                                ]}
                            />
                        </thead>
                        <tbody>
                            {
                                sourceData.records.map((record, index) => (
                                    <ClientInvoiceRow
                                        key={index}
                                        {...record}
                                        onShowDelete={() => modalEvents.onShowDelete(record)}
                                        onShowMarkPaid={() => {
                                            recordStateEvents.onLoadSingle(record.clientInvoiceId)
                                            modalEvents.onShowModal("MARK_AS_PAID")
                                        }}
                                        onEditInoviceAmount={() => {
                                            localStorage.removeItem('expectedClientInvoiceId');
                                            onFetchClientInvoice(record.clientInvoiceId, "EDIT_CLIENT_INVOICE");
                                        }}
                                        onInovicePublish={() => {
                                            onFetchClientInvoice(record.clientInvoiceId, "PUBLISH_CLIENT_INVOICE");
                                        }}
                                        onInoviceReturnToDraft={() => {
                                            onFetchClientInvoice(record.clientInvoiceId, "RETURN_TO_DRAFT_CLIENT_INVOICE");
                                        }}
                                        onDownLoadClientInvoiceReport={() => {
                                            self.props.onDownLoadClientInvoiceReport(record.clientInvoiceId);
                                        }}
                                        disabled={sourceData.inProgress}
                                        onInvoiceNumberClick={() => {
                                            onFetchClientInvoice(record.clientInvoiceId, "UPDATE_CLIENT_INVOICE_NUMBER");
                                        }} />
                                ))
                            }
                        </tbody>
                    </Table>
                    <Paginator {...paginatorEvents} searchOptions={sourceData.searchOptions} />
                    <ButtonGroup className="float-right">
                        <AddButton
                            disabled={sourceData.inProgress}
                            caption="Add"
                            onClick={() => {
                                if (self.props.projectId) {
                                    self.props.addClientInvoice({ projectId: self.props.projectId })
                                } else {
                                    modalEvents.onShowModal("PROJECT_CLIENT_INVOICE")
                                }
                            }} />
                    </ButtonGroup>
                </SpinnerOverlay>
                <FormModal
                    isVisible={ModalActions.IsDeleteVisible(sourceData.modals)}
                    onHide={modalEvents.onHideDelete}
                    title={"Confirm Delete"}>
                    <ConfirmDelete onConfirmDelete={() => confirmEvents.onConfirmDelete(sourceData.recordToUpdate)} />
                </FormModal>
                <FormModal
                    size="lg"
                    isVisible={ModalActions.IsUpdateVisible(sourceData.modals)}
                    onHide={modalEvents.onHideEdit}
                    title={"Update Invoice"}>
                    <ClientInvoiceFormExtended onSubmitUpdate={confirmEvents.onSubmitUpdate}
                        {...sourceData.recordToUpdate}
                        {...lastInvoicePayments}
                    />
                </FormModal>
                <FormModal
                    isVisible={ModalActions.IsVisible(sourceData.modals, "UPLOAD_INVOICE")}
                    onHide={() => modalEvents.onHideModal("UPLOAD_INVOICE")}
                    title={"Upload Invoice"}>
                    <FileUploader
                        feedback={sourceData.feedback}
                        feedbackEvents={feedbackEvents}
                        uploadedFiles={sourceData.fileData}
                        isUploading={sourceData.isUploading}
                        uploadFeedback={sourceData.uploadFeedback}
                        multiple={true}
                        accept=".xls, .xlsx"
                        maxSize={6291456}
                        {...uploadEvents}
                        onUploadSubmit={() => this.props.onUploadSubmit(sourceData.fileData)}
                    >
                        <div>
                            Click here to upload a file or drag a file into this zone.
                            <br />
                            Files must be .XLS or .XLSX
                        </div>
                    </FileUploader>
                </FormModal>
                <FormModal
                    isVisible={ModalActions.IsVisible(sourceData.modals, "MARK_AS_PAID")}
                    onHide={() => modalEvents.onHideModal("MARK_AS_PAID")}
                    title={"Confirm Mark as Paid"}>
                    <ClientInvoiceConfirmPaidForm
                        {...sourceData.recordToUpdate}
                        {...lastInvoicePayments}
                        onConfirmPaid={this.props.onConfirmPaid}
                    />
                </FormModal>
                <FormModal
                    isVisible={ModalActions.IsVisible(sourceData.modals, "EDIT_CLIENT_INVOICE")}
                    onHide={() => modalEvents.onHideModal("EDIT_CLIENT_INVOICE")}
                    title={"Edit Client Invoice"}>
                    <ClientInvoiceForm
                        onInoviceAmountEdit={() => {
                            modalEvents.onHideModal("EDIT_CLIENT_INVOICE");
                            modalEvents.onShowModal("EDIT_CLIENT_INVOICE_AMOUNT");
                        }}
                        onInvoiceItemsEdit={() => {
                            if (sourceData.recordToUpdate) {
                                modalEvents.onHideModal("EDIT_CLIENT_INVOICE");
                                modalEvents.onShowModal("PROJECT_CLIENT_INVOICE_DETAILS")
                            }
                        }}
                    />
                </FormModal>
                <FormModal
                    isVisible={ModalActions.IsVisible(sourceData.modals, "EDIT_CLIENT_INVOICE_AMOUNT")}
                    onHide={() => modalEvents.onHideModal("EDIT_CLIENT_INVOICE_AMOUNT")}
                    title={"Edit Client Invoice Amount"}>
                    <ClientInoviceEditAmountForm
                        {...sourceData.recordToUpdate}
                        onSubmitUpdate={(recrod) => {
                            modalEvents.onHideModal("EDIT_CLIENT_INVOICE_AMOUNT");
                            confirmEvents.onSubmitUpdate(recrod);
                        }}
                    />
                </FormModal>
                <FormModal
                    isVisible={ModalActions.IsVisible(sourceData.modals, "PUBLISH_CLIENT_INVOICE")}
                    onHide={() => modalEvents.onHideModal("PUBLISH_CLIENT_INVOICE")}
                    title={"Edit Client Invoice Amount"}>
                    <ClientInvoicePublishForm
                        {...sourceData.recordToUpdate}
                        onSubmitUpdate={(recrod) => {
                            modalEvents.onHideModal("PUBLISH_CLIENT_INVOICE");
                            self.props.onConfirmPublish(recrod);
                        }}
                    />
                </FormModal>
                <FormModal
                    isVisible={ModalActions.IsVisible(sourceData.modals, "UPDATE_CLIENT_INVOICE_NUMBER")}
                    onHide={() => modalEvents.onHideModal("UPDATE_CLIENT_INVOICE_NUMBER")}
                    title={"Update Client Invoice Number"}>
                    <ClientInvoiceNumberUpdateForm
                        {...sourceData.recordToUpdate}
                        feedback={sourceData.feedback}
                        feedbackEvents={feedbackEvents}
                        inProgress={sourceData.inProgress}
                        onSubmitUpdate={(recrod) => {
                            self.props.updateClientInvoiceNumber(recrod);
                        }}
                    />
                </FormModal>
                <FormModal
                    isVisible={ModalActions.IsVisible(sourceData.modals, "PROJECT_CLIENT_INVOICE")}
                    onHide={() => modalEvents.onHideModal("PROJECT_CLIENT_INVOICE")}
                    title={"Select Project for Client Invoice"}>
                    <ClientInvoiceSelectProjectForm
                        {...sourceData.recordToUpdate}
                        feedback={sourceData.feedback}
                        feedbackEvents={feedbackEvents}
                        inProgress={sourceData.inProgress}
                        onSubmitUpdate={(projectId) => {
                            self.props.addClientInvoice({ projectId: projectId });
                            modalEvents.onHideModal("PROJECT_CLIENT_INVOICE")
                        }}
                    />
                </FormModal>
                <FormModal
                    isVisible={ModalActions.IsVisible(sourceData.modals, "PROJECT_CLIENT_INVOICE_DETAILS")}
                    onHide={() => {
                        modalEvents.onHideModal("PROJECT_CLIENT_INVOICE_DETAILS")
                        this.props.recordStateEvents.onClearSearch({ projectId: this.props.projectId });
                    }}
                    title={"Project Data"}
                    className="modal-xxl">
                    <ClientInvoiceCostItemForm
                        projectId={sourceData.recordToUpdate.projectId}
                        invoiceDate={sourceData.recordToUpdate.dateCreated}
                        expectedClientInvoiceId={sourceData.recordToUpdate.clientInvoiceId}
                        showFilter={false}
                        isOpneInModal={true}
                        onSubmitUpdate={() => {
                            modalEvents.onHideModal("PROJECT_CLIENT_INVOICE_DETAILS");
                            this.props.recordStateEvents.onClearSearch({ projectId: this.props.projectId });
                        }}
                    />
                </FormModal>
                <FormModal
                    isVisible={ModalActions.IsVisible(sourceData.modals, "RETURN_TO_DRAFT_CLIENT_INVOICE")}
                    onHide={() => modalEvents.onHideModal("RETURN_TO_DRAFT_CLIENT_INVOICE")}
                    title={"Confirm Return to draft"}>
                     <FormGroup>
                            Return to draft this record?
                            <Button className="float-right" color="warning" onClick={() => {
                                onConfirmReturnToDraft(sourceData.recordToUpdate);
                                modalEvents.onHideModal("RETURN_TO_DRAFT_CLIENT_INVOICE")
                            }}>
                                Return to draft
                            </Button>
                    </FormGroup>
                </FormModal>
            </div>
        )
    }
}

export default ClientInvoiceList