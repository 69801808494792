import ScopeConstants from "constants/ScopeConstants"
import { FormActionReducer } from "@gman/gman-redux"

export const ClientInvoicesReducer = (
    state = {
        ...FormActionReducer.defaultPageState(),
        ...FormActionReducer.defaultSearchOptions("Number"),
        ...FormActionReducer.defaultRecordState(),
        ...FormActionReducer.defaultUploadState(),
        isClientInvoiceStatusChanged: false
    },
    action) => {

    if (action.scope === ScopeConstants.CLIENT_INVOICE) {
        if (action.type === "APPLY_UPLOAD_FEEDBACK") {
            return {
                ...state,
                uploadFeedback: action.record
            }
        }

        if (action.type === "CLEAR_UPLOAD_FEEDBACK") {
            return {
                ...state,
                uploadFeedback: []
            }
        }
        if (action.type == "CLIENT_INVOICE_COST_ITEM_DATE") {
            return {
                ...state,
                invoiceDate: action.invoiceDate
            }
        }
        if (action.type == "CLIENT_INVOICE_STATUS_CHANGED") {
            return {
                ...state,
                isClientInvoiceStatusChanged: action.isClientInvoiceStatusChanged
            }
        }

        return FormActionReducer.reduce(action, state)
    }

    return state
}