import ScopeConstants from "constants/ScopeConstants"
import OpportunityStatusConstants from "constants/OpportunityStatusConstants"

export const OpportunityMineScope = {
    key: ScopeConstants.OPPORTUNTIY_MINE,
    selector: (state) => state.opportunitiesMine,
    tokenSelector: (state) => state.authentication.token,
    fetchEndpoint: "/api/Opportunity/SearchMine/",
    singleEndpoint: "/api/Opportunity/GetAny/",
    updateEndpoint: "/api/Opportunity/UpdateAny/",
    deleteEndpoint: "/api/Opportunity/DeleteAny/",
    publishEndpoint: "/api/Opportunity/PublishAny/",
    defaultRecord: {
        name: "",
        code: "",
        status: OpportunityStatusConstants.DRAFT
    }
}