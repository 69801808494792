import { connect } from "react-redux"
import DashboardElementConstants from "constants/DashboardElementConstants"
import ExpenseStatusConstants from "constants/ExpenseStatusConstants"
import TimesheetStatusConstants from "constants/TimesheetStatusConstants"
import { DashboardScope } from "components/dashboards/redux/DashboardScope"
import { StaticProjectScope } from "components/static/redux/StaticProjectScope"
import { TimesheetHoursChart } from "components/dashboards/layout/TimesheetHoursChart"
import { ExpenseQuickNumbers } from "components/dashboards/layout/ExpenseQuickNumbers"
import { TimesheetQuickNumbers } from "components/dashboards/layout/TimesheetQuickNumbers"
import { ProjectSummary } from "components/dashboards/layout/ProjectSummary"
import { DispatchActions, FormActionConstants, FormActions, FormActionReducer } from "@gman/gman-redux"

const mapDispatchToPropsSampleSummary = (dispatch) => { return mapDispatchToProps(dispatch, DashboardElementConstants.TIMESHEET_HOURS, "/api/Dashboard/TimesheetHistorySnapshot") }
const mapDispatchToPropsTimesheetQuickNumbersMine = (dispatch) => { return mapDispatchToProps(dispatch, DashboardElementConstants.TIMESHEET_QUICK_NUMBERS_MINE, "/api/Dashboard/TimesheetQuickNumbersMine") }
const mapDispatchToPropsTimesheetQuickNumbersTeam = (dispatch) => { return mapDispatchToProps(dispatch, DashboardElementConstants.TIMESHEET_QUICK_NUMBERS_TEAM, "/api/Dashboard/TimesheetQuickNumbersTeam") }
const mapDispatchToPropsTimesheetQuickNumbersAll = (dispatch) => { return mapDispatchToProps(dispatch, DashboardElementConstants.TIMESHEET_QUICK_NUMBERS_ALL, "/api/Dashboard/TimesheetQuickNumbersAll") }
const mapDispatchToPropsExpenseQuickNumbersMine = (dispatch) => { return mapDispatchToProps(dispatch, DashboardElementConstants.EXPENSE_QUICK_NUMBERS_MINE, "/api/Dashboard/ExpenseQuickNumbersMine") }
const mapDispatchToPropsExpenseQuickNumbersTeam = (dispatch) => { return mapDispatchToProps(dispatch, DashboardElementConstants.EXPENSE_QUICK_NUMBERS_TEAM, "/api/Dashboard/ExpenseQuickNumbersTeam") }
const mapDispatchToPropsExpenseQuickNumbersAll = (dispatch) => { return mapDispatchToProps(dispatch, DashboardElementConstants.EXPENSE_QUICK_NUMBERS_ALL, "/api/Dashboard/ExpenseQuickNumbersAll") }
const mapDispatchToPropsProjectSummaryAsProjectManager = (dispatch) => { return mapDispatchToProps(dispatch, DashboardElementConstants.PROJECT_SUMMARY_AS_PROJECT_MANAGER, "/api/Project/GetSumaary") }
const mapDispatchToPropsProjectSummaryAsProjectDirector = (dispatch) => { return mapDispatchToProps(dispatch, DashboardElementConstants.PROJECT_SUMMARY_AS_PROJECT_DIRECTOR, "") }

const mapDispatchToProps = (dispatch, element, endpoint) => {
    return {
        onLoadData: () => {
            //dispatch(FormActions.fetchAll(StaticProjectScope))
            dispatch({ ...DispatchActions.scopedDispatch(DashboardScope.key, FormActionConstants.IN_PROGRESS_START), element: element })
            dispatch(FormActions.doPost(DashboardScope, {}, endpoint, "", false))
                .then((result) => {
                    if (result && result.error) { }
                    else {
                        dispatch({ ...DispatchActions.scopedDispatch(DashboardScope.key, FormActionConstants.RECEIVE_RECORDS), records: result.record, element: element })
                        dispatch({ ...DispatchActions.scopedDispatch(DashboardScope.key, FormActionConstants.IN_PROGRESS_STOP), element: element })
                    }
                })
        },
        paginatorEvents: {
            onPageChange: function onPageChange(page) {
                console.log(page);
            }
        },
        loadProjectSummary: () => {
            dispatch(function (dispatch, getState) {
                debugger;
                dispatch({ ...DispatchActions.scopedDispatch(DashboardScope.key, FormActionConstants.IN_PROGRESS_START), element: element });
                dispatch(FormActions.doPost(DashboardScope, {}, endpoint, "", false))
                    .then((result) => {
                        if (result && result.error) { }
                        else {
                            dispatch({ ...DispatchActions.scopedDispatch(DashboardScope.key, FormActionConstants.RECEIVE_RECORDS), records: result.record, element: element })
                            dispatch({ ...DispatchActions.scopedDispatch(DashboardScope.key, FormActionConstants.IN_PROGRESS_STOP), element: element })
                        }
                    })
            })
        }
    }
}

const TimesheetHoursChartContainer = connect(
    state => { return { sourceData: state.dashboards[DashboardElementConstants.TIMESHEET_HOURS] } },
    mapDispatchToPropsSampleSummary
)(TimesheetHoursChart)

const TimesheetQuickNumbersMineContainer = connect(
    state => {
        return {
            sourceData: state.dashboards[DashboardElementConstants.TIMESHEET_QUICK_NUMBERS_MINE],
            quickLinks: getTimesheetLinks("mine"),
            timesheetEntryLink: timesheetEntryLink("mine")
        }
    },
    mapDispatchToPropsTimesheetQuickNumbersMine
)(TimesheetQuickNumbers)

const TimesheetQuickNumbersTeamContainer = connect(
    state => {
        return {
            sourceData: state.dashboards[DashboardElementConstants.TIMESHEET_QUICK_NUMBERS_TEAM],
            quickLinks: getTimesheetLinks("team"),
            timesheetEntryLink: timesheetEntryLink("team")
        }
    },
    mapDispatchToPropsTimesheetQuickNumbersTeam
)(TimesheetQuickNumbers)

const TimesheetQuickNumbersAllContainer = connect(
    state => {
        return {
            sourceData: state.dashboards[DashboardElementConstants.TIMESHEET_QUICK_NUMBERS_ALL],
            quickLinks: getTimesheetLinks("any"),
            timesheetEntryLink: timesheetEntryLink("any")
        }
    },
    mapDispatchToPropsTimesheetQuickNumbersAll
)(TimesheetQuickNumbers)

const ExpenseQuickNumbersMineContainer = connect(
    state => {
        return {
            sourceData: state.dashboards[DashboardElementConstants.EXPENSE_QUICK_NUMBERS_MINE],
            quickLinks: getExpenseLinks("mine")
        }
    },
    mapDispatchToPropsExpenseQuickNumbersMine
)(ExpenseQuickNumbers)

const ExpenseQuickNumbersTeamContainer = connect(
    state => {
        return {
            sourceData: state.dashboards[DashboardElementConstants.EXPENSE_QUICK_NUMBERS_TEAM],
            quickLinks: getExpenseLinks("team")
        }
    },
    mapDispatchToPropsExpenseQuickNumbersTeam
)(ExpenseQuickNumbers)

const ExpenseQuickNumbersAnyContainer = connect(
    state => {
        return {
            sourceData: state.dashboards[DashboardElementConstants.EXPENSE_QUICK_NUMBERS_ALL],
            quickLinks: getExpenseLinks("any")
        }
    },
    mapDispatchToPropsExpenseQuickNumbersAll
)(ExpenseQuickNumbers)

const PropsProjectSummaryAsProjectManagerContainer = connect(
    state => {
        return {
            sourceData: {
                ...FormActionReducer.defaultRecordState(),
                ...FormActionReducer.defaultSearchOptions("ProjectName", 10, true),
                ...state.dashboards[DashboardElementConstants.PROJECT_SUMMARY_AS_PROJECT_MANAGER],
            },

        }
    },
    mapDispatchToPropsProjectSummaryAsProjectManager
)(ProjectSummary)

function getTimesheetLinks(suffix) {
    return {
        outstanding: "/outstandingTimesheet/" + suffix,
        current: "/timesheet/" + suffix + "/" + TimesheetStatusConstants.DRAFT,
        submitted: "/timesheet/" + suffix + "/" + TimesheetStatusConstants.INPROGRESS
    }
}

function timesheetEntryLink(suffix) {
    return "/timesheetEntry/" + suffix + "/";
}

function getProjectSummaryLink() {
    return "";
}

function getExpenseLinks(suffix) {
    return {
        unsubmitted: "/expense/" + suffix + "/draft" ,
        submitted: "/expense/" + suffix + "/submitted",
        approved: "/expense/" + suffix + "/approved",
        declined: "/expense/" + suffix + "/declined"
    }
}

export {
    TimesheetHoursChartContainer,
    TimesheetQuickNumbersMineContainer,
    TimesheetQuickNumbersTeamContainer,
    TimesheetQuickNumbersAllContainer,
    ExpenseQuickNumbersMineContainer,
    ExpenseQuickNumbersAnyContainer,
    ExpenseQuickNumbersTeamContainer,
    PropsProjectSummaryAsProjectManagerContainer
}