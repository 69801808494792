import ScopeConstants from "constants/ScopeConstants"
import { FormActionReducer } from "@gman/gman-redux"

export const UserAuthenticationsReducer = (
    state = {
        ...FormActionReducer.defaultPageState(),
        ...FormActionReducer.defaultSearchOptions("UserId"),
        ...FormActionReducer.defaultRecordState()
    },
    action) => {

    if (action.scope === ScopeConstants.USER_AUTHENTICATION || action.scope === ScopeConstants.RESET_PASSWORD) {
        return FormActionReducer.reduce(action, state)
    }

    return state
}