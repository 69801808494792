import React from "react"
import Moment from "moment"
import { withFormik } from "formik"
import * as Yup from "yup"
import RequiredStar from "components/common/RequiredStar"
import { AuthorizedButton, AuthorizedInputField } from "components/authentication/controls/AuthorizedControls"

import {
    Form,
    FormGroup,
    Label
} from "reactstrap"

const SubmitButton = AuthorizedButton(p => p.canWriteSystem)
const InputField = AuthorizedInputField(p => p.canWriteSystem)

const BaseForm = props => {
    const {
        values,
        dirty,
        errors,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting
    } = props
    return (
        <div>
            <Form noValidate onSubmit={handleSubmit}>
                <FormGroup>
                    <RequiredStar />
                    <Label for="gstRate">Current GST</Label>
                    <InputField
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.gstRate || ""}
                        type="decimal"
                        name="gstRate"
                        id="gstRate"
                        placeholder="Enter current GST"
                        invalid={errors.gstRate} />
                    {errors.gstRate && <small className="text-danger">{errors.gstRate}</small>}
                </FormGroup>
                <FormGroup>
                    <RequiredStar />
                    <Label for="effectiveDate">Effective Date</Label>
                    <InputField
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={Moment(values.effectiveDate).format("YYYY-MM-DD") || ""}
                        type="date"
                        name="effectiveDate"
                        id="effectiveDate"
                        placeholder="Effective Date"
                        invalid={errors.effectiveDate} />
                    {errors.effectiveDate && <small className="text-danger">{errors.effectiveDate}</small>}
                </FormGroup>
                <FormGroup>
                    <RequiredStar />
                    <Label for="mileageRate">Current Mileage</Label>
                    <InputField
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.mileageRate || ""}
                        type="decimal"
                        name="mileageRate"
                        id="mileageRate"
                        placeholder="Enter current mileage rate"
                        invalid={errors.mileageRate} />
                    {errors.mileageRate && <small className="text-danger">{errors.mileageRate}</small>}
                </FormGroup>
                <FormGroup>
                    <RequiredStar />
                    <Label for="dailyMileageClaimLimit">Daily Mileage Claim Limit</Label>
                    <InputField
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.dailyMileageClaimLimit || ""}
                        type="decimal"
                        name="dailyMileageClaimLimit"
                        id="dailyMileageClaimLimit"
                        placeholder="Enter current daily mileage claim limit"
                        invalid={errors.dailyMileageClaimLimit} />
                    {errors.dailyMileageClaimLimit && <small className="text-danger">{errors.dailyMileageClaimLimit}</small>}
                </FormGroup>
                <SubmitButton outline className="float-right" disabled={isSubmitting || !dirty} color="success" type="submit">Submit</SubmitButton>
            </Form>
        </div>
    )
}

const validationSchema = Yup.object({
    effectiveDate: Yup.string()
        .required("Effective Date is required"),
    mileageRate: numberValidation("Mileage Rate"),
    gstRate: numberValidation("GST Rate"),
    dailyMileageClaimLimit: numberValidation("Daily Mileage Claim Limit"),
})

function numberValidation() {
    return Yup.number()
        .default(0)
        .nullable()
        .required("Value is required")
        .positive("Must be positive")
        .typeError("Must be a number")
        .min(0, "Must be more than zero")
        .moreThan(0, "Must be more than zero")
}

const SystemFormBasic = withFormik({
    displayName: "SystemFormBasic",
    enableReinitialize: true,
    validationSchema: validationSchema,
    initialValues: ({ value }) => ({
        gstRate: value.gstRate,
        effectiveDate: value.effectiveDate,
        mileageRate: value.mileageRate
    }),
    handleSubmit: (values, { props, setSubmitting }) => {
        props.onSubmitUpdate(values)
        setSubmitting(false)
    }
})(BaseForm)

export default SystemFormBasic