import ScopeConstants from "constants/ScopeConstants"
import { ScopeShape } from "@gman/gman-redux"
import PropTypes from "prop-types"

export const TimesheetAnyScope = {
    key: ScopeConstants.TIMESHEET_ANY,
    selector: (state) => state.timesheetsAny,
    tokenSelector: (state) => state.authentication.token,
    fetchEndpoint: "/api/Timesheet/SearchAny/",
    singleEndpoint: "/api/Timesheet/GetAny/",
    updateEndpoint: "/api/Timesheet/UpdateAny",
    deleteEndpoint: "/api/Timesheet/DeleteAny/",
    defaultRecord: {
        status: 0
    }
}

TimesheetAnyScope.propTypes = PropTypes.shape(ScopeShape).isRequired