import ScopeConstants from "constants/ScopeConstants"
import { FormActionReducer } from "@gman/gman-redux"

export const ProposalsMineReducer = (
  state = {
    ...FormActionReducer.defaultPageState(),
    ...FormActionReducer.defaultSearchOptions("DateCreated", 10, true),
    ...FormActionReducer.defaultRecordState(),
    ...FormActionReducer.defaultTabs()
  },
  action) => {

  if (action.scope === ScopeConstants.PROPOSAL_MINE) {
    if (action.type === "SHOW_ALL") {
      return {
        ...state,
        searchOptions: {
          ...state.searchOptions,
          pageSize: state.searchOptions.totalRecords
        }
      }
    }
    if (action.type === "CHANGE_PAGE_SIZE") {
      return {
        ...state,
        searchOptions: {
          ...state.searchOptions,
          pageSize: action.pageSize
        }
      }
    }
    return FormActionReducer.reduce(action, state)
  }

  return state
}