import React, { Component } from "react"
import { Link } from "react-router-dom"
import { Progress } from "reactstrap"
import { SpinnerOverlay, Paginator } from "@gman/gman-react-bootstrap"

export class ProjectSummary extends Component {
    componentDidMount() {
        if (this.props.loadProjectSummary) {
            this.props.loadProjectSummary()
        }
    }

    render() {

        var sourceData = this.props.sourceData;
        var paginatorEvents = this.props.paginatorEvents

        sourceData.records.map((record, index) => {
            record.projectNumber = record.projectNumber.trim()
        })
        return (
            <div>
                <SpinnerOverlay isLoading={sourceData && sourceData.progress}>
                <table className="table">
                    <tr>
                        {/* <th colspan="4"></th>*/}
                        <th></th>
                        <th><Progress bar value="100" className="p-1">Budget</Progress><Progress bar value="100" className="p-1" color="warning">Cost</Progress></th> {/* changed by avantika task-3.1*/}
                        <th colspan="2"></th>
                        <th colspan="2">Last Week</th>
                        <th colspan="2">Last Month</th>
                    </tr>
                    <tr>
                        <th>Project</th>
                        <th>Financial Snapshot</th>
                        <th>Next Invoice</th>
                        <th>Missing Payment</th>
                        <th>Staff</th>
                        {/*<th>Hours</th>*/} {/* changed by avantika task-3.1*/}
                        <th>Hrs</th>
                        <th>Staff</th>
                        {/*<th>Hours</th>*/} {/* changed by avantika task-3.1*/}
                        <th>Hrs</th>
                    </tr>
                        {
                            sourceData && sourceData.records && sourceData.records.map((record, index) =>
                            <tr>
                                <td>
                                    <Link to={"/projectSheet/" + record.projectId}>
                                            {record.projectNumber} - {record.projectName}
                                    </Link>
                                </td>
                                <td>
                                    <div className="d-flex align-items-center">
                                        {/*<span>Budget</span>*/} {/* changed by avantika task-3.1*/}
                                        {/*<span className="ml-2">*/}
                                        <span>
                                            <Progress bar value="100" className="p-1">
                                                {
                                                        record.budget > 999 ? <span>${Math.round(record.budget / 1000)}k</span> : <span>${Math.round(record.budget / 1000)}k</span>
                                                
                                                }
                                            </Progress>
                                        </span>
                                    </div>
                                    <div className="d-flex align-items-center mt-1">
                                        {/*<span>Cost</span>*/} {/* changed by avantika task-3.1*/}
                                        {/*<span className="ml-4">*/}
                                        <span>
                                            <Progress bar value="100" className="p-1" color="warning">
                                                {
                                                     record.costs > 999 ? <span>${Math.round(record.costs / 1000)}k</span> : <span>${Math.round(record.costs / 1000)}k</span>
                                                }
                                            </Progress>
                                        </span>
                                    </div>
                                </td>
                                <td>
                                    {
                                        record.nextInvoice == "Overdue" ? <span style={{ color: 'red', fontWeight: '600' }}>Overdue</span> : <span>{ record.nextInvoice }</span>
                                    }
                                </td>
                                <td>
                                    {
                                            record.missingPayment == "Yes" ? <span style={{ color: 'red', fontWeight: '600' }}>Yes</span> : <span style={{ color: 'rgb(15, 81, 50)', fontWeight: '600' }}>{record.missingPayment}</span>
                                    }
                                </td>
                                <td>{record.lastWeekStaff}</td>
                                <td>{record.lastWeekHours}</td>
                                <td>{record.lastMonthStaff}</td>
                                <td>{record.lastMonthHours}</td>
                            </tr>)
                        }
                    </table>
                    <Paginator {...paginatorEvents} searchOptions={sourceData.searchOptions} />
                </SpinnerOverlay>
            </div>
        )
    }
}