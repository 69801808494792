import { connect } from "react-redux"
import { DispatchActions, FormActions, FormEvents } from "@gman/gman-redux"
import ResetPasswordCardBasic from "components/resetPasswords/layout/ResetPasswordCardBasic"
import { ResetPasswordScope } from "components/resetPasswords/redux/ResetPasswordScope"

const mapDispatchToProps = (dispatch) => {
    var scope = ResetPasswordScope
    return {
        ...FormEvents.getFeedbackEvents(dispatch, scope),
        ...FormEvents.getModalEventsForModalsOnNewOnly(dispatch, scope),
        onConfirmPasswordReset: userAuthenticationId => {
            dispatch(FormActions.doPost(scope, userAuthenticationId, "/api/UserAuthentication/ResetPassword/", "The password was successfully reset.", true))
                .then((result) => {
                    if (result && result.error) { }
                    else {
                        dispatch(FormActions.hideModal(scope, "CONFIRM_RESET_PASSWORD"))
                        dispatch({ ...DispatchActions.scopedDispatch(scope.key, "CONFIRM_RESET_PASSWORD"), passwordClearText: result.record.passwordClearText })
                    }
                })

        },
        onAcknowledgeReset: () => {
            dispatch({ ...DispatchActions.scopedDispatch(scope.key, "ACKNOWLEDGE_RESET_PASSWORD") })
        }
    }
}

const ResetPasswordContainer = connect(
    (state, ownProps) => {
        return {
            sourceData: ResetPasswordScope.selector(state),
            userAuthenticationId: ownProps.userAuthenticationId
        }
    }, mapDispatchToProps)(ResetPasswordCardBasic)

export { ResetPasswordContainer }
