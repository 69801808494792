import React from "react"
import Moment from "moment"
import { Link } from "react-router-dom"
import ActiveCell from "components/common/ActiveCell"
import ProposalStatusBadge from "components/common/ProposalStatusBadge"
import { AuthorizedSummaryToolbar } from "components/authentication/controls/AuthorizedControls"

const Toolbar = AuthorizedSummaryToolbar(p => p.canWriteWorkItems)

const ProposalRow = (
  {
    proposalId,
    client,
    name,
    code,
    status,
    proposalManager,
    dateCreated,
    onShowEdit,
    onShowDelete,
    disabled
  }) => (
    <tr>
      <ActiveCell active={!disabled}>
        <Link to={"/proposalSheet/" + proposalId} onClick={onShowEdit}>
          {name}
        </Link>
      </ActiveCell>
      <ActiveCell active={!disabled}>{code}</ActiveCell>
      <ActiveCell active={!disabled}>
        <Link to={"/clientDetails/" + client.clientId}>
          {client.name}
        </Link>
      </ActiveCell>
      <ActiveCell active={!disabled}>
        {proposalManager ?
          <Link to={"/userDetails/" + proposalManager.userId}>
            {proposalManager && proposalManager.firstName + " " + proposalManager.lastName}
          </Link>
          : ""}
      </ActiveCell>
      <td>{Moment(dateCreated).format("DD/MM/YYYY")}</td>
      <td>
        <ProposalStatusBadge value={status} />
      </td>
      <td>
        <Toolbar disabled={disabled} onShowDelete={onShowDelete} />
      </td>
    </tr>
  )

export default ProposalRow 