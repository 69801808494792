import React from "react"
import { withFormik } from "formik"
import * as Yup from "yup"
import RequiredStar from "components/common/RequiredStar"

import {
    Button,
    Col,
    Container,
    Form,
    FormGroup,
    Input,
    Label,
    Row
} from "reactstrap"

const BaseForm = props => {
    const {
        values,
        dirty,
        errors,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting
    } = props

    return (
        <div className="pt-2">
            <Form noValidate onSubmit={handleSubmit}>
                <Container>
                    <Row>
                        <Col xs={12}>
                            <FormGroup>
                                <RequiredStar />
                                <Label for="userName">User Name</Label>
                                <Input
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.userName || ""}
                                    type="text"
                                    name="userName"
                                    id="userName"
                                    placeholder="User Name"
                                    invalid={errors.userName} />
                                {errors.userName && <small className="text-danger">{errors.userName}</small>}
                            </FormGroup>
                        </Col>
                        <Col xs={12}>
                            <FormGroup>
                                <RequiredStar />
                                <Label for="password">Password</Label>
                                <Input
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.password || ""}
                                    type="password"
                                    name="password"
                                    id="password"
                                    placeholder="Password"
                                    invalid={errors.password} />
                                {errors.password && <small className="text-danger">{errors.password}</small>}
                            </FormGroup>
                        </Col>
                        <Col xs={12}>
                            <Button outline className="float-right" disabled={isSubmitting || !dirty} color="success" type="submit">Login</Button>
                         </Col>
                    </Row>
                </Container>
            </Form>
        </div>
    )
}

const validationSchema = Yup.object({
    userName: Yup.string()
        .required("User name is required"),
    password: Yup.string()
        .required("Password is required")
})

const LoginForm = withFormik({
    displayName: "LoginForm",
    enableReinitialize: true,
    validationSchema: validationSchema,
    initialValues: ({ value }) => ({
        userName: value.userName,
        password: value.password,
        feedback: value.feedback
    }),
    handleSubmit: (values, { props, setSubmitting }) => {
        props.onSubmitLogin(values)
        setSubmitting(false)
    }
})(BaseForm)

export default LoginForm