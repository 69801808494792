import { connect } from "react-redux"
import ChargeRateList from "components/chargeRates/layout/ChargeRateList"
import ChargeRateListExtended from "components/chargeRates/layout/ChargeRateListExtended"
import ChargeRatesDraftForm from "components/chargeRates/form/ChargeRatesDraftForm"
import { ChargeRateScope } from "components/chargeRates/redux/ChargeRateScope"
import { ChargeRateListScope } from "components/chargeRates/redux/ChargeRateListScope"
import ChargeRateTypeConstants from "constants/ChargeRateTypeConstants"
import { FormEvents, FormActions, ModalConstants, DispatchActions  } from "@gman/gman-redux"

const mapDispatchToChargeRate = (dispatch) => { return mapDispatchToProps(dispatch, ChargeRateScope) }

const mapDispatchToChargeRateList = (dispatch) => { return mapDispatchToProps(dispatch, ChargeRateListScope) }

const mapDispatchToProps = (dispatch, scope) => {
    var customSort = {
        tableHeaderRowEvents: {
            onSortBy: (column, principalId, currentRatesType, currentView) => {
                dispatch(FormActions.updateSortBy(scope, column))
                if (currentView == 'rateList') {
                    getRateList(dispatch, scope, principalId, currentRatesType)
                } else {
                    getApplicableRateChargeList(dispatch, scope, principalId, currentRatesType)
                }
                
            }
        },
        paginatorEvents: {
            onPageChange: (page, principalId, currentRatesType) => {
                dispatch(FormActions.onPageChange(scope, page))
                getApplicableRates(dispatch, scope, principalId, currentRatesType)
            }
        }
    }

    return {
        ...FormEvents.getConfirmEventsForModalsOnAll(dispatch, scope),
        ...FormEvents.getFeedbackEvents(dispatch, scope),
        ...FormEvents.getModalEventsForModalsOnAll(dispatch, scope),
        ...FormEvents.getRecordStateEvents(dispatch, scope),
        ...customSort,
        confirmEvents: {
            onConfirmDelete: (record, currentRatesType, principalId) => {
                dispatch(FormActions.hideModal(scope, ModalConstants.DELETE));
                deleteRateList(dispatch, scope, record.chargeRateListId, currentRatesType)
                    .then(
                        () => {
                            getRateList(dispatch, scope, principalId, currentRatesType);
                        }
                    )
            }
        },
        onGetRates: (principalId, currentRatesType) => {
            return getApplicableRates(dispatch, ChargeRateScope, principalId, currentRatesType)
        },
        onGetRateChargeList: (principalId, currentRatesType) => {
            return getApplicableRateChargeList(dispatch, ChargeRateScope, principalId, currentRatesType)
        },
        onGetRateList: (principalId, currentRatesType) => {
            getRateList(dispatch, scope, principalId, currentRatesType)
        },
        onPublishNewVersion: (draft) => {
            dispatch(FormActions.hideModal(scope, "VERSION"))
            dispatch(FormActions.hideModal(scope, "DRAFT"))
            dispatch(FormActions.hideModal(scope, "CONFIRM_CHARGE_RATES_PUBLISH"))
            dispatch(FormActions.doPost(scope, draft, "/api/ChargeRate/Publish/", "The draft was published successfully.", false))
                .then(
                    () => {
                        getRateList(dispatch, ChargeRateListScope, draft.principalId, draft.currentRatesType);
                    }
                )
        }
    }
}

const getApplicableRates = (dispatch, scope, principalId, currentRatesType) => {
    switch (currentRatesType) {
        case (ChargeRateTypeConstants.CLIENT):
            return dispatch(FormActions.fetchRecords(scope, "/api/ChargeRate/Client/Rates/" + principalId))
        case (ChargeRateTypeConstants.PROJECT):
            return dispatch(FormActions.fetchRecords(scope, "/api/ChargeRate/Project/Rates/" + principalId))
        case (ChargeRateTypeConstants.PROPOSAL):
            return dispatch(FormActions.fetchRecords(scope, "/api/ChargeRate/Proposal/Rates/" + principalId))
        case (ChargeRateTypeConstants.COST):
            return dispatch(FormActions.fetchRecords(scope, "/api/ChargeRate/Cost/Rates/" + principalId))
        case (ChargeRateTypeConstants.STANDARDCONTRACTOR):
            return dispatch(FormActions.fetchRecords(scope, "/api/ChargeRate/StandardContractor/Rates/" + principalId))
        default:
            return dispatch(FormActions.fetchRecords(scope, "/api/ChargeRate/Standard/Rates/"))
    }
}

const getApplicableRateChargeList = (dispatch, scope, principalId, currentRatesType) => {
    switch (currentRatesType) {
        case (ChargeRateTypeConstants.CLIENT):
            return dispatch(FormActions.fetchRecords(scope, "/api/ChargeRate/Client/RateByChargeList/" + principalId))
        case (ChargeRateTypeConstants.PROJECT):
            return dispatch(FormActions.fetchRecords(scope, "/api/ChargeRate/Project/RateByChargeList/" + principalId))
        case (ChargeRateTypeConstants.PROPOSAL):
            return dispatch(FormActions.fetchRecords(scope, "/api/ChargeRate/Proposal/RateByChargeList/" + principalId))
        case (ChargeRateTypeConstants.COST):
            return dispatch(FormActions.fetchRecords(scope, "/api/ChargeRate/Cost/RateByChargeList/" + principalId))
        case (ChargeRateTypeConstants.STANDARDCONTRACTOR):
            return dispatch(FormActions.fetchRecords(scope, "/api/ChargeRate/StandardContractor/RateByChargeList/" + principalId))
        default:
            return dispatch(FormActions.fetchRecords(scope, "/api/ChargeRate/Standard/RateByChargeList/" + principalId))
    }
}

const getRateList = (dispatch, scope, principalId, currentRatesType) => {
    switch (currentRatesType) {
        case (ChargeRateTypeConstants.CLIENT):
            return dispatch(FormActions.fetchRecords(scope, "/api/ChargeRateList/Client/Rates/" + principalId))
        case (ChargeRateTypeConstants.PROJECT):
            return dispatch(FormActions.fetchRecords(scope, "/api/ChargeRateList/Project/Rates/" + principalId))
        case (ChargeRateTypeConstants.PROPOSAL):
            return dispatch(FormActions.fetchRecords(scope, "/api/ChargeRateList/Proposal/Rates/" + principalId))
        case (ChargeRateTypeConstants.COST):
            return dispatch(FormActions.fetchRecords(scope, "/api/ChargeRateList/Cost/Rates/" + principalId))
        case (ChargeRateTypeConstants.STANDARDCONTRACTOR):
            return dispatch(FormActions.fetchRecords(scope, "/api/ChargeRateList/StandardContractor/Rates/"))
        default:
            return dispatch(FormActions.fetchRecords(scope, "/api/ChargeRateList/Standard/Rates/"))
    }
}

const deleteRateList = (dispatch, scope, principalId, currentRatesType) => {
    switch (currentRatesType) {
        case (ChargeRateTypeConstants.CLIENT):
            return dispatch(FormActions.doPost(scope, {},  "/api/ChargeRate/Client/delete/" + principalId, "The charge rate list deleted successfully.", false))
        case (ChargeRateTypeConstants.PROJECT):
            return dispatch(FormActions.doPost(scope, {}, "/api/ChargeRate/Project/delete/" + principalId, "The charge rate list deleted successfully.", false))
        case (ChargeRateTypeConstants.PROPOSAL):
            return dispatch(FormActions.doPost(scope, {}, "/api/ChargeRate/Proposal/delete/" + principalId, "The charge rate list deleted successfully.", false))
        case (ChargeRateTypeConstants.COST):
            return dispatch(FormActions.doPost(scope, {}, "/api/ChargeRate/Cost/delete/" + principalId, "The charge rate list deleted successfully.", false))
        case (ChargeRateTypeConstants.STANDARDCONTRACTOR):
            return dispatch(FormActions.doPost(scope, {}, "/api/ChargeRate/StandardContractor/delete/" + principalId, "The charge rate list deleted successfully.", false))
        default:
            return dispatch(FormActions.doPost(scope, {}, "/api/ChargeRate/Standard/delete/" + principalId, "The charge rate list deleted successfully.", false))
    }
}

const ChargeRateListContainer = connect(
    (state, ownProps) => {
        return {
            sourceData: ChargeRateScope.selector(state),
            currentRatesType: ownProps.currentRatesType,
            principalId: ownProps.principalId
        }
    },
    mapDispatchToChargeRate
)(ChargeRateList)

const ChargeRateListExtendedContainer = connect(
    (state, ownProps) => {
        return {
            sourceData: ChargeRateListScope.selector(state),
            currentRatesType: ownProps.currentRatesType,
            principalId: ownProps.principalId
        }
    },
    mapDispatchToChargeRateList
)(ChargeRateListExtended)

const ChargeRatesDraftFormContainer = connect(
    (state, ownProps) => {
        return {
            chargeRates: ChargeRateScope.selector(state).records,
            currentRatesType: ownProps.currentRatesType,
            principalId: ownProps.principalId,
            principal: ownProps.principal,
            rateListName: ownProps.name,
            comment: ownProps.comment,
            effectiveDate: ownProps.effectiveDate
        }
    },
    mapDispatchToChargeRate
)(ChargeRatesDraftForm)

export { ChargeRateListContainer, ChargeRatesDraftFormContainer, ChargeRateListExtendedContainer }