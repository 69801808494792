import { connect } from "react-redux"
import VariationCardBasic from "components/variations/layout/VariationCardBasic"
import VariationList from "components/variations/layout/VariationList"
import { VariationScope } from "components/variations/redux/VariationScope"
import { StaticProjectScope } from "components/static/redux/StaticProjectScope"
import { FormActions, FormEvents } from "@gman/gman-redux"

const mapDispatchToProps = (dispatch) => {
  var scope = VariationScope
  return {
    ...FormEvents.getTableHeaderRowEvents(dispatch, scope),
    ...FormEvents.getFeedbackEvents(dispatch, scope),
    ...FormEvents.getPaginatorEvents(dispatch, scope),
    ...FormEvents.getModalEventsForModalsOnAll(dispatch, scope),
    ...FormEvents.getRecordStateEvents(dispatch, scope),
    ...FormEvents.getConfirmEventsForModalsOnAll(dispatch, scope),
    onLoadDependentData: () => {
      dispatch(FormActions.fetchAll(StaticProjectScope))
    }
  }
}

const VariationContainer = connect(
  state => { return { sourceData: VariationScope.selector(state) } },
  mapDispatchToProps
)(VariationCardBasic)

const VariationListContainer = connect(
  (state, ownProps) => {
    return {
      sourceData: VariationScope.selector(state),
      projectId: ownProps.projectId
    }
  },
  mapDispatchToProps
)(VariationList)

export { VariationContainer, VariationListContainer }