import ScopeConstants from "constants/ScopeConstants"
import { ScopeShape } from "@gman/gman-redux"
import PropTypes from "prop-types"

export const ManagerScope = {
    key: ScopeConstants.MANAGER,
    selector: (state) => state.managers,
    tokenSelector: (state) => state.authentication.token,
    fetchEndpoint: "/api/User/Search/"
}

ManagerScope.propTypes = PropTypes.shape(ScopeShape).isRequired