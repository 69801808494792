import React from "react"
import { ChargeRateListContainer } from "components/chargeRates/redux/ChargeRateContainers"

const ChargeRateCardBasicExtended = (props) => {
    const {
        currentRatesType, 
        principalId
    } = props.props.match.params
    return (
        <ChargeRateListContainer currentRatesType={currentRatesType} principalId={principalId} />
    )
}

export default ChargeRateCardBasicExtended