import React from "react"
import Moment from "moment"
import { withFormik } from "formik"
import * as Yup from "yup"
import RequiredStar from "components/common/RequiredStar"

import {
    Container,
    Col,
    Form,
    FormGroup,
    Label,
    Row,
    Button
} from "reactstrap"

const ClientInvoiceForm = props => {
    const {
        onInoviceAmountEdit,
        onInvoiceItemsEdit
    } = props

    return (
        <div>
            <Container>
                <Row>
                    <Col xs={12} className="d-flex justify-content-around">
                        <Button color="primary" onClick={onInvoiceItemsEdit}>Edit cost items</Button>
                        <Button color="primary" onClick={onInoviceAmountEdit}>Enter invoice amount</Button>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default ClientInvoiceForm