import { connect } from "react-redux"
import { DispatchActions, FetchActions, FormActions, FormEvents } from "@gman/gman-redux"
import { ReportTimesheetHoursScope } from "components/reports/redux/ReportTimesheetHoursScope"
import { ReportUnpaidInvoicesScope } from "components/reports/redux/ReportUnpaidInvoicesScope"
import { ReportHistoricalInvoicesScope } from "components/reports/redux/ReportHistoricalInvoicesScope"
import { ReportSubContractorInvoicesScope } from "components/reports/redux/ReportSubContractorInvoicesScope"
import { ReportIndividualInvoiceNumbersScope } from "components/reports/redux/ReportIndividualInvoiceNumbersScope"
import { ReportChargeRatesScope } from "components/reports/redux/ReportChargeRatesScope"
import { ReportProjectFinancialsScope } from "components/reports/redux/ReportProjectFinancialsScope"
import ReportExportCardBasic from "components/reports/layout/ReportExportCardBasic"
import ReportUploadFileCardBasic from "components/reports/layout/ReportUploadFileCardBasic"
import FileSaver from "file-saver"

const mapDispatchToReportTimesheetHoursProps = (dispatch) => {
    var scope = ReportTimesheetHoursScope
    return {
        ...FormEvents.getFeedbackEvents(dispatch, scope),
        onRunExport: () => {
            dispatch(function (dispatch, getState) {
                var authToken = ""
                var fileName = ""
                if (scope.tokenSelector) {
                    authToken = scope.tokenSelector(getState())
                }
                dispatch(FormActions.inProgressStart(scope))
                
                FetchActions.fetchApiGet("/api/Report/TimesheetHoursByProject", authToken)
                    .then(function (response) {
                        fileName = extractFileName(response.headers)
                        return Promise.resolve(response.blob())
                    }).then(function (blob) {
                        FileSaver.saveAs(blob, fileName);
                    })
                    .then(() => { dispatch(FormActions.inProgressStop(scope)) })
                    .catch((error) => { FormActions.handleErrorResponse(error, scope, dispatch) })
                
                FetchActions.fetchApiGet("/api/Report/TimesheetHoursByWeekEnding", authToken)
                    .then(function (response) {
                        fileName = extractFileName(response.headers)
                        return Promise.resolve(response.blob())
                    }).then(function (blob) {
                        FileSaver.saveAs(blob, fileName);
                    })
                    .then(() => { dispatch(FormActions.inProgressStop(scope)) })
                    .catch((error) => { FormActions.handleErrorResponse(error, scope, dispatch) })
                
                FetchActions.fetchApiGet("/api/Report/VariationInvoices", authToken)
                    .then(function (response) {
                        fileName = extractFileName(response.headers)
                        return Promise.resolve(response.blob())
                    }).then(function (blob) {
                        FileSaver.saveAs(blob, fileName);
                    })
                    .then(() => { dispatch(FormActions.inProgressStop(scope)) })
                    .catch((error) => { FormActions.handleErrorResponse(error, scope, dispatch) })
                FetchActions.fetchApiGet("/api/Report/ProjectDetailInvoices", authToken)
                    .then(function (response) {
                        fileName = extractFileName(response.headers)
                        return Promise.resolve(response.blob())
                    }).then(function (blob) {
                        FileSaver.saveAs(blob, fileName);
                    })
                    .then(() => { dispatch(FormActions.inProgressStop(scope)) })
                    .catch((error) => { FormActions.handleErrorResponse(error, scope, dispatch) })
                FetchActions.fetchApiGet("/api/Report/ClientInvoices", authToken)
                    .then(function (response) {
                        fileName = extractFileName(response.headers)
                        return Promise.resolve(response.blob())
                    }).then(function (blob) {
                        FileSaver.saveAs(blob, fileName);
                    })
                    .then(() => { dispatch(FormActions.inProgressStop(scope)) })
                    .catch((error) => { FormActions.handleErrorResponse(error, scope, dispatch) })
                
                
            })
        },
        uploadEvents: {
            ...FormEvents.getUploadFileEvents(dispatch, scope).uploadEvents,
            onUploadAccept: fileData => {
                dispatch({ ...DispatchActions.scopedDispatch(scope.key, "CLEAR_UPLOAD_FEEDBACK") })
                dispatch(FormActions.acceptUpload(scope, fileData))
            }
        },
        onUploadSubmit: fileData => {
            dispatch({ ...DispatchActions.scopedDispatch(scope.key, "CLEAR_UPLOAD_FEEDBACK") })
            dispatch(FormActions.fetchUpload(scope, "/api/TimesheetEntry/UpdateInvoiceNumber/", fileData, "The files were uploaded successfully.", true))
                .then((result) => {
                    if (result && result.error) { }
                    else {
                        dispatch({ ...DispatchActions.scopedDispatch(scope.key, "APPLY_UPLOAD_FEEDBACK"), record: result.record })
                    }
                })
                .catch((error) => { FormActions.handleErrorResponse(error, scope, dispatch) })
                .finally(() => { dispatch(FormActions.isUploadingStop(scope)) })
        },
    }
}

const mapDispatchToReportUnpaidInvoicesProps = (dispatch) => {
    var scope = ReportUnpaidInvoicesScope
    return {
        ...FormEvents.getFeedbackEvents(dispatch, scope),
        onRunExport: () => {
            dispatch(function (dispatch, getState) {
                var authToken = ""
                var fileName = ""
                if (scope.tokenSelector) {
                    authToken = scope.tokenSelector(getState())
                }
                dispatch(FormActions.inProgressStart(scope))
                FetchActions.fetchApiGet("/api/Report/UnpaidInvoices", authToken)
                    .then(function (response) {
                        fileName = extractFileName(response.headers)
                        return Promise.resolve(response.blob())
                    }).then(function (blob) {
                        FileSaver.saveAs(blob, fileName);
                    })
                    .then(() => { dispatch(FormActions.inProgressStop(scope)) })
                    .catch((error) => { FormActions.handleErrorResponse(error, scope, dispatch) })
            })
        }
    }
}

const mapDispatchToReportSubContractorInvoicesProps = (dispatch) => {
    var scope = ReportUnpaidInvoicesScope
    return {
        ...FormEvents.getFeedbackEvents(dispatch, scope),
        onRunExport: () => {
            dispatch(function (dispatch, getState) {
                var authToken = ""
                var fileName = ""
                if (scope.tokenSelector) {
                    authToken = scope.tokenSelector(getState())
                }
                dispatch(FormActions.inProgressStart(scope))
                FetchActions.fetchApiGet("/api/Report/SubcontractorInvoices", authToken)
                    .then(function (response) {
                        fileName = extractFileName(response.headers)
                        return Promise.resolve(response.blob())
                    }).then(function (blob) {
                        FileSaver.saveAs(blob, fileName);
                    })
                    .then(() => { dispatch(FormActions.inProgressStop(scope)) })
                    .catch((error) => { FormActions.handleErrorResponse(error, scope, dispatch) })
            })
        }
    }
}

const mapDispatchToReportHistoricalInvoicesProps = (dispatch) => {
    var scope = ReportSubContractorInvoicesScope
    return {
        ...FormEvents.getFeedbackEvents(dispatch, scope),
        onRunExport: () => {
            dispatch(function (dispatch, getState) {
                var authToken = ""
                var fileName = ""
                if (scope.tokenSelector) {
                    authToken = scope.tokenSelector(getState())
                }
                dispatch(FormActions.inProgressStart(scope))
                FetchActions.fetchApiGet("/api/Report/HistoricalInvoices", authToken)
                    .then(function (response) {
                        fileName = extractFileName(response.headers)
                        return Promise.resolve(response.blob())
                    }).then(function (blob) {
                        FileSaver.saveAs(blob, fileName);
                    })
                    .then(() => { dispatch(FormActions.inProgressStop(scope)) })
                    .catch((error) => { FormActions.handleErrorResponse(error, scope, dispatch) })
            })
        }
    }
}

const mapDispatchToReportChargeRatesProps = (dispatch) => {
    var scope = ReportChargeRatesScope
    return {
        ...FormEvents.getFeedbackEvents(dispatch, scope),
        onRunExport: () => {
            dispatch(function (dispatch, getState) {
                var authToken = ""
                var fileName = ""
                if (scope.tokenSelector) {
                    authToken = scope.tokenSelector(getState())
                }
                dispatch(FormActions.inProgressStart(scope))
                FetchActions.fetchApiGet("/api/Report/ChargeRates", authToken)
                    .then(function (response) {
                        fileName = extractFileName(response.headers)
                        return Promise.resolve(response.blob())
                    }).then(function (blob) {
                        FileSaver.saveAs(blob, fileName);
                    })
                    .then(() => { dispatch(FormActions.inProgressStop(scope)) })
                    .catch((error) => { FormActions.handleErrorResponse(error, scope, dispatch) })

                FetchActions.fetchApiGet("/api/Report/ChargeRatesClassification", authToken)
                    .then(function (response) {
                        fileName = extractFileName(response.headers)
                        return Promise.resolve(response.blob())
                    }).then(function (blob) {
                        FileSaver.saveAs(blob, fileName);
                    })
                    .then(() => { dispatch(FormActions.inProgressStop(scope)) })
                    .catch((error) => { FormActions.handleErrorResponse(error, scope, dispatch) })
            })
        }
    }
}

const mapDispatchReportIndividualInvoiceNumbersProps = (dispatch) => {
    var scope = ReportIndividualInvoiceNumbersScope
    return {
        ...FormEvents.getFeedbackEvents(dispatch, scope),
        uploadEvents: {
            ...FormEvents.getUploadFileEvents(dispatch, scope).uploadEvents,
            onUploadAccept: fileData => {
                dispatch({ ...DispatchActions.scopedDispatch(scope.key, "CLEAR_UPLOAD_FEEDBACK") })
                dispatch(FormActions.acceptUpload(scope, fileData))
            }
        },
        onUploadSubmit: fileData => {
            dispatch({ ...DispatchActions.scopedDispatch(scope.key, "CLEAR_UPLOAD_FEEDBACK") })
            dispatch(FormActions.fetchUpload(scope, "/api/TimesheetEntry/UpdateInvoiceNumber/", fileData, "The files were uploaded successfully.", true))
                .then((result) => {
                    if (result && result.error) { }
                    else {
                        dispatch({ ...DispatchActions.scopedDispatch(scope.key, "APPLY_UPLOAD_FEEDBACK"), record: result.record })
                    }
                })
                .catch((error) => { FormActions.handleErrorResponse(error, scope, dispatch) })
                .finally(() => { dispatch(FormActions.isUploadingStop(scope)) })
        },
        onClearUploadFeedback: () => {
            dispatch({ ...DispatchActions.scopedDispatch(scope.key, "CLEAR_UPLOAD_FEEDBACK") })
        }
    }
}

const mapDispatchToReportProjectFinancialsProps = (dispatch) => {
    var scope = ReportProjectFinancialsScope
    return {
        ...FormEvents.getFeedbackEvents(dispatch, scope),
        onRunExport: () => {
            dispatch(function (dispatch, getState) {
                var authToken = ""
                var fileName = ""
                if (scope.tokenSelector) {
                    authToken = scope.tokenSelector(getState())
                }
                dispatch(FormActions.inProgressStart(scope))
                FetchActions.fetchApiGet("/api/Report/ProjectFinancials", authToken)
                    .then(function (response) {
                        fileName = extractFileName(response.headers)
                        return Promise.resolve(response.blob())
                    }).then(function (blob) {
                        FileSaver.saveAs(blob, fileName);
                    })
                    .then(() => { dispatch(FormActions.inProgressStop(scope)) })
                    .catch((error) => { FormActions.handleErrorResponse(error, scope, dispatch) })
            })
        }
    }
}

function extractFileName(headers) {
    var disposition = headers.get("content-disposition")
    var fileName = disposition.match(/filename\*?=((['"])[\s\S]*?\2|[^;\n]*)/)[1]
    return fileName
}

const TimesheetHoursContainer = connect((state) => { return { sourceData: ReportTimesheetHoursScope.selector(state) } }, mapDispatchToReportTimesheetHoursProps)(ReportExportCardBasic)
const UnpaidInvoicesContainer = connect((state) => { return { sourceData: ReportUnpaidInvoicesScope.selector(state) } }, mapDispatchToReportUnpaidInvoicesProps)(ReportExportCardBasic)
const HistoricalInvoicesContainer = connect((state) => { return { sourceData: ReportHistoricalInvoicesScope.selector(state) } }, mapDispatchToReportHistoricalInvoicesProps)(ReportExportCardBasic)
const ReportSubContractorInvoicesContainer = connect((state) => { return { sourceData: ReportSubContractorInvoicesScope.selector(state) } }, mapDispatchToReportSubContractorInvoicesProps)(ReportExportCardBasic)
const ReportChargeRatesContainer = connect((state) => { return { sourceData: ReportChargeRatesScope.selector(state) } }, mapDispatchToReportChargeRatesProps)(ReportExportCardBasic)
const ReportProjectFinancialsContainer = connect((state) => {
    console.log(state, 'state');
    return ({ sourceData: ReportProjectFinancialsScope.selector(state) })
}, mapDispatchToReportProjectFinancialsProps)(ReportExportCardBasic)
const ReportIndividualInvoiceNumbersContainer = connect(
    (state) => {
        return {
            sourceData: ReportIndividualInvoiceNumbersScope.selector(state)
        }
    }, mapDispatchReportIndividualInvoiceNumbersProps)(ReportUploadFileCardBasic)



export {
    TimesheetHoursContainer,
    UnpaidInvoicesContainer,
    HistoricalInvoicesContainer,
    ReportSubContractorInvoicesContainer,
    ReportIndividualInvoiceNumbersContainer,
    ReportChargeRatesContainer,
    ReportProjectFinancialsContainer
}
