import ScopeConstants from "constants/ScopeConstants"
import { ScopeShape } from "@gman/gman-redux"
import PropTypes from "prop-types"

export const UserAuthenticationScope = {
    key: ScopeConstants.USER_AUTHENTICATION,
    selector: (state) => state.userAuthentications,
    tokenSelector: (state) => state.authentication.token,
    fetchEndpoint: "/api/UserAuthentication/Search/",
    singleEndpoint: "/api/UserAuthentication/Get/",
    updateEndpoint: "/api/UserAuthentication/Update/",
    deleteEndpoint: "/api/UserAuthentication/Delete/",
    defaultRecord: {}
}

UserAuthenticationScope.propTypes = PropTypes.shape(ScopeShape).isRequired