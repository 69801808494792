import React from "react"
import { Card, CardBody, CardHeader } from "reactstrap"
import { ProposalListMineContainer } from "components/proposals/redux/ProposalContainers"
import Icon from "components/common/Icon"

const ProposalCardMineBasic = () => {
    return (
        <Card>
            <CardHeader>
                <h6>
                    <Icon proposal />
                    My Proposals
                </h6>
            </CardHeader>
            <CardBody>
                <ProposalListMineContainer />
            </CardBody>
        </Card >
    )
}

export default ProposalCardMineBasic
