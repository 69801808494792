import ScopeConstants from "constants/ScopeConstants"
import { ScopeShape } from "@gman/gman-redux"
import PropTypes from "prop-types"

export const ReportHistoricalInvoicesScope = {
    key: ScopeConstants.REPORT_INVOICES_HISTORICAL,
    selector: (state) => state.reportHistoricalInvoices,
    tokenSelector: (state) => state.authentication.token
}

ReportHistoricalInvoicesScope.propTypes = PropTypes.shape(ScopeShape).isRequired