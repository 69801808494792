import React from "react"
import Moment from "moment"
import { withFormik } from "formik"
import * as Yup from "yup"
import RequiredStar from "components/common/RequiredStar"
import { AuthorizedButton, AuthorizedInputField } from "components/authentication/controls/AuthorizedControls"
import { SelectOption } from "@gman/gman-redux";
import SelectStyles from "components/common/SelectStyles";
import ScopeConstants from "constants/ScopeConstants";

import {
    Container,
    Col,
    Form,
    FormGroup,
    Label,
    Row
} from "reactstrap"

const InputField = AuthorizedInputField(p => p.canMarkItemsAsPaid)
const SubmitButton = AuthorizedButton(p => p.canMarkItemsAsPaid)

const BaseForm = props => {
    const {
        values,
        errors,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting
    } = props
    return (
        <div>
            <Form noValidate onSubmit={handleSubmit}>
                <Container>
                    <Row>
                        <Col xs={12}>
                            <FormGroup>
                                <RequiredStar />
                                <Label for="project">Project</Label>
                                <SelectOption
                                    styles={SelectStyles.colourStyles()}
                                    isClearable={true}
                                    disabled={isSubmitting}
                                    value={values.workItem}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    id="workItem"
                                    invalid={errors.workItem && errors.workItem.length > 0}
                                    stateFunc={state => {
                                        return { ...state.workItems };
                                    }}
                                    valueFunc={record => record}
                                    labelFunc={record => record.name + " (" + record.code + ")"}
                                    sortFunc={(a, b) => a.type.localeCompare(b.type)}
                                    matchFunc={record => record.workItemId}
                                    filterFunc={record =>
                                        record.type !== ScopeConstants.LEAVE ||
                                        (record.type === ScopeConstants.LEAVE &&
                                            (record.code === "MISC" ||
                                                record.code === "R&D ACTIVITY"))
                                    }
                                />
                                {errors.workItem && (
                                    <small className="text-danger">{errors.workItem}</small>
                                )}
                              
                            </FormGroup>
                        </Col>
                    </Row>
                </Container>
                <InputField
                    onChange={handleChange}
                    value={values.invoiceDate ? Moment(values.invoiceDate).format("YYYY-MM-DD") : ""}
                    type="hidden"
                    name="invoiceDate"
                    id="invoiceDate"
                />
                <SubmitButton outline className="float-right" disabled={isSubmitting} color="success" type="submit">Submit</SubmitButton>
            </Form>
        </div>
    )
}


const validationSchema = Yup.object({
    workItem: Yup
    .object()
    .shape({
        workItemId: Yup.string()
            .required("Project is required")
            .nullable()
    })
});


const ClientInvoiceSelectProjectForm = withFormik({
    displayName: "ClientInvoiceSelectProjectForm",
    enableReinitialize: true,
    validationSchema: validationSchema,
    handleSubmit: (values, { props, setSubmitting }) => {
        props.onSubmitUpdate(values.workItem.workItemId);
        setSubmitting(false)
    }
})(BaseForm)

export default ClientInvoiceSelectProjectForm