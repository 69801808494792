import React from "react"
import { withFormik, FieldArray } from "formik"
import * as Yup from "yup"
import WorkItemCell from "components/common/WorkItemCell"

import {
    Button,
    Form
} from "reactstrap"

const BaseForm = props => {
    const {
        values,
        sourceData,
        handleSubmit,
        isSubmitting,
        dirty
    } = props
    return (
        <Form noValidate onSubmit={handleSubmit}>
            {
                <FieldArray
                    name="workItems"
                    render={arrayHelpers => (
                        sourceData.records.map((record) => (
                            <Button
                                active={(indexOf(record, values.workItems) >= 0)}
                                block
                                color="light"
                                onClick={() =>
                                    (indexOf(record, values.workItems) >= 0) ?
                                        arrayHelpers.remove(indexOf(record, values.workItems)) :
                                        arrayHelpers.push(record)
                                }
                            >
                                <WorkItemCell workItem={record} />
                            </Button>
                        ))
                    )}
                />
            }
            <br />
            <Button
                outline
                className="float-right"
                disabled={isSubmitting || !dirty}
                color="success"
                type="submit"
            >
                Add
            </Button>
        </Form>
    )
}

const indexOf = (record, values) => {
    if (values) {
        for (var i = 0; i < values.length; i++) {
            if ((record.workItemId === values[i].workItemId) &&
                ((record.costCode && record.costCode.costCodeId || "") === (values[i].costCode && values[i].costCode.costCodeId || "")) &&
                ((record.variation && record.variation.variationId || "") === (values[i].variation && values[i].variation.variationId || ""))) {
                return i
            }
        }
    }
    return -1
}

const validationSchema = Yup.object({
    comment: Yup.string()
        .nullable()
})

const TimesheetEntryHistorySelectForm = withFormik({
    displayName: "TimesheetEntryHistorySelectForm",
    enableReinitialize: true,
    validationSchema: validationSchema,
    handleSubmit: (values, { props, setSubmitting }) => {
        var workItemEntries = values.workItems.map((workItem) => {
            return {
                timesheetId: values.timesheetId,
                workItem: workItem
            }
        })

        var body = {
            timesheetId: values.timesheetId,
            workItemEntries: workItemEntries
        };

        props.onSubmitUpdate(body)
        setSubmitting(false)
    }
})(BaseForm)

export default TimesheetEntryHistorySelectForm