import ScopeConstants from "constants/ScopeConstants"
import { FormActionReducer, FormActionConstants } from "@gman/gman-redux"

export const ChargeRatesReducer = (
    state = {
        ...FormActionReducer.defaultPageState(),
        ...FormActionReducer.defaultRecordState(),
        ...FormActionReducer.defaultSearchOptions("", 200),
        ...FormActionReducer.defaultTabs()
    },
    action) => {

    if (action.scope === ScopeConstants.CHARGE_RATE) {
        console.log();
        if (action.type === FormActionConstants.RECEIVE_RECORDS) {
            return {
                ...state,
                currentRatesType: action.currentRatesType,
                usingRatesType: action.usingRatesType,
                records: action.records,
                rateListName: action.rateListName,
                status: action.status,
                searchOptions: {
                    ...state.searchOptions,
                    totalRecords: action.totalRecords
                }
            }
        }

        if (action.type === FormActionConstants.UPDATE_SORT) {
            var isDescending = false;
            if (state.searchOptions.orderBy === action.column) {
                isDescending = !state.searchOptions.descending;
            }
            return {
                ...state,
                searchOptions: {
                    ...state.searchOptions,
                    orderBy: action.column,
                    descending: isDescending
                }
            }
        }

        return FormActionReducer.reduce(action, state)
    }

    return state
}