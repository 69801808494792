import React from "react"
import Moment from "moment"
import { Link } from "react-router-dom"
import { withFormik } from "formik"
import * as Yup from "yup"
import OpportunityStatusConstants from "constants/OpportunityStatusConstants"
import Icon from "components/common/Icon"
import RequiredStar from "components/common/RequiredStar"
import SelectStyles from "components/common/SelectStyles"
import { AuthorizedPublishingToolbar, AuthorizedSelectOption, AuthorizedInputField } from "components/authentication/controls/AuthorizedControls"
import {
    Col,
    Container,
    Form,
    FormGroup,
    InputGroup,
    InputGroupText,
    InputGroupAddon,
    Label,
    Row
} from "reactstrap"

const PublishingToolbarGroup = AuthorizedPublishingToolbar(p => p.canWriteWorkItems)
const SelectOptions = AuthorizedSelectOption(p => p.canWriteWorkItems)
const InputField = AuthorizedInputField(p => p.canWriteWorkItems)

const BaseForm = props => {
    const {
        values,
        dirty,
        errors,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
        onConfirmPublish,
        onConfirmConvert,
        modals,
        modalEvents,
        validateForm
    } = props
    var isDraft = values.status === OpportunityStatusConstants.DRAFT
    var isConverted = (values.status === OpportunityStatusConstants.CONVERTEDTOPROPOSAL && values.proposalId)
    return (
        <div>
            <Form noValidate>
                {values.proposalId ?
                    proposalLink(values.proposalId)
                    :
                    ""}
                <h6>
                    <Icon folderOpen />
                    Opportunity Details
                </h6>
                <hr />
                <Container>
                    <Row>
                        <Col xs={12} md={6}>
                            <FormGroup>
                                <RequiredStar />
                                <Label for="name">Opportunity Name</Label>
                                <InputField
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.name || ""}
                                    type="text"
                                    name="name"
                                    id="name"
                                    placeholder="Enter an opportunity name"
                                    invalid={errors.name} />
                                {errors.name && <small className="text-danger">{errors.name}</small>}
                            </FormGroup>
                        </Col>
                        <Col xs={12} md={6}>
                            <FormGroup>
                                <Label for="code">Opportunity Code</Label>
                                <InputGroup>
                                    <InputField
                                        disabled
                                        value={values.code || "Pending"}
                                        type="text"
                                        name="code"
                                        id="code"
                                        placeholder="Opportunity Code"
                                        invalid={errors.code} />
                                </InputGroup>
                                {errors.code && <small className="text-danger">{errors.code}</small>}
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12} md={6}>
                            <FormGroup>
                                <RequiredStar />
                                <Label for="officeId">CMP Office</Label>
                                <SelectOptions
                                    styles={errors.officeId && errors.officeId.length > 0 ? SelectStyles.errorStyles() : {}}
                                    isClearable={true}
                                    disabled={isSubmitting}
                                    value={values.officeId}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    id="officeId"
                                    invalid={errors.officeId && errors.officeId.length > 0}
                                    stateFunc={(state) => { return { ...state.offices } }}
                                    valueFunc={(record) => record.officeId}
                                    labelFunc={(record) => record.name}
                                    sortFunc={(a, b) => a.name.localeCompare(b.name)}
                                />
                                {errors.officeId && <small className="text-danger">{errors.officeId}</small>}
                            </FormGroup>
                        </Col>
                    </Row>
                </Container>
                <h6>
                    <Icon client />
                    Client Details
                </h6>
                <hr />
                <Container>
                    <Row>
                        <Col xs={12} md={6}>
                            <FormGroup>
                                <RequiredStar />
                                <Label for="clientId">Client</Label>
                                <SelectOptions
                                    styles={errors.clientId && errors.clientId.length > 0 ? SelectStyles.errorStyles() : {}}
                                    isClearable={true}
                                    disabled={isSubmitting}
                                    value={values.clientId}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    id="clientId"
                                    invalid={errors.clientId && errors.clientId.length > 0}
                                    stateFunc={(state) => { return { ...state.clients } }}
                                    valueFunc={(record) => record.clientId}
                                    labelFunc={(record) => record.name + " (" + record.code + ")"}
                                    sortFunc={(a, b) => a.name.localeCompare(b.name)}
                                />
                                {errors.clientId && <small className="text-danger">{errors.clientId}</small>}
                            </FormGroup>
                        </Col>
                    </Row>
                </Container>
                <h6>
                    <Icon clock />
                    Fee and Program
                </h6>
                <hr />
                <Container>
                    <Row>
                        <Col xs={12} md={6}>
                            <FormGroup>
                                <RequiredStar />
                                <Label for="likelihood">Likelihood</Label>
                                <InputGroup>
                                    <InputField
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.likelihood || 0}
                                        type="number"
                                        step={1}
                                        min={0}
                                        name="likelihood"
                                        id="likelihood"
                                        placeholder="Enter the likelihood of the opportunity"
                                        invalid={errors.likelihood} />
                                    <InputGroupAddon addonType="append">
                                        <InputGroupText>%</InputGroupText>
                                    </InputGroupAddon>
                                </InputGroup>
                                {errors.likelihood && <small className="text-danger">{errors.likelihood}</small>}
                            </FormGroup>
                        </Col>
                        <Col xs={12} md={6}>
                            <FormGroup>
                                <RequiredStar />
                                <Label for="estimatedFee">Estimated Fee</Label>
                                <InputGroup>
                                    <InputGroupAddon addonType="prepend">
                                        <InputGroupText>$</InputGroupText>
                                    </InputGroupAddon>
                                    <InputField
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.estimatedFee || ""}
                                        type="text"
                                        name="estimatedFee"
                                        id="estimatedFee"
                                        placeholder="Enter the estimated fee"
                                        invalid={errors.estimatedFee} />
                                </InputGroup>
                                {errors.estimatedFee && <small className="text-danger">{errors.estimatedFee}</small>}
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12} md={6}>
                            <FormGroup>
                                <RequiredStar />
                                <Label for="startDate">Start Date</Label>
                                <InputField
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={Moment(values.startDate).format("YYYY-MM-DD") || ""}
                                    type="date"
                                    name="startDate"
                                    id="startDate"
                                    placeholder="Start Date"
                                    invalid={errors.startDate} />
                                {errors.startDate && <small className="text-danger">{errors.startDate}</small>}
                            </FormGroup>
                        </Col>
                        <Col xs={12} md={6}>
                            <FormGroup>
                                <RequiredStar />
                                <Label for="endDate">End Date</Label>
                                <InputField
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={Moment(values.endDate).format("YYYY-MM-DD") || ""}
                                    type="date"
                                    name="endDate"
                                    id="endDate"
                                    placeholder="End Date"
                                    invalid={errors.endDate} />
                                {errors.endDate && <small className="text-danger">{errors.endDate}</small>}
                            </FormGroup>
                        </Col>
                    </Row>
                </Container>
                <h6>
                    <Icon workflow />
                    Workflow
                </h6>
                <hr />
                <Container>
                    <Row>
                        <Col xs={12} md={6}>
                            <FormGroup>
                                <Label for="status">Opportunity Status</Label>
                                <InputField
                                    disabled={isDraft || isConverted}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    type="select"
                                    name="status"
                                    value={values.status || ""}
                                    invalid={errors.status}
                                    id="status"
                                >
                                    <option disabled={!isDraft} value={OpportunityStatusConstants.DRAFT}>Draft</option>
                                    <option value={OpportunityStatusConstants.INPROGRESS}>In Progress</option>
                                    <option value={OpportunityStatusConstants.NOGO}>No Go</option>
                                    <option value={OpportunityStatusConstants.CONVERTEDTOPROPOSAL}>Converted to Proposal</option>
                                </InputField>
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12}>
                            <FormGroup>
                                <Label for="comments">Comments</Label>
                                <InputField
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.comments || ""}
                                    type="textarea"
                                    name="comments"
                                    id="comments"
                                    placeholder="Enter comments / feedback about the opportunity"
                                    style={{ resize: "none" }}
                                    rows={3}
                                    invalid={errors.comments} />
                                {errors.comments && <small className="text-danger">{errors.comments}</small>}
                            </FormGroup>
                        </Col>
                    </Row>
                </Container>
                <PublishingToolbarGroup
                    canPublish={values.status === OpportunityStatusConstants.DRAFT && !dirty}
                    canConvert={(values.status !== OpportunityStatusConstants.DRAFT && (values.proposalId === null) && !dirty) === true}
                    canSaveDraft={!isSubmitting && dirty}
                    modals={modals}
                    modalEvents={modalEvents}
                    recordToUpdate={values}
                    onConfirmSaveDraft={handleSubmit}
                    onConfirmPublish={(e) => {
                        validateForm()
                        onConfirmPublish(e)
                    }}
                    onConfirmConvert={onConfirmConvert}
                    convertEntityDescription="Proposal"
                />
            </Form>
        </div >
    )
}

const proposalLink = (proposalId) => {
    return (
        <div>
            <h6>
                <Icon project />
                <Link to={"/proposalSheet/" + proposalId}>Associated Proposal</Link>
            </h6>
            <hr />
        </div>
    )
}

const validationSchema = Yup.object({
    name: Yup.string()
        .required("Name is required"),
    clientId: Yup.string()
        .required("Client is required")
        .nullable(),
    officeId: Yup.string()
        .required("Office is required")
        .nullable(),
    startDate: Yup.date()
        .required("Start date is required")
        .typeError("Must be a date"),
    estimatedFee: numberValidation(),
    likelihood: numberValidation()
        .required("Likelihood is required")
        .max(100, "Cannot be greater than 100")
        .min(0, "Cannot be less than 0"),
    comments: Yup.string()
        .max(2048, "Maximum length is 2048 characters")
        .nullable(),
    endDate: Yup.date()
        .when(
            "startDate",
            (startDate, schema) => (
                startDate ?
                    schema
                        .min(startDate, "End date cannot precede Start date")
                        .required("End date is required")
                        .typeError("Must be a date")
                    :
                    schema
                        .required("End date is required")
                        .typeError("Must be a date"))
        )
})

function numberValidation() {
    return Yup.number()
        .default(0)
        .nullable()
        .required("Value is required")
        .round()
        .positive("Must be positive")
        .typeError("Must be a number")
}

const OpportunityFormExtended = withFormik({
    displayName: "OpportunityFormExtended",
    enableReinitialize: true,
    validationSchema: validationSchema,
    mapPropsToValues: ({ ...props }) => ({
        ...props,
        startDate: props.startDate || "",
        endDate: props.endDate || ""
    }),
    handleSubmit: (values, { props, setSubmitting }) => {
        props.onSubmitUpdate(values)
        setSubmitting(false)
    }
})(BaseForm)

export default OpportunityFormExtended