import React from "react"
import { Link } from "react-router-dom"
import Icon from "components/common/Icon"
import logo from "assets/logo.png"
import systemLogo from "assets/system-logo.png"
import { AuthenticationStatusContainer } from "components/authentication/redux/AuthenticationContainers"
import { RestrictedComponent } from "components/authentication/redux/AuthorizationWrappers"

import {
    Collapse,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    Navbar,
    NavbarToggler,
    NavbarBrand,
    Nav,
    NavItem,
    NavLink,
    UncontrolledDropdown
} from "reactstrap"

const TimesheetMenuItem =
    RestrictedComponent(
        () =>
            <UncontrolledDropdown nav inNavbar>
                <DropdownToggle nav caret>
                    <Icon clock />Timesheets
                </DropdownToggle>
                <DropdownMenu>
                    <MyTimesheetsMenuItem />
                    <TeamTimesheetsMenuItem />
                    <AnyTimesheetsMenuItem />
                </DropdownMenu>
            </UncontrolledDropdown>,
        p => p.canCompleteOwnTimesheet || p.canWriteWorkItems || p.canReadAllTimesheets)

const MyTimesheetsMenuItem = RestrictedComponent(() => <DropdownItem tag={Link} to="/timesheet/mine/"><Icon person />My Timesheets</DropdownItem>, p => p.canCompleteOwnTimesheet)
const TeamTimesheetsMenuItem = RestrictedComponent(() => <DropdownItem tag={Link} to="/timesheet/team/"><Icon people />Team Timesheets</DropdownItem>, p => p.canWriteWorkItems)
const AnyTimesheetsMenuItem = RestrictedComponent(() => <DropdownItem tag={Link} to="/timesheet/any/"><Icon group />All Timesheets</DropdownItem>, p => p.canReadAnyTimesheets)

const ExpensesMenuItem =
    RestrictedComponent(
        () =>
            <UncontrolledDropdown nav inNavbar>
                <DropdownToggle nav caret>
                    <Icon expense />Expenses
                </DropdownToggle>
                <DropdownMenu>
                    <MyExpensesMenuItem />
                    <TeamExpensesMenuItem />
                    <AnyExpensesMenuItem />
                </DropdownMenu>
            </UncontrolledDropdown>,
        p => p.canCompleteOwnExpenses || p.canWriteWorkItems || p.canReadAnyExpenses)

const MyExpensesMenuItem = RestrictedComponent(() => <DropdownItem tag={Link} to="/expense/mine/"><Icon person />My Expenses</DropdownItem>, p => p.canCompleteOwnExpenses)
const TeamExpensesMenuItem = RestrictedComponent(() => <DropdownItem tag={Link} to="/expense/team/"><Icon people />Team Expenses</DropdownItem>, p => p.canWriteWorkItems)
const AnyExpensesMenuItem = RestrictedComponent(() => <DropdownItem tag={Link} to="/expense/any/"><Icon group />All Expenses</DropdownItem>, p => p.canReadAnyExpenses)

const ProjectManagementMenu =
    RestrictedComponent(
        () =>
            <UncontrolledDropdown nav inNavbar>
                <DropdownToggle nav caret>
                    <Icon hammer />Project Management
                </DropdownToggle>
                <DropdownMenu>
                    <ClientsHeaderItem />
                    <ClientsMenuItem />
                    <DropdownItem header>Projects</DropdownItem>
                    <MyProjectsMenuItem />
                    <AnyProjectsMenuItem />
                    <DropdownItem header>Proposals</DropdownItem>
                    <MyProposalsMenuItem />
                    <AnyProposalsMenuItem />
                    <MyOpportunitiesHeader />
                    <MyOpportunitiesMenuItem />
                    <AnyOpportunitiesMenuItem />
                </DropdownMenu>
            </UncontrolledDropdown>,
        p => p.canReadWorkItems)

const MyProjectsMenuItem = RestrictedComponent(() => <DropdownItem tag={Link} to="/project/mine/"><Icon project />My Projects</DropdownItem>, p => p.canWriteWorkItems)
const AnyProjectsMenuItem = RestrictedComponent(() => <DropdownItem tag={Link} to="/project/any/"><Icon project />All Projects</DropdownItem>, p => p.canReadWorkItems)

const MyProposalsMenuItem = RestrictedComponent(() => <DropdownItem tag={Link} to="/proposal/mine/"><Icon proposal />My Proposals</DropdownItem>, p => p.canWriteWorkItems)
const AnyProposalsMenuItem = RestrictedComponent(() => <DropdownItem tag={Link} to="/proposal/any/"><Icon proposal />All Proposals</DropdownItem>, p => p.canReadWorkItems)

const MyOpportunitiesHeader = RestrictedComponent(() => <DropdownItem header>Opportunities</DropdownItem>, p => p.canWriteWorkItems || p.canReadAllOpportunities)
const MyOpportunitiesMenuItem = RestrictedComponent(() => <DropdownItem tag={Link} to="/opportunity/mine/"><Icon opportunity />My Opportunities</DropdownItem>, p => p.canWriteWorkItems)
const AnyOpportunitiesMenuItem = RestrictedComponent(() => <DropdownItem tag={Link} to="/opportunity/any/"><Icon opportunity />All Opportunities</DropdownItem>, p => p.canReadAllOpportunities)

const ClientsHeaderItem = RestrictedComponent(() => <DropdownItem header>Clients</DropdownItem>, p => p.canReadClients)

const ReportingMenu =
    RestrictedComponent(
        () =>
            <UncontrolledDropdown nav inNavbar>
                <DropdownToggle nav caret>
                    <Icon reports />Reports
                </DropdownToggle>
                <DropdownMenu>
                    <ReportsHeader />
                    <ReportsTimesheetHoursMenuItem />
                    <ReportsUnpaidInvoicesMenuItem />
                    <ReportsHistoricalInvoicesMenuItem />
                    <ReportsSubContractorInvoiceMenuItem />
                    <ReportsChargeRatesMenuItem />
                    <ReportsProjectFinancialsPageMenuItem />
                    <InvoicesHeader />
                    <InvoicesMenuItem />
                    <SubContractorInvoicesMenuItem />
                    <ReportIndividualInvoiceNumbersMenuItem />
                    <WorkbenchExportsHeader />
                    <WorkbenchExportsMenuItem />
                </DropdownMenu>
            </UncontrolledDropdown>,
        p => p.canReadReports || p.canMarkItemsAsPaid || p.canViewUnpaidInvoices || p.canViewHistoricalInvoices || p.canWriteSubContractorInvoice || p.canMarkSubContractorInvoiceAsPaid)

const ReportsHeader = RestrictedComponent(() => <DropdownItem header>Reports</DropdownItem>, p => p.canReadReports || p.canViewUnpaidInvoices)
const InvoicesHeader = RestrictedComponent(() => <DropdownItem header>Invoices</DropdownItem>, p => p.canMarkItemsAsPaid || p.canWriteSubContractorInvoice || p.canMarkSubContractorInvoiceAsPaid)
const WorkbenchExportsHeader = RestrictedComponent(() => <DropdownItem header>Workbench Exports</DropdownItem>, p => p.canViewWorkbenchExports)

const ReportsTimesheetHoursMenuItem = RestrictedComponent(() =>
    <DropdownItem tag={Link} to="/report/timesheetHours/"><Icon hours />Timesheet Hours</DropdownItem>,
    p => p.canReadReports)

const ReportsChargeRatesMenuItem = RestrictedComponent(() =>
    <DropdownItem tag={Link} to="/report/chargeRates/"><Icon chargeRate />Charge Rates</DropdownItem>,
    p => p.canExportChargeRatesReport)

    const ReportsProjectFinancialsPageMenuItem = RestrictedComponent(() =>

    <DropdownItem tag={Link} to="/report/projectFinancials/"><Icon invoiceUnpaid />Project Financials</DropdownItem>,

    p => p.canReadReports)
    
const ReportsSubContractorInvoiceMenuItem = RestrictedComponent(() =>
    <DropdownItem tag={Link} to="/report/subContractorInvoices/"><Icon invoiceUnpaid />Sub Contractor Invoices</DropdownItem>,
    p => p.canReadReports)

const ReportsUnpaidInvoicesMenuItem = RestrictedComponent(() =>
    <DropdownItem tag={Link} to="/report/unpaidInvoices/"><Icon invoiceUnpaid />Unpaid Invoices</DropdownItem>,
    p => p.canViewUnpaidInvoices)

const ReportsHistoricalInvoicesMenuItem = RestrictedComponent(() =>
    <DropdownItem tag={Link} to="/report/historicalInvoices/"><Icon invoiceHistory />Historical Invoices</DropdownItem>,
    p => p.canViewHistoricalInvoices)

const ReportIndividualInvoiceNumbersMenuItem = RestrictedComponent(() =>
    <DropdownItem tag={Link} to="/report/individualInvoiceNumbers/"><Icon invoice />Upload Client Invoice Numbers</DropdownItem>,
    p => p.canReadReports)

const InvoicesMenuItem = RestrictedComponent(() =>
    <DropdownItem tag={Link} to="/clientInvoice/"><Icon invoice />Invoices</DropdownItem>,
    p => p.canMarkItemsAsPaid)

const SubContractorInvoicesMenuItem = RestrictedComponent(() =>
    <DropdownItem tag={Link} to="/subContractorInvoice/"><Icon invoice />Sub Contractor Invoices</DropdownItem>,
    p => p.canWriteSubContractorInvoice || p.canMarkSubContractorInvoiceAsPaid)

const WorkbenchExportsMenuItem = RestrictedComponent(() =>
    <DropdownItem tag={Link} to="/workbenchExport/"><Icon workbench />Workbench Exports</DropdownItem>,
    p => p.canViewWorkbenchExports)

const UserManagementMenu =
    RestrictedComponent(
        () =>
            <NavItem>
                <NavLink tag={Link} to="/user">
                    <Icon smiley />People
                </NavLink>
            </NavItem>,
        p => p.canReadUsers)

const AdminMenu =
    RestrictedComponent(
        () =>
            <UncontrolledDropdown nav inNavbar>
                <DropdownToggle nav caret>
                    <Icon cog />Admin
                </DropdownToggle>
                <DropdownMenu>
                    <ClassificationsMenuItem />
                    <OfficesMenuItem />
                    <ExpenseTypesMenuItem />
                    <ExpenseMonthlyDueDatesMenuItem />
                    <ChargeRatesMenuItem />
                    <StandardContractorChargeRatesMenuItem/>
                    <CostRateMenuItem />
                    <EditDataMenuItem />
                    <SystemMenuItem />
                </DropdownMenu>
            </UncontrolledDropdown>,
        p => p.canWriteSystem)

const ChargeRatesMenuItem = RestrictedComponent(() => <DropdownItem tag={Link} to="/chargeRate/standard"><Icon chargeRate />Standard Charge Rates</DropdownItem>, p => p.canWriteSystem)
const StandardContractorChargeRatesMenuItem = RestrictedComponent(() => <DropdownItem tag={Link} to="/chargeRate/standardContractor"><Icon chargeRate />Standard Contractor Charge Rates</DropdownItem>, p => p.canWriteSystem)
const CostRateMenuItem = RestrictedComponent(() => <DropdownItem tag={Link} to="/chargeRate/cost"><Icon chargeRate />Cost Rates</DropdownItem>, p => p.canWriteSystem)
const ClientsMenuItem = RestrictedComponent(() => <DropdownItem tag={Link} to="/client"><Icon client />Clients</DropdownItem>, p => p.canReadClients)
const ClassificationsMenuItem = RestrictedComponent(() => <DropdownItem tag={Link} to="/classification"><Icon school />Classifications</DropdownItem>, p => p.canWriteSystem)
const ExpenseTypesMenuItem = RestrictedComponent(() => <DropdownItem tag={Link} to="/expenseType"><Icon expenseType />Expense Types</DropdownItem>, p => p.canWriteSystem)
const ExpenseMonthlyDueDatesMenuItem = RestrictedComponent(() => <DropdownItem tag={Link} to="/expenseMonthlyDueDates"><Icon calendar />Expense Monthly Due Dates</DropdownItem>, p => p.canWriteSystem)
const OfficesMenuItem = RestrictedComponent(() => <DropdownItem tag={Link} to="/office"><Icon office />CMP Offices</DropdownItem>, p => p.canWriteSystem)
const EditDataMenuItem = RestrictedComponent(() => <DropdownItem tag={Link} to="/editData"><Icon system />Edit Data</DropdownItem>, p => p.canWriteSystem)
const SystemMenuItem = RestrictedComponent(() => <DropdownItem tag={Link} to="/system"><Icon system />System</DropdownItem>, p => p.canWriteSystem)

class NavMenu extends React.Component {

    constructor(props) {
        super(props)
        this.toggle = this.toggle.bind(this)
        this.state = {
            isOpen: false
        }
    }

    toggle() {
        this.setState({
            isOpen: !this.state.isOpen
        })
    }

    render() {
        return (
            <div>
                <Navbar className="cmp-nav" color="light" light expand="md">
                    <NavbarToggler onClick={this.toggle} />
                    <NavbarBrand className="ml-auto" tag={Link} to="/">
                        <img src={logo} />
                    </NavbarBrand>
                    <Collapse isOpen={this.state.isOpen} navbar>
                        <Nav navbar>
                            <TimesheetMenuItem />
                            <ExpensesMenuItem />
                            <ProjectManagementMenu />
                            <UserManagementMenu />
                            <ReportingMenu />
                            <AdminMenu />
                        </Nav>
                    </Collapse>
                    <NavbarBrand className="ml-auto system-logo" tag={Link} to="/">
                        <img src={systemLogo} />
                    </NavbarBrand>
                </Navbar>
                <AuthenticationStatusContainer />
            </div>
        )
    }
}

export { NavMenu }