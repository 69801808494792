import React, { Component } from "react"
import TableHeaderRow from "components/common/TableHeaderRow"
import { Table } from "reactstrap"
import ExpenseTypeRow from "components/expenseTypes/layout/ExpenseTypeRow"
import ExpenseTypeFormBasic from "components/expenseTypes/form/ExpenseTypeFormBasic"
import { ModalActions } from "@gman/gman-redux"
import { AuthorizedAddButton } from "components/authentication/controls/AuthorizedControls"
import {
    ConfirmDelete,
    FormModal,
    Paginator,
    SpinnerOverlay
} from "@gman/gman-react-bootstrap"
import FeedbackAlert from "components/common/FeedbackAlert"

const AddButton = AuthorizedAddButton(p => p.canWriteExpenseTypes)

class ExpenseTypeList extends Component {

    componentDidMount() {
        this.props.recordStateEvents.onClearSearch()
    }

    componentWillUnmount = () => {
        this.props.feedbackEvents.onFlushFeedback()
    }

    render() {
        var sourceData = this.props.sourceData
        var modals = sourceData.modals
        var confirmEvents = this.props.confirmEvents
        var feedbackEvents = this.props.feedbackEvents
        var modalEvents = this.props.modalEvents
        var paginatorEvents = this.props.paginatorEvents
        var tableHeaderRowEvents = this.props.tableHeaderRowEvents

        return (
            <div>
                <FeedbackAlert {...feedbackEvents} feedback={sourceData.feedback} />
                <SpinnerOverlay isLoading={sourceData.inProgress}>
                    <Table responsive hover size="sm">
                        <thead>
                            <TableHeaderRow
                                {...tableHeaderRowEvents}
                                disabled={sourceData.inProgress}
                                headers={[
                                    { sortBy: "Description", label: "Description" },
                                    { sortBy: "Code", label: "Code / Sort Order" }
                                ]}
                            />
                        </thead>
                        <tbody>
                            {
                                sourceData.records.map((record, index) => (
                                    <ExpenseTypeRow
                                        key={index}
                                        {...record}
                                        onShowDelete={() => modalEvents.onShowDelete(record)}
                                        onShowEdit={() => modalEvents.onShowEdit(record)}
                                        disabled={sourceData.inProgress} />
                                ))
                            }
                        </tbody>
                    </Table>
                    <Paginator {...paginatorEvents} searchOptions={sourceData.searchOptions} />
                    <AddButton disabled={sourceData.inProgress} onClick={() => modalEvents.onShowNew()} />
                </SpinnerOverlay>

                <FormModal
                    isVisible={ModalActions.IsDeleteVisible(modals)}
                    onHide={modalEvents.onHideDelete}
                    title={"Confirm Delete"}>
                    <ConfirmDelete onConfirmDelete={() => confirmEvents.onConfirmDelete(sourceData.recordToUpdate)} />
                </FormModal>
                <FormModal
                    isVisible={ModalActions.IsUpdateVisible(modals)}
                    onHide={modalEvents.onHideEdit}
                    title={"Update Expense Type"}>
                    <ExpenseTypeFormBasic onSubmitUpdate={confirmEvents.onSubmitUpdate}
                        {...sourceData.recordToUpdate} />
                </FormModal>
            </div >
        )
    }
}

export default ExpenseTypeList