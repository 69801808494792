import ScopeConstants from "constants/ScopeConstants"
import ExpenseStatusConstants from "constants/ExpenseStatusConstants"

export const ExpenseTeamScope = {
    key: ScopeConstants.EXPENSE_TEAM,
    selector: (state) => state.expensesTeam,
    tokenSelector: (state) => state.authentication.token,
    fetchEndpoint: "/api/Expense/SearchTeam/",
    singleEndpoint: "/api/Expense/GetTeam/",
    updateEndpoint: "/api/Expense/UpdateTeam/",
    deleteEndpoint: "/api/Expense/DeleteTeam/",
    defaultRecord: {
        name: "",
        code: "",
        status: ExpenseStatusConstants.DRAFT
    }
}