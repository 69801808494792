import React from "react"
import { Badge } from "reactstrap"
import OpportunityStatusConstants from "constants/OpportunityStatusConstants"

const OpportunityStatusBadge = props => {
    const { value } = props
    return (
        <Badge color={MapOpportunityStatusColour(value)}>
            {MapOpportunityStatusLabel(value)}
        </Badge>
    )
}

const MapOpportunityStatusLabel = value => {
    switch (value) {
        case OpportunityStatusConstants.DRAFT:
            return "DRAFT"
        case OpportunityStatusConstants.INPROGRESS:
            return "IN PROGRESS"
        case OpportunityStatusConstants.NOGO:
            return "NO GO"
        case OpportunityStatusConstants.CONVERTEDTOPROPOSAL:
            return "CONVERTED TO PROPOSAL"
        default:
            return "UNKNOWN"
    }
}

const MapOpportunityStatusColour = value => {
    switch (value) {
        case OpportunityStatusConstants.DRAFT:
            return "warning"
        case OpportunityStatusConstants.INPROGRESS:
            return "primary"
        case OpportunityStatusConstants.NOGO:
            return "dark"
        case OpportunityStatusConstants.CONVERTEDTOPROPOSAL:
            return "success"
        default:
            return "default"
    }
}

export default OpportunityStatusBadge