import ScopeConstants from "constants/ScopeConstants"
import { ScopeShape } from "@gman/gman-redux"
import PropTypes from "prop-types"

export const TimesheetEntryScope = {
    key: ScopeConstants.TIMESHEET_ENTRY,
    selector: (state) => state.timesheetEntries,
    tokenSelector: (state) => state.authentication.token,
    fetchEndpoint: "/api/TimesheetEntry/Search/",
    singleEndpoint: "/api/TimesheetEntry/Get/",
    updateEndpoint: "/api/TimesheetEntry/Update/",
    deleteEndpoint: "/api/TimesheetEntry/Delete/",
    unauthorizedFallbackRedirect: "/timesheet/mine/",
    defaultRecord: {
        mondayHours: "",
        tuesdayHours: "",
        wednesdayHours: "",
        thursdayHours: "",
        fridayHours: "",
        saturdayHours: "",
        sundayHours: "",
        timesheetId: "",
        projectId: "",
        comment: ""
    }
}

TimesheetEntryScope.propTypes = PropTypes.shape(ScopeShape).isRequired