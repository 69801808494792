import React from "react"
import { Card, CardBody, CardHeader } from "reactstrap"
import { ExpenseTypeListContainer } from "components/expenseTypes/redux/ExpenseTypeContainers"
import Icon from "components/common/Icon"

const ExpenseTypeCardBasic = () => {
    return (
        <Card>
            <CardHeader>
                <h6>
                    <Icon expenseType/>
                    Expense Types
                </h6>
            </CardHeader>
            <CardBody>
                <ExpenseTypeListContainer />
            </CardBody>
        </Card >
    )
}

export default ExpenseTypeCardBasic