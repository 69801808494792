import ScopeConstants from "constants/ScopeConstants"
import { FormActionReducer } from "@gman/gman-redux"

export const ProjectFinancialReducer = (
    state = {},
    action) => {

    if (action.scope === ScopeConstants.PROJECT_FINANCIAL) {
        return FormActionReducer.reduce(action, state)
    }

    return state
}