import Moment from "moment"

export const WeekEndingReducer = (
    state = {
        records: Weeks()
    }
) => { return state }


const Weeks = () => {

    var weeks = []

    var weekDayToFind = Moment().day("Friday").weekday()

    var searchDate = Moment()

    while (searchDate.weekday() !== weekDayToFind) {
        searchDate.add(1, 'day')
    }

    searchDate.add(-4, 'week')

    for (var i = 0; i < 8; i++) {
        weeks.push(
            {
                date: searchDate.format("YYYY-MM-DD"),
                label: searchDate.format("DD/MM/YYYY")
            })

        searchDate.add(7, 'day')
    }

    return weeks;
}