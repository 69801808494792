import React from "react"
import { AuthorizedSummaryToolbar } from "components/authentication/controls/AuthorizedControls"

const Toolbar = AuthorizedSummaryToolbar(p => p.canWriteOffices)

const OfficeRow = (
    {
        name,
        onShowEdit,
        onShowDelete,
        disabled
    }) => (
        <tr>
            <td>{name}</td>
            <td>
                <Toolbar disabled={disabled} onShowEdit={onShowEdit} onShowDelete={onShowDelete} />
            </td>
        </tr>
    )

export default OfficeRow 