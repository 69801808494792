import React from "react"
import { Card, CardBody, CardHeader } from "reactstrap"
import { OfficeListContainer } from "components/offices/redux/OfficeContainers"
import Icon from "components/common/Icon"

const OfficeCardBasic = () => {
    return (
        <Card>
            <CardHeader>
                <h6>
                    <Icon office/>
                    Offices
                </h6>
            </CardHeader>
            <CardBody>
                <OfficeListContainer />
            </CardBody>
        </Card >
    )
}

export default OfficeCardBasic