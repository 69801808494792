import React from "react"
import { withFormik } from "formik"
import * as Yup from "yup"
import RequiredStar from "components/common/RequiredStar"

import {
    Button,
    Container,
    Col,
    Form,
    FormGroup,
    Input,
    Label,
    Row
} from "reactstrap"

const BaseForm = props => {
    const {
        values,
        dirty,
        errors,
        handleChange,
        handleBlur,
        onConfirmReject,
        isSubmitting
    } = props
    return (
        <div>
            <Form noValidate>
                <Container>
                    <Row>
                        <Col xs={12}>
                            <FormGroup>
                                <RequiredStar />
                                <Label for="comments">Reason for rejection</Label>
                                <Input
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.comments || ""}
                                    type="textarea"
                                    name="comments"
                                    id="comments"
                                    placeholder="Enter reason for rejection"
                                    style={{ resize: "none" }}
                                    rows={3}
                                    invalid={errors.comments} />
                                {errors.comments && <small className="text-danger">{errors.comments}</small>}
                            </FormGroup>
                        </Col>
                    </Row>
                </Container>
                <Button outline className="float-right" disabled={isSubmitting || !dirty} color="success" onClick={() => onConfirmReject(values.comments)}>Submit</Button>
            </Form>
        </div>
    )
}

const validationSchema = Yup.object({
    comments: Yup.string()
        .required("Reason is required")
})

const RejectReasonForm = withFormik({
    displayName: "RejectReasonForm",
    enableReinitialize: true,
    validationSchema: validationSchema
})(BaseForm)

export default RejectReasonForm