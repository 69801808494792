import React from "react"
import Moment from "moment"
import { AuthorizedSummaryToolbar } from "components/authentication/controls/AuthorizedControls"

const Toolbar = AuthorizedSummaryToolbar(p => p.canWriteSystem)

const SystemRow = (
    {
        effectiveDate,
        gstRate,
        mileageRate,
        dailyMileageClaimLimit,
        onShowEdit,
        onShowDelete,
        disabled
    }) => {
    return (<tr>
        <td>{Moment(effectiveDate).format("DD/MM/YYYY") || ""}</td>
        <td>{gstRate}</td>
        <td>{mileageRate}</td>
        <td>{dailyMileageClaimLimit}</td>
        <td>
            <Toolbar disabled={disabled} onShowEdit={onShowEdit} onShowDelete={onShowDelete} />
        </td>
    </tr>)
}

export default SystemRow 