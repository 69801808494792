import React, { Component } from "react"
import { Link } from "react-router-dom"
import { Redirect } from "react-router"
import { Card, CardBody, CardHeader, CardTitle, CardSubtitle } from "reactstrap"
import { SpinnerOverlay } from "@gman/gman-react-bootstrap"
import OpportunityDisplayExtended from "components/opportunities/form/OpportunityDisplayExtended"
import OpportunityFormExtended from "components/opportunities/form/OpportunityFormExtended"
import OpportunityStatusBadge from "components/common/OpportunityStatusBadge"
import FeedbackAlert from "components/common/FeedbackAlert"
import { RestrictedComponent } from "components/authentication/redux/AuthorizationWrappers"
import Icon from "components/common/Icon"

const EditRecordLink = RestrictedComponent((props) =>
    <Link className="editor-span" to={"/opportunityDetails/" + props.recordId}>
        <Icon edit />
    </Link>, p => p.canWriteWorkItems)

class OpportunityCardExtended extends Component {

    componentDidMount() {
        if (this.props.opportunityId) {
            this.props.recordStateEvents.onLoadSingle(this.props.opportunityId)
            this.props.onLoadDependentData()
        }
    }

    componentWillUnmount = () => {
        this.props.feedbackEvents.onFlushFeedback()
        this.props.redirectEvents.onClearRedirect()
    }

    render() {
        var readOnly = this.props.readOnly
        var sourceData = this.props.sourceData
        if (sourceData.redirect) {
            return (<Redirect to={sourceData.redirect} />)
        }

        var modals = sourceData.modals;
        var confirmEvents = this.props.confirmEvents
        var feedbackEvents = this.props.feedbackEvents
        var modalEvents = this.props.modalEvents

        return (
            <Card>
                <CardHeader>
                    <CardTitle>
                        {
                            sourceData.recordToUpdate ?
                                sourceData.recordToUpdate.name :
                                "Loading..."
                        }
                        {readOnly ? <EditRecordLink recordId={this.props.opportunityId} /> : ""}
                    </CardTitle>
                    <hr />
                    <CardSubtitle>
                        <Icon opportunity />
                        <small>
                            <i>Opportunity</i>
                        </small>
                    </CardSubtitle>
                    <br />
                    <OpportunityStatusBadge value={sourceData.recordToUpdate.status} />
                </CardHeader>
                <CardBody>
                    <FeedbackAlert {...feedbackEvents} feedback={sourceData.feedback} />
                    <SpinnerOverlay isLoading={sourceData.inProgress}>
                        {readOnly ?
                            <OpportunityDisplayExtended values={sourceData.recordToUpdate} /> :
                            <div>
                                <OpportunityFormExtended
                                    modals={modals}
                                    modalEvents={modalEvents}
                                    onSubmitUpdate={confirmEvents.onSubmitUpdate}
                                    onConfirmConvert={this.props.onConfirmConvert}
                                    {...confirmEvents}
                                    {...sourceData.recordToUpdate}
                                />
                            </div>
                        }
                    </SpinnerOverlay>
                </CardBody>
            </Card>
        )
    }
}

export default OpportunityCardExtended