import { connect } from "react-redux"
import { CostRateScope } from "components/costRates/redux/CostRateScope"
import CostRateList from "components/costRates/layout/CostRateList"
import CostRateForm from "components/costRates/form/CostRateForm"
import { FormEvents, FormActions } from "@gman/gman-redux"
import ChargeRateTypeConstants from "constants/ChargeRateTypeConstants"

const mapDispatchToProps = (dispatch) => {
    var scope = CostRateScope;
    var customSort = {
        tableHeaderRowEvents: {
            onSortBy: (column) => {
                dispatch(FormActions.updateSortBy(scope, column))
                return dispatch(FormActions.fetchRecords(scope, "/api/ChargeRate/Cost/Rates/"))
            }
        },
        paginatorEvents: {
            onPageChange: (page) => {
                dispatch(FormActions.onPageChange(scope, page))
                return dispatch(FormActions.fetchRecords(scope, "/api/ChargeRate/Cost/Rates/"))
            }
        }
    }

    return {
        ...FormEvents.getConfirmEventsForModalsOnAll(dispatch, scope),
        ...FormEvents.getFeedbackEvents(dispatch, scope),
        ...FormEvents.getModalEventsForModalsOnAll(dispatch, scope),
        ...FormEvents.getRecordStateEvents(dispatch, scope),
        ...customSort,
        onGetRates: () => {
            getApplicableRates(dispatch, scope);
        },
        onPublishNewVersion: (draft) => {
            dispatch(FormActions.hideModal(scope, "DRAFT"));
            dispatch(FormActions.hideModal(scope, "CONFIRM_CHARGE_RATES_PUBLISH"));
            draft.currentRatesType = ChargeRateTypeConstants.COST;
            dispatch(FormActions.doPost(scope, draft, "/api/ChargeRate/Publish/", "The draft was published successfully.", false))
                .then(
                    () => {
                        getApplicableRates(dispatch, scope);
                    }
                )
        }
    }
}

const getApplicableRates = (dispatch, scope) => {
    return dispatch(FormActions.fetchRecords(scope, "/api/ChargeRate/Cost/Rates/"))
}

const CostRateListContainer = connect((state) => {
    return {
        sourceData: CostRateScope.selector(state)
    };
}, mapDispatchToProps)(CostRateList);


const CostRateFormContainer = connect(
    (state, ownProps) => {
        return {
            chargeRates: CostRateScope.selector(state).records
        }
    }, {}
)(CostRateForm)

export { CostRateListContainer, CostRateFormContainer }