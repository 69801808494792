import ScopeConstants from "constants/ScopeConstants"
import { ScopeShape } from "@gman/gman-redux"
import PropTypes from "prop-types"

export const ResetPasswordScope = {
    key: ScopeConstants.RESET_PASSWORD,
    selector: (state) => state.resetPasswords,
    tokenSelector: (state) => state.authentication.token,
    defaultRecord: { }
}

ResetPasswordScope.propTypes = PropTypes.shape(ScopeShape).isRequired