import React from "react"
import { Link } from "react-router-dom"
import { AuthorizedSummaryToolbar } from "components/authentication/controls/AuthorizedControls"
import ChargeListStatusBadge from "components/common/ChargeListStatusBadge"

const Toolbar = AuthorizedSummaryToolbar(p => p.canWriteChargeRates)

const ChargeRateListRowExtended = (
    {
        chargeRateListId,
        name,
        comment,
        status,
        disabled,
        currentRatesType,
        usingRatesType,
        onShowDelete
    }) => (
        <tr>
            <td>
                <Link to={"/chargeRate/" + usingRatesType + "/" + chargeRateListId}>
                    {name}
                </Link>
            </td>
            <td>
                <ChargeListStatusBadge value={status} />
            </td>
            <td>{comment}</td>
            <td>
                {currentRatesType === usingRatesType ? <Toolbar disabled={disabled} onShowDelete={onShowDelete} /> : ''}
                
            </td>
        </tr>
    )

export default ChargeRateListRowExtended 